import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionObligatoriaPorId ,RegistrarRespuestasUsuario} from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import * as constantes from "../../../constants/constantes";
import "./CapaObligatoria.scss"
import { Steps,Panel,Placeholder } from "rsuite";
import Boton from "../../../components/Boton/Boton";
import { ListBox } from "primereact/listbox";
import { Dialog } from "primereact/dialog";

const PreguntasCapaObligatoria = () => {
const [step, setStep] = useState(0);

const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(true);
    const [capaObligatoria, setCapaObligatoria] = useState(null);
    const [numeroPreguntas, setNumeroPreguntas] = useState(5);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { idCapacitacion} = useParams();
    let { visualizacion} = useParams();
    
     
    const [mostrarDialog, setMostrarDialog] = useState(false);
    const [respuestSecleccionada, setRespuestSecleccionada] = useState([]);

    const [tituloDialog, setTituloDialog] = useState("");
    const [estadoRespuesta, setEstadoRespuesta] = useState("");
    const [resultadoUsuario, setResultadoUsuario] = useState(null);
    const [mostrarBotonEnviar, setMostrarBotonEnviar] = useState(true);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        const getCapacitacion = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCapacitacion;
            await ObtenerCapacitacionObligatoriaPorId({jwt,Id}).then(data => 
                {
                    setNumeroPreguntas(data.capacitacion_obligatoria_preguntas.length)
                    setCapaObligatoria(data)
                    let temp = []
                    data.capacitacion_obligatoria_preguntas.map((item,i)=>{
                        
                        temp.push({idCapacitacion: data.id, idPregunta: item.id,idItem: item.numeroPregunta,idRespuesta:null})
                    })
                    setRespuestSecleccionada(temp)
                })
        }
        if(!capaObligatoria) getCapacitacion();

    },[idCapacitacion])
    useEffect(()=>{
        if(visualizacion)setMostrarBotonEnviar(false)
    },[visualizacion])

    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > numeroPreguntas -1 ? numeroPreguntas-1 : nextStep);
      };

        
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);
    const handleChange = (step,valor) => {
        if(respuestSecleccionada.length > 0)
        {
            setRespuestSecleccionada(
                respuestSecleccionada.map((item) =>
                    item.idItem -1 === step
                        ? { ...item, idRespuesta: valor }
                        : { ...item}
                )
            );
        }
        else{
            setRespuestSecleccionada([...respuestSecleccionada,{idPregunta:step,idRespuesta:valor}])
        }
        
    };
    const Registrar =({jsonRespuestas})=>{
        let jwt = window.localStorage.getItem("jwt");
        RegistrarRespuestasUsuario({jsonRespuestas,jwt}).then(data=>{
            setResultadoUsuario(data)
            if(data.estado ==="Exitoso")
            {
                setTituloDialog("¡Felicitaciones!")
                setEstadoRespuesta("Exitoso")
            }
            else{
                if(data.intentosPendientes > 0)
                {
                    setTituloDialog("¡No te desanimes!")
                    setEstadoRespuesta("Pendiente")

                }else{
                    setTituloDialog("¡Lo sentimos!")
                    setEstadoRespuesta("Perdido")

                }
            }
            setMostrarDialog(true)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }

    const enviarRespuestas =()=>{
        let respuestasFaltantes = respuestSecleccionada.filter(x=>x.idRespuesta == null)
        if(respuestasFaltantes.length > 0)
        {
            toast.current.show({severity:'error', summary: 'Error', detail:"Existe preguntas que no han sido respondidas.", life: 7000})
            return;
        }

        let jsonRespuestas = JSON.stringify(respuestSecleccionada,null,2)
        Registrar({jsonRespuestas})


    }
    const footerDialog=
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
                <Boton nombre={"Ir a prendientes"} color="primary" widths="184px" metodoClick={()=>navigate("../Capacitacion/Seccion/"+idSeccion+"/ListaObligatoria")}></Boton>
            </div>
    ;
    return ( 
        <div className="eva-detalleCapaObligatoria" style={{ marginLeft: 40 }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
             <div className="eva-dashboard-header">
                
                
                <div>{capaObligatoria && <label htmlFor="header">{capaObligatoria.nombreCapacitacion}</label>}</div>
            </div>
            <div className="eva-dashboard-preguntas" style={{marginTop:24}}>
                <div className="preguntas-steps">
                    <Steps current={step}>
                        {
                            capaObligatoria &&
                            capaObligatoria.capacitacion_obligatoria_preguntas.length > 0&&
                            capaObligatoria.capacitacion_obligatoria_preguntas.map((item,i)=>{
                               return(<Steps.Item icon={i+1}/>)
                            })
                        }
                     

                    </Steps>
                </div>
                <div className="preguntas-detalle">
                    <div className="preguntas-detalle-nombre titulo">
                        {step +1+". "}
                        {capaObligatoria && capaObligatoria.capacitacion_obligatoria_preguntas.length && capaObligatoria.capacitacion_obligatoria_preguntas[step].detallePregunta}
                    </div>
                    <div className="preguntas-detalle-alternativas">
                        <ListBox value={respuestSecleccionada.length >0 &&respuestSecleccionada.filter(x=>x.idItem -1 === step).length > 0 && respuestSecleccionada.filter(x=>x.idItem -1 === step)[0].idRespuesta} 
                            onChange={(e)=>{
                                handleChange(step,e.value)
                                console.log(respuestSecleccionada)
                            }} 
                            options={capaObligatoria &&capaObligatoria.capacitacion_obligatoria_preguntas[step].capacitacion_obligatoria_alternativas} 
                            optionLabel = "detalleAlternativa"
                            optionValue="id"
                        ></ListBox>
                    </div>
                </div>
                

                <div style={{display:"flex",justifyContent:"end"}}>
                    {step > 0 && <Boton nombre={"Regresar"} color="secondary" metodoClick={onPrevious} widths="184px"></Boton>} 
                    {step > -1 && step < numeroPreguntas -1 && <Boton nombre={"Continuar"} color="primary"  metodoClick={onNext} widths="184px"></Boton>}
                    {(step === numeroPreguntas-1 &&mostrarBotonEnviar) && <Boton nombre={"Enviar"} color="primary"  metodoClick={enviarRespuestas} widths="184px"></Boton>}
                    {(step === numeroPreguntas-1 &&!mostrarBotonEnviar) && <Boton nombre={"Ir a capacitaciones Obligatorias"} color="primary"  metodoClick={navigate("../Capacitacion/Seccion/"+idSeccion+"/ListaObligatoria")} widths="280px"></Boton>}

                </div>
               
            </div>
            <Dialog visible={mostrarDialog} onHide={()=>navigate("../Capacitacion/Seccion/"+idSeccion+"/ListaObligatoria")} 
                footer={footerDialog}  className ="p-eva-dialogRespuestas"
                >
                
                <div className="confirmaRespuestas"><label >{tituloDialog}</label></div>
                <div>
                    {
                        estadoRespuesta === "Exitoso" && 
                        <label className="dialogRespuestas-mensaje">Aprobaste el test, respuestas respondidas correctamente <span className="respuestaCorrectas-exitoso">{resultadoUsuario.respuestasCorrectas}/5</span>. A continuación puedes revisar si tienes alguna capacitación pendiente</label>
                    }
                    {
                        estadoRespuesta ==="Pendiente" &&
                        <label className="dialogRespuestas-mensaje">Puedes lograr mejores resultados, respuestas respondidas correctamente <span className="respuestaCorrectas-error">{resultadoUsuario.respuestasCorrectas}/5</span>. 
                        {resultadoUsuario.intentosPendientes == 2 ? "Tienes dos intentos": "Tienes un intento" } más para poder enviar el test.</label>

                    }
                    {
                        estadoRespuesta ==="Perdido" && 
                        <label className="dialogRespuestas-mensaje">Desaprobaste los 3 intentos, respuestas respondidas correctamente <span className="respuestaCorrectas-error">{resultadoUsuario.respuestasCorrectas}/5</span>. Te recomendamos ver a más detalle el video de la capacitación para los siguientes test.</label>

                    }
                </div>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>
        </div>
     );
}
 
export default PreguntasCapaObligatoria;