import React,{useEffect,useState,useRef} from "react";
import { Card } from "primereact/card";
import  {Avatar} from "primereact/avatar"
import "./CardPublicacion.scss"
import * as Iconsax from "iconsax-react";
import BoxReacciones from "./BoxReacciones";
import Comentarios from "./Comentarios";
import InputIcon from "../../InputIcon/InputIcon";
import useUsuario from "../../../hooks/useUsuario";
import useInteraccion from "../../../hooks/useInteraccion"
import usePublicacion from "../../../hooks/usePublicacion";
import * as constantes from "../../../constants/constantes.js";
import {RegistrarComentario,ListarInteraccionPorPublicacion,EliminarComentario,ListarComentariosPorPublicacion, ObtenerNotificacionPorId} from "../../../service/PublicacionService"
import { Registrar } from "../../../service/PublicacionService";
import InputPublicacion from "./InputPublicacion";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { ObtenerMensajeNotificacion, ObtenerTiempoTranscurrido } from "../../../helpers/helpers";
import { Menu } from 'primereact/menu';
//import { Toast } from 'primereact/toast';
import ToastEva from "../../Toast/ToastEva";
import Compartir from "./Compartir";
import Interacciones from "./Interacciones";
import axios from "axios";
import CrearCompartir from "./CrearCompartir";
import CardPublicacionCompartida from "./CardPublicacionCompartida";
import PreviewMedia from "../../PreviewMedia/PreviewMedia";
import PreviewMediaImage from "../../PreviewMedia/PreviewMediaImage";
import UsuariosSugeridos from "./UsuariosSugeridos"

import EmojiPicker from "emoji-picker-react";
import ReactPlayer from "react-player";
import { Dialog } from "primereact/dialog";
import Boton from "../../Boton/Boton";

import { ActualizarVista } from "../../../service/PublicacionService";
import { Actualizar } from "../../../service/InteraccionService";
import  PreviewCarousel  from "./../../../components/PreviewCarousel/PreviewCarousel";
import {ListUsers} from "../../../service/UsuariosService";


import Konva from "konva";

const CardPublicacion = ({onClickEdit,nombreCorto,foto,idUsuario,fijada,contenido
            ,link,nombreLink,imagen,imagenes,IdPublicacion,listaInteraccion,interacionLogin
            ,interacionUsuarios,totalInteracciones,listaComentariosPub,perfil,keys,nombreGrupo,fechaRegistro
            ,gruposUsuario,objPublicacion,isAdmin,menuRef}) => {
    const {listaComentarios,isCreatedComentario,RegistrarComentarios} = usePublicacion()
    
    const [mostrarReacciones, setMostrarReacciones] = useState(false);
    const [mostrarComentarios, setMostrarComentarios] = useState(false);
    const [srcImagenPerfil, setsrcImagenPerfil] = useState();
    const [reaccionHtmlSeleccionada, setReaccionHtmlSeleccionada] = useState({icon: <Iconsax.Like1 size={24}/>, texto: <span className="text-sm font-normal">Me gusta</span>});
    const [reaccionSeleccionada, setReaccionSeleccionada] = useState(null);
    const {isUpdate} = useInteraccion()
    const [detalleInteracciones, setDetalleInteracciones] = useState([]);
    const [totalReacciones, setTotalReacciones] = useState(0);
    const [stateImage, setStateImage] = useState({ file: null, base64URL: "" });
    const [previewMedia, setPreviewMedia] = useState(null);
    const [previewMediaArregloImg, setPreviewMediaArregloImg] = useState([]);
    const [comentariosPub, setComentariosPub] = useState(null);
    const [mostrarCompartir, setMostrarCompartir] = useState(false);
    const [mostrarInteraccionesPub, setMostrarInteraccionesPub] = useState(false);
    const [mostrarCrearCompartir, setMostrarCrearCompartir] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [idLogin, setIdLogin] = useState(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState({idGrupoS:"",nombreGrupoS:""});
    const [mostrarEmojis, setMostrarEmojis] = useState(false);
    
    const [mostrarEliminarComentario, setMostrarEliminarComentario] = useState(false);
    const [comentarioSeleccionado, setComentarioSeleccionado] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [vistaPublicacion, setVistaPublicacion] = useState(false);
    const [usuariosSugeridos, setUsuariosSugeridos] = useState([]);
    const [comentarioPersonasEtiquetadas, setComentarioPersonasEtiquetadas] = useState([])

    const menu = useRef(null);
    const toast = useRef(null);

    const hiddenRef = useRef();
    
    useEffect(()=>{

        if(objPublicacion)setVistaPublicacion(objPublicacion.vistoUsuario)

    },[objPublicacion])
    let itemsCompartir = [
        {label: 'Copiar link para compartir', icon: <Iconsax.Link2 style={{marginRight:8}} color="#F47647"/>,
        command: () => {
            navigator.clipboard.writeText(window.location.origin+"/Dashboard/Grupos/"+objPublicacion.idGrupo+"/Publicaciones/"+objPublicacion.id)
            toast.current.show({ severity: 'success', summary: 'Copiado', detail: 'Enlace Copiado', life: 3000 });
        }},
        {label: 'Compartir en un grupo', icon: <Iconsax.Profile2User style={{marginRight:8}} color="#F47647"/>,
            command:()=>{
                setMostrarCompartir(true)
            }
        }
    ];

   
    const ObtenerDetalleNotificacion =async(idUsuario,id,imagen,detalle)=>{
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje="";
            if(data.tipoNotificacion == "COMENTARIO")
            {
                mensaje = data.nombreUsuarioOrigen + " Comentó tu publicación." ;
            }
            else if(data.tipoNotificacion == "INTERACCION")
            {
                mensaje = data.nombreUsuarioOrigen + " Reaccionó a tu publicación" ;
                
            }
           
            // let mensaje = (data.tipoNotificacion == "APROBACION" ? "" : data.nombreUsuarioOrigen) + " " + 
            //                 ObtenerMensajeNotificacion(data.tipoNotificacion,data.nombreUsuarioReferencia
            //                             , perfil.idUsuario === data.idUsuarioReferencia ? true : false
            //                             ,data.nombreGrupo)

            sendMessage(idUsuario,id,imagen,detalle,mensaje)
        })  
    }            


    const ActualizarReaccion = async({jsonInteraccion})=>{

        let jwt = window.localStorage.getItem("jwt");
        await Actualizar({jsonInteraccion,jwt}).then(data => 
        {
            let idNoti = data.data.id;
            let imagen = data.data.imagen;
            ListarInteraccionPorPublicacion({jwt,IdPublicacion})
                .then(data => {
                    setDetalleInteracciones(data);
                    let countTotal = 0;
                    data.map((item,i)=>{
                        countTotal += item.listaUsuarios.length;
                    })
                    setTotalReacciones(countTotal)
                    var arr_from_json = JSON.parse( jsonInteraccion );

                    let nombreReaccion = listaInteraccion.filter(x=>x.id ==arr_from_json.idTipoInteraccion );

                    if(idLogin != idUsuario) {
                       
                        ObtenerDetalleNotificacion(idUsuario,idNoti,imagen,nombreReaccion[0].icono+nombreReaccion[0].nombre)
                        
                    }
                }
                );

        })
    }

    useEffect(()=>{
        if(listaComentariosPub) setComentariosPub(listaComentariosPub);
    },[listaComentariosPub])

    useEffect(()=>{
        if(perfil)setIdLogin(perfil.idUsuario)
    },[perfil])
    
    const getComentarios = async ()=>
    {
        let jwt = window.localStorage.getItem("jwt");
        let IdPublicacion = objPublicacion.id;
        await ListarComentariosPorPublicacion({jwt,IdPublicacion}).then(data => 
            {
                setComentariosPub(data)

            })
    }

    const updateVista = async ()=>
    {
        let jwt = window.localStorage.getItem("jwt");
        let idPub = objPublicacion.id;
        await ActualizarVista({jwt,idPub}).then(data => 
        {
            setVistaPublicacion(true)
        })
    }

    const sendMessage = async (idDestinoUsuario,idNoti,ruta,detalle  ="",mensaje="") => {
        return axios.post(constantes.URL_NOTIFICACION+"/messageUser",{
            //GroupName: grupo,
             GroupName: "Administrador General - Plataforma",
            //GroupName: "InLearning",
            ConnectionId:idDestinoUsuario,
            Sender:"WEB222",
            Text: "mensaje enviado por el sistema web",
            Link:  constantes.URLSITE_PUBLICACION+objPublicacion.idGrupo+"/Publicaciones/"+objPublicacion.id,
            IdNotificacion:idNoti,
            RutaImagen: constantes.URLBLOB_PUBLICACIONES+"/"+objPublicacion.id+"/"+ruta,
            Detalle :detalle,
            Mensaje: mensaje
        }).then(resp=>resp.data)
    }

    // useEffect(()=>{
    //     if(isCreatedComentario) 
    //     {       
    //         sendMessage(idUsuario)
    //         setComentariosPub(listaComentarios)
    //     }
    // },[isCreatedComentario])

    useEffect(()=>{
        if(comentariosPub) comentariosPub.length > 0 && setMostrarComentarios(true)
    },[comentariosPub])

    useEffect(() => {
        if (!stateImage.file) {
            setPreviewMedia(null);
            setPreviewMediaArregloImg([])

            return;
        }

        const objectUrl = URL.createObjectURL(stateImage.file);
        console.log("IMAGEEN",objectUrl)
        setPreviewMedia(objectUrl);
        const arregloUrlImg = [objectUrl];
        setPreviewMediaArregloImg(arregloUrlImg)
        console.log("arregloUrlImg",arregloUrlImg)


        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [stateImage]);

    useEffect(()=>{
        if(interacionLogin && listaInteraccion.length >0) {
            setReaccionSeleccionada(interacionLogin)

            let seleccion = listaInteraccion.filter(x=>x.id ==interacionLogin );
            setReaccionHtmlSeleccionada({icon:
                <img src={"../../../../images/inicio/"+seleccion[0].rutaImagen} width={24}></img>,
                texto: <span className="text-sm" style={{color:"#F47647",fontWeight:700}}>{seleccion[0].nombre}</span>
            })

        }
    },[listaInteraccion])

    useEffect(()=>{
        if(interacionUsuarios)setDetalleInteracciones(interacionUsuarios)
    },[interacionUsuarios])

    useEffect(()=>{
        if(totalInteracciones)setTotalReacciones(totalInteracciones)
    },[totalInteracciones])
    const header = 
            <div className="p-eva-cardPublicacion-header">
                <div className="cardPublicacion-avatar">
                    <Avatar image = {foto ? constantes.URLBLOB_ADJ_AVATAR+"/"+idUsuario+"/"+foto : constantes.URLAVATARDEFAULT}
                        size={"xlarge"} shape="circle" style={{minWidth:56}}
                    />
                      
                </div>
                <div className="cardPublicacion-infoAvatar">
                    <div className="infoAvatar-grupo">
                        <div className="cardPublicacion-grupo-info"   style={{display:"flex",alignItems:"center",gap:5}}><div className="titulo">{nombreCorto }</div><div className="titulo">{nombreGrupo && " > "}</div><div className="titulo">{nombreGrupo && nombreGrupo}</div></div>
                        <div className="cardPublicacion-fijadoEdit">
                            {
                                    fijada &&  <div className="cardPublicacion-fijado">Publicacion Fijada</div>

                            }
                                <i onClick={onClickEdit} style={{cursor:"pointer",display:isAdmin?"flex":"none"}}><Iconsax.Edit2 color="#F47647" /></i>
                        </div>  
                    </div>
                    <div><span className="p-eva-labelForm-grey">{ObtenerTiempoTranscurrido(objPublicacion.fechaActual,fechaRegistro)}</span></div>
                </div> 

                {/* <div className="cardPublicacion-fijadoEdit">
                    {
                            fijada &&  <div className="cardPublicacion-fijado text-sm"><span>Publicacion Fijada</span></div>

                    }
                        <i onClick={onClickEdit} style={{cursor:"pointer",display:isAdmin?"flex":"none"}}><Iconsax.Edit2 color="#F47647" /></i>
                </div>  
                <div className="cardPublicacion-avatar">
                    <Avatar image = {foto ? constantes.URLBLOB_ADJ_AVATAR+"/"+idUsuario+"/"+foto : constantes.URLAVATARDEFAULT}
                        size={"xlarge"} shape="circle" style={{minWidth:56}}
                    />
                    <div className="cardPublicacion-infoAvatar">
                        <span className="titulo">{nombreCorto }{nombreGrupo && "> "+nombreGrupo}</span>
                        <span className="p-eva-labelForm-grey">{ObtenerTiempoTranscurrido(objPublicacion.fechaActual,fechaRegistro)}</span>
                    </div>    
                </div> */}
                
                
            </div>
        ;

        
        // useEffect(()=>{
        //     if(isUpdate) {

        //         let jwt = window.localStorage.getItem('jwt');
        //         ListarInteraccionPorPublicacion({jwt,IdPublicacion})
        //         .then(data => {
        //             setDetalleInteracciones(data);
        //             let countTotal = 0;
        //             data.map((item,i)=>{
        //                 countTotal += item.listaUsuarios.length;
        //             })
        //             setTotalReacciones(countTotal)
        //             if(idLogin != idUsuario) sendMessage(idUsuario);
        //         })
        //     }
        // },[isUpdate])

        const recibirDatosUsuariosEtiquetados = (datos) => {
            var PublicacionPersonasEtiquetadasArray = comentarioPersonasEtiquetadas;
            console.log("PublicacionPersonasEtiquetadasAntes",PublicacionPersonasEtiquetadasArray);
            var PublicacionPersonasEtiquetadasRow = {
                "idUsuario": datos.value,
                "nombre": datos.label
            }
            PublicacionPersonasEtiquetadasArray.push(PublicacionPersonasEtiquetadasRow);
            console.log("PublicacionPersonasEtiquetadasRow",PublicacionPersonasEtiquetadasRow);
            console.log("PublicacionPersonasEtiquetadasDespues",PublicacionPersonasEtiquetadasArray);
            setComentarioPersonasEtiquetadas(PublicacionPersonasEtiquetadasArray);
        }

        const handleClicReaccion =(seleccion)=>
        {
            let idPublicacion = IdPublicacion;
            let estado = true;
            let idTipoInteraccion = seleccion.alt;

            let jsonInteraccion = JSON.stringify({ idPublicacion,estado,idTipoInteraccion})

            setMostrarReacciones(false)
            setReaccionSeleccionada(seleccion.alt)
            setReaccionHtmlSeleccionada({icon:
                <img src={seleccion.src} width={24}></img>,
                texto: <span  className="text-sm"style={{color:"#F47647",fontWeight:700}}>{seleccion.getAttribute("data-name")}</span>
            })
            
            ActualizarReaccion({jsonInteraccion});

            
        }
        

        const handleClicMegusta=()=>{
            let idPublicacion = IdPublicacion;
            let estado = false;
            let idTipoInteraccion = null;

            let jsonInteraccion = JSON.stringify({ idPublicacion,estado,idTipoInteraccion})
            if(reaccionSeleccionada)
            {
                ActualizarReaccion({jsonInteraccion})
                setTotalReacciones(totalReacciones - 1)
            }
            

            setMostrarReacciones(false)
            setReaccionSeleccionada(null)
            setReaccionHtmlSeleccionada({icon: <Iconsax.Like1 size={24}/>, texto: <span className="text-sm font-normal">Me gusta</span>})
        }
        const EnviarComentatio =()=>{
            formik.handleSubmit();
        }

        const getBase64 = (file) => {
            return new Promise((resolve) => {
                let fileInfo;
                let baseURL = "";
                // Make new FileReader
                let reader = new FileReader();
    
                // Convert the file to base64 text
                reader.readAsDataURL(file);
                const regex = /data:.*base64,/;
                //console.log(str1.replace(regex,""))
                // on reader load somthing...
                reader.onload = () => {
                    // Make a fileInfo Object
                    console.log("Called", reader);
                    baseURL = reader.result;
                   // console.log(baseURL);
                    resolve(baseURL.replace(regex, ""));
                };
                console.log(fileInfo);
            });
        };
        const onFileChange = (e) => {
            // setResetImage(false)
            let { file } = stateImage;
    
            file = e.target.files[0];
    
            getBase64(file)
                .then((result) => {
                    file["base64"] = result;
                    setStateImage({
                        base64URL: result,
                        file,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
    
            // setStateImage({
            //     file: e.target.files[0],
            // });
        };

       
        const formik = useFormik({
            initialValues: {
              
              comentario:'',
              existeImage: Boolean(previewMedia)
            },
            // validationSchema: Yup.object({
                
            //      comentario: Yup.string().required("Comentario requerido.")
            //     // existeImage:Yup.boolean(),
            //     // comentario:Yup.string().when('existeImage',(existeImage)=>{
               
            //     // comentario: Yup.string().when([], {
            //     //     is: () => !Boolean(previewMedia),
            //     //     then: Yup.string().required("Comentario requerido.")
            //     //   })

            //   }),
            onSubmit: values => {
                console.log("COMENTARIO",values)
                let contenidoComentario = values.comentario
                let rutaAdjunto = stateImage.file === null ? "":stateImage.file.name;
                let imagenBase64 = stateImage.file === null?"": stateImage.base64URL;
                let idPublicacion = IdPublicacion;
                let jsonComentario = JSON.stringify({ contenidoComentario,idPublicacion,rutaAdjunto,imagenBase64,comentarioPersonasEtiquetadas})
                if(values.comentario.length > 0 || Boolean(previewMedia))
                {
                    //alert(jsonComentario)

                    limpiarComentarios();
                    // RegistrarComentarios({jsonComentario});
                    let jwt = window.localStorage.getItem('jwt');
                    RegistrarComentario({jwt,jsonComentario}).then(data=>{
                        if(idLogin != idUsuario){
                            ObtenerDetalleNotificacion(idUsuario,data.id,data.imagen,contenidoComentario)
                            //sendMessage(idUsuario,data.id,data.imagen,contenidoComentario)
                        }
                        
                        //setComentariosPub(data)

                        getComentarios();
                    })
                }
                
            },
          });

          const onDeleteFile = () => {
              setPreviewMedia(null);
              setStateImage({ file: null, base64URL: "" });
            //   setResetImage(true);
              URL.revokeObjectURL(null);
          };

          const limpiarComentarios=()=>{
            onDeleteFile();
            formik.resetForm();

          }

    

    const handleEmoji=(e)=>{
        formik.setFieldValue("comentario",[formik.values.comentario.slice(0, formik.values.comentario.length), e.emoji, formik.values.comentario.slice(formik.values.comentario.length)].join(''));
    
    }
    const handleEliminarComentario=(id)=>{
        setComentarioSeleccionado(id)
        setMostrarEliminarComentario(true)

    }

    const buscarUsuarios = async (texto) => {
        let match = '';
        const textoLimpio = texto.textValue.trim();
        const palabras = textoLimpio.split(/\s+/);
        let comienzaConArroba = false;
        palabras.map((elemento, index) => {          
            if (elemento.startsWith('@')) {
              comienzaConArroba = true;
            const palabraSinArroba = elemento.slice(1);
            match = palabraSinArroba.toLowerCase();
            }else{
                comienzaConArroba = false;
            }
          });

        if (comienzaConArroba) {
            let jwt = window.localStorage.getItem("jwt");
            ListUsers({jwt}).then(data =>{
                const datanew = data.filter(usuario => usuario.nombre.toLowerCase().includes(match));
                setUsuariosSugeridos(datanew)
            })
        } else {
            setUsuariosSugeridos([]);
        }
      };
      const handleInputChange = (event) => {
        const inputValue = event.htmlValue;
        const nuevoTexto = resaltarHashtags(inputValue);
        formik.setFieldValue("comentario",nuevoTexto)
      };



 
      const resaltarHashtags = (html) => {
       
        if (html?.length >0) {
            const hashtagsRegex = /#[A-Za-z0-9áéíóúÁÉÍÓÚ]+/g;
            const nuevoHtml = html.replace(hashtagsRegex, (match) => {
              return `<span style="color: rgb(29, 155, 240)">${match}</span>`;
            });
            return nuevoHtml;
        } else{
            return html
        }
      };
    const DeleteComentario=()=>{
        setIsLoading(true)
        let jwt = window.localStorage.getItem("jwt");
        let IdComentario = comentarioSeleccionado
        EliminarComentario({jwt,IdComentario}).then(data => 
        {
            setIsLoading(false)
            setMostrarEliminarComentario(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Comentario eliminado.", life: 7000})
            getComentarios()
        })
        .catch(errors => {
            setIsLoading(false)
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            //formik.setSubmitting(false)
            setMostrarEliminarComentario(false)
        })
    }
    const footerDeleteComentario = 
                <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setMostrarEliminarComentario(false)}></Boton>
                    <Boton nombre={"Eliminar"} widths="50%" color="primary" metodoClick={DeleteComentario} 
                    loading = {isLoading}></Boton>
                </div>;
    const footer = <>
        <div id ={"p-eva-cardPublicacion-footer"+objPublicacion.id}className="p-eva-cardPublicacion-footer">
            <div className="cardPublicacion-interacion"  onClick={handleClicMegusta}
                    onMouseEnter={()=>setMostrarReacciones(true)}
                    onMouseLeave={()=>setMostrarReacciones(false)}
                    >
                { reaccionHtmlSeleccionada.icon}
                {reaccionHtmlSeleccionada.texto}
                {/* <Iconsax.Like1 size={24}/>
                <span>Me gusta</span> */}
            </div>
            <div className="cardPublicacion-comentar" onClick={()=>mostrarComentarios ? setMostrarComentarios(false):setMostrarComentarios(true)}>
                <Iconsax.Message size={24}/>
                <span className="text-sm font-normal">Comentar</span>
            </div>
            <Menu model={itemsCompartir} popup ref={menu} style={{width:255}} />
            <div  className="cardPublicacion-compartir" onClick={(event) => menu.current.toggle(event)} >
                <Iconsax.ExportSquare size={24}/>
                <span className="text-sm font-normal">Compartir</span>
            </div>
            <BoxReacciones 
                        //mostrar={true}
                        mostrar={mostrarReacciones} 
                        onMouseLeave={()=>setMostrarReacciones(false)}
                        onMouseEnter = {()=>setMostrarReacciones(true)}
                        lista ={listaInteraccion}
                        onClickReaccion ={handleClicReaccion}
                        idPub = {IdPublicacion}
                ></BoxReacciones>
            
        </div>
        {
            mostrarComentarios &&
                <div className="cardPublicacion-comentarios">
                        {
                            comentariosPub ?
                                comentariosPub.map((item,i)=>{
                                    return(
                                        <Comentarios key={i}
                                            contenido={item.contenidoComentario}
                                            fotoUsuario={item.usuario.foto}
                                            idusuario={item.usuario.id}
                                            nombreUsuario={item.usuario.label}
                                            fechaComentario ={item.fechaRegistro}
                                            nombreImagen={item.rutaAdjunto}
                                            id={item.id}
                                            fechaActual={item.fechaActual}
                                            clicEliminar={()=>handleEliminarComentario(item.id)}
                                            esAdmin={isAdmin}
                                            ></Comentarios>
                                    )
                                }) 
                            : <div>Cargando</div>
                        }
                        <div className="cardPublicacion-comentarios-agregar" style={{marginTop:10,width:"100%"}}>
                            <Avatar  image={perfil && perfil.foto? constantes.URLBLOB_ADJ_AVATAR+"/"+perfil.idUsuario+"/"+perfil.foto : constantes.URLAVATARDEFAULT}
                                size={"large"} style={{marginRight:8,borderRadius:"50%",width:42,minWidth:42}} shape="circle" 
                            />
                            <form onSubmit={formik.handleSubmit} style={{width:"94%"}}>
                                <div style={{position:"relative"}}>
                                <InputPublicacion placeholder={"Escribe un comentario..."} 
                                            id="comentario"
                                            name="comentario"
                                            onChange={(e)=>(handleInputChange(e),buscarUsuarios(e))}
                                            // onChange={(e)=>(formik.setFieldValue("comentario",e.textValue.replace(/\n/g,'')),buscarUsuarios(e))}
                                            // onChange={(e) =>formik.setFieldValue("comentario",e.textValue.replace(/\n/g,''))}
                                            onblur= {formik.handleBlur}
                                            value= {formik.values.comentario}
                                            iconEmoji={<Iconsax.EmojiHappy></Iconsax.EmojiHappy>} onClicEmoji={mostrarEmojis ? ()=>setMostrarEmojis(false) :()=>setMostrarEmojis(true)}
                                            iconRight={<Iconsax.GalleryAdd/>}  onclickIcon={onFileChange}
                                            iconSend={<Iconsax.Send/>} onClickSend={EnviarComentatio}
                                            srcMedia={previewMedia}
                                            srcMediaArreglo={previewMediaArregloImg}
                                             isValid ={formik.isValid}
                                            //isValid ={formik.values.comentario.length >0 || Boolean(previewMedia)}
                                            isDirty={formik.dirty}
                                            widthRight={8}
                                            keys ={keys}
                                            clicDeleteImg={onDeleteFile}
                                ></InputPublicacion>
                                {mostrarEmojis&& <div style={{position:"absolute",zIndex:1,transform:"translateX(220px)"}} onMouseLeave={()=>setMostrarEmojis(false)} onFocus={()=>setMostrarEmojis(true)}><EmojiPicker  emojiStyle="google" onEmojiClick={handleEmoji}></EmojiPicker></div>} 
                                {usuariosSugeridos.length > 0 && (
                                        // <UsuariosSugeridos usuarios={usuariosSugeridos} formikProps={formik}  />

                                        <UsuariosSugeridos 
                                        usuarios={usuariosSugeridos} 
                                        recibirDatosUsuariosEtiquetados = {recibirDatosUsuariosEtiquetados}
                                        formikProps={formik} 
                                        />
                                    )}

                                </div>
                                
                                {/* <input id="file-input" type="file" onChange={onFileChange} style={{display:"none"}}  ></input> */}

                            </form>
                            
                        </div>
                   
                    
                </div>
        }
    </>
        
        
    ;
    const SeleccionarGrupo =(idGs,nombreGs) =>
    {
        setGrupoSeleccionado({idGrupoS:idGs,nombreGrupoS:nombreGs})
        setMostrarCrearCompartir(true); 
        setMostrarCompartir(false)
    }

   

    const handleMouseover  =() => {

        console.log("ID: "+objPublicacion.id+" - se vió")
        updateVista()
    }



   
    


    const arreglo = imagenes?.map(imagen => imagen.nombre);

   
    
   
    return ( 
        <div className="p-eva-dashboard-publicacion" onMouseEnter={()=>{
            // setTimeout(() => {
            //     
            // }, 5000)
            if(!vistaPublicacion)handleMouseover()
        }}>
            <ToastEva ref={toast}></ToastEva>
            <Card header={header} footer={footer} className="p-eva-cardPublicacion" 
                style={{opacity: objPublicacion.habilitado ? 1:0.3,pointerEvents:objPublicacion.habilitado ?"auto":"none"}}
                
                >
                <div className="p-eva-cardPublicacion-contenido p-eva-text" style={{whiteSpace:"pre-line",color:"#000000"}}
                    dangerouslySetInnerHTML={{ __html: contenido }}
                >
                    
                    
                </div>
                <div className="p-eva-cardPublicacion-link">
                    <a  className="p-eva-labelForm-orange" target="_blank" href={link}>{nombreLink}</a>
                </div>

               
                {
                    imagenes?.length > 0  &&
              
                     <div>
                        <PreviewCarousel urlFile={constantes.URLBLOB_PUBLICACIONES+"/"+IdPublicacion+"/"} imagenes={arreglo} />
                    </div> 
                }
               
                {
                    objPublicacion.rutaVideo && 
                    <ReactPlayer url={objPublicacion.rutaVideo}  width="100%"/>
                }
                {/* {
                    objPublicacion && objPublicacion.idPublicacionCompartida && <CardPublicacionCompartida publicacion={objPublicacion.publicacionCompartida}></CardPublicacionCompartida>
                } */}
                
                
                <div className="p-eva-interacion-vistas">
                    <div className="p-eva-cardPublicacion-detalleInteraccion" onClick={()=>setMostrarInteraccionesPub(true)}  style={{display:"inline-flex",alignItems:"center",marginTop:16,cursor:"pointer"}}>
                        {totalReacciones > 0?detalleInteracciones.length > 0 && 
                            <div className="detalleInteraccion-reacciones">
                                {
                                    detalleInteracciones.map((item,i)=>{
                                        return(<img key={i} src={"../../../../images/inicio/"+item.imagen} width={24} style={{margin:2}}></img>)
                                    })
                                }
                            </div>:null
                        }
                        {totalReacciones > 0 && <div className="detalleInteraccion-total" style={{marginLeft:6,color:"#F47647",fontSize:14,fontWeight:400}}>{totalReacciones}</div>}
                    </div>
                    <div className="p-eva-cardPublicacion-vistas">
                        <Iconsax.Eye color="#B5B5B5" size={24}></Iconsax.Eye>
                        <div style={{color:"#B5B5B5"}}>{objPublicacion.numeroVistas}</div>
                    </div>
                </div>
            </Card>
           
             {visibleModal&&
                <PreviewMedia visibleModal={visibleModal} urlFile={constantes.URLBLOB_PUBLICACIONES+"/"+IdPublicacion+"/"+arreglo[0]} imagenes={arreglo}
                onHide={()=>setVisibleModal(false)}></PreviewMedia>
            }
            
            
            
            {mostrarCompartir && <Compartir mostrar={mostrarCompartir} onHide={()=>setMostrarCompartir(false)} 
                                    grupos={gruposUsuario} objPublicacion={objPublicacion} 
                                    clickCompartir={SeleccionarGrupo}></Compartir> }

            {mostrarCrearCompartir && <CrearCompartir  mostrar={mostrarCrearCompartir} onHide={()=>setMostrarCrearCompartir(false)}publicacion={objPublicacion}  
                                        IdGrupo={grupoSeleccionado.idGrupoS} nombrGrupo={grupoSeleccionado.nombreGrupoS}></CrearCompartir>}

            {mostrarInteraccionesPub && <Interacciones mostrar={mostrarInteraccionesPub} 
                                        onHide={()=>setMostrarInteraccionesPub(false)}
                                        lista={detalleInteracciones}
                                        total={totalReacciones}
                                        ></Interacciones>}
            {
                mostrarEliminarComentario && 
                <Dialog visible={mostrarEliminarComentario} modal  onHide={()=>setMostrarEliminarComentario(false)} 
                    footer={footerDeleteComentario}  className ="p-eva-dialogDelete"
                    >
                    
                    <label htmlFor="confirmDelete">¿Quieres eliminar comentario?</label>
                    {/* <div className="peva-label">
                        
                    </div> */}
                </Dialog>
            }
        </div>
        
     );
}
 
export default CardPublicacion;