import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import CardReglamento from "../../../components/Reglamento/CardReglamento";
import useSomosin from "../../../hooks/useSomosin";
import { Skeleton } from "primereact/skeleton";
import "./Reglamento.scss";
import { RegistrarAcceso } from "../../../service/InteraccionService";
import { ObtenerReglamentosPorGerencia, EliminarReglamentoPorId } from "../../../service/SomosinService";
import UsePermisos from "../../../hooks/usePermisos";
import useUsuario from "../../../hooks/useUsuario";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import { Dialog } from "primereact/dialog";
import * as Iconsax from "iconsax-react";
import { ObtenerReporte, ObtenerReglamentoGerenciaPorId,ListarReglamentos } from "../../../service/SomosinService";

import * as constantes from "../../../constants/constantes";
const Reglamento = () => {
    const [checked, setChecked] = useState(false);
    // const { GetReglamentoPorSeccion, reglamentoPorSeccion } = useSomosin();
    const [reglamentoPorSeccion, setReglamentoPorSeccion] = useState(null);
    const [arrayReglamentos, setArrayReglamentos] = useState(null);
    const [permisoEdit, setPermisoEdit] = useState(false);
    const { permisos, isLogged } = useUsuario();
    const [reglamentoSeleccionado, setReglamentoSeleccionado] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reglamentoGerencia, setReglamentoGerencia] = useState(null);


    const navigate = useNavigate();
    const toast = useRef(null);

    let content = "Conoce tus derechos y obligaciones, así como los requisitos y procedimientos que deberás tomar en cuenta para dictar tus clases con éxito.";

    useEffect(() => {
        !isLogged && navigate("/");
    }, []);

    



    let { idGerencia } = useParams();
    // useEffect(()=>{
    //     const GuardarAcceso = async () => {
    //         let jwt = window.localStorage.getItem("jwt");
    //         let seccion = "SomosIn/Politicas y reglamentos"
    //         let url = window.location.href;
    //         let jsonAcceso = JSON.stringify({seccion,url},null,2)
    //         await RegistrarAcceso({jsonAcceso,jwt});
    //     };

    //     GuardarAcceso();
    // },[])

    useEffect(() => {
        const AccionPermisos = () => {
            permisos.indexOf("editarReglamentos") > -1 ? setPermisoEdit(true) : setPermisoEdit(false);
        };
        if (permisos.length > 0) {
            permisos.indexOf("verReglamentos") > -1 ? AccionPermisos() : navigate("/");
        }
    }, [permisos]);

    const ObtenerSeccionPorReglamento = async () => {
        let id = idGerencia;
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerReglamentosPorGerencia({ id, jwt }).then((data) => {
            setReglamentoPorSeccion(data);
        });
    };

    const getReglamentoGerenciaId = async () => {
        let id = idGerencia;
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerReglamentoGerenciaPorId({ id, jwt }).then((data) => {
            setReglamentoGerencia(data);
        });
    };
    useEffect(() => {
        if (idGerencia) {
            ObtenerSeccionPorReglamento();
            getReglamentoGerenciaId();
        }
    }, [idGerencia]);

    useEffect(() => {
        if (reglamentoPorSeccion) setArrayReglamentos(reglamentoPorSeccion);
        console.log("Reglamentos",reglamentoPorSeccion)
    }, [reglamentoPorSeccion]);

    const Eliminar = () => {
        let jwt = window.localStorage.getItem("jwt");
        let id = reglamentoSeleccionado;
        EliminarReglamentoPorId({ id, jwt })
            .then((data) => {
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Success", detail: "Registro eliminado exitosamente.", life: 7000 });
                setShowDialog(false);
                ObtenerSeccionPorReglamento();
                // setTimeout(() => {
                //     navigate(-1);
                // }, 3000)
            })
            .catch((errors) => {
                toast.current.show({ severity: "error", summary: "Error", detail: errors.message, life: 7000 });
                setIsLoading(false);
            });
    };

    const handleDownload = () => {
        let jwt = window.localStorage.getItem("jwt");
        let id = idGerencia;
        ObtenerReporte({ id, jwt }).then((data) => {
            var fechaActual = new Date();
            let nombre = reglamentoGerencia.nombre;

            fetch(constantes.URLBLOB_REPORTE_REGLAMENTO + "/ReglamentoPorUsuarios_" + nombre.replace(/ /g, "_")).then((response) => {
                response.blob().then((blob) => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = "ReglamentoPorUsuarios_" + nombre.replace(/ /g, "_") + ".xlsx";
                    alink.click();
                });
            });
        });
    };

    const footerDelete = (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={() => setShowDialog(false)}></Boton>
            <Boton nombre={"Eliminar Sección"} widths="50%" color="primary" metodoClick={Eliminar} loading={isLoading}></Boton>
        </div>
    );
    return (
        <div className="eva-dashboard-reglamento" style={{ marginLeft: 40, width: 1082, margin: "Auto", color: "#000000" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header header-agrupado">
                <div className="header">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    <label htmlFor="header">{reglamentoGerencia && reglamentoGerencia.nombre}</label>
                </div>
                {!permisoEdit && (
                    <div className="header-right">
                        <Link to="#" onClick={handleDownload}>
                            Descargar reporte
                        </Link>
                    </div>
                )}

                {permisoEdit && (
                    <div className="header-right">
                        <Link to="#" onClick={handleDownload}>
                            Descargar reporte
                        </Link>
                        <Link to={"Crear"}>Añadir nueva sección</Link>
                    </div>
                )}
            </div>
            <div className="eva-dashboard-reglamento-body">
                <div className="p-fluid formgrid grid">
                    {arrayReglamentos ? (
                        arrayReglamentos.length > 0 ? (
                            arrayReglamentos.map((itemSeccion, i) => {
                                if (itemSeccion.listaReglamento.length > 0) {
                                    return (
                                        

                                        <div className="field col-12 md:col-12" key={i}>
                                            <div className="reglamento-body-tituloSeccion">{itemSeccion.nombreSeccion}</div>
                                            <div className="row">
                                                {itemSeccion.listaReglamento.map((item, j) => {
                                                    return (
                                                        <div className="field col-12 md:col-6" style={{ display: 'inline-block', width: '50%' }} key={j}>
                                                            <CardReglamento
                                                                titulo={item.nombre}
                                                                contenido={item.descripcion}
                                                                idReglamento={item.id}
                                                                accept={item.aceptaTermino ? true : false}
                                                                urlPdf={item.nombreAdjunto}
                                                                displayEdit={permisoEdit ? "block" : "none"}
                                                                eliminar={() => {
                                                                    setReglamentoSeleccionado(item.id);
                                                                    setShowDialog(true);
                                                                }}
                                                            ></CardReglamento>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                    );
                                }
                            })
                        ) : (
                            <div>No existe data</div>
                        )
                    ) : (
                        <div className="p-fluid formgrid grid" style={{ width: "100%" }}>
                            <div className="field col-12 md:col-6">
                                <Skeleton width="90%" className="mb-4"></Skeleton>
                                <Skeleton width="90%" height="45%" className="mb-4"></Skeleton>
                                <Skeleton width="70%" className="mb-4"></Skeleton>
                                <div className="flex justify-content-between" style={{ width: "90%" }}>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                </div>
                            </div>
                            <div className="field col-12 md:col-6">
                                <Skeleton width="90%" className="mb-4"></Skeleton>
                                <Skeleton width="90%" height="45%" className="mb-4"></Skeleton>
                                <Skeleton width="70%" className="mb-4"></Skeleton>
                                <div className="flex justify-content-between" style={{ width: "90%" }}>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                </div>
                            </div>
                            <div className="field col-12 md:col-6">
                                <Skeleton width="90%" className="mb-4"></Skeleton>
                                <Skeleton width="90%" height="45%" className="mb-4"></Skeleton>
                                <Skeleton width="70%" className="mb-4"></Skeleton>
                                <div className="flex justify-content-between" style={{ width: "90%" }}>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                </div>
                            </div>
                            <div className="field col-12 md:col-6">
                                <Skeleton width="90%" className="mb-4"></Skeleton>
                                <Skeleton width="90%" height="45%" className="mb-4"></Skeleton>
                                <Skeleton width="70%" className="mb-4"></Skeleton>
                                <div className="flex justify-content-between" style={{ width: "90%" }}>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                    <Skeleton width="35%" height="2rem"></Skeleton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Dialog visible={showDialog} modal onHide={() => setShowDialog(false)} footer={footerDelete} className="p-eva-dialogDelete">
                <label htmlFor="confirmDelete">¿Quieres eliminar esta sección?</label>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>
        </div>
    );
};

export default Reglamento;
