// import React, { useState,useRef,useEffect } from 'react';
// import { InputText } from 'primereact/inputtext';
// import "./InputIcon.scss"
// import classNames from "classnames";
// import * as Iconsax from "iconsax-react";
// const InputIcon = ({id,type,display,placeholder,value,name,onChange,onBlur,accept,onClickIcon,OnClickDelete,icon,className,errorClass,width,modo="default",reset=false,onkeyDown,height,disabled,Imultiple=false})=>{
//     const aref = useRef(null)
//     console.log("placeholder",placeholder)
//     console.log( "VALORRRMUL",valueMultiple);

//     useEffect(()=>{
//         aref.current.value = null;
//         console.log("aref.current.value",aref.current.value)
        
//     },[reset])

 
//     const inputClassName = classNames("p-eva-inputIcon", {
//         "white":className === "white" ? true:false,
//         "grey" : className === "grey" ? true:false,
//         "p-eva-inputIcon-error" : errorClass === "error" ? true:false,
//         "p-eva-inputIcon-success" : errorClass === "success" ? true:false,
//         "p-eva-inputSearch" : className ==="type-search" ? true:false
//     });
//     return (
//         <span className="p-input-icon-right p-eva-input-icon-right" style={{width:"100%",display:display}}>
//             <InputText 
//                 ref={aref}
//                 id={id}
//                 type={type} 
//                 placeholder={placeholder} 
//                 className = {inputClassName}
//                 value = {value}
//                 name = {name}
//                 onChange = {onChange}
//                 onBlur = {onBlur}
//                 accept = {accept}
//                 style={{width:width,boxShadow:"none",paddingRight:0,height:height}}
//                 onKeyDown={onkeyDown}
//                 disabled={disabled}
//                 // hidden = {hidden}
//                 //Para multiple eleccion
//                 multiple ={Imultiple}
//             />


//             {
//                 modo==="delete" ? 
//                 <i onClick={OnClickDelete} className="p-eva-input-iconDelete"><Iconsax.Add></Iconsax.Add></i>
//                 :
//                 <i onClick={onClickIcon}>{icon}</i>
//             }
//         </span>
//     )
// }
// export default InputIcon;

import React, { useState,useRef,useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import "./InputIcon.scss"
import classNames from "classnames";
import * as Iconsax from "iconsax-react";
const InputIcon = ({id,type,display,placeholder,value,name,onChange,onBlur,accept,onClickIcon,OnClickDelete,icon,className,errorClass,width,modo="default",reset=false,onkeyDown,height,disabled,Imultiple=false,valueMultiple})=>{
    const aref = useRef(null)
    console.log("placeholder",placeholder)

    console.log( valueMultiple);
    useEffect(()=>{
        aref.current.value = null;
        console.log("aref.current.value",aref.current.value)

        if (valueMultiple) {
            console.log( valueMultiple);
        }

    },[reset])
    const inputClassName = classNames("p-eva-inputIcon", {
        "white":className === "white" ? true:false,
        "grey" : className === "grey" ? true:false,
        "p-eva-inputIcon-error" : errorClass === "error" ? true:false,
        "p-eva-inputIcon-success" : errorClass === "success" ? true:false,
        "p-eva-inputSearch" : className ==="type-search" ? true:false,
        "input-file-hidden" : Imultiple
    });

    return (
        <span className="p-input-icon-right p-eva-input-icon-right" style={{width:"100%",display:display, backgroundColor:"#F5F5F5",borderRadius:"15px"}}>
            <InputText
                ref={aref}
                id={id}
                type={type}
                placeholder={placeholder}
                className = {inputClassName}
                value = {value}
                name = {name}
                onChange = {onChange}
                onBlur = {onBlur}
                accept = {accept}
                style={{width:width,boxShadow:"none",paddingRight:0,height:height}}
                onKeyDown={onkeyDown}
                disabled={disabled}
                // hidden = {hidden}
                //Para multiple eleccion
                multiple ={Imultiple}

            />
            {Imultiple && (<label htmlFor={id}  className="custom-file-upload">
            {valueMultiple.length === 0
          ? 'Ningun archivo select.'
          : `${valueMultiple.length}  archivo${valueMultiple.length>1?'s': ''}.`}
            </label>)}
            {
                modo==="delete" && valueMultiple.length > 0  ?
                <i onClick={OnClickDelete} className="p-eva-input-iconDelete"><Iconsax.Add></Iconsax.Add></i>
                :
                <i onClick={onClickIcon}>{icon}</i>
            }


        </span>


    )
}
export default InputIcon;