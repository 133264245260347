import { registry } from 'chart.js';
import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import {Registrar,Actualizar}from "../service/InteraccionService";
export default function useInteraccion(){
    const {perfil,jwt,setPerfil,setJwt} = useContext(Context)
    const [stateCreate,setStateCreate] = useState({loading:false,error:false,success:false});
    const [stateUpdate, setStateUpdate] = useState({loading:false,error:false,success:false});
    const RegistrarInteraccion = useCallback(({jsonInteraccion})=>{
        Registrar({jsonInteraccion,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
        })
    },[setStateCreate])

    const ActualizarInteraccion = useCallback(({jsonInteraccion})=>{
        setStateUpdate({loading:false,error:false,success:false})
        Actualizar({jsonInteraccion,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateUpdate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateUpdate({loading:false,error:true,success:false})
        })
    },[setStateUpdate])
    return{
        RegistrarInteraccion,
        hasCreateError : stateCreate.error,
        isCreateLoading: stateCreate.loading,
        isCreated : stateCreate.success,
        ActualizarInteraccion,
        hasUpdateError : stateUpdate.error,
        isUpdateLoading: stateUpdate.loading,
        isUpdate : stateUpdate.success
    }
}
