import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionOpcional, ListarSeccionCapacitacion, ObtenerCapacitacionPorGerencia } from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import "./CapaOpcional.scss";
import Boton from "../../../components/Boton/Boton";
import * as constantes from "../../../constants/constantes";
import CardEntry from "../../../components/CardEntryEva/CardEntry";
import DropdownFiltro from "../../../components/DropdownFiltro/DropdownFiltro";
import CardEntryImg from "../../../components/CardEntryEva/CardEntryImg";
import { ObtenerGerenciaPorId } from "../../../service/GerenciaService";
import { getYears } from "../../../helpers/helpers";

const CapaOpcional = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const { permisos, isLogged } = useUsuario();
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [capaOpcional, setCapaOpcional] = useState(null);
    const [capaOpcionalTemp, setCapaOpcionalTemp] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { IdGerencia } = useParams();
    const [filtroSeleccionado, setFiltroSeleccionado] = useState(null);
    const [listaAnios, setListaAnios] = useState(null);

    useEffect(() => {
        !isLogged && navigate("/");
    }, []);

    useEffect(() => {
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarCapacitacionesNoObligatorias") > -1) {
                setPermisoEditar(true);
            }
        };
        if (permisos.length > 0) {
            permisos.indexOf("verCapaIntroduccion") > -1 ? AccionPermisos() : navigate("/");
        }
    }, [permisos]);

    const filtro = [{ value: 1, name: "Periodo" }];

    useEffect(() => {
        let anios = getYears(1990)
        let todo = ["Todos"]
        setListaAnios(todo.concat(anios))
    }, []);

    // useEffect(() => {
    //     const obtenerListaSeccionCapa = async () => {
    //         let jwt = window.localStorage.getItem("jwt");
    //         await ListarSeccionCapacitacion({ jwt }).then((data) => {
    //             let temp = data.filter((x) => x.id == idSeccion);

    //             setTituloPagina(temp[0].nombre);
    //         });
    //     };
    //     if (idSeccion) {
    //         obtenerListaSeccionCapa();
    //     }
    // }, [idSeccion]);

    useEffect(() => {
        const getGerencia = async () => {
            let jwt = window.localStorage.getItem("jwt");
            let Id = IdGerencia;
            await ObtenerGerenciaPorId({ Id, jwt }).then((data) => {
                //setGerencia(data);
                if(data)
                {
                    setTituloPagina(data.nombre);
                }
                else{
                    setTituloPagina("Generales");
                }
                
            });
        };
        if (IdGerencia) getGerencia();
    }, [IdGerencia]);

    useEffect(() => {
        const getCapaGerencias = async () => {
            let jwt = window.localStorage.getItem("jwt");
            let Id = IdGerencia;
            await ObtenerCapacitacionPorGerencia({ jwt, Id }).then((data) => {
                setCapaOpcional(data);
                setCapaOpcionalTemp(data);
            });
        };
        if (!capaOpcional) getCapaGerencias();
    }, [IdGerencia]); //;

    // useEffect(()=>{
    //     const getCapaOpcional = async()=>{
    //         let jwt = window.localStorage.getItem("jwt");
    //         ObtenerCapacitacionOpcional({jwt}).then(data=>{

    //             setCapaOpcional(data)
    //         })
    //         .catch(errors => {
    //             toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

    //         })
    //     }
    //     if(!capaOpcional)getCapaOpcional();
    // },[])

    const hadleButtonCard = (id) => {
        navigate("../Capacitacion/Seccion/" + idSeccion + "/Gerencia/" + IdGerencia + "/DetalleOpcional/" + id);
    };
    const handleFiltro = (e) => {
        //console.log(e)
        setFiltroSeleccionado(e.value);
        if(e.value == "Todos")
        {
            setCapaOpcional(capaOpcionalTemp);
        }
        else{
            let temp = capaOpcionalTemp.filter(x=>x.anio === e.value)
            setCapaOpcional(temp);
        }
       
    };
    return (
        <div className="eva-capaOpcional" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header header-agrupado">
                <div className="header">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                </div>

                {permisoEditar && (
                    <div className="header-right">
                        <Link to={"../Capacitacion/Seccion/" + idSeccion + "/Gerencia/" + IdGerencia + "/EditarGerencia"}>Editar</Link>
                        <Link to={"../Capacitacion/Seccion/" + idSeccion + "/Gerencia/" + IdGerencia + "/CrearOpcional"}>Crear nueva capacitación</Link>
                    </div>
                )}
            </div>
            <div className="p-eva-dashboard-capaOpcional" style={{ marginTop: 16 }}>
                <div className="capaOpcional-desccripción">{capaOpcional && capaOpcional.length >0 && capaOpcional[0].descripcion}</div>
                <div className="capaObligatoria-filtro" style={{ marginBottom: 24 }}>
                    <DropdownFiltro value={filtroSeleccionado} onChange={handleFiltro} options={listaAnios}     placeholder="Selecciona filtro" style={{ width: 286 }}></DropdownFiltro>
                </div>
                <div className="capaOpcional-card">
                    {capaOpcional ? (
                        <div className="p-fluid formgrid grid">
                            {capaOpcional.map((item, i) => {
                                return (
                                    <div className="col-12 md:col-4" key={i}>
                                        <CardEntryImg tituloCard={item.nombre} nombreBoton="Ver más" imagenUrl={item.nombreFoto ? constantes.URLBLOB_CAPAOPCIONAL_HEADER + "/" + item.nombreFoto : "../../../../../images/inicio/ondas.png"} opacity={1} onClicButton={() => hadleButtonCard(item.id)} />
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <Skeleton />
                    )}

                    {/* {capaOpcional ? (
                        <>
                        {
                            capaOpcional.general.length > 0 && 
                            <>
                                <div className="header-subtitle">Generales</div>
                                <div className="p-fluid formgrid grid">
                                    {capaOpcional.general.map((item,i) => {
                                        return (
                                            <div className="col-12 md:col-3" key={i}>
                                                <CardEntry tituloCard={item.nombre} nombreBoton="Ver más" imagenUrl={"../../../../images/inicio/ondas.png"} opacity={1} onClicButton={() => hadleButtonCard(item.id)} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        }
                        {
                            capaOpcional.gerencias.length > 0 && 
                        
                                <div className="p-fluid formgrid grid" style={{marginTop:16}}>
                                    {
                                        capaOpcional.gerencias.map((item,i) => {
                                        return (
                                            <>
                                                <div className="header-subtitle col-12 md:col-12" key={i} style={{marginTop:16}}>{item.nombre}</div>
                                                {
                                                    item.listaCapacitaciones.map((capa,j)=>{
                                                        return(
                                                            <div className="col-12 md:col-3" key={j}>
                                                                <CardEntry tituloCard={capa.nombre} nombreBoton="Ver más" imagenUrl={"../../../../images/inicio/ondas.png"} opacity={1} onClicButton={() => hadleButtonCard(capa.id)} />
                                                            </div>
                                                        )
                                                        
                                                    })
                                                }
                                            </>
                                            
                                            
                                        );
                                        })
                                    }
                                </div>
                        
                        }
                        </>
                        
                        
                        
                    ) : (
                        <Skeleton />
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default CapaOpcional;
