import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
import { ObtenerOnoarding ,ObtenerListaSeccionClima,ActualizarOnboarding} from "../../../service/ClimaService";
import { getBase64 } from "../../../helpers/helpers";
import classNames from "classnames";
import { InputSwitch } from "primereact/inputswitch";


const EditarOnboarding = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState(null);
    const [modoEdicion, setModoEdicion] = useState(true);
    const [onboarding, setOnboarding] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();

    
    useEffect(()=>{
        const obtenerListaSeccionClima = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccionClima);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccionClima){
            obtenerListaSeccionClima();
        } 
    },[idSeccionClima])

    useEffect(()=>{
        const GetOnboarding = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerOnoarding({jwt}).then(data=>{
                setOnboarding(data)
            })
        }
        if(!onboarding)GetOnboarding();

    },[])

    const Actualizar =({jsonClima})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarOnboarding({jsonClima,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }

    const schema = Yup.object().shape({
        descripcion: Yup.string().required("Campor descripción requerido."),
        documentos: Yup.array()
        .of(
            Yup.object().shape({
                nombreProposito: Yup.string().nullable().required("Campo nombre de propósito requerido."),
                nombreFoto : Yup.string().nullable().required("Campo foto requerido."),
                descripcionProposito :Yup.string().nullable().required("Campo descripción requerido.")
            })
        )
    });

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:modoEdicion && onboarding?onboarding.id:0,
            descripcion: modoEdicion  && onboarding?onboarding.descripcion:"",
            descripcionWebOnboarding: modoEdicion  && onboarding?onboarding.descripcionWebOnboarding:"",
            descripcionInlearning: modoEdicion  && onboarding?onboarding.descripcionInlearning:"",
            activoWebOnboarding : modoEdicion && onboarding?onboarding.activoWebOnboarding:false,
            activoInlearning: modoEdicion && onboarding?onboarding.activoInlearning:false,
            activoVideo:modoEdicion && onboarding?onboarding.activoVideo:false,
            linkProposito : modoEdicion  && onboarding?onboarding.linkProposito:"",
            Titulo : modoEdicion  && onboarding?onboarding.titulo:"",
            SubTitulo1 : modoEdicion  && onboarding?onboarding.subTitulo1:"",
            SubTitulo2 : modoEdicion  && onboarding?onboarding.subTitulo2:"",
            SubTitulo3 : modoEdicion  && onboarding?onboarding.subTitulo3:"",
            SubTitulo4 : modoEdicion  && onboarding?onboarding.subTitulo4:"",
            documentos: modoEdicion  && onboarding && onboarding.clima_onboarding_documentos.length >0? onboarding.clima_onboarding_documentos.filter(x=>x.estado == true) : 
                [{imagenBase64:null,tipoDocumento:null,id:0,idClimaOnboarding:0,nombreProposito:'',nombreFoto:'',descripcionProposito:"",estado:true,fechaEliminacion:null,usuarioEliminacion:null}],
        },
      validationSchema: schema,
      onSubmit: values => {
        let esValido =true;

         let id = values.id
         let descripcion = values.descripcion
         let descripcionWebOnboarding = values.descripcionWebOnboarding
         let descripcionInlearning = values.descripcionInlearning
         let activoWebOnboarding = values.activoWebOnboarding
         let activoInlearning = values.activoInlearning
         let activoVideo= values.activoVideo
         let listaDocCompleto = values.documentos;
         let linkProposito =values.linkProposito;
         let Titulo =values.Titulo;
         let SubTitulo1 =values.SubTitulo1;
         let SubTitulo2 =values.SubTitulo2;
         let SubTitulo3 =values.SubTitulo3;
         let SubTitulo4= values.SubTitulo4;
         let jsonClima = JSON.stringify({id,descripcion,descripcionWebOnboarding,descripcionInlearning,
            activoWebOnboarding,activoInlearning,activoVideo,listaDocCompleto,linkProposito,Titulo,SubTitulo1,SubTitulo2,SubTitulo3,SubTitulo4},null,2)
        //  console.log(jsonCapacitacion)
        // formik.setSubmitting(false)
        // alert(jsonClima);
         Actualizar({jsonClima})
      },
    });

    const validarLimitePropositos=()=>{
        let numeroPropositos = formik.values.documentos.length;
        if(numeroPropositos <=5) return true
        else{return false}
    }
    return (
        <div className="eva-dashboard-editarOnboarding" style={{ marginLeft: 40 }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{"Editar " + tituloPagina}</label>}</div>
                {/* <div><label htmlFor="header">{"Editar " + formik.values.Titulo}</label></div> */}

            </div>
            {/* <div className="field col-12 md:col-12">
                <label htmlFor="nombre">Agrega el título</label>
                <InputDefault
                    id="Titulo"
                    name="Titulo"
                    className="white"
                    placeholder="Escribe aquí"
                    errorClass={formik.touched.Titulo!= undefined && (formik.errors.Titulo ? "error" : "success")}
                    value={formik.values.Titulo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <small className="p-error">{formik.touched.Titulo && formik.errors.Titulo}</small>
            </div> */}
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                            </div>
                            {/* <div className="field col-12 md:col-12 header-subtitle">Nuestro propósito y valores</div> */}
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo1}</div>

                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el Subtítulo</label>
                                <InputDefault
                                    id="SubTitulo1"
                                    name="SubTitulo1"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo1!= undefined && (formik.errors.SubTitulo1 ? "error" : "success")}
                                    value={formik.values.SubTitulo1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                
                                <small className="p-error">{formik.touched.SubTitulo1 && formik.errors.SubTitulo1}</small>
                            </div>
                            <FieldArray
                                name="documentos"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className="field col-12 md:col-12">
                                            {formik.values.documentos &&
                                                formik.values.documentos.map((documento, index) => (
                                                    <div className="p-fluid formgrid grid" key={index}>
                                                        {index != 0 && <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>}

                                                        <div className="field col-12 md:col-4">
                                                            <label htmlFor={`documentos[${index}].nombreProposito`}>Añade el nombre del primer propósito</label>

                                                            <InputDefault
                                                                id={`documentos[${index}].nombreProposito`}
                                                                name={`documentos[${index}].nombreProposito`}
                                                                className="white"
                                                                errorClass={formik.errors.documentos && formik.errors.documentos[index] && formik.errors.documentos[index].nombreProposito != undefined && (formik.errors.documentos[index].nombreProposito ? "error" : "success")}
                                                                placeholder="Escribe aquí"
                                                                value={formik.values.documentos[index].nombreProposito}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            <small className="p-error">{formik.errors.documentos && formik.errors.documentos[index] && formik.errors.documentos[index].nombreProposito}</small>
                                                        </div>
                                                        <div className="field col-12 md:col-4">
                                                            <label htmlFor={`documentos[${index}].nombreFoto`}>Adjunta una foto</label>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <label
                                                                    className={classNames("eva-labelFileLider", {
                                                                        "p-eva-error": formik.errors.documentos && formik.errors.documentos[index] && formik.errors.documentos[index].nombreFoto != undefined && formik.errors.documentos[index].nombreFoto,
                                                                    })}
                                                                    style={{ minHeight: 16, height: "fit-content" }}
                                                                >
                                                                    <span>{formik.values.documentos[index].nombreFoto.length > 0 ? formik.values.documentos[index].nombreFoto : "Adjunta aquí"}</span>
                                                                </label>
                                                                {formik.values.documentos[index].nombreFoto != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => {
                                                                                {
                                                                                    formik.setFieldValue(`documentos[${index}].nombreFoto`, "");
                                                                                    formik.setFieldValue(`documentos[${index}].imagenBase64`, null);
                                                                                }
                                                                                
                                                                        }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`documentos[${index}].nombreFoto`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.GalleryAdd color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`documentos[${index}].nombreFoto`}
                                                                    name={`documentos[${index}].nombreFoto`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`documentos[${index}].nombreFoto`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                            <small className="p-error">{formik.errors.documentos && formik.errors.documentos[index] && formik.errors.documentos[index].imagenBase64}</small>
                                                        </div>
                                                        <div className="field col-12 md:col-12">
                                                            <label htmlFor={`documentos[${index}].descripcionProposito`} className="p-eva-labelForm">
                                                                Añade una descripción para el primer propósito
                                                            </label>
                                                            <TextareaDefault
                                                                id={`documentos[${index}].descripcionProposito`}
                                                                minHeight={180}
                                                                placeholder="Escribe aquí"
                                                                className="White"
                                                                errorClass={formik.errors.documentos && formik.errors.documentos[index] && formik.errors.documentos[index].descripcionProposito != undefined && (formik.errors.documentos[index].descripcionProposito ? "error" : "success")}
                                                                value={formik.values.documentos[index].descripcionProposito}
                                                                icon={<Iconsax.DocumentText1 />}
                                                                name={`documentos[${index}].descripcionProposito`}
                                                                onChange={formik.handleChange}
                                                                onblur={formik.handleBlur}
                                                            />
                                                            <small className="p-error">{formik.errors.documentos && formik.errors.documentos[index] && formik.errors.documentos[index].descripcionProposito}</small>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        {validarLimitePropositos() && (
                                            <div className="field col-12 md:col-12">
                                                <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "center" }}>
                                                    <i
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idClimaOnboarding: 0, nombreProposito: "", nombreFoto: "", descripcionProposito: "", estado: false, fechaEliminacion: null, usuarioEliminacion: null });
                                                            // setNumeroPregunta(numeroPregunta+1)
                                                        }}
                                                    >
                                                        Añadir más
                                                    </i>
                                                </div>
                                            </div>
                                        )}
                                        <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>
                                    </>
                                )}
                            ></FieldArray>
                            {/* <div className="field col-12 md:col-12 header-subtitle">Un solo propósito (video)</div> */}
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo4}</div>
                            <div className="field col-10 md:col-10">
                                <label htmlFor="nombre">Agrega el Subtítulo</label>
                                <InputDefault
                                    id="SubTitulo4"
                                    name="SubTitulo4"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo4!= undefined && (formik.errors.SubTitulo4 ? "error" : "success")}
                                    value={formik.values.SubTitulo4}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo4 && formik.errors.SubTitulo4}</small>
                            </div>
                            <div className="field col-12 md:col-2"style={{display:"flex",alignItems:"center",justifyContent:"end"}}>
                                <InputSwitch
                                    id={"activoVideo"}
                                    name={"activoVideo"}
                                    checked={formik.values.activoVideo}
                                    onChange={formik.handleChange}
                                ></InputSwitch>
                            </div>
                            {/* <div  className="field col-12 md:col-12"> */}
                            <div className={classNames("field col-12 md:col-12",{"onboarding-seccion-opacity" : !formik.values.activoVideo})}>

                                <label htmlFor="nombre">Agrega link del video</label>
                                <InputDefault
                                    id="linkProposito"
                                    name="linkProposito"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.linkProposito != undefined && (formik.errors.linkProposito ? "error" : "success")}
                                    value={formik.values.linkProposito}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.linkProposito && formik.errors.linkProposito}</small>
                            </div>
                           

                            {/* <div className={classNames("field col-12 md:col-10 header-subtitle",{"onboarding-seccion-opacity" : !formik.values.activoWebOnboarding})}>{configuraciones.length > 0 && configuraciones.filter((x) => x.codigo == "ONBOARDING_SESCCION_1")[0].valor}</div> */}
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo2}</div>
                            <div className="field col-10 md:col-10">
                                <label htmlFor="nombre">Agrega el Subtítulo</label>
                                <InputDefault
                                    id="SubTitulo2"
                                    name="SubTitulo2"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo2!= undefined && (formik.errors.SubTitulo2 ? "error" : "success")}
                                    value={formik.values.SubTitulo2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo2 && formik.errors.SubTitulo2}</small>
                            </div>
                            <div className="field col-12 md:col-2"style={{display:"flex",alignItems:"center",justifyContent:"end"}}>
                                <InputSwitch
                                    id={"activoWebOnboarding"}
                                    name={"activoWebOnboarding"}
                                    checked={formik.values.activoWebOnboarding}
                                    onChange={formik.handleChange}
                                ></InputSwitch>
                            </div>
                            <div className={classNames("field col-12 md:col-12",{"onboarding-seccion-opacity" : !formik.values.activoWebOnboarding})}>
                                <label htmlFor="descripcionWebOnboarding">Añade una descripción</label>
                                <InputDefault
                                    id="descripcionWebOnboarding"
                                    name="descripcionWebOnboarding"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.descripcionWebOnboarding != undefined && (formik.errors.descripcionWebOnboarding ? "error" : "success")}
                                    value={formik.values.descripcionWebOnboarding}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.descripcionWebOnboarding && formik.touched.descripcionWebOnboarding && formik.errors.descripcionWebOnboarding}</p>
                            </div>

                            <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>
                                                          
                            {/* <div className={classNames("field col-12 md:col-10 header-subtitle",{"onboarding-seccion-opacity" : !formik.values.activoInlearning})}>{configuraciones.length > 0 && configuraciones.filter((x) => x.codigo == "ONBOARDING_SESCCION_2")[0].valor}</div> */}
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo3}</div>

                            <div className="field col-10 md:col-10">
                                <label htmlFor="nombre">Agrega el Subtítulo</label>
                                <InputDefault
                                    id="SubTitulo3"
                                    name="SubTitulo3"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo3!= undefined && (formik.errors.SubTitulo3 ? "error" : "success")}
                                    value={formik.values.SubTitulo3}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo3 && formik.errors.SubTitulo3}</small>
                            </div>
                            <div className="field col-12 md:col-2"style={{display:"flex",alignItems:"center",justifyContent:"end"}}>
                                <InputSwitch
                                    id={"activoInlearning"}
                                    name={"activoInlearning"}
                                    checked={formik.values.activoInlearning}
                                    onChange={formik.handleChange}
                                ></InputSwitch>
                            </div>
                            <div className={classNames("field col-12 md:col-12",{"onboarding-seccion-opacity" : !formik.values.activoInlearning})}>
                                <label htmlFor="descripcionInlearning">Añade una descripción</label>
                                <InputDefault
                                    id="descripcionInlearning"
                                    name="descripcionInlearning"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.descripcionInlearning != undefined && (formik.errors.descripcionWebOnboarding ? "error" : "success")}
                                    value={formik.values.descripcionInlearning}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.descripcionInlearning && formik.touched.descripcionInlearning && formik.errors.descripcionInlearning}</p>
                            </div>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={modoEdicion ? "Actualizar" : "Registrar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default EditarOnboarding;