import React, { useState, useEffect ,useRef} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import { ListarSeccionCapacitacion ,ObtenerCapacitacionIntro} from "../../../service/CapacitacionService";
import "./Intro.scss"
import { Skeleton } from "primereact/skeleton";
import { ObtenerFechaFormatoCorto } from "../../../helpers/helpers";

const Intro = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);
    const {permisos,isLogged}= useUsuario();
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [capacitacionIntro, setCapacitacionIntro] = useState(null);

    let { idSeccion } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])


    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarCapaIntroduccion") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verCapaIntroduccion") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])
    useEffect(()=>{
        const obtenerListaSeccionCapa = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccionCapacitacion({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccion);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccion){
            obtenerListaSeccionCapa();
        } 
    },[idSeccion])

    useEffect(()=>{
        const GetCapacitacionIntro = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerCapacitacionIntro({jwt}).then(data=>{
                setCapacitacionIntro(data)
            })
        }
        if(!capacitacionIntro)GetCapacitacionIntro();

    },[])

    return (
        <div className="eva-capacitacionIntro" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    <div className="header-right">{capacitacionIntro && permisoEditar && tituloPagina && <Link to={"../Capacitacion/Seccion/"+idSeccion+"/EditarIntro/" + capacitacionIntro.id}>{"Editar " + tituloPagina}</Link>}</div>
                </div>
            </div>
            <div className="p-eva-dashboard-capacitacionIntro" style={{marginTop:16}}>
                <div className="p-eva-labelForm">
                    <label style={{whiteSpace:"pre-line"}}>{capacitacionIntro? capacitacionIntro.descripcion : <Skeleton className="mb-2"width="700px" height="100%" borderRadius="16px"/>}</label>
                </div>
                <div className="capacitacionIntro-secciones">
                    <div className="p-fluid formgrid grid grid-secciones">
                        <div className="capacitacionIntro-secciones-item">
                            <div className="secciones-item-img">
                                <img src="../../../../images/capacitacion/meeting.png" width={128}></img>
                            </div>
                            <div className="secciones-item-desc">
                                <div className="item-desc-titulo"><span >{capacitacionIntro&& capacitacionIntro.numeroColaboradoresCapacitados}</span></div>
                                <div className="item-desc-detalle"><span>{capacitacionIntro&& capacitacionIntro.descripcionCC}</span></div>
                            </div>
                        </div>
                        <div className="capacitacionIntro-secciones-item">
                            <div className="secciones-item-img">
                                <img src="../../../../images/capacitacion/startup.png" width={128}></img>
                            </div>
                            <div className="secciones-item-desc">
                                <div className="item-desc-titulo"><span >{capacitacionIntro&& capacitacionIntro.numeroCapacitaciones}</span></div>
                                <div className="item-desc-detalle"><span>{capacitacionIntro&& capacitacionIntro.descripcionCA}</span></div>
                            </div>
                        </div>
                        <div className="capacitacionIntro-secciones-item">
                            <div className="secciones-item-img">
                                <img src="../../../../images/capacitacion/040-clock.png" width={128}></img>
                            </div>
                            <div className="secciones-item-desc">
                                <div className="item-desc-titulo"><span >{capacitacionIntro&& capacitacionIntro.horasCapacitacion}</span></div>
                                <div className="item-desc-detalle"><span>{capacitacionIntro&& capacitacionIntro.descripcionHC}</span></div>
                            </div>
                        </div>
                        <div className="capacitacionIntro-secciones-item">
                            <div className="secciones-item-img">
                                <img src="../../../../images/capacitacion/multitasking.png" width={128}></img>
                            </div>
                            <div className="secciones-item-desc">
                                <div className="item-desc-titulo"><span >{capacitacionIntro&& capacitacionIntro.porcentajeParticipacion}</span></div>
                                <div className="item-desc-detalle"><span>{capacitacionIntro&& capacitacionIntro.descripcionPP}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                    capacitacionIntro && 
                    <div className="eva-dashboard-footer">
                        <span><Iconsax.Verify color="#B5B5B5"/></span>
                        <span className="footer-fechaActualizado"> Actualizado al {ObtenerFechaFormatoCorto(capacitacionIntro.fechaActualizacion)}</span>
                    </div>
                }
        </div>
    );
}
 
export default Intro;