import React, { useState } from "react";
import "./CardGrupo.scss";
import { Button } from "primereact/button";
import { Route, useNavigate } from "react-router-dom";
import * as Iconsax from "iconsax-react";
const CardGrupo = ({srcImg="",name="Grupo genérico",numbersUser="0",displayEdit="block",idGrupo,tipoGrupo="",handleDeletegrupo}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/Dashboard/Grupos/"+idGrupo+"/Publicaciones");
    };

    const handleEditgrupo =()=>{
        navigate("/Dashboard/Grupos/Editar/"+idGrupo);
    }

    return (
        <div className="card p-eva-cardGrupo">
            <div className="p-eva-Container-img">
                <img className="p-eva-img" src={srcImg}></img>
            </div>
            <div className="p-eva-grupos-container-icon">
                    <i className="p-eva-grupos-icon" style={{display:displayEdit}}>
                        <Iconsax.Trash color="#ffffff" onClick={handleDeletegrupo}/>
                    </i>
                    <i className="p-eva-grupos-icon" style={{display:displayEdit}}>
                        <Iconsax.Edit2 color="#ffffff" onClick={handleEditgrupo}/>
                    </i>
            </div>
            <div className="p-eva-container-nameGroup">
                <label className="p-eva-nameGroup">{name}</label>
            </div>
            <div className="p-eva-container-number">
                <label className="p-eva-number">{numbersUser} colaboradores</label>
            </div>
            <div className=" buttonGroup">
                <Button label="Ver más" className="p-eva-primary" onClick={handleClick}></Button>
            </div>
        </div>
    );
};

export default CardGrupo;
