import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import {ObtenerOrganigrama} from "../../../service/SomosinService";
import {ListUsers} from "../../../service/UsuariosService";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Skeleton } from "primereact/skeleton";
import CardFile from "../../../components/CardFileEva/CardFile";
import ContactCard from "../../../components/ContactCard/ContactCard";
import ContactCardV2 from "../../../components/ContactCard/ContactCardV2";
import * as constantes from "../../../constants/constantes";
import { Card } from "primereact/card";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerFechaFormatoCorto } from "../../../helpers/helpers";
import "./Organigrama.scss"
const Organigrama = () => {
    const navigate = useNavigate();
    const {permisos,isLogged} = useUsuario()
    const [organigrama, setOrganigrama] = useState(null);
    const [listaUsuarios, setListaUsuarios] = useState(null);
    const [permisoEditar, setPermisoEditar] = useState(false);
    const toast = useRef(null);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarOrganigrama") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verOrganigrama") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        const GetOrganigrama = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            ObtenerOrganigrama({jwt}).then(data=>{
                console.log("dataOrganigrama",data)
                setOrganigrama(data)})
            .catch(errors => {
                toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

            })
        }
        GetOrganigrama()
    },[])

    useEffect(()=>{
        const GetUsers = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            ListUsers({jwt}).then(data =>{
                setListaUsuarios(data)
            })
        }
        if(!listaUsuarios) GetUsers();
    },[])
    const headerContacto =
        <div className="p-eva-DetalleTema-cardContactIcon" >
            <i className="p-eva-DetalleTema-cardContact-icon">
                {/* <Iconsax.Personalcard color="#F47647" /> */}
                <img src="../../../../../images/liderIn/contact-img.png"></img>
            </i>
            <span><label className="header-subtitle">{organigrama? organigrama.subTitulo1?organigrama.subTitulo1:"":""}</label></span>
        </div>
    
        
    ;
    return ( 
            <div className="eva-organigrama">
                <ToastEva ref={toast} position="top-center"></ToastEva>

                <div className="eva-dashboard-header">
                    <div className="header-agrupado">
                        <div className="header">
                            <label htmlFor="header">Organigrama</label>
                        </div>
                        <div className="header-right">
                            {organigrama && permisoEditar &&<Link to={"Editar/"+organigrama.id}>Editar organigrama</Link>}
                        </div>
                        
                    </div>
                    
                </div>

                
                <div className="p-eva-dashboard-organigrama">
                    <div className="p-eva-labelForm">
                        <label>{organigrama? organigrama.descripcion  : <Skeleton className="mb-2"width="700px" height="30px" borderRadius="16px"/>}</label>
                    </div>
                    <div className="p-eva-dashcboard-documentos" style={{marginTop:24}}>
                        <div className="p-fluid formgrid grid">
                            {
                                organigrama && 
                                organigrama.organigrama_documento &&
                                organigrama.organigrama_documento.filter(x=>x.tipo == "Adjunto" && x.estado == true).map((item,i)=>{
                                    var re = /(?:\.([^.]+))?$/;
                                    return(
                                        <div className="field col-12 md:col-3" key={i} >
                                            <CardFile urlFile={constantes.URLBLOB_ORGANIGRAMA+"/"+item.nombre} key={i} 
                                            extensionArchivo={re.exec(item.nombre)[1]}
                                            nombreArchivo={item.nombre} keys={i}
                                            tipo="Adjuntos"
                                            descargable={item.descargable}
                                            />
                                        </div>
                                    )
                                })
                            }
                            {
                                organigrama && 
                                organigrama.organigrama_documento &&
                                organigrama.organigrama_documento.filter(x=>x.tipo == "Url" && x.estado == true ).map((item,i)=>{
                                    var re = /(?:\.([^.]+))?$/;
                                    return(
                                        <div className="field col-12 md:col-3" key={i} >
                                            <CardFile key={i}
                                            extensionArchivo={"video"}
                                            nombreArchivo={item.nombre} keys={i}
                                            tipo="Urls"/>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                    {
                        organigrama? 
                        organigrama.organigrama_contacto&& 
                            <div className="p-eva-organigrama-contactCard" style={{marginTop:32}}>
                                <Card  header={headerContacto}>
                                    <div className="p-fluid formgrid grid">
                                    {
                                        
                                        organigrama.organigrama_contacto.map((item,i)=>{
                                                    return(
                                                        <div className="organigrama-item-contactCard" key={i}>
                                                            <ContactCardV2
                                                                idUser={item.usuario.id}
                                                                area={item.usuario.gerencia_area.nombre}
                                                                gerencia={item.usuario.gerencia.nombre}
                                                                foto ={item.usuario.nombreFoto}
                                                                puesto={item.usuario.puesto}
                                                                nombreUser = {item.usuario.nombre}
                                                                email={item.usuario.correo}
                                                                fechaNacimiento={(item.usuario.fechaNacimiento)}
                                                                celular={item.usuario.celular}
                                                                colorFondo="#F5F5F5"
                                                                ></ContactCardV2>
                                                        </div>
                                                    )
                                                })
                                        
                                    }
                                    </div>
                                </Card>
                            </div>
                        : null
                    }
                    

                </div>
                {
                    organigrama && 
                    <div className="eva-dashboard-footer">
                        <span><Iconsax.Verify color="#B5B5B5"/></span>
                        <span className="footer-fechaActualizado"> Actualizado al {ObtenerFechaFormatoCorto(organigrama.fechaActualizacion)}</span>
                    </div>
                }
                
            </div>
            );
}
 
export default Organigrama;