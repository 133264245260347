import React, { useEffect, useState } from 'react';
import Select, { components } from "react-select";
import styled from "styled-components";
import "./SearchCard.scss"
import * as Iconsax from "iconsax-react";
import {Avatar} from 'primereact/avatar';
import * as constantes from "../../constants/constantes.js";


const SearchCard = (props) => {
  const { isMulti, value, onChange } = props;

  
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Iconsax.SearchNormal1 color='#F47647'/>
      </components.DropdownIndicator>
    );
  };

  const { Option } = components;
  const IconOption = (props) => (
      <Option {...props}>
          <Avatar className="p-eva_avatar" shape="circle" image={props.data.foto === null || props.data.length === 0 ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR + "/" + props.data.id+"/" + props.data.foto}>
          {props.data.label}
          </Avatar>
      </Option>
  );

  return (
    <div>
      
      <Select {...props} controlShouldRenderValue={!isMulti} className = "p-eva-searchCard" 
      placeholder = {props.placeholder}
      components={{DropdownIndicator,Option: IconOption}}
      />
      <div className='p-eva-searchCard-card'>
        
      </div>
    </div>
  );
};

export default SearchCard;
