 //export const URLAPI = "https://inleraningapi.azurewebsites.net";

import { Export } from "iconsax-react"

 //CONSTANTES DEV
//  export const URLAPI = "https://localhost:7247"
//  export const URLAPI_MAESTROS = "https://localhost:7065"

 //CONSTANTES QA
//  export const URLAPI = "http://10.1.3.55:90"
//  export const URLAPI_MAESTROS = "http://10.1.3.55:90/modulo.maestro.api"|

//CONSTANTES PROD

 export const URLAPI = "https://prodinapi.azure-api.net"
 export const URLAPI_MAESTROS = "https://prodinapi.azure-api.net"
 export const URLBLOB_BASE = "https://grinlearningproda85e.blob.core.windows.net/adjuntos"
 export const URLSITE_CONVOCATORIA =  "https://intranet.inlearning.edu.pe/Dashboard/Desarrollo/1"
 export const URLSITE_PUBLICACION =  "https://intranet.inlearning.edu.pe/Dashboard/Grupos/"
 export const URLSITE_HOME = "https://intranet.inlearning.edu.pe/Dashboard/Home"



//CONSTANTES AZURE ZEGEL - QA

 //export const URLAPI = "https://usuariosapi.azure-api.net"
 //export const URLAPI_MAESTROS = "https://usuariosapi.azure-api.net"
 //export const URLBLOB_BASE = "https://grinlearningqa9414.blob.core.windows.net/adjuntos"
 //export const URLSITE_CONVOCATORIA =  "https://witty-sea-08e2c3f10.2.azurestaticapps.net/Dashboard/Desarrollo/1/CrearConvocatoria"
 //export const URLSITE_PUBLICACION =  "https://witty-sea-08e2c3f10.2.azurestaticapps.net/Dashboard/Grupos/"
 //export const URLSITE_HOME = "https://witty-sea-08e2c3f10.2.azurestaticapps.net/Dashboard/Home"


export const URLBLOB_ADJ_AVATAR = URLBLOB_BASE+"/Avatar"
export const URLBLOB_ADJ_GROUP = URLBLOB_BASE+"/ImagesGroup"
export const URLBLOB_ADJ_POLITICA = URLBLOB_BASE+"/Reglamento"
export const URLBLOB_LIDERIN = URLBLOB_BASE+"/LiderIn"
export const URLBLOB_PUBLICACIONES = URLBLOB_BASE+"/Publicaciones"
export const URLBLOB_BANNER = URLBLOB_BASE+"/Banner";
export const URLBLOB_COMENTARIOS = URLBLOB_BASE+"/Comentarios";
export const URLBLOB_MENSAJE = URLBLOB_BASE+"/Mensaje_Colaborador";
export const URLBLOB_HISTORIA = URLBLOB_BASE+"/Historias_de_exito";
export const URLBLOB_ORGANIGRAMA = URLBLOB_BASE+"/Organigrama";
export const URLBLOB_CAPAMIA = URLBLOB_BASE+"/Capacitacion_mia";
export const URLBLOB_CAPAOPCIONAL = URLBLOB_BASE+"/Capacitacion_opcional";
export const URLBLOB_CAPAOPCIONAL_HEADER = URLBLOB_BASE+"/Capacitacion_opcional/Header";
export const URLBLOB_CAPAOBLIGATORIA = URLBLOB_BASE+"/Capacitacion_obligatoria";
export const URLBLOB_CAPALIDERAZGO = URLBLOB_BASE+"/Capacitacion_liderazgo";
export const URLBLOB_REPORTE_CAPA = URLBLOB_BASE+"/Reporte_capacitacion";
export const URLBLOB_REPORTE_REGLAMENTO = URLBLOB_BASE+"/Reporte_reglamento";

export const URLBLOB_CLIMA_ONBOARDING = URLBLOB_BASE+"/Clima_onboarding";
export const URLBLOB_CLIMA_ACTIVIDADES_COMUNICACION = URLBLOB_BASE+"/Clima_actividad/Clima_actividad_comunicacion";
export const URLBLOB_CLIMA_ACTIVIDADES_CELEBRACION = URLBLOB_BASE+"/Clima_actividad/Clima_actividad_celebracion";
export const URLBLOB_CLIMA_PLAN = URLBLOB_BASE+"/Clima_plan";
export const URLBLOB_CLIMA_GPTW = URLBLOB_BASE+"/Clima_gptw";
export const URLBLOB_CLIMA_RECONOCIMIENTO = URLBLOB_BASE+"/Clima_reconocimiento";
export const URLBLOB_CLIMA_DIVERSIDAD = URLBLOB_BASE+"/Clima_diversidad";
export const URLBLOB_CLIMA_DIVERSIDAD_ACTIVIDADES = URLBLOB_BASE+"/Clima_diversidad/Clima_diversidad_Actividades";
export const URLBLOB_CLIMA_DIVERSIDAD_POLITICAS = URLBLOB_BASE+"/Clima_diversidad/Clima_diversidad_politicas";
export const URLBLOB_DOCUMENTO_CUMPLEAÑOS = URLBLOB_BASE+"/Documentos_Cumpleanios";
export const URLBLOB_DOCUMENTO_ANIVERSARIO = URLBLOB_BASE+"/Documentos_Aniversario";
export const URLBLOB_REPORTE_METRICAS = URLBLOB_BASE+"/Reporte_metricas";



export const DEFAULTPASS = "123456"
export const URLAVATARDEFAULT = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
export const URL_IMAGE_GROUP_DEFAULT ="https://mvpcluster.com/wp-content/uploads/2019/06/AI-Builder-1024x473.jpg"
export const MODOTEST = true;

// export const URL_TEST = "http://localhost:7144/api";
export const URL_TEST = "http://localhost:7080/api";
export const URL_PUBLICTEST = "http://localhost:7098/api";
export const URL_NOTIFICACION = "https://innotificacionqa.azurewebsites.net/api"
//export const URL_NOTIFICACION = "http://localhost:7098/api"
export const URL_DESARROLLO = "http://localhost:7144/api"
export const URL_LIDERTEST = "http://localhost:7080/api";
export const URL_ORGANIGRAMATEST = "http://localhost:7128/api";
export const URL_CAPACITACIONTEST = "http://localhost:7162/api";
export const URL_CLIMATEST = "http://localhost:7148/api";
export const URL_INTERACCIONTEST = "http://localhost:7142/api";
export const URL_GRUPOSTEST = "http://localhost:7137/api";
export const URL__USUARIOTEST = "http://localhost:7152/api";
export const URL_CONFIGTEST = "http://localhost:7162/api";
export const URL_PERFILT = "http://localhost:7066/api";


export const GRUPO_GENERAL = "Inlearning Institutos"

//Produccion
export const gdhenlineaUrlBase = "https://gdhenlinea.inlearning.edu.pe/SpringNet/#/intr/"
//qa
//export const gdhenlineaUrlBase = "http://gdhenlineatest.zegel.edu.pe:8050/SpringPruebas/#/intr/"
export const gdhenlineaUrlCuponeraZegel = "https://zoom.zegel.edu.pe/"
export const gdhenlineaUrlCuponeraIdat = "https://zoom.idat.edu.pe/"
export const gdhenlineaUrlMesaAyuda = "https://mdainstitutos.inlearning.edu.pe/ITHELPCENTER/Index.aspx?lm=NaN"
export const gdhenlineaUrlrankmi = "http://inlearning.rankmi.com/"


// export const URL_NOTIFICACION = "http://localhost:7262/api"
//http://gdhenlineatest.zegel.edu.pe:8050/SpringPruebas/#/