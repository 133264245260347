import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate, json } from "react-router-dom";
//import useUsuario from "../../../../hooks/useUsuario";
//import ToastEva from "../../../../components/Toast/ToastEva";
import { ObtenerInfluencers } from "../../../../service/ClimaService";
//import * as constantes from "../../../../constants/constantes";
//import ContactCard from "../../../../components/ContactCard/ContactCard";
import ContactCardV2 from "../../../../components/ContactCard/ContactCardV2";
import SearchCard from "../../../../components/Directorio/SearchCard";
import * as Iconsax from "iconsax-react";

const Influencer = () => {
    const navigate = useNavigate();
    const [influencer, setInfluencer] = useState(null);
    const toast = useRef(null);
    const [selected, setSelected] = useState([]);
    const [listFilter, setListFilter] = useState(null)

    useEffect(() => {
        const getInfluencer = async () => {
            console.log("Influencer",influencer)
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerInfluencers({ jwt }).then(data => {

                var seleccione={
                    label: "--Seleccione--",
                    value: 0,
                    foto: null,
                    id: 0,
                    codigoColaborador: 0,
                    documento: 0
                  }

                const newData = data?.clima_laboral_influencer_usuario.map(item => ({
                    label: `${item.usuario.nombre} - ${item.usuario.documento}`,
                    value: item.id,
                    foto: item.usuario.foto,
                    id: item.usuario.id,
                    codigoColaborador: item.usuario.codigoColaborador,
                    documento: item.usuario.documento
                  }));

                  newData.unshift(seleccione);

                setInfluencer(data);
                setListFilter(newData);
            })
        }
        if (!influencer) getInfluencer()
    }, [])

    const handleSelectChange = (values) => {
        console.log("Values",values)
        setSelected(values);
        let jwt = window.localStorage.getItem("jwt");
        ObtenerInfluencers({ jwt }).then(data => {
            if (values?.value > 0) {
                console.log("IF".values?.value )
                const filter = data.clima_laboral_influencer_usuario.filter(x => x.id === values.value);
                data.clima_laboral_influencer_usuario = filter;
            }
            setInfluencer(data);
        })
    };
    const allUser = ()=> {
        let jwt = window.localStorage.getItem("jwt");
        var values={
                    label: "--Seleccione--",
                    value: 0,
                    foto: null,
                    id: 0,
                    codigoColaborador: 0,
                    documento: 0
                  }
        setSelected(values);

        ObtenerInfluencers({ jwt }).then(data => {
            setInfluencer(data);
        })
    };

    return (
        <div className="p-eva-influencer">
            <div className="influencer-descripcion">
                {influencer && influencer.descripcion}
            </div>

            <div className="p-fluid formgrid grid" style={{ marginTop: "2%" }}>
                <div style={{ marginTop: "0.6%" }}>
                     <i href="#" onClick={() => allUser()} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div className="field col-12 md:col-9">
                    <SearchCard onChange={handleSelectChange}
                        options={listFilter}
                        value={selected}
                        placeholder="Buscar por nombre o número de documento"
                    ></SearchCard>
                </div>
            </div>

            <div className="influencer-usuario" style={{ marginTop: 54 }}>
                <div className="p-fluid formgrid grid">
                    {
                        influencer &&
                        influencer.clima_laboral_influencer_usuario.length > 0 &&
                        influencer.clima_laboral_influencer_usuario.map((item, i) => {
                            return (
                                <div className="field col-12 md:col-4" key={i} style={{ marginBottom: 60 }}>
                                    <ContactCardV2
                                        idUser={item.usuario.id}
                                        area={item.usuario.gerencia_area ? item.usuario.gerencia_area.nombre : ""}
                                        gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre : ""}
                                        foto={item.usuario.foto}
                                        puesto={item.usuario.puesto}
                                        nombreUser={item.usuario.nombre}
                                        email={item?.usuario?.correo}
                                        fechaNacimiento={item?.usuario?.fechaNacimiento}
                                        celular={item?.usuario?.celular}
                                        colorFondo="#FFFFFF"
                                    ></ContactCardV2>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Influencer;