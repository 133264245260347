import React, { useState,useRef,createRef } from 'react';
import { i } from 'primereact/button';
import "./BoxReacciones.scss"
import classNames from 'classnames';
import { Tooltip } from 'primereact/tooltip';
// const BoxReacciones = ({mostrar=true,onMouseLeave,onMouseEnter,lista},props) => {
    const BoxReacciones = (props) => {

    const aref = useRef([])
    const mostrarClassName = classNames("p-eva-BoxReacciones", {
        "p-eva-mostrar":props.mostrar===true ? true:false,
        "p-eva-oculto":props.mostrar ===false?true:false
    });

    const handleClick =(element)=>{

         let reaccionSeleccionada = aref;
        //let reaccionSeleccionada ="me gusta"
        props.onClickReaccion(element)
    }


    return (
       <div className={mostrarClassName} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter}>
        {
            props.lista &&
            props.lista.map((item,i)=>{
                    return(<div key={i}>
                        <Tooltip target={".BoxReacciones-"+props.idPub+"-"+item.nombre.replace(/\s+/g, "").toLowerCase()} className='p-eva_boxReacciones-tooltip' key={i} ></Tooltip>
                        <img key={item.nombre} ref={e => (aref.current[item.nombre] = e)}
                            onClick={()=>handleClick(aref.current[item.nombre])}
                            data-name = {item.nombre}
                            alt={item.id} className={"p-eva-BoxReacciones-item BoxReacciones-"+props.idPub+"-"+ item.nombre.replace(/\s+/g, "").toLowerCase()} src={"../../../../images/inicio/"+item.rutaImagen}  
                            height={40}
                            data-pr-tooltip={item.nombre} data-pr-position="top"></img>

                    </div>
                    )
                })
        }
            {/* <Tooltip target=".p-eva-BoxReacciones-megusta" className='p-eva_boxReacciones-tooltip'></Tooltip>
            <img className="p-eva-BoxReacciones-megusta" src="../../../../images/inicio/img-megusta.png"  height={56}
                data-pr-tooltip="Me gusta" data-pr-position="top"></img>

            <Tooltip target=".p-eva-BoxReacciones-meencanta" className='p-eva_boxReacciones-tooltip'></Tooltip>
            <img className="p-eva-BoxReacciones-meencanta" src="../../../../images/inicio/img-meencanta.png" height={56}
            data-pr-tooltip="Me encanta" data-pr-position="top"></img>

            <Tooltip target=".p-eva-BoxReacciones-meimporta" className='p-eva_boxReacciones-tooltip'></Tooltip>
            <img className="p-eva-BoxReacciones-meimporta" src="../../../../images/inicio/img-meimporta.png" height={56}
            data-pr-tooltip="Me importa" data-pr-position="top"></img>

            <Tooltip target=".p-eva-BoxReacciones-medivierte" className='p-eva_boxReacciones-tooltip'></Tooltip>
            <img className="p-eva-BoxReacciones-medivierte" src="../../../../images/inicio/img-medivierte.png" height={56}
            data-pr-tooltip="Me divierte" data-pr-position="top"></img>

            <Tooltip target=".p-eva-BoxReacciones-measombra" className='p-eva_boxReacciones-tooltip'></Tooltip>
            <img className="p-eva-BoxReacciones-measombra" src="../../../../images/inicio/img-measombra.png" height={56}
            data-pr-tooltip="Me asombra" data-pr-position="top"></img>

            <Tooltip target=".p-eva-BoxReacciones-meentristece" className='p-eva_boxReacciones-tooltip'></Tooltip>
            <img className="p-eva-BoxReacciones-meentristece" src="../../../../images/inicio/img-meentristece.png" height={56}
                data-pr-tooltip="Me entristece" data-pr-position="top"></img>

            <Tooltip target=".p-eva-BoxReacciones-meenoja" className='p-eva_boxReacciones-tooltip'></Tooltip>
            <img className="p-eva-BoxReacciones-meenoja" src="../../../../images/inicio/img-meenoja.png" height={56}
                data-pr-tooltip="Me enoja" data-pr-position="top"></img> */}
       </div>
     );
}

export default BoxReacciones;