import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate, json } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";

import ToastEva from "../../../../components/Toast/ToastEva";
import { ObtenerDiversidadActividad,EliminarDiversidadActividadPorId } from "../../../../service/ClimaService";
import { Panel } from 'primereact/panel';
import * as constantes from "../../../../constants/constantes";

import * as Iconsax from "iconsax-react";
import Boton from "../../../../components/Boton/Boton";
import { Dialog } from "primereact/dialog";

const Actividad = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario()
    const [tituloPagina, setTituloPagina] = useState(null);
    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(true);
    const [actividades, setActividades] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    let { indexDefault } = useParams();

    const [idSeleccionado, setIdSeleccionado] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const GetActividades = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerDiversidadActividad({jwt}).then(data => 
                {
                    setActividades(data)
            
                })
        }
    useEffect(()=>{
        
        if(!actividades)GetActividades()
    },[])

    const EliminarActividad=()=>{
        setIsLoading(true)
        let jwt = window.localStorage.getItem("jwt");
        let Id = idSeleccionado
        EliminarDiversidadActividadPorId({Id,jwt}).then(data => 
        {               
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro eliminado exitosamente.", life: 7000})
    
            setShowDialog(false)
            GetActividades()
            setIsLoading(false)
    
        }).catch(errors => {
            
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            setIsLoading(false)
    
        })
    
    }
    const footerDelete = 
    <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
        <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialog(false)}></Boton>
        <Boton nombre={"Eliminar actividad"} widths="50%" color="primary" metodoClick={EliminarActividad} 
        loading = {isLoading}></Boton>
    </div>;
    return (
        <div className="p-eva-actividad">
                        <ToastEva ref={toast} position="top-center"></ToastEva>

            <div>Conoce las actividades que realizamos a lo largo del año y sé parte de cada una de ellas.</div>
            <div className="body-repositorio">
                {actividades &&
                    actividades.map((item, i) => {
                        return (
                            <div className="repositorio-item" key={i}>
                                {
                                            permisoEditar && 
                                                <div className="repositorio-item-delete" onClick={()=>
                                                {
                                                    setIdSeleccionado(item.id)
                                                    setShowDialog(true)
                                                }}>
                                                    <Iconsax.Trash color="#FFFFFF"/>
                                                </div>
                                        }
                                <div className="repositorio-item-header">
                                    {/* {item.nombreFoto.length > 0 ? <img src={constantes.URLBLOB_CLIMA_DIVERSIDAD_ACTIVIDADES + "/" + item.nombreFoto} style={{ maxWidth: 312, maxHeight: 136 }}></img> : <div className="repositorio-item-header-nombre">{item.nombre}</div>} */}
                                    <img src={constantes.URLBLOB_CLIMA_DIVERSIDAD_ACTIVIDADES+"/"+item.nombreFoto}
                                                    style={{maxWidth:312,maxHeight:136}}></img>
                                            <div>{item.nombre}</div> 
                                </div>
                                <div className="repositorio-item-footer">
                                    <Boton nombre={"Ver más"} widths="312px" color="secondary" margin={0} metodoClick={() => navigate("../Clima/Seccion/" + idSeccionClima + "/DetalleClimaDiversidad/"+indexDefault+"/DetalleDiversidadActividad/" + item.id)}></Boton>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                footer={footerDelete}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar esta actividad?</label>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>
        </div>
    );
}
 
export default Actividad;