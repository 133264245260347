import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
const ENDPOINT = constantes.URLAPI;
const ENDPOINTEST = constantes.URL_PERFILT
export const  ActualizarMiClave= ({jsonPass,jwt}) =>{
    //return fetch(`${ENDPOINTEST}/ActualizarMiClave`,{
   return fetch(`${ENDPOINT}/Perfil/ActualizarMiClave`,{
       method: "POST",
       //mode: "no-cors",
       headers:{
           "Authorization":"Bearer "+jwt,
           //'Content-Type':'application/json'
           'Content-Type': 'application/json',
           "accept": "application/json"
       },
       //headers: new Headers({ 'Content-Type': 'application/json' }),
       
       body: jsonPass
   }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
   }).then(res=>{
       const {data} = res
       return data
   })
}

export const ReestablecerContrasena =async ({jwt,id}) =>{
    //return await fetch(`${ENDPOINTEST}/ReestablecerContrasena`,{
    return fetch(`${ENDPOINT}/Perfil/ReestablecerContrasena/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}