import React, { useState } from "react";
import Select, { components } from "react-select";
import "./UsuariosSugeridos.scss";
const UsuariosSugeridos = ({ usuarios,recibirDatosUsuariosEtiquetados,formikProps }) => {
  const [usuariosSugeridos, setUsuariosSugeridos] = useState([]);
  const [searchText, setSearchText] = useState('');

  function extractTextBeforeAtSign(inputString) {
    const atSignIndex = inputString.indexOf('@');
    return atSignIndex === -1 ? inputString : inputString.substring(0, atSignIndex);
  }
  


    const seleccionarUsuario = (event) => {
      console.log("Usuarioevent",event)
      recibirDatosUsuariosEtiquetados(event);
      setSearchText(event);
      

      console.log("formikProps.values.contenido",formikProps.values.contenido)
      console.log("formikProps.values.comentario",formikProps.values.comentario)
      const texto = formikProps.values.contenido?formikProps.values.contenido:formikProps.values.comentario;
      console.log("texto",texto)

      const textBeforeAtSign = extractTextBeforeAtSign(texto);
      const regex = /<p>|<\/p>|@/g;
      const textoSinEtiquetas = texto.replace(regex, '');
      const textoNuevo=`${textBeforeAtSign} <strong>${event.label}</strong><span>&nbsp;</span>`;
      console.log("textoNuevo",textoNuevo)

      formikProps.values.contenido?formikProps.setFieldValue('contenido', textoNuevo):formikProps.setFieldValue("comentario",textoNuevo)
     // formikProps.setFieldValue('contenido', textoNuevo); // use formik.setFieldValue to update the input value
     //formikProps.setFieldValue("comentario",textoNuevo)
      setUsuariosSugeridos([]);
    };
    const formattedUsuarios = usuarios.map((user) => ({
      label: user.nombre,
      value: user.id,
    }));
  
    return (

      <div style={{ position: "absolute",
          top: "55px",
          left: 0,
          width: "96%",
          background: "#fff",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1}}>

        <Select
          className="p-eva-searchCard"
          placeholder={''}
          options={formattedUsuarios}
          onChange={seleccionarUsuario}
          menuIsOpen={true} 
        />
        {/* <select onChange={seleccionarUsuario}>
          {formattedUsuarios.map((user) => (
            <option key={user.value} value={user.value}>
              {user.label}
            </option>
          ))}
        </select> */}
      </div>
      
    );
  };
  
export default UsuariosSugeridos;