import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate, json } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
import { ObtenerReconocimientoCategoriaPorId,ActualizarCLimaCategoria} from "../../../service/ClimaService";
import { getBase64 } from "../../../helpers/helpers";
import classNames from "classnames";
import { InputSwitch } from "primereact/inputswitch";
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import { ListUsersTag } from "../../../service/UsuariosService";
const EditarCategoria = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState("Editar reconocimiento");
    const [modoEdicion, setModoEdicion] = useState(true);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    let { idCategoria } = useParams();
    const [categoria, setCategoria] = useState(null);
    const [listUserTotal, setListUserTotal] = useState([]);
    const {permisos,isLogged} = useUsuario()
    const [selectedUser, setSelectedUser] = useState([]);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        const getCategoria= async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let id =  idCategoria
            await ObtenerReconocimientoCategoriaPorId({jwt,id}).then(data => 
                {
                    setCategoria(data)
                    setTituloPagina(data.nombre)
                    let usuariosGrupo = [];

                    data.clima_reconocimiento_categoria_usuario.map((item,i)=>{
                        usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                    })
                    setSelectedUser(usuariosGrupo)
                })
        }
        if(idCategoria)getCategoria()
    },[idCategoria])

    useEffect(()=>{
        if(listUserTotal.length === 0)
        {
            let jwt = window.localStorage.getItem("jwt");
            ListUsersTag(jwt).then(data => setListUserTotal(data))
        }
     },[]);
     const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
        arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: "", tipo: "Adjunto", estado: true })
    }

    const Actualizar =({jsonCategoria})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarCLimaCategoria({jsonCategoria,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    const schema = Yup.object().shape({
        // descripcion: Yup.string().required("Campor descripción requerido."),
        // descripcionCategoria: Yup.string().required("Campor descripcionCategoria requerido."),
        // categoria: Yup.array()
        // .of(
        //     Yup.object().shape({
        //         nombre : Yup.string().nullable().required("Campo nombre requerido."),
        //         descripcion :Yup.string().nullable().required("Campo descripción requerido.")
        //     })
        // )
    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            imagenBase64:null,tipoDocumento:null,
            id:categoria && categoria.id,
            nombre:categoria&&categoria.nombre,
            Titulo : categoria? categoria.titulo:"",
            SubTitulo : categoria? categoria.subTitulo:"",
            nombreFoto : categoria&&(categoria.nombreFoto ? categoria.nombreFoto: ""),
            descripcion: categoria && categoria.descripcion,
            listaDocCompleto:categoria && categoria.clima_reconocimiento_categoria_documento.length > 0 ?
                    categoria.clima_reconocimiento_categoria_documento
                : [{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true}],
            clima_reconocimiento_categoria_usuario:categoria && categoria.clima_reconocimiento_categoria_usuario.length > 0 ?
                        categoria.clima_reconocimiento_categoria_usuario
                        :[{id:0,idClimaReconocimientoCategoria:0,idUsuario:0}],
            selected :categoria && categoria.clima_reconocimiento_categoria_usuario.length > 0 && selectedUser.length > 0 ? selectedUser:[]
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id
        let imagenBase64 = values.imagenBase64
        let tipoDocumento = values.tipoDocumento
        let nombre = values.nombre
        let Titulo =values.Titulo;
        let SubTitulo =values.SubTitulo;
        let nombreFoto = values.nombreFoto
        let descripcion = values.descripcion
        let listaDocCompleto = values.listaDocCompleto
        let clima_reconocimiento_categoria_usuario = values.clima_reconocimiento_categoria_usuario
        let jsonCategoria = JSON.stringify({imagenBase64,tipoDocumento,nombre,Titulo,SubTitulo,nombreFoto,id,descripcion,listaDocCompleto,clima_reconocimiento_categoria_usuario},null,2)
        // console.log(jsonCategoria)
        // formik.setSubmitting(false)
         Actualizar({jsonCategoria})
      },
    });
    return (
        <div className="eva-dashboard-editarCategoria" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                {/* <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div> */}
                <div><label htmlFor="header">{"Crear " + formik.values.nombre}</label></div>
            </div>
            {/* <div className="field col-12 md:col-12">
                <label htmlFor="nombre">Agrega el título</label>
                <InputDefault
                    id="Titulo"
                    name="Titulo"
                    className="white"
                    placeholder="Escribe aquí"
                    errorClass={formik.touched.Titulo!= undefined && (formik.errors.Titulo ? "error" : "success")}
                    value={formik.values.Titulo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <small className="p-error">{formik.touched.Titulo && formik.errors.Titulo}</small>
            </div> */}
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="nombre">Agrega el título</label>
                                <InputDefault
                                    id="nombre"
                                    name="nombre"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")}
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.nombre && formik.errors.nombre}</small>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="nombreFoto">Adjunta una foto</label>
                                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                    <label
                                        className={classNames("eva-labelFileLider", {
                                            "p-eva-error": formik.errors.nombreFoto,
                                        })}
                                        style={{ minHeight: 16, height: "fit-content" }}
                                    >
                                        <span>{formik.values.nombreFoto && formik.values.nombreFoto.length > 0 ? formik.values.nombreFoto : "Adjunta aquí"}</span>
                                    </label>
                                    {formik.values.nombreFoto != "" ? (
                                        <label className="eva-labelFileLider-icon">
                                            <i onClick={() => {
                                                    formik.setFieldValue(`nombreFoto`, "");
                                                    formik.setFieldValue(`imagenBase64`, null);
                                            }}>
                                                <Iconsax.Trash />
                                            </i>
                                        </label>
                                    ) : (
                                        <label htmlFor={`nombreFoto`} className="eva-labelFileLider-icon">
                                            <i>
                                                <Iconsax.GalleryAdd color="#F47647" />
                                            </i>
                                        </label>
                                    )}
                                    <input
                                        type="file"
                                        id={`nombreFoto`}
                                        name={`nombreFoto`}
                                        onChange={(e) => {
                                            formik.setFieldValue(`nombreFoto`, e.currentTarget.files[0].name);
                                            formik.setFieldValue(`tipoDocumento`, e.currentTarget.files[0].type);
                                            getBase64(e.currentTarget.files[0]).then((result) => {
                                                formik.setFieldValue(`imagenBase64`, result);
                                            });

                                            //onFileChange(e, "document")
                                        }}
                                        // accept="application/pdf,audio/*,image/*"
                                        accept="image/*"
                                        hidden
                                    ></input>
                                </div>
                                <small className="p-error">{formik.errors.imagenBase64 && formik.touched.imagenBase64}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción para la primera categoría
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.errors.descripcion && formik.touched.descripcion ? "error" : "success"}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.errors.descripcion && formik.touched.descripcion}</small>
                            </div>
                            <div className="field col-12 md:col-12" style={{ marginBottom: 2 }}>
                                <label className="p-eva-labelForm">Agrega imagen</label>
                            </div>
                            <FieldArray
                                name={`listaDocCompleto`}
                                render={(arrayCategoriaDoc) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaDocCompleto &&
                                                    formik.values.listaDocCompleto.map((documento, zIndex) => (
                                                        <div className="field col-12 md:col-6" key={zIndex}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.listaDocCompleto[zIndex].nombreDocumento.length > 0 ? formik.values.listaDocCompleto[zIndex].nombreDocumento : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                    name={`listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.listaDocCompleto[zIndex].nombreDocumento}
                                                                    onChange={(e)=>{formik.values.listaDocCompleto[zIndex].imagenBase64 && formik.handleChange(e)}}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.listaDocCompleto[zIndex].nombreDocumento != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        {/* <i onClick={() => {
                                                                                formik.setFieldValue(`listaDocCompleto[${zIndex}].nombreDocumento`, "");
                                                                                formik.setFieldValue(`listaDocCompleto[${zIndex}].imagenBase64`, null);
                                                                        }}>
                                                                            <Iconsax.Trash />
                                                                        </i> */}
                                                                        <i onClick={() => onDeleteFile(zIndex, arrayCategoriaDoc)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`listaDocCompleto[${zIndex}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                                                name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`listaDocCompleto[${zIndex}].nombreDocumentoIcon`}
                                                                    name={`listaDocCompleto[${zIndex}].nombreDocumentoIcon`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`listaDocCompleto[${zIndex}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`listaDocCompleto[${zIndex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`listaDocCompleto[${zIndex}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    //accept="application/pdf,audio/*,image/*"
                                                                    accept="image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        {/* <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayCategoriaDoc.push({ imagenBase64: null, tipoDocumento: null, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: "", tipo: "Adjunto", estado: true })}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multiple" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                <input
                                                    type="file"
                                                    id={`file-multiple`}
                                                    name={`file-multiple`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles= e.currentTarget.files
                                                        console.log(tempFiles)
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64  =""
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayCategoriaDoc.push({ imagenBase64: base64, tipoDocumento: tipo, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: nombre, tipo: "Adjunto", estado: true })
                                                            });
                                                        }
                                                      
                                                    }}
                                                    accept="image/*"
                                                    // accept="application/pdf,audio/*,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div> */}
                                    </>
                                )}
                            ></FieldArray>
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo}</div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el Sutítulo</label>
                                <InputDefault
                                    id="SubTitulo"
                                    name="SubTitulo"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo!= undefined && (formik.errors.SubTitulo ? "error" : "success")}
                                    value={formik.values.SubTitulo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo && formik.errors.SubTitulo}</small>
                            </div>
                            <div className="field col-12 md:col-9">
                                <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                                <MultiselectEva
                                    onChange={(listUser) => {
                                        console.log(listUser)
                                        let temp = [];
                                        listUser.map((item, i) => {
                                            temp.push({ id: 0, idClimaReconocimientoCategoria: 0, idUsuario: item.value });
                                        });
                                        formik.setFieldValue(`clima_reconocimiento_categoria_usuario`, temp);
                                        formik.setFieldValue(`selected`, listUser);
                                    }}
                                    isMulti
                                    //options={selectButtonValues1}
                                    options={listUserTotal}
                                    value={formik.values.selected}
                                />
                            </div>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default EditarCategoria;