import React, { useState } from "react";
import { Card } from "primereact/card";
import FileViewer from 'react-file-viewer';
import "./CardFile.scss"
import * as Iconsax from "iconsax-react";
import DocumentViewer from "../PDF/DocumentViewer";
import PreviewMedia from "../PreviewMedia/PreviewMedia";
import ReactPlayer from "react-player";
import { Dialog } from "primereact/dialog";
import { useEffect } from "react";
import { useScrapper } from 'react-tiny-link'


const CardFile = ({urlFile,extensionArchivo,nombreArchivo,keys,tipo,descargable = true}) => {
    console.log("urlFile",urlFile)
    console.log("extensionArchivo",extensionArchivo)
    console.log("nombreArchivo",nombreArchivo);
    console.log("tipo",tipo);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalPdf, setVisibleModalPdf] = useState(false);
    const [visibleModalVideo, setVisibleModalVideo] = useState(false);
    const [imageThumbnail, setimageThumbnail] = useState(null);
    const [iconoDocumento, setIconoDocumento] = useState("../../../../../../../images/liderIn/icon_img.png");
    
    const [data,result, loading, error]= useScrapper({
        url: nombreArchivo, proxyUrl:true
      })
    
      useEffect(()=>{
        if(data)
        {
            if(data.image[0])
            {
                setimageThumbnail(data.image[0])
            }
            else{setimageThumbnail("../../../../../../../images/inicio/play.png")}
           
        }
        else{setimageThumbnail("../../../../../../../images/inicio/play.png")}
        console.log(data)
      },[data])

    useEffect(()=>{
        if(extensionArchivo)
        {
            if(extensionArchivo ==="pdf")
            {
                setIconoDocumento("../../../../../../../images/liderIn/icon_pdf.png")
            }
            else if(extensionArchivo ==="jpg" || extensionArchivo ==="png")
            {
                setIconoDocumento("../../../../../../../../images/liderIn/icon_img.png")
            }
            else if(extensionArchivo==="video")
            {
                if(tipo =="Urls"){
                    if (validarReproductor()) {
                        setIconoDocumento("../../../../../../../images/liderIn/icon_media.png")
                    }
                    else{
                        setIconoDocumento("../../../../../../../images/liderIn/Recurso13.svg")
                    }
                }
                else{
                    setIconoDocumento("../../../../../../../images/liderIn/icon_media.png")
                }
            }
            else if(extensionArchivo==="mp4")
            {
                setIconoDocumento("../../../../../../../images/liderIn/icon_media.png")
            }
        }
       
    },[extensionArchivo])
     
    const onOpenPreview =(e)=>
    {
        if(extensionArchivo ==="pdf")
        {
            setVisibleModalPdf(true)
        }
        else if(extensionArchivo ==="video" || extensionArchivo ==="mp4")
        {
            console.log(validarReproductor());
            if (tipo == 'Urls') {
                if (validarReproductor()) {
                    setVisibleModalVideo(true)
                }
                else {
                    e.preventDefault();
                    window.open(nombreArchivo, "_blank");
                }
            }
            else {
                setVisibleModalVideo(true)
            }
        }
        else{
            setVisibleModal(true)
        }
    }

    const validarReproductor = () => {
        if (nombreArchivo.includes("youtube") || nombreArchivo.includes("youtu.be")
        || nombreArchivo.includes("twitch") || nombreArchivo.includes("dailymotion")
        || nombreArchivo.includes("vimeo")) {
            return true;
        }
        else {
            return false;
        }
    }
   
    const handleClickDownload =(url)=>{

        fetch(url).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = nombreArchivo;
                alink.click();
            })
        })
    }
    const header =
            <div className="p-eva-cardfileHeader" >
             {
                extensionArchivo==="pdf" ? 
                <>
                    {descargable && <div className="cardfileHeader-descargar" onClick={()=>handleClickDownload(urlFile)}><span ><Iconsax.ImportCurve color="#ffffff" size={27}/></span></div>} 
                    <span className={"cardfileHeader-pdf"} onClick={onOpenPreview}>
                        <FileViewer
                            
                            fileType={extensionArchivo}
                            filePath={urlFile}
                            // filePath='https://grinlearningqa9414.blob.core.windows.net/adjuntos/LiderIn/2/Documentos/PlandetrabajoEva.pdf'  
                            > </FileViewer>
                    </span>
                </>
                
                :
                (
                    (extensionArchivo === "video" || extensionArchivo === "mp4") ? 
                        (tipo=="Urls" && validarReproductor())?
                        <div style={{display:"flex",alignItems:"center"}} onClick={onOpenPreview}>
                            <div><img src={imageThumbnail}  width="100%"></img></div>
                        </div>
                        :
                        <><img src={iconoDocumento} className="imagenEnlace" onClick={onOpenPreview}></img></>
                    :
                        <div style={{display:"flex",alignItems:"center"}} onClick={onOpenPreview}>
                            <div ><img src={urlFile} width="100%" ></img></div>
                        </div>
                )
                
            }
            </div>
           
    ;
    return ( 
        <>
            {/* <Card header={header} className="p-eva-cardFile" style={{height:290,width:252}}  key={keys}>
                <div className="p-eva-cardFile-documentIcon">
                    <i className="p-eva-cardFile-icon">
                       
                        <img src={iconoDocumento}></img>
                    </i>
                    <div className="nombre-archivo"><label >{nombreArchivo}</label></div>
                    
                </div>
            </Card> */}
            <div className="p-eva-cardArchivo">
                <div className="cardArchivo-img">
                    {header}
                </div>
                <div className="cardArchivo-detalle" >
                    <div className="cardArchivo-detalle-icon"> <img src={iconoDocumento} style={{maxWidth:"30px"}}></img></div> 
                    <div className="cardArchivo-detalle-nombre">{nombreArchivo}</div>
                </div>
            </div>
            {
                visibleModalPdf&&
                    <DocumentViewer visibleModal={visibleModalPdf} urlFile={urlFile} 
                    onHide={()=>setVisibleModalPdf(false)}></DocumentViewer>
                
            }
            {
                 visibleModal && 
                 <PreviewMedia visibleModal={visibleModal} urlFile={urlFile} 
                    onHide={()=>setVisibleModal(false)} height={90}></PreviewMedia>
            }

            {
                visibleModalVideo && 
                <Dialog visible={visibleModalVideo} footer={<></>} onHide ={()=>setVisibleModalVideo(false)}
                className="p-eva-videoplayer" >
                    <ReactPlayer url={ extensionArchivo == "mp4" ?urlFile: nombreArchivo} controls></ReactPlayer>
                </Dialog>
                
            }
        </>
        
     );
}
 
export default CardFile;