import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import { ListarSeccionCapacitacion ,ObtenerCapacitacionMia,ActualizarCapacitacionMia} from "../../../service/CapacitacionService";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
const EditarMia = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);
    const [modoEdicion, setModoEdicion] = useState(true);

    const [capacitacionMia, setCapacitacionMia] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();

    useEffect(()=>{
        const obtenerListaSeccionCapa = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccionCapacitacion({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccion);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccion){
            obtenerListaSeccionCapa();
        } 
    },[idSeccion])

    useEffect(()=>{
        const GetCapacitacionMia = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerCapacitacionMia({jwt}).then(data=>{
                setCapacitacionMia(data)
            })
        }
        if(!capacitacionMia)GetCapacitacionMia();

    },[])

    const Actualizar =({jsonCapacitacion})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarCapacitacionMia({jsonCapacitacion,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }

    

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }

    const schema = Yup.object().shape({
        descripcion: Yup.string().required("Campo descripción requerido."),
        textoBoton: Yup.string().required("Campo Texto del botón requerido."),
        link: Yup.string().required("Campo Link requerido."),
        // fechaLimite: Yup.date().required("Campo Fehca límite requerido."),

    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:modoEdicion && capacitacionMia?capacitacionMia.id:0,
            descripcion: modoEdicion  && capacitacionMia?capacitacionMia.descripcion:"",
            fechaLimite : modoEdicion && capacitacionMia?capacitacionMia.fechaLimite:null,
            textoBoton: modoEdicion && capacitacionMia?capacitacionMia.textoBoton:"",
            link: modoEdicion && capacitacionMia?capacitacionMia.link:"",
            documentos: modoEdicion  && capacitacionMia && capacitacionMia.capacitacion_mia_documentos.length >0? capacitacionMia.capacitacion_mia_documentos.filter(x=>x.tipo=="Adjunto"&& x.estado == true) : [{imagenBase64:null,tipoDocumento:null,id:0,
                idCapacitacionMia:0,nombreDocumento:'',tipo:'Adjunto',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            listaUrls : modoEdicion  && capacitacionMia && capacitacionMia.capacitacion_mia_documentos.length >0? capacitacionMia.capacitacion_mia_documentos.filter(x=> x.tipo=="Url"&& x.estado == true) : [{id:0,
                idCapacitacionMia:0,nombreDocumento:'',tipo:'Url',estado:false,fechaEliminacion:null,usuarioEliminacion:null}]
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id
        let descripcion = values.descripcion
        let fechaLimite = values.fechaLimite ?new Date(values.fechaLimite).toLocaleString("en-US"):null;
        let textoBoton = values.textoBoton;
        let link = values.link;
        let listaDocCompleto = values.documentos[0].nombreDocumento.length >0 ? values.documentos :[];
        let listaUrls = values.listaUrls.filter(x=>x.nombreDocumento != "");
        
        
        let jsonCapacitacion = JSON.stringify({id,descripcion,fechaLimite,textoBoton,link,listaUrls,listaDocCompleto},null,2)
         console.log(jsonCapacitacion)
        // //alert(jsonTema);
        Actualizar({jsonCapacitacion})
        
      },
    });
    return (
        <div className="eva-dashboard-editarCapaMia" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{"Editar " + tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="fechaLimite">Fecha límite de inscripción</label>
                                <CalendarDefault
                                    id="fechaLimite"
                                    name="fechaLimite"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fechaLimite && new Date(formik.values.fechaLimite)}
                                    placeholder="dd/mm/aaaa"
                                    //disabled={!enableFProgramar}
                                    classesname={"white"}
                                    
                                    dateFormat="dd/mm/yy"
                                ></CalendarDefault>
                                <p className="error">{formik.errors.fechaLimite && formik.touched.fechaLimite && formik.errors.fechaLimite}</p>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="textoBoton">Escribe el texto del botón</label>
                                <InputDefault
                                    id="textoBoton"
                                    name="textoBoton"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.textoBoton != undefined && (formik.errors.textoBoton ? "error" : "success")}
                                    value={formik.values.textoBoton}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.textoBoton && formik.touched.textoBoton && formik.errors.textoBoton}</p>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="link">Inserta link</label>
                                <InputDefault id="link" name="link" className="white" placeholder="Escribe aquí" errorClass={formik.touched.link != undefined && (formik.errors.link ? "error" : "success")} 
                                value={formik.values.link} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <p className="error">{formik.errors.link && formik.touched.link && formik.errors.link}</p>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega documentos</label>
                            </div>
                            <FieldArray
                                name="documentos"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.documentos &&
                                                    formik.values.documentos.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.documentos[index].nombreDocumento.length > 0 ? formik.values.documentos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                                </label>
                                                                {formik.values.documentos[index].nombreDocumento != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpers)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`documentos[${index}].nombreDocumento`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`documentos[${index}].nombreDocumento`}
                                                                    name={`documentos[${index}].nombreDocumento`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`documentos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega links de videos</label>
                            </div>
                            <FieldArray
                                name="listaUrls"
                                render={(arrayHelpersLink) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaUrls &&
                                                    formik.values.listaUrls.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <InputIcon
                                                                    id={`listaUrls[${index}].nombreDocumento`}
                                                                    name={`listaUrls[${index}].nombreDocumento`}
                                                                    className="white"
                                                                    placeholder="Ingresa el link aquí"
                                                                    errorClass={formik.touched.nombreDocumento != undefined && (formik.errors.nombreDocumento ? "error" : "success")}
                                                                    value={formik.values.listaUrls[index].nombreDocumento}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    icon={formik.values.listaUrls[index].nombreDocumento != "" && <Iconsax.Trash />}
                                                                    onClickIcon={() => onDeleteLink(index, arrayHelpersLink)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLink.push({id:0,
                                                idCapacitacionMia:0,nombreDocumento:'',tipo:'Url',estado:false,fechaEliminacion:null,usuarioEliminacion:null})}>
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton
                                nombre={"Cancelar"}
                                color="secondary"
                                widths="184px"
                                metodoClick={()=>navigate(-1)}
                            ></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>  
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default EditarMia;