import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field, FieldArray, Formik, useFormik, FormikProvider } from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import { ObtenerGerenciaPorId } from "../../../service/GerenciaService";
import { ActualizarDescripciónCapacitacioOpcional, ObtenerCapacitacionOpcional, ObtenerCapacitacionOpcionalPorId, ObtenerCapacitacionPorGerencia } from "../../../service/CapacitacionService";
import ToastEva from "../../../components/Toast/ToastEva";
import Boton from "../../../components/Boton/Boton";
const EditarDescripcionCapacitacion = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("");
    const [gerencia, setGerencia] = useState(null);
    const [capacitacionOpcional, setCapacitacionOpcional] = useState(null);
    const toast = useRef(null);
    let { IdGerencia } = useParams();

    useEffect(() => {
        const getGerencia = async () => {
            let jwt = window.localStorage.getItem("jwt");
            let Id = IdGerencia;
            await ObtenerGerenciaPorId({ Id, jwt }).then((data) => {
                setGerencia(data);
                setTituloPagina("Editar:" + data.nombre);
            });
        };
        if (!gerencia) getGerencia();
    }, [IdGerencia]);
    useEffect(() => {
        const getCapacitacion = async () => {
            let jwt = window.localStorage.getItem("jwt");
            let Id = IdGerencia;
            await ObtenerCapacitacionPorGerencia({ Id, jwt }).then((data) => {
                if (data) {
                    if (data.length > 0) {
                        let temp = data.filter((x) => x.descripcion != null);
                        if (temp.length > 0) setCapacitacionOpcional(temp[0]);
                    }
                }
            });
        };
        if (!capacitacionOpcional) getCapacitacion();
    }, [IdGerencia]);

    const Actualizar = ({ jsonCapacitacion }) => {
        let jwt = window.localStorage.getItem("jwt");
        ActualizarDescripciónCapacitacioOpcional({ jsonCapacitacion, jwt })
            .then((data) => {
                formik.setSubmitting(false);
                toast.current.show({ severity: "success", summary: "Success", detail: "Registro exitoso.", life: 7000 });

                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            })
            .catch((errors) => {
                toast.current.show({ severity: "error", summary: "Error", detail: errors.message, life: 7000 });
            });
    };
    const schema = Yup.object().shape({
        descripcion: Yup.string().required("Campo nombre de capacitación requerido."),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idGerencia: capacitacionOpcional ? capacitacionOpcional.idGerencia : 0,

            descripcion: capacitacionOpcional ? capacitacionOpcional.descripcion : "",
        },
        validationSchema: schema,
        onSubmit: (values) => {
            let idGerencia = IdGerencia;
            let descripcion = values.descripcion;
            let jsonCapacitacion = JSON.stringify({ idGerencia, descripcion }, null, 2);
            console.log(jsonCapacitacion);
            // //alert(jsonTema);
            Actualizar({ jsonCapacitacion });
        },
    });
    return (
        <div className="eva-editarDescripcion">
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-editarDescripcion-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12">
                            <label htmlFor="nombre">Descripción</label>
                            <TextareaDefault
                                id="descripcion"
                                minHeight={180}
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.errors.descripcion && formik.touched.descripcion ? "error" : "success"}
                                value={formik.values.descripcion}
                                icon={<Iconsax.DocumentText1 />}
                                name="descripcion"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                        </div>
                    </div>
                    <div className="p-eva-footer-form">
                        <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                        <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditarDescripcionCapacitacion;
