
import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../../components/TextArea/TextArea";
import MultiselectEva from "../../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../../components/Boton/Boton";
import ToastEva from "../../../../components/Toast/ToastEva";
import CalendarDefault from "../../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../../components/InputDefault/InputDefault";
import InputIcon from "../../../../components/InputIcon/InputIcon";
import { ObtenerClimaComite,ActualizarClimaComite} from "../../../../service/ClimaService";
import classNames from "classnames";
import { ListUsersTag } from "../../../../service/UsuariosService";

import { Panel } from 'primereact/panel';

const Comites = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState("Editar comités");
    const [modoEdicion, setModoEdicion] = useState(true);
    const [comite, setComite] = useState(null);
    const [comboEncuesta, setComboEncuesta] = useState(null);
    const [selectedMakisContacto, setSelectedMakisContacto] = useState([]);
    const [selectedMakisColaborador, setSelectedMakisColaborador] = useState([]);
    const [selectedHostigamientoContactoZegel, setSelectedHostigamientoContactoZegel] = useState([]);
    const [selectedHostigamientoColaboradorZegel, setSelectedHostigamientoColaboradorZegel] = useState([]);

    const [selectedHostigamientoContactoIdat, setSelectedHostigamientoContactoIdat] = useState([]);
    const [selectedHostigamientoColaboradorIdat, setSelectedHostigamientoColaboradorIdat] = useState([]);

    const [selectedHostigamientoContactoCorriente, setSelectedHostigamientoContactoCorriente] = useState([]);
    const [selectedHostigamientoColaboradorCorriente, setSelectedHostigamientoColaboradorCorriente] = useState([]);

    const [selectedHostigamientoContactoCentro, setSelectedHostigamientoContactoCentro] = useState([]);
    const [selectedHostigamientoColaboradorCentro, setSelectedHostigamientoColaboradorCentro] = useState([]);

    const [selectedHostigamientoContactoIts, setSelectedHostigamientoContactoIts] = useState([]);
    const [selectedHostigamientoColaboradorIts, setSelectedHostigamientoColaboradorIts] = useState([]);
    const [listUserTotal, setListUserTotal] = useState([]);

    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    let { idComite } = useParams();

    useEffect(()=>{
        const getComite = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerClimaComite({jwt}).then(data => 
                {
                    setComite(data)
                    let usuariosGrupo = [];
                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedMakisColaborador(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-CONTACTO" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-CONTACTO" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedMakisContacto(usuariosGrupo)
                        usuariosGrupo=[]
                    }
                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ZEGEL" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ZEGEL" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoColaboradorZegel(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ZEGEL" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ZEGEL" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoContactoZegel(usuariosGrupo)
                        usuariosGrupo=[]
                    }


                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-IDAT" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-IDAT" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoColaboradorIdat(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-IDAT" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-IDAT" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoContactoIdat(usuariosGrupo)
                        usuariosGrupo=[]
                    }


                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CORRIENTE" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CORRIENTE" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoColaboradorCorriente(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CORRIENTE" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CORRIENTE" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoContactoCorriente(usuariosGrupo)
                        usuariosGrupo=[]
                    }


                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CENTRO" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CENTRO" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoColaboradorCentro(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CENTRO" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CENTRO" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoContactoCentro(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ITS" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ITS" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoColaboradorIts(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                    if(data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ITS" && x.estado == true).length > 0)
                    {
                        data.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ITS" && x.estado == true).map((item,i)=>{
                            usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                        })
                        setSelectedHostigamientoContactoIts(usuariosGrupo)
                        usuariosGrupo=[]
                    }

                })
        }
        if(!comite)getComite()
    },[])

    useEffect(()=>{
        if(listUserTotal.length === 0)
        {
            let jwt = window.localStorage.getItem("jwt");
            ListUsersTag(jwt).then(data => setListUserTotal(data))
        }
     },[]);

    const schema = Yup.object().shape({
        // descripcion: Yup.string().required("Campor descripción requerido."),
        // listaMeses: Yup.array()
        // .of(
        //     Yup.object().shape({
        //         mes:Yup.string().nullable().required("Campo mes requerido."),
        //         idEncuesta: Yup.number().nullable().required("Campo encuesta requerido."),
        //         fechaFinalizacion: Yup.string().nullable().required("Campo Fecha finalización requerido")
        //     })
        // )
        
    });

    const Actualizar =({jsonComite})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarClimaComite({jsonComite,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:comite && comite.id,
            descripcionIntervencion : comite && comite.descripcionIntervencion,
            descripcionMaki : comite && comite.descripcionMaki,
            descripcionHostigamiento : comite && comite.descripcionHostigamiento,
            subtituloMaki: comite && comite.subtituloMaki,
            subtituloHostigamientoZegel: comite && comite.subtituloHostigamientoZegel,
            subtituloHostigamientoIdat: comite && comite.subtituloHostigamientoIdat,
            subtituloHostigamientoCorriente: comite && comite.subtituloHostigamientoCorriente,
            subtituloHostigamientoCentro: comite && comite.subtituloHostigamientoCentro,
            subtituloHostigamientoIts: comite && comite.subtituloHostigamientoIts,
            subtitulo1: comite && comite.subtitulo1,
            subtitulo2: comite && comite.subtitulo2,
            subtitulo3: comite && comite.subtitulo3,

            listaUsuariosMaki : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"MAKI-COLABORADOR"}],
            selectedMakisColaborador :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).length > 0 && selectedMakisColaborador.length > 0 ? selectedMakisColaborador:[],
            
            listaContactoMaki : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-CONTACTO" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-CONTACTO" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"MAKI-CONTACTO"}],
            selectedMakisContacto :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-CONTACTO" && x.estado == true).length > 0 && selectedMakisContacto.length > 0 ? selectedMakisContacto:[],

            
            listaUsuariosHostigamientoZegel : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ZEGEL" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ZEGEL" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-COLABORADOR-ZEGEL"}],
            selectedHostigamientoColaboradorZegel :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ZEGEL" && x.estado == true).length > 0 && selectedHostigamientoColaboradorZegel.length > 0 ? selectedHostigamientoColaboradorZegel:[],
            
            listaContactoHostigamientoZegel : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ZEGEL" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ZEGEL" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-CONTACTO-ZEGEL"}],
            selectedHostigamientoContactoZegel :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ZEGEL" && x.estado == true).length > 0 && selectedHostigamientoContactoZegel.length > 0 ? selectedHostigamientoContactoZegel:[],


            listaUsuariosHostigamientoIdat : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-IDAT" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-IDAT" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-COLABORADOR-IDAT"}],
            selectedHostigamientoColaboradorIdat :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-IDAT" && x.estado == true).length > 0 && selectedHostigamientoColaboradorIdat.length > 0 ? selectedHostigamientoColaboradorIdat:[],

            listaContactoHostigamientoIdat : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-IDAT" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-IDAT" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-CONTACTO-IDAT"}],
            selectedHostigamientoContactoIdat :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-IDAT" && x.estado == true).length > 0 && selectedHostigamientoContactoIdat.length > 0 ? selectedHostigamientoContactoIdat:[],


            listaUsuariosHostigamientoCorriente : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CORRIENTE" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CORRIENTE" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-COLABORADOR-CORRIENTE"}],
            selectedHostigamientoColaboradorCorriente :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CORRIENTE" && x.estado == true).length > 0 && selectedHostigamientoColaboradorCorriente.length > 0 ? selectedHostigamientoColaboradorCorriente:[],

            listaContactoHostigamientoCorriente : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CORRIENTE" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CORRIENTE" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-CONTACTO-CORRIENTE"}],
            selectedHostigamientoContactoCorriente :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CORRIENTE" && x.estado == true).length > 0 && selectedHostigamientoContactoCorriente.length > 0 ? selectedHostigamientoContactoCorriente:[],


            listaUsuariosHostigamientoCentro : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CENTRO" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CENTRO" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-COLABORADOR-CENTRO"}],
            selectedHostigamientoColaboradorCentro :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CENTRO" && x.estado == true).length > 0 && selectedHostigamientoColaboradorCentro.length > 0 ? selectedHostigamientoColaboradorCentro:[],

            listaContactoHostigamientoCentro : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CENTRO" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CENTRO" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-CONTACTO-CENTRO"}],
            selectedHostigamientoContactoCentro :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CENTRO" && x.estado == true).length > 0 && selectedHostigamientoContactoCentro.length > 0 ? selectedHostigamientoContactoCentro:[],


            listaUsuariosHostigamientoIts : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ITS" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ITS" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-COLABORADOR-ITS"}],
            selectedHostigamientoColaboradorIts :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ITS" && x.estado == true).length > 0 && selectedHostigamientoColaboradorIts.length > 0 ? selectedHostigamientoColaboradorIts:[],

            listaContactoHostigamientoIts : comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ITS" && x.estado == true).length > 0 ?
            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ITS" && x.estado == true)
            :[{id:0,IdClimaComite:0,idUsuario:0,seccion:"HOSTIGAMIENTO-CONTACTO-ITS"}],
            selectedHostigamientoContactoIts :comite && comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ITS" && x.estado == true).length > 0 && selectedHostigamientoContactoIts.length > 0 ? selectedHostigamientoContactoIts:[],

        },
      validationSchema: schema,
      onSubmit: values => {
       

        let id = values.id
        let descripcionIntervencion = values.descripcionIntervencion
        let descripcionMaki = values.descripcionMaki
        let descripcionHostigamiento = values.descripcionHostigamiento
        let subtituloMaki = values.subtituloMaki
        let subtituloHostigamientoZegel = values.subtituloHostigamientoZegel
        let subtituloHostigamientoIdat = values.subtituloHostigamientoIdat
        let subtituloHostigamientoCorriente = values.subtituloHostigamientoCorriente
        let subtituloHostigamientoCentro = values.subtituloHostigamientoCentro
        let subtituloHostigamientoIts = values.subtituloHostigamientoIts
        let subtitulo1= values.subtitulo1
        let subtitulo2= values.subtitulo2
        let subtitulo3= values.subtitulo3
        let listaUsuariosMaki = values.listaUsuariosMaki
        let listaContactoMaki = values.listaContactoMaki
        let listaUsuariosHostigamientoZegel = values.listaUsuariosHostigamientoZegel
        let listaContactoHostigamientoZegel = values.listaContactoHostigamientoZegel

        let listaUsuariosHostigamientoIdat = values.listaUsuariosHostigamientoIdat
        let listaContactoHostigamientoIdat = values.listaContactoHostigamientoIdat

        let listaUsuariosHostigamientoCorriente = values.listaUsuariosHostigamientoCorriente
        let listaContactoHostigamientoCorriente = values.listaContactoHostigamientoCorriente

        let listaUsuariosHostigamientoCentro = values.listaUsuariosHostigamientoCentro
        let listaContactoHostigamientoCentro = values.listaContactoHostigamientoCentro

        let listaUsuariosHostigamientoIts = values.listaUsuariosHostigamientoIts
        let listaContactoHostigamientoIts = values.listaContactoHostigamientoIts

        let jsonComite = JSON.stringify({id,descripcionIntervencion,descripcionMaki,descripcionHostigamiento,
            subtituloMaki,subtituloHostigamientoZegel,subtituloHostigamientoIdat,subtituloHostigamientoCorriente,
            subtituloHostigamientoCentro,subtituloHostigamientoIts,
            listaUsuariosMaki,listaContactoMaki,listaUsuariosHostigamientoZegel,listaContactoHostigamientoZegel,
            listaUsuariosHostigamientoIdat,listaContactoHostigamientoIdat,listaUsuariosHostigamientoCorriente,listaContactoHostigamientoCorriente,
            listaUsuariosHostigamientoCentro,listaContactoHostigamientoCentro,listaUsuariosHostigamientoIts,listaContactoHostigamientoIts,
            subtitulo1,subtitulo2,subtitulo3
            },null,2)
       //alert(jsonComite)
       //console.log(jsonComite)
       //formik.setSubmitting(false)

        Actualizar({jsonComite})
        
        
      },
    });

    return (
        <div className="eva-dashboard-editarComite" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        {/* <div className="field col-12 md:col-12 header-subtitle">Comité de intervención</div> */}
                        <label htmlFor="subtitulo1" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtitulo1`}
                                            name={`subtitulo1`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtitulo1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>
                        <div className="field col-12 md:col-12">
                            <label htmlFor="descripcionIntervencion" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <TextareaDefault
                                id="descripcionIntervencion"
                                minHeight={180}
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.errors.descripcionIntervencion && formik.touched.descripcionIntervencion ? "error" : "success"}
                                value={formik.values.descripcionIntervencion}
                                icon={<Iconsax.DocumentText1 />}
                                name="descripcionIntervencion"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <small className="p-error">{formik.errors.descripcionIntervencion && formik.touched.descripcionIntervencion}</small>
                        </div>

                        <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>

                        {/* <div className="field col-12 md:col-12 header-subtitle">Comité de diversidad “Makipura”</div> */}

                        <label htmlFor="subtitulo2" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtitulo2`}
                                            name={`subtitulo2`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtitulo2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>


                        <div className="field col-12 md:col-12">
                            <label htmlFor="descripcionMaki" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <TextareaDefault
                                id="descripcionMaki"
                                minHeight={180}
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.errors.descripcionMaki && formik.touched.descripcionMaki ? "error" : "success"}
                                value={formik.values.descripcionMaki}
                                icon={<Iconsax.DocumentText1 />}
                                name="descripcionMaki"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <small className="p-error">{formik.errors.descripcionMaki && formik.touched.descripcionMaki}</small>
                        </div>
                        <div  className="field col-12 md:col-12">
                            <label htmlFor="subtituloMaki" className="p-eva-labelForm">
                                Añade un subtítulo
                            </label>
                            <InputDefault
                                id={`subtituloMaki`}
                                name={`subtituloMaki`}
                                placeholder="Escribe aquí"
                                className="White"
                                value={formik.values.subtituloMaki}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            ></InputDefault>
                        </div>
                        <div className="field col-12 md:col-9">
                            <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                            <MultiselectEva
                                onChange={(listUser) => {
                                    console.log(listUser);
                                    let temp = [];
                                    listUser.map((item, i) => {
                                        temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                    });
                                    formik.setFieldValue(`listaUsuariosMaki`, temp);
                                    formik.setFieldValue(`selectedMakisColaborador`, listUser);
                                }}
                                isMulti
                                //options={selectButtonValues1}
                                options={listUserTotal}
                                value={formik.values.selectedMakisColaborador}
                            />
                        </div>
                        <div className="field col-12 md:col-9">
                            <label htmlFor="buscarColaborador">Información de contacto en caso el colaborador necesite ayuda</label>
                            <MultiselectEva
                                onChange={(listUser) => {
                                    console.log(listUser);
                                    let temp = [];
                                    listUser.map((item, i) => {
                                        temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                    });
                                    formik.setFieldValue(`listaContactoMaki`, temp);
                                    formik.setFieldValue(`selectedMakisContacto`, listUser);
                                }}
                                isMulti
                                //options={selectButtonValues1}
                                options={listUserTotal}
                                value={formik.values.selectedMakisContacto}
                            />
                        </div>

                        <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>

                        {/* <div className="field col-12 md:col-12 header-subtitle">Comité ante el hostigamiento sexual y laboral</div> */}

                        <label htmlFor="subtitulo3" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtitulo3`}
                                            name={`subtitulo3`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtitulo3}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>

                        <div className="field col-12 md:col-12">
                            <label htmlFor="descripcionHostigamiento" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <TextareaDefault
                                id="descripcionHostigamiento"
                                minHeight={180}
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.errors.descripcionHostigamiento && formik.touched.descripcionHostigamiento ? "error" : "success"}
                                value={formik.values.descripcionHostigamiento}
                                icon={<Iconsax.DocumentText1 />}
                                name="descripcionHostigamiento"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <small className="p-error">{formik.errors.descripcionHostigamiento && formik.touched.descripcionHostigamiento}</small>
                        </div>

                        <div className="field col-12 md:col-12">
                            <Panel
                                 className="p-eva-panel-acordeon comite"
                                 header={"Zegel"} toggleable 
                                 expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                                 collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                                 collapsed
                            >
                                <div className="p-fluid formgrid grid">
                                    <div  className="field col-12 md:col-12">
                                        <label htmlFor="subtituloHostigamientoZegel" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtituloHostigamientoZegel`}
                                            name={`subtituloHostigamientoZegel`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtituloHostigamientoZegel}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaUsuariosHostigamientoZegel`, temp);
                                                formik.setFieldValue(`selectedHostigamientoColaboradorZegel`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoColaboradorZegel}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Información de contacto en caso el colaborador necesite ayuda</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaContactoHostigamientoZegel`, temp);
                                                formik.setFieldValue(`selectedHostigamientoContactoZegel`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoContactoZegel}
                                        />
                                    </div>
                                </div>
                            </Panel>

                        </div>
                        <div className="field col-12 md:col-12">
                            <Panel
                                 className="p-eva-panel-acordeon comite"
                                 header={"Idat"} toggleable 
                                 expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                                 collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                                 collapsed
                            >
                                <div className="p-fluid formgrid grid">
                                    <div  className="field col-12 md:col-12">
                                        <label htmlFor="subtituloHostigamientoIdat" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtituloHostigamientoIdat`}
                                            name={`subtituloHostigamientoIdat`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtituloHostigamientoIdat}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaUsuariosHostigamientoIdat`, temp);
                                                formik.setFieldValue(`selectedHostigamientoColaboradorIdat`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoColaboradorIdat}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Información de contacto en caso el colaborador necesite ayuda</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaContactoHostigamientoIdat`, temp);
                                                formik.setFieldValue(`selectedHostigamientoContactoIdat`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoContactoIdat}
                                        />
                                    </div>
                                </div>
                            </Panel>

                        </div>
                        <div className="field col-12 md:col-12">
                            <Panel
                                 className="p-eva-panel-acordeon comite"
                                 header={"Corriente Alterna"} toggleable 
                                 expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                                 collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                                 collapsed
                            >
                                <div className="p-fluid formgrid grid">
                                    <div  className="field col-12 md:col-12">
                                        <label htmlFor="subtituloHostigamientoCorriente" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtituloHostigamientoCorriente`}
                                            name={`subtituloHostigamientoCorriente`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtituloHostigamientoCorriente}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaUsuariosHostigamientoCorriente`, temp);
                                                formik.setFieldValue(`selectedHostigamientoColaboradorCorriente`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoColaboradorCorriente}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Información de contacto en caso el colaborador necesite ayuda</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaContactoHostigamientoCorriente`, temp);
                                                formik.setFieldValue(`selectedHostigamientoContactoCorriente`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoContactoCorriente}
                                        />
                                    </div>
                                </div>
                            </Panel>

                        </div>
                        <div className="field col-12 md:col-12">
                            <Panel
                                 className="p-eva-panel-acordeon comite"
                                 header={"Centro de la imagen"} toggleable 
                                 expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                                 collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                                 collapsed
                            >
                                <div className="p-fluid formgrid grid">
                                    <div  className="field col-12 md:col-12">
                                        <label htmlFor="subtituloHostigamientoCentro" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtituloHostigamientoCentro`}
                                            name={`subtituloHostigamientoCentro`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtituloHostigamientoCentro}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaUsuariosHostigamientoCentro`, temp);
                                                formik.setFieldValue(`selectedHostigamientoColaboradorCentro`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoColaboradorCentro}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Información de contacto en caso el colaborador necesite ayuda</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaContactoHostigamientoCentro`, temp);
                                                formik.setFieldValue(`selectedHostigamientoContactoCentro`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoContactoCentro}
                                        />
                                    </div>
                                </div>
                            </Panel>

                        </div>
                        <div className="field col-12 md:col-12">
                            <Panel
                                 className="p-eva-panel-acordeon comite"
                                 header={"Innova Teaching School"} toggleable 
                                 expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                                 collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                                 collapsed
                            >
                                <div className="p-fluid formgrid grid">
                                    <div  className="field col-12 md:col-12">
                                        <label htmlFor="subtituloHostigamientoIts" className="p-eva-labelForm">
                                            Añade un subtítulo
                                        </label>
                                        <InputDefault
                                            id={`subtituloHostigamientoIts`}
                                            name={`subtituloHostigamientoIts`}
                                            placeholder="Escribe aquí"
                                            className="grey"
                                            value={formik.values.subtituloHostigamientoIts}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></InputDefault>
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaUsuariosHostigamientoIts`, temp);
                                                formik.setFieldValue(`selectedHostigamientoColaboradorIts`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoColaboradorIts}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-9">
                                        <label htmlFor="buscarColaborador">Información de contacto en caso el colaborador necesite ayuda</label>
                                        <MultiselectEva
                                            onChange={(listUser) => {
                                                
                                                let temp = [];
                                                listUser.map((item, i) => {
                                                    temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                                });
                                                formik.setFieldValue(`listaContactoHostigamientoIts`, temp);
                                                formik.setFieldValue(`selectedHostigamientoContactoIts`, listUser);
                                            }}
                                            isMulti
                                            //options={selectButtonValues1}
                                            options={listUserTotal}
                                            value={formik.values.selectedHostigamientoContactoIts}
                                        />
                                    </div>
                                </div>
                            </Panel>

                        </div>
                        
                    </div>
                    <div className="p-eva-footer-form">
                        <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                        <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                    </div>
                </form>
            </div>
        </div>
    );
}
 
export default Comites;