import { Skeleton } from "primereact/skeleton";
const Sk_DirectorioDetalle = () => {
    return ( 
        <>
        <div>
            <Skeleton width="300px" style={{marginBottom:"20px"}}></Skeleton>
            <div className="p-fluid formgrid grid" style={{marginTop:"20px"}}>
                <div className="field col-12 md:col-4">
                    <Skeleton borderRadius="16px" height="211px"/>
                </div>
                <div className="field col-12 md:col-4">
                    <Skeleton borderRadius="16px" height="211px"/>
                </div>
                <div className="field col-12 md:col-4">
                    <Skeleton borderRadius="16px" height="211px"/>
                </div>
            </div>
            <Skeleton width="300px"></Skeleton>
            <div className="p-fluid formgrid grid" style={{marginTop:"20px"}}>
                <div className="field col-12 md:col-4">
                    <Skeleton borderRadius="16px" height="211px"/>
                </div>
                <div className="field col-12 md:col-4">
                    <Skeleton borderRadius="16px" height="211px"/>
                </div>
                <div className="field col-12 md:col-4">
                    <Skeleton borderRadius="16px" height="211px"/>
                </div>
            </div>
        </div>
            

        </>
     );
}
 
export default Sk_DirectorioDetalle;