import React, { useState, useEffect } from 'react';
import {useNavigate,Link,useParams} from 'react-router-dom';
import InputDefault from '../../../components/InputDefault/InputDefault';
import DropdownDefault from '../../../components/DropdownDefault/Dropdown';
import InputIcon from '../../../components/InputIcon/InputIcon';
import MultiselectEva from '../../../components/Multiselect/MultiSelectEva';

import BotonEva from   '../../../components/Boton/Boton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import {ListUsersTag,UsersGerencia } from "../../../service/UsuariosService";
import {ListarGerencias} from "../../../service/GerenciaService";
import  usePermisos from "../../../hooks/usePermisos"
import {Avatar} from 'primereact/avatar';

import useUsuario from "../../../hooks/useUsuario";
import useGrupos from "../../../hooks/useGrupos";
import * as Iconsax from "iconsax-react";
import "../Grupos.scss"
import * as constantes from "../../../constants/constantes.js";
import "./CrearGrupo.scss"
import { FastField, Formik } from "formik";
import * as Yup from "yup";
const CrearGrupo = () =>{
    const navigate = useNavigate();
    const [checked, setChecked] = useState([]);
    const { permisos,isLogged,perfil} = useUsuario();
    const {CrearGrupoUsuario,isCreated,GetGrupo,grupo,ActualizarGrupoUsuario,isUpdate} = useGrupos();
    const [arrayUsersGerencia,setArrayUsersGerencia] = useState([])
    const [listUserTotal, setListUserTotal] = useState([]);
    const [listGerencias, setListGerencias] = useState([]);
    const [listGeneral, setlistGeneral] = useState([]);
    const [selectedGerencia,setselectedGerencia] = useState(-1);
    const [htmlCheckBox,setHtmlCheckBox] = useState(null)
    const [render,setRender] = useState(true)
    const [selected, setSelected] = useState([]);
    const [stateImage,setStateImage] = useState({file: null,base64URL: ""})   
    const [eventSearch, setEventSearch] =useState(false) ;
    const [eventChecked, setEventChecked] =useState(false) ;
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [tituloPagina, setTituloPagina] = useState("Crea grupo");
    const [modoEdicion, setModoEdicion] = useState(false);
    
    let { idGrupo } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        
        if(permisos.length >0)
        {
            !(permisos.indexOf("creareditargrupos") > -1  || perfil.esJefe) && navigate("/")
        }

    },[permisos,perfil])

    useEffect(()=>{
        const CargarUsuariosGrupos = async(grupoSelected)=>
        {
            let usuariosGrupo = [];

            await grupoSelected.usuarios.map((item,i)=>{
                usuariosGrupo.push({label: item.nombre,value:String(item.id)})
            })
            setSelected(usuariosGrupo);
        }
        if(grupo && selected.length ==0 && !modoEdicion) {
            
            setTituloPagina("Editar grupo "+grupo.nombre)
            setModoEdicion(true)
            CargarUsuariosGrupos(grupo);
        }

        
    },[grupo])
    useEffect(()=>{
        const ObtenerGrupo =async ()=>{
           await GetGrupo({idGrupo});
           
        }
        if(idGrupo){
            ObtenerGrupo();
        } 
    },[])
   

    useEffect(() => {
        if (isCreated || isUpdate) {
            navigate(-1);
        }
    }, [isCreated,isUpdate]);
    useEffect( () => {
        
        if(selectedGerencia != -1 && render === true && arrayUsersGerencia.length ===0)
        {
            let id = selectedGerencia;
            //UsersUsuarioGerencia({id})
            let jwt = window.localStorage.getItem("jwt");
            UsersGerencia({id,jwt}).then((data) => {
                        setArrayUsersGerencia(data);
                     })
        }
        

     },[selectedGerencia]);

     useEffect(()=>{
        if(listUserTotal.length === 0)
        {
            let jwt = window.localStorage.getItem("jwt");
            ListUsersTag(jwt).then(data => setListUserTotal(data))
        }
        

     },[]);

     useEffect(()=>{
        if(listGerencias.length === 0)
        {
            let jwt = window.localStorage.getItem("jwt");
            ListarGerencias(jwt).then(data => setListGerencias(data))
        }
        

     },[]);
     useEffect(()=>{
         if(eventChecked === true || eventSearch === true)
         {
            var listTotal = [];
            var listSearch = [];
            if(selected.length > 0)
            {
                selected.map((item,i) =>{
                    listSearch.push(item.value);
                })
            }
            var listChecked = [...checked];
            listTotal = [...listSearch,...listChecked];
            if(listTotal.length > 0)setlistGeneral(listTotal)
            setEventChecked(false);
            setEventSearch(false)
        }
        
     })

    const handleSelectAll = e => {
        if(arrayUsersGerencia.length > 0)
        {   
            setEventChecked(true)
            setIsCheckAll(!isCheckAll);
            //setIsCheck(list.map(li => li.id));
            setChecked(arrayUsersGerencia[0].users.map(li=>li.id))
            if (isCheckAll) {
                setChecked([]);
            }
        }
        
    };
    const onCheckboxChange = (event) => {
        setEventChecked(true)
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
        
        console.log(checked)
    };

    const handleSelectChange = (values) => {
        setEventSearch(true)
        setSelected(values);
        console.log(values)
    };

    const handleSelectGerenciaChange =  (e) => {
        setArrayUsersGerencia([]) 
        setRender(true)
        setHtmlCheckBox("cargando")
        setselectedGerencia(e.value)
        //let id= e.id
        // UsersUsuarioGerencia({id})
        //RenderResult(id)
        
        
    };
    
    const schema = Yup.object().shape({
        nombreGrupo: Yup.string().required("Nombre de grupo es un campo obligatorio"),
        //imageGrupo: Yup.string().required("Foto de grupo es un campo obligatorio")
    });

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          //console.log(str1.replace(regex,""))
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    const onFileChange=(e)=>
    {
        console.log(e.target.files[0]);
        let { file } = stateImage;

        file = e.target.files[0];

        getBase64(file)
        .then(result => {
            file["base64"] = result;
            console.log("File Is", file);
            setStateImage({
                base64URL: result,
                file
            });
        })
        .catch(err => {
            console.log(err);
        });

        setStateImage({
         file: e.target.files[0]
        });
    }

    return (
        <div className="eva-dashboard-crearGrupo">
            <div className="eva-dashboard-header">
                <div>
                    <i onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label>{tituloPagina}</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
                <Formik
                    enableReinitialize={true}
                    validationSchema={schema}
                    initialValues={{ nombreGrupo: modoEdicion?grupo.nombre:"", imageGrupo: "" }}
                    onSubmit={(values) => {
                        //GetUsersSelected();
                        let Id = modoEdicion?grupo.id:0;
                        let Nombre = values.nombreGrupo;
                        let Imagen = stateImage.file === null ? "":stateImage.file.name;
                        let Tipo = "Privado";
                        let idUsers = [...listGeneral];
                        let ImagenBase64 = stateImage.file === null?"": stateImage.base64URL;
                        let jsonGrupos = JSON.stringify({ Id,Nombre, Imagen, Tipo, ImagenBase64, idUsers, ImagenBase64 });

                        modoEdicion ? ActualizarGrupoUsuario({ jsonGrupos }):CrearGrupoUsuario({ jsonGrupos });
                        
                        //ActualizarGrupoUsuario({ jsonGrupos })
                        // console.log(jsonGrupos)
                        // alert(JSON.stringify(jsonGrupos));
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="imageGrupo">Adjunta una foto de portada</label>
                                    <InputIcon id="imageGrupo" placeholder="Adjunta aquí" className="White" icon={<Iconsax.GalleryAdd />} name="imageGrupo" type="File" onChange={onFileChange} accept="image/*" />
                                    <p className="error">{errors.imageGrupo && touched.imageGrupo && errors.imageGrupo}</p>
                                </div>
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="nombreGrupo">Nombre del grupo</label>
                                    <InputDefault id="nombreGrupo" className="white" placeholder="Escribe aquí" value={values.nombreGrupo} onChange={handleChange} onBlur={handleBlur} name="nombreGrupo" />
                                    <p className="error">{errors.nombreGrupo && touched.nombreGrupo && errors.nombreGrupo}</p>
                                </div>
                            </div>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-9">
                                    <label htmlFor="buscarColaborador">Puedes añandir a los colaboradores escribiendo su nombre o código institucional directamente.</label>
                                    <MultiselectEva
                                        onChange={handleSelectChange}
                                        isMulti
                                        //options={selectButtonValues1}
                                        options={listUserTotal}
                                        value={selected}
                                    />
                                </div>
                            </div>

                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label htmlFor="busquedaAvanzada" className="p-eva-label-busquedaAdavanzada">
                                        ¿Deseas una búsqueda avanzada?
                                    </label>
                                </div>
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="Gerencia">Gerencia</label>
                                    <DropdownDefault placeholder="Seleccione" options={listGerencias} optionLabel="code" value={selectedGerencia} onChange={handleSelectGerenciaChange} />
                                    {/* <DropdownDefault placeholder="Seleccione" options={dropdownItemsGerencia} optionLabel="code" value={selectedGerencia} onChange={handleSelectGerenciaChange} /> */}
                                </div>
                                <div className="field col-12 md:col-4"/>
                                <div className="field col-12 md:col-4"/>
                                {
                                    arrayUsersGerencia.length > 0 && render === true &&
                                    
                                        <div className="field col-12 md:col-4">
                                            <div className="field-checkbox" >
                                                <Checkbox inputId="checkboxOptionAll" className="p-eva-checkbox" name="optionAll"   
                                                    onChange={handleSelectAll} checked={isCheckAll}/>
                                                    <label htmlFor="checkboxOptionAll">Seleccionar a todos</label>
                                                    
                                            </div>
                                            </div>
                                    
                                }
                                
                            </div>
                            <div className="p-fluid formgrid grid">
                                {
                                    //htmlCheckBox
                                    arrayUsersGerencia.length > 0 && render === true
                                        ? arrayUsersGerencia[0].users.sort((a, b) => {if (a.nombre < b.nombre) {return -1; }}).map((item, i) => {
                                              return (
                                                  <div className="field col-12 md:col-4" key={i}>
                                                      <div className="field-checkbox p-eva-checbox-container" style={{ justifyContent: "space-between" }} >
                                                          {/* <label htmlFor={"checkboxOption" + i}>{item.nombre}</label> */}

                                                        <Avatar className='p-eva_avatar' shape='circle' 
                                                            // image={item.foto === null || item.foto.length === 0 ?constantes.URLAVATARDEFAULT : constantes.URLAPI+"/Image/perfil/"+item.foto+"/"+item.id}>
                                                            image={item.foto === null || item.foto.length === 0 ?constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.id+"/"+item.foto}>
                                                            <label> {item.nombre}</label>

                                                        </Avatar>
                                                          <Checkbox inputId={"checkboxOption" + i} className="p-eva-checkbox" name="option" 
                                                          checked={checked.indexOf(item.id) !== -1} 
                                                          onChange={onCheckboxChange} value={item.id} />
                                                      </div>
                                                  </div>
                                              );
                                          }):null
                                        // : <div className="field col-12 md:col-12" ><label>Cargando...</label></div>
                                }
                            </div>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-4" />
                                <div className="field col-12 md:col-4" />
                                <div className="field col-12 md:col-2">
                                    {/* <Button label="Cancelar" /> */}

                                    <BotonEva nombre="Cancelar" color="secondary" type="button" metodoClick={() => navigate(-1)} widths="100%" />
                                </div>
                                <div className="field col-12 md:col-2">
                                    <BotonEva nombre={modoEdicion?"Actualizar":"Crear grupo"} color="primary" type="submit"  widths="100%"  />
                                    {/* <Button label="Crear grupo"
                                        //onClick={handleCreate} 
                                    /> */}
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default CrearGrupo