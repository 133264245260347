import React, { useState, useEffect,useRef} from 'react';
import { Route, useNavigate } from "react-router-dom";
import "./CardReglamento.scss";
import { Card } from 'primereact/card';
import Boton from "../Boton/Boton";
import CheckboxEva from "../CheckBoxEva/CheckboxEva";
import * as Iconsax from "iconsax-react";
import useSomosin from "../../hooks/useSomosin";
import { URLBLOB_ADJ_POLITICA } from "../../constants/constantes";
import DocumentViewer from "../PDF/DocumentViewer";


const CardReglamento =({titulo="Nombre reglamento", contenido="",idReglamento,accept,urlPdf,displayEdit="block",eliminar})=>{
    const navigate = useNavigate();
    const {RegistrarAceptacion,isCreatedAceptacion} = useSomosin();
    const handleEditReglamento =()=>{
        navigate("/Dashboard/SomosIn/Reglamento/Editar/"+idReglamento);
    }

    const [checked, setChecked] = useState(false);
    const [stateDisabled, setStateDisabled] = useState(accept);
    const [visibleModal, setVisibleModal] = useState(false);
    const [recargar, setrecargar] = useState(false);

    console.log("checked",checked)
    const handleChecked =(event)=>{
        console.log("event.checked",event.checked)
        setChecked(event.checked);
        let idReglamento = event.value;
        RegistrarAceptacion({idReglamento})
        setStateDisabled(true)
        //alert(event.value);
    }

    const handlePdfLeido = (leido) => {
        if(!checked){
            console.log('Pdf leido en CardReglamento:', leido);
            setChecked(true);
            RegistrarAceptacion({idReglamento})
            setrecargar(true)
        }else{
            console.log('PDF YA LEIDO');
            setrecargar(false)

        }
        
    };
    const handleClickDownload =(event)=>{
        // const pdfWindow = window.open();
        // pdfWindow.location.href = URLBLOB_ADJ_POLITICA+"/"+urlPdf; 
        // if(checked){
            fetch(URLBLOB_ADJ_POLITICA+"/"+urlPdf).then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = urlPdf;
                    alink.click();
                })
            })
        // }
    }

    //   src={'https://grinlearningqa9414.blob.core.windows.net/adjuntos/Reglamento/Plan%20de%20trabajo%20Eva.pdf'}
    //   type="application/pdf"
    const handleClickPreview =(e)=>
    {
        //return(<DocumentViewer urlPdf={urlPdf}></DocumentViewer>)
         //navigate("/PreviewPdf/"+urlPdf)
         const pdfPreviewWindow = window.open();
         pdfPreviewWindow.location.href = "/PreviewPdf/"+urlPdf
        //const pdfWindow = window.open('<div className="modal-body"><iframe src="../PlandetrabajoEva.pdf" frameBorder={0} width="100%" height="800px"/></div>',"_blank");
    }
    const handleClickPreviewV2 =(e)=>
    {
       setVisibleModal(true)
    }

    
    useEffect(()=>{
        setChecked(accept)
    },[accept])

    const TituloCard =() =>{
        return(
            <>
                <div className="CardReglamento-titulo">
                    <div style={{display:"flex",alignItems:"center",height:50}}><Iconsax.DocumentText1/></div>
                    <div className="CardReglamento-nombre">{titulo}</div>
                </div>
                
                <div className="CardReglamento-edit">
                    <div>
                        <i style={{display:displayEdit}}> 
                            <Iconsax.Edit2 color="#F47647" onClick={handleEditReglamento}/>
                        </i>
                    </div>
                    <div>
                        <i style={{display:displayEdit}}> 
                            <Iconsax.Trash color="#F47647" onClick={eliminar}/>
                        </i>
                    </div>
                    
                </div>
                
            </>
        )
    }
    const footer = <span >
         <div className="grid p-eva-footer-politica" style={{alignItems:"center"}}>
            {/* <div className="field col-12 md:col-4">
                <Boton nombre={"Previsualizar"} color="secondary" widths="80%" metodoClick={handleClickPreview}/>
            </div> */}
            <div className="col-12 md:col-6" >
                <Boton nombre={"Previsualizar"} color="secondary" widths="80%" metodoClick={handleClickPreviewV2}/>
            </div>
            <div className="col-12 md:col-6">
                <Boton nombre={"Descargar"} color="primary" widths="80%" metodoClick={handleClickDownload}/>
            </div>
         </div>
        
    </span>;

    return(
        <>
        <Card title={TituloCard} className={"CardReglamento"} footer={footer}>
            <div className="reglamento-descripcion">{contenido}</div>
           
        </Card>
        {visibleModal&&
            <DocumentViewer  visibleModal={visibleModal} urlFile={URLBLOB_ADJ_POLITICA+"/"+urlPdf} 
            // onHide={()=>setVisibleModal(false)} 
            onHide={() => {
                setVisibleModal(false);
                if (recargar) {
                    window.location.reload(); 
                  }
              }}
            onPdfLeido={handlePdfLeido} 
            accept={accept}></DocumentViewer>
        }
        
        
        </>
        
    )
}

export default CardReglamento