import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import {getPermisoModulo}from "../service/PermisosService";

export default function UsePermisos(){
    const {perfil,jwt,setPerfil,setJwt} = useContext(Context)
    const [permisosVista, setPermisosVista] = useState([]);     
    
    const obtenerPermisosVista = useCallback(async({vista})=>{
        await getPermisoModulo({jwt,vista})
        .then(data=>{
            setPermisosVista(data);
        })
        .catch(err=>{
            setPermisosVista([]);
        })
    },[setPermisosVista])

    return{
        obtenerPermisosVista,
        permisosVista
    }
}