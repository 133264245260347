import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionOpcional,ListarSeccionCapacitacion } from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import "./CapaOpcional.scss"
import Boton from "../../../components/Boton/Boton";
import * as constantes from "../../../constants/constantes";
import CardEntry from "../../../components/CardEntryEva/CardEntry";
import DropdownFiltro from "../../../components/DropdownFiltro/DropdownFiltro";
import CardGerencia from "../../../components/Gerencia/CardGerencia";

const CapaOpcionalGerencia = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [capaOpcional, setCapaOpcional] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    const [filtroSeleccionado, setFiltroSeleccionado] = useState(null);

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    

    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarCapacitacionesNoObligatorias") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verCapaIntroduccion") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])


    
    useEffect(()=>{
        const obtenerListaSeccionCapa = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccionCapacitacion({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccion);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccion){
            obtenerListaSeccionCapa();
        } 
    },[idSeccion])

    useEffect(()=>{
        const getCapaOpcional = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            ObtenerCapacitacionOpcional({jwt}).then(data=>{


                setCapaOpcional(data)
            })
            .catch(errors => {
                toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

            })
        }
        if(!capaOpcional)getCapaOpcional();
    },[])

    
    
    return (
        <div className="eva-capaOpcional" >
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header" style={{marginBottom:32}}>
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    {permisoEditar && (
                        <div className="header-right">
                            <Link to={"../Capacitacion/Seccion/" + idSeccion + "/CrearOpcional"}>Crear nueva gerencia</Link>
                        </div>
                    )}
                </div>
            </div>
            <div className="eva-dashboard-capaOpcional-body">
                <div className="p-fluid formgrid grid">
                    {   capaOpcional&&
                        capaOpcional.map((item, i) => {
                        return (
                            <div className="field col-12 md:col-3 sm:col-12" key={i}>
                                <CardGerencia NombreGerencia={item.nombre} colaboradores={item.cantidad +((item.cantidad ==0)||(item.cantidad >1) ? " capacitaciones":" capacitación")}
                                    onClicButton={() => navigate("../Capacitacion/Seccion/" + idSeccion + "/Gerencia/" + item.idGerencia)}
                                ></CardGerencia>
                            </div>
                        );
                    })}
                </div>
            </div>
            
        </div>
    );
}
 
export default CapaOpcionalGerencia;