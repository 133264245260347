import { Card } from "primereact/card";
import Boton from "../Boton/Boton";
import "./CardEntry.scss"
const CardEntryImg = ({nombreBoton,tituloCard, onClicButton,imagenUrl,opacity}) => {
    const footer = <span>
         <div style={{display:"flex",justifyContent:"right"}}>
            <Boton nombre={nombreBoton} color="secondary" widths="100%" icon="pi pi-angle-right" metodoClick={onClicButton}/>
         </div>
        
    </span>;
    return(
        <div className="p-eva-cardEntryImg-wrap">
            <img className="p-eva-cardEntryImg-fondo" 
                // style={{backgroundImage: `url(${imagenUrl})`,backgroundSize:"cover",backgroundRepeat:"no-repeat"}}
                src={imagenUrl} style={{opacity:opacity}}
                ></img>
            <Card title={tituloCard} footer={footer} className="eva-cardEntryImg" 
                />
        </div>
        
    )
}
 
export default CardEntryImg;