import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
const ENDPOINTTEST = constantes.URL_CLIMATEST;
const ENDPOINT = constantes.URLAPI_MAESTROS;

export const ObtenerListaSeccionClima =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerListaSeccionClima`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerListaSeccionClima`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerOnoarding =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerOnoarding`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerOnoarding`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ActualizarOnboarding= ({jsonClima,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarOnboarding`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarOnboarding`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonClima
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerActividades =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerActividades`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerActividades`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarActividades= ({jsonClima,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarActividades`,{
    return fetch(`${ENDPOINT}/Clima/RegistrarActividades`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonClima
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ObtenerActividadesCelebracionPorId =async ({Id,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerActividadesCelebracionPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerActividadesCelebracionPorId/${Id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}


export const ObtenerCalendarioPorId =async ({Id,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCalendarioPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerCalendarioPorId/${Id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarClimaCelebracion= ({jsonClima,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarClimaCelebracion`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarClimaCelebracion`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonClima
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const EliminarCalendarioPorId =async ({Id,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/EliminarCalendarioPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Clima/EliminarCalendarioPorId/${Id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const EliminarCelebracionPorId =async ({Id,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/EliminarCelebracionPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Clima/EliminarCelebracionPorId/${Id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCalendario= ({jsonClima,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarCalendario`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarCalendario`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonClima
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ListarEncuestas =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ListarEncuestas`,{
    return fetch(`${ENDPOINT}/Clima/ListarEncuestas`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerEncuesta =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerEncuesta`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerEncuesta`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarEncuesta= ({jsonEncuesta,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarEncuesta`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarEncuesta`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonEncuesta
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerClimaPlan =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerClimaPlan`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerClimaPlan`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarPlan= ({jsonPlan,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarPlan`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarPlan`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonPlan
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerClimaGptw =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerClimaGptw`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerClimaGptw`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarGptw= ({jsonGpwt,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarGptw`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarGptw`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonGpwt
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerInfluencers =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerInfluencers`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerInfluencers`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarClimaInfluencer= ({jsonInfluencer,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarClimaInfluencer`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarClimaInfluencer`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonInfluencer
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarReconocimiento= ({jsonReconocimiento,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarReconocimiento`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarReconocimiento`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonReconocimiento
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCLimaCategoria= ({jsonCategoria,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarCLimaCategoria`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarCLimaCategoria`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCategoria
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerClimaReconocimiento =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerClimaReconocimiento`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerClimaReconocimiento`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerReconocimientoCategoriaPorId =async ({jwt,id}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerReconocimientoCategoriaPorId/${id}`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerReconocimientoCategoriaPorId/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerClimaComite =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerClimaComite`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerClimaComite`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerDiversidadActividad =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerDiversidadActividad`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerDiversidadActividad`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ObtenerDiversidadActividadPorId =async ({jwt,id}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerDiversidadActividadPorId/${id}`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerDiversidadActividadPorId/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerDiversidadPolitica =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerDiversidadPolitica`,{
    return fetch(`${ENDPOINT}/Clima/ObtenerDiversidadPolitica`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarDiversidadActividad= ({jsonActividad,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarDiversidadActividad`,{
    return fetch(`${ENDPOINT}/Clima/RegistrarDiversidadActividad`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonActividad
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}


export const ActualizarDiversidadActividad= ({jsonActividad,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarDiversidadActividad`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarDiversidadActividad`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonActividad
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}


export const ActualizarClimaComite= ({jsonComite,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarClimaComite`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarClimaComite`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonComite
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}


export const ActualizarComitePolitica= ({jsonPolitica,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarComitePolitica`,{
    return fetch(`${ENDPOINT}/Clima/ActualizarComitePolitica`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonPolitica
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const EliminarClimaCategoriaPorId =async ({Id,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/EliminarClimaCategoriaPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Clima/EliminarClimaCategoriaPorId/${Id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const EliminarDiversidadActividadPorId =async ({Id,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/EliminarDiversidadActividadPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Clima/EliminarDiversidadActividadPorId/${Id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}