import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate, json } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import "./Encuesta.scss"
import ToastEva from "../../../../components/Toast/ToastEva";
import { ObtenerEncuesta } from "../../../../service/ClimaService";
 import { Steps,Panel,Placeholder } from "rsuite";


//import { Steps } from 'primereact/steps';
const Encuesta = () => {
    const navigate = useNavigate();
    const [encuesta, setEncuesta] = useState(null);
    const [comboEncuesta, setComboEncuesta] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    let { idEncuesta } = useParams();
    const [step, setStep] = useState(0);

    const mesActual = new Date().getMonth() +1;


    const primerSemestre =[{numeroMes:"01",nombreMes :"Enero"},
    {numeroMes:"02",nombreMes :"Febrero"},
    {numeroMes:"03",nombreMes :"Marzo"},
    {numeroMes:"04",nombreMes :"Abril"},
    {numeroMes:"05",nombreMes :"Mayo"},
    {numeroMes:"06",nombreMes :"Junio"}
    ]

    const segundoSemestre =[
    {numeroMes:"07",nombreMes :"Julio"},
    {numeroMes:"08",nombreMes :"Agosto"},
    {numeroMes:"09",nombreMes :"Setiembre"},
    {numeroMes:"10",nombreMes :"Octubre"},
    {numeroMes:"11",nombreMes :"Noviembre"},
    {numeroMes:"12",nombreMes :"Diciembre"}]

    const detalleActual = (lista)=>{

        return(
            <div className="mes-detalle-actual">
                {
                lista.map((item,i)=>{
                    return(
                        <div className="mes-detalle-actual-item">
                            {
                                item.link == "" ? 
                                <div className="actual-item-titulo">{item.nombreEncuesta}</div>
                                :
                                <a className="link-timeline" href={item.link.indexOf("http") > -1 ? item.link : "http://"+item.link} target="_blank">{item.nombreLink? item.nombreLink:item.nombreEncuesta}</a>
                            }
                            
                            <div className="actual-item-estado">{parseInt(item.mes) == mesActual ? "En proceso" :(parseInt(item.mes) > mesActual?"Pendiente":"Finalizado")}</div>
                        </div>
                    )
                })
                }
            </div>
        )
    }

    
    
    useEffect(()=>{
        const getEncuesta = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerEncuesta({jwt}).then(data => 
                {
                    setEncuesta(data)
                    console.log("enc",data)

                })
        }
        if(!encuesta)getEncuesta()
    },[])
    return ( 
        <div className="p-eva-encuesta" >
            <div>{encuesta && encuesta.descripcion}</div>
            {/* <div className="header-subtitle" style={{marginTop:24}}>
                Encuestas de clima laboral
            </div> */}
            <div className="header-subtitle" style={{marginTop:24}}>
                {encuesta && encuesta.subTitulo1}
            </div>
            <div className="encuesta-timeline" style={{marginTop:16}}>
                <div className="encuesta-timeline-descripcion" style={{width:"100%"}}>
                    {
                        encuesta && 
                        encuesta.descripcionTimeline
                    }
                </div>
                <div className="encuesta-timeline-detalle">
                    <Steps current={mesActual-1} >
                       
                        {
                            mesActual <= 6 &&

                            primerSemestre.map((item,i)=>{
                                return(<Steps.Item  title={item.nombreMes} 
                                description={encuesta && encuesta.clima_laboral_encuesta_mes.length > 0 && encuesta.clima_laboral_encuesta_mes.filter(x=>x.mes == item.numeroMes).length > 0 && detalleActual(encuesta.clima_laboral_encuesta_mes.filter(x=>x.mes == item.numeroMes))}/>)
                                // return(
                                //     <Steps model={primerSemestre}></Steps>
                                // )
                            })
                        }
                        {/* {
                            mesActual > 6 &&

                            segundoSemestre.map((item,i)=>{
                                return(<Steps.Item  title={item.nombreMes} 
                                    description={encuesta && encuesta.clima_laboral_encuesta_mes.length > 0 && encuesta.clima_laboral_encuesta_mes.filter(x=>x.mes == item.numeroMes).length > 0 && detalleActual(encuesta.clima_laboral_encuesta_mes.filter(x=>x.mes == item.numeroMes))}/>)
                            })
                        } */}
                     

                    </Steps>
                </div>
            </div>
        </div>
        );
}
 
export default Encuesta;