import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
 const ENDPOINTTEST = constantes.URL_PUBLICTEST;
const ENDPOINT = constantes.URLAPI_MAESTROS;


export const Registrar= ({jsonPublicacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/Registrar`,{
    return fetch(`${ENDPOINT}/Publicacion/Registrar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonPublicacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const RegistrarAdjuntos= ({jsonPublicacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/Registrar`,{
    return fetch(`${ENDPOINT}/Publicacion/RegistrarAdjuntos`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonPublicacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}


export const Actualizar= ({jsonPublicacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarPublicacion`,{
    return fetch(`${ENDPOINT}/Publicacion/ActualizarPublicacion`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonPublicacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export const ActualizarAdjuntos= ({jsonPublicacion,jwt}) =>{
    console.log("ACTUALIZAR")
    return fetch(`${ENDPOINT}/Publicacion/ActualizarPublicacionAdjuntos`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonPublicacion
    }).then(res=>{
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const EliminarPublicacion =async ({jwt,IdPublicacion}) =>{
    //return await fetch(`${ENDPOINT}/Publicacion/EliminarPublicacion/${IdPublicacion}`,{
    return fetch(`${ENDPOINT}/Publicacion/EliminarPublicacion/${IdPublicacion}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export const ListarComentariosPorPublicacion =async ({jwt,IdPublicacion}) =>{
    //return await fetch(`${ENDPOINT}/Publicacion/ListarComentariosPorPublicacion/${IdPublicacion}`,{
    return fetch(`${ENDPOINT}/Publicacion/ListarComentariosPorPublicacion/${IdPublicacion}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const EliminarComentario =async ({jwt,IdComentario}) =>{
    //return await fetch(`${ENDPOINT}/Publicacion/EliminarComentario/${IdComentario}`,{
    return fetch(`${ENDPOINT}/Publicacion/EliminarComentario/${IdComentario}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}


export const ListarPublicacion =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ListarPublicacion`,{
    return fetch(`${ENDPOINT}/Publicacion/ListarPublicacion`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ListarPendientesAprobacion =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ListarPendienteAprobacion`,{
    return fetch(`${ENDPOINT}/Publicacion/ListarPendienteAprobacion`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ListarPublicacionPorGrupo =async ({jwt,IdGrupo}) =>{
    //return await fetch(`${ENDPOINTTEST}/ListarPublicacionPorGrupo/${IdGrupo}`,{
    return fetch(`${ENDPOINT}/Publicacion/ListarPublicacionPorGrupo/${IdGrupo}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}


export const RegistrarComentario= ({jsonComentario,jwt}) =>{
    //return fetch(`${ENDPOINT}/RegistrarComentario`,{
    return fetch(`${ENDPOINT}/Publicacion/RegistrarComentario`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonComentario
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}


export const ActualizarNotificacion= ({jwt,idNotificacion}) =>{
    //return fetch(`${ENDPOINT}/ActualizarNotificacion/${idNotificacion}`,{
    return fetch(`${ENDPOINT}/Publicacion/ActualizarNotificacion/${idNotificacion}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}


export const ListarNotificaciones =async ({jwt}) =>{
    //return await fetch(`${ENDPOINT}/ListarNotificaciones`,{
    return fetch(`${ENDPOINT}/Publicacion/ListarNotificaciones`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}


export const ObtenerNotificacionPorId= ({jwt,id}) =>{
    //return fetch(`${ENDPOINT}/ObtenerNotificacionPorId/${id}`,{
    return fetch(`${ENDPOINT}/Publicacion/ObtenerNotificacionPorId/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}


export const ActualizarEstadoPublicacion =async ({jwt,id,estado}) =>{
    //return await fetch(`${ENDPOINT}/ActualizarEstado/${id}/${estado}`,{
    return fetch(`${ENDPOINT}/Publicacion/ActualizarEstado/${id}/${estado}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ListarInteraccionPorPublicacion =async ({jwt,IdPublicacion}) =>{
    //return await fetch(`${ENDPOINT}/Publicacion/ListarInteraccionPorPublicacion/${IdPublicacion}`,{
    return fetch(`${ENDPOINT}/Publicacion/ListarInteraccionPorPublicacion/${IdPublicacion}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ActualizarVista= ({jwt,idPub}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarVista/${idPub}`,{
    return fetch(`${ENDPOINT}/Publicacion/ActualizarVista/${idPub}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export const ListarPublicacion2 =async ({jwt,pag,row}) =>{
    //return await fetch(`${ENDPOINTTEST}/ListarPublicacion`,{
    return fetch(`${ENDPOINT}/Publicacion/ListarPublicacion2/${pag}/${row}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
