import React, { useEffect, useState,useRef,useLayoutEffect } from 'react';
import { Document,Page,pdfjs } from 'react-pdf';
// import { Document,Page} from 'react-pdf/dist/esm/entry.webpack';

import { URLBLOB_ADJ_POLITICA } from "../../constants/constantes";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Dialog } from 'primereact/dialog';
import "./DocumentViewer.scss"
import { createPortal } from 'react-dom';
import CheckboxEva from "../CheckBoxEva/CheckboxEva";
import { Checkbox } from "primereact/checkbox";

import Boton from "../Boton/Boton";

import * as Iconsax from "iconsax-react";
const DocumentViewer=({urlFile, visibleModal=false,onHide,descarga =false,nombreArchivo ="",onPdfLeido,accept})=>{
    // const pdf = `${URLBLOB_ADJ_POLITICA}/test_files/file-example_PDF_1MB.pdf`;
    //const pdf = `https://grinlearningqa9414.blob.core.windows.net/adjuntos/Reglamento/Plan%20de%20trabajo%20Eva.pdf`;

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    // pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [visible, setVisible] = useState(false);
    const [pdfLeido, setpdfLeido] = useState(false);
    const [checked, setChecked] = useState(false);
    const [pdfCargado, setPdfCargado] = useState(false);

    //const ref = useRef(null);
    const dialogRef = useRef(null);

    const [width, setWidth] = useState(0);
    useEffect(()=>{
        console.log("Acept")
        setpdfLeido(accept)
    },[accept])

    useLayoutEffect(() => {
        setWidth(dialogRef.current.props.style.width -40);
        //setHeight(ref.current.offsetHeight);
    }, []);
    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
      };
    
      function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
        console.log("nextNumPages",nextNumPages)
        setTimeout(() => {
            setPdfCargado(true);
        }, 1000); // 3000 milisegundos = 3 segundos
       // setPdfCargado(true);
      }
    useEffect(()=>{
        setVisible(visibleModal)
    },[visibleModal])
    const footer =<></>;


    const handleClickDownload =(url)=>{

        fetch(url).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = nombreArchivo;
                alink.click();
            })
        })
    }

//ACTUAL
//   const [currentPage, setCurrentPage] = useState(1);
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//     console.log(`Página actual: ${pageNumber}`);
//     if (pageNumber === numPages) {
//         console.log("FINAL del PDF")
//     }
//   };

//   useEffect(() => {
//     if (numPages) {
//       handlePageChange(currentPage);
//     }
//   }, [numPages]);

    const handleScroll = (e) => {
        console.log('handleScroll');
        const { target } = e;
        const { scrollTop, scrollHeight, clientHeight } = target;
        if (scrollTop + (clientHeight + 30) >= scrollHeight) {
        console.log('Final del dialog');
        onPdfLeido(true);
        setpdfLeido(true)
        }
    };
    return (
        <Dialog
            className="p-eva-documento-pdf"
            visible={visible}
            onHide={onHide}
            footer={footer}
            // style={{ width:"43%" , height: "100%" }}
            style={{ width:1000 , height: "100%" }}
            ref={dialogRef}
            >
            {descarga && (
                <div className="cardfileHeader-descargar" onClick={() => handleClickDownload(urlFile)}>
                <span>
                    <Iconsax.ImportCurve color="#ffffff" size={27} />
                </span>
                </div>
            )}

            <div
                style={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                }}
                onScroll={handleScroll}
            >
                <Document
                file={urlFile}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                onLoadError={console.error}
                >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
                ))}
                </Document>

                {/* <div className="col-12 md:col-6" style={{marginLeft: "240px",marginBottom:"50px"}}>
                <Boton  nombre={"Descargar"} color="primary" widths="80%" />
                </div> */}
                {/* <div style={{marginLeft: "240px", marginBottom:"50px", backgroundColor:"#F47629", width:"520px", height:"auto", borderRadius: "10px", padding: "5px"}}>
                    <div style={{marginTop: 8, marginBottom: 8, padding: '8px'}}>                        
                        <Checkbox  
                            className="p-eva-checkbox" 
                            inputId="cb1" 
                            checked={pdfLeido} 
                            >
                        </Checkbox>
                        <label htmlFor="cb1" className="p-checkbox-label" style={{marginLeft: "8px",color:"white"}}><strong>{"Acepto haber leído el documento y estar conforme con el mismo"}</strong></label>
                    </div>
                </div> */}
                {pdfCargado && numPages > 0 && (
                <div style={{marginLeft: "240px", marginBottom:"50px", backgroundColor:"#F47629", width:"520px", height:"auto", borderRadius: "10px", padding: "5px"}}>
                    <div style={{marginTop: 8, marginBottom: 8, padding: '8px'}}>                        
                        <Checkbox  
                            className="p-eva-checkbox" 
                            inputId="cb1" 
                            checked={pdfLeido} 
                        >
                        </Checkbox>
                        <label htmlFor="cb1" className="p-checkbox-label" style={{marginLeft: "8px",color:"white"}}>
                            <strong>{"Acepto haber leído el documento y estar conforme con el mismo"}</strong>
                        </label>
                    </div>
                </div>
            )}
            </div>
           
            </Dialog>


    );
}
export default DocumentViewer;
