import React, { useState } from "react";
import "./CheckboxEva.scss";
import { Checkbox } from "primereact/checkbox";
import * as Iconsax from "iconsax-react";
const CheckboxEva =({checked,onChange,label="",value,disabled=false,marginTop=18,id,name})=>{

    
    return(
        <div style={{marginTop:marginTop}}>
            <Checkbox id={id} name={name} inputId="cb1" onChange={onChange} checked={checked} value={value} disabled={disabled}></Checkbox>
            <label htmlFor="cb1" className="p-checkbox-label" style={{marginLeft:"8px"}}>{label}</label>
        </div>
    )
}

export default CheckboxEva