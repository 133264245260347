import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import InputDefault from "../../../components/InputDefault/InputDefault";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import {  useFormik } from "formik";
import {ListarGerencias} from "../../../service/GerenciaService";
import Boton from "../../../components/Boton/Boton";
import { RegistrarConvocatoria,ActualizarConvocatoria,ObtenerConvocatoriaPorId} from "../../../service/DesarrolloService";
import axios from "axios";
import * as constantes from "../../../constants/constantes.js";

import "./CrearConvocatoria.scss";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerNotificacionPorId } from "../../../service/PublicacionService";
const CrearConvocatoria = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Crear nueva convocatoria");
    const [modoEdicion, setModoEdicion] = useState(false);
    const {permisos,isLogged}= useUsuario();
    const [convocatoria, setConvocatoria] = useState(null);
    const [listaGerencias, setListaGerencias] = useState(null);
    const toast = useRef(null);

    let {idConvocatoria} = useParams();

    const sendMessage = async (nombregrupo,idNoti,mensaje) => {
        return await axios.post(constantes.URL_NOTIFICACION+"/messageGroup",{
            GroupName: nombregrupo,
            ConnectionId:"1",
            Sender:"WEB",
            Text: "mensaje enviado por el sistema web",
            Link: constantes.URLSITE_CONVOCATORIA,
            IdNotificacion:idNoti,
            Mensaje:mensaje
        }).then(resp=>resp.data)
    }

    useEffect(()=>{
        if(!listaGerencias)
        {
            let jwt = window.localStorage.getItem("jwt");
            ListarGerencias(jwt).then(data => setListaGerencias(data))
        }
        

     },[]);

     useEffect(()=>{
        const ObtenerConvocatoria =async ()=>{
            let id= idConvocatoria
            let jwt = window.localStorage.getItem("jwt");
           await ObtenerConvocatoriaPorId({id,jwt}).then(data=>
            {
                setConvocatoria(data)
                setModoEdicion(true)
                setTituloPagina("Editar convocatoria")
            })
           
        }
        if(idConvocatoria){
            ObtenerConvocatoria();
        } 
    },[])

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:modoEdicion?convocatoria.id:0,
            idGerencia: modoEdicion?convocatoria.idGerencia:"",
            nombre: modoEdicion?convocatoria.nombre:"",
            fechaMaxima: modoEdicion?convocatoria.fechaMaxima:"",
            linkConvocatoria: modoEdicion?convocatoria.linkConvocatoria:"",
        },
      validationSchema: Yup.object({
        idGerencia: Yup.string().required("Campo Gerencia requerido."),
        nombre: Yup.string().required("Campo Nombre puesto requerido."),
        fechaMaxima: Yup.string().required("Campo Fecha máxima requerida."),
        linkConvocatoria : Yup.string().required("Campo Link de convocatoria requerido."),
      }),
      onSubmit: values => {
        let id = values.id
        let idGerencia = values.idGerencia
        let nombre = values.nombre
        let fechaMaxima = new Date(new Date(values.fechaMaxima).getFullYear(),new Date(values.fechaMaxima).getMonth(),new Date(values.fechaMaxima).getDate(),23,59).toLocaleString("en-US")
        let linkConvocatoria = values.linkConvocatoria
        
        let jsonConvocatoria=JSON.stringify({id,idGerencia,nombre,fechaMaxima,linkConvocatoria})
    
        // let jsonConvocatoria = JSON.stringify({id,nombre,descripcion,idSeccion,listaContactos,textoEvaluacion,listaUrls,nombreImagenTarjeta,imagenBase64,listaDocCompleto},null,2)
        // console.log(jsonConvocatoria)
         //alert(jsonConvocatoria);
      
         if(modoEdicion )Actualizar({jsonConvocatoria}) 
          else Registrar({jsonConvocatoria})
      
      },
    });
    const ObtenerDetalleNotificacion =async(grupo,idNoti)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje ="¡Tenemos una nueva convocatoria interna!. Has clic para descubrir que tenemos para ti."
        
            // sendMessage(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle,mensaje)
            sendMessage(grupo,idNoti,mensaje)
        })  
    } 

    const Registrar= async({jsonConvocatoria})=>{
        let jwt = window.localStorage.getItem("jwt");
        await RegistrarConvocatoria({jsonConvocatoria,jwt}).then(data=>{
            formik.setSubmitting(false);
            toast.current.show({severity:'success', summary: 'Success', detail:"Convocatoria creada con éxito", life: 7000})
            Promise.all([
                ObtenerDetalleNotificacion("Administrador General - Plataforma",data.data.id),
                ObtenerDetalleNotificacion("Inlearning Institutos",data.data.id)
            //     sendMessage("Administrador General - Plataforma",data.data.id),
            //     sendMessage("Inlearning Institutos",data.data.id),
             ]).then(setTimeout(() => {
                navigate(-1);
            }, 3000) )
            // sendMessage(data.data.id)
            // setTimeout(() => {
            //     navigate(-1);
            // }, 3000)
            
        }).catch(err=>{
            formik.setSubmitting(false);
            toast.current.show({severity:'error', summary: 'Error', detail:err.message, life: 7000})

        })
    }

    const Actualizar= async({jsonConvocatoria})=>{
        let jwt = window.localStorage.getItem("jwt");
        await ActualizarConvocatoria({jsonConvocatoria,jwt}).then(data=>{
            toast.current.show({severity:'success', summary: 'Success', detail:"Convocatoria actualizada con éxito", life: 7000})
            setTimeout(() => {
                navigate(-1);
            }, 3000)
            
        }).catch(err=>{
            toast.current.show({severity:'error', summary: 'Error', detail:err.message, life: 7000})

        })
    }
    return (
        <div className="eva-dashboard-crearConvocatoria">
            <ToastEva ref={toast} position="top-center"></ToastEva>

            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label>{tituloPagina}</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4">
                            <label htmlFor="Seccion">Selecciona la gerencia</label>
                            <DropdownDefault
                                id="idGerencia"
                                name="idGerencia"
                                options={listaGerencias}
                                optionLabel="code"
                                optionValue="value"
                                errorClass={formik.touched.idGerencia != undefined && (formik.errors.idGerencia ? "error" : "success")}
                                placeholder="Seleccione"
                                value={formik.values.idGerencia}
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.idGerencia && formik.touched.idGerencia && formik.errors.idGerencia}</p>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="Seccion">Nombre del puesto</label>
                            <InputDefault id="nombre" name="nombre" className="white" placeholder="Escribe aquí" errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")} value={formik.values.nombre} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <p className="error">{formik.errors.nombre && formik.touched.nombre && formik.errors.nombre}</p>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="Seccion">Fecha máxima para postular</label>
                            <CalendarDefault
                                id="fechaMaxima"
                                name="fechaMaxima"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.fechaMaxima&&new Date(formik.values.fechaMaxima)}
                                placeholder="dd/mm/aaaa"
                                //disabled={!enableFProgramar}
                                classesname={"white"}
                                minDate={new Date()}
                                dateFormat="dd/mm/yy"
                                
                            ></CalendarDefault>
                            <p className="error">{formik.errors.fechaMaxima && formik.touched.fechaMaxima && formik.errors.fechaMaxima}</p>
                        </div>
                        <div className="field col-12 md:col-8">
                            <label htmlFor="Seccion">Agregar link de la convocatoria</label>
                            <InputDefault
                                id="linkConvocatoria"
                                name="linkConvocatoria"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.linkConvocatoria != undefined && (formik.errors.linkConvocatoria ? "error" : "success")}
                                value={formik.values.linkConvocatoria}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.linkConvocatoria && formik.touched.linkConvocatoria && formik.errors.linkConvocatoria}</p>
                        </div>
                    </div>
                    <div className="p-eva-convocatoria-footer">
                        <Boton
                            nombre={"Cancelar"}
                            color="secondary"
                            widths="180px"
                            metodoClick={()=>navigate(-1)}
                        ></Boton>
                        <Boton nombre={modoEdicion?"Actualizar":"Crear convocatoria"} color="primary" widths="180px" type="submit" disabled={!(formik.isValid && formik.dirty)} loading={formik.isSubmitting}></Boton>
                    </div>
                </form>
            </div>
        </div>
    );
}
 
export default CrearConvocatoria;