import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ContactCard from "../../../components/ContactCard/ContactCard";
import ContactCardV2 from "../../../components/ContactCard/ContactCardV2";
import usePermisos from "../../../hooks/usePermisos";
import "./DirectorioDetalle.scss";

import { ObtenerDirectorioPorGerencia } from "../../../service/SomosinService";
import * as Iconsax from "iconsax-react";
import { Skeleton } from "primereact/skeleton";
import Sk_DirectorioDetalle from "../../../components/Skeleton/Sk_DirectorioDetallle";

import useUsuario from "../../../hooks/useUsuario";
import PerfilUsuario from "../../../components/PerfilUsuario/PerfilUsuario";
const DirectorioDetalle = () => {
    const navigate = useNavigate();
    let { IdGerencia,IdUsuario } = useParams();
    const [tituloPagina, settituloPagina] = useState("");
    const [directorioGerencia, setDirectorioGerencia] = useState(null);
    const {permisos,isLogged} = useUsuario()
   
    const [idUsuarioSeleccionado, setIdUsuarioSeleccionado] = useState(null);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        if(permisos.length >0)
        {
            !(permisos.indexOf("verDirectorio") > -1) && navigate("/")
        }
    },[permisos])

    useEffect(()=>{
        const ObtenerDirectorioGerencia =async ()=>{
            //await GetGrupo({idGerencia});
            let jwt = window.localStorage.getItem("jwt");
            let idUsuario = IdUsuario?IdUsuario :0;
            let idGerencia = IdGerencia?IdGerencia : 0;
            await ObtenerDirectorioPorGerencia({jwt,idGerencia,idUsuario}).then((data) => setDirectorioGerencia(data));
         }
        if(IdGerencia || IdUsuario){
            ObtenerDirectorioGerencia();
        } 
    },[IdGerencia])

    useEffect(()=>{
        if(directorioGerencia)settituloPagina(directorioGerencia.nombreGerencia)
        
    },[directorioGerencia])
    return (
        <div className="eva-dashboard-directorioDetalle">
            <div className="eva-dashboard-header">
                <div>
                    <i  onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div className="header">
                    {
                        directorioGerencia?
                        <label htmlFor="header">{tituloPagina}</label>
                        :<Skeleton width="150px" height="45px" borderRadius="16px"></Skeleton>
                    }
                    
                </div>
            </div>
            <div className="eva-dashboard-body">
                {
                    directorioGerencia?
                    directorioGerencia.listaAreas.map((area, i) => {
                        return(
                            <div className={"p-eva-seccion-" + i} key={i}>
                                <div className="header-directorio-area">
                                    <label htmlFor="header">{area.nombreArea}</label>
                                </div>
                                <div className="p-fluid formgrid grid">
                                    {
                                        area.listaUsuarios.map((usuario,j)=>{
                                            return(
                                                <div className="field col-12 md:col-4" key={j} style={{marginBottom:60}}>
                                                    <ContactCardV2 foto={usuario.nombreFoto} 
                                                        idUser={usuario.idUsuario} 
                                                        nombreUser={usuario.nombreUsuario} 
                                                        puesto={usuario.puesto} 
                                                        email={usuario.correo}
                                                        gerencia={tituloPagina}
                                                        area={area.nombreArea}
                                                        fechaNacimiento={usuario?.fechaNacimiento}
                                                        celular={usuario?.celular}
                                                        //metodoClick={()=>{setIdUsuarioSeleccionado(usuario.idUsuario);setMostrarSlideUsuario(true)}}
                                                        />
                                                </div>
                                            )
                                            
                                        })
                                    }
                                    
                                </div>
                            </div>
                        )
                        
                    })
                        
                    : <Sk_DirectorioDetalle/>
                }
                
            </div>
        </div>
    );
}

export default DirectorioDetalle;