import React, { useState,useRef,forwardRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import "./DropdownFiltro.scss"
import classNames from "classnames";
const DropdownFiltro = (props) => {
    return ( 
        <Dropdown
            className={"eva-dropdownFiltro"} {...props}
            
        />
     );
}
 
export default DropdownFiltro;