import React, { useState, useEffect ,useRef} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import { ListUsersTag} from "../../service/UsuariosService";
import InputDefault from "../../components/InputDefault/InputDefault";
import InputIcon from "../../components/InputIcon/InputIcon";
import TextareaDefault from "../../components/TextArea/TextArea";
import SearchCard from "../../components/Directorio/SearchCard";
import MultiselectEva from "../../components/Multiselect/MultiSelectEva";
import BotonEva from '../../components/Boton/Boton';
import UseLiderIn from "../../hooks/useLiderIn";
import { ListarSeccion,ObtenerTemaId} from "../../service/LiderInService";
import DropdownDefault from '../../components/DropdownDefault/Dropdown';
import { ObtenerReglamentoId } from "../../service/SomosinService";
import ToastEva from "../../components/Toast/ToastEva";
import "./CrearTema.scss"
const CrearTema= () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Añadir nuevo tema");
    const [modoEdicion, setModoEdicion] = useState(false);
    const {permisos,isLogged}= useUsuario();
    const [stateImage, setStateImage] = useState({file: null,base64URL: ""});
    const [stateArrayDocument,setStateArrayDocument] = useState([])   
    const [stateAdjDocument,setStateAdjDocument] = useState({nombreDocumento: "",imagenBase64: "",tipoDocumento:""})   

    const [listUserTotal, setListUserTotal] = useState([]);
    const [selected, setSelected] = useState([]);
    const [numInputDocument, setNumInputDocument] = useState(2);
    const [htmlInputDocument, setHtmlInputDocument] = useState([]);
    const {isCreated,RegistrarTema,ActualizarTema,isUpdate} = UseLiderIn()
    const [listaSeccion, setListaSeccion] = useState([]);
    const [tema, setTema] = useState(null);
    let { idTema } = useParams();
    let { idSeccion } = useParams();

    const toast = useRef(null);

    useEffect(()=>{
        const ObtenerTema =async ()=>{
            let id= idTema
            let jwt = window.localStorage.getItem("jwt");
           await ObtenerTemaId({id,jwt}).then(data=>setTema(data))
           
        }
        if(idTema){
            ObtenerTema();
        } 
    },[])

    useEffect(()=>{
        const CargarContactoTema = async(temaSelected)=>
        {
            let contactosTema = [];

            await temaSelected.listaContactos.map((item,i)=>{
                contactosTema.push({label: item.nombreUsuario,value:String(item.idUsuario)})
            })
            setSelected(contactosTema);
        }
        if(tema && selected.length ==0 && !modoEdicion) {
            
            setTituloPagina("Editar tema")
            setModoEdicion(true)
            CargarContactoTema(tema);
        }

        
    },[tema])


    useEffect(()=>{
        const obtenerListaSeccion = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccion({jwt}).then(data => setListaSeccion(data))
        }

        if(listaSeccion.length === 0)
        {
            obtenerListaSeccion();
        }
     },[]);

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(() => {
        if (isCreated || isUpdate) {
            formik.setSubmitting(false)
            if(isCreated)toast.current.show({severity:'success', summary: 'Success', detail:"Tema creado exitosamente.", life: 7000})
            if(isUpdate)toast.current.show({severity:'success', summary: 'Success', detail:"Tema actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
            
        }
    }, [isCreated,isUpdate]);


    useEffect(()=>{
        const obtenerUsuariosTotal = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListUsersTag(jwt).then(data => setListUserTotal(data))
        }

        if(listUserTotal.length === 0)
        {
            obtenerUsuariosTotal();
        }
     },[]);
   
     
    

    const schema = Yup.object().shape({
       
    });

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    
    
      useEffect(()=>{
        if(stateAdjDocument.nombreDocumento !== "")
        {
            var arrayFile =[...stateArrayDocument]
            arrayFile = [...stateArrayDocument,stateAdjDocument]
            // arrayFile = [...stateAdjDocument,file.name]
            
            setStateArrayDocument(arrayFile);
        }
        
      },[stateAdjDocument])
      const handleSelectChange = (values) => {
        if(values.length < 3)
        {
            setSelected(values);
            console.log(values)
        }
        
    };
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:modoEdicion?tema.id:0,
            nombre: modoEdicion?tema.nombreTema:"",
            nombreSubtema: modoEdicion?tema.nombreSubtema:"",
            descripcion: modoEdicion?tema.descripcion:"",
            txtEvaluacion:modoEdicion?tema.textoEvaluacion:"" ,
            seccion:modoEdicion?tema.idSeccion:"",
            nombreImagenTarjeta: modoEdicion? tema.nombreImagenTarjeta:"",
            imagenBase64:modoEdicion?tema.imagenBase64:null,
            documentos: modoEdicion? tema.listaDocCompleto : [{imagenBase64:null,tipoDocumento:null,id:0,
                idLiderin:0,nombreDocumento:'',tipo:'Adjunto',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            listaUrls : modoEdicion? tema.listaUrls : [{id:0,
                idLiderin:0,nombreDocumento:'',tipo:'Url',estado:false,fechaEliminacion:null,usuarioEliminacion:null}]
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id
        let seccion = idSeccion
        let nombreTema = values.nombre
        let nombreSubtema = values.nombreSubtema
        let descripcion = values.descripcion
        
        
        var listaUsuariosContacto = [];
        if(selected.length > 0)
        {
            selected.map((item,i) =>{
                listaUsuariosContacto.push({idUsuario:item.value});
            })
        }

        let listaContactos = listaUsuariosContacto;
        // let nombreImagenTarjeta = stateImage.file === null ? "":stateImage.file.name;
        // let imagenBase64 = stateImage.file === null?"": stateImage.base64URL;
        // let listaDocCompleto = stateArrayDocument;
        let nombreImagenTarjeta = values.nombreImagenTarjeta;
        let imagenBase64 = values.imagenBase64;
        let listaDocCompleto = values.documentos;
        let listaUrls = values.listaUrls;
        let textoEvaluacion = values.txtEvaluacion;
        let jsonTema = JSON.stringify({id,nombreTema,nombreSubtema,descripcion,seccion,listaContactos,textoEvaluacion,listaUrls,nombreImagenTarjeta,imagenBase64,listaDocCompleto},null,2)
        console.log(jsonTema)
        //alert(jsonTema);
        if(modoEdicion )ActualizarTema({jsonTema}) 
        else RegistrarTema({jsonTema})
        
      },
    });

    return (
        <div className="eva-dashboard-crearTema">
            <ToastEva ref={toast} position="top-center"></ToastEva>

            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label>{tituloPagina}</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                            <div className="p-fluid formgrid grid">
                                
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="nombre" className="p-eva-labelForm">Nombre del tema</label>
                                    <InputDefault id="nombre" name="nombre" className="white" 
                                    placeholder="Escribe aquí" errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")} 
                                    value={formik.values.nombre} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <p className="error">{formik.errors.nombre && formik.touched.nombre && formik.errors.nombre}</p>
                                </div>
                                <div className="field col-12 md:col-6">
                                <label htmlFor="nombre" className="p-eva-labelForm">Nombre del subtema</label>
                                    <InputDefault id="nombreSubtema" name="nombreSubtema" className="white" 
                                    placeholder="Escribe aquí" errorClass={formik.touched.nombreSubtema != undefined && (formik.errors.nombreSubtema ? "error" : "success")} 
                                    value={formik.values.nombreSubtema} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <p className="error">{formik.errors.nombreSubtema && formik.touched.nombreSubtema && formik.errors.nombreSubtema}</p>
                                </div>
                                <div className="field col-12 md:col-12" >
                                    <label htmlFor="descripcion" className="p-eva-labelForm">Añade una descripción</label>
                                    <TextareaDefault id="descripcion" height={190} placeholder="Escribe aquí" className="White" 
                                        errorClass={formik.touched.descripcion != undefined&& (formik.errors.descripcion?"error":"success")} 
                                        value={formik.values.descripcion} icon={<Iconsax.DocumentText1 />} name="descripcion"  
                                        onChange={formik.handleChange} onblur={formik.handleBlur} />
                                    <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                                </div>
                                <div className="field col-12 md:col-12" >
                                    <label className="p-eva-labelForm">
                                        Agrega documentos
                                    </label>
                                </div>
                                <FieldArray name="documentos"
                                    render={(arrayHelpers)=>(
                                        <>
                                        <div className="field col-12 md:col-8" >
                                            <div className="p-fluid formgrid grid" >
                                                {
                                                    formik.values.documentos.map((documento, index) => (
                                                        
                                                        <div className="field col-12 md:col-6" key={index} >
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                        <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                            <span>{formik.values.documentos[index].nombreDocumento.length > 0 ?
                                                                                formik.values.documentos[index].nombreDocumento
                                                                            :"Adjunta aquí"}</span>
                                                                        </label>
                                                                        {formik.values.documentos[index].nombreDocumento !='' ?
                                                                            <label className='eva-labelFileLider-icon'><i onClick={()=>onDeleteFile(index,arrayHelpers)} style={{ cursor: "pointer" }}><Iconsax.Trash /></i></label>
                                                                            :
                                                                            <label htmlFor={`documentos[${index}].nombreDocumento`} className='eva-labelFileLider-icon'><i ><Iconsax.Gallery /></i></label>}

                                                                        {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                        <input
                                                                            type="file" id={`documentos[${index}].nombreDocumento`}
                                                                            name={`documentos[${index}].nombreDocumento`} 
                                                                            onChange={(e) => {
                                                                                    formik.setFieldValue(`documentos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                                    formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                                    getBase64(e.currentTarget.files[0])
                                                                                    .then(result => { formik.setFieldValue(`documentos[${index}].imagenBase64`,result)})

                                                                                    //onFileChange(e, "document")
                                                                                }
                                                                                }
                                                                            accept="application/pdf,audio/*,image/*" hidden>
                                                                        </input>

                                                                    </div>
                                                         
                                                        </div>
                                                        
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "end" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push(
                                                                {imagenBase64:null,tipoDocumento:null,id:0,
                                                                    idLiderin:0,nombreDocumento:'',tipo:'Adjunto',estado:false,fechaEliminacion:null,usuarioEliminacion:null}
                                                              )}>Añadir más</i>
                                            </div>
                                        </div>
                                    </>

                                    )}
                                >
                                
                                </FieldArray>
                                {/* <div className="field col-12 md:col-6">
                                    <div className="p-fluid formgrid grid">
                                            {htmlInputDocument}
                                    </div>
                                    
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="p-eva-linkText">
                                        <i style={{cursor:"pointer"}} onClick={handleAñadirDoc}>Añadir más</i>
                                    </div>
                                    
                                </div>  */}

                                <div className="field col-12 md:col-12" >
                                    <label className="p-eva-labelForm">
                                        Agrega links de videos
                                    </label>
                                </div>
                                <FieldArray name="listaUrls"
                                    render={(arrayHelpersLink)=>(
                                        <>
                                        <div className="field col-12 md:col-8" >
                                            <div className="p-fluid formgrid grid" >
                                                {
                                                    formik.values.listaUrls.map((documento, index) => (
                                                        
                                                        <div className="field col-12 md:col-6" key={index} >
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <InputIcon id={`listaUrls[${index}].nombreDocumento`} name={`listaUrls[${index}].nombreDocumento`} className="white" 
                                                                    placeholder="Ingresa el link aquí" errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")} 
                                                                    value={formik.values.listaUrls[index].nombreDocumento}
                                                                    onChange={formik.handleChange} 
                                                                    onBlur={formik.handleBlur} 
                                                                    icon={formik.values.listaUrls[index].nombreDocumento !='' &&
                                                                    <Iconsax.Trash />                                                                        
                                                                        }
                                                                    onClickIcon={()=>onDeleteLink(index,arrayHelpersLink)}
                                                                    />
                                                                            {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                                <span>{formik.values.listaUrls[index].nombreDocumento.length > 0 ?
                                                                                    formik.values.listaUrls[index].nombreDocumento
                                                                                :"Ingresa el link aquí"}</span>
                                                                            </label>
                                                                            {formik.values.listaUrls[index].nombreDocumento !='' &&
                                                                                <label className='eva-labelFileLider-icon'><i onClick={()=>onDeleteLink(index,arrayHelpersLink)} style={{ cursor: "pointer" }}><Iconsax.Trash /></i></label>                                                                       
                                                                            } */}
                                                                    </div>
                                                         
                                                        </div>
                                                        
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "end" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLink.push(
                                                                {id:0,
                                                                    idLiderin:0,nombreDocumento:'',tipo:'Url',estado:false,fechaEliminacion:null,usuarioEliminacion:null}
                                                              )}>Añadir más</i>
                                            </div>
                                        </div>
                                    </>

                                    )}
                                >
                                
                                </FieldArray>
                                <div className="field col-12 md:col-12" >
                                    <label htmlFor="txtEvaluacion" className="p-eva-labelForm">¿Listo para iniciar la evaluación?</label>
                                    <TextareaDefault id="txtEvaluacion" height={80} placeholder="Escribe aquí" className="White" 
                                        errorClass={formik.touched.txtEvaluacion != undefined&& (formik.errors.txtEvaluacion?"error":"success")} 
                                        value={formik.values.txtEvaluacion} icon={<Iconsax.DocumentText1 />} name="txtEvaluacion"  
                                        onChange={formik.handleChange} onblur={formik.handleBlur} />
                                    <p className="error">{formik.errors.txtEvaluacion && formik.touched.txtEvaluacion && formik.errors.txtEvaluacion}</p>
                                </div>

                                <div className="field col-12 md:col-8">
                                    <label  className="p-eva-labelForm">Información de contacto en caso el colaborador necesite ayuda</label>
                                    {/* <SearchCard onChange={handleSelectChange} 
                                                    options={listUserTotal}
                                                    value={selected}
                                                    placeholder ="Escribe aquí el nombre del colaborador"
                                                    ></SearchCard> */}
                                    <MultiselectEva
                                        onChange={handleSelectChange}
                                        isMulti
                                        //options={selectButtonValues1}
                                        options={listUserTotal}
                                        value={selected}
                                    />
                                </div>

                            </div>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-4" />
                                <div className="field col-12 md:col-4" />
                                <div className="field col-12 md:col-2">
                                    <BotonEva nombre="Cancelar" color="secondary" type="button" metodoClick={() => navigate(-1)} widths="100%" />
                                </div>
                                <div className="field col-12 md:col-2">
                                    <BotonEva nombre={modoEdicion?"Actualizar":"Añadir"} color="primary" type="submit" loading={formik.isSubmitting} widths="100%"  />
                                    
                                </div>
                            </div>
                        </form>
            </FormikProvider>
            </div>
    
        </div>
    );
}
 
export default CrearTema;