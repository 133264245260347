import React, { useState ,useEffect,useRef} from 'react';
import { Card } from 'primereact/card';
import InputIcon from '../../InputIcon/InputIcon';
import InputDefault from '../../InputDefault/InputDefault';
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import "./SubirBanner.scss";
import Boton from '../../Boton/Boton';
import {ActualizarBanner} from "../../../service/ConfiguracionService.js"
// import { Toast } from 'primereact/toast';
import ToastEva from '../../Toast/ToastEva';
import { Dialog } from 'primereact/dialog';
const SubirBanner = ({onChange,listaImagenes}) => {
    const [resetImage, setResetImage] = useState(false);
    const [stateImage, setStateImage] = useState({file: null,base64URL: ""});
    const [stateArrayDocument,setStateArrayDocument] = useState([])   
    const [stateAdjDocument,setStateAdjDocument] = useState({nombreDocumento: "",imagenBase64: "",tipoDocumento:""})   
    const [htmlInputDocument, setHtmlInputDocument] = useState([]);
    const [maxNumInput, setMaxNumInput] = useState(3);
    const [numInputDocument, setNumInputDocument] = useState(1);
    const [nombresFile, setNombresFile] = useState(null);
    const [indexUso, setIndexUso] = useState(null);
    const [errorImage, setErrorImage] = useState(null);
    const aref = useRef([])
    const toast = useRef(null);

    const [showDialog, setShowDialog] = useState(false);

    const [banner, setBanner] = useState(null);
    const [arrayHelperSeleccionado, setArrayHelperSeleccionado] = useState(null);
    const [indexSeleccioonado, setIndexSeleccioonado] = useState(null);
    const [accionDelete, setAccionDelete] = useState(false);

    useEffect(()=>{
        if(errorImage) toast.current.show({severity:'error', summary: 'Error', detail:errorImage, life: 10000});
        setErrorImage(null)
    },[errorImage])
    // const onDeleteFile =(i)=>{
    //     var array = [...nombresFile];
        
    //     var nombreViejo = array[i];
    //     array[i] = null
            
        

    //     let arrAdjunto = [...stateArrayDocument]
    //     var index = arrAdjunto.findIndex(function(item, i){
    //         return item.nombreDocumento === nombreViejo
    //       });
          
    //     if (index !== -1) {

    //         arrAdjunto.splice(index, 1);
    //         setStateArrayDocument(arrAdjunto);
    //     }
    //     setHtmlInputDocument([])
    //     setNombresFile(array)
        
    // }
    
    //#region  Adjuntos
    // const RenderDocuentoAdjunto=(i,valor = null)=>{
        
        
    //     return (
    //         <div key={i}>
    //             <label >
    //                 Adjunta una foto
    //             </label>

    //             <div >
    //                 <div style={{display:"flex",alignItems:"center",position:"relative"}} >
    //                     <label  className={"eva-labelFile"} style={{height:"fit-content"}}>
    //                         <span>{nombresFile[i]??"Adjunte aquí"}</span>
                            
    //                     </label>
    //                     {!nombresFile[i] && <label htmlFor={"documentoAdjunto" + i} className='eva-labelFile-icon'><i ><Iconsax.Gallery /></i></label>}
    //                     {nombresFile[i] && <label className='eva-labelFile-icon'><i onClick={()=>onDeleteFile(i)} style={{cursor:"pointer"}}><Iconsax.Trash /></i></label>}
    //                 </div>
    //                 <input ref={e => (aref.current[i] = e)} type="file" id={"documentoAdjunto" + i} onChange={(e) => (onFileChange(e, "document",i),()=>setIndexUso(i))} 
    //                         accept="image/*" hidden ></input>
    //                 {/* <InputIcon id={"documentoAdjunto" + i}
    //                     placeholder="Adjunta aquí" className="grey" 
    //                     icon={<Iconsax.Gallery />}
    //                     display="none"
    //                     // modo={  && "delete"}
    //                     name={"documentoAdjunto" + i} type="File"
    //                     onChange={(e) => onFileChange(e, "document")}
    //                     OnClickDelete={onDeleteFile}
    //                     reset={resetImage}
    //                     accept="image/*"
    //                     width={"100%"}
    //                 /> */}
    //             </div>
                
    //         </div>
                
              
    //     );

    // }

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    
    const onFileChange = (e, typeInput, i) => {
        let { file } = stateImage;

        file = e.target.files[0];
        let img = new Image();
        const objectUrl = URL.createObjectURL(file);
        let ancho 
        let alto 
        try {
            img.onload = function () {
                //alert(this.width + " " + this.height);
                URL.revokeObjectURL(objectUrl);
                 ancho = this.width;
                 alto = this.height;
                
                 let razon = ancho/alto
                 if(Number(Math.round(razon+'e2')+'e-2') < 2.75 && 2.78 > Number(Math.round(razon+'e2')+'e-2'))  
                    // throw Error("Imagen no cumple con las dimensiones para banner."); 
                    setErrorImage("Imagen no cumple con las dimensiones para banner. La proporción correcta es de 640px - 232px")
                else{
                
                    getBase64(file)
                    .then((result) => {
                        file["base64"] = result;
                        //console.log("File Is", file);
                        if (typeInput === "image") {
                            setStateImage({
                                base64URL: result,
                                file,
                            });
                        } else {
                            setStateAdjDocument({
                                imagenBase64: result,
                                nombreDocumento: file.name,
                                tipoDocumento: file.type,
                            });

                            setIndexUso(i);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                        if (typeInput === "image") {
                            setStateImage({
                                file: e.target.files[0],
                            });
                        } else {
                            // setStateAdjDocument({
                            //     file: e.target.files[0]
                            //   })
                        }
                            }
                    
                
            };
            
            img.src = objectUrl;
            
        } catch (ex) {

            toast.current.show({severity:'error', summary: 'Error', detail:ex.message, life: 3000});

        }
        //console.log(e.target.files[0]);
    };

    //   useEffect(()=>{
    //     if(stateAdjDocument.nombreDocumento !== "")
    //     {
    //         var arrayFile =[...stateArrayDocument]
    //         arrayFile = [...stateArrayDocument,stateAdjDocument]
    //         // arrayFile = [...stateAdjDocument,file.name]
            
    //         setStateArrayDocument(arrayFile);
            
    //     }
        
    //   },[stateAdjDocument])

    //   useEffect(()=>{
    //     if(indexUso != null) {
    //         let arrTemp = [...nombresFile]
    //         arrTemp[indexUso] = stateAdjDocument.nombreDocumento

    //         arrTemp = [...arrTemp]
    //         setHtmlInputDocument([]);
    //         setNombresFile(arrTemp)
    //     }
    //   },[indexUso])

    // useEffect(()=>{
    //     // if(listaImagenes.length > 0) setNombresFile(listaImagenes)
    //     setHtmlInputDocument([])
    //     setNombresFile(listaImagenes)
    // },[listaImagenes])
    //#endregion
    // useEffect(()=>{
    //     var html = [...htmlInputDocument]
    //     if(nombresFile)
    //     {
    //         if(nombresFile.length >0 )
    //         {
    //             setNumInputDocument(nombresFile.length)
                
    //             // setNombresFile(listaImagenes)
    //             for(let i = 0 ;i< nombresFile.length ;i++)
    //             {   
                    
    //                 html = [...html,RenderDocuentoAdjunto(i,nombresFile[i])]
    //             }
    //         }  
            
    //         else{
    //             for(let i = 0 ;i< numInputDocument;i++)
    //             {
    //                 html = [...html,RenderDocuentoAdjunto(i)]
    //             }
    //         }
    //     }
        

    //     setHtmlInputDocument(html);

    // },[nombresFile])

    // const handleAñadirDoc =()=>{
    //     setNumInputDocument(numInputDocument+1)
    //     if(numInputDocument<= maxNumInput)
    //     {
    //         var html = [...htmlInputDocument]
    //         html = [...htmlInputDocument,RenderDocuentoAdjunto(numInputDocument)]
    //         setHtmlInputDocument(html)
    //     }
        

    // }    
     
    useEffect(()=>{
        if(listaImagenes.length >0)
        {
            let temp = [];
            listaImagenes.map((item,i)=>{

                temp.push({nombreDocumento: item,imagenBase64: "",tipoDocumento:""});
            })

            setBanner(temp);


        }
    },[listaImagenes])
    // const publicarBanner =()=>{
        
    //     let listaAdjuntos = stateArrayDocument;
    //     let codigo ="IMG_BANNER";
    //     let valor = "";
    //     // listaAdjuntos.map((item,i)=>{
    //     //     valor = valor + item.nombreDocumento +"|";
    //     // })

    //     nombresFile.map((item,i)=>{
    //             if(item) valor = valor + item +"|";

    //         })
    
    //     valor = valor.substring(0, valor.length - 1);

    //     let jsonConfiguracion = JSON.stringify({ codigo,valor, listaAdjuntos});
    //     console.log(jsonConfiguracion);
    //     let jwt = window.localStorage.getItem("jwt");
    //     //alert(jsonConfiguracion);
    //     ActualizarBanner({jsonConfiguracion,jwt})
    //     .then(data => {
    //         console.log("success: "+data);
    //         window.location.reload();
    //     })
    // }
    const Actualizar =({jsonConfiguracion})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarBanner({jsonConfiguracion,jwt}).then(data=>{
            //setAccionDelete(false)
            setShowDialog(false)
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Banner actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                window.location.reload();
            }, 3000)
        })
        .catch(errors => {
            setAccionDelete(false)
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
const handleDelete=(index,arrayHelpers)=>{
    setArrayHelperSeleccionado(arrayHelpers)
    setIndexSeleccioonado(index)
    setShowDialog(true)
}
const EliminarBanner=()=>{
    arrayHelperSeleccionado.remove(indexSeleccioonado)
    setAccionDelete(true)
   
    
}
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            documentos : banner ? banner : [{nombreDocumento: "",imagenBase64: "",tipoDocumento:""}]
        },
      //validationSchema: schema,
      onSubmit: values => {
        let listaAdjuntos = values.documentos;
        let codigo ="IMG_BANNER";
        let valor = "";
        
        listaAdjuntos.map((item,i)=>{
            if(item) valor = valor + item.nombreDocumento +"|";

        })

        valor = valor.substring(0, valor.length - 1);

        let jsonConfiguracion = JSON.stringify({ codigo,valor, listaAdjuntos});
        Actualizar({jsonConfiguracion}) 
        },
    });

    useEffect(()=>{
        if(accionDelete){

            let listaAdjuntos = formik.values.documentos;
            let codigo ="IMG_BANNER";
            let valor = "";
                
            listaAdjuntos.map((item,i)=>{
                if(item) valor = valor + item.nombreDocumento +"|";
        
            })
        
            valor = valor.substring(0, valor.length - 1);
        
            let jsonConfiguracion = JSON.stringify({ codigo,valor, listaAdjuntos});
            Actualizar({jsonConfiguracion}) 
            //alert(jsonConfiguracion)
        }
    },[formik.values.documentos,accionDelete])

    const header = 
    <label  className='header-subtitle'>Subir banner</label>


    const cardFooter =(arrayHelpers)=>{
        return(
            <div className="p-eva-subirBanner-footer">
                {
                    formik.values.documentos.length <=3 &&
                        <i onClick={() => arrayHelpers.push({ nombreDocumento: "",imagenBase64: "",tipoDocumento:"" })}>Añadir más</i>
                }
                
                <Boton nombre={"Publicar"} color='primary' widths='50%'type="submit" loading={formik.isSubmitting}
                    // disabled = {stateArrayDocument.length > 0 ?false:true}
                    ></Boton>
            </div>
        )
       
    }
    const footerDelete = 
    <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
        <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialog(false)}></Boton>
        <Boton nombre={"Eliminar banner"} widths="50%" color="primary" metodoClick={EliminarBanner} 
        ></Boton>
    </div>;
    return (
        <div className="p-eva-subirBanner" style={{marginBottom:10, width:"100%"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                   
                        {/* {
                            htmlInputDocument ?? null
                        } */}
                        {/* <div className="p-fluid formgrid grid"> */}
                            <FieldArray
                                name="documentos"
                                render={(arrayHelpers) => (

                                    <Card header={header} className="p-eva-subirBanner-Card" footer={()=>cardFooter(arrayHelpers)}>
                                        <div style={{display:"grid",gap:8}}>
                                        {
                                            
                                            formik.values.documentos && 
                                            formik.values.documentos.map((item,index)=>(
                                                
                                                <div style={{ display: "flex", alignItems: "center", position: "relative"}} key={index}>
                                                    <label className={"eva-labelFile"} style={{ minHeight: 16, height: "fit-content" }}>
                                                        <span>{formik.values.documentos[index].nombreDocumento.length > 0 ? formik.values.documentos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                    </label>
                                                    {formik.values.documentos[index].nombreDocumento != "" ? (
                                                        <label className="eva-labelFile-icon">
                                                            <i style={{ cursor: "pointer" }} onClick={()=>handleDelete(index,arrayHelpers)}>
                                                                <Iconsax.Trash />
                                                            </i>
                                                        </label>
                                                    ) : (
                                                        <label htmlFor={`documentos[${index}].nombreDocumento`} className="eva-labelFileLider-icon">
                                                            <i>
                                                                <Iconsax.DocumentText1 color="#F47647" />
                                                            </i>
                                                        </label>
                                                    )}

                                                    {/* <input id={`documentos[${index}].imagenBase64`}
                                                                name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                    <input
                                                        type="file"
                                                        id={`documentos[${index}].nombreDocumento`}
                                                        name={`documentos[${index}].nombreDocumento`}
                                                        onChange={(e) => {
                                                            let { file } = {file: null,base64URL: ""};

                                                            file = e.target.files[0];
                                                            let img = new Image();
                                                            const objectUrl = URL.createObjectURL(file);
                                                            let ancho 
                                                            let alto 
                                                            try {
                                                                img.onload = function () {
                                                                    //alert(this.width + " " + this.height);
                                                                    URL.revokeObjectURL(objectUrl);
                                                                    ancho = this.width;
                                                                    alto = this.height;
                                                                    
                                                                    let razon = ancho/alto
                                                                    if(Number(Math.round(razon+'e2')+'e-2') < 2.75 && 2.78 > Number(Math.round(razon+'e2')+'e-2'))  
                                                                         //throw Error("Imagen no cumple con las dimensiones para banner. La proporción correcta es de 640px - 232px"); 
                                                                        setErrorImage("Imagen no cumple con las dimensiones para banner. La proporción correcta es de 640px - 232px")
                                                                    else{
                                                                    
                                                                            formik.setFieldValue(`documentos[${index}].nombreDocumento`, file.name);
                                                                            formik.setFieldValue(`documentos[${index}].tipoDocumento`, file.type);
                                                                            getBase64(file).then((result) => {
                                                                                formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                            });
            
                                                                        }
                                                                        
                                                                    
                                                                };
                                                                
                                                                img.src = objectUrl;
                                                                
                                                            } catch (ex) {

                                                                toast.current.show({severity:'error', summary: 'Error', detail:ex.message, life: 3000});

                                                            }
                                                            
                                                            //onFileChange(e, "document")
                                                        }}
                                                        accept="application/pdf,audio/*,image/*"
                                                        hidden
                                                    ></input>
                                                </div>
                                            
                                            ))
                                        }
                                        </div>
                                    </Card>
                                )}>

                                </FieldArray>
                        {/* </div> */}
                    
                </form>
            </FormikProvider>
            
            <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                footer={footerDelete}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar banner?</label>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>
        </div>
    );
}
 
export default SubirBanner;