import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field, FieldArray, Formik, useFormik, FormikProvider } from "formik";
import { ObtenerCapacitacionOpcionalPorId, ActualizarCapacitacionOpcional, RegistrarCapacitacionOpcional } from "../../../service/CapacitacionService";
import ToastEva from "../../../components/Toast/ToastEva";
import TextareaDefault from "../../../components/TextArea/TextArea";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
import Boton from "../../../components/Boton/Boton";
import { ListarGerencias } from "../../../service/GerenciaService";
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import classNames from "classnames";
import { Calendar } from "primereact/calendar";
const EditarCapaOpcional = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Crear nueva capacitación");
    const [modoEdicion, setModoEdicion] = useState(false);
    const [capacitacionOpcional, setCapacitacionOpcional] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { IdGerencia } = useParams();
    let { idCapacitacion } = useParams();
    const [listGerencias, setListGerencias] = useState(null);

    useEffect(() => {
        const ObtenerPorId = async () => {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCapacitacion;
            await ObtenerCapacitacionOpcionalPorId({ jwt, Id }).then((data) => {
                setCapacitacionOpcional(data);
                setModoEdicion(true);
                setTituloPagina("Editar capacitación");
            });
        };
        if (idCapacitacion) {
            ObtenerPorId();
        }
    }, [idCapacitacion]);

    useEffect(() => {
        const getGerencias = async () => {
            let jwt = window.localStorage.getItem("jwt");
            ListarGerencias(jwt).then((data) => {
                let temp = [{ code: "General", value: 0 }];

                setListGerencias(temp.concat(data));
            });
        };
        if (!listGerencias) getGerencias();
    }, []);

    // useEffect(()=>{
    //     if(capacitacionOpcional  && !modoEdicion) {

    //         setTituloPagina("Editar capacitacion")
    //         setModoEdicion(true)
    //     }
    // },[capacitacionOpcional])

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";

            let reader = new FileReader();

            reader.readAsDataURL(file);
            const regex = /data:.*base64,/;
            reader.onload = () => {
                //console.log("Called", reader);
                baseURL = reader.result;
                //console.log(baseURL);
                resolve(baseURL.replace(regex, ""));
            };
            console.log(fileInfo);
        });
    };

    const onDeleteFile = (i, arrayHelpers) => {
        arrayHelpers.remove(i);
    };
    const onDeleteLink = (i, arrayHelpers) => {
        arrayHelpers.remove(i);
    };
    const Actualizar = ({ jsonCapacitacion }) => {
        let jwt = window.localStorage.getItem("jwt");
        ActualizarCapacitacionOpcional({ jsonCapacitacion, jwt })
            .then((data) => {
                formik.setSubmitting(false);
                toast.current.show({ severity: "success", summary: "Success", detail: "Registro actualizado exitosamente.", life: 7000 });

                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            })
            .catch((errors) => {
                toast.current.show({ severity: "error", summary: "Error", detail: errors.message, life: 7000 });
            });
    };

    const Registrar = ({ jsonCapacitacion }) => {
        let jwt = window.localStorage.getItem("jwt");
        RegistrarCapacitacionOpcional({ jsonCapacitacion, jwt })
            .then((data) => {
                formik.setSubmitting(false);
                toast.current.show({ severity: "success", summary: "Success", detail: "Registro exitoso.", life: 7000 });

                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            })
            .catch((errors) => {
                toast.current.show({ severity: "error", summary: "Error", detail: errors.message, life: 7000 });
            });
    };
    const schema = Yup.object().shape({
        nombre: Yup.string().required("Campo nombre de capacitación requerido."),
        anio: Yup.string().required("Campo año de capacitación requerido."),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: capacitacionOpcional ? capacitacionOpcional.id : 0,
            idGerencia: capacitacionOpcional ? capacitacionOpcional.idGerencia : 0,
            nombre: capacitacionOpcional ? capacitacionOpcional.nombre : "",
            test: capacitacionOpcional ? capacitacionOpcional.test : "",
            descripcion: capacitacionOpcional ? capacitacionOpcional.descripcion : "",
            nombreFoto: capacitacionOpcional ? capacitacionOpcional.nombreFoto : "",
            anio: capacitacionOpcional ? new Date("01/01/" + capacitacionOpcional.anio) : null,
            base64: null,
            tipoDocumento: null,
            encuestaSatisfaccion: capacitacionOpcional ? capacitacionOpcional.encuestaSatisfaccion : "",
            documentos:
                capacitacionOpcional && capacitacionOpcional.capacitacion_opcional_documentos.length > 0
                    ? capacitacionOpcional.capacitacion_opcional_documentos.filter((x) => x.tipo == "Adjunto" && x.estado == true)
                    : [{ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null }],
            listaUrls:
                capacitacionOpcional && capacitacionOpcional.capacitacion_opcional_documentos.length > 0
                    ? capacitacionOpcional.capacitacion_opcional_documentos.filter((x) => x.tipo == "Url" && x.estado == true)
                    : [{ id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Url", estado: false, fechaEliminacion: null, usuarioEliminacion: null }],
        },
        validationSchema: schema,
        onSubmit: (values) => {
            let id = values.id;
            let idGerencia = values.idGerencia;
            let nombre = values.nombre;
            let descripcion = values.descripcion;
            let nombreFoto = values.nombreFoto;
            let base64 = values.base64;
            let tipoDocumento = values.tipoDocumento;
            let anio = values.anio.getFullYear();
            let test = values.test;
            let encuestaSatisfaccion = values.encuestaSatisfaccion;
            let listaDocCompleto = values.documentos.filter((x) => x.nombreDocumento != "");
            let listaUrls = values.listaUrls.filter((x) => x.nombreDocumento != "");

            let jsonCapacitacion = JSON.stringify({ id, idGerencia, nombre, test, encuestaSatisfaccion, listaUrls, listaDocCompleto, descripcion, nombreFoto, base64, tipoDocumento, anio }, null, 2);
            console.log(jsonCapacitacion);
            // //alert(jsonTema);
            if (modoEdicion) Actualizar({ jsonCapacitacion });
            else {
                Registrar({ jsonCapacitacion });
            }
        },
    });
    return (
        <div className="eva-dashboard-editarCapaOpcional" style={{ marginLeft: 40 }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            {IdGerencia == null && (
                                <div className="field col-12 md:col-5">
                                    <label htmlFor="nombre">Gerencia</label>
                                    <DropdownDefault
                                        id="idGerencia"
                                        name="idGerencia"
                                        options={listGerencias}
                                        optionLabel="code"
                                        optionValue="value"
                                        errorClass={formik.touched.idGerencia != undefined && (formik.errors.idGerencia ? "error" : "success")}
                                        placeholder="Seleccione"
                                        value={formik.values.idGerencia}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <p className="error">{formik.errors.idGerencia && formik.touched.idGerencia && formik.errors.idGerencia}</p>
                                </div>
                            )}

                            <div className="field col-12 md:col-8">
                                <label htmlFor="nombre">Nombre de la capacitaciones</label>
                                <InputDefault
                                    id="nombre"
                                    name="nombre"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")}
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.nombre && formik.touched.nombre && formik.errors.nombre}</p>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="nombre">Adjunta foto de portada </label>
                                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                    <label
                                        className={classNames("eva-labelFileLider", {
                                            "p-eva-error": formik.errors.nombreFoto,
                                        })}
                                        style={{ minHeight: 16, height: "fit-content" }}
                                    >
                                        <span>{formik.values.nombreFoto && formik.values.nombreFoto.length > 0 ? formik.values.nombreFoto : "Adjunta aquí"}</span>
                                    </label>
                                    {formik.values.nombreFoto != "" ? (
                                        <label className="eva-labelFileLider-icon">
                                            <i
                                                onClick={() => {
                                                    formik.setFieldValue(`nombreFoto`, "");
                                                    formik.setFieldValue(`base64`, null);
                                                }}
                                            >
                                                <Iconsax.Trash />
                                            </i>
                                        </label>
                                    ) : (
                                        <label htmlFor={`nombreFoto`} className="eva-labelFileLider-icon">
                                            <i>
                                                <Iconsax.GalleryAdd color="#F47647" />
                                            </i>
                                        </label>
                                    )}

                                    <input
                                        type="file"
                                        id={`nombreFoto`}
                                        name={`nombreFoto`}
                                        onChange={(e) => {
                                            formik.setFieldValue(`nombreFoto`, e.currentTarget.files[0].name);
                                            formik.setFieldValue(`tipoDocumento`, e.currentTarget.files[0].type);
                                            getBase64(e.currentTarget.files[0]).then((result) => {
                                                formik.setFieldValue(`base64`, result);
                                            });

                                            //onFileChange(e, "document")
                                        }}
                                        accept="image/*"
                                        hidden
                                    ></input>
                                </div>
                            </div>
                            {/* {IdGerencia == null && (
                                <div className="field col-12 md:col-12">
                                    <label htmlFor="descripcion" className="p-eva-labelForm">
                                        Añade una descripción
                                    </label>
                                    <TextareaDefault
                                        id="descripcion"
                                        minHeight={180}
                                        placeholder="Escribe aquí"
                                        className="White"
                                        errorClass={formik.errors.descripcion && formik.touched.descripcion ? "error" : "success"}
                                        value={formik.values.descripcion}
                                        icon={<Iconsax.DocumentText1 />}
                                        name="descripcion"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <small className="p-error">{formik.errors.descripcion && formik.touched.descripcion}</small>
                                </div>
                            )} */}

                            <div className="field col-12 md:col-4">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Seleccione año
                                </label>
                                <Calendar
                                    className="capacitacion-anio"
                                    id="anio"
                                    name="anio"
                                    value={formik.values.anio}
                                    onChange={(e) => {
                                        formik.setFieldValue(`anio`, e.value);
                                    }}
                                    view="year"
                                    dateFormat="yy"
                                ></Calendar>
                                <small className="p-error">{formik.errors.anio && formik.touched.anio}</small>
                            </div>
                            <div className="field col-12 md:col-4"></div>
                            <div className="field col-12 md:col-4"></div>
                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega documentos</label>
                            </div>
                            <FieldArray
                                name="documentos"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.documentos &&
                                                    formik.values.documentos.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.documentos[index].nombreDocumento.length > 0 ? formik.values.documentos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`documentos[${index}].nombreDocumento`}
                                                                    name={`documentos[${index}].nombreDocumento`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.documentos[index].nombreDocumento}
                                                                    onChange={(e) => {
                                                                        formik.values.documentos[index].imagenBase64 && formik.handleChange(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.documentos[index].nombreDocumento != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpers)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`documentos[${index}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`documentos[${index}].nombreDocumentoIcon`}
                                                                    name={`documentos[${index}].nombreDocumentoIcon`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`documentos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multiple" style={{ cursor: "pointer" }}>
                                                    <i className="p-eva-link-multiple">Añadir múltiple</i>
                                                </label>
                                                <input
                                                    type="file"
                                                    id={`file-multiple`}
                                                    name={`file-multiple`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles = e.currentTarget.files;
                                                        console.log(tempFiles);
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64 = "";
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayHelpers.push({ imagenBase64: base64, tipoDocumento: tipo, id: 0, idCapacitacionMia: 0, nombreDocumento: nombre, tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null });
                                                            });
                                                        }
                                                    }}
                                                    accept="application/pdf,audio/*,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega links de videos</label>
                            </div>
                            <FieldArray
                                name="listaUrls"
                                render={(arrayHelpersLink) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaUrls &&
                                                    formik.values.listaUrls.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <InputIcon
                                                                    id={`listaUrls[${index}].nombreDocumento`}
                                                                    name={`listaUrls[${index}].nombreDocumento`}
                                                                    className="white"
                                                                    placeholder="Ingresa el link aquí"
                                                                    errorClass={formik.touched.nombreDocumento != undefined && (formik.errors.nombreDocumento ? "error" : "success")}
                                                                    value={formik.values.listaUrls[index].nombreDocumento}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    icon={formik.values.listaUrls[index].nombreDocumento != "" && <Iconsax.Trash />}
                                                                    onClickIcon={() => onDeleteLink(index, arrayHelpersLink)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLink.push({ id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Url", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Test</label>
                                <InputDefault id="test" name="test" className="white" placeholder="Escribe aquí" errorClass={formik.touched.test != undefined && (formik.errors.test ? "error" : "success")} value={formik.values.test} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <p className="error">{formik.errors.test && formik.touched.test && formik.errors.test}</p>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="encuestaSatisfaccion" className="p-eva-labelForm">
                                    Encuesta de satisfacción
                                </label>
                                <TextareaDefault
                                    id="encuestaSatisfaccion"
                                    minHeight={96}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.touched.encuestaSatisfaccion != undefined && (formik.errors.encuestaSatisfaccion ? "error" : "success")}
                                    value={formik.values.encuestaSatisfaccion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="encuestaSatisfaccion"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.encuestaSatisfaccion && formik.touched.encuestaSatisfaccion && formik.errors.encuestaSatisfaccion}</p>
                            </div>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={modoEdicion ? "Actualizar" : "Registrar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
};

export default EditarCapaOpcional;
