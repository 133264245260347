
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import * as Iconsax from "iconsax-react";
import useUsuario from "../../../hooks/useUsuario";
import {ListarConvocatoria} from "../../../service/DesarrolloService";
import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";
import Boton from "../../../components/Boton/Boton";
import "./Convocatoria.scss";
import classNames from "classnames";
import { ObtenerFechaFormatoPalabraMes } from "../../../helpers/helpers";
const Convocatoria = () => {
    const navigate = useNavigate();
    const { permisos,isLogged,perfil,configuraciones} = useUsuario();
    const [listaConvocatorias, setListaConvocatorias] = useState(null);
    const [permisoEditar, setPermisoEditar] = useState(false);
    // const cardClassName = classNames("p-eva-card-convocatoria", {
    //     "white":className === "white" ? true:false,
    //     "grey" : className === "grey" ? true:false,
    //     "p-eva-error" : errorClass === "error" ? true:false,
    //     "p-eva-success" : errorClass === "success" ? true:false,
    // });
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarConvocatorias") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verConvocatorias") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        const ObtenerListaConvocatorias = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarConvocatoria({jwt}).then(data => 
                {
                    setListaConvocatorias(data);
                })
        }

        if(!listaConvocatorias)
        {
            ObtenerListaConvocatorias();
        }
     },[]);


     const header =(item)=> <div className="text-lg font-bold" style={{display:"flex",alignItems:"center",padding:16,color:"#000000"}}>
            <span style={{width:"90%",display:"flex",justifyContent:"start"}}>{item.nombre}</span>
            <span style={{width:"10%",display:"flex",justifyContent:"end"}}>
                    {permisoEditar && <Iconsax.Edit2 color="#F47647" onClick={()=>navigate("EditarConvocatoria/"+item.id)}></Iconsax.Edit2>}
            </span>
     </div>
            
        

     const footer =(url)=><div>
        <Boton nombre={"Conoce el perfil y postula"} color="secondary" widths="95%" icon="pi pi-angle-right"
            metodoClick={()=>window.open(url.indexOf("http") > -1 ? url : "http://"+url, '_blank', 'noopener,noreferrer')}></Boton>
     </div>
    return ( 
        <div className="eva-dashboard-Convocatorias">
            <div className="eva-dashboard-Convocatorias-body" >
            {   
                        listaConvocatorias ?
                            (listaConvocatorias.length > 0 &&
                                listaConvocatorias.map((item,i)=>{
                                    if (item.listaConvocatoria.length > 0)
                                    {
                                        return(
                                            <div key={i}>
                                                <div style={{marginBottom:16,marginTop:24}}>
                                                    <label className="text-2xl font-bold" htmlFor="header">{item.nombreGerencia}</label>
                                                </div>
                                                <div className="grid">
                                                    {item.listaConvocatoria.map((convocatoria,j)=>{
                                                            return(
                                                                <div className="col-12 md:col-3" key={j} >
                                                                    {/* style={{opacity: objPublicacion.habilitado ? 1:0.3,pointerEvents:objPublicacion.habilitado ?"auto":"none"}} */}
                                                                    {
                                                                        convocatoria.vencido ? 
                                                                        <Card className={"p-eva-card-convocatoria disabled"}
                                                                            header={()=>header(convocatoria)} 
                                                                            footer={()=>footer(convocatoria.linkConvocatoria)}>
                                                                            {"Postulación vencida"}
                                                                        </Card>
                                                                        :
                                                                        <Card className={"p-eva-card-convocatoria"}
                                                                            header={()=>header(convocatoria)} 
                                                                            footer={()=>footer(convocatoria.linkConvocatoria)}>
                                                                            {"Postula hasta: "+ ObtenerFechaFormatoPalabraMes(convocatoria.fechaMaximaFormat)}
                                                                        </Card>
                                                                    }
                                                                    
                                                                    
                                                                </div>
                                                            )
                                                    })}
                                                </div>
                                                
        
                                            </div>
                                            
                                        )
                                    }
                                    
                                }))
                        : 
                        <Skeleton/>
                    }
            </div>

        </div>
     );
}
 
export default Convocatoria;