import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionObligatoriaPorId } from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import * as constantes from "../../../constants/constantes";
import parse from 'html-react-parser'
import CardFile from "../../../components/CardFileEva/CardFile";
import Boton from "../../../components/Boton/Boton";
import "./CapaObligatoria.scss"
const DetalleCapaObligatoria = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(true);
    const [capaObligatoria, setCapaObligatoria] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { idCapacitacion} = useParams();
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        const getCapacitacion = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCapacitacion;
            await ObtenerCapacitacionObligatoriaPorId({jwt,Id}).then(data => 
                {
                    setCapaObligatoria(data)
                })
        }
        if(!capaObligatoria) getCapacitacion();

    },[idCapacitacion])
    return ( 
        <div className="eva-detalleCapaObligatoria" style={{ marginLeft: 40 }}>
             <ToastEva ref={toast} position="top-center"></ToastEva>
             <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    
                </div>
                
                <div>{capaObligatoria && <label htmlFor="header">{capaObligatoria.nombreCapacitacion}</label>}</div>
            </div>
            <div className="p-eva-dashboard-detalleCapaObligatoria" style={{ marginTop: 32 }}>
                <div className=" p-fluid formgrid grid">
                    {
                        capaObligatoria && 
                        capaObligatoria.capacitacion_obligatoria_documentos
                        .filter(x=>x.tipo =="Url"&& x.estado == true).length>0 &&
                        <div className=" p-fluid formgrid grid">
                            {
                                capaObligatoria && 
                                capaObligatoria.capacitacion_obligatoria_documentos
                                .filter(x=>x.tipo =="Url"&& x.estado == true)
                                .map((item,i)=>{
                                    var re = /(?:\.([^.]+))?$/;
                                    return (
                                        <>
                                            
                                            <div className="field col-12 md:col-3" key={i}>
                                                {i==0 && <div className="header-subtitle" style={{width:270}}><span>Videos de la capacitación</span></div>}
                                                <CardFile key={i} extensionArchivo={"video"} nombreArchivo={item.nombreDocumento} keys={i} tipo="Urls" />
                                            </div>
                                        </>
                                        
                                    );
                                })
                            }
                        </div>
                    }
                    
                    {
                        capaObligatoria && 
                        capaObligatoria.capacitacion_obligatoria_documentos
                        .filter(x=>x.tipo =="Adjunto"&& x.estado == true).length >0 &&
                        <div className=" p-fluid formgrid grid">
                            {
                                capaObligatoria && 
                                capaObligatoria.capacitacion_obligatoria_documentos
                                .filter(x=>x.tipo =="Adjunto"&& x.estado == true)
                                .map((item,i)=>{
                                    var re = /(?:\.([^.]+))?$/;
                                    return (
                                        <div className="field col-12 md:col-3" key={i}>
                                            {i==0 && <div className="header-subtitle" style={{width:270}}>Material de apoyo</div>}
                                            <CardFile urlFile={constantes.URLBLOB_CAPAOBLIGATORIA + "/" +capaObligatoria.id+"/"+ item.nombreDocumento} 
                                            key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} 
                                            nombreArchivo={item.nombreDocumento} 
                                            keys={i} tipo="Adjuntos" descargable={true} />
                                        </div>
                                        
                                    );
                                })
                            }
                        </div>
                    }
                    
                </div>
                <div className="header-subtitle"><span>¡Es hora de poner en práctica lo aprendido!</span></div>
                <div className="p-eva-labelForm"><span>Este test tiene como objetivo validar los conocimientos aprendidos en la capacitación. Una vez que inicies el test, no podrás salir hasta que lo finalices.</span></div>
                <div style={{display:"flex",justifyContent:"end",paddingTop:16}}>
                    <Boton nombre={"Comenzar"} color="primary" widths="184px"
                    metodoClick={()=>navigate("../Capacitacion/Seccion/" + idSeccion + "/Preguntas/"+idCapacitacion)}
                    ></Boton>
                    </div>
            </div>
        </div>

     );
}
 
export default DetalleCapaObligatoria;