import { Skeleton } from "primereact/skeleton";
const Sk_Grupos = () => {
    return ( 
        <>
            <div>
                <Skeleton height="30px" width="120px"/>
                <div className="p-fluid formgrid grid" style={{marginTop:"20px"}}>
                    <div className="field col-12 md:col-4">
                        <Skeleton borderRadius="16px" width="344px" height="296px" />
                    </div>
                    <div className="field col-12 md:col-4">
                        <Skeleton borderRadius="16px" width="344px" height="296px" />
                    </div>
                    <div className="field col-12 md:col-4">
                        <Skeleton borderRadius="16px" width="344px" height="296px" />
                    </div>
                </div>
                <Skeleton height="30px" width="120px"/>
                <div className="p-fluid formgrid grid" style={{marginTop:"20px"}}>
                    <div className="field col-12 md:col-4">
                        <Skeleton borderRadius="16px" width="344px" height="296px" />
                    </div>
                    <div className="field col-12 md:col-4">
                        <Skeleton borderRadius="16px" width="344px" height="296px" />
                    </div>
                    <div className="field col-12 md:col-4">
                        <Skeleton borderRadius="16px" width="344px" height="296px" />
                    </div>
                </div>
            </div>
            +
        </>
     );
}
 
export default Sk_Grupos;