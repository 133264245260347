import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { Skeleton } from "primereact/skeleton";
import "./Reconocimiento.scss"
import Boton from "../../../components/Boton/Boton";

import { ObtenerFechaFormatoCorto } from "../../../helpers/helpers";
import * as constantes from "../../../constants/constantes";
import { ObtenerListaSeccionClima,ObtenerClimaReconocimiento,EliminarClimaCategoriaPorId } from "../../../service/ClimaService";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
const ReconocimientoDetalle = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario()
    const [tituloPagina, setTituloPagina] = useState("");
    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [reconocimientos, setReconocimientos] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    const [idSeleccionado, setIdSeleccionado] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarClima") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verClima") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        const obtenerListaSeccionClima = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccionClima);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccionClima){
            obtenerListaSeccionClima();
        } 
    },[idSeccionClima])

    const getReconocimiento = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerClimaReconocimiento({jwt}).then(data => 
                {
                    setReconocimientos(data)

                })
        }

    useEffect(()=>{
        
        if(!reconocimientos)getReconocimiento()
    },[])

    const EliminarCategoria=()=>{
        setIsLoading(true)
        let jwt = window.localStorage.getItem("jwt");
        let Id = idSeleccionado
        EliminarClimaCategoriaPorId({Id,jwt}).then(data => 
        {               
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro eliminado exitosamente.", life: 7000})
    
            setShowDialog(false)
            getReconocimiento()
            setIsLoading(false)
    
        }).catch(errors => {
            
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            setIsLoading(false)
    
        })
    
    }

    const footerDelete = 
                <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialog(false)}></Boton>
                    <Boton nombre={"Eliminar categoría"} widths="50%" color="primary" metodoClick={EliminarCategoria} 
                    loading = {isLoading}></Boton>
                </div>;

    return ( 
        <div className="eva-reconocimientos" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
             <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    {/* <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div> */}
                    <div className="header">{reconocimientos && <label htmlFor="header">{reconocimientos.titulo}</label>}</div>

                    <div className="header-right">{permisoEditar && tituloPagina && <Link to={"../Clima/Seccion/" + idSeccionClima + "/EditarReconocimiento"}>{"Editar "+tituloPagina}</Link>}</div>
                </div>
            </div>
            <div className="p-eva-dashboard-reconocimientos" style={{ marginTop: 16 }}>
                <div className="p-eva-labelForm">
                    {
                        reconocimientos && <span>{reconocimientos.descripcion}</span>
                    }
                </div>
                <div className="header-subtitle" style={{marginTop:24}}>{reconocimientos? reconocimientos.subTitulo1:""}</div>
                {/* <div className="header-subtitle" style={{marginTop:24}}>Conoce nuestras categorías de reconocimiento</div> */}
                <div className="p-eva-labelForm">
                    {
                        reconocimientos && <span style={{whiteSpace:"pre-line"}}>{reconocimientos.descripcionCategoria}</span>
                    }
                </div>
                <div className="reconocimientos-body-repositorio">
                    {
                        reconocimientos &&
                        reconocimientos.clima_reconocimiento_categoria.length > 0 &&
                        reconocimientos.clima_reconocimiento_categoria.map((item,i)=>{
                            return(
                                <div className="repositorio-item" key={i}>
                                    {
                                            permisoEditar && 
                                                <div className="repositorio-item-delete" onClick={()=>
                                                {
                                                    setIdSeleccionado(item.id)
                                                    setShowDialog(true)
                                                }}>
                                                    <Iconsax.Trash color="#FFFFFF"/>
                                                </div>
                                        }
                                    <div className="repositorio-item-header">
                                        {/* {
                                            item.nombreFoto.length > 0 ?
                                            <img src={constantes.URLBLOB_CLIMA_RECONOCIMIENTO+"/"+item.nombreFoto}
                                                style={{maxWidth:312,maxHeight:136}}></img>
                                            :
                                            <div className="repositorio-item-header-nombre">
                                                {item.nombre}
                                            </div>
                                        } */}
                                        <img src={constantes.URLBLOB_CLIMA_RECONOCIMIENTO+"/"+item.nombreFoto}
                                               style={{maxWidth:312,maxHeight:136}}></img>
                                        <div>{item.nombre}</div> 
                                    </div>
                                    <div className="repositorio-item-footer">
                                        <Boton nombre={"Ver más"} widths="312px" color="secondary" margin={0} metodoClick={()=>navigate("../Clima/Seccion/"+idSeccionClima+"/DetalleCategoria/"+item.id)}></Boton>
                                    </div>
                                </div>
                            )

                        })
                    }
                </div>
            </div>
            <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                footer={footerDelete}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar esta categoría?</label>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>
        </div>
     );
}
 
export default ReconocimientoDetalle;