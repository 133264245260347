import React, { useEffect, useState ,useRef} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
//validar que hay que quitar

import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";

import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import * as Iconsax from "iconsax-react";
import * as Yup from "yup";
import "../../components/Login/ChangePassword.scss";
// import usePassword from "../../hooks/usePassword";
import perfilServices from "../../service/PerfilService";
import getPerfil from "../../service/PerfilService";

import {ActualizarMiClave} from "../../service/PasswordService";
import ToastEva from "../Toast/ToastEva";
import Boton from "../Boton/Boton";

export default function(props) {
    const navigate = useNavigate();
    //const passUser = window.localStorage.getItem("passTemp");

    
    //const [requiredChangePass,setrequiredChangePass] = useState(window.localStorage.getItem('reset'))
    //const [changePasswordDialog, setChangePasswordDialog] = useState(passUser == props.value ? true : false);
    const [changePasswordDialog, setChangePasswordDialog] = useState(window.localStorage.getItem('reset')==='false'|| !window.localStorage.getItem('reset')?false:true);

    const [passwordTypeNew, setPasswordTypeNew] = useState("password");
    const [passwordTypeRepeat, setPasswordTypeRepeat] = useState("password");
    // const { isChange, changePass } = usePassword();
    const toast = useRef(null);
    // useEffect(() => {
    //     if (isChange) {
    //         window.localStorage.setItem('reset',false)
    //         setChangePasswordDialog(false);
    //         window.location = "#/Home"; 
            
            
    //     }
    // }, [isChange]);


    const togglePasswordNew = () => {
        if (passwordTypeNew === "password") {
            setPasswordTypeNew("text");
            return;
        }
        setPasswordTypeNew("password");
    };

    const togglePasswordRepeat = () => {
        if (passwordTypeRepeat === "password") {
            setPasswordTypeRepeat("text");
            return;
        }
        setPasswordTypeRepeat("password");
    };

    //if(passUser == props.value) {ssetProducts(emptyProduct)}

    const hideDialog = () => {
        setChangePasswordDialog(false);
    };

    const clasesnameDialog = classNames({
        "peva-dialog-password": changePasswordDialog,
    });
    const schema = Yup.object().shape({
        passNew: Yup.string()
            .required("Contraseña es un campo obligatorio")
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .test("OK", "Contraseña debe tener al menos una mayúscula", function (value) {
                var s = value;
                var numUpper = 1;
                if (s != null) numUpper = s.length - s.replace(/[A-Z]/g, "").length;
                return numUpper > 0;
            })
            .test("OK", "Contraseña debe tener al menos una minúscula", function (value) {
                var s = value;
                var numlower = 0;
                if (s != null) numlower = s.length - s.replace(/[a-z]/g, "").length;
                return numlower > 0;
            })
            .test("OK", "Contraseña requiere un número.", function (value) {
                var s = value;
                var numbers = 1;
                if (s != null) numbers = s.length - s.replace(/[0-9]/g, "").length;
                return numbers > 0;
            }),
        passNewRepeat: Yup.string()
        .oneOf([Yup.ref('passNew'), null], 'Las contraseñas no coinciden.')
    });

    const Actualizar =({jsonPass})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarMiClave({jsonPass,jwt}).then(data=>{
            formik.setSubmitting(false)
            window.localStorage.setItem('reset',false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Contraseña actualizada exitosamente.", life: 7000})
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            passNew: "",
            passNewRepeat: "" 
        },
      validationSchema: schema,
      onSubmit: values => {
        
        let  password = values.passNewRepeat;
        let jsonPass = JSON.stringify({password},null,2)
         console.log(jsonPass)
        // //alert(jsonTema);
        Actualizar({jsonPass});
        
      },
    });

    return (
        <>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <Dialog visible={changePasswordDialog} modal className={clasesnameDialog} 
            // onHide={hideDialog} 
            showHeader={false}>
            {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}

            <div className="peva-label-header" style={{ marginTop: "24px" }}>
                <label htmlFor="changePass">Cambia tu contraseña</label>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="peva-form">
                    <p>Recuerda que debe tener 8 caracteres como mínimo. Debe contar con al menos una mayúscula, una minúscula y un número.</p>
                    <label htmlFor="passNew" className="peva-label">
                        Nueva contraseña
                    </label>
                    <div className="peva-input-icon">
                        <input
                            id="passNew"
                            type={passwordTypeNew}
                            className="peva-input"
                            placeholder="Escribe aquí"
                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                            name="passNew"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <div className="peva-icon-rigth">
                            {passwordTypeNew === "password" ? (
                                <i onClick={togglePasswordNew}>
                                    <Iconsax.EyeSlash />
                                </i>
                            ) : (
                                <i onClick={togglePasswordNew}>
                                    <Iconsax.Eye />
                                </i>
                            )}
                        </div>
                    </div>

                    <p className="error">{formik.errors.passNew && formik.touched.passNew && formik.errors.passNew}</p>

                    <label htmlFor="passNewRepeat" className="peva-label">
                        Confirma tu nueva contraseña
                    </label>
                    <div className="peva-input-icon">
                        <input
                            id="passNewRepeat"
                            type={passwordTypeRepeat}
                            className="peva-input"
                            placeholder="Escribe aquí"
                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                            name="passNewRepeat"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <div className="peva-icon-rigth">
                            {passwordTypeRepeat === "password" ? (
                                <i onClick={togglePasswordRepeat}>
                                    <Iconsax.EyeSlash />
                                </i>
                            ) : (
                                <i onClick={togglePasswordRepeat}>
                                    <Iconsax.Eye />
                                </i>
                            )}
                        </div>
                    </div>

                    <p className="error">{formik.errors.passNewRepeat && formik.touched.passNewRepeat && formik.errors.passNewRepeat}</p>
                    
                    <div>
                    <Boton nombre="Guarda contraseña" widths="401px" margin={0} color="secondary"type="submit" loading={formik.isSubmitting} />
                    </div>
                    
                    
                </div>
            </form>
            </Dialog>
        </>
        
    );
};
// const comparisonFn = function (prevProps, nextProps) {
//     return prevProps.location.pathname === nextProps.location.pathname;
// };

// export default React.memo(ChangePassword, comparisonFn);
//export default ChangePassword;
