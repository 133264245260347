import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { Document,Page } from 'react-pdf';
import { useParams } from "react-router-dom";
import { URLBLOB_ADJ_POLITICA } from "../../constants/constantes";
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
const PdfViewer = (props) => {
    const [numPage, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    let { nombrePdf } = useParams();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    return (
        <>
            {nombrePdf ? (
                // <DocumentViewer nombrePdf={nombrePdf}>

                // </DocumentViewer>
                <div className="modal-body" style={{height:"100vh"}}>
                    <object
                        type="application/pdf"
                        // type="application/octet-stream"
                        // src="https://upch-segen-documentos.s3.amazonaws.com/NORMATIVA/REGLAMENTO+DEL+PROCESO+DE+ADMISION+AL+PREGRADO+A+LA+UPCH/RE-102-UPCH_V.02.00_2022.04.06.pdf#zoom=100"
                        // src="chrome-extension://mhjfbmdgcfjbbpaeojofohoefgiehjai/9c5a68ef-2702-475e-a6ee-bc9ef235adbd"
                        data={URLBLOB_ADJ_POLITICA+"/"+nombrePdf}
                        //src="../PlandetrabajoEva.pdf"
                        //data={URLBLOB_ADJ_POLITICA+"/"+nombrePdf}
                      
                        frameBorder={0}
                        width="100%"
                        height="100%"
                    />
             

                 {/* <iframe src={"https://docs.google.com/viewerng/viewer?url="+URLBLOB_ADJ_POLITICA+"/"+nombrePdf+"&embedded=true"}
                 width="100%"
                 height="100%"
                 ></iframe> */}

                    {/* <embed 
                        type="application/x-google-chrome-pdf" 
                        src="chrome-extension://mhjfbmdgcfjbbpaeojofohoefgiehjai/9c5a68ef-2702-475e-a6ee-bc9ef235adbd" 
                        original-url="https://grinlearningqa9414.blob.core.windows.net/adjuntos/Reglamento/Plan%20de%20trabajo%20Eva.pdf" 
                        background-color="4283586137" 
                        javascript="allow" full-frame/> */}
                </div>
            ) : (
                "..."
            )}
        </>
    );
};
export default PdfViewer;
