import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
const ENDPOINT = constantes.URLAPI_MAESTROS;

export  const  ListarGerencias= async (jwt) =>{
    return await fetch(`${ENDPOINT}/Gerencia/Listar/ `,{
    //return fetch("assets/demo/data/users-gerencia.json",{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export  const  ListarAreas= async (jwt) =>{
    return await fetch(`${ENDPOINT}/Gerencia/ListarAreas/ `,{
    //return fetch("assets/demo/data/users-gerencia.json",{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ObtenerGerenciaPorId =async ({Id,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerGerenciaPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Gerencia/ObtenerGerenciaPorId/${Id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
