import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import "./PreviewMedia.scss"
import * as Iconsax from "iconsax-react";

const PreviewMedia = ({src,deleteImg}) => {
    return ( 
        <div className='p-eva-previeMedia-container' style={{ textAlign: "center", display: "flex", flexWrap: "wrap" }}>
            {src.map((imageSrc, index) => (
                <div key={index} style={{ width: "50%", position: "relative", boxSizing: "border-box", padding: "5px" }}>
                    <img src={imageSrc} alt="" style={{ borderRadius: 16, width: "100%", marginBottom: 10 }} />
                    <span className='close-icon-img' onClick={() => deleteImg(index)}><Iconsax.CloseCircle /></span>

                </div>
            ))} 
        </div> 
        
        // <div className='p-eva-previeMedia-container' style={{ textAlign: "center" }}>
        //         {src.map((imageSrc, index) => (
        //         <div style={{ width: "100%", position: "relative" }}>
        //            <img key={index} src={imageSrc} alt="" style={{ borderRadius: 16 }} />
        //         <span className='close-icon-img' onClick={deleteImg}><Iconsax.CloseCircle></Iconsax.CloseCircle></span>
        //         </div>
        //         ))}
                
            
        // </div>
    
    


    //     <div className='p-eva-previeMedia-container' style={{textAlign:"center"}}>
    //         <div style={{width:"100%",position:"relative"}}>
    //             <img src={src}  style={{borderRadius:16}}>
    //             </img>
    //             <span className='close-icon-img' onClick={deleteImg}><Iconsax.CloseCircle></Iconsax.CloseCircle></span>
    //         </div>
            
    //  </div>


        // <ReactTinyLink
        //     cardSize="large"
        //     showGraphic={true}
        //     maxLine={2}
        //     minLine={1}
        //     url={dataUrl}
            
        // />

       
        
     );
}
 
export default PreviewMedia;