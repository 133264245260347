import React, { useState, useEffect, useRef } from 'react';
import "./ModalEva.scss";
import {Dialog} from "primereact/dialog"
import Boton from "../Boton/Boton";
import "./ModalEva.scss"

const ModalEva = ({mostrar,onHide,footer,contenido,widths,height,titulo, posicionTitulo = 'left',iconoBack,nombreEtiqueta,tamañoTitulo}) => {
    const [mostrarModal, setMostrarModal] = useState(false);
    useEffect(()=>{
        setMostrarModal(mostrar)
    },[])

const header = 
        <div className='p-modalEva-header' style={{textAlign:posicionTitulo}}>
            <div className='modalEva-header-titulo'>
                <div className='modalEva-header-back' style={{marginRight:18}}>
                    {iconoBack}
                </div>
                <div><label className={'header-general '+tamañoTitulo} >{titulo}</label></div>
            </div>
            
            <div className='modalEva-header-etiqueta'>
               {nombreEtiqueta && <div className='modalEva-header-etiqueta-content text-sm'><span>{nombreEtiqueta}</span></div>} 
            </div>
        </div>
        ;

   
    return ( 
       <Dialog  className='p-modalEva' header={header} visible={mostrarModal} showHeader={true} onHide={onHide} footer={footer}
                breakpoints={{'960px': '75vw', '640px': '90vw'}} style={{width: widths}}>
            {contenido}
       </Dialog>
     );
}
 
export default ModalEva;