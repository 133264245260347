import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
const ENDPOINTTEST = constantes.URL_CAPACITACIONTEST;
const ENDPOINT = constantes.URLAPI_MAESTROS;

export const ListarSeccionCapacitacion =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ListarSeccionCapacitacion`,{
    return fetch(`${ENDPOINT}/Capacitacion/ListarSeccionCapacitacion`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCapacitacionIntro =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionIntro`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionIntro`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCapaIntro= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarCapacitacionIntro`,{
    return fetch(`${ENDPOINT}/Capacitacion/ActualizarCapacitacionIntro`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCapacitacionMia =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionMia`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionMia`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCapacitacionMia= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarCapacitacionMia`,{
    return fetch(`${ENDPOINT}/Capacitacion/ActualizarCapacitacionMia`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ObtenerCapacitacionOpcional =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionOpcional`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionOpcional`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCapacitacionOpcional= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarCapacitacionOpcional`,{
    return fetch(`${ENDPOINT}/Capacitacion/ActualizarCapacitacionOpcional`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarDescripciónCapacitacioOpcional= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarDescripciónCapacitacioOpcional`,{
    return fetch(`${ENDPOINT}/Capacitacion/ActualizarDescripciónCapacitacioOpcional`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarCapacitacionOpcional= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarCapacitacionOpcional`,{
    return fetch(`${ENDPOINT}/Capacitacion/RegistrarCapacitacionOpcional`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCapacitacionOpcionalPorId =async ({jwt,Id}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionOpcionalPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionOpcionalPorId/${Id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCapacitacionLiderazgo =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionLiderazgo`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionLiderazgo`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCapacitacionLiderazgo= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarCapacitacionLiderazgo`,{
    return fetch(`${ENDPOINT}/Capacitacion/ActualizarCapacitacionLiderazgo`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarCapacitacionLiderazgo= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarCapacitacionLiderazgo`,{
    return fetch(`${ENDPOINT}/Capacitacion/RegistrarCapacitacionLiderazgo`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCapacitacionLiderazgoPorId =async ({jwt,Id}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionLiderazgoPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionLiderazgoPorId/${Id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ObtenerCategoriaCapacitacion =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCategoriaCapacitacion`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCategoriaCapacitacion`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCapacitacionObligatoriaPorId =async ({jwt,Id}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionObligatoriaPorId/${Id}`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionObligatoriaPorId/${Id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCapacitacionPorGerencia =async ({jwt,Id}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCapacitacionPorGerencia/${Id}`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerCapacitacionPorGerencia/${Id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ObtenerListaCapacitacionesObligatoriasUsuario =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerListaCapacitacionesObligatoriasUsuario`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerListaCapacitacionesObligatoriasUsuario`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}



export const ObtenerListaCapacitacionesOblgatoriasAdmin =async ({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerListaCapacitacionesOblgatoriasAdmin`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerListaCapacitacionesOblgatoriasAdmin`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarCapacitacionObligatoria= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarCapacitacionObligatoria`,{
    return fetch(`${ENDPOINT}/Capacitacion/RegistrarCapacitacionObligatoria`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCapacitacionObligatoria= ({jsonCapacitacion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarCapacitacionObligatoria`,{
    return fetch(`${ENDPOINT}/Capacitacion/ActualizarCapacitacionObligatoria`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonCapacitacion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarRespuestasUsuario= ({jsonRespuestas,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarRespuestasUsuario`,{
    return fetch(`${ENDPOINT}/Capacitacion/RegistrarRespuestasUsuario`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonRespuestas
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerReporte =async ({jwt,IdCapa}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerReporte/${IdCapa}`,{
    return fetch(`${ENDPOINT}/Capacitacion/ObtenerReporte/${IdCapa}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}