import React, { useState ,forwardRef} from 'react';
import { Calendar } from 'primereact/calendar';
import "./CalendarDefault.scss"
import classNames from "classnames";
import { addLocale } from 'primereact/api';
const CalendarDefault = forwardRef((props,ref) =>{

    addLocale('pe', {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });
    const dropdownClassName = classNames("p-eva-calendarDefault", {
        "whiteCalendar":props.classesname === "white" ? true:false,
        "greyCalendar" : props.classesname === "grey" ? true:false,
        "p-eva-calendarDefault-error" : props.errorClass === "error" ? true:false,
        "p-eva-calendarDefault-success" : props.errorClass === "success" ? true:false,
    });
    return ( 
        <Calendar ref={ref}  {...props} className={dropdownClassName} locale='pe'></Calendar>
     );
})
 
export default CalendarDefault;