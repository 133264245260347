
import React, { useState, useEffect ,useRef} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import {ListarHistorias} from "../../../service/DesarrolloService";
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";
import "./Historias.scss";
import * as Iconsax from "iconsax-react";
import * as constantes from "../../../constants/constantes";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import Boton from "../../../components/Boton/Boton";
import { EliminarHistoria} from "../../../service/DesarrolloService";
import ToastEva from "../../../components/Toast/ToastEva";
import PreviewMedia from "../../../components/PreviewMedia/PreviewMedia";
import ReactPlayer from "react-player";

const Historias = () => {
    const navigate = useNavigate();
    const { permisos,isLogged,perfil,configuraciones} = useUsuario();
    const [listaHistorias, setListaHistorias] = useState(null);
    const [idHistoriaSeleccionada, setIdHistoriaSeleccionada] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalVideo, setVisibleModalVideo] = useState(false);
    const [urlSeleccionado, setUrlSeleccionado] = useState(null);
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const menu = useRef(null);
    const toast = useRef(null);

    let itemsEditar = [
        {label: 'Editar historias de éxito', icon: <Iconsax.Edit2 style={{marginRight:8}} color="#F47647"/>,
        command: () => {
            navigate("EditarHistoria/"+idHistoriaSeleccionada)
        }},
        {label: 'Eliminar historias de éxito', icon: <Iconsax.Trash style={{marginRight:8}} color="#F47647"/>,
            command:()=>{
                setShowDialog(true)
            }
        }
    ];

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    const ObtenerHistoria = async ()=>
    {
        let jwt = window.localStorage.getItem("jwt");
        await ListarHistorias({jwt}).then(data => 
            {
                setListaHistorias(data);
            })
    }

    useEffect(()=>{
        

        if(!listaHistorias)
        {
            ObtenerHistoria();
        }
     },[]);

     const header =(item)=> 
        <div >
            {
                item.nombreFoto && 
                <img src={constantes.URLBLOB_HISTORIA+"/"+item.nombreFoto} style={{borderTopLeftRadius:16,borderTopRightRadius:16,cursor:"pointer"}} 
                    onClick={()=>(setImagenSeleccionada(constantes.URLBLOB_HISTORIA+"/"+item.nombreFoto),setVisibleModal(true))}></img>
            }
            {
                item.linkVideo &&
                <img src="../../../../../images/inicio/default_video.png" style={{borderTopLeftRadius:16,borderTopRightRadius:16,cursor:"pointer"}}
                    onClick={()=>(setUrlSeleccionado(item.linkVideo),setVisibleModalVideo(true))}></img>
            }
            
        </div>
    const Eliminar=()=>{
        let id= idHistoriaSeleccionada
        let jwt = window.localStorage.getItem("jwt");
        EliminarHistoria({id,jwt}).then(data=>
        {
            toast.current.show({severity:'success', summary: 'Success', detail:"Historia eliminada exitosamente.", life: 7000})
            setListaHistorias(null)
            setShowDialog(false)
            ObtenerHistoria()

        })
    }
    const footerDelete = 
                <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialog(false)}></Boton>
                    <Boton nombre={"Eliminar"} widths="50%" color="primary" metodoClick={Eliminar}></Boton>
                </div>;
    return ( 
        <div className="eva-dashboard-Historias" >
            <ToastEva ref={toast} position="top-center"></ToastEva>

            <div className="eva-dashboard-Historias-body" >
                <div className="p-fluid formgrid grid">
                {
                    listaHistorias ?
                        listaHistorias.length > 0 ?
                            listaHistorias.map((item,i)=>{
                                return(
                                <div className="col-12 md:col-4 card-historia-item" key={i}  >
                                    <Card className="p-eva-card-historia"
                                        header={()=>header(item)} 
                                    >
                                        
                                        <div className="card-historia-content" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}} >
                                            <span className="historia-content-titulo">{item.nombreColaborador}</span>
                                            
                                            <Menu model={itemsEditar} popup ref={menu} id="popup_menu" style={{width:255}}/>
                                            <div style={{display:"flex"}}  onClick={(event) => {setIdHistoriaSeleccionada(item.id);menu.current.toggle(event)}}  aria-controls="popup_menu" aria-haspopup>
                                                
                                                <Iconsax.More color="#F47647" style={{cursor:"pointer"}}
                                               >
                                                </Iconsax.More>
                                            </div>
                                        </div>
                                        <div className="card-historia-content-descripcion">
                                            {item.descripcion}
                                        </div>
                                        
                                    </Card>
                                </div>
                                    
                                )
                            })
                        :
                        "No existe data"
                    :
                    <Skeleton></Skeleton>
                }
                </div>
            </div>
            <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                footer={footerDelete}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar historia?</label>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>

            {
                 visibleModal&&
                 <PreviewMedia visibleModal={visibleModal} urlFile={imagenSeleccionada} 
                    onHide={()=>setVisibleModal(false)} height={60}></PreviewMedia>
            }

            {
                visibleModalVideo && 
                <Dialog visible={visibleModalVideo} footer={<></>} onHide ={()=>setVisibleModalVideo(false)}
                className="p-eva-videoplayer" >
                    <ReactPlayer url={urlSeleccionado} ></ReactPlayer>
                </Dialog>
                
            }
        </div>
        );
}
 
export default Historias;