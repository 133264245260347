import React, { useState, useEffect ,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
//import CardGerencia from "../../../components/Gerencia/CardGerencia";
import SearchCard from "../../../components/Directorio/SearchCard";
//import usePermisos from "../../../hooks/usePermisos";
import { ListUsersGerencia, ListUsersTag } from "../../../service/UsuariosService";
import { ObtenerDirectorioPorGerencia, ObtenerGerenciaySubGerencias ,ObtenerSubGerenciayAreas} from "../../../service/SomosinService";
//import ContactCard from "../../../components/ContactCard/ContactCard";
//import Sk_Directorio from "../../../components/Skeleton/Sk_Directorio";
import "../../../components/ContactCard/ContactCard.scss"
import "./Directorio.scss";
//import { Skeleton } from "primereact/skeleton";
import useUsuario from "../../../hooks/useUsuario";
//import { RegistrarAcceso } from "../../../service/InteraccionService";
import ContactCardV2 from "../../../components/ContactCard/ContactCardV2";
import * as Iconsax from "iconsax-react";
const Directorio = () => {
    const [usersGerencia, setUsersGerencia] = useState([]);
    const [selected, setSelected] = useState([]);
    const [listUserTotal, setListUserTotal] = useState([]);
    const [vistaGerencia, setVistaGerencia] = useState(true);
    const [directorioUsuario, setDirectorioUsuario] = useState(null);
    const navigate = useNavigate();
    const { permisos, isLogged } = useUsuario()
    const [GerenciaySubGerencias, setGerenciaySubGerencias] = useState(null);
    const [LiderDirecto, setLiderDirecto] = useState(null);
    const targetRef = useRef(null); 
    const [scrollPosition, setScrollPosition] = useState(0);
    const [ActiveScroll,setActiveScroll] = useState(0);
    //ListUsersGerencia(window.localStorage.getItem("jwt")).then((data) => setUsersGerencia(data));

    useEffect(() => {
        !isLogged && navigate("/");
    }, [])

    useEffect(()=>{
        const targetPosition = targetRef.current?.offsetTop;
        console.log(targetPosition);
        console.log("LiderDirecto",LiderDirecto)
        console.log("GerenciaySubGerencias",GerenciaySubGerencias)
            if(ActiveScroll != 0 ){
                console.log("InicioScroll")
                    window.scrollTo({
                    top: targetPosition + 240,
                    behavior: 'smooth'
                    });
                    setScrollPosition(targetPosition);
                }
    },[ActiveScroll])

    // useEffect(()=>{
    //     const GuardarAcceso = async () => {
    //         let jwt = window.localStorage.getItem("jwt");
    //         let seccion = "SomosIn/Directorio"
    //         let url = window.location.href;
    //         let jsonAcceso = JSON.stringify({seccion,url},null,2)
    //         await RegistrarAcceso({jsonAcceso,jwt});
    //     };

    //     GuardarAcceso();
    // },[])

   /*  useEffect(() => {
        console.log("scrollPosition",scrollPosition);
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }, [scrollPosition]);

      useEffect(() => {
        const buttonTop = buttonRef.current.getBoundingClientRect().top;
        setScrollPosition(buttonTop);
      }, []); */
    useEffect(() => {
        const FnObtenerGerenciaySubGerencias = async () => {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerGerenciaySubGerencias({ jwt }).then(
                (data) => {
                    console.log("setGerenciaySubGerencias",data);
                    setGerenciaySubGerencias(data);
                    setLiderDirecto(null);
                })
        }
        FnObtenerGerenciaySubGerencias();
    }, []);

    useEffect(() => {
        if (permisos.length > 0) {
            !(permisos.indexOf("verDirectorio") > -1) && navigate("/")
        }
    }, [permisos])


    useEffect(() => {
        const obtenerUsuariosGerencia = async () => {
            await ListUsersGerencia(window.localStorage.getItem("jwt")).then(data => setUsersGerencia(data));
        }
        obtenerUsuariosGerencia();
    }, []);

    useEffect(() => {
        const obtenerUsuariosTotal = async () => {
            let jwt = window.localStorage.getItem("jwt");
            await ListUsersTag(jwt).then((data) => {
                const newData = data.map(item => ({
                    label: `${item.label} - ${item.documento}`,
                    value: item.value,
                    foto: item.foto,
                    id: item.id,
                    codigoColaborador: item.codigoColaborador,
                    documento: item.documento
                  }));
                setListUserTotal(newData)
            })
        }
        if (listUserTotal.length === 0) {
            obtenerUsuariosTotal();
        }
    }, []);

    const handleSelectChange = (values) => {
        console.log(values);
        /* const ObtenerDirectorioUsuario = async (idUsuario) => {
            // let jwt = window.localStorage.getItem("jwt");
            // let idGerencia = 0;
            // await ObtenerDirectorioPorGerencia({jwt,idGerencia,idUsuario}).then((data) => setDirectorioUsuario(data));
            navigate("/Dashboard/SomosIn/Directorio/Usuario/" + idUsuario)
        }
        setSelected(values);
        setVistaGerencia(false);
        ObtenerDirectorioUsuario(values.id) */
        Actualizar(values.codigoColaborador);
    };

    const Actualizar = (codigoTrabajador) => {
        let jwt = window.localStorage.getItem("jwt");
        setActiveScroll(0)
        if (codigoTrabajador != 0) {
            setLiderDirecto(null);
            ObtenerSubGerenciayAreas({ codigoTrabajador, jwt }).then(
                (data) => {
                    setGerenciaySubGerencias(data);
                    if (data?.jefeResPonsable !== undefined && data?.jefeResPonsable !== "0") {
                        ObtenerSubGerenciayAreas({ codigoTrabajador: data.jefeResPonsable, jwt }).then((data2) => {
                            setLiderDirecto(data2);
                            setActiveScroll(1)
                        })
                    }
                })
        }
    }

    return (
        <div className="eva-dashboard-directorio">
            <div className="eva-dashboard-header">
                <div className="header">
                    <label htmlFor="header">Directorio In</label>
                </div>
            </div>

            <div className="eva-dashboard-directorio-body">
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-9">
                        <SearchCard onChange={handleSelectChange}
                            options={listUserTotal}
                            value={selected}
                            placeholder="Buscar por nombre o número de documento"
                        ></SearchCard>
                    </div>
                </div>
                <div ref={targetRef}></div>
                {LiderDirecto && GerenciaySubGerencias?.jefeResPonsable !== "0" ? (
                    <><div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4">
                            <div >
                                <i href="#" onClick={() => Actualizar(LiderDirecto?.codigoTrabajador)} className="p-eva-icon-atras">
                                    <Iconsax.ArrowLeft color="#000000" />
                                </i>
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 titulo-organigrama ">
                            <span>LÍDER DIRECTO</span>
                        </div>
                    </div><div className="p-fluid formgrid grid" style={{ marginTop: "3%" }}>
                            <div className="field col-12 md:col-4"></div>
                            <div className="field col-12 md:col-4 card-detalle-usuario" style={{ marginBottom: 60 }}>
                                <span >
                                    <ContactCardV2 foto={LiderDirecto && LiderDirecto?.foto}
                                        idUser={LiderDirecto && LiderDirecto?.id}
                                        nombreUser={LiderDirecto && LiderDirecto?.nombres}
                                        puesto={LiderDirecto && LiderDirecto?.puesto}
                                        email={LiderDirecto && LiderDirecto?.correo}
                                        gerencia={LiderDirecto && LiderDirecto?.gerencia}
                                        area={LiderDirecto && LiderDirecto?.area}
                                        fechaNacimiento={(LiderDirecto && LiderDirecto?.fechaNacimiento)}// ? new Date(LiderDirecto?.fechaNacimiento).toLocaleDateString('es-ES') : ''}
                                        celular={LiderDirecto && LiderDirecto?.celular}
                                        sombreado={false} />
                                </span>
                            </div>
                            <div className="field col-12 md:col-4"></div>
                        </div></>) :
                    (<></>)
                }

                <div className="p-fluid formgrid grid" style={{ marginTop: "3%" }}>
                    <div className="field col-12 md:col-4"></div>
                    <div className="field col-12 md:col-4 card-detalle-usuario" style={{ marginBottom: 60 }}>
                        <span >
                            <ContactCardV2 foto={GerenciaySubGerencias && GerenciaySubGerencias?.foto}
                                idUser={GerenciaySubGerencias && GerenciaySubGerencias?.id}
                                nombreUser={GerenciaySubGerencias && GerenciaySubGerencias?.nombres}
                                puesto={GerenciaySubGerencias && GerenciaySubGerencias?.puesto}
                                email={GerenciaySubGerencias && GerenciaySubGerencias?.correo}
                                gerencia={GerenciaySubGerencias && GerenciaySubGerencias?.gerencia}
                                area={GerenciaySubGerencias && GerenciaySubGerencias?.area}
                                fechaNacimiento={GerenciaySubGerencias && GerenciaySubGerencias?.fechaNacimiento}// new Date(GerenciaySubGerencias?.fechaNacimiento).toLocaleDateString('es-ES') : ''}
                                celular={GerenciaySubGerencias && GerenciaySubGerencias?.celular}
                                sombreado={GerenciaySubGerencias?.jefeResPonsable == "0" ? false : true}
                            //metodoClick={()=>{setIdUsuarioSeleccionado(usuario.idUsuario);setMostrarSlideUsuario(true)}}
                            />
                        </span>
                    </div>
                    <div className="field col-12 md:col-4"></div>
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-4 card-detalle-usuario"></div>
                    <div className="field col-12 md:col-4 titulo-organigrama ">
                        {LiderDirecto && GerenciaySubGerencias?.jefeResPonsable !== "0" ?
                            GerenciaySubGerencias && GerenciaySubGerencias?.subGerencias.length > 0 ?
                                (
                                    <span >REPORTES DIRECTOS</span>
                                ) : <></> : (<span>COMITÉ GERENCIAL</span>)}
                    </div>
                </div>


                {GerenciaySubGerencias && GerenciaySubGerencias?.subGerencias.length > 0 ?
                    (
                        <div className="p-fluid formgrid grid contenerdor-reporte-organigrama">
                            {
                                GerenciaySubGerencias?.subGerencias.map((item, i) => {
                                    return (
                                        GerenciaySubGerencias?.subGerencias.length % 3 == 0
                                            || (GerenciaySubGerencias?.subGerencias.length % 3 == 1 && GerenciaySubGerencias?.subGerencias.length - (i) > 1)
                                            || (GerenciaySubGerencias?.subGerencias.length % 3 == 2 && GerenciaySubGerencias?.subGerencias.length - (i) > 2) ?
                                            <div className="field col-12 md:col-4 card-detalle-usuario" style={{ marginBottom: 60 }}>
                                                <span onClick={() => Actualizar(item?.codigoTrabajador)}>
                                                    <ContactCardV2 foto={item?.foto}
                                                        idUser={item?.id}
                                                        nombreUser={item?.nombres}
                                                        puesto={item?.puesto}
                                                        email={item?.correo}
                                                        gerencia={item?.gerencia }
                                                        area={item?.area}
                                                        fechaNacimiento={item?.fechaNacimiento}
                                                        celular={item?.celular}
                                                        sombreado={false}
                                                    />
                                                </span>
                                            </div>
                                            :
                                            (GerenciaySubGerencias?.subGerencias.length == 2
                                                || (GerenciaySubGerencias?.subGerencias.length % 3 == 2 && GerenciaySubGerencias?.subGerencias.length - (i) <= 2)
                                            ) ?
                                                <div className="field col-12 md:col-6 card-detalle-usuario" style={{ marginBottom: 60 }}>
                                                    <span onClick={() => Actualizar(item?.codigoTrabajador)}>
                                                        <ContactCardV2 foto={item?.foto}
                                                            idUser={item?.id}
                                                            nombreUser={item?.nombres}
                                                            puesto={item?.puesto}
                                                            email={item?.correo}
                                                            gerencia={item?.gerencia }
                                                            area={GerenciaySubGerencias?.area }
                                                            fechaNacimiento={item?.fechaNacimiento}
                                                            celular={item?.celular}
                                                            sombreado={false}
                                                        />
                                                    </span>
                                                </div> :
                                                <div className="field col-12 md:col-12 card-detalle-usuario" style={{ marginBottom: 60 }}>
                                                    <span onClick={() => Actualizar(item?.codigoTrabajador)}>
                                                        <ContactCardV2 foto={item?.foto}
                                                            idUser={item?.codigoTrabajador}
                                                            nombreUser={item?.nombres}
                                                            puesto={item?.puesto }
                                                            email={item?.correo}
                                                            gerencia={item?.gerencia}
                                                            area={GerenciaySubGerencias?.area}
                                                            fechaNacimiento={item?.fechaNacimiento}
                                                            celular={item?.celular}
                                                            sombreado={false}
                                                        />
                                                    </span>
                                                </div>
                                    )
                                })
                            }
                        </div>
                    ) : <></>}
                {/* {vistaGerencia ?
                    <div className="p-fluid formgrid grid" >
                        {
                            usersGerencia.length > 0 ?
                                usersGerencia.map((item, i) => {
                                    return (
                                        <div className="field col-12 md:col-3" key={i}>
                                            <CardGerencia NombreGerencia={item.gerencia} colaboradores={item.users.length + " colaboradores"}
                                                onClicButton={() => navigate("/Dashboard/SomosIn/Directorio/Gerencia/" + item.idGerencia)}
                                            ></CardGerencia>
                                        </div>
                                    )

                                })
                                :
                                <Sk_Directorio />
                        }

                    </div>

                    :
                    directorioUsuario ?
                        directorioUsuario.listaAreas.map((area, i) => {
                            return (
                                <div className={"p-eva-seccion-" + i} key={i} style={{ marginTop: "40px" }}>
                                    <div className="p-fluid formgrid grid">
                                        {
                                            area.listaUsuarios.map((usuario, j) => {
                                                return (
                                                    <div className="field col-12 md:col-4" key={j}>
                                                        <ContactCard foto={usuario.nombreFoto}
                                                            idUser={usuario.idUsuario}
                                                            nombreUser={usuario.nombreUsuario}
                                                            puesto={usuario.puesto}
                                                            email={usuario.correo}
                                                            gerencia={directorioUsuario.nombreGerencia}
                                                            area={area.nombreArea}
                                                        />
                                                    </div>
                                                )

                                            })
                                        }

                                    </div>
                                </div>
                            )

                        })

                        : null
                } */}
            </div>
        </div>
    );
};
export default Directorio;
