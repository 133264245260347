import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Carousel.scss";
import { FaDownload } from "react-icons/fa"; // Importa el icono de descarga
import { FaEllipsisV } from "react-icons/fa";
import { Menu } from "primereact/menu";
import * as Iconsax from "iconsax-react";

const Carousel = ({ images}) => {
console.log("images[0]",images[0])
const height = 90
 const [selectedImage, setSelectedImage] = React.useState(null);

 const menu = useRef(null);
 const itemsEditar = [
    {
      label: <strong>Descargar</strong>,
      icon: <Iconsax.ImportCurve style={{ marginRight: 8 }} color="#F47647" />,
      command: () => handleDownload(selectedImage),
    },
 ];

 const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
 };

//  const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   centerMode: true,
//   centerPadding: '0px',
//   adaptiveHeight: true,
// };
const handleDownload = (imageUrl) => {
    const fileName = imageUrl.split('/').pop().split('.')[0];
    const fileExtension = imageUrl.split('.').pop();

    fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.${fileExtension}`;
            link.click();    
            window.URL.revokeObjectURL(link.href);
        })
        .catch(error => console.error('Error al descargar la imagen:', error));
};




 const renderCarousel = () => {
    if (images.length > 1) {
      return (
        // <Slider {...settings} className="custom-slider" style={{ height: 85 + "vh" ,width:"988px"}}>
        <Slider {...settings} className="custom-slider" style={{ height: "100%",width:"84%"}}>
          
          {images.map((image, index) => (
          <div  key={index} className="p-eva-cardPublicacion-img"  
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <Menu model={itemsEditar} popup ref={menu} id="popup_menu" style={{ width: 150, marginLeft:835}} />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end", 
                    marginBottom:"10px",
                    marginRight:"15px"
                  }}
                onClick={(event) => {
                    setSelectedImage(image);
                    menu.current.toggle(event);
                }}
                aria-controls="popup_menu"
                aria-haspopup
            >
                <span style={{ display: "flex", alignItems: "center" ,backgroundColor:"white",height: '13px'}}>
                    <Iconsax.More  color="#F47647"  size={24}></Iconsax.More>
                </span>
              </div>
              <img src={image} alt={`Image ${index}`} style={{ cursor: "pointer" }}></img>
            </div>
          ))}
        </Slider>
      );
    } else {
      return (
        <div className="p-eva-cardPublicacion-img2" style={{ alignItems: 'center', justifyContent: 'center', margin: '0% 7%' }} >
          <Menu model={itemsEditar} popup ref={menu} id="popup_menu" style={{ width: 150, marginLeft: 835 }} />
          <div style={{textAlign:"end", marginRight:"15px"}} onClick={(event) => {
            setSelectedImage(images[0]);
            menu.current.toggle(event);
          }}>
            <span>
              <Iconsax.More color="#F47647" size={24}></Iconsax.More>
            </span>
          </div>
          {/* <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom:"10px",
                    marginRight:"15px"
                  }}
                onClick={(event) => {
                    setSelectedImage(images[0]);
                    menu.current.toggle(event);
                }}
                aria-controls="popup_menu"
                aria-haspopup
            >
                <span style={{ display: "flex", alignItems: "center" ,backgroundColor:"white",height: '13px'}}>
                    <Iconsax.More  color="#F47647"  size={24}></Iconsax.More>
                </span>
            </div> */}
            <img src={images[0]} style={{cursor:"pointer"}}></img>
          </div>
        // <div  className="p-eva-cardPublicacion-img2" style={{ height: "100% ",width:"fit-content%", marginLeft:"75px",display:"flex" ,alignItems: "stretch", placeContent: "center", flexDirection: "column",justifyContent: "space-evenly" }}>
        //   <Menu model={itemsEditar} popup ref={menu} id="popup_menu" style={{ width: 150, marginLeft:835}} />
        //   <div
        //     style={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "flex-end",
        //       //marginBottom: "1px",
        //         marginTop:"5px",
        //         marginRight:"15px"

        //     }}
        //     onClick={(event) => {
        //       setSelectedImage(images[0]);
        //       menu.current.toggle(event);
        //     }}
        //     aria-controls="popup_menu"
        //     aria-haspopup
        //   >
        //     <span style={{ display: "flex", alignItems: "center", backgroundColor: "white", height: '13px' }}>
        //       <Iconsax.More color="#F47647" size={24}></Iconsax.More>
        //     </span>
        //   </div>
        //   <img  src={images[0]} style={{ cursor: "pointer", marginBottom: "20px", marginTop:"9px"}} alt="Imagen"></img>
        // </div>
        

           
      );
    }
 };



 return renderCarousel();
};

export default Carousel;


