import { Sidebar } from "primereact/sidebar";
import  {Avatar} from "primereact/avatar"
import classNames from "classnames";
import "./Notificaciones.scss"
import * as constantes from "../../constants/constantes.js"
import { ObtenerTiempoTranscurrido ,ObtenerMensajeNotificacion} from "../../helpers/helpers";
const Notificaciones = ({visible,onHide,lista = [],idLogin,onCLickNotificacion}) => {
    const handleClick =(idPublicacion,idGrupo,id,tipo)=>{
        onCLickNotificacion(idPublicacion,idGrupo,id,tipo)
    }
    return ( 
        <Sidebar className="p-eva-slideNotificaciones" visible={visible} onHide={onHide} position="right" >
                    <div className="header">
                        <label className="font-bold text-4xl">Notificaciones</label>
                    </div>
                    <div className="slideNotificaciones-contenido" >
                        {
                            lista.map((item,i)=>{
                                return(
                                    <div className="slideNotificaciones-item" style={{display:"flex",alignItems:"center",marginBottom:16}} key={i}>
                                            <Avatar  image={!item.fotoUsuarioOrigen  || item.fotoUsuarioOrigen == ""? constantes.URLAVATARDEFAULT: constantes.URLBLOB_ADJ_AVATAR+"/"+item.idUsuarioOrigen+"/"+item.fotoUsuarioOrigen }
                                                    shape="circle" size="large" style={{minWidth:42}}
                                            ></Avatar>
                                            <div className={classNames("slideNotificaciones-item-info", {"notificacion-pendiente": !item.estadoLeido})} 
                                                style={{padding:16,borderRadius:16,margin:8}} onClick={()=>handleClick(item.idPublicacion,item.idGrupo,item.id,item.tipoNotificacion)}>
                                                <div style={{display:"inline-flex",alignItems:"center",marginBottom:16}}>
                                                    <span className="titulo text-orange" style={{marginRight:8}}>{item.nombreUsuarioOrigen}</span>
                                                    <span className="p-eva-labelForm-grey">{ObtenerTiempoTranscurrido(item.fechaActual,item.fechaNotificacion)}</span>
                                                </div>
                                                <div>
                                                    <span>{ ObtenerMensajeNotificacion(item.tipoNotificacion,item.nombreUsuarioReferencia,idLogin === item.idUsuarioReferencia ? true:false,item.nombreGrupo)}</span>
                                                </div>
                                            </div>
                                    </div>
                                )
                            })
                        }
                        
                        
                    </div>
                </Sidebar>
    );
}
 
export default Notificaciones;