import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./PreviewCarousel.scss";
import { FaDownload } from "react-icons/fa"; // Importa el icono de descarga
import { FaEllipsisV } from "react-icons/fa";
import { Menu } from "primereact/menu";
import * as Iconsax from "iconsax-react";
import PreviewMediaImage from "../PreviewMedia/PreviewMediaImage";

const PreviewCarousel = ({ urlFile="",imagenes=[] }) => {
  const [visibleModal, setVisibleModal] = useState(false);

 const imagesCarrusel = imagenes.map(item => urlFile + item);

 const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
 };

 const renderCarousel = () => {
    if (imagesCarrusel.length > 1) {
      return (
        <Slider {...settings} className="custom-slider" >
          {imagesCarrusel.map((image, index) => (
            <div  key={index} className="p-eva-cardPublicacion-img" >
               <img src={image} alt={`Image ${index}`}  onClick={()=>setVisibleModal(true)} style={{cursor:"pointer"}}></img>
            </div>
          ))}
        </Slider>

      
      );
    } else {
      return (
        <div className="p-eva-cardPublicacion-img">
          <img src={imagesCarrusel[0]} onClick={()=>setVisibleModal(true)} style={{cursor:"pointer"}}></img>
        </div>
              );
    }
 };
 return ( 
  <>
    {renderCarousel()}
    {visibleModal&&
        <PreviewMediaImage visibleModal={visibleModal} urlFile={urlFile} imagenes={imagenes}
           onHide={()=>setVisibleModal(false)}>
        </PreviewMediaImage>
    }
  </>
 )

};

export default PreviewCarousel;


