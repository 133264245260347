import React, { useCallback, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useEffect } from "react";
import useUsuario from "./hooks/useUsuario";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import Boton from "./components/Boton/Boton";
import "./components/Inicio/Perfil.scss";
import "./components/Inicio/PerfilOptions.scss";
import "./components/Inicio/FormsEva.scss";
import { Sidebar } from "primereact/sidebar";
import { Formik } from "formik";
import * as Iconsax from "iconsax-react";
import * as Yup from "yup";
import usePassword from "./hooks/usePassword";
import useperfil from "./hooks/usePerfil";
import ChangePassword from "./components/Login/ChangePassword";
import * as constantes from "./constants/constantes.js";
import Avatar from "react-avatar-edit";
import { Skeleton } from "primereact/skeleton";
import * as signalR from "@microsoft/signalr";
import axios from "axios";
import { Badge } from "primereact/badge";
import { ListarNotificaciones } from "./service/PublicacionService";
import Notificaciones from "./components/Inicio/Notificaciones";
import { ActualizarNotificacion } from "./service/PublicacionService";
import { ObtenerFechaFormatoPalabraMes, ObtenerMensajeNotificacion, ObtenerTiempoTranscurrido } from "./helpers/helpers";
import { ActualizarAlertas } from "./service/UsuariosService";
import { ObtenerReconocimientoUsuario } from "./service/UsuariosService";

// import {getAuth, signInAnonymously} from "firebase/auth";
// import {getToken, onMessage} from "firebase/messaging";
// import {messaging} from "./firebase";
// import { EnviarNotificacionPush } from "./service/UsuariosService";

//window.localStorage.getItem("jwt")
import DialogChangePassword from "./components/Login/ChangePassword";
export const AppTopbar = (props) => {
    const [conexionSignal, setConexionSignal] = useState(null);
    const { logout, isLogged, perfil } = useUsuario();
    const [listaNotificaciones, setListaNotificaciones] = useState([]);
    const [idLogin, setIdLogin] = useState(0);
    const [numeroNotificacionesNuevas, setNumeroNotificacionesNuevas] = useState(null);
    const [reconocimiento, setReconocimiento] = useState(null);
    const ActualizarNumeroAlertas = (tipo) => {
        let jwt = window.localStorage.getItem("jwt");
        let idUsuario = perfil.idUsuario;
        let agregar = tipo;
        ActualizarAlertas({ idUsuario, agregar, jwt }).then((data) => {
            setNumeroNotificacionesNuevas(data.numeroAlertas);
        });
    };
    const [mostrarPopUpMensaje, setMostrarPopUpMensaje] = useState(false);
    
    useEffect(() => {
        const obteneReconocimiento = async () => {
            //await GetGrupo({idGerencia});
            let jwt = window.localStorage.getItem("jwt");
            let id = perfil.idUsuario;
            await ObtenerReconocimientoUsuario({ id, jwt }).then((data) => setReconocimiento(data));
        };
        if (perfil) obteneReconocimiento();
    }, [perfil]);

    // const activarMensajes = async () => {
    //     const token = await getToken(messaging, {
    //         vapidKey: "BBkJ-MYT_litEUhLRWK8XSfezgoSY4ZRiSLdsS57qQ6cVi0wxMhpAHWnzUCb11onosr6CAU1fl_3C62eaVTcfo4",
    //     }).catch((error) => console.log("Tuviste un error al generar el token, papá"));

    //     if (token) console.log("tu token:", token);
    //     if (!token) console.log("no tienes token, rey");
    // };

    // const loguearse = () => {
    //     signInAnonymously(getAuth()).then((usuario) =>
    //     {
    //         console.log(usuario)
    //         activarMensajes()
    //     });
    // };

    // useEffect(()=>{
    //     loguearse()

    // },[])

    // useEffect(() => {
    //     onMessage(messaging, (message) => {
    //         console.log("tu mensaje:", message);

    //         var options = {
    //             body: message,
    //             icon: "/images/inicio/logo-notificaciones.png",

    //         };
    //         var noti = new Notification("Tienes una notificacion",options)
    //     });
    // }, []);

    useEffect(() => {
        if (perfil) {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(constantes.URL_NOTIFICACION, {
                    headers: { "x-ms-client-principal-id": perfil.idUsuario, "x-ms-token-aad-access-token": window.localStorage.getItem("jwt") },
                })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            setNumeroNotificacionesNuevas(perfil.numeroAlertas ? perfil.numeroAlertas : 0);
            setConexionSignal(connection);
            setIdLogin(perfil.idUsuario);
        }
    }, [perfil]);
    useEffect(() => {
        if (conexionSignal) {
            conexionSignal
                .start()
                .then((result) => {
                    console.log("Connected!");
                    addGroup();
                    conexionSignal.on("mensaje_nuevo", (message) => {
                        //alert(message.Text)

                        let jwt = window.localStorage.getItem("jwt");
                        ListarNotificaciones({ jwt }).then((data) => {
                            setListaNotificaciones(data);

                            let info = data[0];
                            let tiempoTranscurrido = ObtenerTiempoTranscurrido(info.fechaNotificacion, info.fechaActual);

                            // console.log(data[0])
                            if (tiempoTranscurrido === "Ahora") {
                                ActualizarNumeroAlertas(true);
                                // var options = {
                                //     body: (info.tipoNotificacion === "APROBACION" ? "" : info.nombreUsuarioOrigen) + " " + ObtenerMensajeNotificacion(info.tipoNotificacion, info.nombreUsuarioReferencia, idLogin === info.idUsuarioReferencia ? true : false, info.nombreGrupo),

                                //     icon: "/images/inicio/logo-notificaciones.png",
                                // };
                                // if (!("Notification" in window)) {
                                //     console.log("Este navegador no es compatible con las notificaciones de escritorio");
                                // }

                                // // Comprobamos si los permisos han sido concedidos anteriormente
                                // else if (Notification.permission === "granted") {
                                //     // Si es correcto, lanzamos una notificación
                                //     // var notification = new Notification("Hola!");
                                //     //new Notification("Tienes una notificacion",options)
                                //     var noti = new Notification("Tienes una notificacion", options);
                                //     noti.onclick = function () {
                                //         handleClickNotificacion(info.idPublicacion, info.idGrupo, info.id);
                                //         noti.onclose(window.focus());
                                //     };
                                // }

                                // // Si no, pedimos permiso para la notificación
                                // else if (Notification.permission !== "denied" || Notification.permission === "default") {
                                //     Notification.requestPermission(function (permission) {
                                //         // Si el usuario nos lo concede, creamos la notificación
                                //         if (permission === "granted") {
                                //             var noti = new Notification("Tienes una notificacion", options);
                                //             noti.onclick = function () {
                                //                 handleClickNotificacion(info.idPublicacion, info.idGrupo, info.id);
                                //             };
                                //         }
                                //     });
                                // }
                            }
                        });

                        console.log("llega mensaje");
                        //handleNotificaciones();
                    });
                })
                .catch((e) => console.log("Connection failed: ", e));
        }
    }, [conexionSignal]);

    const sendMessage = async () => {
        return axios
            .post(constantes.URL_NOTIFICACION + "/messageGroup", {
                GroupName: "Administrador General - Plataforma",
                //GroupName: "InLearning",
                ConnectionId: "1",
                Sender: "Kevyn",
                Text: "mensaje enviado por kevyn",
            })
            .then((resp) => resp.data);
    };

    const addGroup = async () => {
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("jwt")}` },
        };
        return axios
            .post(
                constantes.URL_NOTIFICACION + "/addToGroup",
                {
                    nombreGrupo: "Admin",
                },
                config
            )
            .then((resp) => resp.data);
    };

    const [active, setActive] = useState(false);
    const [activePrincipal, setActivePrincipal] = useState(true);
    const [activePerfilDetalle, setActivePerfilDetalle] = useState(false);
    const [activeChangePass, setActiveChangePass] = useState(false);
    const [activeReconocimientos, setActiveReconocimientos] = useState(false);
    const [toggleState, setToggleState] = useState(1);

    const [passwordTypeCurrent, setPasswordTypeCurrent] = useState("password");
    const [passwordTypeNew, setPasswordTypeNew] = useState("password");
    const [passwordTypeRepeat, setPasswordTypeRepeat] = useState("password");
    // const { isChange, changePass } = usePassword();
    const { isUpdate, updatePerfil, perfilUpdate, updatePerfilImage, isUpdateImage } = useperfil();

    const [visibleEditImg, setVisibleEditImg] = useState(false);
    const [srcImagenPerfil, setsrcImagenPerfil] = useState(null);
    const [disabledSaveImage, setdisabledSaveImage] = useState(true);
    const [imagenPerfilGuardar, setImagenPerfilGuardar] = useState(null);
    const [nombreImagenPerfilGuardar, setNombreImagenPerfilGuardar] = useState(null);

    const [activarNotificacion, setActivarNotificacion] = useState(false);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (isChange || isUpdate) {

    //         navigate("/Dashboard/Home")

    //         setActive(false);
    //     }
    // }, [isChange]);

    useEffect(() => {
        if (isUpdateImage) {
            setdisabledSaveImage(true);
            window.location.reload();
        }
    }, [isUpdateImage]);
    const togglePasswordCurrent = () => {
        if (passwordTypeCurrent === "password") {
            setPasswordTypeCurrent("text");
            return;
        }
        setPasswordTypeCurrent("password");
    };

    const togglePasswordNew = () => {
        if (passwordTypeNew === "password") {
            setPasswordTypeNew("text");
            return;
        }
        setPasswordTypeNew("password");
    };

    const togglePasswordRepeat = () => {
        if (passwordTypeRepeat === "password") {
            setPasswordTypeRepeat("text");
            return;
        }
        setPasswordTypeRepeat("password");
    };

    const SendMessageFirebase = () => {
        let jwt = window.localStorage.getItem("jwt");
        //EnviarNotificacionPush({jwt})
    };

    const toggleTab = (index) => {
        setToggleState(index);
    };
    const hidePerfil = useCallback(
        (event) => {
            setActive(false);
            // unbindOutsideClickListener();
            // event.preventDefault();
        } //, [unbindOutsideClickListener]
    );

    const togglePerfil = (event) => {
        //setActive((prevState) => !prevState);
        setActive(true);
        setActivePrincipal(true);
        setActivePerfilDetalle(false);
        setActiveChangePass(false);
        setActiveReconocimientos(false);
    };
    const showPerfilDetail = (event) => {
        setActivePrincipal(false);
        setActivePerfilDetalle(true);
        setActiveChangePass(false);
        //SendMessageFirebase();
        //window.location.reload();
        // const messageText = prompt('Send private message to ' + 637);

        //     if (messageText) {
        //       conexionSignal.send("SendToUser", "1", "MENSAJE DE KEVYN");
        //     }
    };
    const showPerfilReconocimiento = () => {
        setActivePrincipal(false);
        setActiveChangePass(false);
        setActiveReconocimientos(true);
    };

    const showPerfilChangePass = (event) => {
        setActivePrincipal(false);
        setActivePerfilDetalle(false);
        setActiveChangePass(true);
    };
    const perfilClassName = classNames("layout-perfil", {
        "layout-perfil-active": active,
        "p-perfilOptions-close": activePerfilDetalle || activeChangePass || activeReconocimientos,
    });
    const clasnameBack = classNames("p-sidebar-header", {
        "p-sidebar-header-back-close": activePrincipal,
        "p-sidebar-header-back": !activePrincipal,
    });

    useEffect(() => {
        // perfil === undefined|| perfil.foto === null || perfil.foto === "" ? setsrcImagenPerfil(constantes.URLAVATARDEFAULT)  :setsrcImagenPerfil(constantes.URLAPI+"/Image/perfil/"+perfil.foto+"/"+perfil.idUsuario)
        if (perfil) perfil.foto === "" || perfil.foto === null ? setsrcImagenPerfil(constantes.URLAVATARDEFAULT) : setsrcImagenPerfil(constantes.URLBLOB_ADJ_AVATAR + "/" + perfil.idUsuario + "/" + perfil.foto);
    }, [perfil]);

    const handleClickLogout = (e) => {
        e.preventDefault();
        logout();
    };
    useEffect(() => {
        //if (!isLogged) window.location = "#/";
        if (!isLogged) navigate("/Login");
    }, [isLogged]);

    // const buttonClose =()=> {
    //     return(
    //         <button type="button" className="p-sidebar-close p-sidebar-icon p-link" aria-label="close" onClick ={hidePerfil} style={{justifyContent: "flex-start" }}>
    //             <span className="p-sidebar-close-icon pi pi-times" ></span>
    //             <span className="p-ink" style={{height: "28px", width: "28px;" ,top: "5.5px;" ,left: "3.5px;"}}></span>
    //         </button>
    //         )

    // }
    const backPrincipal = (event) => {
        setActivePrincipal(true);
        setActivePerfilDetalle(false);
        setActiveChangePass(false);
        setActiveReconocimientos(false);
    };

    const schema = Yup.object().shape({
        passNew: Yup.string()
            .required("Contraseña es un campo obligatorio")
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .test("OK", "Contraseña debe tener al menos una mayúscula", function (value) {
                var s = value;
                var numUpper = 1;
                if (s != null) numUpper = s.length - s.replace(/[A-Z]/g, "").length;
                return numUpper > 0;
            })
            .test("OK", "Contraseña debe tener al menos una minúscula", function (value) {
                var s = value;
                var numlower = 0;
                if (s != null) numlower = s.length - s.replace(/[a-z]/g, "").length;
                return numlower > 0;
            })
            .test("OK", "REQUIERE UN NUMERO", function (value) {
                var s = value;
                var numbers = 1;
                if (s != null) numbers = s.length - s.replace(/[0-9]/g, "").length;
                return numbers > 0;
            }),
        passNewRepeat: Yup.string()
            .required("Contraseña es un campo obligatorio")
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .test("passwords-match", "Contraseñas no coinciden", function (value) {
                return this.parent.passNew === value;
            })
            .test("OK", "Contraseña debe tener al menos una mayúscula", function (value) {
                var s = value;
                var numUpper = 1;
                if (s != null) numUpper = s.length - s.replace(/[A-Z]/g, "").length;
                return numUpper > 0;
            })
            .test("OK", "Contraseña debe tener al menos una minúscula", function (value) {
                var s = value;
                var numlower = 1;
                if (s != null) numlower = s.length - s.replace(/[a-z]/g, "").length;
                return numlower > 0;
            })
            .test("OK", "REQUIERE UN NUMERO", function (value) {
                var s = value;
                var numbers = 1;
                if (s != null) numbers = s.length - s.replace(/[0-9]/g, "").length;
                return numbers > 0;
            }),
    });
    const onCrop = (elem) => {
        //setsrcImagenPerfil(elem)
        const regex = /data:.*base64,/;
        setImagenPerfilGuardar(elem.replace(regex, ""));
    };
    const onFileLoad = (file) => {
        setNombreImagenPerfilGuardar(file.name);
        setdisabledSaveImage(false);
    };
    const GuardarImagenPerfil = () => {
        let nombreImagen = nombreImagenPerfilGuardar;
        let imagenBase64 = imagenPerfilGuardar;

        let jsonPerfilImage = JSON.stringify({ nombreImagen, imagenBase64 });
        updatePerfilImage({ jsonPerfilImage });

        setVisibleEditImg(false);
    };

    const handleNotificaciones = async () => {
        let jwt = window.localStorage.getItem("jwt");
        await ListarNotificaciones({ jwt }).then((data) => setListaNotificaciones(data));
    };
    useEffect(() => {
        handleNotificaciones();
    }, []);
    useEffect(() => {
        if (listaNotificaciones) {
            if (listaNotificaciones.length > 0) {
                var nuevas = listaNotificaciones.filter((x) => x.estadoLeido == false).length;

                //setNumeroNotificacionesNuevas(nuevas)
            }
        }
    }, [listaNotificaciones]);

    const handleClickNotificacion = (idPublicacion, idGrupo, id, tipoAlerta) => {
        let jwt = window.localStorage.getItem("jwt");
        let idNotificacion = id;
        ActualizarNotificacion({ jwt, idNotificacion }).then(() => {
            setActivarNotificacion(false);
            handleNotificaciones();
            if (tipoAlerta === "CONVOCATORIA") {
                navigate("/Dashboard/Desarrollo/1");
            } else {
                navigate("/Dashboard/Grupos/" + idGrupo + "/Publicaciones/" + idPublicacion);
            }
        });
    };

    return (
        <>
            <DialogChangePassword></DialogChangePassword>

            <div className="layout-topbar" style={{ backgroundColor: "#000000" }}>
                <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars" />
                </button>
                <Link to={"/Dashboard/Inicio"} className="layout-topbar-logo">
                    <img src="/images/inicio/LogoTopBar.png" alt="logo" />
                    <span></span>
                </Link>

                {/* <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button> */}

                {/* <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}> */}
                <ul className={"p-eva-topbar-usuario lg:flex origin-top"}>
                    <li>
                        <button className="p-link layout-topbar-button peva-icon-bar" onClick={props.onMobileSubTopbarMenuClick}>
                            {/* <img src="images/inicio/notification.png" alt="logo" /> */}
                            <i
                                className="pi mr-4 p-text-secondary p-overlay-badge"
                                style={{ fontSize: "2rem" }}
                                onClick={() => {
                                    setActivarNotificacion(true);
                                    numeroNotificacionesNuevas > 0 && ActualizarNumeroAlertas(false);
                                }}
                            >
                                {numeroNotificacionesNuevas > 0 && <Badge className="bage-notificaciones" value={numeroNotificacionesNuevas > 9 ? "+9" : numeroNotificacionesNuevas}></Badge>}
                                <Iconsax.Notification color="#ffffff"></Iconsax.Notification>
                            </i>
                        </button>
                    </li>

                    <li>
                        <button className="p-link layout-topbar-button layout-topbar-button-perfil" onClick={togglePerfil}>
                            {/* <img className="peva-avatar-bar" src={perfil.foto === "" || perfil.foto ===null ? constantes.URLAVATARDEFAULT : perfil.foto} alt="avatar"/> */}
                            {srcImagenPerfil ? <img className="peva-avatar-bar" src={srcImagenPerfil} alt="avatar" /> : <Skeleton shape="circle" size="38px"></Skeleton>}
                            <span>{perfil ? perfil.nombreCorto : <Skeleton height="30px" width="100%" />}</span>
                        </button>
                    </li>
                </ul>
            </div>

            <Dialog
                visible={visibleEditImg}
                className="p-eva-dialogEditImg"
                header={() => <label>Actualizar foto de perfil</label>}
                onHide={() => (setVisibleEditImg(false), setdisabledSaveImage(true), setImagenPerfilGuardar(null))}
                footer={() => <Boton widths="100%" color="primary" disabled={disabledSaveImage} nombre="Guardar" metodoClick={GuardarImagenPerfil} />}
            >
                <Avatar width={"100%"} height={340} onCrop={onCrop} onFileLoad={onFileLoad} label="Elegir imagen"></Avatar>
            </Dialog>

            <Sidebar visible={active} id={"layout-perfil"} className={perfilClassName} position={"right"} onHide={hidePerfil}>
                <div className={clasnameBack}>
                    <button type="button" className="p-sidebar-close p-sidebar-icon p-link" aria-label="close" onClick={backPrincipal}>
                        <span className="p-sidebar-close-icon ">
                            <Iconsax.ArrowLeft />
                        </span>
                        <span className="p-ink"></span>
                    </button>
                </div>
                <div className="p-perfilOtions-avatar-container" hidden={!activePrincipal}>
                    <div className="p-perfil-avatar">
                        {/* <img className="p-perfil-avatar-img" src={perfil.foto === null || perfil.foto === "" ? constantes.URLAVATARDEFAULT : perfil.foto}></img> */}
                        <img className="p-perfil-avatar-img" src={srcImagenPerfil}></img>
                    </div>
                    <div className="p-perfil-avatar-upload">
                        <i className="p-perfil-avatar-upload-icon" onClick={() => setVisibleEditImg(true)}>
                            <Iconsax.GalleryAdd color="#FFFFFF" />
                        </i>
                    </div>
                    <div className="p-perfil-avatar-name">
                        <span>{perfil ? perfil.nombreCompleto : <Skeleton height="30px" width="100%" />}</span>
                    </div>
                    <div className="p-perfil-avatar-puesto">
                        <span>{perfil ? perfil.puesto : <Skeleton height="30px" width="100%" />}</span>
                    </div>
                </div>
                <div className="p-perfilOptions" hidden={!activePrincipal}>
                    <ul className="p-perfilOptions-lista" role="perfilMenu">
                        <li className="p-perfilItem" role="none">
                            <Link to="#" className="p-perfilItem-link" role="perfilItem" onClick={showPerfilDetail}>
                                <span className="p-perfilItem-icon">
                                    <Iconsax.User></Iconsax.User>
                                </span>
                                <span className="p-perfilItem-text">Perfil</span>
                                <span className="p-arrowPerfilItem-icon">
                                    <Iconsax.ArrowRight2></Iconsax.ArrowRight2>
                                </span>
                                <span className="p-ink"></span>
                            </Link>
                        </li>
                        {/**SE OCULTA A PETICIÓN DEL CLIENTE */}
                        {/* <li className="p-perfilItem" role={"none"}>
                            <Link to="#" className="p-perfilItem-link" role="perfilItem" onClick={showPerfilChangePass}>
                                <span className="p-perfilItem-icon">
                                    <Iconsax.Lock></Iconsax.Lock>
                                </span>
                                <span className="p-perfilItem-text">Cambiar contraseña</span>
                                <span className="p-arrowPerfilItem-icon">
                                    <Iconsax.ArrowRight2></Iconsax.ArrowRight2>
                                </span>
                                <span className="p-ink"></span>
                            </Link>
                        </li> */}
                        <li className="p-perfilItem" role={"none"}>
                            <Link to="#" className="p-perfilItem-link" role="perfilItem" onClick={showPerfilReconocimiento}>
                                <span className="p-perfilItem-icon ">
                                    <Iconsax.MedalStar></Iconsax.MedalStar>
                                </span>
                                <span className="p-perfilItem-text">Reconocimientos</span>
                                <span className="p-arrowPerfilItem-icon ">
                                    <Iconsax.ArrowRight2></Iconsax.ArrowRight2>
                                </span>
                                <span className="p-ink"></span>
                            </Link>
                        </li>
                        <li className="p-perfilItem" role={"none"}>
                            <Link to="#" className="p-perfilItem-link" role="perfilItem" onClick={handleClickLogout}>
                                <span className="p-perfilItem-icon">
                                    <Iconsax.Logout></Iconsax.Logout>
                                </span>
                                <span className="p-perfilItem-text">Cerrar sessión</span>
                                <span className="p-arrowPerfilItem-icon ">
                                    <Iconsax.ArrowRight2></Iconsax.ArrowRight2>
                                </span>
                                <span className="p-ink"></span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="p-perfilOptionsDetail" hidden={!activePerfilDetalle} style={{ height: "100%" }}>
                    <div className="peva-label-header">
                        <label htmlFor="Perfil">Perfil</label>
                    </div>

                    <div className="containerTab">
                        <div className="bloc-tabs">
                            <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}>
                                Información personal
                            </button>
                            <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>
                                Acerca de mí
                            </button>
                        </div>

                        <div className="content-tabs">
                            <Formik
                                //validationSchema={schema}
                                initialValues={{
                                    nombres: perfil ? perfil.nombreCompleto : "",
                                    correo: perfil ? perfil.correo : "",
                                    celular: perfil ? perfil.celular : "",
                                    puesto: perfil ? perfil.puesto : "",
                                    cumpleanios: perfil ? perfil.cumpleanios : "",
                                    acercademi: perfil ? perfil.acercademi : "",
                                }}
                                onSubmit={(values) => {
                                    // Alert the input values of the form that we filled
                                    // let userName = values.username;
                                    // let password = values.password;
                                    // login({ userName, password });
                                    //   alert(JSON.stringify(values));
                                    console.log("Envio")

                                    let codigo = perfil.codigo;
                                    let nombreCompleto = perfil.nombreCompleto;
                                    let nombreCorto = perfil.nombreCorto;
                                    let cumpleanios = perfil.cumpleanios;
                                    let correo = perfil.correo;
                                    let celular = values.celular;
                                    let puesto = values.puesto;
                                    let foto = perfil.foto;
                                    let acercademi = values.acercademi;
                                    let jsonPerfil = JSON.stringify({ codigo, nombreCompleto, nombreCorto, cumpleanios, correo, celular, puesto, foto, acercademi });
                                    //alert(jsonPerfil);
                                    updatePerfil({ jsonPerfil });
                                    setMostrarPopUpMensaje(true);
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className={toggleState === 1 ? "contentTabs  active-content" : "contentTabs"}>
                                            <div className="peva-form">
                                                <label htmlFor="Nombres" className="peva-label">
                                                    Nombres y apellidos
                                                </label>
                                                <input
                                                    id="nombres"
                                                    type="text"
                                                    className="peva-input peva-input-disabled"
                                                    placeholder="Escribe aquí"
                                                    disabled={true}
                                                    //onChange={(e) => setUserName(e.target.value)} value={values.username}
                                                    value={values.nombres}
                                                    name="nombres"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <p className="error">{errors.nombres && touched.nombres && errors.usernombresname}</p>
                                                <label htmlFor="cumpleanios" className="peva-label">
                                                    Cumpleaños
                                                </label>
                                                <input
                                                    id="cumpleanios"
                                                    type="text"
                                                    placeholder="Escribe aquí"
                                                    className="peva-input peva-input-disabled"
                                                    disabled={true}
                                                    //onChange={(e) => setPassword(e.target.value)} value={values.username}
                                                    //value={values.cumpleanios.substring(10, 0)}
                                                    value={ObtenerFechaFormatoPalabraMes(values.cumpleanios)}
                                                    name="cumpleanios"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <p className="error">{errors.cumpleanios && touched.cumpleanios && errors.cumpleanios}</p>

                                                <label htmlFor="correo" className="peva-label">
                                                    Correo electrónico
                                                </label>
                                                <input
                                                    id="correo"
                                                    type="correo"
                                                    placeholder="Escribe aquí"
                                                    className="peva-input peva-input-disabled"
                                                    value={values.correo}
                                                    disabled={true}
                                                    //onChange={(e) => setPassword(e.target.value)} value={values.username}
                                                    name="correo"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <p className="error">{errors.correo && touched.correo && errors.correo}</p>

                                                <label htmlFor="puesto" className="peva-label">
                                                    Puesto
                                                </label>
                                                <input
                                                    id="puesto"
                                                    type="puesto"
                                                    placeholder="Escribe aquí"
                                                    className="peva-input peva-input-disabled"
                                                    value={values.puesto}
                                                    //onChange={(e) => setPassword(e.target.value)} value={values.username}
                                                    name="puesto"
                                                    disabled={true}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <p className="error">{errors.puesto && touched.puesto && errors.puesto}</p>

                                                <label htmlFor="celular" className="peva-label">
                                                    Celular
                                                </label>
                                                <input
                                                    id="celular"
                                                    type="celular"
                                                    placeholder="Escribe aquí"
                                                    className="peva-input"
                                                    value={values.celular}
                                                    //onChange={(e) => setPassword(e.target.value)} value={values.username}
                                                    name="celular"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <p className="error">{errors.celular && touched.celular && errors.celular}</p>

                                                <Button label="Guardar cambios" className="peva-button" />
                                                <div className="flex align-items-center justify-content-between mb-6"></div>
                                            </div>
                                        </div>
                                        <div className={toggleState === 2 ? "contentTabs  active-content" : "contentTabs"}>
                                            <div className="peva-form">
                                                <label htmlFor="acercademi" className="peva-label">
                                                    Cuentanos cosas interesantes de ti...
                                                </label>
                                                <textarea
                                                    id="acercademi"
                                                    type="text"
                                                    className="peva-input peva-input-Area"
                                                    placeholder="Escribe aquí"
                                                    //onChange={(e) => setUserName(e.target.value)} value={values.username}
                                                    value={values.acercademi}
                                                    name="acercademi"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <p className="error">{errors.nombres && touched.nombres && errors.usernombresname}</p>

                                                <Button label="Guardar cambios" className="peva-button" />
                                                <div className="flex align-items-center justify-content-between mb-6"></div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="p-perfilOptionsChangPass" hidden={!activeChangePass}>
                    <div className="peva-label-header">
                        <label htmlFor="changePass">Cambiar contraseña</label>
                    </div>

                    <Formik
                        validationSchema={schema}
                        initialValues={{ passCurrent: "", passNew: "", passNewRepeat: "" }}
                        onSubmit={(values) => {
                            // Alert the input values of the form that we filled
                            let passwordAnterior = values.passNew;
                            let passwordNuevo = values.passNew;
                            // login({ userName, password });
                            //   alert(JSON.stringify(values));
                            //changePass({ passwordAnterior, passwordNuevo });
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="peva-form">
                                    <label htmlFor="passCurrent" className="peva-label">
                                        Contraseña actual
                                    </label>
                                    <div className="peva-input-icon">
                                        <input
                                            id="passCurrent"
                                            type={passwordTypeCurrent}
                                            className="peva-input"
                                            placeholder="Escribe aquí"
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                                            name="passCurrent"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <div className="peva-icon-rigth">
                                            {passwordTypeCurrent === "password" ? (
                                                <i onClick={togglePasswordCurrent}>
                                                    <Iconsax.EyeSlash />
                                                </i>
                                            ) : (
                                                <i onClick={togglePasswordCurrent}>
                                                    <Iconsax.Eye />
                                                </i>
                                            )}
                                        </div>
                                    </div>

                                    <p className="error">{errors.passCurrent && touched.passCurrent && errors.passCurrent}</p>

                                    <label htmlFor="passNew" className="peva-label">
                                        Nueva Contraseña
                                    </label>
                                    <div className="peva-input-icon">
                                        <input
                                            id="passNew"
                                            type={passwordTypeNew}
                                            className="peva-input"
                                            placeholder="Escribe aquí"
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                                            name="passNew"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <div className="peva-icon-rigth">
                                            {passwordTypeNew === "password" ? (
                                                <i onClick={togglePasswordNew}>
                                                    <Iconsax.EyeSlash />
                                                </i>
                                            ) : (
                                                <i onClick={togglePasswordNew}>
                                                    <Iconsax.Eye />
                                                </i>
                                            )}
                                        </div>
                                    </div>

                                    <p className="error">{errors.passNew && touched.passNew && errors.passNew}</p>

                                    <label htmlFor="passNewRepeat" className="peva-label">
                                        Repetir nueva Contraseña
                                    </label>
                                    <div className="peva-input-icon">
                                        <input
                                            id="passNewRepeat"
                                            type={passwordTypeRepeat}
                                            className="peva-input"
                                            placeholder="Escribe aquí"
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                                            name="passNewRepeat"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <div className="peva-icon-rigth">
                                            {passwordTypeRepeat === "password" ? (
                                                <i onClick={togglePasswordRepeat}>
                                                    <Iconsax.EyeSlash />
                                                </i>
                                            ) : (
                                                <i onClick={togglePasswordRepeat}>
                                                    <Iconsax.Eye />
                                                </i>
                                            )}
                                        </div>
                                    </div>

                                    <p className="error">{errors.passNewRepeat && touched.passNewRepeat && errors.passNewRepeat}</p>

                                    <Button label="Guardar cambios" className="peva-button" />
                                    <div className="flex align-items-center justify-content-between mb-6"></div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                <div className="p-perfilOptionsDetail" hidden={!activeReconocimientos}>
                    <div className="peva-label-header">
                        <label htmlFor="Perfil">Reconocimientos</label>
                    </div>
                    <div className="p-fluid formgrid grid">
                        {reconocimiento &&
                            reconocimiento.length > 0 &&
                            reconocimiento.map((item, i) => {
                                return (
                                    <div className="field col-12 md:col-6">
                                        <div className="reconocimiento-item-img">
                                            <img src={constantes.URLBLOB_CLIMA_RECONOCIMIENTO + "/" + item.imagenInsignia} width={200}></img>
                                        </div>
                                        <div className="reconocimiento-item-nombre">{item.nombreReconocimiento}</div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Sidebar>
            <Notificaciones visible={activarNotificacion} onHide={() => setActivarNotificacion(false)} lista={listaNotificaciones} idLogin={idLogin} onCLickNotificacion={handleClickNotificacion}></Notificaciones>
            <Dialog visible={mostrarPopUpMensaje} onHide={() => setMostrarPopUpMensaje(false)}
                className="p-eva-dialog-descripcion" footer={<></>}
            >
                <div className="header-general" style={{ textAlign: "center" }}>
                    <label>¡Exitoso!</label>
                </div>
                <div className="p-eva-labelForm" style={{ whiteSpace: "pre-line", paddingTop: 24 }}>
                    <label>
                        El perfil se actualizó correctamente
                    </label>
                </div>
            </Dialog>
        </>
    );
};
