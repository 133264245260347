import React, { useState, useEffect ,useRef} from 'react';
import {useNavigate,Link,useParams} from 'react-router-dom';
import InputDefault from '../../../components/InputDefault/InputDefault';
import InputIcon from '../../../components/InputIcon/InputIcon';
import DropdownDefault from '../../../components/DropdownDefault/Dropdown';
import TextareaDefault from '../../../components/TextArea/TextArea';
import BotonEva from '../../../components/Boton/Boton';
import usePermisos from "../../../hooks/usePermisos";
import * as Iconsax from "iconsax-react";
import { FastField, Formik,useFormik } from "formik";
import * as Yup from "yup";
import "./CrearReglamento.scss";

import { Dialog } from "primereact/dialog";


import useSomosin from "../../../hooks/useSomosin";
import useUsuario from '../../../hooks/useUsuario';
import { ObtenerSeccion,ObtenerReglamentoId,Registrar,Actualizar,RegistrarSeccion } from "../../../service/SomosinService";
import ToastEva from "../../../components/Toast/ToastEva";

const CrearReglamento = () =>{
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Añadir nueva sección");
    const [modoEdicion, setModoEdicion] = useState(false);
    const [comboSeccion, setComboSeccion] = useState([]);
    const [stateImage,setStateImage] = useState({file: null,base64URL: ""})   
    const {permisos,isLogged}= useUsuario();
    const [seccion, setSeccion] = useState(null);
    const [reglamento, setReglamento] = useState();
    let { idGerencia } = useParams();


    //
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const getSeccion = async () => {
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerSeccion({ jwt }).then(data => {
            setSeccion(data);
        });
    };

    useEffect(() => {
        getSeccion();
    }, []);


    const footerSeccion = 
    <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
        <BotonEva nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialog(false)}></BotonEva>
        <BotonEva nombre={"Registrar"} widths="50%" color="primary" 
        loading = {isLoading}></BotonEva>
    </div>;

const formikSeccion = useFormik({
    enableReinitialize:true,
    initialValues: {
        nombreSeccion:""
    },
  onSubmit: values => {
    console.log("jsonSeccionnnn")
    let nombreSeccion = values.nombreSeccion
    let jsonSeccion = JSON.stringify({ nombreSeccion},null,2);
    console.log("jsonSeccion",jsonSeccion)
    RegistrarSecciones({jsonSeccion})  
  },
});

const RegistrarSecciones =({jsonSeccion})=>{
    let jwt = window.localStorage.getItem("jwt");
    RegistrarSeccion({jsonSeccion,jwt}).then(data=>{
        formikSeccion.setSubmitting(false)
        toast.current.show({severity:'success', summary: 'Success', detail:"Se añadio exitosamente.", life: 7000})
        getSeccion();
        setShowDialog(false)
    })
    .catch(errors => {
        toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
        formikSeccion.setSubmitting(false)
        setShowDialog(false)

    })
}

useEffect(() => {
    console.log("LIMPIAR")
    if (!showDialog) {
        formikSeccion.setFieldValue("nombreSeccion", "");
    }
}, [showDialog]);

    //
    const toast = useRef(null);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        if(permisos.length >0)
        {
            !(permisos.indexOf("editarReglamentos")> -1)  && navigate("/")
        }
    },[permisos])
    

    

    // useEffect(()=>{
    //     const getSeccion =async ()=>{
    //         let jwt = window.localStorage.getItem("jwt");
    //        await ObtenerSeccion({jwt}).then(data=>{
    //             setSeccion(data)
    //        });
    //     }
    //     getSeccion();
    // },[])

    let { idReglamento } = useParams();
    useEffect(()=>{
        const getReglamentoPorId =async ()=>{
            let id= idReglamento;
            let jwt = window.localStorage.getItem("jwt");
           await ObtenerReglamentoId({id,jwt}).then(data=>{
                setReglamento(data)
           });
           
        }
        if(seccion){
            getReglamentoPorId();
        } 
    },[seccion])

    useEffect(()=>{
        setComboSeccion(seccion);
    },[seccion])

    useEffect(()=>{
        if(reglamento) {
            let seccionSelected = seccion.filter(x=>x.value == reglamento.idSeccion);
            if(seccionSelected.length > 0)
            {
                setTituloPagina("Editar "+seccionSelected[0].code)
                setModoEdicion(true)
            }
            
        }

    },[reglamento])
    
    // const dropdownSeccion = [
    //     { value: 1, code: "Reglamentos" },
    //     { value: 2, code: "Guías y políticas" }
    // ];

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    
    

    const schema = Yup.object().shape({
        seccion: Yup.string().required("Seccion es un campo obligatorio"),
        tituloDoc: Yup.string().required("Título de documento es un campo obligatorio"),
        descripcion: Yup.string().required("Descripcción es un campo obligatorio")
        //imageGrupo: Yup.string().required("Foto de grupo es un campo obligatorio")
    });


    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            seccion:modoEdicion?reglamento.idSeccion:""
            ,tituloDoc:modoEdicion?reglamento.nombre:""
            ,descripcion :modoEdicion?reglamento.descripcion:""
           ,nombreAdjunto:modoEdicion?reglamento.nombreAdjunto:""
           ,tipoDocumento: null
           ,imagenBase64:null
        },
      //validationSchema: schema,
      onSubmit: values => {
    
        let Id =modoEdicion?reglamento.id:0;
        let Nombre = values.tituloDoc;
        let Descripcion = values.descripcion
        let IdSeccion = values.seccion;
        let NombreAdjunto = values.nombreAdjunto;
        let ImageBase64 = values.imagenBase64
        let IdGerencia = idGerencia;
        let jsonReglamento = JSON.stringify({ Id,Nombre, Descripcion, IdSeccion, NombreAdjunto, ImageBase64,IdGerencia },null,2);
        
        //alert(jsonReglamento)
            // console.log(jsonReglamento)
         modoEdicion ?ActualizarReglamento({jsonReglamento}) :RegistrarReglamento({jsonReglamento})
        // //  console.log(jsonCapacitacion)
        //  formik.setSubmitting(false)
        //  alert(jsonClima);
         //Actualizar({jsonPolitica})
        
        
      },
    });
    const ActualizarReglamento =({jsonReglamento})=>{
        let jwt = window.localStorage.getItem("jwt");
        Actualizar({jsonReglamento,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    const RegistrarReglamento =({jsonReglamento})=>{
        let jwt = window.localStorage.getItem("jwt");
        Registrar({jsonReglamento,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro creado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    return (
        <div className="eva-dashboard-crearReglamento">
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label>{tituloPagina}</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-3">
                            <label htmlFor="Seccion">Escoje una sección</label>
                            <DropdownDefault
                                id="seccion"
                                name="seccion"
                                errorClass={formik.touched.tituloDoc != undefined && (formik.errors.seccion ? "error" : "success")}
                                placeholder="Seleccione"
                                options={seccion}
                                optionLabel="code"
                                value={formik.values.seccion}
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.seccion && formik.touched.seccion && formik.errors.seccion}</p>
                        </div>
                        <div className="field col-12 md:col-1 p-eva-container-icon">
                            <div className="p-eva-icon">
                                <i style={{ cursor: "pointer" }} onClick={()=> setShowDialog(true)}>
                                <Iconsax.Add color="#ffffff"/>
                                </i>
                            </div>
                           
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="tituloDoc">Título del documento</label>
                            <InputDefault
                                id="tituloDoc"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.tituloDoc != undefined && (formik.errors.tituloDoc ? "error" : "success")}
                                value={formik.values.tituloDoc}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="tituloDoc"
                            />
                            <p className="error">{formik.errors.tituloDoc && formik.touched.tituloDoc && formik.errors.tituloDoc}</p>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="nombreAdjunto">Agregar un documento</label>
                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                <InputDefault
                                    id={`nombreAdjunto`}
                                    name={`nombreAdjunto`}
                                    placeholder="Adjunta aquí"
                                    className="White"
                                    value={formik.values.nombreAdjunto}
                                    onChange={(e) => {
                                        formik.values.imagenBase64 && formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                ></InputDefault>
                                {formik.values.nombreAdjunto != "" ? (
                                    <label className="eva-labelFileLider-icon">
                                        <i onClick={() => {
                                             formik.setFieldValue(`nombreAdjunto`, "");
                                             formik.setFieldValue(`imagenBase64`, null);
                                        }} style={{ cursor: "pointer" }}>
                                            <Iconsax.Trash />
                                        </i>
                                    </label>
                                ) : (
                                    <label htmlFor={`nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                        <i>
                                            <Iconsax.DocumentText1 color="#F47647" />
                                        </i>
                                    </label>
                                )}

                                <input
                                    type="file"
                                    id={`nombreDocumentoIcon`}
                                    name={`nombreDocumentoIcon`}
                                    onChange={(e) => {
                                        formik.setFieldValue(`nombreAdjunto`, e.currentTarget.files[0].name);
                                        formik.setFieldValue(`tipoDocumento`, e.currentTarget.files[0].type);
                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                            formik.setFieldValue(`imagenBase64`, result);
                                        });

                                        //onFileChange(e, "document")
                                    }}
                                    accept="application/pdf"
                                    hidden
                                ></input>
                            </div>
                            {/* <InputIcon id="documentoAdjunto" 
                            placeholder="Adjunta aquí" className="White" 
                            icon={<Iconsax.DocumentText1 />} name="imageGrupo" type="File" 
                            onChange={onFileChange} accept="application/pdf" />
                            <p className="error">{errors.documentoAdjunto && touched.documentoAdjunto && errors.documentoAdjunto}</p> */}
                        </div>
                        <div className="field col-12 md:col-12">
                            <label htmlFor="descripcion">Añade una descripción</label>
                            <TextareaDefault
                                id="descripcion"
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                value={formik.values.descripcion}
                                icon={<Iconsax.DocumentText1 />}
                                name="descripcion"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                        </div>
                    </div>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4" />
                        <div className="field col-12 md:col-4" />
                        <div className="field col-12 md:col-2">
                            <BotonEva nombre="Cancelar" color="secondary" type="button" metodoClick={() => navigate(-1)} widths="100%" />
                        </div>
                        <div className="field col-12 md:col-2">
                            <BotonEva nombre={modoEdicion ? "Actualizar" : "Publicar"} color="primary" type="submit" widths="100%" loading ={formik.isSubmitting} />
                        </div>
                    </div>
                </form>
            </div>
            {/* <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                    footer={footerSeccion}  className ="p-eva-dialogDelete">
                    <div>
                            <label htmlFor="nombreSeccion">Añadir sección</label>
                            <InputDefault
                                id="nombreSeccion"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formikSeccion.touched.nombreSeccion != undefined && (formikSeccion.errors.nombreSeccion ? "error" : "success")}
                                value={formikSeccion.values.nombreSeccion}
                                onChange={formikSeccion.handleChange}
                                onBlur={formikSeccion.handleBlur}
                                name="nombreSeccion"
                            />
                            <p className="error">{formikSeccion.errors.nombreSeccion && formikSeccion.touched.nombreSeccion && formikSeccion.errors.nombreSeccion}</p>
                        </div>
            </Dialog> */}

            <Dialog visible={showDialog} onHide={() => {setShowDialog(false); formikSeccion.setFieldValue("nombreSeccion", "")}} 
             footer={<></>} >
                 <div className="eva-header">Añadir sección</div>
                <form  onSubmit={formikSeccion.handleSubmit}>
                    <div>
                        <div>
                            <label htmlFor="nombreSeccion">Nombre </label>
                            <InputDefault
                                id="nombreSeccion"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formikSeccion.touched.nombreSeccion != undefined && (formikSeccion.errors.nombreSeccion ? "error" : "success")}
                                value={formikSeccion.values.nombreSeccion}
                                onChange={formikSeccion.handleChange}
                                onBlur={formikSeccion.handleBlur}
                                name="nombreSeccion"
                            />
                            <p className="error">{formikSeccion.errors.nombreSeccion && formikSeccion.touched.nombreSeccion && formikSeccion.errors.nombreSeccion}</p>
                        </div>

                        <div className="p-eva-footer-form d-flex justify-content-center">
                            <BotonEva nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => {setShowDialog(false); formikSeccion.setFieldValue("nombreSeccion", "")}}></BotonEva>
                            
                            <BotonEva nombre={"Enviar"} color="primary" widths="184px" type="submit" loading={formikSeccion.isSubmitting}></BotonEva>
                        </div>
                    </div>
                </form>
        </Dialog>
            
        </div>
    );
}
export default CrearReglamento;