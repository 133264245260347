import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../../components/TextArea/TextArea";
import MultiselectEva from "../../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../../components/Boton/Boton";
import ToastEva from "../../../../components/Toast/ToastEva";
import InputDefault from "../../../../components/InputDefault/InputDefault";
import InputIcon from "../../../../components/InputIcon/InputIcon";
import {ObtenerDiversidadActividadPorId,RegistrarDiversidadActividad,ActualizarDiversidadActividad} from "../../../../service/ClimaService";
import classNames from "classnames";
const EditarActividad = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();
    const [modoEdicion, setmodoEdicion] = useState(false);
    const [tituloPagina, setTituloPagina] = useState("Crear nueva actividad");
    const [actividad, setActividad] = useState(null);
    const toast = useRef(null);
    let { idActividad } = useParams();
    let { idSeccionClima } = useParams();
    useEffect(()=>{
        const getActividad = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let id= idActividad;
            await ObtenerDiversidadActividadPorId({jwt,id}).then(data => 
                {
                    setActividad(data)
                    setmodoEdicion(true)
                    setTituloPagina("Editar "+data.nombre)

                })
        }
        if(idActividad)getActividad()
    },[idActividad])

    const Actualizar =({jsonActividad})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarDiversidadActividad({jsonActividad,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    const Registrar =({jsonActividad})=>{
        let jwt = window.localStorage.getItem("jwt");
        RegistrarDiversidadActividad({jsonActividad,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Actividad registrada exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const schema = Yup.object().shape({
        nombre: Yup.string().required("Campo nombre de la actividad requerido."),
        descripcion: Yup.string().required("Campo descripción requerido."),

    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            imagenBase64:null,
            tipoDocumento:null,
            id:modoEdicion && actividad?actividad.id:0,
            descripcion: modoEdicion  && actividad?actividad.descripcion:"",
            nombre : modoEdicion && actividad?actividad.nombre:"",
            nombreFoto: modoEdicion && actividad?actividad.nombreFoto:"",
            listaDocDescargable: modoEdicion  && actividad && actividad.clima_diversidad_actividad_documento.length >0? actividad.clima_diversidad_actividad_documento.filter(x=>x.tipo=="Adjunto"&& x.estado == true && x.descargable ==true) 
                : [{imagenBase64:null,tipoDocumento:null,id:0,IdClimaDiversidadActividad:0,nombreDocumento:'',tipo:'Adjunto',descargable : true,estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            
            listaDocCompleto: modoEdicion  && actividad && actividad.clima_diversidad_actividad_documento.length >0? actividad.clima_diversidad_actividad_documento.filter(x=>x.tipo=="Adjunto"&& x.estado == true &&  x.descargable ==false) 
                : [{imagenBase64:null,tipoDocumento:null,id:0, IdClimaDiversidadActividad:0,nombreDocumento:'',tipo:'Adjunto',descargable:false,estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            
            listaUrl : modoEdicion  && actividad && actividad.clima_diversidad_actividad_documento.length >0? actividad.clima_diversidad_actividad_documento.filter(x=> x.tipo=="Url"&& x.estado == true) 
            : [{id:0,IdClimaDiversidadActividad:0,nombreDocumento:'',tipo:'Url',descargable:false,estado:false,fechaEliminacion:null,usuarioEliminacion:null}]
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id
        let descripcion = values.descripcion
        let imagenBase64 = values.imagenBase64
        let tipoDocumento = values.tipoDocumento;
        let nombre = values.nombre;
        let nombreFoto = values.nombreFoto
        let listaDocDescargable = values.listaDocDescargable
        let listaDocCompleto = values.listaDocCompleto
        let listaUrl = values.listaUrl
        
        
        let jsonActividad = JSON.stringify({id,descripcion,imagenBase64,tipoDocumento,nombre,nombreFoto,listaDocDescargable,listaUrl,listaDocCompleto},null,2)
         console.log(jsonActividad)
        // //alert(jsonTema);
        //Actualizar({jsonCapacitacion})
        if(!modoEdicion) Registrar({jsonActividad}) 
        else{
            Actualizar({jsonActividad})
        }
        
        
      },
    });
    return (
        <div className="eva-dashboard-crearActividades" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4">
                                <label htmlFor="textnombreoBoton">Nombre de la actividad</label>
                                <InputDefault
                                    id="nombre"
                                    name="nombre"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")}
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.nombre && formik.errors.nombre}</small>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="nombreFoto">Adjunta una foto</label>
                                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                    <label
                                        className={classNames("eva-labelFileLider", {
                                            "p-eva-error": formik.errors.nombreFoto,
                                        })}
                                        style={{ minHeight: 16, height: "fit-content" }}
                                    >
                                        <span>{formik.values.nombreFoto.length > 0 ? formik.values.nombreFoto : "Adjunta aquí"}</span>
                                    </label>
                                    {formik.values.nombreFoto != "" ? (
                                        <label className="eva-labelFileLider-icon">
                                            <i onClick={() => {
                                                    formik.setFieldValue(`nombreFoto`, "");
                                                    formik.setFieldValue(`imagenBase64`, null);
                                            }}>
                                                <Iconsax.Trash />
                                            </i>
                                        </label>
                                    ) : (
                                        <label htmlFor={`nombreFoto`} className="eva-labelFileLider-icon">
                                            <i>
                                                <Iconsax.GalleryAdd color="#F47647" />
                                            </i>
                                        </label>
                                    )}

                                    <input
                                        type="file"
                                        id={`nombreFoto`}
                                        name={`nombreFoto`}
                                        onChange={(e) => {
                                            formik.setFieldValue(`nombreFoto`, e.currentTarget.files[0].name);
                                            formik.setFieldValue(`tipoDocumento`, e.currentTarget.files[0].type);
                                            getBase64(e.currentTarget.files[0]).then((result) => {
                                                formik.setFieldValue(`imagenBase64`, result);
                                            });

                                            //onFileChange(e, "document")
                                        }}
                                        accept="application/pdf,audio/*,image/*"
                                        hidden
                                    ></input>
                                </div>
                                <small className="p-error">{formik.errors.imagenBase64 && formik.touched.imagenBase64}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción para la categoría
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.descripcion && formik.errors.descripcion}</small>
                            </div>
                            <div className="field col-12 md:col-12"><label className="p-eva-labelForm">Agrega documentos</label></div>
                            <FieldArray
                                name="listaDocCompleto"
                                render={(arrayHelpersDoc) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaDocCompleto &&
                                                    formik.values.listaDocCompleto.map((listaDocCompleto, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.listaDocCompleto[index].nombreDocumento.length > 0 ? formik.values.listaDocCompleto[index].nombreDocumento : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`listaDocCompleto[${index}].nombreDocumento`}
                                                                    name={`listaDocCompleto[${index}].nombreDocumento`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.listaDocCompleto[index].nombreDocumento}
                                                                    onChange={(e)=>{formik.values.listaDocCompleto[index].imagenBase64 && formik.handleChange(e)}}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.listaDocCompleto[index].nombreDocumento != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpersDoc)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`listaDocCompleto[${index}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`listaDocCompleto[${index}].nombreDocumentoIcon`}
                                                                    name={`listaDocCompleto[${index}].nombreDocumentoIcon`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`listaDocCompleto[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`listaDocCompleto[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`listaDocCompleto[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersDoc.push({imagenBase64:null,tipoDocumento:null,id:0,IdClimaDiversidadActividad:0,nombreDocumento:'',tipo:'Adjunto',descargable:false,estado:false,fechaEliminacion:null,usuarioEliminacion:null})}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multiplend" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                <input
                                                    type="file"
                                                    id={`file-multiplend`}
                                                    name={`file-multiplend`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles= e.currentTarget.files
                                                        console.log(tempFiles)
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64  =""
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayHelpersDoc.push({imagenBase64:base64,tipoDocumento:tipo,id:0,IdClimaDiversidadActividad:0,nombreDocumento:nombre,tipo:'Adjunto',descargable:false,estado:false,fechaEliminacion:null,usuarioEliminacion:null})
                                                            });
                                                        }
                                                      
                                                    }}
                                                    accept="application/pdf,audio/*,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                            <div className="field col-12 md:col-12"><label className="p-eva-labelForm">Agrega documentos descargables</label></div>


                            <FieldArray
                                name="listaDocDescargable"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaDocDescargable &&
                                                    formik.values.listaDocDescargable.map((listaDocDescargable, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.listaDocDescargable[index].nombreDocumento.length > 0 ? formik.values.listaDocDescargable[index].nombreDocumento : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`listaDocDescargable[${index}].nombreDocumento`}
                                                                    name={`listaDocDescargable[${index}].nombreDocumento`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.listaDocDescargable[index].nombreDocumento}
                                                                    onChange={(e)=>{formik.values.listaDocDescargable[index].imagenBase64 && formik.handleChange(e)}}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.listaDocDescargable[index].nombreDocumento != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpers)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`listaDocDescargable[${index}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`listaDocDescargable[${index}].nombreDocumentoIcon`}
                                                                    name={`listaDocDescargable[${index}].nombreDocumentoIcon`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`listaDocDescargable[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`listaDocDescargable[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`listaDocDescargable[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push({imagenBase64:null,tipoDocumento:null,id:0,IdClimaDiversidadActividad:0,nombreDocumento:'',tipo:'Adjunto',descargable:true,estado:false,fechaEliminacion:null,usuarioEliminacion:null})}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multipled" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                <input
                                                    type="file"
                                                    id={`file-multipled`}
                                                    name={`file-multipled`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles= e.currentTarget.files
                                                        console.log(tempFiles)
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64  =""
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayHelpers.push({imagenBase64:base64,tipoDocumento:tipo,id:0,IdClimaDiversidadActividad:0,nombreDocumento:nombre,tipo:'Adjunto',descargable:true,estado:false,fechaEliminacion:null,usuarioEliminacion:null})
                                                            });
                                                        }
                                                      
                                                    }}
                                                    accept="application/pdf,audio/*,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            <div className="field col-12 md:col-12"><label className="p-eva-labelForm">Agrega links de videos</label></div>

                            <FieldArray
                                name="listaUrl"
                                render={(arrayHelpersLink) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaUrl &&
                                                    formik.values.listaUrl.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <InputIcon
                                                                    id={`listaUrl[${index}].nombreDocumento`}
                                                                    name={`listaUrl[${index}].nombreDocumento`}
                                                                    className="white"
                                                                    placeholder="Ingresa el link aquí"
                                                                    errorClass={formik.touched.nombreDocumento != undefined && (formik.errors.nombreDocumento ? "error" : "success")}
                                                                    value={formik.values.listaUrl[index].nombreDocumento}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    icon={formik.values.listaUrl[index].nombreDocumento != "" && <Iconsax.Trash />}
                                                                    onClickIcon={() => onDeleteLink(index, arrayHelpersLink)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLink.push(
                                                    {id:0,IdClimaDiversidadActividad:0,nombreDocumento:'',tipo:'Url',descargable:false,estado:false,fechaEliminacion:null,usuarioEliminacion:null})}>
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={modoEdicion?"Actualizar":"Registrar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default EditarActividad;