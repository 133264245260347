import React, { useState, useEffect, useRef, useCallback } from "react";
import * as Iconsax from "iconsax-react";
import * as constantes from "../../../constants/constantes.js";
import "./CardPublicacionCompartida.scss"
import { Avatar } from "primereact/avatar";
import { ObtenerTiempoTranscurrido } from "../../../helpers/helpers";

const CardPublicacionCompartida = ({publicacion}) => {
    return (
        <div className="p-eva-publicacionCompartida">
            {/* <div className="publicacionCompartida-img">{publicacion.rutaImagenVideo && <img src={constantes.URLBLOB_PUBLICACIONES + "/" + publicacion.id + "/" + publicacion.rutaImagenVideo}></img>}</div> */}
            <div className="publicacionCompartida-img">
            {publicacion.rutaImagenVideo ? (
                <img src={constantes.URLBLOB_PUBLICACIONES + "/" + publicacion.id + "/" + publicacion.rutaImagenVideo} alt="Publicación imagen o video" />
            ) : (
                <div>No image or video available</div>
            )}
            </div>
            <div className="publicacionCompartida-body">
                <div className="p-eva-info-header">
                    {/* <Avatar image={constantes.URLAVATARDEFAULT} size={"xlarge"} shape="circle" style={{ minWidth: 56 }} /> */}
                    <Avatar image={publicacion.nombreFoto ? constantes.URLBLOB_ADJ_AVATAR + "/" + publicacion.idUsuarioRegistro + "/" + publicacion.nombreFoto : constantes.URLAVATARDEFAULT} size={"xlarge"} shape="circle" style={{ minWidth: 56 }} />
                    <div className="p-eva-info-header-avatar">
                        <span className="titulo">{publicacion.nombreUsuario}</span>
                        <span className="p-eva-labelForm-grey">{ObtenerTiempoTranscurrido(publicacion.fechaActual,publicacion.fechaRegistro)}</span>
                        {/* <span className="p-eva-labelForm-grey">Hace 20 minutos</span> */}
                    </div>
                </div>
                <div className="publicacion-aprobacion-info-body">
                    <div className="info-body-contenido"  style={{whiteSpace:"pre-line",color:"#000000"}}
                    dangerouslySetInnerHTML={{ __html: publicacion.contenidoPublicacion }}>
                        {/* <span>{publicacion.contenidoPublicacion}</span> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CardPublicacionCompartida;