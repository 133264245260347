import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { Skeleton } from "primereact/skeleton";
import "./OnBoarding.scss"
import Boton from "../../../components/Boton/Boton";

import { ObtenerUrlTexto } from "../../../helpers/helpers";
import * as constantes from "../../../constants/constantes";
import { ObtenerListaSeccionClima } from "../../../service/ClimaService";
import { ObtenerOnoarding } from "../../../service/ClimaService";
import { Dialog } from "primereact/dialog";
import ReactPlayer from "react-player";
const Onboarding = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario()
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [onboarding, setOnboarding] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    const [mostrarDescripcion, setMostrarDescripcion] = useState(false);
    const [onboardingSeleccionado, setOnboardingSeleccionado] = useState(null);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarClima") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verClima") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        const obtenerListaSeccionClima = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccionClima);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccionClima){
            obtenerListaSeccionClima();
        } 
    },[idSeccionClima])

    useEffect(()=>{
        const getOnboarding = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            ObtenerOnoarding({jwt}).then(data=>setOnboarding(data))
            .catch(errors => {
                toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

            })
        }
        if(!onboarding)getOnboarding();
    },[])

    return (
        <div className="eva-onboarding" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    {/* <div className="header">{onboarding && <label htmlFor="header">{onboarding.titulo}</label>}</div> */}
                    <div className="p-eva-labelForm">
                </div>
                    <div className="header-right">{onboarding && permisoEditar && tituloPagina && <Link to={"../Clima/Seccion/" + idSeccionClima + "/EditarOnboarding/" + onboarding.id}>{"Editar " + tituloPagina}</Link>}</div>
                </div>
            </div>
            <div className="p-eva-dashboard-onboarding" style={{ marginTop: 16 }}>
                <div className="p-eva-labelForm">
                    <span>{onboarding ? onboarding.descripcion : <Skeleton className="mb-2" width="700px" height="100%" borderRadius="16px" />}</span>
                </div>
                {/* <div className="onboarding-valores-header header-subtitle" style={{marginTop:24}}>Nuestro propósito y valores</div> */}
                {/* <div className="onboarding-valores-header header-subtitle" style={{marginTop:24}}>{onboarding.subTitulo1}</div> */}
                <div className="onboarding-valores-header header-subtitle" style={{marginTop:24}}> {onboarding && <label htmlFor="header">{onboarding.subTitulo1}</label>}</div>

                <div className="onboarding-valores" style={{marginTop:16}}>
                    <div className="onboarding-valores-footer">
                        <span>“Hacemos posible que cada uno de nuestros estudiantes, en todo el Perú, transforme su vida para siempre”. #TRANSFORMANDOVIDAS</span>
                    </div>
                    <div className="onboarding-valores-body">
                        {
                            onboarding &&
                            onboarding.clima_onboarding_documentos.length > 0 &&
                            onboarding.clima_onboarding_documentos.map((item,i)=>{
                                return(
                                    <div className="valores-item">
                                        <div className="valores-item-img">
                                            <img src={constantes.URLBLOB_CLIMA_ONBOARDING+"/"+item.nombreFoto} width={112} height={112}></img>
                                        </div>
                                        <div className="valores-item-descripcion">
                                            <span>{item.nombreProposito}</span>
                                        </div>
                                        <div className="valores-item-boton">
                                            <Boton nombre={"Ver más"} widths="127px" heights="48px" color="secondary"
                                                    metodoClick={()=>{
                                                        setOnboardingSeleccionado(item)
                                                        setMostrarDescripcion(true)
                                                    }}></Boton>
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </div>
                    
                    {
                        onboarding && 
                        onboarding.linkProposito && 
                        onboarding.linkProposito.length>0 && onboarding.activoVideo &&
                        <div className="onboarding-valores-media">
                           <ReactPlayer url={onboarding.linkProposito} controls height={423}></ReactPlayer>
                        </div>
                    }
                    
                
                </div>
                <div style={{marginTop:32}}>
                    {onboarding && onboarding.descripcionWebOnboarding.length > 0 && onboarding.activoWebOnboarding && (
                        <div className="onboarding-seccion" style={{marginBottom:16}}>
                            <div className="onboarding-seccion-img">
                                <img src="../../../../images/inicio/search-icon-onboarding.png" width={104}></img>
                            </div>
                            <div className="onboarding-seccion-detalle">
                                <div className="seccion-detalle-titulo  header-subtitle">
                                   <div className="onboarding-valores-header header-subtitle" style={{marginTop:24}}>{onboarding && <label htmlFor="header">{onboarding.subTitulo2}</label>}</div>
                                    {/* {configuraciones.length >0&& configuraciones.filter(x=>x.codigo=="ONBOARDING_SESCCION_1")[0].valor} */}
                                </div>
                                <div className="seccion-detalle-item">
                                    <span>{ObtenerUrlTexto(onboarding.descripcionWebOnboarding)}</span>
                                </div>
                            </div>
                        </div>
                        // <div className="field col-12 md:col-12 header-subtitle">{configuraciones.length >0&& configuraciones.filter(x=>x.codigo=="ONBOARDING_SESCCION_1")[0].valor}</div>
                        // <div className="onboarding-item">
                        //     <div>
                        //         <span>{ObtenerUrlTexto(onboarding.descripcionWebOnboarding)}</span>
                        //     </div>
                        // </div>
                        
                        
                    )}

                    {onboarding && onboarding.descripcionInlearning.length > 0 && onboarding.activoInlearning && (
                        <div className="onboarding-seccion">
                            <div className="onboarding-seccion-img">
                                <img src="../../../../images/inicio/website-icon-onboarding.png" width={104}></img>
                            </div>
                            <div className="onboarding-seccion-detalle">
                                <div className="seccion-detalle-titulo  header-subtitle">
                                   <div className="onboarding-valores-header header-subtitle" style={{marginTop:24}}>{onboarding && <label htmlFor="header">{onboarding.subTitulo3}</label>}</div>
                                {/* {configuraciones.length >0&& configuraciones.filter(x=>x.codigo=="ONBOARDING_SESCCION_2")[0].valor} */}
                                </div>
                                <div className="seccion-detalle-item">
                                    <span>{ObtenerUrlTexto(onboarding.descripcionInlearning)}</span>
                                </div>
                            </div>
                        </div>
                        // <>
                        // <div className="field col-12 md:col-12 header-subtitle">{configuraciones.length >0&& configuraciones.filter(x=>x.codigo=="ONBOARDING_SESCCION_2")[0].valor}</div>
                        // <div className="onboarding-item">
                        //     <div>
                        //         <span>{ObtenerUrlTexto(onboarding.descripcionInlearning)}</span>
                        //     </div>
                        // </div>
                        // </>
                        
                    )}
                </div>
                
            </div>
            <Dialog visible={mostrarDescripcion} onHide={() => setMostrarDescripcion(false)} 
                    className="p-eva-dialog-descripcion" footer={<></>}
                    >
                        <div className="header-general" style={{ textAlign: "center" }}>
                            <label>{onboardingSeleccionado&&onboardingSeleccionado.nombreProposito}</label>
                        </div>
                        <div className="p-eva-labelForm" style={{whiteSpace:"pre-line",paddingTop:24}}>
                            <label>
                            {onboardingSeleccionado&&onboardingSeleccionado.descripcionProposito}
                            </label>
                        </div>
                    </Dialog>
        </div>
    );
}
 
export default Onboarding;