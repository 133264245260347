import React, { useEffect, useState } from 'react';
import Select, { components } from "react-select";
import styled from "styled-components";
import "./MultiselectEva.scss"
import * as Iconsax from "iconsax-react";
import {Avatar} from 'primereact/avatar';
import * as constantes from "../../constants/constantes.js";

const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Value = styled.div`
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin: 0 0.55rem 0.55rem 0;
  font-size: 0.75rem;
  color: black;
  background-color: rgba(247, 173, 46, 0.6);
  user-select: none;
  border-radius: 16px ;
  background: #FFFFFF;
`;

const XButton = styled.button`
  all: unset;
  margin-left: 0.3rem;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    color: #bb392d;
  }
  &:focus {
    color: #c82f21;
  }
`;

const MultiselectEva = (props) => {
  const { isMulti, value, onChange,mostrarAvatar=true,placeholder = "Buscar por nombre" } = props;

  const handleRemoveValue = (e) => {
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = value.find((val) => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter((val) => val.value !== buttonName),
      {action: "remove-value", removedValue }
    );
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Iconsax.SearchNormal1 color='#F47647'/>
      </components.DropdownIndicator>
    );
  };

  const { Option } = components;
  const IconOption = (props) => (
      <Option {...props}>
        {
          mostrarAvatar ? 
          <Avatar className="p-eva_avatar" shape="circle" image={props.data.foto === null || props.data.length === 0 ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR + "/" + props.data.id + "/" + props.data.foto}>
          {props.data.label}
          </Avatar>
          : props.data.label
        }
          
      </Option>
  );

  return (
    <div>
      
      <Select {...props} controlShouldRenderValue={!isMulti} className = "p-eva-multiselect" 
      placeholder = {placeholder} 
      components={{DropdownIndicator,Option: IconOption}}
      />
      <ValuesContainer className='p-eva-multiselect-tag'>
        {isMulti
          ? value.map((val) => (
              <Value key={val.value}>
                {val.label}
                <XButton name={val.value} onClick={handleRemoveValue}>
                  ✕
                </XButton>
              </Value>
            ))
          : null}
      </ValuesContainer>
    </div>
  );
};

export default MultiselectEva;
