import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import InputIcon from "../../../components/InputIcon/InputIcon";
import {ObtenerCalendarioPorId,ActualizarCalendario} from "../../../service/ClimaService";
import { getBase64 } from "../../../helpers/helpers";
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../components/InputDefault/InputDefault";
import { ListarAreas } from "../../../service/GerenciaService"; 
import { InputSwitch } from "primereact/inputswitch";
const EditarCalendario = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState("");
    const [calendario, setCalendario] = useState(null);
    const toast = useRef(null);
    let { idCalendario } = useParams();
    let { idSeccionClima } = useParams();
    const [listAreas, setListAreas] = useState(null);

     useEffect(()=>{
        const obtenerAreas=async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarAreas(jwt).then
            (data => {
            const datosTransformados = data.map(item => {
                return {
                    value: item.id,
                    code: item.nombre
                };
            });
            setListAreas(datosTransformados);
        })
            // (data => setListAreas(data))
        }

        if(!listAreas)
        {
            obtenerAreas()
        }
     },[]);

    useEffect(()=>{
        const getCalendario = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCalendario
            await ObtenerCalendarioPorId({jwt,Id}).then(data => 
                {
                    setTituloPagina(data.nombreActividad)
                    setCalendario(data)
                })
        }

        if(idCalendario) getCalendario()
    },[idCalendario])

    const mesAnio =[{numeroMes:"01",nombreMes :"Enero"},
    {numeroMes:"02",nombreMes :"Febrero"},
    {numeroMes:"03",nombreMes :"Marzo"},
    {numeroMes:"04",nombreMes :"Abril"},
    {numeroMes:"05",nombreMes :"Mayo"},
    {numeroMes:"06",nombreMes :"Junio"},
    {numeroMes:"07",nombreMes :"Julio"},
    {numeroMes:"08",nombreMes :"Agosto"},
    {numeroMes:"09",nombreMes :"Setiembre"},
    {numeroMes:"10",nombreMes :"Octubre"},
    {numeroMes:"11",nombreMes :"Noviembre"},
    {numeroMes:"12",nombreMes :"Diciembre"}]

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:calendario&&calendario.id,
            idClimaActividad:calendario&&calendario.idClimaActividad,
            mes:calendario&&calendario.mes,
            nombreActividad:calendario&&calendario.nombreActividad,
            fechaActividad:calendario&&calendario.fechaActividad,
            hora:calendario&&calendario.hora.split(" ")[0],
            inidicadorHora:calendario&&calendario.hora.split(" ")[1],
            linkActividad:calendario&&calendario.linkActividad,
            color:calendario&&calendario.color,
            idArea:calendario&&calendario.idArea,
            lugar:calendario&&calendario.lugar
        },
      //validationSchema: schema,
      onSubmit: values => {
       

         let id = values.id
         let idClimaActividad = values.idClimaActividad
         let mes = values.mes
         let nombreActividad = values.nombreActividad
         let fechaActividad = values.fechaActividad
         let hora = values.hora+" "+values.inidicadorHora
         let linkActividad = values.linkActividad
         let color = values.color
         let idArea = values.idArea
         let lugar = values.lugar
       
         let jsonClima = JSON.stringify({id,idClimaActividad,mes,nombreActividad,fechaActividad,hora,linkActividad,color,idArea,lugar},null,2)
        // // //  console.log(jsonCapacitacion)
        //  formik.setSubmitting(false)
        //  alert(jsonClima);
         Actualizar({jsonClima})
        
        
      },
    });
    const Actualizar =({jsonClima})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarCalendario({jsonClima,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    return (
        <div className="eva-dashboard-editarCalendario" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{"Editar " + tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4">
                            <label htmlFor="mes">Escoge el mes</label>
                            <DropdownDefault
                                id="mes"
                                name="mes"
                                options={mesAnio}
                                optionLabel="nombreMes"
                                optionValue="numeroMes"
                                errorClass={formik.errors.mes && formik.touched.mes ? "error" : "success"}
                                placeholder="Seleccione"
                                value={formik.values.mes}
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <small className="p-error">{formik.errors.mes && formik.touched.mes}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="nombreActividad">Nombre de la actividad</label>

                            <InputDefault
                                id="nombreActividad"
                                name="nombreActividad"
                                className="white"
                                errorClass={formik.errors.nombreActividad ? "error" : "success"}
                                placeholder="Escribe aquí"
                                value={formik.values.nombreActividad}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />

                            <small className="p-error">{formik.errors.nombreActividad && formik.touched.nombreActividad}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="fechaActividad">Fecha de la actividad</label>
                            <CalendarDefault
                                id="fechaActividad"
                                name="fechaActividad"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={new Date(formik.values.fechaActividad)}
                                placeholder="DD /MM/ AAAA"
                                //disabled={!enableFProgramar}
                                classesname={"white"}
                                minDate={new Date()}
                                dateFormat="dd/mm/yy"
                            ></CalendarDefault>
                            <small className="p-error">{formik.errors.fechaActividad && formik.touched.fechaActividad}</small>
                        </div>
                        <div className="field col-12 md:col-2">
                            <label htmlFor="hora">Hora</label>
                            <InputDefault
                                id="hora"
                                name="hora"
                                className="white"
                                errorClass={formik.errors.hora && formik.touched.hora? "error" : "success"}
                                placeholder="HH:mm"
                                value={formik.values.hora}
                                onChange={(e) => {
                                    if (e.target.value.length == 0) formik.setFieldValue("hora", "");

                                    let primero = /^(1[0-2]|0?[1-9])$/;
                                    if (primero.test(e.target.value)) formik.setFieldValue("hora", e.target.value);
                                    let segundo = /^(1[0-2]|0?[1-9]):$/;
                                    if (segundo.test(e.target.value)) formik.setFieldValue("hora", e.target.value);

                                    let tercero = /^(1[0-2]|0?[1-9]):[0-5]$/;
                                    if (tercero.test(e.target.value)) formik.setFieldValue("hora", e.target.value);

                                    let cuarto = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
                                    if (cuarto.test(e.target.value)) formik.setFieldValue("hora", e.target.value);
                                }}
                                //onChange={formik.handleChange}
                                onBlur={(e) => {
                                    let pattern = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
                                    var valido = pattern.test(e.target.value);
                                    if (valido) formik.handleBlur(e.target.value);
                                    else {
                                        formik.setFieldValue("hora", "");
                                    }
                                }}
                                //onblur={formik.handleBlur}
                            />
                        </div>
                        <div className="field col-12 md:col-2 actividad-switch" style={{ display: "flex", alignItems: "end", marginBottom: 20 }}>
                            <InputSwitch id="inidicadorHora" name="inidicadorHora" checked={formik.values.inidicadorHora} 
                                onChange={formik.handleChange} trueValue="PM" falseValue={"AM"}></InputSwitch>
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="linkActividad">Link de la actividad</label>

                            <InputDefault
                                id="linkActividad"
                                name="linkActividad"
                                className="white"
                                errorClass={formik.errors.linkActividad && formik.touched.linkActividad? "error" : "success"}
                                placeholder="Escribe aquí"
                                value={formik.values.linkActividad}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />

                            <small className="p-error">{formik.errors.linkActividad && formik.touched.linkActividad}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="linkActividad">Color</label>

                            <InputDefault
                                id="color"
                                name="color"
                                className="white"
                                errorClass={formik.errors.color && formik.touched.color? "error" : "success"}
                                placeholder="Escribe aquí"
                                value={formik.values.color}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />

                            <small className="p-error">{formik.errors.color && formik.touched.color}</small>
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="idArea">Area</label>
                            <DropdownDefault
                                id={`idArea`}
                                name={`idArea`}
                                options={listAreas}
                                optionLabel="code"
                                errorClass={formik.touched.idArea != undefined && (formik.errors.idArea ? "error" : "success")}
                                placeholder="Seleccione"
                                value={formik.values.idArea}
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                           
                            <small className="p-error">{formik.errors.idArea && formik.touched.idArea}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="lugar">Lugar</label>
                            <InputDefault
                                id="lugar"
                                name="lugar"
                                className="white"
                                errorClass={formik.errors.lugar ? "error" : "success"}
                                placeholder="Escribe aquí"
                                value={formik.values.lugar}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />

                            <small className="p-error">{formik.errors.lugar && formik.touched.lugar}</small>
                        </div>
                    </div>
                    <div className="p-eva-footer-form">
                        <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                        <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                    </div>
                </form>
            </div>
        </div>
    );
}
 
export default EditarCalendario;