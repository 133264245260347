import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../components/TextArea/TextArea";
import Boton from "../../components/Boton/Boton";
import ToastEva from "../../components/Toast/ToastEva";
import InputIcon from "../../components/InputIcon/InputIcon";
// import {ObtenerActividadesCelebracionPorId,ActualizarClimaCelebracion} from "../../../service/ClimaService";
import { getBase64 } from "../../helpers/helpers";
import InputDefault from "../../components/InputDefault/InputDefault";
import classNames from "classnames";
import { ObtenerDirectorioPorGerencia } from "../../service/SomosinService";
import { Skeleton } from "primereact/skeleton";
import Sk_DirectorioDetalle from "../../components/Skeleton/Sk_DirectorioDetallle";
import "./Usuarios.scss"
import * as constantes from "../../constants/constantes.js";
import { Dialog } from "primereact/dialog";
import { DeleteUser } from "../../service/UsuariosService";

const UsuarioDetalle = () => {
    const navigate = useNavigate();
    const toast = useRef(null);
    let { IdGerencia,IdUsuario } = useParams();
    const [tituloPagina, settituloPagina] = useState("");
    const [directorioGerencia, setDirectorioGerencia] = useState(null);
    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [nameUserHeader, setNameUserHeader] = useState(null);
    const [idUsuarioSeleccionado, setIdUsuarioSeleccionado] = useState(null);
    const clasesnameDialog = classNames("peva-dialog",{
        "peva-dialog-delete": showDialog,
    });

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    const URLAVATARDEFAULT = constantes.URLAVATARDEFAULT;
    useEffect(()=>{
        if(permisos.length >0)
        {
            if(permisos.indexOf("editarUsuario") > -1) setPermisoEditar(true)
        }
    },[permisos])
    const [usersGerencia, setUsersGerencia] = useState(null);

    useEffect(()=>{
        const ObtenerDirectorioGerencia =async ()=>{
            //await GetGrupo({idGerencia});
            let jwt = window.localStorage.getItem("jwt");
            let idUsuario = 0;
            let idGerencia = IdGerencia?IdGerencia : 0;
            await ObtenerDirectorioPorGerencia({jwt,idGerencia,idUsuario}).then((data) => setDirectorioGerencia(data));
         }
        if(IdGerencia ){
            ObtenerDirectorioGerencia();
        } 
    },[IdGerencia])
    useEffect(()=>{
        if(directorioGerencia)settituloPagina(directorioGerencia.nombreGerencia)
        
    },[directorioGerencia])

    const onDeleteUser = (id,nombre)=>{
        
        setIdUsuarioSeleccionado(id);
        setNameUserHeader(nombre)
        setShowDialog(true)
    }

    const Eliminar =()=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idUsuarioSeleccionado;
        DeleteUser({id,jwt}).then(data=>{
            //formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro eliminado exitosamente.", life: 4000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            //formik.setSubmitting(false)
        })
    }

    const footerDelet = 
                <div className="p-eva-footer-form">
                    <Boton nombre="Cancelar" color="secondary" metodoClick={()=>setShowDialog(false)} widths={210}/>
                    <Boton nombre="Eliminar colaborador" color="primary" metodoClick={Eliminar} widths={210}/>
                </div>
    return (
        <div className="eva-dashboard-usuarioDetalle" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div className="header">{directorioGerencia ? <label htmlFor="header">{tituloPagina}</label> : <Skeleton width="150px" height="45px" borderRadius="16px"></Skeleton>}</div>
            </div>
            <div className="eva-dashboard-body">
                <div className="p-fluid formgrid grid">
                    {directorioGerencia ? (
                        directorioGerencia.listaAreas.map((area, i) => {
                            return (
                                <>
                                    {area.listaUsuarios.map((usuario, j) => {
                                        return (
                                            // <div className="field col-12 md:col-4" key={j} style={{marginBottom:60}}>
                                            //     <ContactCard foto={usuario.nombreFoto}
                                            //         idUser={usuario.idUsuario}
                                            //         nombreUser={usuario.nombreUsuario}
                                            //         puesto={usuario.puesto}
                                            //         email={usuario.correo}
                                            //         gerencia={tituloPagina}
                                            //         area={area.nombreArea}
                                            //         />
                                            // </div>
                                            <div className="field col-12 md:col-4 " key={j} style={{ color: "#000000" }}>
                                                <div className="card peva-card-content">
                                                    <div className="peva-profile">
                                                        <img className="peva-profile-image" src={usuario.nombreFoto === null || usuario.nombreFoto === "" ? URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR + "/" + usuario.idUsuario +"/" +usuario.nombreFoto  }></img>
                                                    </div>
                                                    <div className="peva-profile-name">
                                                        <span>{usuario.nombreUsuario}</span>
                                                    </div>
                                                    {permisoEditar && (
                                                        <div className="peva-profile-edit">
                                                            <div style={{cursor:"pointer"}} onClick={()=>navigate("../Usuario/Editar/"+usuario.idUsuario)}>
                                                                <Iconsax.Edit2 color="#F47647" />
                                                            </div>
                                                            <div    onClick={()=>onDeleteUser(usuario.idUsuario,usuario.nombreUsuario)}style={{cursor:"pointer"}}>
                                                                <Iconsax.Trash color="#F47647" />
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="peva-profile-email">
                                                        <i className="peva-profile-icon">
                                                            <Iconsax.Sms color="#F47647" />
                                                        </i>
                                                        <span>{usuario.correo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            );
                        })
                    ) : (
                        <div className="field col-12 md:col-12 ">
                            <Sk_DirectorioDetalle />
                        </div>
                        
                    )}
                </div>
            </div>
            

            <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                footer={footerDelet}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">Quieres eliminar a {nameUserHeader} de la plataforma InLearning?</label>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>
        </div>
    );
}
 
export default UsuarioDetalle;