import React, { useState, useEffect, useRef } from 'react';
import {useNavigate,Link,useParams} from 'react-router-dom';
import { Card } from 'primereact/card';
import { ListBox } from 'primereact/listbox';
import Boton from '../../Boton/Boton';
import "./Colaboradores.scss"
import * as constantes from "../../../constants/constantes.js";
const Colaboradores = ({usuarios,onClick,EsAdmin,tipoGrupo}) => {
    const [coloboradorSeleccionado, setColoboradorSeleccionado] = useState(null);
    const ColaboradoresTemplate = (option) => {
        return (
            <div className="colaborador-item">
                <img alt={option.name} src={option.foto?constantes.URLBLOB_ADJ_AVATAR+"/"+option.id+"/"+option.foto : constantes.URLAVATARDEFAULT} style={{borderRadius:"50%",width:40,marginRight:8}}
                    onError={(e) => (e.target.src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png")} 
                    className={`flag flag-${option.code.toLowerCase()}`} />
                <div>{option.name}</div>
            </div>
        );
    }

    
    const header = 
        <div  className="p-eva-tituloCard "><label className='header-subtitle'>Colaboradores</label></div>
        ;
    
    const footer= 
        <div className='p-eva-colabpradores-footer'>
            {(EsAdmin  && tipoGrupo == "Privado")&& <Boton nombre={"Añadir colaborador"} color="secondary" widths='100%' metodoClick={onClick}></Boton>}
        </div>
    return ( 
        <Card header={header} className="p-eva-cardColaboradores" footer={footer}>
            <div className='p-eva-listaColaboradores'>
                <ListBox value={coloboradorSeleccionado} options={usuarios} onChange={(e) => setColoboradorSeleccionado(e.value)}  optionLabel="name"
                itemTemplate={ColaboradoresTemplate} style={{ width: '15rem' }} listStyle={{ maxHeight: '250px' }} />
            </div>
        </Card>
     );
}
 
export default Colaboradores;