import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionLiderazgo,ListarSeccionCapacitacion } from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import "./CapaLiderazgo.scss"
import Boton from "../../../components/Boton/Boton";
import * as constantes from "../../../constants/constantes";
import CardEntry from "../../../components/CardEntryEva/CardEntry";
const CapaLiderazgo = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [capaLiderazgo, setCapaLiderazgo] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarCapaLiderazgo") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verCapaIntroduccion") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])
    useEffect(()=>{
        const obtenerListaSeccionCapa = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccionCapacitacion({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccion);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccion){
            obtenerListaSeccionCapa();
        } 
    },[idSeccion])

    useEffect(()=>{
        const getCapaLiderazgo = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            ObtenerCapacitacionLiderazgo({jwt}).then(data=>setCapaLiderazgo(data))
            .catch(errors => {
                toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

            })
        }
        if(!capaLiderazgo)getCapaLiderazgo();
    },[])

    const hadleButtonCard =(id)=>{
        navigate("../Capacitacion/Seccion/" + idSeccion + "/DetalleLiderazgo/"+id);
    }

    return ( 
        <div className="eva-capaLiderazgo" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    {permisoEditar && <div className="header-right"><Link to={"../Capacitacion/Seccion/" + idSeccion + "/CrearLiderazgo"}>Crear nuevo programa de liderazgo</Link></div>}
                </div>
            </div>
            <div className="p-eva-dashboard-capaLiderazgo" style={{ marginTop: 16 }}>
                {/* <div>aqui va el combo de filtro</div> */}
                <div className="capaLiderazgo-card">
                    <div className="p-fluid formgrid grid">
                        {
                            capaLiderazgo ? 
                                (
                                    capaLiderazgo.length >0?
                                        capaLiderazgo.map((item,i)=>{
                                            return(
                                                <div className="col-12 md:col-3" key={i}>
                                                    <CardEntry tituloCard={item.nombre} 
                                                        nombreBoton="Ver más" 
                                                        imagenUrl={"../../../../images/inicio/ondas.png"} 
                                                        opacity={1} onClicButton={() => hadleButtonCard(item.id)} />
                                                </div>
                                            )
                                        })
                                    :"No existe data"
                                )
                            :<Skeleton/>      
                        }
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default CapaLiderazgo;