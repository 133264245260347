import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import loginService from "../service/LoginService";
import {UpdatePerfil,UpdateImagePerfil} from '../service/PerfilService';
export default function usePassword(){
    const {perfil,jwt,setPerfil,setJwt} = useContext(Context)
    const [stateUpdate,setstateUpdate] = useState()
    const [stateUpdateImage,setstateUpdateImage] = useState()
    

    const updatePerfil = useCallback(({jsonPerfil})=>{
        UpdatePerfil({jsonPerfil,jwt})
        .then(data => {
            console.log("success: "+data);
            setstateUpdate(true)
            setPerfil(data)
        })
        .catch(err =>{
            console.error("error: "+err);
            setstateUpdate(false)
        })
    },[setstateUpdate])

    const updatePerfilImage = useCallback(({jsonPerfilImage})=>{
        UpdateImagePerfil({jsonPerfilImage,jwt})
        .then(data => {
            console.log("success: "+data);
            setstateUpdateImage(true)
            setPerfil(data)
        })
        .catch(err =>{
            console.error("error: "+err);
            setstateUpdateImage(false)
        })
    },[setstateUpdateImage])

    return{
        isUpdate: stateUpdate,
        isUpdateImage : stateUpdateImage,
        updatePerfil,
        updatePerfilImage,
        perfilUpdate: perfil
    }
}