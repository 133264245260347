import React, { useState } from "react";
import "./CardGerencia.scss";
import { Card } from 'primereact/card';
import Boton from "../Boton/Boton";
import * as Iconsax from "iconsax-react";
const CardGerencia =({NombreGerencia="Nombre Gerencia", colaboradores="" ,onClicButton})=>{

    const footer = <span>
         <div className="p-fluid formgrid grid">
             <div className="field col-12 md:col-6"/>
            <div className="field col-12 md:col-6" style={{marginBottom:"0px"}}>
                <Boton nombre={"Entrar"} color="primary" widths="100%" icon="pi pi-angle-right" metodoClick={onClicButton}/>
            </div>
         </div>
        
    </span>;
    return(
        <Card title={NombreGerencia} footer={footer} className="eva-card-gerencia">
            {colaboradores}
        </Card>
    )
}

export default CardGerencia;