
import React, { useState, useEffect ,useRef} from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import * as Iconsax from "iconsax-react";
// import { replace, useFormik ,FieldArray,Field,Formik} from 'formik';
import {FieldArray, Field, Form, Formik, FormikProps ,useFormik} from 'formik';

import * as Yup from "yup";
import InputDefault from '../../../components/InputDefault/InputDefault';
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import Boton from "../../../components/Boton/Boton";
import { ListarEvaluaciones,ListarEvaluacionesUsuario ,RegistrarEvaluaciones} from "../../../service/DesarrolloService";
import TextareaDefault from "../../../components/TextArea/TextArea";
const EditarEvaluacion = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Editar evaluación de desempeño");
    const [listaEvaluaciones, setListaEvaluaciones] = useState([]);
    const [timeLine, setTimeLine] = useState(null);
    const mesRef = useRef([])
    const calendarRef = useRef([])

    let today = new Date();
    let year = today.getFullYear();
    const comboMes= [{code: "1",value:"Enero"},
        {code: "2",value:"Febrero"},
        {code: "3",value:"Marzo"},
        {code: "4",value:"Abril"},
        {code: "5",value:"Mayo"},
        {code: "6",value:"Junio"},
        {code: "7",value:"Julio"},
        {code: "8",value:"Agosto"},
        {code: "9",value:"Setiembre"},
        {code: "10",value:"Octubre"},
        {code: "11",value:"Noviembre"},
        {code: "12",value:"Diciembre"}
    ]

    const comboSeguir = 
    [
        {code:"Feedback Evaluacion 1",value:"Feedback Evaluacion 1"},
        {code:"Feedback Evaluacion 2",value:"Feedback Evaluacion 2"},
        {code:"Feedback Evaluacion 3",value:"Feedback Evaluacion 3"},
    ]

    
      const schema = Yup.object().shape({
        sigueAhora: Yup.string().required("Sigue ahora requerido."),
        textoBoton: Yup.string().required("Texto de botón requerido."),
        link: Yup.string().required("Link de botón requerido."),
        evaluaciones: Yup.array()
            .of(
                Yup.object().shape({
                    idEvaluacion:Yup.string().required("Evaluacion requerida."),
                    mesEtapa:Yup.string().required("Mes Requerido."),
                    // fechaFinalizacion : Yup.string().required("Fecha de finalización requerida."),
                    fechaFinalizacion :  Yup.string().when('mesEtapa',(mesEtapa)=>{
                        if(mesEtapa)
                        {
                            let mesSeleccionado = comboMes.filter(x=>x.value == mesEtapa);
                            let month = mesSeleccionado[0].code -1;
                            let year = today.getFullYear();
                            let minDate = new Date();
                            minDate.setDate(1);
                            minDate.setMonth(month);
                            minDate.setFullYear(year);
                            return Yup.date().required("Fecha de finalización requerida.").min(minDate,"No puedes es coger una fecha menor")
                        }
                        
                    })
                            
                   
                })

            ).min(1, "Debe Registrar al menos una evaluación")
      });
    
    useEffect(()=>{
        const ObtenerEvaluaciones =async ()=>{
            
            let jwt = window.localStorage.getItem("jwt");
           await ListarEvaluaciones({jwt}).then(data=>setListaEvaluaciones(data))
           
        }
        ObtenerEvaluaciones();

    },[])


    useEffect(()=>{
        const ObtenerTimeline =async ()=>{
            
            let jwt = window.localStorage.getItem("jwt");
           await ListarEvaluacionesUsuario({jwt}).then(data=>
            {
                let id = data.id; 
                let sigueAhora = data.sigueAhora;
                let textoBoton = data.textoBoton;
                let link = data.link;
                let tipoOperacion = "U"
                let evaluaciones =[]
                data.etapas.map((etapa,i)=>{
                    
                    etapa.evaluaciones.map((evaluacion,j)=>{
                        
                        evaluaciones.push({
                            id:evaluacion.id,
                            idEvaluacion : evaluacion.idEvaluacion,
                            mesEtapa : etapa.nombreEtapa,
                            fechaFinalizacion : evaluacion.fechaFinalizacion,
                            //fechaFinalizacion : "1/1/2023",
                            
                            tipoOperacion:"U"
                        });
                    })
                })

                let json = JSON.stringify({id,sigueAhora,textoBoton,link,tipoOperacion,evaluaciones},null,2);

                //alert(json)
                setTimeLine({
                    id:data.id,
                    sigueAhora:data.sigueAhora,
                    textoBoton:data.textoBoton,
                    link:data.link,
                    tipoOperacion:'U',
                    descripcion : data.descripcion,
                    evaluaciones: evaluaciones
                });
            
            })
           
        }
        ObtenerTimeline();

    },[])

    const handleMonth =(e,index)=>{
        let mesSeleccionado = comboMes.filter(x=>x.value == e.value)
        let today = new Date();
        let month = mesSeleccionado[0].code -1;
        let year = today.getFullYear();
        let nextYear = (month === 11) ? year + 1 : year;

        let minDate = new Date();
            minDate.setMonth(month);
            minDate.setFullYear(year);

        let maxDate = new Date();
            maxDate.setMonth(11);
            maxDate.setFullYear(nextYear);

        calendarRef.current[index].props.minDate = minDate;
        calendarRef.current[index].props.maxDate = maxDate;
        calendarRef.current[index].props.viewDate = minDate
        
    }
    return ( 
        <div className="eva-dashboard-editarEvaluacion">
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label>{tituloPagina}</label>
                </div>
            </div>  
            <div className="eva-dashboard-body">
                
                    
                        <Formik
                    enableReinitialize={true}
                    initialValues={timeLine ?timeLine:{    
                                        id:0,
                                        sigueAhora:'',
                                        textoBoton:'',
                                        link:'',
                                        tipoOperacion:'A',
                                        descripcion:'',
                                        evaluaciones: [
                                            {
                                                id : 0,
                                                idEvaluacion: '',
                                                mesEtapa: '',
                                                fechaFinalizacion: '',
                                                tipoOperacion:'A'
                                            },
                                        ] }}
                    validationSchema={schema}
                    onSubmit={(values, actions) => {
                        
                        //alert(JSON.stringify(values))
                        let jwt = window.localStorage.getItem("jwt");
                        let jsonEvaluacion = JSON.stringify(values)
                        RegistrarEvaluaciones({jsonEvaluacion,jwt}).then(data=> navigate(-1))
                    
                    }}
                    >
                    {({ values ,handleSubmit,handleChange,handleBlur,errors,touched}) => (

                            <form onSubmit={handleSubmit}>
                                 <div className="p-fluid formgrid grid ">
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="descripcion" className="p-eva-labelForm">
                                            Añade una descripción
                                        </label>
                                        <TextareaDefault
                                            id="descripcion"
                                            minHeight={180}
                                            placeholder="Escribe aquí"
                                            className="White"
                                            value={values.descripcion}
                                            icon={<Iconsax.DocumentText1 />}
                                            name="descripcion"
                                            onChange={handleChange}
                                            onblur={handleBlur}
                                        />
                                    </div>
                                 </div>
                                <FieldArray name="evaluaciones">
                                    {({ insert, remove, push }) => (
                                        <div>
                                        
                                            {values.evaluaciones&&
                                                values.evaluaciones.length >0 &&
                                                    values.evaluaciones.map((item,index)=>(
                                                        <div className="p-fluid formgrid grid editarEvaluacion-lista-evaluaciones" key={index}>
                                                            <div className="field col-12 md:col-4" >
                                                                <label htmlFor={`evaluaciones.${index}.mesEtapa`}>Seleccione el mes</label>
                                                                <Field
                                                                    name={`evaluaciones.${index}.mesEtapa`} 
                                                                    >
                                                                    {({
                                                                        field, // { name, value, onChange, onBlur }
                                                                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                                        meta,
                                                                        }) => (
                                                                            <>
                                                                            {console.log(field)}
                                                                                <DropdownDefault 
                                                                                    key={item}
                                                                                    placeholder="Selecciona"
                                                                                    errorClass={meta.touched && 
                                                                                        (meta.error ? "error" : "success")}  
                                                                                    options={comboMes} optionLabel="value"
                                                                                    ref={(e)=>mesRef.current[index]=e}

                                                                                   
                                                                                    {...field}

                                                                                    onChange={(e)=>(field.onChange(e),handleMonth(e,index))}
                                                                                />
                                                                                <p className="error">{meta.error && meta.touched    && meta.error}</p>

                                                                            </>
                                                                            
                                                                        )}

                                                                </Field>
                                                            </div>
                                                            <div className="field col-12 md:col-4" >
                                                                <label htmlFor={`evaluaciones.${index}.idEvaluacion`}>Seleccione la evaluación</label>
                                                                <Field
                                                                    name={`evaluaciones.${index}.idEvaluacion`} 
                                                                    >
                                                                    {({
                                                                        field, // { name, value, onChange, onBlur }
                                                                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                                        meta,
                                                                        }) => (
                                                                            
                                                                            <>
                                                                            
                                                                            <DropdownDefault 
                                                                                placeholder="Selecciona"
                                                                                errorClass={meta.touched && 
                                                                                        (meta.error ? "error" : "success")}  
                                                                                options={listaEvaluaciones} optionLabel="nombre" optionValue="id"
                                                                                {...field}
                                                                            />
                                                                                <p className="error">{meta.error && meta.touched    && meta.error}</p>

                                                                            </>
                                                                            
                                                                        )}

                                                                </Field>
                                                            </div>
                                                            <div className="field col-12 md:col-4" >
                                                                <label htmlFor={`evaluaciones.${index}.fechaFinalizacion`}>Fecha en la que finaliza la evaluación</label>
                                                                <Field
                                                                    id={`evaluaciones.${index}.fechaFinalizacion`} 
                                                                    name={`evaluaciones.${index}.fechaFinalizacion`} 
                                                                    
                                                                    >
                                                                    {({
                                                                        field, // { name, value, onChange, onBlur }
                                                                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                                        meta,
                                                                        }) => (
                                                                            <>
                                                                            
                                                                                <CalendarDefault placeholder="dd/mm/aaaa" dateFormat="dd/mm/yy" 
                                                                                    ref={(e)=>calendarRef.current[index]=e}
                                                                                    errorClass={meta.touched && 
                                                                                        (meta.error ? "error" : "success")}  
                                                                                    {...field} 
                                                                                    id={`evaluaciones.${index}.fechaFinalizacion`} 
                                                                                    value={field.value.length > 0&&new Date(field.value)} 
                                                                                    readOnlyInput
                                                                                    ></CalendarDefault>
                                                                                <p className="error">{meta.error && meta.touched    && meta.error}</p>

                                                                            </>
                                                                            
                                                                        )}

                                                                </Field>         
                                                            </div>
                                                            <div className="field col-12 md:col-12 p-eva-linkText" style={{justifyContent:"end"}}>

                                                                <i style={{cursor:"pointer"}} onClick={()=>{
                                                                    remove(index)
                                                                }}>Eliminar</i>
                                                            </div>
                                                            {index != values.evaluaciones.length-1 && <div className="field col-12 md:col-12" style={{height:2,background:"#000000",opacity:0.1,marginTop:10}}></div>}
                                                        </div>
                                                        
                                                    ))
                                            }
                                            <div className="p-eva-linkText" style={{display:"flex",justifyContent:"end"}}>
                                                <i style={{cursor:"pointer"}} onClick={() => push({
                                                            id : 0,
                                                            idEvaluacion: '',
                                                            mesEtapa: '',
                                                            fechaFinalizacion: '',
                                                            tipoOperacion:'A'
                                                        })}>Añadir más</i>
                                            </div>
                                            <div style={{height:2,background:"#000000",opacity:0.1,marginTop:24,marginBottom:24}}></div>

                                        </div>
                                    )}
                                </FieldArray>
                                
                                <div className="editarEvaluacion-sigueAhora">
                                    <div className="p-fluid formgrid grid ">
                                        <div className="field col-12 md:col-12" >
                                            <label htmlFor="sigueAhora">Sigue ahora</label>
                                            {/* <DropdownDefault id="sigueAhora" name="sigueAhora" options={listaEvaluaciones} optionLabel="nombre" optionValue="nombre"
                                            errorClass={touched.sigueAhora != undefined&& (errors.sigueAhora?"error":"success")} placeholder="Seleccione" 
                                            value={values.sigueAhora} onChange={handleChange} onblur={handleBlur}/>
                                            <p className="error">{errors.sigueAhora && touched.sigueAhora && errors.sigueAhora}</p> */}
                                            <InputDefault id="sigueAhora" name="sigueAhora" className="white" placeholder="Escribe aquí"
                                            errorClass={touched.sigueAhora != undefined && (errors.sigueAhora ? "error" : "success")} 
                                            value={values.sigueAhora} onChange={handleChange} onBlur={handleBlur} />
                                            <p className="error">{errors.sigueAhora && touched.sigueAhora && errors.sigueAhora}</p>
                                        </div>
                                        <div className="field col-12 md:col-4" >
                                            <label htmlFor="textoBoton" className="p-eva-labelForm">Escribe el texto del boton</label>
                                            <InputDefault id="textoBoton" name="textoBoton" className="white" placeholder="Escribe aquí"
                                            errorClass={touched.textoBoton != undefined && (errors.textoBoton ? "error" : "success")} 
                                            value={values.textoBoton} onChange={handleChange} onBlur={handleBlur} />
                                            <p className="error">{errors.textoBoton && touched.textoBoton && errors.textoBoton}</p>
                                        </div>
                                        <div className="field col-12 md:col-4" >
                                            <label htmlFor="link" className="p-eva-labelForm">Insertar link</label>
                                            <InputDefault id="link" name="link" className="white" placeholder="Escribe aquí"
                                            errorClass={touched.link != undefined && (errors.link ? "error" : "success")} 
                                            value={values.link} onChange={handleChange} onBlur={handleBlur} />
                                            <p className="error">{errors.link && touched.link && errors.link}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="editarEvaluacion-footer">
                                    <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={()=>navigate(-1)}></Boton>
                                    <Boton nombre={"Actualizar"} color="primary" type="submit" widths="184px"></Boton>
                                </div>
                            </form>
                    )}
                        </Formik>
                
            
                
            </div>
        </div>
     );
}
 
export default EditarEvaluacion;