import React, { useState, useEffect } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import { Timeline } from 'primereact/timeline';
import { ProgressBar } from 'primereact/progressbar';
import "./HomeEvaluacion.scss"
import useUsuario from "../../../hooks/useUsuario";
import { ListarEvaluacionesUsuario} from "../../../service/DesarrolloService";
import Boton from "../../../components/Boton/Boton";

const HomeEvaluacion = () => {
    const navigate = useNavigate();
    const [timeline, setTimeline] = useState(null);
    const {permisos,isLogged}= useUsuario();
    const [permisoEditar, setPermisoEditar] = useState(false);


    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarEvaluaciones") > -1) {
                setPermisoEditar(true);
                
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verEvaluaciones") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        const ObtenerTimeline =async ()=>{
            
            let jwt = window.localStorage.getItem("jwt");
           await ListarEvaluacionesUsuario({jwt}).then(data=>setTimeline(data))
           
        }
        ObtenerTimeline();

    },[])
    const events = [
        { nombreEtapa: 'Enero', estado :'Finalizado', evaluaciones:[{nombreEvaluacion:"Retro de fin de año",estado:"Evaluado"}]},
        { nombreEtapa: 'Febrero', estado :'Finalizado', evaluaciones:[{nombreEvaluacion:"KPIs 2023",estado:"Evaluado"}] },
        { nombreEtapa: 'Marzo',  estado :'Finalizado', evaluaciones:[{nombreEvaluacion:"Medición potencial",estado:"Evaluado"}] },
        { nombreEtapa: 'Abril',  estado :'Actual', evaluaciones:[{nombreEvaluacion:"Evaluación 361",estado:"Evaluado"},
                                                            {nombreEvaluacion:"Evaluación 362",estado:"En proceso"},
                                                             {nombreEvaluacion:"Evaluación 363",estado:"En proceso"}]},
        { nombreEtapa: 'Mayo', estado :'Pendiente', evaluaciones:[{nombreEvaluacion:"Evaluación",estado:"Pendiente"}]},
        { nombreEtapa: 'Junio',  estado :'Pendiente', evaluaciones:[{nombreEvaluacion:"Feedback medio año",estado:"Pendiente"}] },
        // { nombreEtapa: 'Julio',  estado :'Finalizado', evaluaciones:[{nombreEvaluacion:"Feedback medio año",estado:"Pendiente"}] },
        // { nombreEtapa: 'Agosto',  estado :'Actual', evaluaciones:[{nombreEvaluacion:"Feedback medio año",estado:"Pendiente"}] },
        // { nombreEtapa: 'Setiembre',  estado :'Pendiente', evaluaciones:[{nombreEvaluacion:"Feedback medio año",estado:"Pendiente"}] },
        // { nombreEtapa: 'Octubre',  estado :'Pendiente', evaluaciones:[{nombreEvaluacion:"Feedback medio año",estado:"Pendiente"}] },
        // { nombreEtapa: 'Noviembre',  estado :'Pendiente', evaluaciones:[{nombreEvaluacion:"Feedback medio año",estado:"Pendiente"}] },
        // { nombreEtapa: 'Diciembre',  estado :'Pendiente', evaluaciones:[{nombreEvaluacion:"Feedback medio año",estado:"Pendiente"}] },

    ];
    const totalItems = events.length;

    const numberOfActiveItems = events.filter(o => o.estado =='Finalizado').length;
    const progressBarWidth =
      totalItems > 1 && numberOfActiveItems > 0
        ? ((numberOfActiveItems+1) / (totalItems))*100
        : 0;

    const handleClicMes =(nombre)=>{
        alert(nombre)
    }

    const customizedMarker = (item) => {
        return (
            <>
             <div className={"p-eva-timeLineMarker "+ item.estado} style={{cursor:"pointer"}} onClick={()=> handleClicMes(item.nombreEtapa)}>
                
                <div><span className="font-bold text-sm" style={{color:"white",lineHeight:"16px"}}>{item.nombreEtapa}</span></div>
                
            </div>
            <div className={"p-timeline-event-connectorEva " + item.estado}></div>
            </>
           
        );
    };
    const customizedContent = (item) => {
        let html = ""
        html =item.evaluaciones.map((content,i)=>{
            return(
                <div className={"p-eva-timeLineContenido "+item.nombreEtapa} key={i}>
                    <span className={"timeLineContenido-evaluacion "+item.estado}>{content.nombreEvaluacion}</span>
                    <span className={"timeLineContenido-estado "+item.estado}>{content.estado}</span>
                </div>
            )
         })
        return (
            <>
            {html}
            </>
            
           
        );
    };
    return (
        <>
        <div style={{whiteSpace:"pre-line",marginTop:12,color:"#000000"}}>{timeline && timeline.descripcion}</div>
         <div className="p-eva-timeLine">
            {
                timeline &&
                <>
                    {/* <ProgressBar value={progressBarWidth} color="#00B560" aria-aria-valuemax={12} style={{ height: 2, marginLeft: 70, marginRight: 70}} showValue={false} /> */}
                    <Timeline className="p-eva-timeLine-items" 
                    value={timeline.etapas} 
                    
                    //value={events} 
                    content={customizedContent} layout="horizontal" marker={customizedMarker} style={{ marginTop: -48 }} />

                    
                </>
                
            }
            
            
        </div>
        {
            timeline &&
            <div className="timeline-seccion-link">
                <div className="seccion-link-texto">{timeline.sigueAhora}</div>
                <div style={{display:"flex",justifyContent:"center"}}><Boton nombre={timeline.textoBoton} color="secondary" widths="248px"
                    metodoClick={() => window.open(timeline.link.indexOf("http") > -1 ? timeline.link : "http://"+timeline.link, "_blank", "noopener,noreferrer")}
                ></Boton>
                </div>
                        
            </div>
        }
        
        </>
       
    );
}
 
export default HomeEvaluacion;