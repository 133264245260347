import { registry } from 'chart.js';
import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import {Actualizar,ObtenerReglamentosPorSeccion,ObtenerSeccion,Registrar,ObtenerReglamentoId,RegistrarReglamentoUsuario, ObtenerReglamentosPorGerencia}from "../service/SomosinService";
export default function UseSomosin(){
    const {perfil,jwt,setPerfil,setJwt} = useContext(Context)
    const [stateCreate,setStateCreate] = useState({loading:false,error:false,success:false});
    const [stateUpdate, setStateUpdate] = useState({loading:false,error:false,success:false});
    const [seccion, setSeccion] = useState([]);
    const [reglamentoPorSeccion, setReglamentoPorSeccion] = useState([]);
    const [reglamento, setReglamento] = useState(null);
    const RegistrarReglamento = useCallback(({jsonReglamento})=>{
        Registrar({jsonReglamento,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
        })
    },[setStateCreate])

    const ActualizarReglamento = useCallback(({jsonReglamento})=>{
        Actualizar({jsonReglamento,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateUpdate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateUpdate({loading:false,error:true,success:false})
        })
    },[setStateUpdate])

    const GetSeccion = useCallback(async ()=>{
        await ObtenerSeccion({jwt})
        .then(data=>{
            setSeccion(data);
        })
        .catch(err=>{
            setSeccion([])
        })

    },[setSeccion])

    const GetReglamentoPorSeccion = useCallback(async ()=>{
        await ObtenerReglamentosPorGerencia({jwt})
        .then(data=>{
            setReglamentoPorSeccion(data);
        })
        .catch(err=>{
            setReglamentoPorSeccion([])
        })

    },[setReglamentoPorSeccion])
    const GetReglamentoPorId = useCallback(async ({id})=>{
        await ObtenerReglamentoId({id,jwt})
        .then(data=>{
            setReglamento(data);
        })
        .catch(err=>{
            setReglamento(null)
        })

    },[setReglamento])

    const RegistrarAceptacion = useCallback(({idReglamento})=>{
        RegistrarReglamentoUsuario({idReglamento,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
        })
    },[setStateCreate])

    return{
        RegistrarReglamento,
        hasCreateError : stateCreate.error,
        isCreateLoading: stateCreate.loading,
        isCreated : stateCreate.success,
        RegistrarAceptacion,
        hasCreateAceptacionError : stateCreate.error,
        isCreateAceptacionLoading: stateCreate.loading,
        isCreatedAceptacion: stateCreate.success,
        seccion,
        GetSeccion,
        reglamentoPorSeccion,
        GetReglamentoPorSeccion,
        reglamento,
        GetReglamentoPorId,
        ActualizarReglamento,
        hasUpdateError : stateUpdate.error,
        isUpdateLoading: stateUpdate.loading,
        isUpdate : stateUpdate.success,
    }
}
