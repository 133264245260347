import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
import { ObtenerClimaReconocimiento,ActualizarReconocimiento} from "../../../service/ClimaService";
import { getBase64 } from "../../../helpers/helpers";
import classNames from "classnames";
import { InputSwitch } from "primereact/inputswitch";
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import { ListUsersTag } from "../../../service/UsuariosService";

const EditarReconocimiento = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState("Editar reconocimiento");
    const [modoEdicion, setModoEdicion] = useState(true);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    const [reconocimientos, setReconocimientos] = useState(null);
    const [listUserTotal, setListUserTotal] = useState([]);
    const [selected, setSelected] = useState([]);


    useEffect(()=>{
        if(listUserTotal.length === 0)
        {
            let jwt = window.localStorage.getItem("jwt");
            ListUsersTag(jwt).then(data => setListUserTotal(data))
        }
        

     },[]);
    useEffect(()=>{
        const getReconocimiento = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerClimaReconocimiento({jwt}).then(data => 
                {
                    setReconocimientos(data)

                })
        }
        if(!reconocimientos)getReconocimiento()
    },[])


    

    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
        arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: "", tipo: "Adjunto", estado: true })

    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const Actualizar =({jsonReconocimiento})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarReconocimiento({jsonReconocimiento,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    const schema = Yup.object().shape({
        descripcion: Yup.string().required("Campor descripción requerido."),
        descripcionCategoria: Yup.string().required("Campor descripcionCategoria requerido."),
        categoria: Yup.array()
        .of(
            Yup.object().shape({
                nombre : Yup.string().nullable().required("Campo nombre requerido."),
                descripcion :Yup.string().nullable().required("Campo descripción requerido.")
            })
        )
    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:reconocimientos && reconocimientos.id,
            descripcion: reconocimientos && reconocimientos.descripcion,
            descripcionCategoria: reconocimientos && reconocimientos.descripcionCategoria,
            // categoria : [{id:0,idClimaReconocimiento:0,nombre:"",nombreFoto:"",descripcion :"",imagenBase64:null,tipoDocumento:null,
            //             listaDocCompleto:[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true}],
            //             clima_reconocimiento_categoria_usuario:[{id:0,idClimaReconocimientoCategoria:0,idUsuario:0}],
            //             selected :[]
            //             }],
            categoria:[],
            Titulo : reconocimientos? reconocimientos.titulo:"",
            SubTitulo1 : reconocimientos? reconocimientos.subTitulo1:"",
            SubTitulo2 : reconocimientos? reconocimientos.subTitulo2:"",

            
        },
      validationSchema: schema,
      onSubmit: values => {
       

        let id = values.id
        let descripcion = values.descripcion
        let descripcionCategoria = values.descripcionCategoria
        let clima_reconocimiento_categoria_completo = values.categoria
        let Titulo =values.Titulo;
        let SubTitulo1 =values.SubTitulo1;
        let SubTitulo2 =values.SubTitulo2;

        let jsonReconocimiento = JSON.stringify({id,descripcion,descripcionCategoria,clima_reconocimiento_categoria_completo,Titulo,SubTitulo1,SubTitulo2},null,2)
        // // //  console.log(jsonCapacitacion)
        // //  formik.setSubmitting(false)
        // //  alert(jsonClima);
        
         Actualizar({jsonReconocimiento})
        
        
      },
    });

    return ( 
        <div className="eva-dashboard-editarReconocimiento" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                {/* <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div> */}
                <div><label htmlFor="header">{"Crear " + formik.values.Titulo}</label></div>
            </div>
            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el título</label>
                                <InputDefault
                                    id="Titulo"
                                    name="Titulo"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.Titulo!= undefined && (formik.errors.Titulo ? "error" : "success")}
                                    value={formik.values.Titulo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.Titulo && formik.errors.Titulo}</small>
                            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.errors.descripcion && formik.touched.descripcion ? "error" : "success"}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.errors.descripcion && formik.touched.descripcion}</small>
                            </div>
                            <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>
                            {/* <div className="field col-12 md:col-12 header-subtitle">Conoce nuestras categorías de reconocimiento</div> */}
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo1}</div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el Sutítulo</label>
                                <InputDefault
                                    id="SubTitulo1"
                                    name="SubTitulo1"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo1!= undefined && (formik.errors.SubTitulo1 ? "error" : "success")}
                                    value={formik.values.SubTitulo1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo1 && formik.errors.SubTitulo1}</small>
                            </div>
                            
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcionCategoria" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcionCategoria"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.errors.descripcionCategoria && formik.touched.descripcionCategoria ? "error" : "success"}
                                    value={formik.values.descripcionCategoria}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcionCategoria"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.errors.descripcionCategoria && formik.touched.descripcionCategoria}</small>
                            </div>
                            <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>
                            {/* <div className="field col-12 md:col-12 header-subtitle">Categorías de reconocimiento</div> */}
                            {/* <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo2}</div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el Sutítulo</label>
                                <InputDefault
                                    id="SubTitulo2"
                                    name="SubTitulo2"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo2!= undefined && (formik.errors.SubTitulo2 ? "error" : "success")}
                                    value={formik.values.SubTitulo2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo2 && formik.errors.SubTitulo2}</small>
                            </div> */}
                            <FieldArray
                                name="categoria"
                                render={(arrayCategoria) => (
                                    <>
                                        {formik.values.categoria &&
                                            formik.values.categoria.map((categoria, jIndex) => (
                                                <>
                                                    {jIndex != 0 && <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>}
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`categoria[${jIndex}].nombre`}>Titulo</label>

                                                        <InputDefault
                                                            id={`categoria[${jIndex}].nombre`}
                                                            name={`categoria[${jIndex}].nombre`}
                                                            className="white"
                                                            errorClass={formik.errors.categoria && formik.touched.categoria &&
                                                                        formik.errors.categoria[jIndex] && formik.touched.categoria[jIndex] && (formik.errors.categoria[jIndex].nombre ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.categoria[jIndex].nombre}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.categoria && formik.touched.categoria &&
                                                                                formik.errors.categoria[jIndex] && formik.touched.categoria[jIndex] && formik.errors.categoria[jIndex].nombre}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`categoria[${jIndex}].nombreFoto`}>Adjunta una foto</label>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <label
                                                                className={classNames("eva-labelFileLider", {
                                                                    "p-eva-error": formik.errors.categoria && formik.errors.categoria[jIndex] && formik.errors.categoria[jIndex].nombreFoto != undefined && formik.errors.categoria[jIndex].nombreFoto,
                                                                })}
                                                                style={{ minHeight: 16, height: "fit-content" }}
                                                            >
                                                                <span>{formik.values.categoria[jIndex].nombreFoto.length > 0 ? formik.values.categoria[jIndex].nombreFoto : "Adjunta aquí"}</span>
                                                            </label>
                                                            {formik.values.categoria[jIndex].nombreFoto != "" ? (
                                                                <label className="eva-labelFileLider-icon">
                                                                    <i onClick={() => {}}>
                                                                        <Iconsax.Trash />
                                                                    </i>
                                                                </label>
                                                            ) : (
                                                                <label htmlFor={`categoria[${jIndex}].nombreFoto`} className="eva-labelFileLider-icon">
                                                                    <i>
                                                                        <Iconsax.GalleryAdd color="#F47647" />
                                                                    </i>
                                                                </label>
                                                            )}

                                                            <input
                                                                type="file"
                                                                id={`categoria[${jIndex}].nombreFoto`}
                                                                name={`categoria[${jIndex}].nombreFoto`}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`categoria[${jIndex}].nombreFoto`, e.currentTarget.files[0].name);
                                                                    formik.setFieldValue(`categoria[${jIndex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                    getBase64(e.currentTarget.files[0]).then((result) => {
                                                                        formik.setFieldValue(`categoria[${jIndex}].imagenBase64`, result);
                                                                    });

                                                                    //onFileChange(e, "document")
                                                                }}
                                                                // accept="application/pdf,audio/*,image/*"
                                                                accept="image/jpeg,image/png"

                                                                hidden
                                                            ></input>
                                                        </div>
                                                        <small className="p-error">{formik.errors.categoria && formik.errors.categoria[jIndex] && formik.errors.categoria[jIndex].imagenBase64}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`categoria[${jIndex}].descripcion`} className="p-eva-labelForm">
                                                            Añade una descripción para la categoría
                                                        </label>
                                                        <TextareaDefault
                                                            id={`categoria[${jIndex}].descripcion`}
                                                            minHeight={180}
                                                            placeholder="Escribe aquí"
                                                            className="White"
                                                            errorClass={formik.errors.categoria && formik.touched.categoria &&
                                                            formik.errors.categoria[jIndex] &&  formik.touched.categoria[jIndex] &&(formik.errors.categoria[jIndex].descripcion ? "error" : "success")}
                                                            value={formik.values.categoria[jIndex].descripcion}
                                                            icon={<Iconsax.DocumentText1 />}
                                                            name={`categoria[${jIndex}].descripcion`}
                                                            onChange={formik.handleChange}
                                                            onblur={formik.handleBlur}
                                                        />
                                                        <small className="p-error">{formik.errors.categoria && formik.touched.categoria && 
                                                                formik.errors.categoria[jIndex] && formik.touched.categoria[jIndex] && formik.errors.categoria[jIndex].descripcion}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-12" style={{marginBottom:2}}>
                                                        <label className="p-eva-labelForm">Agrega imagen</label>
                                                    </div>


                                                    <FieldArray
                                                        name={`categoria[${jIndex}].listaDocCompleto`}
                                                        render={(arrayCategoriaDoc) => (
                                                            <>
                                                             
                                                            <div className="field col-12 md:col-8">
                                                               
                                                                <div className="p-fluid formgrid grid">
                                                                    
                                                                {
                                                                    formik.values.categoria[jIndex].listaDocCompleto &&
                                                                    formik.values.categoria[jIndex].listaDocCompleto.map((documento, zIndex) => (
                                                                        <div className="field col-12 md:col-6" key={zIndex}>
                                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                                    <span>{formik.values.categoria[jIndex].listaDocCompleto[zIndex].nombreDocumento.length > 0 ? formik.values.categoria[jIndex].listaDocCompleto[zIndex].nombreDocumento : "Adjunta aquí"}</span>
                                                                                </label> */}
                                                                                <InputDefault
                                                                                    id={`categoria[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                                    name={`categoria[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                                    placeholder="Adjunta aquí"
                                                                                    className="White"
                                                                                    value={formik.values.categoria[jIndex].listaDocCompleto[zIndex].nombreDocumento}
                                                                                    onChange={(e)=>{formik.values.categoria[jIndex].listaDocCompleto[zIndex].imagenBase64 && formik.handleChange(e)}}
                                                                                    onBlur={formik.handleBlur}
                                                                                ></InputDefault>
                                                                                {formik.values.categoria[jIndex].listaDocCompleto[zIndex].nombreDocumento != "" ? (
                                                                                    <label className="eva-labelFileLider-icon">
                                                                                        <i onClick={() => onDeleteFile(zIndex, arrayCategoriaDoc)} style={{ cursor: "pointer" }}>
                                                                                            <Iconsax.Trash />
                                                                                        </i>
                                                                                    </label>
                                                                                ) : (
                                                                                    <label htmlFor={`categoria[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                                        <i>
                                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                                        </i>
                                                                                    </label>
                                                                                )}
                
                                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                                <input
                                                                                    type="file"
                                                                                    id={`categoria[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} 
                                                                                    name={`categoria[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} 
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue(`categoria[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                                        formik.setFieldValue(`categoria[${jIndex}].listaDocCompleto[${zIndex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                                            formik.setFieldValue(`categoria[${jIndex}].listaDocCompleto[${zIndex}].imagenBase64`, result);
                                                                                        });
                
                                                                                        //onFileChange(e, "document")
                                                                                    }}
                                                                                  //  accept="application/pdf,audio/*,image/*"
                                                                                  accept="image/jpeg,image/png"

                                                                                    hidden
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                </div>
                                                            </div>
                                                            {/* <div className="field col-12 md:col-4">
                                                                <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <i style={{ cursor: "pointer" }} onClick={() => arrayCategoriaDoc.push(
                                                                            {imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true}
                                                                            )}>
                                                                        Añadir más
                                                                    </i>
                                                                    <label htmlFor={"file-multiple"+jIndex} style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                                    <input
                                                                        type="file"
                                                                        id={"file-multiple"+jIndex}
                                                                        name={"file-multiple"+jIndex}
                                                                        onChange={(e) => {
                                                                            console.log(e.currentTarget.files);
                                                                            let tempFiles= e.currentTarget.files
                                                                            console.log(tempFiles)
                                                                            for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                                                let nombre = e.currentTarget.files[index].name;
                                                                                let tipo = e.currentTarget.files[index].type;
                                                                                let base64  =""
                                                                                getBase64(e.currentTarget.files[index]).then((result) => {
                                                                                    base64 = result;
                                                                                    arrayCategoriaDoc.push(
                                                                                        {imagenBase64:base64,tipoDocumento:tipo,id:0,idClimaActividadCelebacion:0,nombreDocumento:nombre,tipo:'Adjunto',estado:true}
                                                                                        )
                                                                                });
                                                                            }
                                                                        
                                                                        }}
                                                                        accept="application/pdf,audio/*,image/*"
                                                                        hidden
                                                                        multiple
                                                                    ></input>
                                                                </div>
                                                            </div> */}
                                                            </>
                                                        )}
                                                    ></FieldArray>
                                                    
                                                    <div className="field col-12 md:col-12">
                                                            <label htmlFor="nombre">Agrega el Sutítulo</label>
                                                            <InputDefault
                                                                id={`categoria[${jIndex}].subTitulo`}
                                                                name={`categoria[${jIndex}].subTitulo`}
                                                                className="white"
                                                                placeholder="Escribe aquí"
                                                                errorClass={formik.errors.categoria && formik.touched.categoria &&
                                                                formik.errors.categoria[jIndex] &&  formik.touched.categoria[jIndex] &&(formik.errors.categoria[jIndex].subTitulo ? "error" : "success")}
                                                                value={formik.values.categoria[jIndex].subTitulo}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                             <small className="p-error">{formik.errors.categoria && formik.touched.categoria && 
                                                                formik.errors.categoria[jIndex] && formik.touched.categoria[jIndex] && formik.errors.categoria[jIndex].subTitulo}</small>
                                                    </div> 
                                                    
                                                    <div className="field col-12 md:col-9">
                                                    <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                                                    <MultiselectEva
                                                        onChange={(listUser)=>{
                                                            //console.log(listUser)
                                                            let temp = []
                                                            listUser.map((item,i)=>{
                                                                temp.push({id:0,idClimaReconocimientoCategoria:0,idUsuario:item.id})
                                                            })
                                                            formik.setFieldValue(`categoria[${jIndex}].clima_reconocimiento_categoria_usuario`, temp);
                                                            formik.setFieldValue(`categoria[${jIndex}].selected`, listUser);

                                                        }}
                                                        isMulti
                                                        //options={selectButtonValues1}
                                                        options={listUserTotal}
                                                        value={formik.values.categoria[jIndex].selected}
                                                    />
                                                </div>
                                                </>

                                            ))}
                                         <div className="field col-12 md:col-12">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "center" }}>
                                                <i
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        arrayCategoria.push(
                                                            {id:0,idClimaReconocimiento:0,nombre:"",nombreFoto:"",descripcion :"",subTitulo:"",imagenBase64:null,tipoDocumento:null,
                                                                listaDocCompleto:[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true}],
                                                                clima_reconocimiento_categoria_usuario:[{id:0,idClimaReconocimientoCategoria:0,idUsuario:0}],
                                                                selected :[]
                                                                }
                                                        );
                                                        // setNumeroPregunta(numeroPregunta+1)
                                                    }}
                                                >
                                                    Añade categoría
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}>

                                </FieldArray>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
     );
}
 
export default EditarReconocimiento;