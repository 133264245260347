import React, { useState, useEffect, useRef } from 'react';
import ModalEva from '../../Modal/ModalEva';
import Boton from '../../Boton/Boton';
import TextareaDefault from '../../TextArea/TextArea';
import InputIcon from '../../InputIcon/InputIcon';
import InputDefault from '../../InputDefault/InputDefault';
import DropdownDefault from '../../DropdownDefault/Dropdown';
import CheckboxEva from '../../CheckBoxEva/CheckboxEva';
import InputPublicacion from './InputPublicacion';
import CardPublicacionCompartida from './CardPublicacionCompartida';
import { Dialog } from 'primereact/dialog';
import * as Iconsax from "iconsax-react";
import { replace, useFormik } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import * as constantes from "../../../constants/constantes.js";
import UsuariosSugeridos from "./UsuariosSugeridos"
import CalendarDefault from '../../CalendarDefault/CalendarDefault';
import usePublicacion from "../../../hooks/usePublicacion";
import {ListUsers} from "../../../service/UsuariosService";
import ToastEva from "../../../components/Toast/ToastEva.js"


const EditarPublicacion = ({mostrar,onHide,perfil,publicacionEdit,isAdmin}) => {
    const [stateImage, setStateImage] = useState({ file: null, base64URL: "" });
    const [stateImages, setStateImages] = useState([]);
    const [mostrarModalDesacartar, setMostrarModalDesacartar] = useState(false);
    const [previewMedia, setPreviewMedia] = useState(null);
    const [previewMediaArregloImg, setPreviewMediaArregloImg] = useState([]);
    const [comboDias, setComboDias] = useState([]);
    const [resetImage, setResetImage] = useState(false);
    const [nombreCorto, setNombreCorto] = useState('');
    const {ActualizarPublicacionAdjuntos,isUpdate,Eliminar,isDelete} = usePublicacion();
    const [nombreImagen, setNombreImagen] = useState(null);
    const [nombreImagenes, setNombreImagenes] = useState([]);
    const [showDialogEliminar, setShowDialogEliminar] = useState(false);
    const [enableAdjunto, setEnableAdjunto] = useState(true);
    const [enableLinkVideo, setEnableLinkVideo] = useState(true);
    const mesActual = new Date().getMonth()
    const diaActual = new Date().getDate()
    const horaActual = new Date().getHours()
    const [enableFProgramar, setEnableFProgramar] = useState(false);
    const [publicacionCompletaAdjunto, setpublicacionCompletaAdjunto] = useState([]);
    const [usuariosSugeridos, setUsuariosSugeridos] = useState([]);
    const toast = useRef(null);

const [enableFFijado, setEnableFFijado] = useState(false);
    useEffect(()=>{
        if (isUpdate || isDelete) {
            window.location.reload()
            
        }
    },[isUpdate,isDelete])
    
    useEffect(()=>{
        if(perfil)setNombreCorto(perfil.nombreCorto)

    },[perfil])

    const resaltarHashtags = (html) => {
        const hashtagsRegex = /#[A-Za-z0-9áéíóúÁÉÍÓÚ]+/g;
        const nuevoHtml = html.replace(hashtagsRegex, (match) => {
          return `<span style="color: rgb(29, 155, 240)">${match}</span>`;
        });
        return nuevoHtml;
      };
    const handleInputChange = (event) => {
        console.log("Enevent",event)
        const inputValue = event.htmlValue;
        const nuevoTexto = resaltarHashtags(inputValue);
        formik.setFieldValue("contenido",nuevoTexto)
      };
    const buscarUsuarios = async (texto) => {
        const textoLimpio = texto.textValue.trim();
        const palabras = textoLimpio.split(/\s+/);
        let comienzaConArroba = false;
        palabras.map((elemento, index) => {          
            if (elemento.startsWith('@')) {
              comienzaConArroba = true;
            }else{
                comienzaConArroba = false;
            }
          });
          
        if (comienzaConArroba) {
            console.log("Api")
            let jwt = window.localStorage.getItem("jwt");
            ListUsers({jwt}).then(data =>{
                console.log("Data",data)
                setUsuariosSugeridos(data)
            })
        } else {
            setUsuariosSugeridos([]);
        }


        console.log("usuariosSugeridos",usuariosSugeridos)

        
    };
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);
            const regex = /data:.*base64,/;
            //console.log(str1.replace(regex,""))
            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL.replace(regex, ""));
            };
            console.log(fileInfo);
        });
    };
    const onFileChange = (e) => {
        
        setResetImage(false)
        let { file } = stateImage;
        const files = e.target.files;
        if ( isAdmin && files.length > 10) {
            toast.current.show({severity:'error', summary: 'Error', detail:"No puedes seleccionar más de 10 imágenes", life: 7000})
            onDeleteFile();
        return;
        }
        else  {
            if(!isAdmin && files.length > 3){
                toast.current.show({severity:'error', summary: 'Error', detail:"No puedes seleccionar más de 3 imágenes", life: 7000})
                onDeleteFile();
                return;
            }
        }
        setEnableLinkVideo(false)
        formik.values.linkVideo = '';
       
        const promises = [];
        const publicacionCompleta = []; 
        const nombreimagenes =[];
        const stateImageAdjuntos =[]
        for (const file of files) {
        const promise = getBase64(file)
            .then((result) => {
            file["base64"] = result;
            setNombreImagen(file.name)

            publicacionCompleta.push({
                adjuntoBase64: result,
                nombre: file.name.replace(/\s/g, '_')
            });
            nombreimagenes.push(file.name);

            setStateImage({
                base64URL: result,
                file,
            });
            stateImageAdjuntos.push({
                base64URL: result,
                file,
            });

            })
            .catch((err) => {
            console.log(err);
            });

        promises.push(promise);
        }

        Promise.all(promises)
        .then(() => {
            console.log(publicacionCompleta);
        })
        .catch((error) => {
            console.log(error);
        });
        setpublicacionCompletaAdjunto(publicacionCompleta)
        console.log("publicacionCompleta",publicacionCompleta)
        setNombreImagenes(nombreimagenes)
        setStateImages(stateImageAdjuntos); 

    };

    
    const onDeleteFile =(e)=>{
        const nuevoArreglo = [...previewMediaArregloImg];
        nuevoArreglo.splice(e, 1);
        console.log("DELETE",e)
        console.log("publicacionCompletaAdjunto",publicacionCompletaAdjunto)
        setPreviewMediaArregloImg(nuevoArreglo)

        const nuevopublicacionCompletaAdjunto = [...publicacionCompletaAdjunto];
        nuevopublicacionCompletaAdjunto.splice(e, 1);
        console.log("nuevopublicacionCompletaAdjunto",nuevopublicacionCompletaAdjunto)

        setpublicacionCompletaAdjunto(nuevopublicacionCompletaAdjunto)
        // console.log("onDeleteFile")

        // setPreviewMedia(null);
        
        // setStateImage({file:null,base64URL:""})
        // formik.setFieldValue("nombreFoto","");
        // setNombreImagen(null);
        // setResetImage(true)
        // setEnableLinkVideo(true)
        // URL.revokeObjectURL(null)
        
        
        
    }
    const handleDescartar =(formik)=>{
        onDeleteFile();
        formik.resetForm()
        // OcultarCreacion()
        setMostrarModalDesacartar(false)
    }

    useEffect(() => {
        if (stateImage.file) {
            const arregloUrlImg =[]
            for (const img of stateImages) {
                const objectUrlImg = URL.createObjectURL(img.file);
                arregloUrlImg.push(objectUrlImg);
            }
            setPreviewMediaArregloImg(arregloUrlImg)
            formik.setFieldValue("nombreFoto",stateImage.file.name);
            const objectUrl = URL.createObjectURL(stateImage.file);
            setPreviewMedia(objectUrl);
            console.log("objectUrl",objectUrl)
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [stateImage]);
 
    useEffect(()=>{
        if(publicacionEdit) 
        {
            console.log("NOMBRE IMAGEN",publicacionEdit)
            const arreglo = publicacionEdit.publicacionCompletoAdjuntos?.map(imagen => imagen.nombre);
            console.log("arreglo",arreglo)
            console.log("publicacionEdit",publicacionEdit)
             setNombreImagen(publicacionEdit.rutaImagenVideo)
             setNombreImagenes(arreglo)
             setpublicacionCompletaAdjunto(publicacionEdit.publicacionCompletoAdjuntos)
            formik.setFieldValue("nombreFoto",publicacionEdit.rutaImagenVideo);
            if(publicacionEdit.rutaImagenVideo)setPreviewMedia(constantes.URLBLOB_PUBLICACIONES+"/"+publicacionEdit.id+"/"+publicacionEdit.rutaImagenVideo);
            const nuevoArreglo = arreglo?.map(elemento => {
            return constantes.URLBLOB_PUBLICACIONES + "/" + publicacionEdit.id + "/" + elemento;
            }); 
            if(publicacionEdit.rutaImagenVideo)setPreviewMediaArregloImg(nuevoArreglo);

           console.log("URLL",constantes.URLBLOB_PUBLICACIONES+"/"+publicacionEdit.id+"/"+publicacionEdit.rutaImagenVideo)
        }

    },[publicacionEdit])
   

    const comboMes = [
        { code: "Enero", value: 1 },
        { code: "Febrero", value: 2 },
        { code: "Marzo", value: 3 },
        { code: "Abril", value: 4 },
        { code: "Mayo", value: 5 },
        { code: "Junio", value: 6 },
        { code: "Julio", value: 7 },
        { code: "Agosto", value: 8 },
        { code: "Setiembre", value: 9 },
        { code: "Octubre", value: 10 },
        { code: "Noviembre", value: 11 },
        { code: "Diciembre", value: 12 },
    ];

    const handleChangeMonth = (e) => {
        //   const date = new Date();
        //   const currentYear = date.getFullYear();
        //   console.log(new Date(currentYear, e.value, 0).getDate());
        //   formik.handleChange(e)
        //   return new Date(currentYear,  e.value, 0).getDate();
        const mes = e.value - 1;
        formik.handleChange(e);
        const dateNow = new Date();
        const currentYear = dateNow.getFullYear();
        var date = new Date(currentYear, mes, 1);
        var days = [];
        while (date.getMonth() === mes) {
            const actual = new Date(date);
            days.push({ value: actual.getDate() });
            date.setDate(date.getDate() + 1);
        }
        setComboDias(days);
    };
    const CambiaFechaProgramacion = (valor) =>{
        if(!valor) {setEnableFProgramar(false);formik.values.fechaProgramada = '';}
        else{ setEnableFProgramar(true);}
    }
    
    const CambiaFechaFijado = (valor) =>{
        if(!valor) {setEnableFFijado(false); formik.values.fechaFijado=''}
        else{ setEnableFFijado(true); }
    }
    const formik = useFormik({
       enableReinitialize:true,
        initialValues: {
        contenido: publicacionEdit.contenidoPublicacion?publicacionEdit.contenidoPublicacion :'',
        link: publicacionEdit.link,
        nombreLink: publicacionEdit.nombreLink,
    
        fijado: publicacionEdit.fijada ? publicacionEdit.fijada : false,
        programada:publicacionEdit.programada ? publicacionEdit.programada : false,
        fechaProgramada: publicacionEdit.fechaProgramada? publicacionEdit.fechaProgramada :'',
      
        fechaFijado : publicacionEdit.fechaFijado ?publicacionEdit.fechaFijado :'',
        linkVideo: publicacionEdit.rutaVideo ? publicacionEdit.rutaVideo : '',
        nombreFoto : publicacionEdit.rutaImagenVideo ? publicacionEdit.rutaImagenVideo : '',
        enviar : ''
      },
      
      //resetForm:true,
      validationSchema: Yup.object({
        //   contenido: Yup.string().required("Mensaje de publicación requerido."),
        contenido: Yup.string(),
        nombreFoto: Yup.string(),
        enviar :Yup.string().when(['nombreFoto','contenido'],(nombreFoto,contenido)=>{
            if(!nombreFoto && !contenido)
                return Yup.string().required('Mensaje o imagen de publicación requerido.')  
        }),
        fijado: Yup.boolean(),
        fechaFijado: Yup.string().when('fijado',(fijado)=>{
            if(fijado)
                return Yup.string().required('Fecha fijada requerida')  
        }),
        programada: Yup.boolean(),
            fechaProgramada: Yup.string().when('programada',(programada)=>{
                if(programada)
                    return Yup.string().required('Fecha programación requerida')  
            })
         
        }),
      onSubmit: values => {

           let id = publicacionEdit.id;
        //    let idGrupo = IdGrupo;
           let idPublicacionCompartida = publicacionEdit.idPublicacionCompartida;
           let contenidoPublicacion = values.contenido;
          //  let contenidoPublicacion = contenidoPub
          let fijada = values.fijado;
          let fechaFijado = values.fijado ?new Date(values.fechaFijado).toLocaleString("en-US"):null;
          let programada = values.programada;
          let fechaProgramada = programada ? new Date(values.fechaProgramada).toLocaleString("en-US"):null;
           
           let link = values.link;
           let nombreLink = values.nombreLink;
        //    let rutaImagenVideo = stateImage.file === null ? "":stateImage.file.name;
           let rutaImagenVideo = nombreImagen;
           let adjuntoBase64 = stateImage.file === null?"": stateImage.base64URL;
           let publicacionCompletoAdjuntos = publicacionCompletaAdjunto;
           let rutaVideo = values.linkVideo;
           let jsonPublicacion = JSON.stringify({ id, idPublicacionCompartida, contenidoPublicacion, fijada,fechaFijado,
            programada,fechaProgramada,link,rutaVideo,
              nombreLink,rutaImagenVideo,publicacionCompletoAdjuntos,adjuntoBase64});

        //    RegistrarPublicacion({jsonPublicacion})
        console.log("jsonPublicacion",jsonPublicacion)
        ActualizarPublicacionAdjuntos({jsonPublicacion})
          //alert(jsonPublicacion);
        
      },
    });

    const handleLinkVideo=(e)=>{
        if(e.target.value.length > 0){
            setEnableAdjunto(false);
            onDeleteFile();
        }
        else{setEnableAdjunto(true)}
    }

    const footerModal =
        // <form  onSubmit={formik.handleSubmit}>
        //     <div className='p-eva-editarPublicacion-footer'>
        //         <Boton nombre={"Actualizar publicacion"} color="primary" type='submit' widths='40%' disabled={!(formik.isValid && formik.dirty)} loading={formik.isSubmitting}></Boton>
        //     </div>
        // </form>
        <></>
   
    const contenidoModal = (
        <div>
            <form id="form-crearPublicacion" onSubmit={formik.handleSubmit}>
                <div>
                    <div className="p-fluid for+mgrid grid">
                        <div className="field col-12 md:col-12">
                            {/* <TextareaDefault className={"grey"} placeholder="¿Qué quieres compartir. Camila?"></TextareaDefault> */}
                            {/* <InputPublicacion
                                placeholder={"¿Qué quieres compartir, " + nombreCorto + "?"}
                                id="contenido"
                                name="contenido"
                                onChange={(e)=>( formik.setFieldValue("contenido", e.textValue.replace(/\n/g, "")),buscarUsuarios(e))}
                                onblur={formik.handleBlur}
                                value={formik.values.contenido}
                                srcMedia={previewMedia}
                                srcMediaArreglo={previewMediaArregloImg}
                                widthRight={0}
                                clicDeleteImg={onDeleteFile}
                            ></InputPublicacion> */}

                            <InputPublicacion placeholder={"¿Qué quieres compartir, "+nombreCorto+"?"} 
                                        id="contenido"
                                        name="contenido"
                                        onChange={(e)=>(handleInputChange(e),buscarUsuarios(e))}
                                        onblur= {formik.handleBlur}
                                        value= {formik.values.contenido}
                                        srcMedia={previewMedia}
                                        srcMediaArreglo={previewMediaArregloImg}
                                        widthRight={0}
                                        clicDeleteImg={onDeleteFile}
                                    ></InputPublicacion>
                             {usuariosSugeridos.length > 0 && (
                                        <UsuariosSugeridos usuarios={usuariosSugeridos} formikProps={formik}  />
                                    )}
                            <small id="enviar" className="p-error block">
                                        {formik.errors.enviar && formik.touched.enviar && formik.errors.enviar}
                                    </small>
                        </div>
                        {
                            publicacionEdit && publicacionEdit.idPublicacionCompartida && 
                            <div className="field col-12 md:col-12">
                                <CardPublicacionCompartida publicacion={publicacionEdit.publicacionCompartida}></CardPublicacionCompartida>
                            </div>
                        }

                        {
                            publicacionEdit && !publicacionEdit.idPublicacionCompartida &&
                            <>
                                <div className="field col-12 md:col-6" style={{display:"flex",alignItems:"center",position:"relative"}}>
                                <label className={"eva-labelFile"} style={{ minHeight: 16, height: "fit-content" }}>
                                    {nombreImagenes?.length > 0 ? (
                                        nombreImagenes.map((nombreImagen, index) => (
                                        <span key={index}>
                                            {nombreImagen}
                                            <br />
                                        </span>
                                        ))
                                    ) : (
                                        <span>{"Adjunte aquí"}</span>
                                    )}
                                </label>
                                {/* {
                                    nombreImagen ?
                                    <label  className={"eva-labelFile"} style={{minHeight:16,height:"fit-content"}}>
                                        <span>{nombreImagen}</span>
                                    
                                    </label>
                                    :
                                    <label  className={"eva-labelFile"} style={{minHeight:16,height:"fit-content"}}>
                                        <span>{"Adjunte aquí"}</span>
                                    
                                    </label>
                                } */}
                                {!stateImage.file  && <label htmlFor="documentoAdjuntos" className='eva-labelFile-icon'><i><Iconsax.Gallery /></i></label>}
                                {stateImage.file && <label className='eva-labelFile-icon'><i onClick={onDeleteFile} style={{cursor:"pointer"}}><Iconsax.Trash /></i></label>}
                                </div>
                                 <input  type="file" id="documentoAdjuntos" name ="documentoAdjuntos"  onChange={onFileChange} 
                                    accept="image/jpeg,image/png"  multiple hidden></input>
                                   
                                
                                
                            </>
                            }
                        <div className="field col-12 md:col-6">
                                <InputDefault placeholder={"Insertar link de video"} className={"grey"}
                                    id="linkVideo"
                                    name="linkVideo"
                                    type="text"
                                    onChange={(e)=>(formik.handleChange(e),handleLinkVideo(e))}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.linkVideo}
                                    disabled={!enableLinkVideo}
                                ></InputDefault>
                                
                            </div>


                        <div className="field col-12 md:col-6">
                            <InputDefault placeholder={"Inserta link"} className={"grey"} id="link" name="link" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.link}></InputDefault>
                        </div>
                        <div className="field col-12 md:col-6">
                            <InputDefault placeholder={"Escribe el text del link"} className={"grey"} id="nombreLink" name="nombreLink" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.nombreLink}></InputDefault>
                        </div>
                        <div className="field col-12 md:col-5 ">
                                <CheckboxEva label="Programar publicación:" marginTop={10}
                                    id="programada"
                                    name="programada"
                                    onChange={(e)=>(formik.handleChange(e),CambiaFechaProgramacion(e.checked))}
                                   
                                    checked={formik.values.programada}
                                    value= {formik.values.programada}
                                ></CheckboxEva>
                            </div>
                            <div className="field col-12 md:col-7 ">
                                <CalendarDefault id="fechaProgramada"
                                    name="fechaProgramada" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    showTime hourFormat="12"
                                    value ={formik.values.fechaProgramada &&new Date(formik.values.fechaProgramada)}
                                    placeholder="Seleccionar fecha"
                                    disabled = {!enableFProgramar}
                                    classesname = {"grey"}
                                    minDate = {new Date()}
                                    dateFormat="dd/mm/yy"
                                    ></CalendarDefault>
                                    <small id="fechaProgramada" className="p-error block">
                                        {formik.errors.fechaProgramada && formik.touched.fechaProgramada && formik.errors.fechaProgramada}
                                    </small>
                            </div>
                        <div className="field col-12 md:col-5 ">
                            <CheckboxEva label="Fijar publicación hasta:" marginTop={10} id="fijado" name="fijado" 
                            onChange={(e)=>(formik.handleChange(e),CambiaFechaFijado(e.checked))}
                            checked={formik.values.fijado} value={formik.values.fijado}></CheckboxEva>
                        </div>
                        <div className="field col-12 md:col-7">
                                {/* {console.log(formik.values.fechaFijado)} */}
                                <CalendarDefault id="fechaFijado"
                                    name="fechaFijado" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    showTime hourFormat="12"
                                    value ={formik.values.fechaFijado&&new Date(formik.values.fechaFijado)}
                                    classesname = {"grey"}
                                    placeholder="Seleccionar fecha"
                                    disabled = {!enableFFijado}
                                    minDate = {new Date()}
                                    dateFormat="dd/mm/yy"
                                    ></CalendarDefault>
                                <small id="fechaFijado" className="p-error block">
                                    {formik.errors.fechaFijado && formik.touched.fechaFijado && formik.errors.fechaFijado}
                                </small>
                            </div>
                        <div className="p-eva-crearPublicaion-Publicar">
                            <Boton nombre ="Eliminar publicación" color='secondary' widths='40%' metodoClick={()=>setShowDialogEliminar(true)}></Boton>
                            <Boton nombre={"Actualizar publicacion"} color="primary" widths="40%" type="submit" 
                                disabled={!(formik.isValid && formik.dirty)} loading={formik.isSubmitting}
                                ></Boton>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
    const handleDelete =()=>{
        let IdPublicacion = publicacionEdit.id;
        //alert(IdPublicacion)
         Eliminar({IdPublicacion})
    }

    const footerDialog=()=>{
        return(
            <>
            <Boton nombre="Cancelar" color="secondary"metodoClick={()=>setShowDialogEliminar(false)} widths="100%"/>
            <Boton nombre="Eliminar"color="primary" metodoClick={()=>handleDelete()} widths="100%"/>
            </>
            
        )
    }

    
    return ( 
        <>
            <ModalEva mostrar={mostrar} 
                                onHide={onHide}
                                footer={footerModal}
                                contenido={contenidoModal}
                                widths="40vw"
                                height="22vw"
                                titulo={"Editar publicación"}
                                />
            <Dialog visible={showDialogEliminar}   onHide={()=>setShowDialogEliminar(false)} 
                footer={footerDialog}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar publicación?</label>
                {/* <div className="peva-label">
                    
                </div> */}
            </Dialog>
        </>
        
        
     );
}
 
export default EditarPublicacion;