import React, { useState, useEffect, useRef } from "react";
import { TabView } from 'primereact/tabview';
import "./Tabview.scss"
const TabViewEva = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const handleTab =(index)=>{
        setActiveIndex(index)
        props.cambiarAccion(index)
        

    }
    useEffect(() => {
        setActiveIndex(props.indexDefault)
    }, [props.indexDefault]);
    return ( 
        <TabView activeIndex={activeIndex} className={"p-eva-tabview"} onTabChange={(e) => handleTab(e.index)}>
            {props.children.map((item,i)=>{
                return(item)
            })}
        </TabView>
        );
}
 
export default TabViewEva;