import React,{useEffect,useState,useRef} from "react";
import ModalEva from "../../Modal/ModalEva";
import { TabView, TabPanel } from 'primereact/tabview';
import { ListBox } from 'primereact/listbox';
import * as constantes from "../../../constants/constantes.js";
import  {Avatar} from "primereact/avatar"
const Interacciones = ({mostrar,onHide,lista,total}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [interaccionTotal, setInteraccionTotal] = useState([]);
    useEffect(()=>{
        let temp = []
        lista&&
            lista.map((item,i)=>{
                let reaccion = item.imagen;
                let idTipoReaccion = item.idTipoInteraccion;
                item.listaUsuarios.map((user,j)=>{
                    
                    temp.push({id:user.id,foto:user.foto,nombre: user.label,iconoReaccion: reaccion,idReaccion:idTipoReaccion})
                })
            })
        setInteraccionTotal(temp);
    },[])

    const tabHeaderTemplate = (options,item) => {
        return (
            <div className="flex align-items-center px-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
                    <img width={24} src={"../../../../images/inicio/"+item.imagen}></img>                
                    <span className="titulo px-2">{item.listaUsuarios.length}</span>
            </div>
        )
    };
    const usuariosTemplate = (option)=>
    
    <div  style={{display:"flex", alignItems:"center"}}>
        <div><Avatar image = {option.foto ? constantes.URLBLOB_ADJ_AVATAR+"/"+option.id+"/"+option.foto : constantes.URLAVATARDEFAULT}
                        size={"large"} shape="circle" /></div>
        
        <div style={{width:"80%",paddingLeft:10}}>{option.nombre}</div>
        <div ><img width={40} src={"../../../../images/inicio/"+option.iconoReaccion}></img></div>

    </div>;
  
    const contenido = 
            <>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header={total+ " en total"}>
                        <ListBox  options={interaccionTotal} optionLabel="nombre"
                                            itemTemplate={usuariosTemplate} 
                                            style={{ width: "100%" }} listStyle={{ maxHeight: '200px',minHeight:200}} />
                    </TabPanel>
                    {
                        lista && 
                            lista.map((item,i)=>{
                                return(
                                    
                                    <TabPanel key={i+1} headerTemplate={(e)=>tabHeaderTemplate(e,item)} headerClassName="flex align-items-center" >
                                         <ListBox  options={interaccionTotal.filter(x=>x.idReaccion == item.idTipoInteraccion)} optionLabel="nombre"
                                            itemTemplate={usuariosTemplate} 
                                            style={{ width: "100%" }} listStyle={{ maxHeight: '200px',minHeight:200}} />
                                    </TabPanel>
                                    
                                )
                            })
                    }
                    
                </TabView>
            </>;

    const footerModal = <></>;
    return ( 
        <ModalEva 
            mostrar={mostrar} 
            onHide={onHide}
            titulo="Interacciones"
            contenido = {contenido}
            footer={footerModal}
            widths={510}
            height={431}
        ></ModalEva>
     );
}
 
export default Interacciones;