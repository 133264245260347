import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import {CreateGroupUser,ObtenerGrupo,UpdateGroup,DeleteGroup}from "../service/GruposService";
export default function UseGrupos(){
    const {perfil,jwt,setPerfil,setJwt} = useContext(Context)
    const [stateCreate,setStateCreate] = useState({loading:false,error:false,success:false})
    const [stateUpdate, setStateUpdate] = useState({loading:false,error:false,success:false});
    const [stateDelete, setStateDelete] = useState({loading:false,error:false,success:false});
    const [grupo, setGrupo] = useState(null);
    const CrearGrupoUsuario = useCallback(({jsonGrupos})=>{
        CreateGroupUser({jsonGrupos,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
        })
    },[setStateCreate])

    const ActualizarGrupoUsuario = useCallback(({jsonGrupos})=>{
        UpdateGroup({jsonGrupos,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateUpdate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateUpdate({loading:false,error:true,success:false})
        })
    },[setStateUpdate])

    const GetGrupo = useCallback(async ({idGrupo})=>{
        await ObtenerGrupo({idGrupo,jwt})
        .then(data=>{
            setGrupo(data);
        })
        .catch(err=>{
            setGrupo(null)
        })

    },[setGrupo])
    const EliminarGrupo = useCallback(({id})=>{
        DeleteGroup({id,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateDelete({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateDelete({loading:false,error:true,success:false})
        })
    },[setStateDelete])
    return{
        CrearGrupoUsuario,
        hasCreateError : stateCreate.error,
        isCreateLoading: stateCreate.loading,
        isCreated : stateCreate.success,
        grupo,
        GetGrupo,
        ActualizarGrupoUsuario,
        hasUpdateError : stateUpdate.error,
        isUpdateLoading: stateUpdate.loading,
        isUpdate : stateUpdate.success,
        EliminarGrupo,
        hasDeleteError : stateDelete.error,
        isDeleteLoading: stateDelete.loading,
        isDelete : stateDelete.success,
    }
}