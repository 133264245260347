import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import { ObtenerInfluencers,ActualizarClimaInfluencer } from "../../../../service/ClimaService";
import TextareaDefault from "../../../../components/TextArea/TextArea";
import Boton from "../../../../components/Boton/Boton";
import ToastEva from "../../../../components/Toast/ToastEva";
import MultiselectEva from '../../../../components/Multiselect/MultiSelectEva';
import { ListUsersTag } from "../../../../service/UsuariosService";

const EditarInfluencer = () => {
    const navigate = useNavigate();

    const [tituloPagina, setTituloPagina] = useState("");
    const [influencer, setInfluencer] = useState(null);
    const toast = useRef(null);
    const [listUserTotal, setListUserTotal] = useState([]);
    const [selected, setSelected] = useState([]);

    const handleSelectChange = (values) => {
        setSelected(values);
        console.log(values)
    };
    useEffect(()=>{
        const getInfluencer = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerInfluencers({jwt}).then(data => 
                {
                    setInfluencer(data)
                    let usuariosGrupo = [];

                    data.clima_laboral_influencer_usuario.map((item,i)=>{
                        usuariosGrupo.push({label: item.usuario.nombre,value:String(item.usuario.id)})
                    })
                    setSelected(usuariosGrupo);
                })
        }
        if(!influencer)getInfluencer()
    },[])

    useEffect(()=>{
        if(listUserTotal.length === 0)
        {
            let jwt = window.localStorage.getItem("jwt");
            ListUsersTag(jwt).then(data => setListUserTotal(data))
        }
        

     },[]);
     const Actualizar =({jsonInfluencer})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarClimaInfluencer({jsonInfluencer,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            id:influencer&&influencer.id,
            descripcion: influencer && influencer.descripcion,
            clima_laboral_influencer_usuario :influencer&&influencer.clima_laboral_influencer_usuario, 
            
        },
      //validationSchema: schema,
      onSubmit: values => {
    
         let id = values.id
         let descripcion = values.descripcion
            let tempUsers  =[]
            if(selected.length > 0)
            {
                selected.map((item,i)=>{
                    tempUsers.push({id:0,idClimaInfluencer:0,idUsuario:item.value})
                })
            }
         let clima_laboral_influencer_usuario = tempUsers;
         let jsonInfluencer = JSON.stringify({id,descripcion,clima_laboral_influencer_usuario},null,2)
        // //  console.log(jsonCapacitacion)
        //  formik.setSubmitting(false)
        //  alert(jsonClima);
         Actualizar({jsonInfluencer})
        
        
      },
    });
    return (
        <div className="eva-dashboard-editarInfluencer" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label htmlFor="header">Editar nuestros influencers</label>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12">
                        <label htmlFor="descripcion" className="p-eva-labelForm">
                            Añade una descripción
                        </label>
                        <TextareaDefault
                            id="descripcion"
                            minHeight={180}
                            placeholder="Escribe aquí"
                            className="White"
                            errorClass={formik.errors.descripcion && formik.touched.descripcion ? "error" : "success"}
                            value={formik.values.descripcion}
                            icon={<Iconsax.DocumentText1 />}
                            name="descripcion"
                            onChange={formik.handleChange}
                            onblur={formik.handleBlur}
                        />
                        <small className="p-error">{formik.errors.descripcion && formik.touched.descripcion}</small>
                    </div>
                    <div className="field col-12 md:col-9">
                        <label htmlFor="buscarColaborador">Añade a los colaboradores</label>
                        <MultiselectEva
                            onChange={handleSelectChange}
                            isMulti
                            //options={selectButtonValues1}
                            options={listUserTotal}
                            value={selected}
                        />
                    </div>
                </div>
                <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
            </form>
        </div>
    );
}
 
export default EditarInfluencer;