import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import * as Iconsax from "iconsax-react";
import UseLiderIn from "../../hooks/useLiderIn";
import { Skeleton } from "primereact/skeleton";
import CardFile from "../../components/CardFileEva/CardFile";
import { URLBLOB_LIDERIN } from "../../constants/constantes";
import { Card } from "primereact/card";
import ContactCard from "../../components/ContactCard/ContactCard";
import ContactCardV2 from "../../components/ContactCard/ContactCardV2";
import "./TemaDetalle.scss"
import useUsuario from "../../hooks/useUsuario";
import { render } from "preact/compat";
import parse from 'html-react-parser'


const LiderInDetalle = () => {
    const navigate = useNavigate();
    const {permisos,isLogged,perfil} = useUsuario()
    let { idTema } = useParams();
    const {tema,ObtenerTemaPorId} = UseLiderIn()
    const [permisoEditar, setPermisoEditar] = useState(false);

    let { idSeccion } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    // useEffect(()=>{
    //     const AccionPermisos = async () => {
    //         if (permisos.indexOf("editarLiderIn") > -1) {
    //             setPermisoEditar(true);
    //         }
    //     };
    //     if(permisos.length >0)
    //     {
    //         permisos.indexOf("verLiderIn") > -1 ? AccionPermisos() : navigate("/")
    //     }

    // },[permisos])

    useEffect(()=>{
        if(perfil)
        {
            if(perfil.esJefe)
            {
                setPermisoEditar(true);
            }
        }
    },[perfil])

    const redirectLink=(link)=>{
        window.open("https://"+link);
    }
    useEffect(()=>{
        const ObtenerTema = async()=>{
            let id=idTema;
            ObtenerTemaPorId({id})
        }
        if(idTema)
        {
            ObtenerTema()
        }
    },[idTema])

    const headerContacto =
        <div className="p-eva-DetalleTema-cardContactIcon" >
            <i className="p-eva-DetalleTema-cardContact-icon">
                {/* <Iconsax.Personalcard color="#F47647" /> */}
                <img src="../../../../../images/liderIn/contact-img.png"></img>
            </i>
            <span><label className="header-subtitle">¿Necesita ayuda? Comunícate con</label></span>
        </div>
    
        
    ;

    const ObtenerUrlTexto =(text)=>{
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        var textoNuevo= text.replace(urlRegex, function (url) {
          var hyperlink = url;
        //   if (!hyperlink.match('^https?:\/\/')) {
        //     hyperlink = 'http://' + hyperlink;
        //   }
        return '<a  className="p-eva-labelForm-orange" target="_blank" href="http://'+hyperlink+'">'+hyperlink+'</a>'
        //   return '<i onClick={()=>redirectLink("'+hyperlink+'")>'+hyperlink+'</i>'
          
        });
        return  <div>{parse(textoNuevo)}</div>
    }
    return ( 
        <div className="eva-DetalleTema">
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    {
                        permisoEditar &&
                        <div className="header-right">
                            <Link to={"../LiderIn/Seccion/"+idSeccion+"/Editar/"+idTema}>Editar tema</Link>
                        </div>
                    }
                    
                </div>
                <div className="header">
                    <label htmlFor="header">{tema? tema.nombreTema : <Skeleton className="mb-2"width="700px" height="30px" borderRadius="16px"/>}</label>
                </div>
                <div >
                    <label className="header-subtema">{tema? tema.nombreSubtema : <Skeleton className="mb-2"width="700px" height="30px" borderRadius="16px"/>}</label>
                </div>
            </div>
            <div className="p-eva-dashboard-DetalleTema">
                <div className="p-eva-DetalleTema-Descripcion" style={{marginTop:16}}>
                    <label>{tema? tema.descripcion : <Skeleton className="mb-2" width="100%" height="100px" borderRadius="16px"/>}</label>
                </div>
                <div className="p-eva-dashcboard-documentos" style={{marginTop:24}}>
                    <div className="p-fluid formgrid grid">
                        {
                            tema && 
                            tema.listaDocCompleto.map((item,i)=>{
                                var re = /(?:\.([^.]+))?$/;
                                return(
                                    <div className="field col-12 md:col-3" key={i} >
                                        <CardFile urlFile={URLBLOB_LIDERIN+"/"+idTema+"/Documentos/"+item.nombreDocumento} key={i} 
                                        extensionArchivo={re.exec(item.nombreDocumento)[1]}
                                        nombreArchivo={item.nombreDocumento} keys={i}
                                        tipo="Adjuntos"/>
                                    </div>
                                )
                            })
                        }
                        {
                            tema && 
                            tema.listaUrls.map((item,i)=>{
                                var re = /(?:\.([^.]+))?$/;
                                return(
                                    <div className="field col-12 md:col-3" key={i} >
                                        <CardFile key={i}
                                        extensionArchivo={"video"}
                                        nombreArchivo={item.nombreDocumento} keys={i}
                                        tipo="Urls"/>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                {
                    tema&&
                        tema.textoEvaluacion && 
                        <>
                            <label className="header-subtitle">¿Listo para iniciar la evaluación?</label>
                            <div className="p-eva-dashboard-link" style={{marginBottom:32}}>
                               {ObtenerUrlTexto(tema.textoEvaluacion)}
                            </div>
                        </>
                            
                }
                
                <div className="p-eva-detalleTema-contactCard" style={{marginTop:32}}>
                    <Card   header={headerContacto}>
                        <div className="p-fluid formgrid grid">
                            {
                                tema? 
                                    tema.listaContactos.length > 0 && 
                                        tema.listaContactos.map((item,i)=>{
                                            return(
                                                <div className="temadetalle-item-contactCard" key={i}>
                                                    <ContactCardV2 
                                                        idUser={item.idUsuario}
                                                        area={item.nombreArea}
                                                        gerencia={item.nombreGerencia}
                                                        foto ={item.nombreFoto}
                                                        puesto={item.puesto}
                                                        nombreUser = {item.nombreUsuario}
                                                        colorFondo="#F5F5F5"
                                                        email={item?.correo}
                                                        fechaNacimiento={item?.fechaNacimiento}
                                                        celular={item?.celular}
                                                        ></ContactCardV2>
                                                </div>
                                            )
                                        })
                                : null
                            }
                        </div>
                    </Card>
                </div>

            </div>
        </div>
        );
}
 
export default LiderInDetalle;