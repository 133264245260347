import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../../components/Toast/ToastEva";
import { ObtenerDiversidadActividadPorId } from "../../../../service/ClimaService";
import { Skeleton } from "primereact/skeleton";

import Boton from "../../../../components/Boton/Boton";

import { ObtenerFechaFormatoPalabra } from "../../../../helpers/helpers";
import * as constantes from "../../../../constants/constantes";
import CardFile from "../../../../components/CardFileEva/CardFile";
const DetalleActividad = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [actividad, setActividad] = useState(null);
    const toast = useRef(null);
    let { idActividad } = useParams();
    let { idSeccionClima } = useParams();
    let { indexDefault } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarClima") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verClima") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        const getActividad = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let id = idActividad
            await ObtenerDiversidadActividadPorId({jwt,id}).then(data => 
                {
                    setTituloPagina(data.nombre)
                    setActividad(data)
                })
        }

        if(idActividad) getActividad()
    },[idActividad])
    return ( 
        <div className="eva-detalleActividad" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    {
                        permisoEditar &&
                        <div className="header-right">
                            {actividad &&<Link to={"../Clima/Seccion/" + idSeccionClima + "/DetalleClimaDiversidad/"+indexDefault+"/EditarActividad/" + actividad.id}>{"Editar " + tituloPagina}</Link>}
                        </div>
                    }
                </div>
                <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>

               
            </div>
            <div className="p-eva-dashboard-detalleEventos" style={{ marginTop: 16 }}>
                <div className="p-eva-labelForm">
                    <span>{actividad ? actividad.descripcion : <Skeleton className="mb-2" width="700px" height="100%" borderRadius="16px" />}</span>
                </div>
            </div>
            <div className="p-eva-dashcboard-documentos" style={{ marginTop: 24 }}>
                <div className="p-fluid formgrid grid">
                    {
                        actividad &&
                        actividad.clima_diversidad_actividad_documento.length > 0 &&
                        actividad.clima_diversidad_actividad_documento
                            .filter((x) => x.tipo == "Adjunto" && x.estado == true && x.descargable ==false)
                            .map((item,i)=>{
                                var re = /(?:\.([^.]+))?$/;
                                return(
                                    <div className="field col-12 md:col-3" key={i}>
                                        {
                                            re.exec(item.nombreDocumento)[1] == "mp4" ?
                                                <CardFile key={i} extensionArchivo={"mp4"} nombreArchivo={item.nombreDocumento}  urlFile={constantes.URLBLOB_CLIMA_DIVERSIDAD_ACTIVIDADES+"/Documentos/"+item.nombreDocumento} keys={i} tipo="Urls" />

                                            :
                                            <CardFile urlFile={constantes.URLBLOB_CLIMA_DIVERSIDAD_ACTIVIDADES+"/Documentos/"+item.nombreDocumento} key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} nombreArchivo={item.nombreDocumento} keys={i} tipo="Adjuntos" descargable={false} />
                                        }
                                        
                                    </div>
                                )

                            })
                    }
                    {
                        actividad &&
                        actividad.clima_diversidad_actividad_documento.length > 0 &&
                        actividad.clima_diversidad_actividad_documento
                            .filter((x) => x.tipo == "Adjunto" && x.estado == true && x.descargable ==true)
                            .map((item,i)=>{
                                var re = /(?:\.([^.]+))?$/;
                                return(
                                    <div className="field col-12 md:col-3" key={i}>
                                            <CardFile urlFile={constantes.URLBLOB_CLIMA_DIVERSIDAD_ACTIVIDADES+"/Documentos/"+item.nombreDocumento} key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} nombreArchivo={item.nombreDocumento} keys={i} tipo="Adjuntos" descargable={true} />
                                        </div>
                                )

                            })
                    }

                    {actividad &&
                            actividad.clima_diversidad_actividad_documento.length >0 &&
                            actividad.clima_diversidad_actividad_documento
                                .filter((x) => x.tipo == "Url" && x.estado == true)
                                .map((item, i) => {
                                    var re = /(?:\.([^.]+))?$/;
                                    return (
                                        <div className="field col-12 md:col-3" key={i}>
                                            <CardFile key={i} extensionArchivo={"video"} nombreArchivo={item.nombreDocumento} keys={i} tipo="Urls" />
                                        </div>
                                    );
                                })}
                </div>
            </div>
        </div>
     );
}
 
export default DetalleActividad;