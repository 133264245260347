import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import InicioPage from "./pages/Inicio/Inicio";
import "@fontsource/poppins";
// import Original from "./pages/Original/Original";

import FormLayoutDemo from "./components/FormLayoutDemo";
//import Dashboard from "./components/Dashboard";
import Dashboard from "./pages/Inicio/Home";
import Usuario from "./pages/Usuarios/Usuario";
import UsuarioDetalle from "./pages/Usuarios/UsuarioDetalle";
import EditarUsuario from "./pages/Usuarios/EditarUsuario";
import Grupos from "./pages/Grupos/Grupos";
import CrearGrupo from "./pages/Grupos/Crear/CrearGrupo";
import HomeGrupo from "./pages/Grupos/Home/Home";
import Rol from "./pages/Rol/Rol";
import RolEditar from "./pages/Rol/RolEditar";
import Reglamento from "./pages/SomosIn/Reglamento/Reglamento";
import ReglamentoGerencia from "./pages/SomosIn/Reglamento/ReglamentoGerecnia";
import Directorio from "./pages/SomosIn/Directorio/Directorio";
import DirectorioDetalle from "./pages/SomosIn/Directorio/DirectorioDetalle";
import CrearReglamento from "./pages/SomosIn/Reglamento/CrearReglamento";
import PdfViewer from "./pages/PDF/PdfViewer"; 
// import Experiencia from "./pages/SomosIn/Experiencia/Experiencia";
import LiderIn from "./pages/LiderIn/LiderIn";
import PrimeReact from "primereact/api";
import CrearTema from "./pages/LiderIn/CrearTema";
import CrearTemaTest from "./pages/LiderIn/CrearTemaTest";
import LiderInDetalle from "./pages/LiderIn/TemaDetalle";
import PublicacionGrupo from "./pages/Grupos/Publicaciones/PublicacionGrupo";
import Desarrollo from "./pages/Desarrollo/Desarrollo";
import EditarEvaluacion from "./pages/Desarrollo/EvaluacionDesempeño/EditarEvaluacion";
import CrearConvocatoria from "./pages/Desarrollo/ConvocatoriaInterna/CrearConvocatoria";
import CrearHistoria from "./pages/Desarrollo/HistoriaDeExito/CrearHistoria";
import Organigrama from "./pages/SomosIn/Organigrama/Organigrama";
import EditarOrganigrama from "./pages/SomosIn/Organigrama/EditarOrganigrama";
import Intro from "./pages/Capacitación/Intro/Intro";
import EditarIntro from "./pages/Capacitación/Intro/EditarIntro";
import CapaMia from "./pages/Capacitación/Mia/CapaMia";
import EditarMia from "./pages/Capacitación/Mia/EditarMia";
import CapaOpcional from "./pages/Capacitación/Opcional/CapaOpcional";
import CapaOpcionalGerencia from "./pages/Capacitación/Opcional/CapaOpcionalGerencia";
import EditarCapaOpcional from "./pages/Capacitación/Opcional/EditarCapaOpcional";
import DetalleOpcional from "./pages/Capacitación/Opcional/DetalleCapaOpcional";
import CapaLiderazgo from "./pages/Capacitación/Liderazgo/CapaLiderazgo";
import EditarCapaLiderazgo from "./pages/Capacitación/Liderazgo/EditarCapaLiderazgo";
import DetalleCapaLiderazgo from "./pages/Capacitación/Liderazgo/DetalleCapaLiderazgo";
import CapaObligatoria from "./pages/Capacitación/Obligatoria/CapaObligatoria";
import EditarCapaObligatoria from "./pages/Capacitación/Obligatoria/EditarCapaObligatoria";
import DetalleCapaObligatoria from "./pages/Capacitación/Obligatoria/DetalleCapaObligatoria";
import PreguntasCapaObligatoria from "./pages/Capacitación/Obligatoria/PreguntasCapaObligatoria";
import Onboarding from "./pages/Clima/Onboarding/Onboarding";
import EditarOnboarding from "./pages/Clima/Onboarding/EditarOnboarding";
import Actividades from "./pages/Clima/Actividades/Actividades";
import Comunicaciones from "./pages/Clima/Actividades/Comunicaciones";
import CrearActividades from "./pages/Clima/Actividades/CrearActividades";
import CrearComunicaciones from "./pages/Clima/Actividades/CrearComunicaciones";
import DetalleEventos from "./pages/Clima/Actividades/DetalleEventos";
import EditarEvento from "./pages/Clima/Actividades/EditarEvento";
import EditarCalendario from "./pages/Clima/Actividades/EditarCalendario";

import Laboral from "./pages/Clima/Laboral/Laboral";
import EditarEncuesta from "./pages/Clima/Laboral/Encuesta/EditarEncuesta";
import EditarPlan from "./pages/Clima/Laboral/Plan/EditarPlan";
import EditarGptw from "./pages/Clima/Laboral/GPTW/EditarGptw";

import EditarInfluencer from "./pages/Clima/Laboral/Influencer/EditarInfluencer";

import ReconocimientoDetalle from "./pages/Clima/Reconocimiento/ReconocimientoDetalle";
import EditarReconocimiento from "./pages/Clima/Reconocimiento/EditarReconocimiento";
import DetalleCategoria from "./pages/Clima/Reconocimiento/DetalleCategoria";
import Diversidad from "./pages/Clima/Diversidad/Diversidad";
import EditarComites from "./pages/Clima/Diversidad/Comites/EditarComites";
import EditarActividad from "./pages/Clima/Diversidad/Actividades/EditarActividad";
import DetalleActividad from "./pages/Clima/Diversidad/Actividades/DetalleActividad";
import EditarPolitica from "./pages/Clima/Diversidad/Politicas/EditarPolitica";
import Metricas from "./pages/Metricas/Metricas";

import { UsuarioContextProvider } from "./context/usuarioContext";
import { AppTopbar } from "./AppTopbarEva";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import { Tooltip } from "primereact/tooltip";
import * as constantes from "./constants/constantes.js";
import DialogChangePassword from "./components/Login/ChangePassword";
import * as Iconsax from "iconsax-react";

import useUsuario from "./hooks/useUsuario";
import {getPermisoModulo} from "./service/PermisosService"
import { bool, boolean } from "yup";
import EditarCategoria from "./pages/Clima/Reconocimiento/EditarCategoria";
import EditarDescripcionCapacitacion from "./pages/Capacitación/Opcional/EditarDescripcionCapacitacion";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [menuEva, setMenuEva] = useState([{label: "", items:  []}]);
    //const [PassDefect, setPassDefect] = useState(constantes.DEFAULTPASS);
    const { isloginLoading, hasLoginError, login, isLogged,permisosMenu} = useUsuario();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);
    const scale = 14;
    useEffect(() => {
        document.documentElement.style.fontSize = scale + "px";
    }, [scale]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };





    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active ": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active peva-menu-overlay-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    // if (!window.localStorage.getItem("jwt")) {
    //     return (
    //         <UsuarioContextProvider>
    //             <Login colorMode={layoutColorMode} location={location} />
    //         </UsuarioContextProvider>
    //     );
    // }else{
        return (
            <>
            {/* <UsuarioContextProvider> */}
                {/* <Route path="/" exact render={() => <Login colorMode={layoutColorMode} location={location} />} />
                  <Route path="/Inicio" component={InicioPage} /> */}
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />    
                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                    <div className="layout-sidebar eva-slider-left" onClick={onSidebarClick}>
                        <AppMenu model={[]} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>
                    <div className="layout-main-container">
                        <div className="layout-main">
                        
                            <Routes>
                              
                                    <Route path="Home" element={<Dashboard><label>dentro de dashboard</label></Dashboard>} />
                                    <Route path="Usuario" element={<Usuario />} />
                                    <Route path="Usuario/Gerencia/:IdGerencia" element={<UsuarioDetalle />} />
                                    <Route path="Usuario/Editar/:idUsuario" element={<EditarUsuario />} />
                                    <Route path="Grupos" element={<Grupos />} />
                                    <Route path="Grupos/Crear" element={<CrearGrupo />} />
                                    <Route path="Grupos/Editar/:idGrupo" element={<CrearGrupo />} />
                                    <Route path="Grupos/:idGrupo/Publicaciones" element={<PublicacionGrupo/>} />
                                    <Route path="Grupos/:idGrupo/Publicaciones/:idPublicacion" element={<PublicacionGrupo/>} />
                                    <Route path="Rol" element={<Rol />} />
                                    <Route path="Rol/Editar" element={<RolEditar />} />
                                    <Route path="SomosIn/ReglamentoGerencia" element={<ReglamentoGerencia />} />
                                    <Route path="SomosIn/Reglamento/:idGerencia" element={<Reglamento />} />
                                    <Route path="SomosIn/Reglamento/:idGerencia/Crear" element={<CrearReglamento />} />
                                    <Route path="SomosIn/Reglamento/Editar/:idReglamento" element={<CrearReglamento />} />
                                    <Route path="SomosIn/Directorio" element={<Directorio />} />
                                    <Route path="SomosIn/Directorio/Gerencia/:IdGerencia" element={<DirectorioDetalle />} />
                                    <Route path="SomosIn/Directorio/Usuario/:IdUsuario" element={<DirectorioDetalle />} />
                                    <Route path="/Pdfviewer/:nombrePdf" element={<PdfViewer />}  />
                                    {/* <Route path="SomosIn/ExperienciaIn" element={<Experiencia/>} /> */}
                                    <Route path="SomosIn/Organigrama" element={<Organigrama/>} />
                                    <Route path="SomosIn/Organigrama/Editar/:idOrganigrama" element={<EditarOrganigrama/>} />
                                    <Route path="LiderIn/Seccion/:idSeccion" element={<LiderIn/>} />
                                    <Route path="LiderIn/Seccion/:idSeccion/Crear" element={<CrearTemaTest/>} />
                                    <Route path="LiderIn/Seccion/:idSeccion/Detalle/:idTema" element={<LiderInDetalle/>} />
                                    <Route path="LiderIn/Seccion/:idSeccion/Editar/:idTema" element={<CrearTemaTest/>} />
                                    <Route path="Desarrollo/:indexDefault" element={<Desarrollo/>} />
                                    <Route path="Desarrollo/:indexDefault/EditarEvaluacion" element={<EditarEvaluacion/>} />
                                    <Route path="Desarrollo/:indexDefault/CrearConvocatoria" element={<CrearConvocatoria/>} />
                                    <Route path="Desarrollo/:indexDefault/EditarConvocatoria/:idConvocatoria" element={<CrearConvocatoria/>} />
                                    <Route path="Desarrollo/:indexDefault/CrearHistoria" element={<CrearHistoria/>} />
                                    <Route path="Desarrollo/:indexDefault/EditarHistoria/:idHistoria" element={<CrearHistoria/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/DetalleIntro" element={<Intro/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/EditarIntro/:idCapacitacion" element={<EditarIntro/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/DetalleMia" element={<CapaMia/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/EditarMia/:idCapacitacion" element={<EditarMia/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/ListaOpcional" element={<CapaOpcionalGerencia/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/Gerencia/:IdGerencia" element={<CapaOpcional/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/Gerencia/:IdGerencia/CrearOpcional" element={<EditarCapaOpcional/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/CrearOpcional" element={<EditarCapaOpcional/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/Gerencia/:IdGerencia/EditarOpcional/:idCapacitacion" element={<EditarCapaOpcional/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/Gerencia/:IdGerencia/DetalleOpcional/:idCapacitacion" element={<DetalleOpcional/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/Gerencia/:IdGerencia/EditarGerencia" element={<EditarDescripcionCapacitacion/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/ListaLiderazgo" element={<CapaLiderazgo/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/CrearLiderazgo" element={<EditarCapaLiderazgo/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/DetalleLiderazgo/:idCapacitacion" element={<DetalleCapaLiderazgo/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/EditarLiderazgo/:idCapacitacion" element={<EditarCapaLiderazgo/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/ListaObligatoria" element={<CapaObligatoria/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/CrearObligatoria" element={<EditarCapaObligatoria/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/EditarObligatoria/:idCapacitacion" element={<EditarCapaObligatoria/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/DetalleObligatoria/:idCapacitacion" element={<DetalleCapaObligatoria/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/Preguntas/:idCapacitacion" element={<PreguntasCapaObligatoria/>} />
                                    <Route path="Capacitacion/Seccion/:idSeccion/Preguntas/:idCapacitacion/:visualizacion" element={<PreguntasCapaObligatoria/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleOnboarding" element={<Onboarding/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/EditarOnboarding/:idClima" element={<EditarOnboarding/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleActividades" element={<Actividades/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleComunicaciones" element={<Comunicaciones/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/CrearActividades" element={<CrearActividades/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/CrearComunicaciones" element={<CrearComunicaciones/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleEvento/:idEvento" element={<DetalleEventos/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/EditarEvento/:idEvento" element={<EditarEvento/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/EditarCalendario/:idCalendario" element={<EditarCalendario/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaLaboral/:indexDefault" element={<Laboral/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaLaboral/:indexDefault/EditarEncuestas" element={<EditarEncuesta/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaLaboral/:indexDefault/EditarPlanAccion" element={<EditarPlan/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaLaboral/:indexDefault/EditarGptw" element={<EditarGptw/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaLaboral/:indexDefault/EditarInfluencers" element={<EditarInfluencer/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleReconocimiento" element={<ReconocimientoDetalle/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/EditarReconocimiento" element={<EditarReconocimiento/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleCategoria/:idCategoria" element={<DetalleCategoria/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/EditarCategoria/:idCategoria" element={<EditarCategoria/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaDiversidad/:indexDefault" element={<Diversidad/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaDiversidad/:indexDefault/EditarComites" element={<EditarComites/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaDiversidad/:indexDefault/CrearActividad" element={<EditarActividad/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaDiversidad/:indexDefault/DetalleDiversidadActividad/:idActividad" element={<DetalleActividad/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaDiversidad/:indexDefault/EditarActividad/:idActividad" element={<EditarActividad/>} />
                                    <Route path="Clima/Seccion/:idSeccionClima/DetalleClimaDiversidad/:indexDefault/EditarPoliticas" element={<EditarPolitica/>} />
                                    <Route path="Metricas" element={<Metricas/>} />




                                    EditarReconocimiento
                                {/* </Route> */}
                                
                            </Routes>
                        
                        </div>
                        {/* <DialogChangePassword></DialogChangePassword> */}
                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>
    
                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>
            {/* </UsuarioContextProvider> */}
            </>
        );
    //}
    
};

export default App;
