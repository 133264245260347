import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
 import { ListarSeccionCapacitacion,ObtenerListaCapacitacionesOblgatoriasAdmin,ObtenerListaCapacitacionesObligatoriasUsuario,ObtenerReporte} from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import "./CapaObligatoria.scss"
import Boton from "../../../components/Boton/Boton";
import * as constantes from "../../../constants/constantes";
import CardEntry from "../../../components/CardEntryEva/CardEntry";
import { FechaFormatoCorto, ObtenerFechaFormatoCorto } from "../../../helpers/helpers";
import { Menu } from "primereact/menu";
import { TabPanel } from 'primereact/tabview';
import TabViewEva from "../../../components/TabviewEva/TabviewEva";
import { Dialog } from "primereact/dialog";
import DropdownFiltro from "../../../components/DropdownFiltro/DropdownFiltro";
const CapaObligatoria = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [listaCapaObligatoria, setListaCapaObligatoria] = useState(null);
    const [listaCapaObligatoriaUsuario, setListaCapaObligatoriaUsuario] = useState(null);
    const [idCapaSeleccionada, setIdCapaSeleccionada] = useState(null);
    const [indexTab, setIndexTab] = useState(0);
    const [mostrarAdvertencia, setMostrarAdvertencia] = useState(false);
    const [mostrarAlertaPendientes, setMostrarAlertaPendientes] = useState(false);
    const menu = useRef(null);
    const [filtroSeleccionado, setFiltroSeleccionado] = useState(null);

    const toast = useRef(null);
    let { idSeccion } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    const ObtenerCapacitacionesAdmin = async ()=>{
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerListaCapacitacionesOblgatoriasAdmin({jwt}).then(data=>{
            setListaCapaObligatoria(data);
        })
    }
    const ObtenerCapacitacionesUsuario = async ()=>{
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerListaCapacitacionesObligatoriasUsuario({jwt}).then(data=>{
            setListaCapaObligatoriaUsuario(data);
            if(data.filter(x=>x.estadoTerminado == false).length > 0) setMostrarAlertaPendientes(true)
        })
    }

    const AccionPermisos = async (permiso) => {
        if (permisos.indexOf(permiso) > -1) {
            setPermisoEditar(true);
            ObtenerCapacitacionesAdmin()
            ObtenerCapacitacionesUsuario()
        }else
        {
            setPermisoEditar(false);
            ObtenerCapacitacionesUsuario()
        }
    };

    useEffect(()=>{
        
        if(permisos.length >0)
        {
            permisos.indexOf("verCapacitacionesObligatorias") > -1 ? AccionPermisos("editarCapacitacionesObligatorias"):  navigate("/")
        }

    },[permisos])
    let itemsEditar = [
        {label: 'Editar capacitacion', icon: <Iconsax.Edit2 style={{marginRight:8}} color="#F47647"/>,
        command: () => {
            setMostrarAdvertencia(true)
            //navigate("../Capacitacion/Seccion/" + idSeccion + "/EditarObligatoria/"+idCapaSeleccionada)
        }},
        {label: 'Ver capacitación', icon: <Iconsax.Eye style={{marginRight:8}} color="#F47647"/>,
            command:()=>{
                // setShowDialog(true)
                navigate("../Capacitacion/Seccion/" + idSeccion + "/Preguntas/"+idCapaSeleccionada+"/"+true)
            }
        }
    ];

    useEffect(()=>{
        const obtenerListaSeccionCapa = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccionCapacitacion({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccion);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccion){
            obtenerListaSeccionCapa();
        } 
    },[idSeccion])

    
    // useEffect(()=>{
        
    //     if(!listaCapaObligatoria) {
    //         permisoEditar ?ObtenerCapacitacionesAdmin() : ObtenerCapacitacionesUsuario()
    //     }
    // },[setPermisoEditar])

    const filtro =[
        {value:1,name:"Ordenar por año de creación"},
        {value:2,name:"Ordenar por nombre"}
    ]
    const handleFiltro=(e)=>{
        setFiltroSeleccionado(e.value)
        
        if(e.value === 1){
            let temp = listaCapaObligatoria.sort((a,b)=>b.anioCreacion - a.anioCreacion);
            setListaCapaObligatoria(null)
            setListaCapaObligatoria(temp)
        }
        else if(e.value === 2)
        {
            let temp = listaCapaObligatoria.sort((a,b)=>{if (a.nombreCapacitacion < b.nombreCapacitacion) {return -1; }});
            setListaCapaObligatoria(null)
            setListaCapaObligatoria(temp)
        }
        
    }
    const footerDialog =
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
            <Boton nombre={"Ir a editar capacitación"} color="primary" widths="215px" metodoClick={()=>navigate("../Capacitacion/Seccion/" + idSeccion + "/EditarObligatoria/"+idCapaSeleccionada)}></Boton>
        </div>
    ;
    const handleReporte=(id,nombre)=>{
        let jwt = window.localStorage.getItem("jwt");
        let IdCapa = id;
         ObtenerReporte({jwt,IdCapa}).then(data=>{
            var fechaActual = new Date();
            var fecha = fechaActual.getFullYear()+"-"+fechaActual.getMonth()+"-"+fechaActual.getDate();

            fetch(constantes.URLBLOB_REPORTE_CAPA+"/"+nombre.replace(/ /g,'_')+"_"+FechaFormatoCorto(fechaActual)).then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = nombre.replace(/ /g,'_')+"_"+FechaFormatoCorto(fechaActual)+".xlsx";
                    alink.click();
                })
            })
         })
    }
    const footerAlerta = <></>;
    return (
        <div className="eva-capaObligatoria" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    {permisoEditar && (
                        <div className="header-right">
                            <Link to={"../Capacitacion/Seccion/" + idSeccion + "/CrearObligatoria"}>Crear nueva capacitación</Link>
                        </div>
                    )}
                </div>
            </div>
            <div className="p-eva-dashboard-capaObligatoria" style={{ marginTop: 16 }}>
                

                <div className="capaObligatoria-container">
                    {permisoEditar ? 
                                <TabViewEva cambiarAccion={() => {}} indexDefault={parseInt(indexTab)}>
                                    <TabPanel header="Vista administrador">
                                    <div className="capaObligatoria-filtro" style={{ marginBottom: 24 }}>
                                        <DropdownFiltro value={filtroSeleccionado} onChange={handleFiltro} options={filtro} optionLabel="name" placeholder="Selecciona filtro" style={{ width: 286 }}></DropdownFiltro>
                                    </div>
                                    <div className="capaObligatoria-container-lista">
                                        {
                                            listaCapaObligatoria && 
                                                listaCapaObligatoria.length > 0 ?
                                                    listaCapaObligatoria.map((item,i)=>{
                                                        return(
                                                            <div className="capaObligatoria-item" key={i}>
                                                                <img src="../../../../images/capacitacion/024-notepad.png" width={48}></img>
                                                                <div className="capaObligatoria-item-detalle">
                                                                    <div className="capaObligatoria-item-detalle-titulo">
                                                                        <span className="detalle-titulo-nombre">{item.nombreCapacitacion}</span>
                                                                        <span className="detalle-titulo-categoria">{item.categoria_capacitacion.nombre}</span>
                                                                    </div>
                                                                    <div className="capaObligatoria-item-detalle-datos">
                                                                        <span className="detalle-datos-item">{"Creado el: " + ObtenerFechaFormatoCorto(item.fechaRegistro)}</span>
                                                                        <div className="detalle-datos-barra"></div>
                                                                        <span className="detalle-datos-item">Respuestas: {item.numeroRespuestas}</span>
                                                                        <div className="detalle-datos-barra"></div>
                                                                        <span className="detalle-datos-item">Estado: Vigente</span>
                                                                    </div>
                                                                </div>
                                                                <div className="capaObligatoria-item-opciones">
                                                                    <Menu model={itemsEditar} popup ref={menu} id="popup_menu" style={{ width: 255 }} />
                                                                    <div
                                                                        style={{ display: "flex", alignItems: "center" }}
                                                                        onClick={(event) => {
                                                                            setIdCapaSeleccionada(item.id);
                                                                            menu.current.toggle(event);
                                                                        }}
                                                                        aria-controls="popup_menu"
                                                                        aria-haspopup
                                                                    >
                                                                        <span>
                                                                            <Iconsax.More color="#F47647" size={24}></Iconsax.More>
                                                                        </span>
                                                                    </div>
                                                                    <div className="item-opciones-descarga">
                                                                        <Boton nombre={"Descargar reporte"} margin={0} widths="184px" heights="48px" color="primary" displayButton="flex" metodoClick={() => handleReporte(item.id,item.nombreCapacitacion)}></Boton>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    
                                                : "No existe data" 
                                        }
                                       </div> 
                                    </TabPanel>
                                    <TabPanel header="Pendientes">
                                        <div className="capaObligatoria-container-lista">
                                        {
                                        listaCapaObligatoriaUsuario &&
                                            listaCapaObligatoriaUsuario
                                                .filter((x) => x.estadoTerminado == false)
                                                .map((item, i) => {
                                                    return (
                                                        <div className="capaObligatoria-item" key={i}>
                                                            <img src="../../../../images/capacitacion/024-notepad.png" width={48}></img>
                                                            <div className="capaObligatoria-item-detalleUsuario">
                                                                <div className="capaObligatoria-item-detalle-titulo">
                                                                    <span className="detalle-titulo-nombre">{item.nombreCapacitacion}</span>
                                                                    <span className="detalle-titulo-categoria">{item.categoria_capacitacion.nombre}</span>
                                                                </div>
                                                                <div className="capaObligatoria-item-detalle-datos">
                                                                    <span className="detalle-datos-item">{item.capacitacion_obligatoria_usuario.length == 0 ? "Tienes 3 intentos" : "Te quedan " + (3 - item.capacitacion_obligatoria_usuario.length) + " intentos"}</span>
                                                                    {item.capacitacion_obligatoria_usuario.length > 0 && <div className="detalle-datos-barra"></div>}
                                                                    {item.capacitacion_obligatoria_usuario.length > 0 && (
                                                                        <label>
                                                                            Respuestas correctas:{" "}
                                                                            <span className="detalle-datos-item" style={{ color: "#DC0922" }}>
                                                                                {" "}
                                                                                {item.capacitacion_obligatoria_usuario[0].respuestasCorrectas}/5
                                                                            </span>
                                                                        </label>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="capaObligatoria-item-arrow">
                                                                <span onClick={() => navigate("../Capacitacion/Seccion/" + idSeccion + "/DetalleObligatoria/" + item.id)} style={{ cursor: "pointer" }}>
                                                                    <Iconsax.ArrowRight2 color="#F47647" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                        })}
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Finalizados">
                                        <div className="capaObligatoria-container-lista">
                                        {
                                            listaCapaObligatoriaUsuario &&
                                                listaCapaObligatoriaUsuario.length > 0 ? 
                                                    listaCapaObligatoriaUsuario
                                                    .filter((x) => x.estadoTerminado == true)
                                                    .map((item, i) => {
                                                        return (
                                                            <div className="capaObligatoria-item" key={i}>
                                                                <img src="../../../../images/capacitacion/024-notepad.png" width={48}></img>
                                                                <div className="capaObligatoria-item-detalleUsuario">
                                                                    <div className="capaObligatoria-item-detalle-titulo">
                                                                        <span className="detalle-titulo-nombre">{item.nombreCapacitacion}</span>
                                                                        <span className="detalle-titulo-categoria">{item.categoria_capacitacion.nombre}</span>
                                                                    </div>
                                                                    <div className="capaObligatoria-item-detalle-datos">
                                                                        <span className={"detalle-datos-item"}>Intentos realizados: {item.capacitacion_obligatoria_usuario.length} </span>
                                                                        {item.capacitacion_obligatoria_usuario.length > 0 && <div className="detalle-datos-barra"></div>}
                                                                        {item.capacitacion_obligatoria_usuario.length > 0 && (
                                                                            <label>
                                                                                Respuestas correctas: <span className={item.capacitacion_obligatoria_usuario[0].respuestasCorrectas >= 3 ? "detalle-datos-item-exito" : "detalle-datos-item-error"}> {item.capacitacion_obligatoria_usuario[0].respuestasCorrectas}/5</span>
                                                                            </label>
                                                                        )}
                                                                        <div className="detalle-datos-barra"></div>
                                                                        <span className="detalle-datos-item">Capacitació completada: {ObtenerFechaFormatoCorto(item.capacitacion_obligatoria_usuario[0].fechaCompletada)} </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                :"No existe Data"
                                        }
                                        </div>
                                    </TabPanel>
                                </TabViewEva>
                                
                     : (
                        <TabViewEva cambiarAccion={() => {}} indexDefault={parseInt(indexTab)}>
                            <TabPanel header="Pendientes">
                                <div className="capaObligatoria-container-lista">
                                {
                                    listaCapaObligatoriaUsuario &&
                                        listaCapaObligatoriaUsuario
                                            .filter((x) => x.estadoTerminado == false)
                                            .map((item, i) => {
                                                return (
                                                    <div className="capaObligatoria-item" key={i}>
                                                        <img src="../../../../images/capacitacion/024-notepad.png" width={48}></img>
                                                        <div className="capaObligatoria-item-detalleUsuario">
                                                            <div className="capaObligatoria-item-detalle-titulo">
                                                                <span className="detalle-titulo-nombre">{item.nombreCapacitacion}</span>
                                                                <span className="detalle-titulo-categoria">{item.categoria_capacitacion.nombre}</span>
                                                            </div>
                                                            <div className="capaObligatoria-item-detalle-datos">
                                                                <span className="detalle-datos-item">{item.capacitacion_obligatoria_usuario.length == 0 ? "Tienes 3 intentos" : "Te quedan " + (3 - item.capacitacion_obligatoria_usuario.length) + " intentos"}</span>
                                                                {item.capacitacion_obligatoria_usuario.length > 0 && <div className="detalle-datos-barra"></div>}
                                                                {item.capacitacion_obligatoria_usuario.length > 0 && (
                                                                    <label>
                                                                        Respuestas correctas:{" "}
                                                                        <span className="detalle-datos-item" style={{ color: "#DC0922" }}>
                                                                            {" "}
                                                                            {item.capacitacion_obligatoria_usuario[0].respuestasCorrectas}/5
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="capaObligatoria-item-arrow">
                                                            <span onClick={() => navigate("../Capacitacion/Seccion/" + idSeccion + "/DetalleObligatoria/" + item.id)} style={{ cursor: "pointer" }}>
                                                                <Iconsax.ArrowRight2 color="#F47647" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                    })
                                }
                                </div>
                            </TabPanel>
                            <TabPanel header="Finalizadas">
                                <div className="capaObligatoria-container-lista">
                                {
                                listaCapaObligatoriaUsuario &&
                                    listaCapaObligatoriaUsuario.length > 0 ? 
                                        listaCapaObligatoriaUsuario
                                            .filter((x) => x.estadoTerminado == true)
                                            .map((item, i) => {
                                                return (
                                                    <div className="capaObligatoria-item" key={i}>
                                                        <img src="../../../../images/capacitacion/024-notepad.png" width={48}></img>
                                                        <div className="capaObligatoria-item-detalleUsuario">
                                                            <div className="capaObligatoria-item-detalle-titulo">
                                                                <span className="detalle-titulo-nombre">{item.nombreCapacitacion}</span>
                                                                <span className="detalle-titulo-categoria">{item.categoria_capacitacion.nombre}</span>
                                                            </div>
                                                            <div className="capaObligatoria-item-detalle-datos">
                                                                <span className={"detalle-datos-item"}>Intentos realizados: {item.capacitacion_obligatoria_usuario.length} </span>
                                                                {item.capacitacion_obligatoria_usuario.length > 0 && <div className="detalle-datos-barra"></div>}
                                                                {item.capacitacion_obligatoria_usuario.length > 0 && (
                                                                    <label>
                                                                        Respuestas correctas: <span className={item.capacitacion_obligatoria_usuario[0].respuestasCorrectas >= 3 ? "detalle-datos-item-exito" : "detalle-datos-item-error"}> {item.capacitacion_obligatoria_usuario[0].respuestasCorrectas}/5</span>
                                                                    </label>
                                                                )}
                                                                <div className="detalle-datos-barra"></div>
                                                                <span className="detalle-datos-item">Capacitació completada: {ObtenerFechaFormatoCorto(item.capacitacion_obligatoria_usuario[0].fechaCompletada)} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                                
                                            })
                                    : "No existe data"
                                }
                                </div>   
                            </TabPanel>
                        </TabViewEva>
                    )}
                </div>
            </div>
            <Dialog visible={mostrarAdvertencia} onHide={() => setMostrarAdvertencia(false)} footer={footerDialog} className="p-eva-dialogEditar">
                <div className="header-general" style={{ textAlign: "center" }}>
                    <label>Editar capacitación</label>
                </div>
                <div className="p-eva-labelForm" style={{ width: 492, paddingTop: 16 }}>
                    <label>Si cambias la sección “Preguntas del test”, se verá afectado las respuestas de los colaboradores que ya terminaron su capacitación. Te recomendamos solo editar la sección “Material de capacitación”.</label>
                </div>
            </Dialog>

            <Dialog visible={mostrarAlertaPendientes} onHide={() => setMostrarAlertaPendientes(false)} footer={footerAlerta} className="p-eva-dialogAlerta">
                {listaCapaObligatoriaUsuario && 
                 listaCapaObligatoriaUsuario.length>0 &&
                (
                    <div className="header-general" style={{ textAlign: "center" }}>
                        <label>¡Hola! Tienes {listaCapaObligatoriaUsuario.filter((x) => x.estadoTerminado == false).length == 1 ? "1 capacitación pendiente" : listaCapaObligatoriaUsuario.filter((x) => x.estadoTerminado == false).length + " capacitaciones pendientes"}</label>
                    </div>
                )}
                {listaCapaObligatoriaUsuario &&
                    listaCapaObligatoriaUsuario.length>0 &&
                    (listaCapaObligatoriaUsuario[0].semestre == 1 ? (
                        <div className="p-eva-labelForm" style={{ width: 492, paddingTop: 16 }}>
                            <label>
                                Tienes hasta el 30 de junio para completar la capacitación obligatoria que tienes pendiente. Si no lo completas hasta dicha fecha, no podrás acceder al beneficio de tu cuponera de tiempo libre {listaCapaObligatoriaUsuario[0].anio} - II semestre (Julio - Diciembre).
                            </label>
                        </div>
                    ) : (
                        <div className="p-eva-labelForm" style={{ width: 492, paddingTop: 16 }}>
                            <label>
                                Tienes hasta el 31 de diciembre para completar la capacitación obligatoria que tienes pendiente. Si no lo completas hasta dicha fecha, no podrás acceder al beneficio de tu cuponera de tiempo libre {listaCapaObligatoriaUsuario[0].anio + 1} - I semestre (Enero - Junio).
                            </label>
                        </div>
                    ))}
            </Dialog>
        </div>
    );
}
 
export default CapaObligatoria;