import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import { ObtenerCategoriaCapacitacion,RegistrarCapacitacionObligatoria,ObtenerCapacitacionObligatoriaPorId,ActualizarCapacitacionObligatoria} from "../../../service/CapacitacionService";
import ToastEva from "../../../components/Toast/ToastEva";
import TextareaDefault from "../../../components/TextArea/TextArea";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
import Boton from "../../../components/Boton/Boton";
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import { InputSwitch } from "primereact/inputswitch";
const EditarCapaObligatoria = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Crear nueva capacitación");
    const [modoEdicion, setModoEdicion] = useState(false);
    const [capaObligatoria, setCapaObligatoria] = useState(null);
    const [listaCategorias, setListaCategorias] = useState(null);
    const [numeroPregunta, setNumeroPregunta] = useState(1);
    const [isValid, setIsValid] = useState(true);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { idCapacitacion } = useParams();

    

    useEffect(()=>{

        const ObtenerCategorias = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerCategoriaCapacitacion({jwt}).then(data =>
                {
                    setListaCategorias(data)

                })
        }
        if(!listaCategorias){
            ObtenerCategorias();
        }
    },[])

    useEffect(()=>{
        const ObtenerPorId = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCapacitacion;
            await ObtenerCapacitacionObligatoriaPorId({jwt,Id}).then(data =>
                {
                    setCapaObligatoria(data)

                    // setModoEdicion(true)
                    // setTituloPagina("Editar capacitación");
                })
        }
        if(idCapacitacion){
            ObtenerPorId();
        }
    },[])


    useEffect(()=>{
        if(capaObligatoria  && !modoEdicion) {

            setTituloPagina("Editar capacitacion")
            setModoEdicion(true)
            setNumeroPregunta(capaObligatoria.capacitacion_obligatoria_preguntas.length)
        }
    },[capaObligatoria])

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();

          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    const schema = Yup.object().shape({
        nombreCapacitacion: Yup.string().required("Campo nombre capacitación requerido."),
        idCategoria: Yup.string().required("Campo categoría capacitación requerido."),
        descripcion: Yup.string().required("Campo descripción requerido."),
    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            id:modoEdicion && capaObligatoria?capaObligatoria.id:0,
            nombreCapacitacion: modoEdicion  && capaObligatoria?capaObligatoria.nombreCapacitacion:"",
            idCategoria : modoEdicion && capaObligatoria?capaObligatoria.idCategoria:"",
            descripcion: modoEdicion && capaObligatoria?capaObligatoria.descripcion:"",
            documentos: modoEdicion  && capaObligatoria && capaObligatoria.capacitacion_obligatoria_documentos.length >0? capaObligatoria.capacitacion_obligatoria_documentos.filter(x=>x.tipo=="Adjunto"&& x.estado == true) : [{imagenBase64:null,tipoDocumento:null,id:0,
                idCapacitacionObligatoria:0,nombreDocumento:'',tipo:'Adjunto',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            listaUrls : modoEdicion  && capaObligatoria && capaObligatoria.capacitacion_obligatoria_documentos.length >0? capaObligatoria.capacitacion_obligatoria_documentos.filter(x=> x.tipo=="Url"&& x.estado == true) : [{id:0,
                idCapacitacionObligatoria:0,nombreDocumento:'',tipo:'Url',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            listaPreguntas: modoEdicion && capaObligatoria && capaObligatoria.capacitacion_obligatoria_preguntas.length >0?capaObligatoria.capacitacion_obligatoria_preguntas
                        : [{id:0, detallePregunta:"", numeroPregunta:numeroPregunta, idCapacitacionObligatoria:0, capacitacion_obligatoria_alternativas : [{id:0, detalleAlternativa:"", esRespuesta:false, idPregunta:0}]}]
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id
        let nombreCapacitacion = values.nombreCapacitacion
        let idCategoria = values.idCategoria
        let descripcion = values.descripcion
        let listaDocCompleto = values.documentos[0].nombreDocumento.length >0 ? values.documentos :[];
        let listaUrls = values.listaUrls[0].nombreDocumento.length >0 ? values.listaUrls :[];
        let capacitacion_obligatoria_preguntas = values.listaPreguntas;


        let jsonCapacitacion = JSON.stringify({id,nombreCapacitacion,idCategoria,descripcion,listaUrls,listaDocCompleto,capacitacion_obligatoria_preguntas},null,2)
          console.log(jsonCapacitacion)
        //   formik.setSubmitting(false)
        //Registrar({jsonCapacitacion})

        let esValido = true;
        if(capacitacion_obligatoria_preguntas.length <5)
        {
            formik.setSubmitting(false)
            esValido =false;
            toast.current.show({severity:'error', summary: 'Error', detail:"Debe ingresar 5 preguntas para la presente capacitación.", life: 7000})
            
            return;
        }
        capacitacion_obligatoria_preguntas.map((pregunta,i)=>{
            if(pregunta.capacitacion_obligatoria_alternativas.length <4)
            {
                formik.setSubmitting(false)
                esValido =false;
                toast.current.show({severity:'error', summary: 'Error', detail:"Debe ingresar 4 alernativas por pregunta.", life: 7000})
                
                return 
            }
        })

        if(esValido){
            if(modoEdicion)Actualizar({jsonCapacitacion})
            else{Registrar({jsonCapacitacion})}
        }
         


      },
    });

    const validarLimiteAlternativas=(index)=>{
        let numAlternativas = formik.values.listaPreguntas[index].capacitacion_obligatoria_alternativas.length;
        if(numAlternativas <= 3) return true
        else{return false}
    }
    const validarLimitePreguntas=()=>{
        let numPreguntas = formik.values.listaPreguntas.length;
        if(numPreguntas <=4) return true
        else{return false}
    }
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }

    const Registrar =({jsonCapacitacion})=>{
        let jwt = window.localStorage.getItem("jwt");
        RegistrarCapacitacionObligatoria({jsonCapacitacion,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro exitoso.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }

    const Actualizar =({jsonCapacitacion})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarCapacitacionObligatoria({jsonCapacitacion,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Actualización exitosa.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }
    return (
        <div className="eva-dashboard-editarCapaObligatoria" style={{ marginLeft: 40 }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="header-subtitle"><span>Material de capacitacion</span></div>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-8">
                                <label htmlFor="nombreCapacitacion">Nombre de la capacitación</label>
                                <InputDefault
                                    id="nombreCapacitacion"
                                    name="nombreCapacitacion"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.nombreCapacitacion != undefined && (formik.errors.nombreCapacitacion ? "error" : "success")}
                                    value={formik.values.nombreCapacitacion}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <p className="error">{formik.errors.nombreCapacitacion && formik.touched.nombreCapacitacion && formik.errors.nombreCapacitacion}</p>
                            </div>
                            <div  className="field col-12 md:col-4">
                                <label htmlFor="idCategoria">Ingresa la categoría de la capacitación</label>
                                <DropdownDefault
                                    id="idCategoria"
                                    name="idCategoria"
                                    options={listaCategorias}
                                    optionLabel="nombre"
                                    optionValue="id"
                                     errorClass={formik.touched.idCategoria != undefined&& (formik.errors.idCategoria?"error":"success")}
                                     placeholder="Seleccione" value={formik.values.idCategoria} onChange={formik.handleChange}
                                     onblur={formik.handleBlur}/>
                                <p className="error">{formik.errors.idCategoria && formik.touched.idCategoria && formik.errors.idCategoria}</p>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                    value={formik.values.descripcion}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega links de la capacitación</label>
                            </div>
                            <FieldArray
                                name="listaUrls"
                                render={(arrayHelpersLink) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaUrls &&
                                                    formik.values.listaUrls.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <InputIcon
                                                                    id={`listaUrls[${index}].nombreDocumento`}
                                                                    name={`listaUrls[${index}].nombreDocumento`}
                                                                    className="white"
                                                                    placeholder="Ingresa el link aquí"
                                                                    errorClass={formik.touched.nombreDocumento != undefined && (formik.errors.nombreDocumento ? "error" : "success")}
                                                                    value={formik.values.listaUrls[index].nombreDocumento}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    icon={formik.values.listaUrls[index].nombreDocumento != "" && <Iconsax.Trash />}
                                                                    onClickIcon={() => onDeleteLink(index, arrayHelpersLink)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLink.push({id:0,
                                                idCapacitacionMia:0,nombreDocumento:'',tipo:'Url',estado:false,fechaEliminacion:null,usuarioEliminacion:null})}>
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega material de apoyo</label>
                            </div>
                            <FieldArray
                                name="documentos"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.documentos &&
                                                    formik.values.documentos.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.documentos[index].nombreDocumento.length > 0 ? formik.values.documentos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`documentos[${index}].nombreDocumento`}
                                                                    name={`documentos[${index}].nombreDocumento`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.documentos[index].nombreDocumento}
                                                                    onChange={(e)=>{formik.values.documentos[index].imagenBase64 && formik.handleChange(e)}}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.documentos[index].nombreDocumento != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpers)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`documentos[${index}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`documentos[${index}].nombreDocumentoIcon`}
                                                                    name={`documentos[${index}].nombreDocumentoIcon`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`documentos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multiple" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                <input
                                                    type="file"
                                                    id={`file-multiple`}
                                                    name={`file-multiple`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles= e.currentTarget.files
                                                        console.log(tempFiles)
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64  =""
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayHelpers.push({ imagenBase64: base64, tipoDocumento: tipo, id: 0, idCapacitacionMia: 0, nombreDocumento: nombre, tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null })
                                                            });
                                                        }
                                                      
                                                    }}
                                                    accept="application/pdf,audio/*,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                        </div>

                        <div className="header-subtitle"><span>Preguntas del test</span></div>
                        <div className="p-fluid formgrid grid">
                            <FieldArray
                                name="listaPreguntas"
                                render={(arrayHelpersPreguntas) => (
                                    <>
                                        <div className="field col-12 md:col-12">
                                            <div className="p-fluid formgrid grid">
                                                {
                                                    formik.values.listaPreguntas &&
                                                        formik.values.listaPreguntas.map((documento, index) => (
                                                            <>
                                                                <div className="field col-12 md:col-10" key={index}>
                                                                    <label htmlFor={`listaPreguntas[${index}].detallePregunta`} style={{color:"#F47647",fontWeight:600}}>Añade pregunta al test</label>
                                                                    <InputDefault
                                                                        id={`listaPreguntas[${index}].detallePregunta`}
                                                                        name={`listaPreguntas[${index}].detallePregunta`}
                                                                        className="white"
                                                                        placeholder="Escribe aquí"
                                                                        errorClass={formik.touched.detallePregunta != undefined && (formik.errors.detallePregunta ? "error" : "success")}
                                                                        value={formik.values.listaPreguntas[index].detallePregunta}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur} />
                                                                    <p className="error">{formik.errors.detallePregunta && formik.touched.detallePregunta && formik.errors.detallePregunta}</p>
                                                                </div>
                                                                <FieldArray
                                                                    name={`listaPreguntas[${index}].capacitacion_obligatoria_alternativas`}
                                                                    render={(arrayHelpersAlernativa) => (
                                                                        <>
                                                                        {
                                                                            formik.values.listaPreguntas[index].capacitacion_obligatoria_alternativas &&
                                                                            formik.values.listaPreguntas[index].capacitacion_obligatoria_alternativas.map((documento, jIndex) => (
                                                                                <>
                                                                                    {/* <div  className="field col-12 md:col-12"><label htmlFor={`listaPreguntas[${index}].capacitacion_obligatoria_alternativas[${jIndex}].detalleAlternativa`}>Añade la respuesta</label></div> */}
                                                                                    <div className="field col-12 md:col-10">
                                                                                        <label htmlFor={`listaPreguntas[${index}].capacitacion_obligatoria_alternativas[${jIndex}].detalleAlternativa`} style={{fontWeight:500,fontSize:14,color:"#000000"}}>Añade la respuesta</label>
                                                                                        <InputDefault
                                                                                            id={`listaPreguntas[${index}].capacitacion_obligatoria_alternativas[${jIndex}].detalleAlternativa`}
                                                                                            name={`listaPreguntas[${index}].capacitacion_obligatoria_alternativas[${jIndex}].detalleAlternativa`}
                                                                                            className="white"
                                                                                            placeholder="Escribe aquí"
                                                                                            errorClass={formik.touched.detalleAlternativa != undefined && (formik.errors.detalleAlternativa ? "error" : "success")}
                                                                                            value={formik.values.listaPreguntas[index].capacitacion_obligatoria_alternativas[jIndex].detalleAlternativa}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur} />
                                                                                        <p className="error">{formik.errors.detalleAlternativa && formik.touched.detalleAlternativa && formik.errors.detalleAlternativa}</p>
                                                                                    </div>
                                                                                    <div className="field col-12 md:col-2" style={{display:"flex",alignItems:"center"}}>
                                                                                        <span style={{paddingRight:16,fontSize:12}}>Respuesta correcta</span>
                                                                                        <InputSwitch
                                                                                            id={`listaPreguntas[${index}].capacitacion_obligatoria_alternativas[${jIndex}].esRespuesta`}
                                                                                            name={`listaPreguntas[${index}].capacitacion_obligatoria_alternativas[${jIndex}].esRespuesta`}
                                                                                            checked={formik.values.listaPreguntas[index].capacitacion_obligatoria_alternativas[jIndex].esRespuesta}
                                                                                            onChange={(e=>{
                                                                                                formik.setFieldValue(`listaPreguntas[${index}].capacitacion_obligatoria_alternativas[${jIndex}].esRespuesta`, e.value);


                                                                                            })}></InputSwitch>
                                                                                    </div>

                                                                                </>
                                                                            ))
                                                                        }
                                                                        {
                                                                            validarLimiteAlternativas(index) &&
                                                                            <div className="field col-12 md:col-12">
                                                                                <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "end" }}>
                                                                                    <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersAlernativa.push(
                                                                                        {id:0, detalleAlternativa:"", esRespuesta:false, idPregunta:0}
                                                                                        )}>
                                                                                        Añadir respuesta
                                                                                    </i>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        </>


                                                                    )}
                                                                >

                                                                </FieldArray>
                                                                {index != formik.values.listaPreguntas.length-1 && <div className="field col-12 md:col-12" style={{height:2,background:"#000000",opacity:0.1,marginTop:10}}></div>}
                                                            </>

                                                        ))
                                                }

                                            </div>

                                        </div>
                                        {
                                            validarLimitePreguntas() &&
                                            <div className="field col-12 md:col-12">
                                                <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "center" }}>
                                                    <i style={{ cursor: "pointer" }} onClick={() => {
                                                        arrayHelpersPreguntas.push(
                                                            {id:0, detallePregunta:"", numeroPregunta:formik.values.listaPreguntas.length +1, idCapacitacionObligatoria:0, capacitacion_obligatoria_alternativas : [{id:0, detalleAlternativa:"", esRespuesta:false, idPregunta:0}]}
                                                            )
                                                        // setNumeroPregunta(numeroPregunta+1)

                                                    }}>
                                                        Añadir pregunta
                                                    </i>
                                                </div>
                                                
                                            </div>
                                        }

                                    </>
                                )}
                            >

                            </FieldArray>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton
                                nombre={"Cancelar"}
                                color="secondary"
                                widths="184px"
                                metodoClick={()=>navigate(-1)}
                            ></Boton>
                            <Boton nombre={modoEdicion?"Actualizar":"Registrar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>

     );
}

export default EditarCapaObligatoria;