import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { Skeleton } from "primereact/skeleton";
import "./Actividades.scss"
import Boton from "../../../components/Boton/Boton";

import { ObtenerFechaFormatoCorto } from "../../../helpers/helpers";
import * as constantes from "../../../constants/constantes";
import { ObtenerListaSeccionClima,ObtenerActividades ,EliminarCelebracionPorId,EliminarCalendarioPorId} from "../../../service/ClimaService";
import classNames from "classnames";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from "primereact/dialog";
const Comunicaciones = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario()
    const [tituloPagina, setTituloPagina] = useState(null);
    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [actividades, setActividades] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    const op = useRef([]);
    const fechActual = new Date();
    const [isLoading, setIsLoading] = useState(false);
    const mesActual = fechActual.getMonth() +1
    const [idSeleccionado, setIdSeleccionado] = useState(null);
    const [idActividadSeleccionado, setIdActividadSeleccionado] = useState(null);

    const [showDialog, setShowDialog] = useState(false);
    const [showDialogActividad, setShowDialogActividad] = useState(false);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarClima") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verClima") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])
    const mesAnio =[{numeroMes:"01",nombreMes :"Enero"},
    {numeroMes:"02",nombreMes :"Febrero"},
    {numeroMes:"03",nombreMes :"Marzo"},
    {numeroMes:"04",nombreMes :"Abril"},
    {numeroMes:"05",nombreMes :"Mayo"},
    {numeroMes:"06",nombreMes :"Junio"},
    {numeroMes:"07",nombreMes :"Julio"},
    {numeroMes:"08",nombreMes :"Agosto"},
    {numeroMes:"09",nombreMes :"Setiembre"},
    {numeroMes:"10",nombreMes :"Octubre"},
    {numeroMes:"11",nombreMes :"Noviembre"},
    {numeroMes:"12",nombreMes :"Diciembre"}]

    useEffect(()=>{
        const obtenerListaSeccionClima = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccionClima);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccionClima){
            obtenerListaSeccionClima();
        } 
    },[idSeccionClima])

    const getActividades = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerActividades({jwt}).then(data => 
                {
                    setActividades(data)

                })
        }
    useEffect(()=>{
        
        if(!actividades)getActividades()
    },[])

const EliminarCelebracion=()=>{
    setIsLoading(true)
    let jwt = window.localStorage.getItem("jwt");
    let Id = idSeleccionado
    EliminarCelebracionPorId({Id,jwt}).then(data => 
    {               
        toast.current.show({severity:'success', summary: 'Success', detail:"Registro eliminado exitosamente.", life: 7000})

        setShowDialog(false)
        getActividades()
        setIsLoading(false)

    }).catch(errors => {
        
        toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
        setIsLoading(false)

    })

}

const EliminarActividad=()=>{
    setIsLoading(true)
    let jwt = window.localStorage.getItem("jwt");
    let Id = idActividadSeleccionado
    EliminarCalendarioPorId({Id,jwt}).then(data => 
    {               
        toast.current.show({severity:'success', summary: 'Success', detail:"Registro eliminado exitosamente.", life: 7000})

        setShowDialogActividad(false)
        getActividades()
        setIsLoading(false)

    }).catch(errors => {
        
        toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
        setIsLoading(false)

    })

}
const footerDelete = 
                <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialog(false)}></Boton>
                    <Boton nombre={"Eliminar evento"} widths="50%" color="primary" metodoClick={EliminarCelebracion} 
                    loading = {isLoading}></Boton>
                </div>;
const footerDeleteActividad = 
<div style={{display:"flex",justifyContent:"center",width:"100%"}}>
    <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialogActividad(false)}></Boton>
    <Boton nombre={"Eliminar actividad"} widths="50%" color="primary" metodoClick={EliminarActividad} 
    loading = {isLoading}></Boton>
</div>;
    return ( 
        <div className="eva-actividades" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    {/* <div className="header">{actividades && <label htmlFor="header">{actividades.tituloComunicaciones}</label>}</div> */}

                    <div className="header-right">{actividades&&permisoEditar && tituloPagina && <Link to={"../Clima/Seccion/" + idSeccionClima + "/CrearComunicaciones"}>{"Crear "+tituloPagina}</Link>}</div>
                </div>
            </div>
            <div className="p-eva-dashboard-actividades" style={{ marginTop: 16 }}>
                {/* <div className="actividades-comunicacion-header header-subtitle" style={{marginTop:24}}>Espacios de Comunicación Interna</div> */}
                <div className="actividades-comunicacion-body" style={{marginTop:16}}>
                    {/* <div className="comunicacion-body-descripcion">
                        Vivimos una cultura horizontal y transparente, sé parte de nuestros espacios de comunicación.
                    </div> */}
                    <div className="comunicacion-body-descripcion">
                    {actividades? actividades.subTitulo3:""}
                    </div>

                    <div className="body-repositorio">
                        {
                            actividades && 
                            actividades.clima_actividades_celebracion.filter(x=>x.seccion=="COMUNICACION").map((item,i)=>{
                                return(
                                    <div className="repositorio-item" key={i}>
                                        {
                                            permisoEditar && 
                                                <div className="repositorio-item-delete" onClick={()=>
                                                {
                                                    setIdSeleccionado(item.id)
                                                    setShowDialog(true)
                                                }}>
                                                    <Iconsax.Trash color="#FFFFFF"/>
                                                </div>
                                        }
                                        
                                        <div className="repositorio-item-header">
                                           
                                             <div style={{height:136}}>
                                                <img src={constantes.URLBLOB_CLIMA_ACTIVIDADES_COMUNICACION+"/"+item.nombreFoto}
                                                    style={{maxWidth:312,maxHeight:136}}></img>
                                             </div>
                                            
                                            <div>{item.nombre}</div> 
                                        </div>
                                        <div className="repositorio-item-footer">
                                            <Boton nombre={"Ver más"} widths="312px" color="secondary" margin={0} metodoClick={()=>navigate("../Clima/Seccion/"+idSeccionClima+"/DetalleEvento/"+item.id)}></Boton>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </div>
            <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                footer={footerDelete}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar este evento?</label>
                
            </Dialog>

            <Dialog visible={showDialogActividad} modal  onHide={()=>setShowDialogActividad(false)} 
                footer={footerDeleteActividad}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar esta actividad?</label>
            </Dialog>
        </div>
     );
}
 
export default Comunicaciones;