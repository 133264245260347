import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerReconocimientoCategoriaPorId } from "../../../service/ClimaService";
import { Skeleton } from "primereact/skeleton";
import "./Reconocimiento.scss"
import Boton from "../../../components/Boton/Boton";

import { ObtenerFechaFormatoPalabra } from "../../../helpers/helpers";
import * as constantes from "../../../constants/constantes";
import CardFile from "../../../components/CardFileEva/CardFile";
import { Categories } from "emoji-picker-react";
import ContactCard from "../../../components/ContactCard/ContactCard";
import ContactCardV2 from "../../../components/ContactCard/ContactCardV2";

const DetalleCategoria = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [categoria, setCategoria] = useState(null);
    const [imagen, setImagen ] = useState(null);

    const toast = useRef(null);
    let { idCategoria } = useParams();
    let { idSeccionClima } = useParams();

    const fechaActual = new Date()
    const anioActual = fechaActual.getFullYear();
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarClima") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verClima") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        const getCategoria= async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let id =  idCategoria
            await ObtenerReconocimientoCategoriaPorId({jwt,id}).then(data => 
                {
                    setCategoria(data)
                    setTituloPagina(data.nombre)
                    const img = data.clima_reconocimiento_categoria_documento[0].nombreDocumento
                    setImagen(constantes.URLBLOB_CLIMA_RECONOCIMIENTO+"/Documentos/"+img)

                })
        }
        if(idCategoria)getCategoria()
    },[idCategoria])

    return ( 
        <div className="eva-detalleCategoria" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    {
                        permisoEditar &&
                        <div className="header-right">
                            {categoria &&<Link to={"../Clima/Seccion/"+idSeccionClima+"/EditarCategoria/"+categoria.id}>{"Editar " + tituloPagina}</Link>}
                        </div>
                    }
                </div>
                {/* <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div> */}
                <div className="header">{categoria && <label htmlFor="header">{categoria.nombre}</label>}</div>

               
            </div>
            <div className="p-eva-dashboard-detalleEventos" style={{ marginTop: 16 }}>
                <div className="p-eva-labelForm">
                    <span style={{whiteSpace:"pre-line"}}>{categoria ? categoria.descripcion : <Skeleton className="mb-2" width="700px" height="100%" borderRadius="16px" />}</span>
                </div>
                {/* <div className="p-eva-dashcboard-documentos" style={{ marginTop: 24 }}>
                    <div className="p-fluid formgrid grid">
                        {
                            categoria &&
                            categoria.clima_reconocimiento_categoria_documento.length > 0 &&
                            categoria.clima_reconocimiento_categoria_documento
                                .filter((x) => x.tipo == "Adjunto" && x.estado == true)
                                .map((item,i)=>{
                                    var re = /(?:\.([^.]+))?$/;
                                    return(
                                        <div className="field col-12 md:col-3" key={i}>
                                                <CardFile urlFile={constantes.URLBLOB_CLIMA_RECONOCIMIENTO+"/Documentos/"+item.nombreDocumento} key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} nombreArchivo={item.nombreDocumento} keys={i} tipo="Adjuntos" descargable={false} />
                                            </div>
                                    )

                                })
                        }

                        
                    </div>
                </div> */}

                <div style={{ marginTop: 24}}>
                    <img src={imagen}   style={{cursor:"pointer",height: "100%", width: "100%"}}></img>
                </div>
                {/* <div className="header-subtitle" style={{marginTop:24}}>Conoce a nuestros reconocidos - {anioActual}</div> */}
                {/* <div className="header-subtitle" style={{marginTop: 48}}> {categoria ? categoria.subTitulo : ""}</div> */}
                <div className="header-subtitle" style={{marginTop: 65, fontSize: '25px'}}> {categoria ? categoria.subTitulo : ""}</div>

                <div className="influencer-usuario" style={{marginTop:90}}>
                    <div className="p-fluid formgrid grid">
                        {
                            categoria &&
                            categoria.clima_reconocimiento_categoria_usuario.length > 0 &&
                            categoria.clima_reconocimiento_categoria_usuario.map((item,i)=>{
                                return(
                                    <div className="field col-12 md:col-4" key={i} style={{marginBottom:60}}>
                                        <ContactCardV2
                                            idUser={item.usuario.id}
                                            area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                            gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                            foto ={item.usuario.nombreFoto}
                                            puesto={item.usuario.puesto}
                                            nombreUser = {item.usuario.nombre}
                                            email = {item.usuario.correo}
                                            fechaNacimiento = {item.usuario.fechaNacimiento}
                                            celular = {item.usuario.celular}
                                            colorFondo="#FFFFFF"
                                        ></ContactCardV2>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default DetalleCategoria;