import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";

import ToastEva from "../../../components/Toast/ToastEva";
import { ListUsersTag} from "../../../service/UsuariosService";
import {ObtenerOrganigrama,ActualizarOrganigrama} from "../../../service/SomosinService";
import TextareaDefault from "../../../components/TextArea/TextArea";
import InputIcon from "../../../components/InputIcon/InputIcon";
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../components/Boton/Boton";
import InputDefault from "../../../components/InputDefault/InputDefault";
const EditarOrganigrama = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Editar organigrama");
    const [modoEdicion, setModoEdicion] = useState(true);
    const [organigrama, setOrganigrama] = useState(null);
    const [listUserTotal, setListUserTotal] = useState([]);
    const [selected, setSelected] = useState([]);
    let { idOrganigrama } = useParams();

    const toast = useRef(null);

    useEffect(()=>{
        const GetOrganigrama = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerOrganigrama({jwt}).then(data=>setOrganigrama(data))
            .catch(errors => {
                toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

            })
        }
        GetOrganigrama()
    },[])   
    useEffect(()=>{
        const obtenerUsuariosTotal = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListUsersTag(jwt).then(data => setListUserTotal(data))
        }

        if(listUserTotal.length === 0)
        {
            obtenerUsuariosTotal();
        }
     },[]);

    

    useEffect(()=>{
        const CargarContactoTema = async(organigramaSelected)=>
        {
            let contactosOrganigrama = [];

            if( organigramaSelected.organigrama_contacto )
            {
                await organigramaSelected.organigrama_contacto.map((item,i)=>{
                    let nombreUser = listUserTotal.filter(x=> x.id == item.idUsuario)
                    contactosOrganigrama.push({label: nombreUser[0].label,value:String(item.idUsuario)})
                })
                setSelected(contactosOrganigrama);
            }
           
        }
        if(organigrama && selected.length ==0 && listUserTotal) {
            
            CargarContactoTema(organigrama);
        }

        
    },[organigrama,listUserTotal])

    const schema = Yup.object().shape({
       
    });

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          //console.log(fileInfo);
        });
      };
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const Actualizar =({jsonOrganigrama})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarOrganigrama({jsonOrganigrama,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Organigrama actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:modoEdicion && organigrama?organigrama.id:0,
            descripcion: modoEdicion  && organigrama?organigrama.descripcion:"",
            subTitulo1: modoEdicion  && organigrama?organigrama.subTitulo1?organigrama.subTitulo1:"":"",
            documentos: modoEdicion  && organigrama? organigrama.organigrama_documento.filter(x=>x.descargable == false && x.tipo=="Adjunto"&& x.estado == true) : [{imagenBase64:null,tipoDocumento:null,id:0,
                idOrganigrama:0,nombre:'',tipo:'Adjunto',descargable:false,estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            documentosDescargables: modoEdicion  && organigrama? organigrama.organigrama_documento.filter(x=>x.descargable == true && x.tipo=="Adjunto"&& x.estado == true) : [{imagenBase64:null,tipoDocumento:null,id:0,
                    idOrganigrama:0,nombre:'',tipo:'Adjunto',descargable:true,estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            listaUrls : modoEdicion  && organigrama? organigrama.organigrama_documento.filter(x=> x.tipo=="Url"&& x.estado == true) : [{id:0,
                idLiderin:0,nombre:'',tipo:'Url',descargable:false,estado:false,fechaEliminacion:null,usuarioEliminacion:null}]
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id
       
        let descripcion = values.descripcion
        let subTitulo1 = values.subTitulo1
        
        var listaUsuariosContacto = [];
        if(selected.length > 0)
        {
            selected.map((item,i) =>{
                listaUsuariosContacto.push({idUsuario:item.value});
            })
        }

        let listaContactos = listaUsuariosContacto;
        // let nombreImagenTarjeta = stateImage.file === null ? "":stateImage.file.name;
        // let imagenBase64 = stateImage.file === null?"": stateImage.base64URL;
        // let listaDocCompleto = stateArrayDocument;
     
        let listaDocCompleto = values.documentos;
        let listaDocCompletoDescargable = values.documentosDescargables;
        let listaUrls = values.listaUrls;
        
        let jsonOrganigrama = JSON.stringify({id,descripcion,subTitulo1,listaContactos,listaUrls,listaDocCompleto,listaDocCompletoDescargable},null,2)
         console.log(jsonOrganigrama)
        // //alert(jsonTema);
        Actualizar({jsonOrganigrama})
        
      },
    });
    const handleSelectChange = (values) => {
        if(values.length < 3)
        {
            setSelected(values);
            console.log(values)
        }
        
    };
    
    return (
        <div className="eva-dashboard-editarOrganigrama">
            <ToastEva ref={toast} position="top-center"></ToastEva>

            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label>{tituloPagina}</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="subTitulo1">Agrega el Subtítulo</label>
                                <InputDefault
                                    id="subTitulo1"
                                    name="subTitulo1"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.subTitulo1!= undefined && (formik.errors.subTitulo1 ? "error" : "success")}
                                    value={formik.values.subTitulo1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                
                                <small className="p-error">{formik.touched.subTitulo1 && formik.errors.subTitulo1}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega documentos descargables</label>
                            </div>
                            <FieldArray
                                name="documentosDescargables"
                                render={(arrayHelpersDesc) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.documentosDescargables &&
                                                    formik.values.documentosDescargables.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.documentosDescargables[index].nombre.length > 0 ? formik.values.documentosDescargables[index].nombre : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`documentosDescargables[${index}].nombre`}
                                                                    name={`documentosDescargables[${index}].nombre`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.documentosDescargables[index].nombre}
                                                                    onChange={(e)=>{formik.values.documentosDescargables[index].imagenBase64 && formik.handleChange(e)}}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.documentosDescargables[index].nombre != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpersDesc)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`documentosDescargables[${index}].nombreIcon`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.Gallery />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                <input
                                                                    type="file"
                                                                    id={`documentosDescargables[${index}].nombreIcon`}
                                                                    name={`documentosDescargables[${index}].nombreIcon`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`documentosDescargables[${index}].nombre`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`documentosDescargables[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`documentosDescargables[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersDesc.push({ imagenBase64: null, tipoDocumento: null, id: 0, idLiderin: 0, nombre: "", tipo: "Adjunto", descargable: true, estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multiple" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                <input
                                                    type="file"
                                                    id={`file-multiple`}
                                                    name={`file-multiple`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles= e.currentTarget.files
                                                        console.log(tempFiles)
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64  =""
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayHelpersDesc.push({ imagenBase64: base64, tipoDocumento: tipo, id: 0, idLiderin: 0, nombre: nombre, tipo: "Adjunto", descargable: true, estado: false, fechaEliminacion: null, usuarioEliminacion: null })
                                                            });
                                                        }
                                                      
                                                    }}
                                                    accept="application/pdf,audio/*,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega documentos</label>
                            </div>
                            <FieldArray
                                name="documentos"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.documentos &&
                                                    formik.values.documentos.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.documentos[index].nombre.length > 0 ? formik.values.documentos[index].nombre : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`documentos[${index}].nombre`}
                                                                    name={`documentos[${index}].nombre`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.documentos[index].nombre}
                                                                    onChange={(e)=>{formik.values.documentos[index].imagenBase64 && formik.handleChange(e)}}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.documentos[index].nombre != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpers)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`documentos[${index}].nombre`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.Gallery />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`documentos[${index}].nombre`}
                                                                    name={`documentos[${index}].nombre`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`documentos[${index}].nombre`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idLiderin: 0, nombre: "", tipo: "Adjunto", descargable: false, estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multiple_ndesc" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                <input
                                                    type="file"
                                                    id={`file-multiple_ndesc`}
                                                    name={`file-multiple_ndesc`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles= e.currentTarget.files
                                                        console.log(tempFiles)
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64  =""
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayHelpers.push({ imagenBase64: base64, tipoDocumento: tipo, id: 0, idLiderin: 0, nombre: nombre, tipo: "Adjunto", descargable: false, estado: false, fechaEliminacion: null, usuarioEliminacion: null })
                                                            });
                                                        }
                                                      
                                                    }}
                                                    accept="application/pdf,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega links de videos</label>
                            </div>
                            <FieldArray
                                name="listaUrls"
                                render={(arrayHelpersLink) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaUrls &&
                                                    formik.values.listaUrls.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <InputIcon
                                                                    id={`listaUrls[${index}].nombre`}
                                                                    name={`listaUrls[${index}].nombre`}
                                                                    className="white"
                                                                    placeholder="Ingresa el link aquí"
                                                                    errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")}
                                                                    value={formik.values.listaUrls[index].nombre}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    icon={formik.values.listaUrls[index].nombre != "" && <Iconsax.Trash />}
                                                                    onClickIcon={() => onDeleteLink(index, arrayHelpersLink)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLink.push({ id: 0, idLiderin: 0, nombre: "", tipo: "Url", descargable: false, estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                            <div className="field col-12 md:col-8">
                                <label className="p-eva-labelForm">Información de contacto en caso el colaborador necesite ayuda</label>
                                {/* <SearchCard onChange={handleSelectChange} 
                                                    options={listUserTotal}
                                                    value={selected}
                                                    placeholder ="Escribe aquí el nombre del colaborador"
                                                    ></SearchCard> */}
                                <MultiselectEva
                                    onChange={handleSelectChange}
                                    isMulti
                                    //options={selectButtonValues1}
                                    options={listUserTotal}
                                    value={selected}
                                />
                            </div>
                        </div>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-4" />
                            <div className="field col-12 md:col-4" />
                            <div className="field col-12 md:col-2">
                                <Boton nombre="Cancelar" color="secondary" type="button" metodoClick={() => navigate(-1)} widths="100%" />
                            </div>
                            <div className="field col-12 md:col-2">
                                <Boton nombre={modoEdicion ? "Actualizar" : "Añadir"} color="primary" type="submit" loading={formik.isSubmitting} widths="100%" />
                            </div>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default EditarOrganigrama;