import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import CalendarDefault from "../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
import {ObtenerListaSeccionClima,ObtenerActividades,RegistrarActividades} from "../../../service/ClimaService";
import { getBase64 } from "../../../helpers/helpers";
import classNames from "classnames";
import { InputSwitch } from "primereact/inputswitch";
import DropdownDefault from "../../../components/DropdownDefault/Dropdown";
import { ListarAreas } from "../../../service/GerenciaService"; 


const CrearActividades = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState(null);
    const [modoEdicion, setModoEdicion] = useState(true);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    const [actividades, setActividades] = useState(null);
    const [listAreas, setListAreas] = useState(null);

    useEffect(()=>{
        const obtenerListaSeccionClima = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccionClima);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccionClima){
            obtenerListaSeccionClima();
        } 
    },[idSeccionClima])
    useEffect(()=>{
        const getActividades = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerActividades({jwt}).then(data => 
                {
                    setActividades(data)

                })
        }
        if(!actividades)getActividades()
    },[])
    useEffect(()=>{
        const obtenerAreas=async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarAreas(jwt).then
            (data => {
            const datosTransformados = data.map(item => {
                return {
                    value: item.id,
                    code: item.nombre
                };
            });
            setListAreas(datosTransformados);
        })
            // (data => setListAreas(data))
        }

        if(!listAreas)
        {
            obtenerAreas()
        }
     },[]);

    const mesAnio =[{numeroMes:"01",nombreMes :"Enero"},
    {numeroMes:"02",nombreMes :"Febrero"},
    {numeroMes:"03",nombreMes :"Marzo"},
    {numeroMes:"04",nombreMes :"Abril"},
    {numeroMes:"05",nombreMes :"Mayo"},
    {numeroMes:"06",nombreMes :"Junio"},
    {numeroMes:"07",nombreMes :"Julio"},
    {numeroMes:"08",nombreMes :"Agosto"},
    {numeroMes:"09",nombreMes :"Setiembre"},
    {numeroMes:"10",nombreMes :"Octubre"},
    {numeroMes:"11",nombreMes :"Noviembre"},
    {numeroMes:"12",nombreMes :"Diciembre"}]

    const schema = Yup.object().shape({
        // descripcion: Yup.string().required("Campor descripción requerido."),
        // documentos: Yup.array()
        // .of(
        //     Yup.object().shape({
        //         nombreProposito: Yup.string().nullable().required("Campo nombre de propósito requerido."),
        //         nombreFoto : Yup.string().nullable().required("Campo foto requerido."),
        //         descripcionProposito :Yup.string().nullable().required("Campo descripción requerido.")
        //     })
        // )
    });
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const Actualizar =({jsonClima})=>{
        let jwt = window.localStorage.getItem("jwt");
        RegistrarActividades({jsonClima,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro creado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:actividades && actividades.id,
            calendario : [{id:0,idClimaActividad:0,mes:"",nombreActividad:"",fechaActividad:null,hora:"",inidicadorHora:"AM",linkActividad:"",color:"",idArea:0,lugar:""}],
            comunicacion :[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividad:0,nombre:"",nombreFoto : "",descripcion :"", seccion :"COMUNICACION",
                            listaDocCompleto:[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true}],
                            listaUrls:[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Url',estado:true}]
                            }],
            celebracion :[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividad:0,nombre:"",nombreFoto : "",descripcion :"", seccion :"CELEBRACION",
                            listaDocCompleto:[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true}],
                            listaUrls:[{imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Url',estado:true}]
                            }],
            Titulo : actividades? actividades.titulo:"",
            SubTitulo1 : actividades? actividades.subTitulo1:"",
            SubTitulo2 : actividades? actividades.subTitulo2:"",
            SubTitulo3 : actividades? actividades.subTitulo3:"",
            tituloComunicaciones : actividades? actividades.tituloComunicaciones:"",
            Descripcion1 : actividades? actividades.descripcion1:"",
            Descripcion2 : actividades? actividades.descripcion2:"",

    },
      validationSchema: schema,
      onSubmit: values => {
       

         let id = values.id
         values.calendario.map((item,i)=>{
            item.hora = item.hora+" "+item.inidicadorHora
         })
         let clima_actividades_calendario = values.calendario.filter(x=>x.mes != null && x.nombreActividad != "" && x.fechaActividad != null && x.hora != null)
         let clima_actividades_celebracion_comunicacion = values.comunicacion.filter(x=>(x.nombre != ""|| x.nombreFoto != "") && x.descripcion != "")
         let clima_actividades_celebracion_celebracion = values.celebracion.filter(x=>(x.nombre != ""|| x.nombreFoto != "") && x.descripcion != "")
         let Titulo =values.Titulo;
         let SubTitulo1 =values.SubTitulo1;
         let SubTitulo2 =values.SubTitulo2;
         let SubTitulo3 =values.SubTitulo3;
         let Descripcion1 = values.Descripcion1;
         let Descripcion2 = values.Descripcion2;
         let tituloComunicaciones = values.tituloComunicaciones;

         let jsonClima = JSON.stringify({id,clima_actividades_calendario,clima_actividades_celebracion_comunicacion,clima_actividades_celebracion_celebracion,Titulo,SubTitulo1,SubTitulo2,SubTitulo3,tituloComunicaciones,Descripcion1,Descripcion2},null,2)
        // //  console.log(jsonCapacitacion)
        //   formik.setSubmitting(false)
        //   alert(jsonClima);
         Actualizar({jsonClima})
        
        
      },
    });

    return (
        <div className="eva-dashboard-crearActividades" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{"Crear " + tituloPagina}</label>}</div>
                {/* <div><label htmlFor="header">{"Crear " + formik.values.Titulo}</label></div> */}

            </div>
            {/* <div className="field col-12 md:col-12">
                <label htmlFor="nombre">Agrega el título</label>
                <InputDefault
                    id="Titulo"
                    name="Titulo"
                    className="white"
                    placeholder="Escribe aquí"
                    errorClass={formik.touched.Titulo!= undefined && (formik.errors.Titulo ? "error" : "success")}
                    value={formik.values.Titulo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <small className="p-error">{formik.touched.Titulo && formik.errors.Titulo}</small>
            </div> */}
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega la descripcion</label>
                                <InputDefault
                                    id="Descripcion1"
                                    name="Descripcion1"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.Descripcion1!= undefined && (formik.errors.Descripcion1 ? "error" : "success")}
                                    value={formik.values.Descripcion1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.Descripcion1 && formik.errors.Descripcion1}</small>
                            </div>
                            {/* <div className="field col-12 md:col-12 header-subtitle">Calendario anual de actividades</div> */}
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo1}</div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el Sutítulo</label>
                                <InputDefault
                                    id="SubTitulo1"
                                    name="SubTitulo1"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo1!= undefined && (formik.errors.SubTitulo1 ? "error" : "success")}
                                    value={formik.values.SubTitulo1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo1 && formik.errors.SubTitulo1}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega la descripcion</label>
                                <InputDefault
                                    id="Descripcion2"
                                    name="Descripcion2"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.Descripcion2!= undefined && (formik.errors.Descripcion2 ? "error" : "success")}
                                    value={formik.values.Descripcion2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.Descripcion2 && formik.errors.Descripcion2}</small>
                            </div>
                            <FieldArray
                                name="calendario"
                                render={(arrayHelpers) => (
                                    <>
                                        {formik.values.calendario &&
                                            formik.values.calendario.map((calendar, index) => (
                                                <>
                                                    {index != 0 && <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>}
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendario[${index}].mes`}>Escoge el mes</label>
                                                        <DropdownDefault
                                                            id={`calendario[${index}].mes`}
                                                            name={`calendario[${index}].mes`}
                                                            options={mesAnio}
                                                            optionLabel="nombreMes"
                                                            optionValue="numeroMes"
                                                            errorClass={formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].mes != undefined && (formik.errors.calendario[index].mes ? "error" : "success")}
                                                            placeholder="Seleccione"
                                                            value={formik.values.calendario[index].mes}
                                                            onChange={formik.handleChange}
                                                            onblur={formik.handleBlur}
                                                        />
                                                        <small className="p-error">{formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].mes}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendario[${index}].nombreActividad`}>Nombre de la actividad</label>

                                                        <InputDefault
                                                            id={`calendario[${index}].nombreActividad`}
                                                            name={`calendario[${index}].nombreActividad`}
                                                            className="white"
                                                            errorClass={formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].nombreActividad != undefined && (formik.errors.calendario[index].nombreActividad ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.calendario[index].nombreActividad}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].nombreActividad}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendario[${index}].fechaActividad`}>Fecha de la actividad</label>
                                                        <CalendarDefault
                                                            id={`calendario[${index}].fechaActividad`}
                                                            name={`calendario[${index}].fechaActividad`}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.calendario[index].fechaActividad && new Date(formik.values.calendario[index].fechaActividad)}
                                                            placeholder="DD /MM/ AAAA"
                                                            //disabled={!enableFProgramar}
                                                            classesname={"white"}
                                                            //minDate={new Date()}
                                                            dateFormat="dd/mm/yy"
                                                        ></CalendarDefault>
                                                        <small className="p-error">{formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].fechaActividad}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-2">
                                                        <label htmlFor={`calendario[${index}].hora`}>Hora</label>
                                                        <InputDefault
                                                            id={`calendario[${index}].hora`}
                                                            name={`calendario[${index}].hora`}
                                                            className="white"
                                                            errorClass={formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].hora != undefined && (formik.errors.calendario[index].hora ? "error" : "success")}
                                                            placeholder="HH:mm"
                                                            value={formik.values.calendario[index].hora}
                                                            onChange={(e) => {
                                                                if (e.target.value.length == 0) formik.setFieldValue(`calendario[${index}].hora`, "");

                                                                let primero = /^(1[0-2]|0?[1-9])$/;
                                                                if (primero.test(e.target.value)) formik.setFieldValue(`calendario[${index}].hora`, e.target.value);
                                                                let segundo = /^(1[0-2]|0?[1-9]):$/;
                                                                if (segundo.test(e.target.value)) formik.setFieldValue(`calendario[${index}].hora`, e.target.value);

                                                                let tercero = /^(1[0-2]|0?[1-9]):[0-5]$/;
                                                                if (tercero.test(e.target.value)) formik.setFieldValue(`calendario[${index}].hora`, e.target.value);

                                                                let cuarto = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
                                                                if (cuarto.test(e.target.value)) formik.setFieldValue(`calendario[${index}].hora`, e.target.value);
                                                            }}
                                                            //onChange={formik.handleChange}
                                                            onBlur={(e) => {
                                                                let pattern = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
                                                                var valido = pattern.test(e.target.value);
                                                                if (valido) formik.handleBlur(e.target.value);
                                                                else {
                                                                    formik.setFieldValue(`calendario[${index}].hora`, "");
                                                                }
                                                            }}
                                                            //onblur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className="field col-12 md:col-2 actividad-switch" style={{ display: "flex", alignItems: "end", marginBottom: 20 }}>
                                                        <InputSwitch id={`calendario[${index}].inidicadorHora`} name={`calendario[${index}].inidicadorHora`} checked={formik.values.calendario[index].inidicadorHora} onChange={formik.handleChange} trueValue="PM" falseValue={"AM"}></InputSwitch>
                                                    </div>

                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendario[${index}].linkActividad`}>Link de la actividad</label>

                                                        <InputDefault
                                                            id={`calendario[${index}].linkActividad`}
                                                            name={`calendario[${index}].linkActividad`}
                                                            className="white"
                                                            errorClass={formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].nombreActividad != undefined && (formik.errors.calendario[index].linkActividad ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.calendario[index].linkActividad}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].linkActividad}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendario[${index}].color`}>Color</label>

                                                        <InputDefault
                                                            id={`calendario[${index}].color`}
                                                            name={`calendario[${index}].color`}
                                                            className="white"
                                                            errorClass={formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].color != undefined && (formik.errors.calendario[index].color ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.calendario[index].color}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].color}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendario[${index}].idArea`}>Area</label>
                                                        <DropdownDefault
                                                            id={`calendario[${index}].idArea`}
                                                            name={`calendario[${index}].idArea`}
                                                            className="white"
                                                            options={listAreas}
                                                            optionLabel="code"
                                                            errorClass={formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].idArea != undefined && (formik.errors.calendario[index].idArea ? "error" : "success")}
                                                            placeholder="Seleccione"
                                                            value={formik.values.calendario[index].idArea}
                                                            onChange={formik.handleChange}
                                                            onblur={formik.handleBlur}
                                                        />
                           
                                                    <small className="p-error">{formik.errors.idArea && formik.touched.idArea}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendario[${index}].lugar`}>lugar</label>
                                                        <InputDefault
                                                            id={`calendario[${index}].lugar`}
                                                            name={`calendario[${index}].lugar`}
                                                            className="white"
                                                            errorClass={formik.errors.calendario && formik.errors.calendario[index] && formik.errors.calendario[index].lugar != undefined && (formik.errors.calendario[index].lugar ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.calendario[index].lugar}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />


                                                        <small className="p-error">{formik.errors.lugar && formik.touched.lugar}</small>
                                                    </div>
                                                </>
                                            ))}

                                        <div className="field col-12 md:col-12">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "center" }}>
                                                <i
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        arrayHelpers.push({ id: 0, idClimaActividad: 0, mes: "", nombreActividad: "", fechaActividad: null, hora: "", inidicadorHora: "AM", linkActividad: "",color:"",idArea:0 ,lugar:"" });
                                                        // setNumeroPregunta(numeroPregunta+1)
                                                    }}
                                                >
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            {/* <div className="field col-12 md:col-12 header-subtitle">Espacios de comunicación interna</div>
                            <FieldArray
                                name="comunicacion"
                                render={(arrayComunicacion) => (
                                    <>
                                        {formik.values.comunicacion &&
                                            formik.values.comunicacion.map((comunicacion, jIndex) => (
                                                <>
                                                    {jIndex != 0 && <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>}
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`comunicacion[${jIndex}].nombre`}>Nombre</label>

                                                        <InputDefault
                                                            id={`comunicacion[${jIndex}].nombre`}
                                                            name={`comunicacion[${jIndex}].nombre`}
                                                            className="white"
                                                            errorClass={formik.errors.comunicacion && formik.errors.comunicacion[jIndex] && formik.errors.comunicacion[jIndex].nombre != undefined && (formik.errors.comunicacion[jIndex].nombre ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.comunicacion[jIndex].nombre}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.comunicacion && formik.errors.comunicacion[jIndex] && formik.errors.comunicacion[jIndex].nombre}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`comunicacion[${jIndex}].nombreFoto`}>Adjunta una foto</label>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <label
                                                                className={classNames("eva-labelFileLider", {
                                                                    "p-eva-error": formik.errors.comunicacion && formik.errors.comunicacion[jIndex] && formik.errors.comunicacion[jIndex].nombreFoto != undefined && formik.errors.comunicacion[jIndex].nombreFoto,
                                                                })}
                                                                style={{ minHeight: 16, height: "fit-content" }}
                                                            >
                                                                <span>{formik.values.comunicacion[jIndex].nombreFoto.length > 0 ? formik.values.comunicacion[jIndex].nombreFoto : "Adjunta aquí"}</span>
                                                            </label>
                                                            {formik.values.comunicacion[jIndex].nombreFoto != "" ? (
                                                                <label className="eva-labelFileLider-icon">
                                                                    <i onClick={() => {
                                                                        formik.setFieldValue(`comunicacion[${jIndex}].nombreFoto`, "");
                                                                        formik.setFieldValue(`comunicacion[${jIndex}].imagenBase64`, null);
                                                                    }}>
                                                                        <Iconsax.Trash />
                                                                    </i>
                                                                </label>
                                                            ) : (
                                                                <label htmlFor={`comunicacion[${jIndex}].nombreFoto`} className="eva-labelFileLider-icon">
                                                                    <i>
                                                                        <Iconsax.GalleryAdd color="#F47647" />
                                                                    </i>
                                                                </label>
                                                            )}

                                                            <input
                                                                type="file"
                                                                id={`comunicacion[${jIndex}].nombreFoto`}
                                                                name={`comunicacion[${jIndex}].nombreFoto`}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`comunicacion[${jIndex}].nombreFoto`, e.currentTarget.files[0].name);
                                                                    formik.setFieldValue(`comunicacion[${jIndex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                    getBase64(e.currentTarget.files[0]).then((result) => {
                                                                        formik.setFieldValue(`comunicacion[${jIndex}].imagenBase64`, result);
                                                                    });
                                                                }}
                                                                accept="application/pdf,audio/*,image/*"
                                                                hidden
                                                            ></input>
                                                        </div>
                                                        <small className="p-error">{formik.errors.comunicacion && formik.errors.comunicacion[jIndex] && formik.errors.comunicacion[jIndex].imagenBase64}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`comunicacion[${jIndex}].descripcion`} className="p-eva-labelForm">
                                                            Añade una descripción
                                                        </label>
                                                        <TextareaDefault
                                                            id={`comunicacion[${jIndex}].descripcion`}
                                                            minHeight={180}
                                                            placeholder="Escribe aquí"
                                                            className="White"
                                                            errorClass={formik.errors.comunicacion && formik.errors.comunicacion[jIndex] && formik.errors.comunicacion[jIndex].descripcion != undefined && (formik.errors.comunicacion[jIndex].descripcion ? "error" : "success")}
                                                            value={formik.values.comunicacion[jIndex].descripcion}
                                                            icon={<Iconsax.DocumentText1 />}
                                                            name={`comunicacion[${jIndex}].descripcion`}
                                                            onChange={formik.handleChange}
                                                            onblur={formik.handleBlur}
                                                        />
                                                        <small className="p-error">{formik.errors.comunicacion && formik.errors.comunicacion[jIndex] && formik.errors.comunicacion[jIndex].descripcion}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-12" style={{marginBottom:2}}>
                                                        <label className="p-eva-labelForm">Agrega documentos</label>
                                                    </div>
                                                    <FieldArray
                                                        name={`comunicacion[${jIndex}].listaDocCompleto`}
                                                        render={(arrayComunicacionDoc) => (
                                                            <>
                                                             
                                                            <div className="field col-12 md:col-8">
                                                               
                                                                <div className="p-fluid formgrid grid">
                                                                    
                                                                {
                                                                    formik.values.comunicacion[jIndex].listaDocCompleto &&
                                                                    formik.values.comunicacion[jIndex].listaDocCompleto.map((documento, zIndex) => (
                                                                        <div className="field col-12 md:col-6" key={zIndex}>
                                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                                <InputDefault
                                                                                    id={`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                                    name={`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                                    placeholder="Adjunta aquí"
                                                                                    className="White"
                                                                                    value={formik.values.comunicacion[jIndex].listaDocCompleto[zIndex].nombreDocumento}
                                                                                    onChange={(e)=>{formik.values.comunicacion[jIndex].listaDocCompleto[zIndex].imagenBase64 && formik.handleChange(e)}}
                                                                                    onBlur={formik.handleBlur}
                                                                                ></InputDefault>
                                                                                {formik.values.comunicacion[jIndex].listaDocCompleto[zIndex].nombreDocumento != "" ? (
                                                                                    <label className="eva-labelFileLider-icon">
                                                                                        <i onClick={() => onDeleteFile(zIndex, arrayComunicacionDoc)} style={{ cursor: "pointer" }}>
                                                                                            <Iconsax.Trash />
                                                                                        </i>
                                                                                    </label>
                                                                                ) : (
                                                                                    <label htmlFor={`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                                        <i>
                                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                                        </i>
                                                                                    </label>
                                                                                )}
                                                                               <input
                                                                                    type="file"
                                                                                    id={`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} 
                                                                                    name={`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} 
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue(`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                                        formik.setFieldValue(`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                                            formik.setFieldValue(`comunicacion[${jIndex}].listaDocCompleto[${zIndex}].imagenBase64`, result);
                                                                                        });
                                                                                    }}
                                                                                    accept="application/pdf,audio/*,image/*"
                                                                                    hidden
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="field col-12 md:col-4">
                                                                <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <i style={{ cursor: "pointer" }} onClick={() => arrayComunicacionDoc.push({imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true})}>
                                                                        Añadir más
                                                                    </i>
                                                                    <label htmlFor="file-multipleCom" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                                    <input
                                                                        type="file"
                                                                        id={`file-multipleCom`}
                                                                        name={`file-multipleCom`}
                                                                        onChange={(e) => {
                                                                            console.log(e.currentTarget.files);
                                                                            let tempFiles= e.currentTarget.files
                                                                            console.log(tempFiles)
                                                                            for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                                                let nombre = e.currentTarget.files[index].name;
                                                                                let tipo = e.currentTarget.files[index].type;
                                                                                let base64  =""
                                                                                getBase64(e.currentTarget.files[index]).then((result) => {
                                                                                    base64 = result;
                                                                                    arrayComunicacionDoc.push({imagenBase64:base64,tipoDocumento:tipo,id:0,idClimaActividadCelebacion:0,nombreDocumento:nombre,tipo:'Adjunto',estado:true})
                                                                                });
                                                                            }
                                                                        
                                                                        }}
                                                                        accept="application/pdf,audio/*,image/*"
                                                                        hidden
                                                                        multiple
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                            </>
                                                        )}
                                                    ></FieldArray>
                                                     <div className="field col-12 md:col-12" style={{marginBottom:2}}>
                                                        <label className="p-eva-labelForm">Agrega links de videos</label>
                                                    </div>
                                                    <FieldArray
                                                        name={`comunicacion[${jIndex}].listaUrls`}
                                                        render={(arrayComunicacionLink) => (
                                                            <>
                                                                <div className="field col-12 md:col-8">
                                                                    <div className="p-fluid formgrid grid">
                                                                    {formik.values.comunicacion[jIndex].listaUrls &&
                                                                     formik.values.comunicacion[jIndex].listaUrls.map((documento, zIndex) => (
                                                                                <div className="field col-12 md:col-6" key={zIndex}>
                                                                                    <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                                        <InputIcon
                                                                                            id={`comunicacion[${jIndex}].listaUrls[${zIndex}].nombreDocumento`}
                                                                                            name={`comunicacion[${jIndex}].listaUrls[${zIndex}].nombreDocumento`}
                                                                                            className="white"
                                                                                            placeholder="Ingresa el link aquí"
                                                                                            value={formik.values.comunicacion[jIndex].listaUrls[zIndex].nombreDocumento}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                            icon={formik.values.comunicacion[jIndex].listaUrls[zIndex].nombreDocumento != "" && <Iconsax.Trash />}
                                                                                            onClickIcon={() => onDeleteLink(zIndex, arrayComunicacionLink)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                                <div className="field col-12 md:col-4">
                                                                    <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                                        <i style={{ cursor: "pointer" }} onClick={() => arrayComunicacionLink.push({imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Url',estado:true})}>
                                                                            Añadir más
                                                                        </i>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    ></FieldArray>
                                                </>
                                            ))}
                                        <div className="field col-12 md:col-12">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "center" }}>
                                                <i
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        arrayComunicacion.push({
                                                            imagenBase64: null,
                                                            tipoDocumento: null,
                                                            id: 0,
                                                            idClimaActividad: 0,
                                                            nombre: "",
                                                            nombreFoto: "",
                                                            descripcion: "",
                                                            seccion: "COMUNICACION",
                                                            listaDocCompleto: [{ imagenBase64: null, tipoDocumento: null, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: "", tipo: "Adjunto", estado: true }],
                                                            listaUrls: [{ imagenBase64: null, tipoDocumento: null, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: "", tipo: "Url", estado: true }],
                                                        });
                                                        // setNumeroPregunta(numeroPregunta+1)
                                                    }}
                                                >
                                                    Añadir espacio de comunicación interna
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                             */}
                            <div className="field col-12 md:col-12 header-subtitle">{formik.values.SubTitulo2}</div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el Sutítulo</label>
                                <InputDefault
                                    id="SubTitulo2"
                                    name="SubTitulo2"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo2!= undefined && (formik.errors.SubTitulo2 ? "error" : "success")}
                                    value={formik.values.SubTitulo2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo2 && formik.errors.SubTitulo2}</small>
                            </div>
                            <FieldArray
                                name="celebracion"
                                render={(arrayCelebracion) => (
                                    <>
                                        {formik.values.celebracion &&
                                            formik.values.celebracion.map((celebracion, jIndex) => (
                                                <>
                                                    {jIndex != 0 && <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>}
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`celebracion[${jIndex}].nombre`}>Nombre</label>

                                                        <InputDefault
                                                            id={`celebracion[${jIndex}].nombre`}
                                                            name={`celebracion[${jIndex}].nombre`}
                                                            className="white"
                                                            errorClass={formik.errors.celebracion && formik.errors.celebracion[jIndex] && formik.errors.celebracion[jIndex].nombre != undefined && (formik.errors.celebracion[jIndex].nombre ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.celebracion[jIndex].nombre}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.celebracion && formik.errors.celebracion[jIndex] && formik.errors.celebracion[jIndex].nombre}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`celebracion[${jIndex}].nombreFoto`}>Adjunta una foto</label>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <label
                                                                className={classNames("eva-labelFileLider", {
                                                                    "p-eva-error": formik.errors.celebracion && formik.errors.celebracion[jIndex] && formik.errors.celebracion[jIndex].nombreFoto != undefined && formik.errors.celebracion[jIndex].nombreFoto,
                                                                })}
                                                                style={{ minHeight: 16, height: "fit-content" }}
                                                            >
                                                                <span>{formik.values.celebracion[jIndex].nombreFoto.length > 0 ? formik.values.celebracion[jIndex].nombreFoto : "Adjunta aquí"}</span>
                                                            </label>
                                                            {formik.values.celebracion[jIndex].nombreFoto != "" ? (
                                                                <label className="eva-labelFileLider-icon">
                                                                    <i onClick={() => {}}>
                                                                        <Iconsax.Trash />
                                                                    </i>
                                                                </label>
                                                            ) : (
                                                                <label htmlFor={`celebracion[${jIndex}].nombreFoto`} className="eva-labelFileLider-icon">
                                                                    <i>
                                                                        <Iconsax.GalleryAdd color="#F47647" />
                                                                    </i>
                                                                </label>
                                                            )}

                                                            <input
                                                                type="file"
                                                                id={`celebracion[${jIndex}].nombreFoto`}
                                                                name={`celebracion[${jIndex}].nombreFoto`}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`celebracion[${jIndex}].nombreFoto`, e.currentTarget.files[0].name);
                                                                    formik.setFieldValue(`celebracion[${jIndex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                    getBase64(e.currentTarget.files[0]).then((result) => {
                                                                        formik.setFieldValue(`celebracion[${jIndex}].imagenBase64`, result);
                                                                    });

                                                                    //onFileChange(e, "document")
                                                                }}
                                                                accept="application/pdf,audio/*,image/*"
                                                                hidden
                                                            ></input>
                                                        </div>
                                                        <small className="p-error">{formik.errors.celebracion && formik.errors.celebracion[jIndex] && formik.errors.celebracion[jIndex].imagenBase64}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`celebracion[${jIndex}].descripcion`} className="p-eva-labelForm">
                                                            Añade una descripción
                                                        </label>
                                                        <TextareaDefault
                                                            id={`celebracion[${jIndex}].descripcion`}
                                                            minHeight={180}
                                                            placeholder="Escribe aquí"
                                                            className="White"
                                                            errorClass={formik.errors.celebracion && formik.errors.celebracion[jIndex] && formik.errors.celebracion[jIndex].descripcion != undefined && (formik.errors.celebracion[jIndex].descripcion ? "error" : "success")}
                                                            value={formik.values.celebracion[jIndex].descripcion}
                                                            icon={<Iconsax.DocumentText1 />}
                                                            name={`celebracion[${jIndex}].descripcion`}
                                                            onChange={formik.handleChange}
                                                            onblur={formik.handleBlur}
                                                        />
                                                        <small className="p-error">{formik.errors.celebracion && formik.errors.celebracion[jIndex] && formik.errors.celebracion[jIndex].descripcion}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-12" style={{marginBottom:2}}>
                                                        <label className="p-eva-labelForm">Agrega documentos</label>
                                                    </div>
                                                    <FieldArray
                                                        name={`celebracion[${jIndex}].listaDocCompleto`}
                                                        render={(arrayCelebracionDoc) => (
                                                            <>
                                                             
                                                            <div className="field col-12 md:col-8">
                                                               
                                                                <div className="p-fluid formgrid grid">
                                                                    
                                                                {
                                                                    formik.values.celebracion[jIndex].listaDocCompleto &&
                                                                    formik.values.celebracion[jIndex].listaDocCompleto.map((documento, zIndex) => (
                                                                        <div className="field col-12 md:col-6" key={zIndex}>
                                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                                    <span>{formik.values.celebracion[jIndex].listaDocCompleto[zIndex].nombreDocumento.length > 0 ? formik.values.celebracion[jIndex].listaDocCompleto[zIndex].nombreDocumento : "Adjunta aquí"}</span>
                                                                                </label> */}
                                                                                <InputDefault
                                                                                    id={`celebracion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                                    name={`celebracion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`}
                                                                                    placeholder="Adjunta aquí"
                                                                                    className="White"
                                                                                    value={formik.values.celebracion[jIndex].listaDocCompleto[zIndex].nombreDocumento}
                                                                                    onChange={(e)=>{formik.values.celebracion[jIndex].listaDocCompleto[zIndex].imagenBase64 && formik.handleChange(e)}}
                                                                                    onBlur={formik.handleBlur}
                                                                                ></InputDefault>
                                                                                {formik.values.celebracion[jIndex].listaDocCompleto[zIndex].nombreDocumento != "" ? (
                                                                                    <label className="eva-labelFileLider-icon">
                                                                                        <i onClick={() => onDeleteFile(zIndex, arrayCelebracionDoc)} style={{ cursor: "pointer" }}>
                                                                                            <Iconsax.Trash />
                                                                                        </i>
                                                                                    </label>
                                                                                ) : (
                                                                                    <label htmlFor={`celebracion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                                        <i>
                                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                                        </i>
                                                                                    </label>
                                                                                )}
                
                                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                                <input
                                                                                    type="file"
                                                                                    id={`celebracion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} 
                                                                                    name={`celebracion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumentoIcon`} 
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue(`celebracion[${jIndex}].listaDocCompleto[${zIndex}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                                        formik.setFieldValue(`celebracion[${jIndex}].listaDocCompleto[${zIndex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                                            formik.setFieldValue(`celebracion[${jIndex}].listaDocCompleto[${zIndex}].imagenBase64`, result);
                                                                                        });
                
                                                                                        //onFileChange(e, "document")
                                                                                    }}
                                                                                    accept="application/pdf,audio/*,image/*"
                                                                                    hidden
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="field col-12 md:col-4">
                                                                <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <i style={{ cursor: "pointer" }} onClick={() => arrayCelebracionDoc.push({imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Adjunto',estado:true})}>
                                                                        Añadir más
                                                                    </i>
                                                                    <label htmlFor="file-multipleCel" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                                    <input
                                                                        type="file"
                                                                        id={`file-multipleCel`}
                                                                        name={`file-multipleCel`}
                                                                        onChange={(e) => {
                                                                            console.log(e.currentTarget.files);
                                                                            let tempFiles= e.currentTarget.files
                                                                            console.log(tempFiles)
                                                                            for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                                                let nombre = e.currentTarget.files[index].name;
                                                                                let tipo = e.currentTarget.files[index].type;
                                                                                let base64  =""
                                                                                getBase64(e.currentTarget.files[index]).then((result) => {
                                                                                    base64 = result;
                                                                                    arrayCelebracionDoc.push({imagenBase64:base64,tipoDocumento:tipo,id:0,idClimaActividadCelebacion:0,nombreDocumento:nombre,tipo:'Adjunto',estado:true})
                                                                                });
                                                                            }
                                                                        
                                                                        }}
                                                                        accept="application/pdf,audio/*,image/*"
                                                                        hidden
                                                                        multiple
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                            </>
                                                        )}
                                                    ></FieldArray>
                                                     <div className="field col-12 md:col-12" style={{marginBottom:2}}>
                                                        <label className="p-eva-labelForm">Agrega links de videos</label>
                                                    </div>
                                                    <FieldArray
                                                        name={`celebracion[${jIndex}].listaUrls`}
                                                        render={(arrayCelebracionLink) => (
                                                            <>
                                                                <div className="field col-12 md:col-8">
                                                                    <div className="p-fluid formgrid grid">
                                                                    {formik.values.celebracion[jIndex].listaUrls &&
                                                                     formik.values.celebracion[jIndex].listaUrls.map((documento, zIndex) => (
                                                                                <div className="field col-12 md:col-6" key={zIndex}>
                                                                                    <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                                        <InputIcon
                                                                                            id={`celebracion[${jIndex}].listaUrls[${zIndex}].nombreDocumento`}
                                                                                            name={`celebracion[${jIndex}].listaUrls[${zIndex}].nombreDocumento`}
                                                                                            className="white"
                                                                                            placeholder="Ingresa el link aquí"
                                                                                            value={formik.values.celebracion[jIndex].listaUrls[zIndex].nombreDocumento}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                            icon={formik.values.celebracion[jIndex].listaUrls[zIndex].nombreDocumento != "" && <Iconsax.Trash />}
                                                                                            onClickIcon={() => onDeleteLink(zIndex, arrayCelebracionLink)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                                <div className="field col-12 md:col-4">
                                                                    <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                                        <i style={{ cursor: "pointer" }} onClick={() => arrayCelebracionLink.push({imagenBase64:null,tipoDocumento:null,id:0,idClimaActividadCelebacion:0,nombreDocumento:"",tipo:'Url',estado:true})}>
                                                                            Añadir más
                                                                        </i>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    ></FieldArray>
                                                </>
                                            ))}
                                        <div className="field col-12 md:col-12">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "center" }}>
                                                <i
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        arrayCelebracion.push({
                                                            imagenBase64: null,
                                                            tipoDocumento: null,
                                                            id: 0,
                                                            idClimaActividad: 0,
                                                            nombre: "",
                                                            nombreFoto: "",
                                                            descripcion: "",
                                                            seccion: "CELEBRACION",
                                                            listaDocCompleto: [{ imagenBase64: null, tipoDocumento: null, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: "", tipo: "Adjunto", estado: true }],
                                                            listaUrls: [{ imagenBase64: null, tipoDocumento: null, id: 0, idClimaActividadCelebacion: 0, nombreDocumento: "", tipo: "Url", estado: true }],
                                                        });
                                                        // setNumeroPregunta(numeroPregunta+1)
                                                    }}
                                                >
                                                    Añadir celebraciones importantes
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Registrar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default CrearActividades;