import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import InputDefault from "../../../components/InputDefault/InputDefault";
import TextareaDefault from "../../../components/TextArea/TextArea";
import {  useFormik } from "formik";
import Boton from "../../../components/Boton/Boton";
import { RegistrarHistoria,ActualizarHistoria,ObtenerHistoriaPorId} from "../../../service/DesarrolloService";
import ToastEva from "../../../components/Toast/ToastEva";
const CrearHistoria = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Crear nueva historia de éxito");
    const [modoEdicion, setModoEdicion] = useState(false);
    const [historia, setHistoria] = useState(null);
    const {permisos,isLogged}= useUsuario();
    const [enabledAdjuntos, setEnabledAdjuntos] = useState(true);
    const [enabledLinkVideo, setEnabledLinkVideo] = useState(true);
    const toast = useRef(null);
    let {idHistoria} = useParams();

    useEffect(()=>{
        const ObtenerHistoria =async ()=>{
            let id= idHistoria
            let jwt = window.localStorage.getItem("jwt");
           await ObtenerHistoriaPorId({id,jwt}).then(data=>
            {
                setHistoria(data)
                setModoEdicion(true)
                setTituloPagina("Editar historia de éxito")
            })
           
        }
        if(idHistoria){
            ObtenerHistoria();
        } 
    },[])

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:modoEdicion?historia.id:0,
            nombreFoto: modoEdicion?historia.nombreFoto:"",
            nombreColaborador: modoEdicion?historia.nombreColaborador:"",
            linkVideo: modoEdicion?historia.linkVideo:"",
            descripcion: modoEdicion?historia.descripcion:"",
            imagenBase64:""
        },
      validationSchema: Yup.object({
        nombreColaborador: Yup.string().required("Campo Nombre colaborador requerido."),
        descripcion: Yup.string().required("Campo Descripción requerido."),
      }),
      onSubmit: values => {
        let id = values.id
       
        let nombreColaborador = values.nombreColaborador
        let linkVideo = values.linkVideo
        let descripcion = values.descripcion
        let nombreFoto = values.nombreFoto
        let imagenBase64 = values.imagenBase64
        // let idGerencia = values.idGerencia
        // let nombre = values.nombre
        // let fechaMaxima = new Date(values.fechaMaxima).toLocaleString("en-US")
        // let linkConvocatoria = values.linkConvocatoria
        
         let jsonHistoria=JSON.stringify({id,nombreColaborador,linkVideo,descripcion,nombreFoto,imagenBase64})
    
        // // let jsonConvocatoria = JSON.stringify({id,nombre,descripcion,idSeccion,listaContactos,textoEvaluacion,listaUrls,nombreImagenTarjeta,imagenBase64,listaDocCompleto},null,2)
        // // console.log(jsonConvocatoria)
          //alert(jsonHistoria);
      
         if(modoEdicion )Actualizar({jsonHistoria}) 
          else Registrar({jsonHistoria})
      
      },
    });

    const Registrar= async({jsonHistoria})=>{
        let jwt = window.localStorage.getItem("jwt");
        await RegistrarHistoria({jsonHistoria,jwt}).then(data=>{
            formik.setSubmitting(false);
            //toast.current.show({severity:'success', summary: 'Success', detail:"Convocatoria creada con éxito", life: 7000})
            
            toast.current.show({severity:'success', summary: 'Success', detail:"Historia creada exitosamente.", life: 7000})
            setTimeout(() => {
                navigate(-1);
            }, 3000)
            
        }).catch(err=>{
            formik.setSubmitting(false);
            
            //toast.current.show({severity:'error', summary: 'Error', detail:err.message, life: 7000})

        })
    }

    const Actualizar= async({jsonHistoria})=>{
        let jwt = window.localStorage.getItem("jwt");
        await ActualizarHistoria({jsonHistoria,jwt}).then(data=>{
            toast.current.show({severity:'success', summary: 'Success', detail:"Historia actualizada exitosamente.", life: 7000})
            setTimeout(() => {
                navigate(-1);
            }, 3000)
            
        }).catch(err=>{
            //toast.current.show({severity:'error', summary: 'Error', detail:err.message, life: 7000})

        })
    }

    return (
        <div className="eva-dashboard-crearHistoria">
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label>{tituloPagina}</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4">
                            <label htmlFor="Seccion">Nombre del colaborador</label>
                            <InputDefault
                                id="nombreColaborador"
                                name="nombreColaborador"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.nombreColaborador != undefined && (formik.errors.nombreColaborador ? "error" : "success")}
                                value={formik.values.nombreColaborador}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.nombreColaborador && formik.touched.nombreColaborador && formik.errors.nombreColaborador}</p>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="fotoHistoria" className="p-eva-labelForm">
                                Adjunta una foto
                            </label>
                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                    <span>{formik.values.nombreFoto.length > 0 ? formik.values.nombreFoto : "Adjunta aquí"}</span>
                                </label>
                                {formik.values.nombreFoto != "" ? (
                                    <label className="eva-labelFileLider-icon" style={{opacity:enabledAdjuntos?2:0.5,pointerEvents:enabledAdjuntos?"auto":"none"}}>
                                        <i
                                            onClick={() => {
                                                formik.setFieldValue("nombreFoto", "");
                                                formik.setFieldValue("imagenBase64", null);
                                                setEnabledLinkVideo(true)
                                                return false;
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Iconsax.Trash />
                                        </i>
                                    </label>
                                ) : (
                                    <label htmlFor={"nombreFoto"} className="eva-labelFileLider-icon" style={{opacity:enabledAdjuntos?2:0.5,pointerEvents:enabledAdjuntos?"auto":"none"}}>
                                        <i>
                                            <Iconsax.Gallery />
                                        </i>
                                    </label>
                                )}

                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                <input
                                    type="file"
                                    id="nombreFoto"
                                    name="nombreFoto"
                                    onChange={(e) => {
                                        formik.setFieldValue("nombreFoto", e.currentTarget.files[0].name);
                                        setEnabledLinkVideo(false)
                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                            formik.setFieldValue("imagenBase64", result);
                                        });

                                        //onFileChange(e, "document")
                                    }}
                                    accept="application/pdf,audio/*,image/*"
                                    disabled={!enabledAdjuntos}
                                    hidden
                                ></input>
                            </div>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="linkVideo">Agregar link de video</label>
                            <InputDefault
                                id="linkVideo"
                                name="linkVideo"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.linkVideo != undefined && (formik.errors.linkVideo ? "error" : "success")}
                                value={formik.values.linkVideo}
                                disabled={!enabledLinkVideo}
                                onChange={(e)=>{
                                    
                                    if(e.target.value.length > 0) 
                                    {
                                        setEnabledAdjuntos(false);
                                        formik.setFieldValue("nombreFoto", "");
                                        formik.setFieldValue("imagenBase64", null);
                                    }else{
                                        setEnabledAdjuntos(true);
                                    }
                                    formik.handleChange(e)
                                }}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.linkVideo && formik.touched.linkVideo && formik.errors.linkVideo}</p>
                        </div>
                        <div className="field col-12 md:col-12">
                            <label htmlFor="descripcion" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <TextareaDefault
                                id="descripcion"
                                height={193}
                                minHeight={193}
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                value={formik.values.descripcion}
                                icon={<Iconsax.DocumentText1 />}
                                name="descripcion"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                        </div>
                    </div>
                    <div className="p-eva-historia-footer" style={{display:"flex",justifyContent:"right"}}>
                        <Boton
                            nombre={"Cancelar"}
                            color="secondary"
                            widths="15%"
                            metodoClick={()=>navigate(-1)}
                        ></Boton>
                        <Boton nombre={modoEdicion?"Actualizar":"Crear historia"} color="primary" widths="18%" type="submit" disabled={!(formik.isValid && formik.dirty)} loading={formik.isSubmitting}></Boton>
                    </div>
                </form>
            </div>
        </div>
    );
}
 
export default CrearHistoria;