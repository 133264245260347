import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionOpcionalPorId } from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import * as constantes from "../../../constants/constantes";
import parse from 'html-react-parser'
import CardFile from "../../../components/CardFileEva/CardFile";
import "./CapaOpcional.scss"
const DetalleOpcional = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [capacitacionOpcional, setCapacitacionOpcional] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { idCapacitacion} = useParams();
    let { IdGerencia } = useParams();
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarCapacitacionesNoObligatorias") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verCapaIntroduccion") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])
    useEffect(()=>{
        const getCapacitacion = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCapacitacion;
            await ObtenerCapacitacionOpcionalPorId({jwt,Id}).then(data => 
                {
                    setCapacitacionOpcional(data)
                })
        }
        if(!capacitacionOpcional) getCapacitacion();

    },[idCapacitacion])

    const ObtenerUrlTexto =(text)=>{
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        var textoNuevo= text.replace(urlRegex, function (url) {
          var hyperlink = url;
        //   if (!hyperlink.match('^https?:\/\/')) {
        //     hyperlink = 'http://' + hyperlink;
        //   }
        hyperlink = hyperlink.indexOf("http") > -1 ? hyperlink : "http://" + hyperlink
        return '<a  className="p-eva-labelForm-orange" target="_blank" href="'+hyperlink+'">'+hyperlink+'</a>'
        //   return '<i onClick={()=>redirectLink("'+hyperlink+'")>'+hyperlink+'</i>'
          
        });
        return  <div>{parse(textoNuevo)}</div>
    }
    return ( 
        <div className="eva-detalleCapaOpcional" >
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    {
                        permisoEditar &&
                        <div className="header-right">
                            <Link to={"../Capacitacion/Seccion/"+idSeccion+"/Gerencia/"+IdGerencia+"/EditarOpcional/"+idCapacitacion}>Editar capacitacion</Link>
                        </div>
                    }
                </div>
                
                <div>{capacitacionOpcional && <label htmlFor="header">{capacitacionOpcional.nombre}</label>}</div>
            </div>
            <div className="p-eva-dashboard-detalleCapaOpcional" style={{ marginTop: 32 }}>
                <div className="p-eva-dashboard-url p-fluid formgrid grid" >
                {
                    capacitacionOpcional && 
                    capacitacionOpcional.capacitacion_opcional_documentos
                    .filter(x=>x.tipo =="Url"&& x.estado == true).length >0 &&
                        <div className="header-subtitle field col-12 md:col-12"><span>Capacitaciones</span></div>
                }
                
                {
                    capacitacionOpcional && 
                        capacitacionOpcional.capacitacion_opcional_documentos
                        .filter(x=>x.tipo =="Url"&& x.estado == true)
                        .map((item,i)=>{
                            var re = /(?:\.([^.]+))?$/;
                            return (
                                <div className="field col-12 md:col-3" key={i}>
                                    <CardFile key={i} extensionArchivo={"video"} nombreArchivo={item.nombreDocumento} keys={i} tipo="Urls" />
                                </div>
                            );
                        })
                            
                }
                </div>
                <div className="p-eva-dashboard-documentos p-fluid formgrid grid" >
                {
                    capacitacionOpcional && 
                    capacitacionOpcional.capacitacion_opcional_documentos
                    .filter(x=>x.tipo =="Adjunto"&& x.estado == true).length >0 &&
                        <div className="header-subtitle field col-12 md:col-12"><span>Descargables</span></div>
                }
                {
                    capacitacionOpcional && 
                    capacitacionOpcional.capacitacion_opcional_documentos
                    .filter(x=>x.tipo =="Adjunto"&& x.estado == true)
                    .map((item,i)=>{
                        var re = /(?:\.([^.]+))?$/;
                        return (
                            <div className="field col-12 md:col-3" key={i}>
                                <CardFile urlFile={constantes.URLBLOB_CAPAOPCIONAL + "/" + item.nombreDocumento} 
                                key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} 
                                nombreArchivo={item.nombreDocumento} 
                                keys={i} tipo="Adjuntos" descargable={true} />
                            </div>
                        );
                    })
                }
                </div>
                <div className="detalleCapaOpcional-adicional">
                   
                        {capacitacionOpcional && 
                            capacitacionOpcional.test.length > 0 &&
                            <div className="adicional-item">
                               
                                <div style={{marginBottom:16}}><span className="header-subtitle" >Test</span></div>
                                <div><span>{ObtenerUrlTexto(capacitacionOpcional.test)}</span></div>
                               
                            </div>
                        }
                    
                    
                        {capacitacionOpcional && 
                            capacitacionOpcional.encuestaSatisfaccion.length > 0 && 
                            <div className="adicional-item">
                               
                                    <div style={{marginBottom:16}}><span className="header-subtitle" style={{marginBottom:16}}>Encuesta de satisfacción</span></div>
                                    <span>{ObtenerUrlTexto(capacitacionOpcional.encuestaSatisfaccion)}</span>
                               
                            </div>
                        }
                    
                </div>
            </div>

        </div>

     );
}
 
export default DetalleOpcional;