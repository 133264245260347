import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
const ENDPOINT = constantes.URLAPI_MAESTROS;
const ENDPOINTEST = constantes.URL_GRUPOSTEST;
export const CreateGroupUser= ({jsonGrupos,jwt}) =>{

    return fetch(`${ENDPOINT}/Grupos/Registrar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonGrupos
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export  const  ListGroupsType= async(jwt,tipo) =>{
    return await fetch(`${ENDPOINT}/Grupos/ListarGruposTipo/${tipo}`,{
    //return await fetch(`${ENDPOINTEST}/ListarGruposTipo/${tipo}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export  const  ObtenerGrupo= async({idGrupo,jwt}) =>{
    return await fetch(`${ENDPOINT}/Grupos/ObtenerGrupo/${idGrupo}`,{
    //return fetch("assets/demo/data/users-gerencia.json",{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const UpdateGroup= ({jsonGrupos,jwt}) =>{

    return fetch(`${ENDPOINT}/Grupos/Actualizar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonGrupos
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export const DeleteGroup= ({id,jwt}) =>{

    return fetch(`${ENDPOINT}/Grupos/Eliminar/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const AgregarUsuarioGrupos= ({jsonGrupo,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/AgregarUsuarioGrupos`,{
    return fetch(`${ENDPOINT}/Grupos/AgregarUsuarioGrupos`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonGrupo
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export  const  Listar= async({jwt}) =>{
    return await fetch(`${ENDPOINT}/Grupos/Listar/`,{
    //return fetch("assets/demo/data/users-gerencia.json",{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

