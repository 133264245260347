import React, { useState, useEffect, useRef } from 'react';
import "./CardSubirAniversario.scss"
import * as constantes from "../../../constants/constantes.js";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import InputDefault from '../../InputDefault/InputDefault';
import Boton from '../../Boton/Boton';
import { getBase64 } from '../../../helpers/helpers';
import { ActualizarAniversario } from '../../../service/ConfiguracionService';
import useUsuario from "../../../hooks/useUsuario";
import ToastEva from '../../Toast/ToastEva';
const CardSubirAniversario = () => {
    const [aniversario, setAniversario] = useState(null);
    const [cumpleanio, setCumpleanio] = useState(null);
    const {configuraciones} = useUsuario();
    const toast = useRef(null);
    useEffect(()=>{

        if(configuraciones.length > 0)
        {
            let docAnivevrsario = configuraciones.filter(x=>x.codigo=="DOCUMENTO_ANIVERSARIO");
            let docCumpleanio = configuraciones.filter(x=>x.codigo=="DOCUMENTO_CUMPLEANIOS");
            if(docAnivevrsario)
            {
                setAniversario({nombreDocumento: docAnivevrsario[0].valor,imagenBase64: "",tipoDocumento:""})
            }    
            if(docCumpleanio)
            {
                setCumpleanio({nombreDocumento: docCumpleanio[0].valor,imagenBase64: "",tipoDocumento:""})
            }     
    
        }
        
    },[configuraciones])

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            documentosAniversario : aniversario ? aniversario : {nombreDocumento: "",imagenBase64: "",tipoDocumento:""},
            documentosCumpleanio : cumpleanio ? cumpleanio : {nombreDocumento: "",imagenBase64: "",tipoDocumento:""}
            
        },
      //validationSchema: schema,
      onSubmit: values => {
        let documentoCumpleanio = values.documentosCumpleanio;
        let documentoAniversario = values.documentosAniversario;
        // let listaAdjuntos = values.documentos;
        // let codigo ="IMG_BANNER";
        // let valor = "";
        
        // listaAdjuntos.map((item,i)=>{
        //     if(item) valor = valor + item.nombreDocumento +"|";

        // })

        // valor = valor.substring(0, valor.length - 1);

        let jsonConfiguracion = JSON.stringify({ documentoCumpleanio,documentoAniversario},null,2);
        //console.log(jsonConfiguracion)
        Actualizar({jsonConfiguracion}) 
        },
    });

    const Actualizar =({jsonConfiguracion})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarAniversario({jsonConfiguracion,jwt}).then(data=>{
        
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                window.location.reload();
            }, 3000)
        })
        .catch(errors => {
           
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    return ( 
        <div className="p-eva-subirAniversario">
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className='subirAniversario-header'>Cumpleaños y aniversarios del mes</div>
            <div className='subirAniversario-body'>
                <form onSubmit={formik.handleSubmit}>
                    <div  className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12">
                            <label htmlFor="documentosCumpleanio.nombreDocumento">Agrega documento de cumpleaños</label>
                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                <InputDefault
                                    id={`documentosCumpleanio.nombreDocumento`}
                                    name={`documentosCumpleanio.nombreDocumento`}
                                    placeholder="Adjunta aquí"
                                    className="grey"
                                    value={formik.values.documentosCumpleanio.nombreDocumento}
                                    onChange={(e) => {
                                        formik.values.documentosCumpleanio.imagenBase64 && formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                ></InputDefault>
                                {formik.values.documentosCumpleanio.nombreDocumento != "" ? (
                                    <label className="eva-labelFileLider-icon">
                                        <i onClick={() => {
                                             formik.setFieldValue(`documentosCumpleanio.nombreDocumento`, "");
                                             formik.setFieldValue(`documentosCumpleanio.imagenBase64`, null);
                                        }} style={{ cursor: "pointer" }}>
                                            <Iconsax.Trash />
                                        </i>
                                    </label>
                                ) : (
                                    <label htmlFor={`nombreDocumentoCumpleanioIcon`} className="eva-labelFileLider-icon">
                                        <i>
                                            <Iconsax.DocumentText1 color="#F47647" />
                                        </i>
                                    </label>
                                )}

                                <input
                                    type="file"
                                    id={`nombreDocumentoCumpleanioIcon`}
                                    name={`nombreDocumentoCumpleanioIcon`}
                                    onChange={(e) => {
                                        formik.setFieldValue(`documentosCumpleanio.nombreDocumento`, e.currentTarget.files[0].name);
                                        formik.setFieldValue(`documentosCumpleanio.tipoDocumento`, e.currentTarget.files[0].type);
                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                            formik.setFieldValue(`documentosCumpleanio.imagenBase64`, result);
                                        });

                                        //onFileChange(e, "document")
                                    }}
                                    accept="application/pdf"
                                    hidden
                                ></input>
                            </div>
                            
                        </div>
                        <div className="field col-12 md:col-12">
                            <label htmlFor="documentosAniversario.nombreDocumento">Agrega documento de aniversarios</label>
                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                <InputDefault
                                    id={`documentosAniversario.nombreDocumento`}
                                    name={`documentosAniversario.nombreDocumento`}
                                    placeholder="Adjunta aquí"
                                    className="grey"
                                    value={formik.values.documentosAniversario.nombreDocumento}
                                    onChange={(e) => {
                                        formik.values.documentosAniversario.imagenBase64 && formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                ></InputDefault>
                                {formik.values.documentosAniversario.nombreDocumento != "" ? (
                                    <label className="eva-labelFileLider-icon">
                                        <i onClick={() => {
                                             formik.setFieldValue(`documentosAniversario.nombreDocumento`, "");
                                             formik.setFieldValue(`documentosAniversario.imagenBase64`, null);
                                        }} style={{ cursor: "pointer" }}>
                                            <Iconsax.Trash />
                                        </i>
                                    </label>
                                ) : (
                                    <label htmlFor={`nombreDocumentoAniversarioIcon`} className="eva-labelFileLider-icon">
                                        <i>
                                            <Iconsax.DocumentText1 color="#F47647" />
                                        </i>
                                    </label>
                                )}

                                <input
                                    type="file"
                                    id={`nombreDocumentoAniversarioIcon`}
                                    name={`nombreDocumentoAniversarioIcon`}
                                    onChange={(e) => {
                                        formik.setFieldValue(`documentosAniversario.nombreDocumento`, e.currentTarget.files[0].name);
                                        formik.setFieldValue(`documentosAniversario.tipoDocumento`, e.currentTarget.files[0].type);
                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                            formik.setFieldValue(`documentosAniversario.imagenBase64`, result);
                                        });

                                        //onFileChange(e, "document")
                                    }}
                                    accept="application/pdf"
                                    hidden
                                ></input>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div className="p-eva-footer-form">
                        <Boton nombre={"Publicar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default CardSubirAniversario;