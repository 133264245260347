import React, { useState, useEffect } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import { TabPanel } from 'primereact/tabview';
import TabViewEva from '../../components/TabviewEva/TabviewEva';
import { Timeline } from 'primereact/timeline';
import { ProgressBar } from 'primereact/progressbar';
import HomeEvaluacion from "./EvaluacionDesempeño/HomeEvaluacion";
import Convocatoria from "./ConvocatoriaInterna/Convocatoria";
import Historias from "./HistoriaDeExito/Historias";
import "./Desarrollo.scss"
import useUsuario from "../../hooks/useUsuario";
const Desarrollo = () => {
    const navigate = useNavigate();

    const [tabSelected, setTabSelected] = useState({nombre:"Editar evaluación de desempeño",navegacion:"EditarEvaluacion"});
    const {permisos,isLogged,perfil}= useUsuario();
    console.log("perfil",perfil)
    console.log("permisos",permisos)
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [verEvaluaciones, setVerEvaluaciones] = useState(true);
    const [indexTab, setIndexTab] = useState(1);
    let {indexDefault} = useParams();
    
    const AccionPermisos = async (permiso) => {
        if (permisos.indexOf(permiso) > -1) {
            setPermisoEditar(true);
            
        }else
        {
            setPermisoEditar(false);
        }
    };

    useEffect(()=>{
        
        if(permisos.length >0)
        {
            // permisos.indexOf("verEvaluaciones") > -1 ? AccionPermisos() : setVerEvaluaciones(false)
            permisos.indexOf("vertudesarrollo") > -1 ??  navigate("/")
        }

    },[permisos])

    useEffect(()=>{
        if(indexDefault && permisoEditar) 
        {
            setIndexTab(indexDefault)
            cambiarAccion(parseInt(indexDefault),"BACK")
        }
        else{
            setIndexTab(1)
            cambiarAccion(parseInt(1),"BACK")
        }
    },[indexDefault,permisoEditar])
    const cambiarAccion =(index,origen="TAB")=>{
        switch (index) {
            case 0:
                AccionPermisos("editarEvaluaciones");
                setTabSelected({nombre:"Editar evaluación de desempeño",navegacion:"EditarEvaluacion"})
                if(origen==="TAB")navigate("../Desarrollo/"+index)
                
                break;
            case 1:
                AccionPermisos("editarConvocatorias");
                setTabSelected({nombre:"Crear convocatoria",navegacion:"CrearConvocatoria"})
                if(origen==="TAB")navigate("../Desarrollo/"+index)
                 break;
            case 2:
                AccionPermisos("editarHistorias");
                setTabSelected({nombre:"Crear Historias de exito",navegacion:"CrearHistoria"})
                if(origen==="TAB")navigate("../Desarrollo/"+index)
                break;
            default:
                break;
        }
    }

    return ( 
        <div className="eva-dashboard-desarrollo" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <div className="eva-dashboard-header header-agrupado" style={{height:"100%"}}>
                <div className="header">
                    <label htmlFor="header">Tu Desarrollo</label>
                </div>
                {permisoEditar && 
                    <div className="header-right">
                    <Link to={tabSelected.navegacion}>{tabSelected.nombre}</Link>
                </div>
                }
                
                
            </div>
            <div className="eva-dashboard-desarrollo-body" >
                {
                   indexTab&&
                    <TabViewEva cambiarAccion={cambiarAccion} indexDefault={parseInt(indexTab)}>  
                    {
                        permisoEditar && permisos.length >0 && permisos.indexOf("verEvaluaciones") > -1 &&
                        <TabPanel header="Evaluación de desempeño">
                            <HomeEvaluacion></HomeEvaluacion>
                        </TabPanel>
                    }
                    {
                         permisos.length >0 && permisos.indexOf("verConvocatorias") > -1 &&
                         <TabPanel header="Convocatoria interna">
                            <Convocatoria></Convocatoria>
                        </TabPanel>
                    }
                    {
                        permisos.length >0 && permisos.indexOf("verHistorias") > -1 &&
                        <TabPanel header="Historia de éxito">
                            <Historias></Historias>
                        </TabPanel>
                    }
                    
                </TabViewEva>
                }
               
            </div>
        </div>
     );
}
 
export default Desarrollo;