import React, { useState ,useEffect,useRef} from 'react';
import { Card } from 'primereact/card';
import Boton from '../../Boton/Boton';
import InputDefault from '../../InputDefault/InputDefault';
import TextareaDefault from '../../../components/TextArea/TextArea';
import * as Yup from "yup";
import { replace, useFormik } from 'formik';
import * as Iconsax from "iconsax-react";
import {ActualizarBanner,ActualizarMensaje} from "../../../service/ConfiguracionService.js"
import ToastEva from '../../Toast/ToastEva';

import "./MensajeColaborador.scss"
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import { Listar } from '../../../service/GruposService';

const MensajeColaborador = ({editarMensaje, contenido}) => {
    
    const [stateImage, setStateImage] = useState({ file: null, base64URL: "",tipoDocumento:"" });
    const [nombreImagen, setNombreImagen] = useState(null);
    const [enableForm, setEnableForm] = useState(true);
    const [enableAdjunto, setEnableAdjunto] = useState(true);
    const [isvalidTitulo, setIsvalidTitulo] = useState(false);
    const [isvalidDescripcion, setIsvalidDescripcion] = useState(false);
    const toast = useRef(null);
    const [selectedGrupo, setSelectedGrupo] = useState([]);
    const [grupos, setGrupos] = useState(null);

    const getGrupos = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await Listar({jwt}).then(data => 
                {
                    let temp =[];
                    data.map((item,i)=>{
                        temp.push({label:item.nombre,value:item.id})
                    })
                    setGrupos(temp)

                })
        }
    useEffect(()=>{
        
        if(!grupos)getGrupos()
    },[])

    useEffect(()=>{
        if(grupos && contenido)
        {
            setNombreImagen(contenido.nombreImagen)

            if(contenido.listaGrupos.length >0)
            {
                let arrayGrupos = contenido.listaGrupos.split(";");
                let usuariosGrupo = [];
    
                arrayGrupos.map((item,i)=>{
                    let grupo = grupos.filter(x=>x.value ==parseInt(item))
                    if(grupo.length >0)
                    {
                        usuariosGrupo.push({label: grupo[0].label,value:String(grupo[0].value)})
                    }
                    
                })
                setSelectedGrupo(usuariosGrupo);
            }
        }
        

    },[contenido,grupos])
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);
            const regex = /data:.*base64,/;
            //console.log(str1.replace(regex,""))
            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL.replace(regex, ""));
            };
            console.log(fileInfo);
        });
    };
    const onFileChange = (e) => {
        
        console.log(e.target.files[0]);
        let { file } = stateImage;

        file = e.target.files[0];
        setEnableForm(false)
        setEnableAdjunto(true)
        getBase64(file)
            .then((result) => {
                file["base64"] = result;
                console.log("File Is", file);
                setNombreImagen(file.name)
                setStateImage({
                    base64URL: result,
                    file,
                    tipoDocumento:file.type
                });
            })
            .catch((err) => {
                console.log(err);
            });

        setStateImage({
            file: e.target.files[0],
            tipoDocumento:file.type
        });
    };

    const onDeleteFile =()=>{
        
        setStateImage({file:null,base64URL:""})
        setNombreImagen(null);
        setEnableForm(true)
        setEnableAdjunto(true)
        
    }

    const handleReestablecer=()=>{
        let codigo ="MENSAJE_COLABORADORES";
        let valor = "";
        
        let nombreDocumento = "";
        let imagenBase64 = "";
        let tipoDocumento = "";
        let jsonConfiguracion = JSON.stringify({ codigo,valor,nombreDocumento,imagenBase64,tipoDocumento});

        // RegistrarPublicacion({jsonPublicacion})
        //alert(jsonConfiguracion);
        let jwt = window.localStorage.getItem("jwt");
        ActualizarMensaje({jsonConfiguracion,jwt})
        .then(data => {
            console.log("success: "+data);
            //window.location.reload();
            toast.current.show({severity:'success', summary: 'Success', detail:"Se eliminó mensaje para colaboradores.", life: 7000})
        })
    }

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
        titulo:contenido.titulo,
        descripcion: contenido.mensaje,
        
    },
    validationSchema: Yup.object({
            // titulo: Yup.string().required("Título requerido."),
            // descripcion : Yup.string().required("Descripción requerida."),
            titulo: Yup.string().when([],{
                is:()=> ! nombreImagen,
                then: Yup.string().required("Título requerido.")
                
            }),
            descripcion: Yup.string().when([],{
                is:()=> ! nombreImagen,
                then: Yup.string().required("Descripción requerida.")
                
            })
        }),
    onSubmit: values => {

        let codigo ="MENSAJE_COLABORADORES";
        let valor = "";
        if(nombreImagen)
        {
            valor = "imagen|"+nombreImagen
        }else{
            valor = values.titulo+"|"+values.descripcion;
        }

        let cadenaGrupo = ""
        if(selectedGrupo.length > 0)
        {
            selectedGrupo.map((item,i)=>{
                cadenaGrupo = cadenaGrupo  +item.value+ ";"
            })
            cadenaGrupo = cadenaGrupo.substring(0, cadenaGrupo.length - 1);
        }
        else{
            cadenaGrupo = "121"
        }
        if(cadenaGrupo.length > 0)
        {
            valor = valor +"|"+cadenaGrupo;
        }

        let nombreDocumento = stateImage.file === null ? "":stateImage.file.name;
        let imagenBase64 = stateImage.file === null?"": stateImage.base64URL;
        let tipoDocumento = stateImage.file === null?"": stateImage.tipoDocumento;
        
        // let idGrupo = IdGrupo;
        let jsonConfiguracion = JSON.stringify({ codigo,valor,nombreDocumento,imagenBase64,tipoDocumento});

        // RegistrarPublicacion({jsonPublicacion})
        //alert(jsonConfiguracion);
        let jwt = window.localStorage.getItem("jwt");
        ActualizarMensaje({jsonConfiguracion,jwt})
        .then(data => {
            console.log("success: "+data);
            //window.location.reload();
            toast.current.show({severity:'success', summary: 'Success', detail:"Se estableció nuevo mensaje para colaboradores.", life: 7000})
        })
        
    },
    });
const validarTitulo =(e)=>{
    if(e.target.value.length > 0) 
    {
        setIsvalidTitulo(true)
        setEnableAdjunto(false)
        setEnableForm(true)
    }
    else{
        setIsvalidTitulo(false)
    }
    
}

const validarDescripcion =(e)=>{
    if(e.target.value.length > 0) 
    {
        setIsvalidDescripcion(true)
        setEnableAdjunto(false)
        setEnableForm(true)
    }
    else{
        setIsvalidDescripcion(false)
    }
   
}
const handleSelectChange = (values) => {
    setSelectedGrupo(values);
    
};
useEffect(()=>{
    if(!(isvalidTitulo || isvalidDescripcion)){
        setEnableAdjunto(true)
        setEnableForm(true)
    }

},[isvalidTitulo,isvalidDescripcion])

    const header = 
    <label  className='header-subtitle' >Mensaje para colaboradores</label>

    const cardFooter =
        <form onSubmit={formik.handleSubmit}>
            <div className="p-eva-mensajeColaborador-footer">
                <Boton nombre={"Reestablecer"} color='secondary' widths='50%' disabled={editarMensaje} metodoClick={handleReestablecer}></Boton>
                <Boton nombre={"Publicar"} color='primary' widths='50%' type='submit'
                    disabled={!((formik.isValid && formik.dirty && editarMensaje) || (nombreImagen && editarMensaje)) }
                ></Boton>
            </div>
        </form>
        
    return ( 
        <div className="p-eva-mensajeColaborador" style={{marginBottom:10, width:"100%"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>

            <Card header={header} className="p-eva-mensajeColaborador-card" footer={cardFooter}>
                <div className='p-eva-mensajeColaborador-contenido'>
                    <form>
                        <div>
                            <MultiselectEva
                                // onChange={(listUser) => {
                                //     console.log(listUser);
                                //     let temp = [];
                                //     listUser.map((item, i) => {
                                //         temp.push({ id: 0, IdClimaComite: 0, idUsuario: item.value });
                                //     });
                                //     formik.setFieldValue(`listaUsuariosMaki`, temp);
                                //     formik.setFieldValue(`selectedMakisColaborador`, listUser);
                                // }}
                                onChange={handleSelectChange}
                                isMulti
                                //options={selectButtonValues1}
                                options={grupos}
                                value={selectedGrupo}
                                mostrarAvatar={false}
                                placeholder="Buscar por grupo"
                            />
                        </div>
                        <div>
                            <label htmlFor="titulo">Adjunte una foto</label>
                            <div  style={{display:"flex",alignItems:"center",position:"relative",opacity:enableAdjunto && editarMensaje ?1:0.6}} >
                                {
                                    nombreImagen ?
                                    <label  className={"eva-labelFile"} style={{minHeight:16,height:"fit-content"}}>
                                        <span>{nombreImagen}</span>
                                    
                                    </label>
                                    :
                                    <label  className={"eva-labelFile"} style={{minHeight:16,height:"fit-content"}}>
                                        <span>{"Adjunte aquí"}</span>
                                    
                                    </label>
                                }
                                {!stateImage.file  && <label htmlFor={"documentoAdjunto"} className='eva-labelFile-icon'><i ><Iconsax.Gallery /></i></label>}
                                {stateImage.file && <label className='eva-labelFile-icon'><i onClick={onDeleteFile} style={{cursor:"pointer"}}><Iconsax.Trash /></i></label>}
                            </div>
                            <input  type="file" id={"documentoAdjunto"} onChange={onFileChange} disabled={!(enableAdjunto && editarMensaje)}
                                    accept="image/*" hidden ></input>
                        </div>
                                
                            
                        <div style={{marginTop:24}}>
                            <label htmlFor="titulo">Titulo del Mensaje</label>
                            <InputDefault id="titulo" className="grey" placeholder="Escribe aquí" 
                                value={formik.values.titulo} onChange={(e)=>(formik.handleChange(e),validarTitulo(e))} onBlur={formik.handleBlur} name="titulo" 
                                disabled={!enableForm}
                                />
                            <p className="error">{formik.errors.titulo && formik.touched.titulo && formik.errors.titulo}</p>
                        </div>
                        <div style={{marginTop:24}}>
                            <label htmlFor="descripcion">Añade una descripción</label>
                                <TextareaDefault id="descripcion" placeholder="Escribe aquí" className="grey" height={"200"}
                                errorClass={formik.touched.descripcion != undefined&& (formik.errors.descripcion?"error":"success")} 
                                value={formik.values.descripcion} icon={<Iconsax.DocumentText1 />} name="descripcion"  
                                onChange={(e)=>(formik.handleChange(e),validarDescripcion(e))} onblur={formik.handleBlur} 
                                disabled={!enableForm}/>
                                <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
     );
}
 
export default MensajeColaborador;