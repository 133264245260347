import React, { useState, useEffect, useRef } from 'react';
import {useNavigate,Link,useParams} from 'react-router-dom';
// import "../../components/Inicio/FormsEva.scss"
import CardPublicacion from '../../components/Inicio/Publicaciones/CardPublicacion';
import SubirBanner from '../../components/Inicio/Publicaciones/SubirBanner';
import CardCumpleanios from '../../components/Inicio/Publicaciones/CardCumpleanios';
import { TabView,TabPanel } from 'primereact/tabview';
import useUsuario from "../../hooks/useUsuario";
import * as constantes from "../../constants/constantes.js";
import { ListarPublicacion,ListarPublicacion2} from '../../service/PublicacionService';
import { ListarInteraccion } from '../../service/InteraccionService';
import { ListGroupsType } from '../../service/GruposService';
import { Carousel } from 'primereact/carousel';
import MensajeColaborador from '../../components/Inicio/Publicaciones/MensajeColaborador';
import ModalEva from '../../components/Modal/ModalEva';
import "./Home.scss"
import { Skeleton } from 'primereact/skeleton';
import EditarPublicacion from '../../components/Inicio/Publicaciones/EditarPublicacion';
import PreviewMedia from '../../components/PreviewMedia/PreviewMedia';
import { Tag } from 'primereact/tag';
import CardAniversario from '../../components/Inicio/Publicaciones/CardAniversario';
import CardSubirAniversario from '../../components/Inicio/Publicaciones/CardSubirAniversario';
import CardDocumentoAniversario from '../../components/Inicio/Publicaciones/CardDocumentoAniversario';
import CardCorreo from '../../components/Inicio/CardCorreo';

import CrearPublicacion from "../../components/Inicio/Publicaciones/CrearPublicacion.js";
import ToastEva from "../../components/Toast/ToastEva.js";
import { ProgressSpinner } from 'primereact/progressspinner';
export default function Home () {
    const navigate = useNavigate();
    const { permisos,isLogged,perfil,configuraciones} = useUsuario();
    const [listaPublicacion, setListaPublicacion] = useState(null);
    const [listaInteraccion, setlistaInteraccion] = useState([]);

    const [gruposUsuario, setGruposUsuario] = useState([]);
    const [tipoPermisoGrupo, setTipoPermisoGrupo] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [listImagenesBanner, setListImagenesBanner] = useState([]);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [contendoAlerta, setContendoAlerta] = useState({titulo:"",mensaje:"",nombreImagen:"",listaGrupos:""});
    const [editarMensaje, setEditarMensaje] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [mostrarEditar, setMostrarEditar] = useState(false);
    const [publicacionEdicion, setPublicacionEdicion] = useState(null);
    const [idLogin, setIdLogin] = useState(0);
    const [mostrarImgAlerta, setMostrarImgAlerta] = useState(false);
    const [imagenAlerta, setImagenAlerta] = useState(null);
    const [mostrarTagProgramada, setMostrarTagProgramada] = useState(false);
    
    const menuCompartir = useRef([]);
    const divRef = useRef(null);
    const [isEndOfScroll, setIsEndOfScroll] = useState(false);
    //let [pagListarPublicaciones, setPagListarPublicaciones] = useState(0)
    const [rowpagListarPublicaciones, setrowPagListarPublicaciones] = useState(2)
    //
    const toast = useRef(null);
    const [grupos, setGrupos] = useState([]);
    const RefrescarListado = (mensaje)=>{
        console.log("Mensaje",mensaje)
        setListaPublicacion(null)
        toast.current.show({severity:'success', summary: 'Success', detail:mensaje, life: 7000})
        ObtenerPublicaciones();
    }
    const mostrarError = (mensaje)=>{
        toast.current.show({severity:'error', summary: 'Error', detail:mensaje, life: 7000})

    }
   
    const ObtenerPublicaciones= async()=>
    {
        let jwt = window.localStorage.getItem("jwt");
        
        await ListarPublicacion2({jwt,pag:1,row:rowpagListarPublicaciones }).then((data) => 
        {
            let pubNoHabilitada = data.filter(x=>x.habilitado == false)
            if(pubNoHabilitada[0])
            {
                setMostrarTagProgramada(true)
            }
            setListaPublicacion(data)
            localStorage.setItem('ListaPublicacion', 1);    

        });
    }

    
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("verGruposTodos") > -1) {
                setTipoPermisoGrupo("total");
            } else if (permisos.indexOf("creareditargrupos") > -1) {
                setTipoPermisoGrupo("edicion");
            } else if (permisos.indexOf("verGerenciaArea") > -1) {
                setTipoPermisoGrupo("basico");
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verGrupos") > -1 ? AccionPermisos() : navigate("/")
            permisos.indexOf("aprobarPublicaciones") > -1 && setIsAdmin(true);

        }

    },[permisos])


    // useEffect(()=>{

    //     const ObtenerGroupType= async()=>
    //     {
    //         let jwt = window.localStorage.getItem("jwt");
    //         await ListGroupsType(jwt,tipoPermisoGrupo).then((data) => setGruposUsuario(data));
    //     }
    //     // if (listGruposType.length === 0) {
    //     if (tipoPermisoGrupo) {
    //         ObtenerGroupType();
    //     }
    // },[tipoPermisoGrupo])

    useEffect(()=>{
        const ObtenerGroupType= async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListGroupsType(jwt,tipoPermisoGrupo).then((data) => {
                if (data) {
                    const soloGrupos = data.map((item) => item.grupos).flat();
                    setGrupos(soloGrupos);
                }
                setGruposUsuario(data)
            });
        }
        // if (listGruposType.length === 0) {
        if (tipoPermisoGrupo) {
            ObtenerGroupType();
        }
    },[tipoPermisoGrupo])

    useEffect(()=>{

        const ObtenerPublicaciones= async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            //=> prevPag  + 1);
            await ListarPublicacion2({jwt,pag:1,row:rowpagListarPublicaciones }).then((data) => 
            {
                let pubNoHabilitada = data.filter(x=>x.habilitado == false)
                if(pubNoHabilitada[0])
                {
                    setMostrarTagProgramada(true)
                }
                setListaPublicacion(data)
                localStorage.setItem('ListaPublicacion', 1);              
                console.log("DATA PUBLICACION",data)

            });
        }
        ObtenerPublicaciones()
    },[])

    useEffect(()=>{
        const ObtenerInteraccionesBase= async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarInteraccion({jwt}).then((data) => setlistaInteraccion(data));
        }
        // if (listGruposType.length === 0) {
            ObtenerInteraccionesBase()

    },[])

    const validarGrupos =(arrayconfig) =>{
        let arrayGrupos = arrayconfig.split(";");
        if(perfil != undefined && perfil != null)
        {
            if(perfil.listaGrupos)
            {
                const filteredArray = perfil.listaGrupos.filter(value => arrayGrupos.includes(value.toString()));

                if(filteredArray.length>0)
                {
                    return true
                }
                else {
                    return false
                }
            }
            
        }
        

    }
    useEffect(() => {
        if(perfil && configuraciones)
        {
            let arrayBanner = []
            let imgBanner;
            let objAlerta;
            if(configuraciones.length >0){
                imgBanner = configuraciones.filter(x=>x.codigo=="IMG_BANNER");
                objAlerta = configuraciones.filter(x=>x.codigo=="MENSAJE_COLABORADORES");
            }
            if(imgBanner)
            {
                arrayBanner = imgBanner[0].valor.split("|");
                console.log("arrayBanner",arrayBanner);
                const arraySinVacios = arrayBanner.filter(elemento => elemento !== "");
                if(arrayBanner[0].length > 0)setListImagenesBanner(arraySinVacios);
                
            }
            if(objAlerta)
            {
                let tempAlerta = objAlerta[0].valor.split("|");
                let fecha = objAlerta[0].fechaRegistro;
                let oneday = 60 * 60 * 24 * 1000;
                ;
                if((new Date().getTime() -new Date(fecha).getTime()) <= oneday)
                {
                    if(tempAlerta[0] ==="imagen")
                    {
                        if(validarGrupos(tempAlerta[2]))
                        {
                            setMostrarImgAlerta(true);
                        }
                        
                        setImagenAlerta(tempAlerta[1])
                       
                        setContendoAlerta({titulo:"" ,mensaje: "" ,nombreImagen:tempAlerta[1],listaGrupos:tempAlerta[2]})
                        setEditarMensaje(false)
                    }else{
                        
                        setContendoAlerta({titulo:tempAlerta[0] ,mensaje: tempAlerta[1],nombreImagen:null,listaGrupos:tempAlerta[2]})
                        if(validarGrupos(tempAlerta[2]))
                        {
                            setMostrarAlerta(true)
                        }
                        
                        setEditarMensaje(false)
                    }
                    
                }else{
                    setEditarMensaje(true)
                }
               
            }
        }
       
    }, [perfil,configuraciones]); 


    
    const MostrarEdicion =(item)=>{
        setMostrarEditar(true)
        setPublicacionEdicion(item)
    }

    const productTemplate=(imagen)=>{
        return(
            <div >
                <img src={constantes.URLBLOB_BANNER+"/"+imagen} width="100%" style={{borderRadius:16}} ></img>
            </div>
        )
            
    }

    const handleScroll = () => {
        var listaPublicacionDataActual = [];
        const { scrollTop, scrollHeight, clientHeight } = divRef.current;
        if (scrollTop + 10 + clientHeight >= scrollHeight) {
            console.log("Fin del scroll")
            const ListaPublicacionlocalStorage = parseInt(localStorage.getItem('ListaPublicacion'));
            let jwt = window.localStorage.getItem("jwt");
            if(!isEndOfScroll){
                ListarPublicacion2({ jwt, pag: ListaPublicacionlocalStorage + 1, row: rowpagListarPublicaciones }).then((data) => {
                    localStorage.setItem('ListaPublicacion', ListaPublicacionlocalStorage + 1);
                    listaPublicacionDataActual = listaPublicacion;
                    data.forEach(element => {
                        listaPublicacionDataActual.push(element);
                    });
                    setListaPublicacion(listaPublicacionDataActual);
                    if (divRef.current) {
                        divRef.current.scrollTop = scrollTop - 10;
                    }
                });
            }
            setIsEndOfScroll(true);
        } else {
            setIsEndOfScroll(false);
        }
    };

/*     const handleScroll2 = () => {
        var listaPublicacionDataActual = [];
        const { scrollTop, scrollHeight, clientHeight } = divRef2.current;
        if (scrollTop + clientHeight >= scrollHeight) {
            setIsEndOfScroll2(true);
            const ListaPublicacionlocalStorage = parseInt(localStorage.getItem('ListaPublicacion'));
            let jwt = window.localStorage.getItem("jwt");
            ListarPublicacion2({ jwt, pag: ListaPublicacionlocalStorage + 1, row: rowpagListarPublicaciones }).then((data) => {
                localStorage.setItem('ListaPublicacion', ListaPublicacionlocalStorage + 1);
                listaPublicacionDataActual = listaPublicacion;
                data.forEach(element => {
                    listaPublicacionDataActual.push(element);
                });
                setListaPublicacion(listaPublicacionDataActual);
                if (divRef2.current) {
                    divRef2.current.scrollTop = scrollTop - 1;
                  }
            });
        } else {
            setIsEndOfScroll2(false);
        }
    }; */

    const footerAlerta = <></>;
    return (
        <>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="p-eva-dashboard-inicio" style={{ display: "inline-flex", width: "100%", marginTop: 40 }}>
                <div ref={divRef} className="p-eva-dashboard-inicio-publicaciones" 
                    style={{ minWidth:670,width: 670 ,marginLeft:"auto",marginRight:"auto",overflowX:"hidden"}} onScroll={handleScroll}>

                {listImagenesBanner.length>0 &&
                    <Carousel value={listImagenesBanner} numVisible={1} numScroll={1}  className="custom-carousel" circular
                    autoplayInterval={7000} itemTemplate={productTemplate} showNavigators={false} style={{maxHeight:292}}/>
                }
                    {
                       mostrarTagProgramada&& <Tag severity='warning' value="Existen pubicaciones programadas que se mostrarán según fecha configurada"style={{width:"100%",marginBottom:16}}></Tag>
                    }


                    <CrearPublicacion  perfil={perfil} nombreGrupo={""} handleRegister={RefrescarListado} handleError={mostrarError} isAdmin={isAdmin} ></CrearPublicacion>
                    {listaPublicacion ? 
                        listaPublicacion.length > 0 ? (
                            listaPublicacion.map((item, i) => {
                                return (
                                    <CardPublicacion
                                        nombreCorto={item.nombreUsuario}
                                        contenido={item.contenidoPublicacion}
                                        link={item.link}
                                        nombreLink={item.nombreLink}
                                        fijada={item.fijada}
                                        foto={item.nombreFoto}
                                        idUsuario={item.idUsuarioRegistro}
                                        imagen={item.rutaImagenVideo}
                                        imagenes ={item.publicacionCompletoAdjuntos}
                                        IdPublicacion={item.id}
                                        listaInteraccion={listaInteraccion}
                                        interacionLogin={item.idTipoInteraccionLogin}
                                        interacionUsuarios={item.interacionesPorUsuario}
                                        totalInteracciones={item.totalInteracciones}
                                        listaComentariosPub={item.listaComentarios}
                                        nombreGrupo={item.nombreGrupo}
                                        perfil={perfil}
                                        fechaRegistro={item.fechaRegistro}
                                        onClickEdit={()=>MostrarEdicion(item)}
                                        key={i}
                                        keys={i}
                                        gruposUsuario={gruposUsuario}
                                        objPublicacion={item}
                                        isAdmin={isAdmin}
                                        menuRef={menuCompartir}
                                       
                                    ></CardPublicacion>
                                );
                            })
                        ) : <>No existe Data</>
                    :
                        <div style={{display:"block"}}>
                            <Skeleton className="mb-4"height='150px' width='100%' style={{borderRadius:16}}></Skeleton>
                            <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>
                            <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>

                        </div>
                    }
                 
                    <div className="card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>

                </div>
                <div className="p-eva-dashboard-inicio-right" style={{ minWidth:380,width: 380, marginLeft: 12 }}>
                    {isAdmin && <SubirBanner listaImagenes ={listImagenesBanner}></SubirBanner>}
                    <CardCorreo></CardCorreo>
                    <div className="p-eva-cumpleanios" style={{ marginBottom: 10 }}>
                        <CardCumpleanios></CardCumpleanios>
                    </div>
                    <div className="p-eva-aniversario" style={{ marginBottom: 10 }}>
                        <CardAniversario></CardAniversario>
                    </div>
                    {/* <div className="p-eva-documentoaniversario" style={{ marginBottom: 10 }}>
                        <CardDocumentoAniversario></CardDocumentoAniversario>
                    </div> */}
                    {isAdmin && <div style={{ marginBottom: 10 }}><CardSubirAniversario></CardSubirAniversario></div>}
                    {isAdmin && <MensajeColaborador contenido={contendoAlerta} editarMensaje={editarMensaje}></MensajeColaborador>}
                    
                </div>
                
            </div>
            <div   className="p-eva-dashboard-inicio-mobile"  >
                <TabView  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} scrollable>
                    <TabPanel header="Conversación">
                        <div className="p-eva-dashboard-inicio-publicaciones" style={{minWidth:375,width: "100%" }}>
                        {listImagenesBanner.length>0 &&
                            <Carousel value={listImagenesBanner} numVisible={1} numScroll={1}  className="custom-carousel" circular
                            autoplayInterval={7000} itemTemplate={productTemplate} showNavigators={false} />
                        }
                        {
                            mostrarTagProgramada&& <Tag severity='warning' value="Existen pubicaciones programadas que se mostrarán según fecha configurada"style={{width:"100%",marginBottom:16}}></Tag>
                            }
                            {listaPublicacion ?
                                listaPublicacion.length > 0 ? (
                                    listaPublicacion.map((item, i) => {
                                        return (
                                            <CardPublicacion
                                                nombreCorto={item.nombreUsuario}
                                                contenido={item.contenidoPublicacion}
                                                link={item.link}
                                                nombreLink={item.nombreLink}
                                                fijada={item.fijada}
                                                foto={item.nombreFoto}
                                                idUsuario={item.idUsuarioRegistro}
                                                imagen={item.rutaImagenVideo}
                                                imagenes ={item.publicacionCompletoAdjuntos}
                                                IdPublicacion={item.id}
                                                listaInteraccion={listaInteraccion}
                                                interacionLogin={item.idTipoInteraccionLogin}
                                                interacionUsuarios={item.interacionesPorUsuario}
                                                totalInteracciones={item.totalInteracciones}
                                                listaComentariosPub={item.listaComentarios}
                                                nombreGrupo={item.nombreGrupo}
                                                perfil={perfil}
                                                fechaRegistro={item.fechaRegistro}
                                                onClickEdit={()=>MostrarEdicion(item)}
                                                gruposUsuario={gruposUsuario}
                                                objPublicacion={item}
                                                isAdmin={isAdmin}
                                                //idLogin = {perfil.idUsuario}
                                                key={i}
                                                keys={i}
                                                menuRef={menuCompartir}
                                            ></CardPublicacion>
                                        );
                                    })
                            ) : <>No existe Data</>
                        :
                            <div style={{display:"block"}}>
                                    <Skeleton className="mb-4"height='150px' width='100%' style={{borderRadius:16}}></Skeleton>
                                    <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>
                                    <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>

                                </div>
                        }
                          <div className="card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                          </div>
                        </div>
                    </TabPanel>
                    {
                        isAdmin &&
                            <TabPanel header="Subir banner">
                                <div className="p-eva-dashboard-inicio-right" style={{ width: "100%", marginLeft: 32 }}>
                                    <SubirBanner listaImagenes ={listImagenesBanner}></SubirBanner>
                                    
                                </div>
                            </TabPanel>
                    }
                    {isAdmin && 
                        <TabPanel header="Mensaje para todos los colaboradores">
                            <MensajeColaborador contenido={contendoAlerta} editarMensaje={editarMensaje}></MensajeColaborador>
                        </TabPanel>
                    }
                    <CardCorreo></CardCorreo>
                    <TabPanel header="Cumpleaños">
                        <div className="p-eva-cumpleanios" style={{ marginBottom: 10 }}>
                            <CardCumpleanios></CardCumpleanios>
                        </div>
                    </TabPanel>
                    <TabPanel header="Aniversario">
                        <div className="p-eva-aniversario" style={{ marginBottom: 10 }}>
                            <CardAniversario></CardAniversario>
                        </div>
                        
                    </TabPanel>
                    <TabPanel header="Cumpleaños y Aniversario">
                        <div className="p-eva-documentoaniversario" style={{ marginBottom: 10 }}>
                            <CardDocumentoAniversario></CardDocumentoAniversario>
                        </div>
                        {isAdmin && <div style={{ marginBottom: 10 }}><CardSubirAniversario></CardSubirAniversario></div>}
                    </TabPanel>
                    
                </TabView>
            </div>
            {mostrarAlerta && <ModalEva mostrar={mostrarAlerta}titulo={contendoAlerta.titulo} onHide={()=>setMostrarAlerta(false)}
             footer={footerAlerta} contenido={contendoAlerta.mensaje} height="100%" widths="40%" posicionTitulo="center"></ModalEva>}
            {mostrarEditar && <EditarPublicacion mostrar={mostrarEditar} perfil={perfil} publicacionEdit={publicacionEdicion} onHide={() => setMostrarEditar(false)} isAdmin={isAdmin}></EditarPublicacion>}
            {mostrarImgAlerta&&
                <PreviewMedia visibleModal={mostrarImgAlerta} urlFile={constantes.URLBLOB_MENSAJE+"/"+imagenAlerta} 
                onHide={()=>setMostrarImgAlerta(false)} maxWidth="70%" height={70}></PreviewMedia>
            }
            

        </>
    );
}