import React,{useEffect,useState,useRef} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ToastEva from "../../components/Toast/ToastEva";
import useUsuario from "../../hooks/useUsuario";
import "./Metricas.scss";
import * as Iconsax from "iconsax-react";
import { ObtenerMetricas,ObtenerReporte } from "../../service/InteraccionService";
import { ProgressBar } from 'primereact/progressbar';
import DropdownFiltro from "../../components/DropdownFiltro/DropdownFiltro";
import * as constantes from "../../constants/constantes";
import { Panel } from 'primereact/panel';
import { ListarAreas } from "../../service/GerenciaService";
import { Calendar } from 'primereact/calendar';
// import es from 'date-fns/locale/es';
import { es } from 'date-fns/locale';
import { addLocale } from 'primereact/api';


const Metricas = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario()
    const [tituloPagina, setTituloPagina] = useState("Métricas");
    const {permisos,isLogged} = useUsuario()
    const toast = useRef(null);
    const [metricas, setMetricas] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [usuariosfiltrados, setUsuariosfiltrados] = useState(null);
    const [filtroSeleccionadoMarca, setFiltroSeleccionadoMarca] = useState(null);
    const [filtroSeleccionadoGerencia, setFiltroSeleccionadoGerencia] = useState(null);
    const [filtroSeleccionadoGeneral, setFiltroSeleccionadoGeneral] = useState("semana");
    const [listAreas, setListAreas] = useState(null);
    const [filtroSeleccionadoArea, setFiltroSeleccionadoArea] = useState(null);
  
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const initialDate = new Date(currentYear, currentMonth - 1, 1); 
    const [date, setDate] = useState(initialDate);

    const obtenerAccesos = async () => {
        let jwt = window.localStorage.getItem("jwt");
        // let filtro = filtroSeleccionadoGeneral
        console.log("DATE",date)
        var mes = date.getMonth() + 1; // Sumar 1 porque los meses comienzan desde 0
        var dia = date.getDate();
        var año = date.getFullYear();

        var fechaFormateada = mes + '/' + dia + '/' + año;
        console.log(fechaFormateada); // Output: 12/1/2023
        //let newdate= new Date(date).toLocaleString("en-US")
        let filtro = fechaFormateada
        let jsonfiltro = JSON.stringify({ filtro});
            console.log("jsonfiltro",jsonfiltro)
           
        // let filtro2 = date


        await ObtenerMetricas({  jsonfiltro,jwt }).then(data=>{
            let agrupador = [];
            data.secciones.map((item,i)=>{
                var array = item.nombre.split("/");
                agrupador.push(array[0])
            })

            var uniqueAgrupador = agrupador.filter((value, index, array) => array.indexOf(value) === index);
            let nuevoJson = [];
            uniqueAgrupador.map((item,i)=>{
                let seccion = []
                seccion = data.secciones.filter((x) => x.nombre.startsWith(item))
                nuevoJson.push({nombreModulo: item , secciones: seccion})
            })
            let nuevaData =data;
            nuevaData.secciones = nuevoJson;
            setMetricas(nuevaData)
            setUsuarios(nuevaData.usuarios)
            setUsuariosfiltrados(nuevaData.usuarios)
            
        });
    };

    useEffect(() => {
       
        obtenerAccesos();
    }, [date]);

    useEffect(()=>{
        const obtenerAreas=async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarAreas(jwt).then
            (data => {
            const datosTransformados = data.map(item => {
                return {
                    valor: item.id,
                    label: item.nombre
                };
            });
            setListAreas(datosTransformados);
            })
          //  (data => setListAreas(data))
        }

        if(!listAreas)
        {
            obtenerAreas()
        }
     },[]);

    const filtroMarca =[
        {valor:"marca",label:"Ordenar por marca"}
    ];

    const filtroGerencia =[
        {valor:"gerencia",label:"Ordenar por gerencia"}
    ];

    const filtroGeneral =[
        {valor:"semana",label:"Esta semana"}
        ,{valor:"mes",label:"Este mes"}
    ]
    const obtenerFiltroGeneral = () => {
        const meses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"
        ];
    
        const fechaActual = new Date();
        const mesActual = fechaActual.getMonth(); // Obtener el mes actual (0-indexado)
    
        const filtro = [
            { valor: "semana", label: "Esta semana" },
            { valor: "mes", label: "Este mes" }
        ];
    
        // Agregar los meses anteriores al filtro
        for (let i = 0; i < 11; i++) {
            const indiceMes = (mesActual - i + 11) % 12; // Asegurar que el índice sea positivo y esté dentro del rango
            filtro.push({ valor: meses[indiceMes], label: meses[indiceMes] });
        }
    
        return filtro;
    };
    
    
    const filtroGeneralTotal = obtenerFiltroGeneral();

    const handleFiltroArea=(e)=>{
        let newusuarios =[]
        setFiltroSeleccionadoArea(e.value)
        usuarios.map((item,i)=>{
            if(item.idArea === e.value){
                newusuarios.push(item)
            }
        })

    setUsuariosfiltrados(newusuarios)

    }

    const handleDownload = () => {
        let jwt = window.localStorage.getItem("jwt");
        var mes = date.getMonth() + 1;
        var dia = date.getDate();
        var año = date.getFullYear();

        var fechaFormateada = mes + '/' + dia + '/' + año;
        var fechaFormateada2 = mes + '/' + año;

        console.log(fechaFormateada); 
        let filtro = fechaFormateada
        let jsonfiltro = JSON.stringify({ filtro});
        console.log("jsonfiltro",jsonfiltro)
        ObtenerReporte({ jsonfiltro, jwt }).then((data) => {   

            fetch(constantes.URLBLOB_REPORTE_METRICAS + "%5CReporte de Metricas").then((response) => {

                response.blob().then((blob) => {
                    const fileURL = window.URL.createObjectURL(blob);
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = "ReporteMetricas_" + fechaFormateada2 + ".xlsx";
                    alink.click();
                });
            });
        });
    };

    const handleFiltroMarca=(e)=>{
        setFiltroSeleccionadoMarca(e.value)
    }

    const handleFiltroGerencia=(e)=>{
        setFiltroSeleccionadoGerencia(e.value)
    }
    
    const handleFiltroGeneral=(e)=>{
        setFiltroSeleccionadoGeneral(e.value)
    }
    const handleFiltroGeneralMes=(e)=>{
        setDate(e.value)
        console.log(e.value)
    }
    addLocale('pe', {
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],

    });
    return ( 
        <div className="eva-metricas" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header metricas">
                <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                {/* <div><DropdownFiltro value={filtroSeleccionadoGeneral} onChange={handleFiltroGeneral} options={filtroGeneral} optionLabel="label" optionValue="valor" placeholder="Selecciona filtro" style={{ width: 270 }}></DropdownFiltro></div> */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{height:"50px", marginRight: '10px'}}>
                        <Calendar style={{height: '100%', width: '100%'}}  
                        locale='pe'  
                        placeholder="Seleccionar mes"  
                        value={date} 
                        onChange={handleFiltroGeneralMes} 
                        view="month" 
                        dateFormat="mm/yy" />
                    </div>
                    <div className="header-right">
                        <Link to="#" onClick={handleDownload}>
                            Descargar reporte
                        </Link>
                    </div>
                </div> 
            </div>
            <div className="eva-dashboard-body">
                <div className="grid grid-metrica-publicaciones">
                    <div className="col-12 md:col-8 card-metrica metricas-colaboradores">
                        <div className="metricas-icon">
                            <Iconsax.User color="#F47647" size={48}/>
                        </div>
                        <div className="metricas-textoL">
                            {metricas && metricas.totalAccesos} colaboradores activos
                        </div>
                        <div className="metricas-colaboradores-porcentaje">
                            {metricas && parseInt(metricas.porcentajeTotalAccesos) >= 0 ?
                                <span><Iconsax.ArrowUp color="#27AE60" size={20}/></span>
                                :
                                <span><Iconsax.ArrowDown color="#EB5757" size={20}/></span>
                            }
                            
                            <div className="metricas-textoS"><span style={{color:metricas && parseInt(metricas.porcentajeTotalAccesos) >= 0 ?"#27AE60" :"#EB5757"}}>
                                {metricas && parseInt(metricas.porcentajeTotalAccesos)}%</span> este mes
                                </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4 card-metrica metricas-vistas">
                        <div className="metricas-icon">
                            <Iconsax.Eye color="#463347" size={48}/>
                        </div>
                        <div className="metricas-vistas-detalle">
                            <div className="metricas-textoSl">Promedio de vistas</div>
                            <div className="metricas-textoL">{metricas && parseInt(metricas.vistas)}</div>
                            <div className="metricas-vistas-porcentaje">
                            {metricas && parseInt(metricas.porcentajeVistas) >= 0 ?
                                <span><Iconsax.ArrowUp color="#27AE60" size={20}/></span>
                                :
                                <span><Iconsax.ArrowDown color="#EB5757" size={20}/></span>
                            }
                                
                                <div className="metricas-textoS"><span style={{color:metricas && parseInt(metricas.porcentajeVistas) >= 0 ?"#27AE60" :"#EB5757"}}>
                                {metricas && parseInt(metricas.porcentajeVistas)}%</span> este mes</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4 card-metrica metricas-publicaciones">
                        <div className="metricas-icon">
                            <Iconsax.DocumentText color="#00AC4F" size={48}/>
                        </div>
                        <div className="metricas-publicaciones-detalle">
                            <div className="metricas-textoSl">Promedio de publicaciones</div>
                            <div className="metricas-textoL">{metricas && parseInt(metricas.publicaciones)}</div>
                            <div className="metricas-publicaciones-porcentaje">
                                {metricas && parseInt(metricas.porcentajePublicaciones) >= 0 ?
                                    <span><Iconsax.ArrowUp color="#27AE60" size={20}/></span>
                                    :
                                    <span><Iconsax.ArrowDown color="#EB5757" size={20}/></span>
                                }
                                
                                <div className="metricas-textoS"><span style={{color:metricas && parseInt(metricas.porcentajePublicaciones) >= 0 ?"#27AE60" :"#EB5757"}}>
                                {metricas && parseInt(metricas.porcentajePublicaciones)}%</span>  este mes</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4 card-metrica metricas-interacciones">
                        <div className="metricas-icon">
                            <Iconsax.Like1 color="#0F5FC2" size={48}/>
                        </div>
                        <div className="metricas-interacciones-detalle">
                            <div className="metricas-textoSl">Promedio de interacciones</div>
                            <div className="metricas-textoL">{metricas && parseInt(metricas.interaciones)}</div>
                            <div className="metricas-interacciones-porcentaje">
                                {metricas && parseInt(metricas.porcentajeInteracciones) >= 0 ?
                                    <span><Iconsax.ArrowUp color="#27AE60" size={20}/></span>
                                    :
                                    <span><Iconsax.ArrowDown color="#EB5757" size={20}/></span>
                                }
                                
                                <div className="metricas-textoS"><span style={{color:metricas && parseInt(metricas.porcentajeInteracciones) >= 0 ?"#27AE60" :"#EB5757"}}>
                                {metricas && parseInt(metricas.porcentajeInteracciones)}%</span> este mes</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4 card-metrica metricas-comentarios">
                        <div className="metricas-icon">
                            <Iconsax.Message color="#ED2424" size={48}/>
                        </div>
                        <div className="metricas-comentarios-detalle">
                            <div className="metricas-textoSl">Promedio de comentarios </div>
                            <div className="metricas-textoL"> {metricas && parseInt(metricas.comentarios)}</div>
                            <div className="metricas-comentarios-porcentaje">
                                {metricas && parseInt(metricas.porcentajeComentarios) >= 0 ?
                                    <span><Iconsax.ArrowUp color="#27AE60" size={20}/></span>
                                    :
                                    <span><Iconsax.ArrowDown color="#EB5757" size={20}/></span>
                                }
                                
                                <div className="metricas-textoS"><span style={{color:metricas && parseInt(metricas.porcentajeComentarios) >= 0 ?"#27AE60" :"#EB5757"}}>
                                {metricas && parseInt(metricas.porcentajeComentarios)}%</span> este mes</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="metrica-secciones">
                    <div className="header-subtitle" >Módulos con mas interacción</div>
                    <div className="secciones-lista">
                        {
                            metricas &&
                            metricas.secciones &&
                            metricas.secciones.length > 0 &&
                            metricas.secciones.map((agrupador,i)=>{
                                return(
                                    <Panel key={i}
                                        className="p-eva-panel-acordeon metricas"
                                        header={agrupador.nombreModulo} toggleable 
                                        expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                                        collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                                        collapsed
                                        >
                                        {
                                            agrupador.secciones.map((item,j)=>{
                                                return(<div className="seccion-item" key={j}>
                                                            <div className="p-eva-labelForm" style={{width:350}}>{item.nombre.replace(agrupador.nombreModulo+"/","")}</div>
                                                            <ProgressBar value={Math.round((item.cantidad/metricas.total)*100,2)}showValue={false} style={{width:650,background:"#FFFFFF"}}></ProgressBar>
                                                            <div className="seccion-porcentaje"
                                                                style={{marginLeft:8, position:"absolute",transform:"translateX("+(350+(Math.round((item.cantidad/metricas.total)*100,2))*650 /100)+"px)"}}
                                                            >{Math.round((item.cantidad/metricas.total)*100,2)}%</div>
                                                        </div>)
                                            })
                                        }
                                    </Panel>
                                )

                                // return(<div className="seccion-item">
                                //             <div className="p-eva-labelForm" style={{width:350}}>{item.nombre}</div>
                                //             <ProgressBar value={Math.round((item.cantidad/metricas.total)*100,2)}showValue={false} style={{width:650,background:"#FFFFFF"}}></ProgressBar>
                                //             <div className="seccion-porcentaje"
                                //                 style={{marginLeft:8, position:"absolute",transform:"translateX("+(350+(Math.round((item.cantidad/metricas.total)*100,2))*650 /100)+"px)"}}
                                //             >{Math.round((item.cantidad/metricas.total)*100,2)}%</div>
                                //         </div>)


                            })
                        
                        }
                    </div>
                </div>
                <div className="metrica-usuarios">
                    <div className="metrica-usuarios-header">
                        <div className="header-metricas" style={{marginRight:26}} >Colaboradores que más interactúan</div>
                        <div><DropdownFiltro value={filtroSeleccionadoMarca} onChange={handleFiltroArea} options={listAreas} optionLabel="label" optionValue="valor" placeholder="Selecciona área" style={{ width: 270 ,marginRight:16}}></DropdownFiltro></div>
                        <div><DropdownFiltro value={filtroSeleccionadoMarca} onChange={handleFiltroMarca} options={filtroMarca} optionLabel="label" optionValue="valor" placeholder="Selecciona filtro" style={{ width: 270 ,marginRight:16}}></DropdownFiltro></div>
                        <div><DropdownFiltro value={filtroSeleccionadoGerencia} onChange={handleFiltroGerencia} options={filtroGerencia} optionLabel="label" optionValue="valor" placeholder="Selecciona filtro" style={{ width: 270 }}></DropdownFiltro></div>
                    </div>
                    <div className="usuarios-lista">
                        <div className="usuarios-item-header">
                            <div className="titulo" style={{marginLeft:80,width:280}}>Nombre</div>
                            <div className="titulo" style={{width:168}}>Marca</div>
                            <div className="titulo" style={{width:248}}>Gerencia</div>
                            <div className="titulo"style={{width:248}}>Área</div>
                            {/* <div className="titulo" style={{marginLeft:80,width:235}}>Nombre</div>
                            <div className="titulo" style={{width:150}}>Marca</div>
                            <div className="titulo" style={{width:210}}>Gerencia</div>
                            <div className="titulo" style={{width:165}}>Puesto</div>
                            <div className="titulo" style={{width:230}}>Area</div> */}



                        </div>
                        {
                            metricas &&
                            metricas.usuarios &&
                            metricas.usuarios.length > 0 &&
                            // metricas.usuarios.map((item,i)=>{
                            usuariosfiltrados.map((item,i)=>{

                                return(
                                    <div className="usuarios-item" key={i}>
                                        <div className="usuarios-item-avatar" style={{marginLeft:-24}}>
                                            <img src={!item.foto ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.idUsuario+"/"+item.foto }
                                                width={80} style={{borderRadius:100,marginRight:24}}></img>
                                        </div>
                                        <div className="usuarios-item-nombre p-eva-labelForm">{item.nombre}</div>
                                        <div className="usuarios-item-marcar p-eva-labelForm">{item.marca}</div>
                                        <div className="usuarios-item-gerencia p-eva-labelForm">{item.gerencia}</div>
                                        <div className="usuarios-item-puesto p-eva-labelForm">{item.puesto}</div>

                                    </div>
                                )
                                
                            })
                        }
                        
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Metricas;