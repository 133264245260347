import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
// const ENDPOINT = constantes.URL_TEST;
const ENDPOINTTEST = constantes.URL_ORGANIGRAMATEST;
const ENDPOINT = constantes.URLAPI_MAESTROS;

export const ObtenerSeccion =async ({jwt}) =>{
    //return await fetch(`${ENDPOINT}/ObtenerSeccion`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerSeccion`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ObtenerReglamentosPorGerencia =async({id,jwt}) =>{
    //return await fetch(`${ENDPOINT}/ObtenerReglamentosPorGerencia/${id}`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerReglamentosPorGerencia/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const Registrar= ({jsonReglamento,jwt}) =>{
    //return fetch(`${ENDPOINT}/Registrar`,{
    return fetch(`${ENDPOINT}/Somosin/Registrar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonReglamento
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export const RegistrarSeccion= ({jsonSeccion,jwt}) =>{
    return fetch(`${ENDPOINT}/Somosin/RegistrarSeccion`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },        
        body: jsonSeccion
    }).then(res=>{
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const Actualizar= ({jsonReglamento,jwt}) =>{
    //return fetch(`${ENDPOINT}/Actualizar`,{
    return fetch(`${ENDPOINT}/Somosin/Actualizar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonReglamento
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ObtenerReglamentoId =async({id,jwt}) =>{
    //return await fetch(`${ENDPOINT}/ObtenerReglamentoPorId/${id}`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerReglamentoPorId/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const RegistrarReglamentoUsuario= ({idReglamento,jwt}) =>{
    //return fetch(`${ENDPOINT}/RegistrarReglamentoUsuario/${idReglamento}`,{
    return fetch(`${ENDPOINT}/Somosin/RegistrarReglamentoUsuario/${idReglamento}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}


export const ObtenerDirectorioPorGerencia= async({idGerencia,idUsuario,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerDirectorioPorGerencia/${idGerencia}/${idUsuario}`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerDirectorioPorGerencia/${idGerencia}/${idUsuario}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ActualizarOrganigrama= ({jsonOrganigrama,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarOrganigrama`,{
    return fetch(`${ENDPOINT}/Somosin/ActualizarOrganigrama`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonOrganigrama
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerOrganigrama =async({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerOrganigrama`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerOrganigrama`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerGerenciasReglamento =async({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerGerenciasReglamento`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerGerenciasReglamento`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const EliminarReglamentoPorId= ({id,jwt}) =>{
    //return fetch(`${ENDPOINT}/EliminarReglamentoPorId/${id}`,{
    return fetch(`${ENDPOINT}/Somosin/EliminarReglamentoPorId/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ObtenerReporte= ({id,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ObtenerReporte/${id}`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerReporte/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ObtenerReglamentoGerenciaPorId= ({id,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ObtenerReglamentoGerenciaPorId/${id}`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerReglamentoGerenciaPorId/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ListarReglamentos =async({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ListarReglamentos`,{
    return fetch(`${ENDPOINT}/Somosin/ListarReglamentos`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ObtenerGerenciaySubGerencias= async({jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerGerenciaySubGerencias`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerGerenciaySubGerencias`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ObtenerSubGerenciayAreas= async({codigoTrabajador,jwt}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerSubGerenciayAreas/${codigoTrabajador}`,{
    return fetch(`${ENDPOINT}/Somosin/ObtenerSubGerenciayAreas/${codigoTrabajador}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const EncriptarUrlCodigoUsuario= ({jsonEncryptUrl,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarOrganigrama`,{
    return fetch(`${ENDPOINT}/Somosin/EncriptarUrlCodigoUsuario`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonEncryptUrl
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}