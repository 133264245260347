import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { ProductService } from "../../service/ProductService";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { CustomerService } from "../../service/CustomerService";
import RolEditar from "../Rol/RolEditar";
import Boton from "../../components/Boton/Boton";
import InputDefault from "../../components/InputDefault/InputDefault";
import { Route, useNavigate } from "react-router-dom";

export default function Rol() {
    const [expandedRows, setExpandedRows] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [customers2, setCustomers2] = useState([]);
    const [filters1, setFilters1] = useState(null);
    const customerService = new CustomerService();
    const expandAll = () => {
        let _expandedRows = {};
        products.forEach((p) => (_expandedRows[`${p.id}`] = true));

        setExpandedRows(_expandedRows);
    };
    const statusOrderBodyTemplate = (rowData) => {
        return <span className={`order-badge order-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    };
    const deleteBodyTemplate = () => {
        return <Button icon="pi pi-trash" className="p-button-danger" onClick={redirecDelete} />;
    };
    const editBodyTemplate = () => {
        return <Button icon="pi pi-pencil" className="p-button-success" onClick={redirecEdit} />;
    };
    const navigate = useNavigate();
    const redirecEdit = () => {
        navigate("/Dashboard/Rol/Editar");
    };
    const redirecDelete = () => {
        alert("eliminando");
    };
    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            "country.name": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            representative: { value: null, matchMode: FilterMatchMode.IN },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
            verified: { value: null, matchMode: FilterMatchMode.EQUALS },
        });
    };
    const header = (
        <div className="table-header-container">
            {/* <InputText placeholder="Agregar Rol" className="mr-2 mb-2" /> */}
            <InputDefault placeholder="Agregar Rol" />
            {/* <Button icon="pi pi-plus" label="Agregar" onClick={expandAll} className="mr-2 mb-2" /> */}
            <Boton icon="pi pi-plus" nombre="Agregar" />
        </div>
    );
    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };
    const productService = new ProductService();
    useEffect(() => {
        setLoading2(true);
        customerService.getCustomersLarge().then((data) => {
            setCustomers2(getCustomers(data));
            setLoading2(false);
        });

        productService.getProductsWithOrdersSmall().then((data) => setProducts(data));

        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.balance);
    };
    const balanceTemplate = (rowData) => {
        return <span className="text-bold">{formatCurrency(rowData.balance)}</span>;
    };
    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };
    const amountBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };
    const imageBodyTemplate = (rowData) => {
        return <img src={`images/product/${rowData.image}`} onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")} alt={rowData.image} className="shadow-2" width={100} />;
    };
    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.price);
    };
    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    };
    const statusBodyTemplate2 = (rowData) => {
        return <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Permisos para {data.name}</h5>
                <DataTable value={data.orders} responsiveLayout="scroll">
                    <Column field="id" header="Id" sortable></Column>
                    <Column field="module" header="Módulo" sortable></Column>
                    <Column field="create" header="Crear"></Column>
                    <Column field="consult" header="Consultar"></Column>
                    <Column field="modify" header="Modificar"></Column>
                    <Column field="delete" header="Eliminar"></Column>
                    <Column field="execute" header="Ejecutar"></Column>
                    <Column headerStyle={{ width: "4rem" }} body={editBodyTemplate}></Column>
                    <Column headerStyle={{ width: "4rem" }} body={deleteBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };
    return (
        <>
            <h1 className="mt-3">Mantenimiento de Roles</h1>
            <div className="col-12">
                <div className="card">
                    <h5>Listado de Roles</h5>
                    <DataTable value={products} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} responsiveLayout="scroll" rowExpansionTemplate={rowExpansionTemplate} dataKey="id" header={header}>
                        <Column expander style={{ width: "3em" }} />
                        <Column field="id" header="Id" sortable />
                        <Column field="name" header="Nombre" sortable />
                        <Column field="description" header="Descripción" sortable />
                    </DataTable>
                </div>
            </div>
            <RolEditar />
        </>
    );
}
