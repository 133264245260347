import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate, json } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import ToastEva from "../../../../components/Toast/ToastEva";
import { ObtenerDiversidadPolitica } from "../../../../service/ClimaService";
import * as constantes from "../../../../constants/constantes";
import CardFile from "../../../../components/CardFileEva/CardFile";
const Politica = () => {
    const navigate = useNavigate();
    const [politica, setPolitica] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    let { idPlan } = useParams();

    useEffect(()=>{
        const getPolitica = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerDiversidadPolitica({jwt}).then(data => 
                {
                    setPolitica(data)
                })
        }
        if(!politica)getPolitica()
    },[])

    return ( 
        <div className="p-eva-politica">
            <div className="plan-descripcion">
                {politica && politica.descripcion}
            </div>
            <div className="plan-adjuntos" style={{marginTop:24}}>
                {
                    politica && 
                    politica.listaSeccion && 
                    politica.listaSeccion.length>0 &&
                    politica.listaSeccion.map((seccion,j)=>{
                        return(
                            <>
                                <div className="header-subtitle" style={{marginBottom:10}}>{seccion.nombreSeccion}</div>
                                <div className="p-fluid formgrid grid">
                                {
                        
                                    seccion.clima_diversidad_politica_documento.length > 0 &&
                                    seccion.clima_diversidad_politica_documento
                                        .filter((x) => x.tipo == "Adjunto" && x.estado == true)
                                        .map((item,i)=>{
                                            var re = /(?:\.([^.]+))?$/;
                                            return(
                                                <div className="field col-12 md:col-3" key={i}>
                                                        <CardFile urlFile={constantes.URLBLOB_CLIMA_DIVERSIDAD_POLITICAS+"/"+item.nombreDocumento} key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} nombreArchivo={item.nombreDocumento} keys={i} tipo="Adjuntos" descargable={false} />
                                                    </div>
                                            )

                                        })
                                }
                                {
                                    seccion.clima_diversidad_politica_documento.length >0 &&
                                    seccion.clima_diversidad_politica_documento
                                        .filter((x) => x.tipo == "Url" && x.estado == true)
                                        .map((item, i) => {
                                            var re = /(?:\.([^.]+))?$/;
                                            return (
                                                <div className="field col-12 md:col-3" key={i}>
                                                    <CardFile key={i} extensionArchivo={"video"} nombreArchivo={item.nombreDocumento} keys={i} tipo="Urls" />
                                                </div>
                                            );
                                        })}
                                </div>
                            </>
                        )
                    })
                }
                {/* <div className="p-fluid formgrid grid">
                    {
                        
                        politica.clima_diversidad_politica_documento.length > 0 &&
                        politica.clima_diversidad_politica_documento
                            .filter((x) => x.tipo == "Adjunto" && x.estado == true)
                            .map((item,i)=>{
                                var re = /(?:\.([^.]+))?$/;
                                return(
                                    <div className="field col-12 md:col-3" key={i}>
                                            <CardFile urlFile={constantes.URLBLOB_CLIMA_DIVERSIDAD_POLITICAS+"/"+item.nombreDocumento} key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} nombreArchivo={item.nombreDocumento} keys={i} tipo="Adjuntos" descargable={false} />
                                        </div>
                                )

                            })
                    }

                    {politica &&
                            politica.clima_diversidad_politica_documento.length >0 &&
                            politica.clima_diversidad_politica_documento
                                .filter((x) => x.tipo == "Url" && x.estado == true)
                                .map((item, i) => {
                                    var re = /(?:\.([^.]+))?$/;
                                    return (
                                        <div className="field col-12 md:col-3" key={i}>
                                            <CardFile key={i} extensionArchivo={"video"} nombreArchivo={item.nombreDocumento} keys={i} tipo="Urls" />
                                        </div>
                                    );
                                })}
                </div> */}
            </div>
        </div>
     );
}
 
export default Politica;