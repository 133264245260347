import React, { useState, useEffect, useRef } from "react";
import {useNavigate,Link,useParams} from 'react-router-dom';
import "../../components/Inicio/FormsEva.scss";
import "./Usuarios.scss";
import "../../components/Inicio/ConfirmDelete.scss";
import { FastField, Formik } from "formik";
import * as Iconsax from "iconsax-react";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import Boton from "../../components/Boton/Boton";
import useUsuario from "../../hooks/useUsuario";
import { ListUsersGerencia, ListUsers } from "../../service/UsuariosService";
import * as constantes from "../../constants/constantes.js";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import {ListarGerencias} from "../../service/GerenciaService";
import usePermisos from "../../hooks/usePermisos";
//import DialogConfirmDelete from "../../components/Inicio/ConfirmDelete";
import CardGerencia from "../../components/Gerencia/CardGerencia";
export default function Usuario() {
    const [showDialog,setShowDialog] =useState(false);
    let htmlListUserEdit = null;
    let htmlListUserGerencia = null;
    const [usersGerencia, setUsersGerencia] = useState(null);
    //let htmlUserEdit = null;
    const [dropdownItemGerencia, setDropdownItemGerencia] = useState(null);
    const [toggleState, setToggleState] = useState(2);
    const { isCreateLoading, hasCreateError, isCreated, CrearUsuario, ActualizarUsuario, EliminarUsuario, isUpdate, isDelete ,ListarUsuarios,usuariosTotal} = useUsuario();
    const [valueSearch, setValueSearch] = useState("");
    const [activeDelete, setActiveDelete] = useState(false);
    const [activeDialogDelete, setActiveDialogDelete] = useState(false);
    const [idDelete, setIdDelete] = useState(0);
    const [listGerencias, setListGerencias] = useState([]);

    const userDefault = [{ id: 1, nombre: "", codigoColaborador: "", puesto: "", fechaNacimiento: "", idLogin: 0, idRol: 0, correo: "", foto: "", celular: "", genero: "", idMarca: 0, idGerencia: 0 }];

    const { obtenerPermisosVista, permisosVista } = usePermisos();
    const [permisoEditDelete, setpermisoEditDelete] = useState(false);
    const navigate = useNavigate();
    useEffect(()=>{
        const validarPermisos = async(operaciones)=>
        {
            await operaciones.map((item,i)=>{
                if(item === "editarUsuario")
                {
                    //Cambia la logica segun pagina
                    setpermisoEditDelete(true);
                }
            })
        }

        if (permisosVista.length == 0) {
            let vista = "verusuario";
            obtenerPermisosVista({vista});
        }else{
            if(permisosVista.vista)
            {
                if(permisosVista.operaciones.length > 0)
                {
                    validarPermisos(permisosVista.operaciones);

                    //logica
                }
            }
            else{
                navigate("/Login")
            }
        }
    })
    useEffect(()=>{
        const obtenerUsuarios = async()=>{
            await ListarUsuarios();
        }
        if(usuariosTotal.length ==0) obtenerUsuarios();

    },[])
    useEffect(()=>{
        const obtenerGerencias=async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarGerencias(jwt).then(data => setListGerencias(data))
        }

        if(listGerencias.length === 0)
        {
            obtenerGerencias()
        }
     },[]);

    const hideDialog = () => {
        setShowDialog(false);
    };

    const onDeleteUser = (id)=>{
        EliminarUsuario({id});
        setActiveDelete(false)
        setShowDialog(false)
        setActiveSearch(false)
        //alert("eliminar user: "+id)
    }
    const clasesnameDialog = classNames("peva-dialog",{
        "peva-dialog-delete": showDialog,
    });


    const toggleTab = (index) => {
        if(index === 3) setActiveDelete(true)
        if(index === 2) setActiveDelete(false)
        setToggleState(index);
    };
    const getValueSearch = (event) => {
        setValueSearch(event.target.value);
    };

    useEffect(() => {
        if (isCreated || isUpdate   || isDelete) {
            setListUserTotal(null);
            setUsersGerencia(null);
            setActiveSearch(false);
            window.location = navigate(-1);
            //alert("Usuario creado/actualizado/Delete.");
        }
    }, [isCreated, isUpdate,isDelete]);

    const dropdownItemsGerencia = [
        { value: 1, code: "Sistemas" },
        { value: 2, code: "Comercial y Marqueting" },
        { value: 3, code: "Contabilidad" },
        { value: 4, code: "Tesorería y Cobranza" },
    ];

    const [dropdownItemPuesto, setDropdownItemPuesto] = useState(null);
    const dropdownItemsMarca = [
        { value: 1, code: "Marca 1" },
        { value: 2, code: "Marca 2" },
    ];
    const schema = Yup.object().shape({
        correo: Yup.string().required("Correo es un campo obligatorio").email("Formato de correo incorrecto"),
        nombres: Yup.string().required("Nombres y apellidos es un campo obligatorio"),
        dateBirth: Yup.string().required("Fecha de nacimiento es un campo obligatorio"),
        marca: Yup.string().required("Marca es un campo obligatorio"),
        gerencia: Yup.string().required("Gerencia es un campo obligatorio"),
        puesto: Yup.string().required("Puesto es un campo obligatorio"),
        codigoCol: Yup.string().required("Codigo del colaborador es un campo obligatorio"),
    });

    const [activeFiltroEdit, setActiveFiltroEdit] = useState(true);
    const [activeResultEdit, setActiveResultEdit] = useState(false);
    const [activeUsuarioEdit, setActiveUsuarioEdit] = useState(false);
    const [listUsersEdit, setListUsersEdit] = useState(null);
    const [listUserTotal, setListUserTotal] = useState(null);
    const [usersEdit, setUsersEdit] = useState(userDefault);
    const [nameGerenciaHeader, setNameGerenciaHeader] = useState(null);
    const [nameUserHeader, setNameUserHeader] = useState(null);
    const [activeSearch, setActiveSearch] = useState(false);

    // const [activeBackResultEdit, setActiveBackResultEdit] = useState(false);
    // const [activeBackUsuarioEdit, setActiveBackUsuarioEdit] = useState(false);
    const URLAVATARDEFAULT = constantes.URLAVATARDEFAULT;

    if (listUsersEdit != null) {
        htmlListUserEdit = listUsersEdit[0].users.map((item, i) => {
            return (
                <div className="field col-12 md:col-4 " key={i} style={{ color: "#000000" }}>
                    <div className="card peva-card-content">
                        <div className="peva-profile">
                            <img className="peva-profile-image" src={item.foto === null || item.foto === "" ? URLAVATARDEFAULT : constantes.URLAPI + "/Image/perfil/" + item.foto + "/" + item.id}></img>
                        </div>
                        <div className="peva-profile-name">
                            <span>{item.nombre}</span>
                        </div>
                        {
                            permisoEditDelete && 
                            <div className="peva-profile-edit">
                            <div style={{ cursor: "pointer" }} onClick={() => navigate("../Usuario/Editar/" + item.id)}>
                                <Iconsax.Edit2 color="#F47647" />
                            </div>
                            <div onClick={() => onDeleteUser(item.id, item.nombre)} style={{ cursor: "pointer" }}>
                                <Iconsax.Trash color="#F47647" />
                            </div>
                        </div>

                        }
                        
                        <div className="peva-profile-email">
                            <i className="peva-profile-icon">
                                <Iconsax.Sms color="#F47647" />
                            </i>
                            <span>{item.correo}</span>
                        </div>
                    </div>
                </div>
            );
        });
    }


    const onEntry = (idJson) => {
        let list = Object.entries(usersGerencia)
            .filter(([key, obj]) => key == idJson)
            .map((item) => item[1]);
        navigate("Gerencia/"+idJson)
        // setNameGerenciaHeader(list[0].gerencia);
        // setListUsersEdit(list);
        // setActiveFiltroEdit(false);
        // setActiveResultEdit(true);
        // setActiveUsuarioEdit(false);
    };
    // const onSearch = () => {
    //     setActiveSearch(true);
    //     ListUsers(window.localStorage.getItem("jwt")).then((data) => setListUserTotal(data));
    // };
    // if (listUserTotal != null) {
    //     let list = Object.entries(listUserTotal)
    //         .filter(([key, obj]) => obj.codigoColaborador.toLowerCase().includes(valueSearch.toLocaleLowerCase()))
    //         .map((item) => item[1]);

    //     let temp = [{ gerencia: "todas", users: list }];
    //     setListUsersEdit(temp);

    //     setListUserTotal(null);
    // }


    const onSearch = () => {
        setActiveSearch(true);
        //ListUsers(window.localStorage.getItem("jwt")).then((data) => setListUserTotal(data));
        if (usuariosTotal != null) {
            let list = Object.entries(usuariosTotal)
                .filter(([key, obj]) => obj.nombre.toLowerCase().includes(valueSearch.toLocaleLowerCase()))
                .map((item) => item[1]);

            let temp = [{ gerencia: "todas", users: list }];
            setListUsersEdit(temp);

            //setListUserTotal(null);
        }

    };

    const onEntryEditUser = (idJson) => {
        let user = Object.entries(listUsersEdit[0].users)
            .filter(([key, obj]) => key == idJson)
            .map((item) => item[1]);
        if(activeDelete)
        {
            setShowDialog(true);
            setIdDelete(user[0].id)
            setNameUserHeader(user[0].nombre);

        }else{
            setUsersEdit(user);
            setNameUserHeader(user[0].nombre);
            setActiveFiltroEdit(false);
            setActiveResultEdit(false);
            setActiveUsuarioEdit(true);
        }

    };

    const onBackResultEdit = () => {
        setActiveFiltroEdit(true);
        setActiveResultEdit(false);
        setActiveUsuarioEdit(false);
    };

    const onBackUsuariotEdit = () => {
        if(activeSearch === true)
        {
            setActiveFiltroEdit(true);
            setActiveResultEdit(false);
            setActiveUsuarioEdit(false);
        }else{
            setActiveFiltroEdit(false);
            setActiveResultEdit(true);
            setActiveUsuarioEdit(false);
        }

    };

    // useEffect(() => {
    //     ListUsersGerencia().then(data => setUsersGerencia(data));
    // }, []);

    useEffect(()=>{
        const obtenerUsuariosPorGerencia=async()=>{
            await ListUsersGerencia(window.localStorage.getItem("jwt")).then((data) => setUsersGerencia(data));
        }
        if (usersGerencia == null) {
            obtenerUsuariosPorGerencia()
        }
    },[])

    if (usersGerencia != null) {
        htmlListUserGerencia = usersGerencia.map((item, i) => {
            if (item.users.length > 0) {
                return (
                    // <div className="field card col-12 md:col-4 peva-card-user" key={i}>
                    //     <div className="p-fluid formgrid grid">
                    //         <div className="field col-12 md:col-12 peva-card-user-body">
                    //             <div className="peva-gerencia">
                    //                 <p>{item.gerencia}</p>
                    //             </div>
                    //             <div className="peva-colaboradores">
                    //                 {" "}
                    //                 <p>{item.users.length} Colaboradores</p>
                    //             </div>
                    //         </div>
                    //         {/* <div className="field col-12 md:col-6" />
                    //         <div className="field col-12 md:col-6">
                    //             <Boton nombre="Entrar" metodoClick={() => onEntry(i)} widths="90%" color="primary" icon="pi pi-angle-right"></Boton>
                    //         </div> */}
                    //     </div>
                    //     <div className="p-eva-footer-form">
                    //         <Boton nombre="Entrar" metodoClick={() => onEntry(i)} widths="109px" color="primary" icon="pi pi-angle-right"></Boton>

                    //     </div>
                    // </div>
                    <div className="field col-12 md:col-3" key={i}>
                        <CardGerencia NombreGerencia={item.gerencia} colaboradores={item.users.length + " colaboradores"}
                        onClicButton={() => onEntry(item.idGerencia)}
                        ></CardGerencia>
                    </div>

                );
            }
        });
    }

    return (
        <div className="eva-dashboard" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <div className="eva-dashboard-header" hidden={!activeFiltroEdit}>
                <label htmlFor="changePass">Mantenimiento de usuarios</label>
            </div>
            <div className="eva-dashboard-usuarios" hidden={!activeFiltroEdit} style={{color:"#000000"}}>
                {/* <div className="containerTab">
                    <div className="bloc-tabs">
                       
                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>
                            Editar
                        </button>
                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(3)}>
                            Eliminar
                        </button>
                    </div>

                    <div className="content-tabs">
                        
                        <div className={toggleState === 2 ? "contentTabs  active-content" : "contentTabs"}>
                            
                            <div className="p-fluid formgrid grid">
                               
                                <div className="field col-12 md:col-9">
                                    <div className="peva-input-icon">
                                        <input className="peva-input icon-search" placeholder="Buscar por código de colaborador" onChange={getValueSearch} />
                                        <div className="peva-icon-rigth">
                                            <i onClick={onSearch}>
                                                <Iconsax.SearchNormal1 />
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="field col-12 md:col-3" />
                               

                                {!activeSearch ? htmlListUserGerencia : htmlListUserEdit}

                            </div>
                        </div>

                        <div className={toggleState === 3 ? "contentTabs  active-content" : "contentTabs"}>
                            <div className="p-fluid formgrid grid">
                               
                                <div className="field col-12 md:col-9">
                                    <div className="peva-input-icon">
                                        <input className="peva-input icon-search" placeholder="Buscar por código de colaborador" onChange={getValueSearch} />
                                        <div className="peva-icon-rigth">
                                            <i onClick={onSearch}>
                                                <Iconsax.SearchNormal1 />
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="field col-12 md:col-3" />
                               

                                {!activeSearch ? htmlListUserGerencia : htmlListUserEdit}

                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="p-fluid formgrid grid">
                                {/** BUQUEDA DE USUARIO */}
                                <div className="field col-12 md:col-9">
                                    <div className="peva-input-icon">
                                        <input className="peva-input icon-search" placeholder="Buscar por código de colaborador" onChange={getValueSearch} />
                                        <div className="peva-icon-rigth">
                                            <i onClick={onSearch}>
                                                <Iconsax.SearchNormal1 />
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="field col-12 md:col-3" />
                                {/** RESULTADOS DE BUSQUEDA LISTA DE USUARIOS POR COLABORADORES */}

                                {!activeSearch ? htmlListUserGerencia : htmlListUserEdit}

                            </div>
            </div>
            <div className="peva-dashboard-usuarios-result" hidden={!activeResultEdit}>
                <div className="peva-dashboard-usuarios-result-header">
                    <button type="button" className="p-sidebar-close p-sidebar-icon p-link" aria-label="close" onClick={onBackResultEdit}>
                        <span className="p-sidebar-close-icon ">
                            <Iconsax.ArrowLeft />
                        </span>
                        <span className="p-ink"></span>
                    </button>
                    <div className="eva-dashboard-header" style={{ marginBottom: "20px" }}>
                        <label>{nameGerenciaHeader}</label>
                    </div>
                    {/* <h5>LISTA DE USUARIOS -EDIT</h5> */}
                </div>
                <div className="peva-dashboard-usuarios-result-body">
                    <div className="p-fluid formgrid grid">
                        {htmlListUserEdit}
                        {/* <div className="field col-12 md:col-4 ">
                            <div className="card peva-card-content">
                                <div className="peva-profile">
                                    <img className="peva-profile-image"src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"></img>

                                </div>
                                <div className="peva-profile-name">
                                    <span>Ana Camila Valeria Torres Cordova</span>
                                </div>
                                <div className="peva-profile-edit">
                                    <i onClick={onEntryEditUser} className="peva-profile-icon" >
                                        <Iconsax.Edit2 color="#F47647"/>
                                    </i>

                                </div>
                                <div className="peva-profile-email">
                                    <i className="peva-profile-icon"><Iconsax.Sms color="#F47647"/></i>
                                    <span>cvasquezc@inlearning.edu.pe</span>
                                </div>
                            </div>

                        </div>
                        <div className="field col-12 md:col-4 ">
                            <div className="card peva-card-content">
                                <div className="peva-profile">
                                    <img className="peva-profile-image"src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"></img>

                                </div>
                                <div className="peva-profile-name">
                                    <span>Ana Camila Valeria Torres Cordova</span>
                                </div>
                                <div className="peva-profile-edit">
                                    <i className="peva-profile-icon" onClick={onEntryEditUser}><Iconsax.Edit2 color="#F47647"/></i>
                                </div>
                                <div className="peva-profile-email">
                                    <i className="peva-profile-icon"><Iconsax.Sms color="#F47647"/></i>
                                    <span>cvasquezc@inlearning.edu.pe</span>
                                </div>
                            </div>

                        </div>
                        <div className="field col-12 md:col-4 ">
                            <div className="card peva-card-content">
                                <div className="peva-profile">
                                    <img className="peva-profile-image "src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"></img>

                                </div>
                                <div className="peva-profile-name">
                                    <span>Ana Camila Valeria Torres Cordova</span>
                                </div>
                                <div className="peva-profile-edit">
                                    <i className="peva-profile-icon" onClick={onEntryEditUser}><Iconsax.Edit2 color="#F47647"/></i>
                                </div>
                                <div className="peva-profile-email">
                                    <i className="peva-profile-icon"><Iconsax.Sms color="#F47647"/></i>
                                    <span>cvasquezc@inlearning.edu.pe</span>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </div>
            </div>
            <div className="peva-dashboard-usuarios-edit" hidden={!activeUsuarioEdit}>
                <div className="peva-dashboard-usuarios-edit-header">
                    <button type="button" className="p-sidebar-close p-sidebar-icon p-link" aria-label="close" onClick={onBackUsuariotEdit}>
                        <span className="p-sidebar-close-icon ">
                            <Iconsax.ArrowLeft />
                        </span>
                        <span className="p-ink"></span>
                    </button>
                    <div className="eva-dashboard-header" style={{ marginBottom: "20px" }}>
                        <label>{nameUserHeader}</label>
                    </div>
                    {/* <h5>{nameUserHeader}</h5> */}
                </div>
                {/* <div className="peva-dashboard-usuarios-edit-body">
                    {htmlUserEdit}
                </div> */}
                {/* {htmlUserEdit} */}

                <div className="peva-dashboard-usuarios-edit-body">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{ puesto: usersEdit[0].puesto, nombres: usersEdit[0].nombre, dateBirth: usersEdit[0].fechaNacimiento.substring(10,0), marca: usersEdit[0].idMarca, correo: usersEdit[0].correo, gerencia: usersEdit[0].idGerencia, codigoCol: usersEdit[0].codigoColaborador, id: usersEdit[0].id }}
                        onSubmit={(values) => {
                            // Alert the input values of the form that we filled
                            // let userName = values.username;
                            // let password = values.password;
                            // login({ userName, password });
                            let id = values.id;
                            let nombre = values.nombres;
                            let codigoColaborador = values.codigoCol;
                            let puesto = values.puesto;
                            let fechaNacimiento = values.dateBirth;
                            let idLogin = 0;
                            let idRol = 0;
                            let correo = values.correo;
                            let idGerencia = values.gerencia;
                            let idMarca = values.marca;
                            let foto = "";
                            let celular = values.celular;
                            let jsonUsuario = JSON.stringify({ id, nombre, codigoColaborador, puesto, fechaNacimiento, idLogin, idRol, correo, idGerencia, foto, celular, idMarca });
                            //CrearUsuario({ jsonUsuario })

                            ActualizarUsuario({ jsonUsuario });
                            //alert(JSON.stringify(jsonUsuario));
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="nombres" className="peva-label">
                                            Nombres y apellidos
                                        </label>
                                        <input
                                            id="nombres"
                                            type="text"
                                            className="peva-input peva-input-disabled"
                                            placeholder="Escribe aquí"
                                            value={values.nombres}
                                            //value={usersEdit[0].nombre}
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}
                                            disabled={true}
                                            name="nombres"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <p className="error">{errors.nombres && touched.nombres && errors.nombres}</p>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="dateBirth" className="peva-label">
                                            Fecha de nacimiento
                                        </label>
                                        <input
                                            id="dateBirth"
                                            type="Date"
                                            className="peva-input peva-input-disabled"
                                            placeholder="Escribe aquí"
                                            value={values.dateBirth}
                                            //value={usersEdit[0].fechaNacimiento}
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}
                                            disabled={true}
                                            name="dateBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <p className="error">{errors.dateBirth && touched.dateBirth && errors.dateBirth}</p>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="marca" className="peva-label">
                                            Marca
                                        </label>
                                        <Dropdown
                                            id="marca"
                                            value={values.marca}
                                            //value={usersEdit[0].idMarca}
                                            //onChange={handleChange}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            //onBlur={handleBlur}
                                            options={dropdownItemsMarca}
                                            optionLabel="code"
                                            placeholder="Selecciona"
                                            className="peva-input"
                                            name="marca"
                                        />
                                        {/* <input
                                                    id="marca"
                                                    type="text"
                                                    className="peva-input"
                                                    placeholder="Escribe aquí"
                                                    value={values.marca}
                                                    //onChange={(e) => setUserName(e.target.value)} value={values.username}

                                                    name="marca"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                        <p className="error">{errors.marca && touched.marca && errors.marca}</p>
                                    </div>

                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="correo" className="peva-label">
                                            Correo electronico
                                        </label>
                                        <input
                                            id="correo"
                                            type="text"
                                            className="peva-input"
                                            placeholder="Escribe aquí"
                                            value={values.correo}
                                            //value={usersEdit[0].correo}
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                                            name="correo"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <p className="error">{errors.correo && touched.correo && errors.correo}</p>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="gerencia" className="peva-label">
                                            Gerencia
                                        </label>
                                        <Dropdown
                                            id="gerencia"
                                            name="gerencia"
                                            value={values.gerencia}
                                            //value={usersEdit[0].idGerencia}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            options={listGerencias}
                                            optionLabel="code"
                                            placeholder="Selecciona"
                                            className="peva-input"
                                        />

                                        <p className="error">{errors.gerencia && touched.gerencia && errors.gerencia}</p>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="puesto" className="peva-label">
                                            Puesto
                                        </label>
                                        <input
                                            id="puesto"
                                            type="text"
                                            className="peva-input"
                                            placeholder="Escribe aquí"
                                            value={values.puesto}
                                            //value={usersEdit[0].puesto}
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                                            name="puesto"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <p className="error">{errors.puesto && touched.puesto && errors.puesto}</p>
                                    </div>

                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="codigoCol" className="peva-label">
                                            Código del colaborador
                                        </label>
                                        <input
                                            id="codigoCol"
                                            type="text"
                                            className="peva-input peva-input-disabled"
                                            placeholder="Escribe aquí"
                                            value={values.codigoCol}
                                            //value={usersEdit[0].codigoColaborador}
                                            //onChange={(e) => setUserName(e.target.value)} value={values.username}

                                            name="codigoCol"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <p className="error">{errors.codigoCol && touched.codigoCol && errors.codigoCol}</p>
                                    </div>

                                    <div className="field col-12 md:col-4"></div>
                                    <div className="field col-12 md:col-4"></div>

                                    <div className="field col-12 md:col-10"> </div>
                                    <div className="field col-12 md:col-2 peva-footer">
                                        {/* <Button label="Guardar cambios" className="peva-button-usuarios" /> */}
                                        <Boton nombre="Guardar cambios" color="primary"widths="100%" type="submit" displayButton={permisoEditDelete?"block":"none"}/>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>

            <Dialog visible={showDialog} modal className={clasesnameDialog} onHide={hideDialog} showHeader={false}>

                <div className="peva-label-confirm" >
                    <label htmlFor="confirmDelete" >Quieres eliminar a {nameUserHeader} de la plataforma InLearning?</label>
                </div>
                <div className="peva-label-confirm-button">
                    <Button label="Cancelar"className="peva-label-confirm-cancel" onClick={hideDialog}/>
                    <Button label="Eliminar colaborador"className="peva-label-confirm-delete" onClick={()=>onDeleteUser(idDelete)}/>
                </div>
            </Dialog>
        </div>
    );
}
