import React, { useState, useEffect, useRef } from 'react';
import "./CardCorreo.scss"
import * as constantes from "../../constants/constantes.js";
import useUsuario from '../../hooks/useUsuario';
import Boton from '../Boton/Boton';
const CardCorreo = () => {
    const {configuraciones} =useUsuario();
    const [valorCorreo, setValorCorreo] = useState(null);
    useEffect(()=>{

        if(configuraciones.length > 0)
        {
            let home_correo = configuraciones.filter(x=>x.codigo=="HOME_CORREO");
           
            if(home_correo[0].valor.length > 0)
            {
                setValorCorreo(home_correo[0].valor)
            }    
               
        }

    },[configuraciones])
    return ( 
        <div className='p-eva-cardCorreo'>
            <div className='p-eva-cardCorre-body'>
                <div className='cardCorre-body-img'>
                    <img src='../../../images/inicio/email_2.png' width={72}></img>
                </div>
                <div className='cardCorre-body-texto'>
                Ingresar a mi correo office 365
                </div>
                <div className='cardCorre-body-boton'>
                    <Boton nombre={"Acceder"} color='secondary' widths='200px'
                         metodoClick={() => window.open(valorCorreo, "_blank", "noopener,noreferrer")}
                    ></Boton>
                </div>
            </div>
        </div>
     );
}
 
export default CardCorreo;