import { registry } from 'chart.js';
import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import {Registrar,Actualizar,ObtenerTemaId}from "../service/LiderInService";
export default function UseLiderIn(){
    const {perfil,jwt,setPerfil,setJwt} = useContext(Context)
    const [stateCreate,setStateCreate] = useState({loading:false,error:false,success:false});
    const [stateUpdate, setStateUpdate] = useState({loading:false,error:false,success:false});
    const [listaTemas, setListaTemas] = useState([]);
    const [tema, setTema] = useState(null);
    const RegistrarTema = useCallback(({jsonTema})=>{
        Registrar({jsonTema,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
        })
    },[setStateCreate])

    const ActualizarTema = useCallback(({jsonTema})=>{
        Actualizar({jsonTema,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateUpdate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateUpdate({loading:false,error:true,success:false})
        })
    },[setStateUpdate])
    const ObtenerTemaPorId = useCallback(async ({id})=>{
        await ObtenerTemaId({id,jwt})
        .then(data=>{
            setTema(data);
        })
        .catch(err=>{
            setTema(null)
        })

    },[setTema])
    return{
        RegistrarTema,
        hasCreateError : stateCreate.error,
        isCreateLoading: stateCreate.loading,
        isCreated : stateCreate.success,
        ActualizarTema,
        hasUpdateError : stateUpdate.error,
        isUpdateLoading: stateUpdate.loading,
        isUpdate : stateUpdate.success,
        ObtenerTemaPorId,
        tema
    }
}
