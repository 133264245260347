import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
//import App from './App';
import App from './App';
// import * as serviceWorker from './serviceWorker';
// import * as serviceWorker from './serviceWorkerTest';
import { BrowserRouter,Route,Routes,Navigate } from 'react-router-dom'
import { createRoot } from 'react-dom/client';
import Login from './pages/Login/Login';
import { UsuarioContextProvider } from './context/usuarioContext';
import PdfViewer from './pages/PDF/PdfViewer';
//simport ScrollToTop from './ScrollToTop';

// ReactDOM.render(
//     <BrowserRouter>
//         <App/>
        
//     </BrowserRouter>,
//     document.getElementById('root')
    
// );

const root = createRoot(document.getElementById('root'));
const  jwt = window.localStorage.getItem("jwt");
//serviceWorker.register();
root.render(<BrowserRouter>
             {/* <App/> */}
             <UsuarioContextProvider>
                <Routes>
                    <Route path="/" element={Boolean(jwt)? <Navigate to="/Dashboard/Home"/>:<Navigate to="/Login"/>}>
                        
                    </Route>
                    <Route path='/Login' element={<Login/>}/>
                    <Route path="/Dashboard/*" element={<App/>} />
                    <Route path="/PreviewPdf/:nombrePdf" element={<PdfViewer/>} />
                </Routes>
            </UsuarioContextProvider>
            
         </BrowserRouter>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
// serviceWorker.register()
