
import React, { useState } from "react";
import parse from 'html-react-parser'
import { useScrapper } from 'react-tiny-link'

export function ObtenerTiempoTranscurrido(fecharegistro,fechaActual) {

    var date_now= new Date
    var d = Math.abs(new Date(fechaActual).getTime() - new Date(fecharegistro).getTime()) / 1000;                           // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function(key){
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });
    // console.log(r)
    var tiempo ='';
    if( r.year > 0)
    {
        tiempo = 'Hace '+ r.year +" años"
    }
    else if(r.month >0){
        tiempo = 'Hace '+ r.month +" meses"
    }
    else if(r.week > 0)
    {
        tiempo = 'Hace '+ r.week +" semanas"
    }
    else if(r.day > 0)
    {
        tiempo = 'Hace '+ r.day +" días"
    }
    else if(r.hour > 0)
    {
        tiempo = 'Hace '+ r.hour +" horas"
    }
    else if(r.minute > 0)
    {
        tiempo = 'Hace '+ r.minute +" minutos"
    }
    else{ tiempo  ='Ahora'}
    return tiempo;
};

export function ObtenerMensajeNotificacion(tipoInteraccion="",nombreReferencia="",destinoLogin = false ,grupo ="") {
    var mensaje = ""
    if(tipoInteraccion == "FELICITACION")
    {
        if(destinoLogin)
        {
            mensaje = "Te felicitó por tu cumpleaños."
        }else{
            mensaje ="Te felicitó por tu cumpleaños.";
        }
    }
    else if(tipoInteraccion == "FELICITACION-ANIVERSARIO")
    {
        if(destinoLogin)
        {
            mensaje = "Te felicitó por tu aniversario."
        }else{
            mensaje ="Te felicitó por tu aniversario.";
        }
    }

    else if(tipoInteraccion == "COMENTARIO")
    {
        if(destinoLogin)
        {
            mensaje = "Comentó tu publicación."
        }else{
            mensaje ="Hizo un comentario en la publicación de "+ nombreReferencia;
        }
    }
    else if(tipoInteraccion == "INTERACCION")
    {
        if(destinoLogin)
        {
            mensaje = "Reaccionó a tu publicación."
        }else{
            mensaje ="Reaccionó a una publicación de "+ nombreReferencia;
        }
    }
    else if(tipoInteraccion == "PUBLICACION")
    {
        mensaje ="Subió una publicación en el grupo "+grupo+ "."
    }
    else if(tipoInteraccion == "APROBACION")
    {
        mensaje ="Tiene publicaciones pendiente de aprobación en el grupo "+grupo+ "."
    }

    else if(tipoInteraccion == "RECHAZO")
    {
        mensaje ="Rechazó tu publicación en el grupo "+grupo+ ". Para más información comunicarte con el área respectiva."
    }

    else if(tipoInteraccion == "CONVOCATORIA")
    {
        mensaje ="¡Tenemos una nueva convocatoria interna!. Has clic para descubrir que tenemos para ti."
    }
    return mensaje;
}

export function ObtenerFechaFormatoCorto(fecha) {
    let objectDate = new Date(fecha);


    let day = objectDate.getDate();

    let month = objectDate.getMonth() +1;

    let year = objectDate.getFullYear();

    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }
    
    let format = `${day}/${month}/${year}`;

    return format;
}

export function ObtenerFechaFormatoDiaAnio(fecha) {
    let objectDate = new Date(fecha);


    let day = objectDate.getDate();

    let month = objectDate.getMonth() +1;

    let year = objectDate.getFullYear();

    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }
    
    let format = `${day}/${year}`;

    return format;
}

export function ObtenerFechaFormatoPalabra(fecha) {
    let objectDate = new Date(fecha);

    let day = objectDate.getDate();

    let month = objectDate.getMonth() +1;

     
    let nameDay = objectDate.toLocaleDateString("pe-ES", { weekday: 'long' });  
    nameDay =nameDay.charAt(0).toUpperCase()+ nameDay.substring(1)
    let nameMonth = objectDate.toLocaleDateString("pe-ES", { month: 'long' });  

    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }
    
    let format = `${nameDay} ${day} de ${nameMonth}`;
    return format;
}

export function ObtenerFechaFormatoPalabraMes(fecha) {
    let objectDate = new Date(fecha);

    let day = objectDate.getDate();

    let month = objectDate.getMonth() +1;

     
    let nameDay = objectDate.toLocaleDateString("pe-ES", { weekday: 'long' });  
    nameDay =nameDay.charAt(0).toUpperCase()+ nameDay.substring(1)
    let nameMonth = objectDate.toLocaleDateString("pe-ES", { month: 'long' });  

    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }
    
    let format = `${day} de ${nameMonth}`;
    return format;
}

export function FechaFormatoCorto(fecha) {

    let objectDate = new Date(fecha);

    let day = objectDate.getDate();

    let month = objectDate.getMonth() +1;

    let year = objectDate.getFullYear()

     


    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }
    
    let format = `${year}-${month}-${day}`;
    return format;
}

export function ObtenerUrlTexto (text){
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var textoNuevo= text.replace(urlRegex, function (url) {
      var hyperlink = url;
    //   if (!hyperlink.match('^https?:\/\/')) {
    //     hyperlink = 'http://' + hyperlink;
    //   }
    let href = ""

    if(hyperlink.indexOf("http") > -1)
    {
        href=hyperlink;
    }else{
        href= 'http://'+hyperlink
    }

    // return '<a  className="p-eva-labelForm-orange" target="_blank" href="http://'+hyperlink+'">'+hyperlink+'</a>'
    return '<a  className="p-eva-labelForm-orange" target="_blank" href="'+href+'">'+hyperlink+'</a>'
    //   return '<i onClick={()=>redirectLink("'+hyperlink+'")>'+hyperlink+'</i>'
      
    });
    return  <div>{parse(textoNuevo)}</div>
}
export function getBase64 (file){
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);
      const regex = /data:.*base64,/
      reader.onload = () => {
        //console.log("Called", reader);
        baseURL = reader.result;
        //console.log(baseURL);
        resolve(baseURL.replace(regex,""));
      };
      console.log(fileInfo);
    });
  };

export function getYears(inicio)
{
    var currentYear = new Date().getFullYear(), years = [];
    inicio = inicio || 1980;  
    while ( inicio <= currentYear ) {
        years.push(inicio++);
    }   

    return years.sort(function(a, b) {
        return b-a;
      });
}

export function htmlToPlainText(html)
{
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const plainText = doc.body.textContent;
    return plainText;
}

//   export function ObtenerThumb (link){
//     const [data,result, loading, error]= useScrapper({
//         url: link, proxyUrl:true
//       })
    
//       useEffect(()=>{
//         if(data)
//         {
//             if(data.image[0])
//             {
//                 return(data.image[0])
//             }
//             else{return("../../../../../../../images/inicio/play.png")}
//         }
//         console.log(data)
//       },[data])
//   };