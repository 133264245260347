import React, { useState, useEffect, useRef } from 'react';
import "./CardDocumentoAniversario.scss"
import Boton from '../../Boton/Boton';
import useUsuario from "../../../hooks/useUsuario";
import DocumentViewer from '../../PDF/DocumentViewer';
import * as constantes from "../../../constants/constantes.js";
const CardDocumentoAniversario = () => {
    const {configuraciones} = useUsuario();

    const [aniversario, setAniversario] = useState(null);
    const [cumpleanio, setCumpleanio] = useState(null);

    const [visibleModalPdf, setVisibleModalPdf] = useState(false);

    const [urlFile, setUrlFile] = useState(null);
    const [nombreArchivo, setNombreArchivo] = useState(null);

    useEffect(()=>{

        if(configuraciones.length > 0)
        {
            let docAnivevrsario = configuraciones.filter(x=>x.codigo=="DOCUMENTO_ANIVERSARIO");
            let docCumpleanio = configuraciones.filter(x=>x.codigo=="DOCUMENTO_CUMPLEANIOS");
            if(docAnivevrsario[0].valor.length > 0)
            {
                setAniversario({nombreDocumento: docAnivevrsario[0].valor,imagenBase64: "",tipoDocumento:""})
            }    
            if(docCumpleanio[0].valor.length > 0)
            {
                setCumpleanio({nombreDocumento: docCumpleanio[0].valor,imagenBase64: "",tipoDocumento:""})
            }     
        }
        

    },[configuraciones])

const mostrarPdf =(url,nombre)=>{
    setUrlFile(url)
    setVisibleModalPdf(true)
    setNombreArchivo(nombre)
}

    return ( 
        <div className="p-eva-documentoAniversario">
            <div className='documentoAniversario-header'>¿Deseas conocer los cumpleaños y aniversarios de mes?</div>
            <div className='documentoAniversario-body'>
                <div className='documentoAniversario-body-item'>
                    <div className='documentoAniversario-icon'>
                        <img src={"../../../../images/inicio/017-gift.png"} width={48}></img>
                    </div>
                    <div className='documentoAniversario-texto p-eva-labelForm'>Cumpleaños</div>
                    <div className='documentoAniversario-buton'>
                        <Boton nombre={"¡conocer más!"} color="secondary" widths='149px' disabled={!cumpleanio}
                            metodoClick={()=>mostrarPdf(constantes.URLBLOB_DOCUMENTO_CUMPLEAÑOS+"/"+cumpleanio.nombreDocumento,cumpleanio.nombreDocumento)}
                            ></Boton>
                    </div>
                </div>
                <div className='documentoAniversario-body-item'>
                    <div className='documentoAniversario-icon'>
                        <img src={"../../../../images/inicio/011-confetti.png"} width={48}></img>
                    </div>
                    <div className='documentoAniversario-texto p-eva-labelForm'>Aniversarios</div>
                    <div className='documentoAniversario-buton'>
                        <Boton nombre={"¡conocer más!"} color="secondary" widths='149px' disabled={!aniversario}
                                 metodoClick={()=>mostrarPdf(constantes.URLBLOB_DOCUMENTO_ANIVERSARIO+"/"+aniversario.nombreDocumento,aniversario.nombreDocumento)}
                                 ></Boton>
                    </div>
                </div>
            </div>

            {
                visibleModalPdf&&
                    <DocumentViewer visibleModal={visibleModalPdf} urlFile={urlFile} descarga={true} nombreArchivo={nombreArchivo}
                    onHide={()=>setVisibleModalPdf(false)}></DocumentViewer>

               
                
            }
        </div>
     );
}
 
export default CardDocumentoAniversario;