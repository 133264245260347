import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionMia,ListarSeccionCapacitacion } from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import "./CapaMia.scss"
import Boton from "../../../components/Boton/Boton";

import { ObtenerFechaFormatoPalabra } from "../../../helpers/helpers";
import * as constantes from "../../../constants/constantes";
import CardFile from "../../../components/CardFileEva/CardFile";
const CapaMia = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [capaMia, setCapaMia] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarCapaMia") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verCapaIntroduccion") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])
    useEffect(()=>{
        const obtenerListaSeccionCapa = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccionCapacitacion({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccion);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccion){
            obtenerListaSeccionCapa();
        } 
    },[idSeccion])

    useEffect(()=>{
        const getCapaMia = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            ObtenerCapacitacionMia({jwt}).then(data=>setCapaMia(data))
            .catch(errors => {
                toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

            })
        }
        if(!capaMia)getCapaMia();
    },[])

    return (
        <div className="eva-capaMia" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    <div className="header-right">{capaMia && permisoEditar && tituloPagina && <Link to={"../Capacitacion/Seccion/" + idSeccion + "/EditarMia/" + capaMia.id}>{"Editar " + tituloPagina}</Link>}</div>
                </div>
            </div>
            <div className="p-eva-dashboard-capaMia" style={{ marginTop: 16 }}>
                <div className="p-eva-labelForm">
                    <span style={{whiteSpace:"pre-line",color:"#000000"}}>{capaMia ? capaMia.descripcion : <Skeleton className="mb-2" width="700px" height="100%" borderRadius="16px" />}</span>
                </div>
                <div className="capaMia-fechaLimite" style={{ marginTop: 24 }}>
                    <div>
                        <img src="../../../../images/capacitacion/Clock_perspective_matte.png" width={40}></img>
                    </div>
                    {capaMia && (
                        <div>
                            <span className="fechaLimite-left">Fecha límite de inscripción </span>
                            <span className="fechaLimite-right">
                                <strong>{ObtenerFechaFormatoPalabra(capaMia.fechaLimite)}</strong>
                            </span>
                        </div>
                    )}
                </div>
                {capaMia && (
                    <div className="capaMia-link">
                        <Boton nombre={capaMia.textoBoton} metodoClick={() => window.open(capaMia.link.indexOf("http") > -1 ? capaMia.link : "http://" + capaMia.link, "_blank", "noopener,noreferrer")} widths={208}></Boton>
                    </div>
                )}
                <div className="p-eva-dashcboard-documentos" style={{ marginTop: 24 }}>
                    <div style={{paddingBottom:16}}><span className="header-subtitle" >Revisa el tutorial en caso no recuerdes acceder a la plataforma</span></div>
                    <div className="p-fluid formgrid grid">
                        {capaMia &&
                            capaMia.capacitacion_mia_documentos &&
                            capaMia.capacitacion_mia_documentos
                                .filter((x) => x.tipo == "Adjunto" && x.estado == true)
                                .map((item, i) => {
                                    var re = /(?:\.([^.]+))?$/;
                                    return (
                                        <div className="field col-12 md:col-3" key={i}>
                                            <CardFile urlFile={constantes.URLBLOB_CAPAMIA + "/" + item.nombreDocumento} key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} nombreArchivo={item.nombreDocumento} keys={i} tipo="Adjuntos" descargable={false} />
                                        </div>
                                    );
                                })}
                        {capaMia &&
                            capaMia.capacitacion_mia_documentos &&
                            capaMia.capacitacion_mia_documentos
                                .filter((x) => x.tipo == "Url" && x.estado == true)
                                .map((item, i) => {
                                    var re = /(?:\.([^.]+))?$/;
                                    return (
                                        <div className="field col-12 md:col-3" key={i}>
                                            <CardFile key={i} extensionArchivo={"video"} nombreArchivo={item.nombreDocumento} keys={i} tipo="Urls" />
                                        </div>
                                    );
                                })}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CapaMia;