import React, { useState,useRef,forwardRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import "./Dropdown.scss"
import classNames from "classnames";

// const DropdownDefault = ({id,value,onChange,onBlur,options,optionLabel,optionValue,placeholder,className,name,errorClass})=>
const DropdownDefault = forwardRef((props,ref) =>{
    const dropdownClassName = classNames("p-eva-dropdownDefault", {
        "white":props.classNames === "white" ? true:false,
        "grey" : props.classNames === "grey" ? true:false,
        "p-eva-dropdown-error" : props.errorClass === "error" ? true:false,
        "p-eva-dropdown-success" : props.errorClass === "success" ? true:false,
    });
    return (
        <Dropdown

            // id={id}
            // value={value}
            // onChange={onChange}
            // onBlur={onBlur}
            // options={options}
            // optionLabel={optionLabel}
            // placeholder={placeholder}
            // className={dropdownClassName}
            // name={name}
            // optionValue={optionValue}
            className={dropdownClassName} {...props}
            
        />
    );

})

export default DropdownDefault;