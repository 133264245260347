import React, { useState, useEffect ,useRef} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import { ListarSeccionCapacitacion ,ObtenerCapacitacionIntro,ActualizarCapaIntro} from "../../../service/CapacitacionService";
import ToastEva from "../../../components/Toast/ToastEva";
import TextareaDefault from "../../../components/TextArea/TextArea";
import InputDefault from "../../../components/InputDefault/InputDefault";
import Boton from "../../../components/Boton/Boton";
const EditarIntro = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);
    const [capacitacionIntro, setCapacitacionIntro] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();

    useEffect(()=>{
        const obtenerListaSeccionCapa = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarSeccionCapacitacion({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccion);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccion){
            obtenerListaSeccionCapa();
        } 
    },[idSeccion])

    useEffect(()=>{
        const GetCapacitacionIntro = async()=>{
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerCapacitacionIntro({jwt}).then(data=>{
                setCapacitacionIntro(data)
            })
        }
        if(!capacitacionIntro)GetCapacitacionIntro();

    },[])

    const Actualizar =({jsonCapacitacion})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarCapaIntro({jsonCapacitacion,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }

    const schema = Yup.object().shape({
        descripcion: Yup.string().required("Campo descripción requerido."),
        numeroColaboradoresCapacitados: Yup.string().required("Campo Número de colaboradores capacitados requerido."),
        descripcionCC: Yup.string().required("Campo descripción requerido."),
        numeroCapacitaciones: Yup.string().required("Campo Número de capacitaciones requerido."),
        descripcionCA: Yup.string().required("Campo descripción requerido."),
        horasCapacitacion: Yup.string().required("Campo  Horas de capacitación requerido."),
        descripcionHC: Yup.string().required("Campo descripción requerido."),
        porcentajeParticipacion: Yup.string().required("Campo Porcentaje de participación requerido."),
        descripcionPP: Yup.string().required("Campo descripción requerido."),        

    });

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:capacitacionIntro?capacitacionIntro.id:0,
            descripcion: capacitacionIntro?capacitacionIntro.descripcion:"",
            numeroColaboradoresCapacitados:capacitacionIntro?capacitacionIntro.numeroColaboradoresCapacitados:"",
            descripcionCC:capacitacionIntro?capacitacionIntro.descripcionCC:"",
            numeroCapacitaciones:capacitacionIntro?capacitacionIntro.numeroCapacitaciones:"",
            descripcionCA :capacitacionIntro?capacitacionIntro.descripcionCA:"",
            horasCapacitacion :capacitacionIntro?capacitacionIntro.horasCapacitacion:"",
            descripcionHC  :capacitacionIntro?capacitacionIntro.descripcionHC:"",
            porcentajeParticipacion :capacitacionIntro?capacitacionIntro.porcentajeParticipacion:"",
            descripcionPP :capacitacionIntro?capacitacionIntro.descripcionPP:""
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id;
        let descripcion = values.descripcion
        let numeroColaboradoresCapacitados = values.numeroColaboradoresCapacitados
        let descripcionCC  = values.descripcionCC
        let numeroCapacitaciones =values.numeroCapacitaciones
        let descripcionCA  = values.descripcionCA
        let horasCapacitacion  = values.horasCapacitacion
        let descripcionHC  = values.descripcionHC
        let porcentajeParticipacion  = values.porcentajeParticipacion
        let descripcionPP = values.descripcionPP
        
        let jsonCapacitacion = JSON.stringify({id,descripcion,numeroColaboradoresCapacitados,descripcionCC,numeroCapacitaciones,descripcionCA,
        horasCapacitacion,descripcionHC,porcentajeParticipacion,descripcionPP },null,2)
         console.log(jsonCapacitacion)
        // //alert(jsonTema);
        Actualizar({jsonCapacitacion})
        
      },
    });
    return (
        <div className="eva-dashboard-editarCapacitacionIntro" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{"Editar " + tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12">
                            <label htmlFor="descripcion" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <TextareaDefault
                                id="descripcion"
                                minHeight={180}
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.touched.descripcion != undefined && (formik.errors.descripcion ? "error" : "success")}
                                value={formik.values.descripcion}
                                icon={<Iconsax.DocumentText1 />}
                                name="descripcion"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcion && formik.touched.descripcion && formik.errors.descripcion}</p>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="numeroColaboradoresCapacitados" className="p-eva-labelForm">
                                Número de colaboradores capacitados
                            </label>
                            <InputDefault
                                id="numeroColaboradoresCapacitados"
                                name="numeroColaboradoresCapacitados"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.numeroColaboradoresCapacitados != undefined && (formik.errors.numeroColaboradoresCapacitados ? "error" : "success")}
                                value={formik.values.numeroColaboradoresCapacitados}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.numeroColaboradoresCapacitados && formik.touched.numeroColaboradoresCapacitados && formik.errors.numeroColaboradoresCapacitados}</p>
                        </div>
                        <div className="field col-12 md:col-8">
                            <label htmlFor="descripcionCC" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <InputDefault
                                id="descripcionCC"
                                name="descripcionCC"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.descripcionCC != undefined && (formik.errors.descripcionCC ? "error" : "success")}
                                value={formik.values.descripcionCC}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcionCC && formik.touched.descripcionCC && formik.errors.descripcionCC}</p>
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="numeroCapacitaciones" className="p-eva-labelForm">
                                Número de capacitaciones
                            </label>
                            <InputDefault
                                id="numeroCapacitaciones"
                                name="numeroCapacitaciones"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.numeroCapacitaciones != undefined && (formik.errors.numeroCapacitaciones ? "error" : "success")}
                                value={formik.values.numeroCapacitaciones}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.numeroCapacitaciones && formik.touched.numeroCapacitaciones && formik.errors.numeroCapacitaciones}</p>
                        </div>
                        <div className="field col-12 md:col-8">
                            <label htmlFor="descripcionCA" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <InputDefault
                                id="descripcionCA"
                                name="descripcionCA"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.descripcionCA != undefined && (formik.errors.descripcionCA ? "error" : "success")}
                                value={formik.values.descripcionCA}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcionCA && formik.touched.descripcionCA && formik.errors.descripcionCA}</p>
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="horasCapacitacion" className="p-eva-labelForm">
                                Horas de capacitación
                            </label>
                            <InputDefault
                                id="horasCapacitacion"
                                name="horasCapacitacion"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.horasCapacitacion != undefined && (formik.errors.horasCapacitacion ? "error" : "success")}
                                value={formik.values.horasCapacitacion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.horasCapacitacion && formik.touched.horasCapacitacion && formik.errors.horasCapacitacion}</p>
                        </div>
                        <div className="field col-12 md:col-8">
                            <label htmlFor="descripcionHC" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <InputDefault
                                id="descripcionHC"
                                name="descripcionHC"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.descripcionHC != undefined && (formik.errors.descripcionHC ? "error" : "success")}
                                value={formik.values.descripcionHC}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcionHC && formik.touched.descripcionHC && formik.errors.descripcionHC}</p>
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="porcentajeParticipacion" className="p-eva-labelForm">
                                Porcentaje de participación
                            </label>
                            <InputDefault
                                id="porcentajeParticipacion"
                                name="porcentajeParticipacion"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.porcentajeParticipacion != undefined && (formik.errors.porcentajeParticipacion ? "error" : "success")}
                                value={formik.values.porcentajeParticipacion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.porcentajeParticipacion && formik.touched.porcentajeParticipacion && formik.errors.porcentajeParticipacion}</p>
                        </div>
                        <div className="field col-12 md:col-8">
                            <label htmlFor="descripcionPP" className="p-eva-labelForm">
                                Añade una descripción
                            </label>
                            <InputDefault
                                id="descripcionPP"
                                name="descripcionPP"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.descripcionPP != undefined && (formik.errors.descripcionPP ? "error" : "success")}
                                value={formik.values.descripcionPP}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.descripcionPP && formik.touched.descripcionPP && formik.errors.descripcionPP}</p>
                        </div>
                    </div>
                    <div className="p-eva-footer-form">
                        <Boton
                            nombre={"Cancelar"}
                            color="secondary"
                            widths="184px"
                            metodoClick={()=>navigate(-1)}
                        ></Boton>
                        <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                    </div>
                </form>
            </div>
        </div>
    );
}
 
export default EditarIntro;