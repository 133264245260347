import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
const ENDPOINT = constantes.URLAPI;
const ENDPOINTEST = constantes.URL__USUARIOTEST;

export const CreateUser= ({jsonUsuario,jwt}) =>{

    return fetch(`${ENDPOINT}/Usuario/Registrar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonUsuario
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

// export  const  ListUsersGerencia= () =>{
//     //return fetch(`${ENDPOINT}/Usuario/Registrar`,{
//     return fetch("assets/demo/data/users-gerencia.json",{
//         method: "GET",
        
//     }).then(res=>{
//         if(!res.ok) throw new Error("Response is Not Ok")
//         return res.json()
//     }).then(res=>{
//         const {data} = res
//         return data
//     })
// }

export  const  ListUsersGerencia= async (jwt) =>{
    return await fetch(`${ENDPOINT}/Usuario/UsuarioPorGerenciav2/ `,{
    //return fetch("assets/demo/data/users-gerencia.json",{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export  const  ListUsers= async({jwt}) =>{
    return await fetch(`${ENDPOINT}/Usuario/Listar`,{
    //return fetch("assets/demo/data/users-gerencia.json",{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export  const  ListUsersTag= async(jwt) =>{
    return await fetch(`${ENDPOINT}/Usuario/ListarTag`,{
    //return fetch("assets/demo/data/users-gerencia.json",{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        }
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data.sort((a, b) => {if (a.label < b.label) {return -1; }})
    })
}

export const UpdateUser= ({jsonUsuario,jwt}) =>{

    return fetch(`${ENDPOINT}/Usuario/Actualizar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonUsuario
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const DeleteUser= ({id,jwt}) =>{

    return fetch(`${ENDPOINT}/Usuario/Eliminar/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export  const  UsersGerencia=  async({id,jwt}) =>{
    return await fetch(`${ENDPOINT}/Usuario/UsuariosPorGerencia/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        
        const {data} = res
        return data
    })
}

// export  const  FelicitarUsuario=  async({id,jwt}) =>{
//     return await fetch(`${ENDPOINT}/Usuario/FelicitarUsuario/${id}`,{
//         method: "GET",
//         //mode: "no-cors",
//         headers:{
//             "Authorization":"Bearer "+jwt,
//             //'Content-Type':'application/json'
//             "accept": "accept: text/plain"
//         },
//     }).then(res=>{
//         //if(!res.ok) throw new Error("Response is Not Ok")
//         if(!res.ok) 
//         {
//             if(res.status == 401)
//             {
//                 window.localStorage.removeItem('jwt')
//                 window.location.reload();
//             }
//             else
//             {
//                 throw new Error("No se recibió respuesta del servidor")
//             }
//         }
//         return res.json()
//     }).then(res=>{
        
//         const {data} = res
//         return data
//     })
// }

export const FelicitarUsuario= ({jsonMensaje,jwt}) =>{
    return fetch(`${ENDPOINT}/Usuario/FelicitarUsuario`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonMensaje
    }).then(res=>{
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export  const  FelicitarUsuarioAniversario=  async({id,jwt}) =>{
    return await fetch(`${ENDPOINT}/Usuario/FelicitarUsuarioAniversario/${id}`,{
    //return await fetch(`${ENDPOINTEST}/FelicitarUsuarioAniversario/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export  const  ObtenerUsuariosCumpleanios=  async({jwt}) =>{
    return await fetch(`${ENDPOINT}/Usuario/ObtenerUsuariosCumpleanios`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        
        const {data} = res
        return data
    })
}

export  const  ObtenerUsuariosAniversario=  async({jwt}) =>{
    return await fetch(`${ENDPOINT}/Usuario/ObtenerUsuariosAniversario`,{
    //return await fetch(`${ENDPOINTEST}/ObtenerUsuariosAniversario`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export  const  ActualizarAlertas=  async({idUsuario,agregar,jwt}) =>{
    return await fetch(`${ENDPOINT}/Usuario/ActualizarAlertas/${idUsuario}/${agregar}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        
        const {data} = res
        return data
    })
}

export const ObtenerUsuarioPorId =async ({jwt,Id}) =>{
    //return await fetch(`${ENDPOINTTEST}/Obtener/${Id}`,{
    return fetch(`${ENDPOINT}/Usuario/Obtener/${Id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export  const  ObtenerReconocimientoUsuario=  async({id,jwt}) =>{
    return await fetch(`${ENDPOINT}/Usuario/ObtenerReconocimientoUsuario/${id}`,{
    //return await fetch(`${ENDPOINTEST}/ObtenerReconocimientoUsuario/${id}`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            "accept": "accept: text/plain"
        },
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}


export const EnviarNotificacionPush =async ({jwt}) =>{
    return await fetch(`${ENDPOINTEST}/EnviarNotificacionPush`,{
   //return fetch(`${ENDPOINT}/Clima/EnviarNotificacionPush`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}