import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import InputDefault from "../../InputDefault/InputDefault";
import TextareaDefault from "../../TextArea/TextArea";
import InputIcon from "../../InputIcon/InputIcon";
import CheckboxEva from "../../CheckBoxEva/CheckboxEva";
import DropdownDefault from "../../DropdownDefault/Dropdown";
import InputPublicacion from "./InputPublicacion";
import UsuariosSugeridos from "./UsuariosSugeridos"
import Boton from "../../Boton/Boton";
import * as Iconsax from "iconsax-react";
import "./CrearPublicacion.scss";
import { useScrapper } from "react-tiny-link";
import { replace, useFormik } from 'formik';
import * as Yup from "yup";
import ModalEva from "../../Modal/ModalEva";
import usePublicacion from "../../../hooks/usePublicacion";
import * as constantes from "../../../constants/constantes.js";
import axios from "axios";
import CalendarDefault from "../../CalendarDefault/CalendarDefault";
import EmojiPicker from "emoji-picker-react";
import { ObtenerNotificacionPorId, Registrar,RegistrarAdjuntos } from "../../../service/PublicacionService";
import { htmlToPlainText } from "../../../helpers/helpers";


import Select, { components } from "react-select";
import { ListGroupsType } from '../../../service/GruposService.js';
import ToastEva from "../../../components/Toast/ToastEva.js"
import {ListUsers} from "../../../service/UsuariosService";

const CrearPublicacion = ({IdGrupo,perfil,nombreGrupo,handleRegister,handleError,isAdmin}) => {
    const navigate = useNavigate();
    const [verDetalleCreacion, setVerDetalleCreacion] = useState(false);
    const [verDetalleBasico, setVerDetalleBasico] = useState(true);
    const [mensajePublicacion, setMensajePublicacion] = useState("");
    const [stateImage, setStateImage] = useState({ file: null, base64URL: "" });
    const [stateImages, setStateImages] = useState([]);

    const [previewMedia, setPreviewMedia] = useState(null);
    const [previewMediaArregloImg, setPreviewMediaArregloImg] = useState([]);

    const [comboDias, setComboDias] = useState([]);
    const [resetImage, setResetImage] = useState(false);
    const [mostrarModalDesacartar, setMostrarModalDesacartar] = useState(false);
    const {isCreated,isUpdate,hasCreateError,errorMessage} = usePublicacion();
    const [contenidoPub, setContenidoPub] = useState('');
    const [nombreCorto, setNombreCorto] = useState('');
    const mesActual = new Date().getMonth()
    const diaActual = new Date().getDate()
    const horaActual = new Date().getHours()
    const [enableFProgramar, setEnableFProgramar] = useState(false);
    const [enableFFijado, setEnableFFijado] = useState(false);
    const [mostrarEmojis, setMostrarEmojis] = useState(false);
    const [enableAdjunto, setEnableAdjunto] = useState(true);
    const [enableLinkVideo, setEnableLinkVideo] = useState(true);
    const [tipoPermisoGrupo, setTipoPermisoGrupo] = useState(null);

    const [grupos, setGrupos] = useState([]);
    const [IdSegmento, setIdSegmento] = useState(null);
    const [publicacionCompletaAdjunto, setpublicacionCompletaAdjunto] = useState([]);
    const [publicacionPersonasEtiquetadas, setPublicacionPersonasEtiquetadas] = useState([])
    const toast = useRef(null);

    const [usuariosSugeridos, setUsuariosSugeridos] = useState([]);

    const buscarUsuarios = async (texto) => {
        let match = '';
        const textoLimpio = texto.textValue.trim();
        const palabras = textoLimpio.split(/\s+/);
        let comienzaConArroba = false;
        palabras.map((elemento, index) => {          
            if (elemento.startsWith('@')) {
              comienzaConArroba = true;
            const palabraSinArroba = elemento.slice(1);
            match = palabraSinArroba.toLowerCase();
            }else{
                comienzaConArroba = false;
            }
          });

        if (comienzaConArroba) {
            let jwt = window.localStorage.getItem("jwt");
            ListUsers({jwt}).then(data =>{
                const datanew = data.filter(usuario => usuario.nombre.toLowerCase().includes(match));
                setUsuariosSugeridos(datanew)
            })
        } else {
            setUsuariosSugeridos([]);
        }
      };
    const handleSelectChange = (event) => {
        setIdSegmento(event.value);
    };

    const sendMessage = async (grupo,idNoti,idGrupo,idPublicacion,ruta,detalle ="",mensaje="") => {
        
        return await axios.post(constantes.URL_NOTIFICACION+"/messageGroup",{
            GroupName: grupo,
            // GroupName: "Administrador General - Plataforma",
            //GroupName: "InLearning",
            ConnectionId:"1",
            Sender:"WEB",
            Text: "mensaje enviado por el sistema web",
            IdNotificacion:idNoti,
            Link:constantes.URLSITE_PUBLICACION+idGrupo+"/Publicaciones/"+idPublicacion,
            RutaImagen:constantes.URLBLOB_PUBLICACIONES+"/"+idPublicacion+"/"+ruta,
            Detalle :htmlToPlainText(detalle),
            Mensaje:mensaje
        }).then(resp=>resp.data)
    }
    useEffect(()=>{
        if(perfil)setNombreCorto(perfil.nombreCorto)

    },[perfil])


    useEffect(()=>{
        setTipoPermisoGrupo("total");
        const ObtenerGroupType= async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListGroupsType(jwt,tipoPermisoGrupo).then((data) => {
                if (data) {
                    const soloGrupos = data.map((item) => item.grupos).flat();
                    setGrupos(soloGrupos);
                }
            });
        }
        if (tipoPermisoGrupo) {
            ObtenerGroupType();
        }
    },[tipoPermisoGrupo])

    const opciones = grupos.map((grupo) => ({
        value: grupo.idGrupo,
        label: grupo.nombreGrupo
    }));

    const opciones2 = [
        {
            value: 121,
            label: "Inlearning Institutos"
        }
    ];
    

    // useEffect(()=>{
    //     if (isCreated || isUpdate) {
    //         // navigate("/Dashboard/Grupos/Publicaciones/"+IdGrupo);
    //         if(nombreGrupo.toLowerCase() === "inlearning institutos")
    //         {
    //             Promise.all([
    //                 sendMessage("Administrador General - Plataforma"),
                    
    //             ]).then(setTimeout(() => {
    //                 formik.setSubmitting(false);
    //                 handleDescartar(formik)
    //                 handleRegister("Su publicación será aprobada por el administrador de la plataforma.")
    //                 handleDescartar(formik)
    //             }, 3000) )
    //         }else{
    //             Promise.all([
    //                 sendMessage("Administrador General - Plataforma"),
    //                 sendMessage(nombreGrupo),
    //             ]).then(setTimeout(() => {
    //                 formik.setSubmitting(false);
    //                 handleRegister("Publicación registrada con éxito.")
    //                 handleDescartar(formik)
    //             }, 3000) )
    //         }
            
            
    //     }
    //     if(hasCreateError && errorMessage) {handleError(errorMessage.message);handleDescartar(formik);formik.setSubmitting(false);}
    // },[isCreated,hasCreateError,errorMessage])
    const ObtenerDetalleNotificacion =async(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje="";
            if(data.tipoNotificacion == "PUBLICACION")
            {
                mensaje =data.nombreUsuarioOrigen+ " Subió una publicación en el grupo "+data.nombreGrupo+ "."
            }
            else if(data.tipoNotificacion == "APROBACION")
            {
                mensaje ="Tiene publicaciones pendiente de aprobación en el grupo "+data.nombreGrupo+ "."
            }
            // let mensaje = (data.tipoNotificacion == "APROBACION" ? "" : data.nombreUsuarioOrigen) + " " + 
            //                 ObtenerMensajeNotificacion(data.tipoNotificacion,data.nombreUsuarioReferencia
            //                             , perfil.idUsuario === data.idUsuarioReferencia ? true : false
            //                             ,data.nombreGrupo)

            sendMessage(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle,mensaje)
        })  
    } 

    const RegistrarPub =({jsonPublicacion})=>{
        let temp = JSON.parse( jsonPublicacion );
        let jwt = window.localStorage.getItem("jwt");
        


        RegistrarAdjuntos({jsonPublicacion,jwt}).then(data=>{
           console.log("Registro publicacion")
            if(nombreGrupo.toLowerCase() === "inlearning institutos")
            {   
                console.log("if")
                Promise.all([
                    ObtenerDetalleNotificacion("Administrador General - Plataforma",
                    data.data.id,data.data.idGrupo,data.data.idTipoTransaccion,data.data.imagen,
                    temp.contenidoPublicacion)
                ]).then(setTimeout(() => {
                    formik.setSubmitting(false);
                    handleDescartar(formik)
                    handleRegister("Su publicación será aprobada por el administrador de la plataforma.")
                    handleDescartar(formik)
                }, 3000) )
            }else{
                console.log("else")

                Promise.all([
                    ObtenerDetalleNotificacion("Administrador General - Plataforma",
                                                data.data.id,
                                                data.data.idGrupo,
                                                data.data.idTipoTransaccion,
                                                data.data.imagen,
                                                temp.contenidoPublicacion),
                    ObtenerDetalleNotificacion(nombreGrupo,
                                            data.data.id,
                                            data.data.idGrupo,
                                            data.data.idTipoTransaccion,
                                            data.data.imagen,
                                            temp.contenidoPublicacion)                   
                ]).then(setTimeout(() => {
                    console.log("Publicación registrada con éxito")
                    formik.setSubmitting(false);
                    handleRegister("Publicación registrada con éxito.")
                    handleDescartar(formik)
                }, 3000) )
            }      
        })
        .catch(errors => {
            handleError(errors.message);handleDescartar(formik);formik.setSubmitting(false);
        })
    }

    const ExtenderPublicacion = () => {
        setVerDetalleBasico(false);
        setVerDetalleCreacion(true);
    };
    const OcultarCreacion =()=>{
        setVerDetalleBasico(true);
        setVerDetalleCreacion(false);
    }
    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            if(formik.values.contenido.length > 0){setMostrarModalDesacartar(true) }else handleDescartar(formik)

        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);
    const header = (
        <div className="p-eva-tituloCard ">
            <label className="header-subtitle">Crear publicación</label>
        </div>
    );
    const detectURLs = (message) => {
        var urlRegex = /((http|ftp|https)\:\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
        return message.match(urlRegex);
    };
  
    const handleChange = (value) => {
        setMensajePublicacion(value);
    };
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
           
            let reader = new FileReader();
            reader.readAsDataURL(file);
            const regex = /data:.*base64,/;
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL.replace(regex, ""));
            };
            console.log(fileInfo);
        });
    };

    const onFileChange = (e) => {
        setResetImage(false)
        const files = e.target.files;
        if ( isAdmin && files.length > 10) {
            toast.current.show({severity:'error', summary: 'Error', detail:"No puedes seleccionar más de 10 imágenes", life: 7000})
            onDeleteFile();
        return;
        }
        else  {
            if(!isAdmin && files.length > 3){
                toast.current.show({severity:'error', summary: 'Error', detail:"No puedes seleccionar más de 3 imágenes", life: 7000})
                onDeleteFile();
                return;
            }
        }
        
        
        let { file } = stateImage;
        file = e.target.files[0];
        //formik.values.linkVideo ='';
        //formik.setFieldValue("nombreFoto",file.name);
        setEnableLinkVideo(false)
        // getBase64(file)
        //     .then((result) => {
        //         file["base64"] = result;
        //         console.log("File Is", file);
        //         setStateImage({
        //             base64URL: result,
        //             file,
        //         });
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

        // setStateImage({
        //     file: e.target.files[0],
        // });

        const promises = [];
        const publicacionCompleta = []; 
        const stateImageAdjuntos =[]
        for (const file of files) {
        const promise = getBase64(file)
            .then((result) => {
            file["base64"] = result;
            publicacionCompleta.push({
                adjuntoBase64: result,
                nombre: file.name.replace(/\s/g, '_')
            });

            setStateImage({
                base64URL: result,
                file,
            }); 
            stateImageAdjuntos.push({
                base64URL: result,
                file,
            });

            })
            .catch((err) => {
            console.log(err);
            });
            

        promises.push(promise);
        }
        Promise.all(promises)
        .then(() => {
            console.log(publicacionCompleta);
        })
        .catch((error) => {
            console.log(error);
        });
        setpublicacionCompletaAdjunto(publicacionCompleta)
        setStateImages(stateImageAdjuntos); 
    };
    const onDeleteFile =(e)=>{
        console.log("DELETE LOCAL")

        const nuevoArreglo = [...previewMediaArregloImg];
        nuevoArreglo.splice(e, 1);
        console.log("DELETE",e)
        setPreviewMediaArregloImg(nuevoArreglo)

        const nuevopublicacionCompletaAdjunto = [...publicacionCompletaAdjunto];
        nuevopublicacionCompletaAdjunto.splice(e, 1);
        console.log("nuevopublicacionCompletaAdjunto",nuevopublicacionCompletaAdjunto)

        setpublicacionCompletaAdjunto(nuevopublicacionCompletaAdjunto)

        //setPreviewMedia(null);
        //setStateImage({file:null,base64URL:""})
        //setResetImage(true)
        //setEnableLinkVideo(true)
        //URL.revokeObjectURL(null)
    }
    const onDeleteFiles =()=>{
        console.log("DELETE")
        setPreviewMediaArregloImg([])
        setResetImage(true)
        setIdSegmento(null)

    }
    const handleDescartar =(formik)=>{
       // publicacionCompleta = []; 
      //  onDeleteFile();
        onDeleteFiles();
        formik.resetForm()
        OcultarCreacion()
        setMostrarModalDesacartar(false)
        setMostrarEmojis(false)
        setUsuariosSugeridos([]);
    }

    useEffect(() => {
        const arregloUrlImg =[]
        for (const img of stateImages) {
            const objectUrlImg = URL.createObjectURL(img.file);
            arregloUrlImg.push(objectUrlImg);
        }
        setPreviewMediaArregloImg(arregloUrlImg)
        
        if (!stateImage.file) {
            setPreviewMedia(null);
            setPreviewMediaArregloImg([])
            formik.setFieldValue("nombreFoto","");
            return;
        }
        const objectUrl = URL.createObjectURL(stateImage.file);
        setPreviewMedia(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [stateImage]);

    useEffect(() => {
        for (const img of stateImages) {

        
            // stateImageAdjuntos.push({
            //     base64URL: result,
            //     file,
            // });
        }
        
    }, [stateImages]);
   
    // useEffect(() => {
    //     const objectsUrl = []
    //     console.log("USEEFECTpublicacionCompletaAdjunto",stateImages)
    //     // stateImages.map((imagen) => {
    //     //     console.log("file",imagen)
           
    //     // })
    //     stateImages.forEach((imagen) => {
    //         console.log("imagen", imagen);
    //       });
         
    //     // for (const file of stateImages) {
    //     //     console.log("file",file)
    //     //     if (!file.file) {
    //     //         setPreviewMedia(null);
    //     //         formik.setFieldValue("nombreFoto","");
    //     //         return;
    //     //     }
    //     //     const objectUrl = URL.createObjectURL(file.file);
    //     //     console.log("objectUrl",objectUrl)
    //     //     objectsUrl.push(objectUrl);
    //     //     setPreviewMediaArreglo(objectsUrl);
    //     //     console.log("ARREGLO",Arreglo)

    //     //     return () => URL.revokeObjectURL(objectUrl);
    //     // }



        
    // }, [stateImages]);
 
                
    const comboMes= [{code: "Enero",value:1},
                        {code: "Febrero",value:2},
                        {code: "Marzo",value:3},
                        {code: "Abril",value:4},
                        {code: "Mayo",value:5},
                        {code: "Junio",value:6},
                        {code: "Julio",value:7},
                        {code: "Agosto",value:8},
                        {code: "Setiembre",value:9},
                        {code: "Octubre",value:10},
                        {code: "Noviembre",value:11},
                        {code: "Diciembre",value:12}
                    ]
    const comboHoras=[{code: "0",value:0},
                    {code: "1",value:1},
                    {code: "2",value:2},
                    {code: "3",value:3},
                    {code: "4",value:4},
                    {code: "5",value:5},
                    {code: "6",value:6},
                    {code: "7",value:7},
                    {code: "8",value:8},
                    {code: "9",value:9},
                    {code: "10",value:10},
                    {code: "11",value:11},
                    {code: "12",value:12},
                    {code: "13",value:13},
                    {code: "14",value:14},
                    {code: "15",value:15},
                    {code: "16",value:16},
                    {code: "17",value:17},
                    {code: "18",value:18},
                    {code: "19",value:19},
                    {code: "20",value:20},
                    {code: "21",value:21},
                    {code: "22",value:22},
                    {code: "23",value:23},]
           
    const formik = useFormik({
          initialValues: {
          contenido:'',
          link: '',
          nombreLink: '',
          programada:false,
          fechaProgramada: '',
        
          fijado: false,
          fechaFijado : '',
          linkVideo:'',
          nombreFoto : "",
          enviar : ""
        },
        validationSchema: Yup.object({
            //contenido: Yup.string().required("Mensaje de publicación requerido."),
            nombreFoto:Yup.string(),
            contenido: Yup.string().nullable(),
            /* enviar :Yup.string().when(['nombreFoto','contenido'],(nombreFoto,contenido)=>{
                if(!nombreFoto && !contenido)
                    return Yup.string().required('Mensaje o imagen de publicación requerido.')  
            }),*/

            fijado: Yup.boolean(),
            fechaFijado: Yup.string().when('fijado',(fijado)=>{
                if(fijado)
                    return Yup.string().required('Fecha fijada requerida')  
            }),
            programada: Yup.boolean(),
            fechaProgramada: Yup.string().when('programada',(programada)=>{
                if(programada)
                    return Yup.string().required('Fecha programación requerida')  
            })
          }),
        onSubmit: values => {
            console.log("contenidoPublicacion",values)
             let id = 0;
             let idGrupo = IdGrupo ? IdGrupo : IdSegmento;
            // let idGrupo = IdGrupo 
             let idPublicacionCompartida = null;
             let contenidoPublicacion = values.contenido;
            //  let contenidoPublicacion = contenidoPub
             let fijada = values.fijado;
             let fechaFijado = values.fijado ?new Date(values.fechaFijado).toLocaleString("en-US"):null;
             let programada = values.programada;
             let fechaProgramada = programada ? new Date(values.fechaProgramada).toLocaleString("en-US"):null;
            //  let mesFijado = values.mes == '' ? null : values.mes;
            //  let diaFijado = values.dia == '' ? null:values.dia;
            //  let horaFijado = values.hora == '' ? null:values.hora;
             let link = values.link;
             let nombreLink = values.nombreLink;
             let rutaImagenVideo = stateImage.file === null ? "":stateImage.file.name;
             let adjuntoBase64 = stateImage.file === null?"": stateImage.base64URL;
             let publicacionCompletoAdjuntos = publicacionCompletaAdjunto;
             console.log("FILE",stateImage)

             console.log("publicacionCompletoAdjuntos",publicacionCompletaAdjunto)
             let rutaVideo = values.linkVideo;
             let jsonPublicacion = JSON.stringify({ id,idGrupo, idPublicacionCompartida, contenidoPublicacion, fijada, fechaFijado,programada,fechaProgramada,link,
                nombreLink,rutaImagenVideo,adjuntoBase64,publicacionCompletoAdjuntos,rutaVideo,publicacionPersonasEtiquetadas});
                console.log("jsonPublicacion",JSON.parse(jsonPublicacion))
                RegistrarPub({jsonPublicacion})
            
            //alert(jsonPublicacion);
          
        },
      });

      const handleChangeMonth =(e)=>
      {
        //   const date = new Date();
        //   const currentYear = date.getFullYear();
        //   console.log(new Date(currentYear, e.value, 0).getDate());
        //   formik.handleChange(e)
        //   return new Date(currentYear,  e.value, 0).getDate();
        const mes= e.value -1
        formik.handleChange(e)
        const dateNow = new Date();
        const currentYear = dateNow.getFullYear();
        var date = new Date(currentYear, mes, 1);
        var days = [];
        while (date.getMonth() === mes) {
            const actual =new Date(date)
            days.push({value:actual.getDate()});
            date.setDate(date.getDate() + 1);
        }
        setComboDias(days)
      }

      const footerModalDescartar = <>
            <Boton nombre={"Cancelar"} color="secondary" widths="40%" 
                metodoClick={()=>setMostrarModalDesacartar(false)}
            ></Boton>
            <Boton nombre={"Descartar publicación"} color="primary" widths="50%" 
                metodoClick={()=> handleDescartar(formik)}
            ></Boton>
        </>
        
        ;
    
    const CambiaFechaProgramacion = (valor) =>{
        if(valor) {setEnableFProgramar(false);formik.values.fechaProgramada = '';}
        else{ setEnableFProgramar(true);}
    }
    
    const CambiaFechaFijado = (valor) =>{
        if(valor) {setEnableFFijado(false); formik.values.fechaFijado=''}
        else{ setEnableFFijado(true); }
    }
    const handleEmoji =e=>{
        console.log(e)
        formik.setFieldValue("contenido",[formik.values.contenido.slice(0, formik.values.contenido.length), e.emoji, formik.values.contenido.slice(formik.values.contenido.length)].join(''));
        
    }
    const handleLinkVideo=(e)=>{
        if(e.target.value.length > 0){
            setEnableAdjunto(false);
            onDeleteFile();
        }
        else{setEnableAdjunto(true)}
    }
    const handleInputChange = (event) => {
        console.log("Enevent",event)
        const inputValue = event.htmlValue;
        const nuevoTexto = resaltarHashtags(inputValue);
        formik.setFieldValue("contenido",nuevoTexto)
      };
    
    
    //   const resaltarHashtags = (texto) => {
    //     const palabras = texto.split(' ');
    //     const nuevoTexto = palabras.map((palabra) => {
    //       if (palabra.startsWith('#') ||palabra.startsWith('<p>#')) {
    //         return `<span style="color: rgb(29, 155, 240);">${palabra} </span>`;
    //       }
    //       return `${palabra} `;
    //     });
    //     return nuevoTexto.join('');
    //   };
      const resaltarHashtags = (html) => {
        // const hashtagsRegex = /#[A-Za-z0-9áéíóúÁÉÍÓÚ]+/g;
        // const nuevoHtml = html.replace(hashtagsRegex, (match) => {
        //   return `<span style="color: rgb(29, 155, 240)">${match}</span>`;
        // });
        // return nuevoHtml;
        if (html?.length >0) {
            const hashtagsRegex = /#[A-Za-z0-9áéíóúÁÉÍÓÚ]+/g;
            const nuevoHtml = html.replace(hashtagsRegex, (match) => {
              return `<span style="color: rgb(29, 155, 240)">${match}</span>`;
            });
            return nuevoHtml;
        } else{
            return html
        }
      };

      const recibirDatosUsuariosEtiquetados = (datos) => {
        var PublicacionPersonasEtiquetadasArray = publicacionPersonasEtiquetadas;
        console.log("PublicacionPersonasEtiquetadasAntes",PublicacionPersonasEtiquetadasArray);
        var PublicacionPersonasEtiquetadasRow = {
            "idUsuario": datos.value,
            "nombre": datos.label
        }
        PublicacionPersonasEtiquetadasArray.push(PublicacionPersonasEtiquetadasRow);
        console.log("PublicacionPersonasEtiquetadasRow",PublicacionPersonasEtiquetadasRow);
        console.log("PublicacionPersonasEtiquetadasDespues",PublicacionPersonasEtiquetadasArray);
        setPublicacionPersonasEtiquetadas(PublicacionPersonasEtiquetadasArray);
      }

    return (
        <>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <Card header={header} className="p-eva-crearPublicacion"  >
            {verDetalleBasico && (
                <div className="p-eva-crearPublicacion-basico" style={{ width: "100%" }}>
                    <Avatar shape="circle" image={perfil&& perfil.foto? constantes.URLBLOB_ADJ_AVATAR+"/"+perfil.idUsuario+"/"+perfil.foto : constantes.URLAVATARDEFAULT} 
                        size={"large"} style={{ marginRight: 10 ,minWidth:42}} />
                    <InputDefault placeholder={"¿Qué quieres compartir, "+nombreCorto+"?"} className="grey" onclick={ExtenderPublicacion}></InputDefault>
                </div>
            )}
            {verDetalleCreacion && (
                <form id="form-crearPublicacion" onSubmit={formik.handleSubmit}>
                    <div>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12 eva-inputPubliacionContainer">
                                <div style={{display:"flex",alignItems:"center",position:"relative"}}>
                                    <InputPublicacion placeholder={"¿Qué quieres compartir, "+nombreCorto+"?"} 
                                        id="contenido"
                                        name="contenido"
                                        onChange={(e)=>(handleInputChange(e),buscarUsuarios(e))}
                                        onblur= {formik.handleBlur}
                                        value= {formik.values.contenido}
                                        srcMedia={previewMedia}
                                        srcMediaArreglo={previewMediaArregloImg}
                                        widthRight={0}
                                        clicDeleteImg={onDeleteFile}
                                    ></InputPublicacion>
                                  
                                    {usuariosSugeridos.length > 0 && (
                                        <UsuariosSugeridos 
                                        usuarios={usuariosSugeridos} 
                                        recibirDatosUsuariosEtiquetados = {recibirDatosUsuariosEtiquetados}
                                        formikProps={formik} 
                                        
                                        />
                                    )}

                                    
                                    <Iconsax.EmojiHappy className="eva-button-emojis" onClick={()=>{mostrarEmojis ? setMostrarEmojis(false) :setMostrarEmojis(true)}}></Iconsax.EmojiHappy>
                                </div>
                                {mostrarEmojis&& <div className="eva-EmojisIcons" onMouseLeave={()=>setMostrarEmojis(false)} onFocus={()=>setMostrarEmojis(true)}><EmojiPicker emojiStyle="google" onEmojiClick={handleEmoji}></EmojiPicker></div>} 

                               
                            </div>
                            <div className="field col-16 md:col-6">
                                {/* <InputIcon placeholder={"Adjunta aqui"} className={"grey"} icon={<Iconsax.Gallery />} 
                                modo={stateImage.file && "delete"} name="imageGrupo" type="File" 
                                onChange={onFileChange}
                                OnClickDelete={onDeleteFiles}
                                valueMultiple={previewMediaArregloImg}
                                reset={resetImage}
                                disabled={!enableAdjunto}
                                accept="image/jpeg,image/png"
                                Imultiple = {true}
                                ></InputIcon> */}

                            <InputIcon placeholder={"Adjunta aqui"} className={"grey"} icon={<Iconsax.Gallery />}
                                modo={stateImage.file && "delete"} name="imageGrupo" type="File"
                                onChange={onFileChange}
                                OnClickDelete={onDeleteFiles}
                                valueMultiple={previewMediaArregloImg}
                                reset={resetImage}
                                disabled={!enableAdjunto}
                                accept="image/jpeg,image/png"
                                Imultiple = {true}
                                id="multiple"
                                ></InputIcon>
                            </div>
                            {/* <div className="field col-12 md:col-6">
                                <InputIcon placeholder={"Adjunta aqui"} className={"grey"} icon={<Iconsax.Gallery />} 
                                modo={stateImages.file && "delete"} name="imageGrupo" type="File" 
                                onChange={onFileChange}
                                OnClickDelete={onDeleteFile}
                                reset={resetImage}
                                disabled={!enableAdjunto}
                                accept="image/jpeg"
                                Imultiple = {true}
                                ></InputIcon>
                            </div> */}

                            <div className="field col-12 md:col-6">
                                <InputDefault placeholder={"Insertar link de video"} className={"grey"}
                                    id="linkVideo"
                                    name="linkVideo"
                                    type="text"
                                    onChange={(e)=>(formik.handleChange(e),handleLinkVideo(e))}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.linkVideo}
                                    disabled={!enableLinkVideo}
                                ></InputDefault>
                            </div>
                            <div className="field col-12 md:col-6">
                                <InputDefault placeholder={"Inserta link"} className={"grey"}
                                    id="link"
                                    name="link"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.link}
                                ></InputDefault>
                            </div>
                            <div className="field col-12 md:col-6">
                                <InputDefault placeholder={"Escribe el text del link"} className={"grey"}
                                    id="nombreLink"
                                    name="nombreLink"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.nombreLink}
                                ></InputDefault>
                            </div>
                            {
                                isAdmin &&
                                <>
                                    <div className="field col-12 md:col-4 ">
                                        <CheckboxEva label="Programar publicación:" marginTop={10}
                                            id="programada"
                                            name="programada"
                                            onChange={(e)=>(formik.handleChange(e),CambiaFechaProgramacion(e.value))}
                                        
                                            checked={formik.values.programada}
                                            value= {formik.values.programada}
                                        ></CheckboxEva>
                                    </div>
                                    <div className="field col-12 md:col-8 ">
                                        <CalendarDefault id="fechaProgramada"
                                            name="fechaProgramada" 
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            showTime hourFormat="12"
                                            value ={formik.values.fechaProgramada}
                                            placeholder="Seleccionar fecha"
                                            disabled = {!enableFProgramar}
                                            classesname = {"grey"}
                                            minDate = {new Date()}
                                            dateFormat="dd/mm/yy"
                                            ></CalendarDefault>
                                            <small id="fechaProgramada" className="p-error block">
                                                {formik.errors.fechaProgramada && formik.touched.fechaProgramada && formik.errors.fechaProgramada}
                                            </small>
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <CheckboxEva label="Fijar publicación hasta:" marginTop={10}
                                            id="fijado"
                                            name="fijado"
                                            onChange={(e)=>(formik.handleChange(e),CambiaFechaFijado(e.value))}
                                            checked={formik.values.fijado}
                                            value= {formik.values.fijado}
                                        ></CheckboxEva>
                                    </div>
                                    <div className="field col-12 md:col-8">
                                        <CalendarDefault id="fechaFijado"
                                            name="fechaFijado" 
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            showTime hourFormat="12"
                                            value ={formik.values.fechaFijado}
                                            classesname = {"grey"}
                                            placeholder="Seleccionar fecha"
                                            disabled = {!enableFFijado}
                                            minDate = {new Date()}
                                            dateFormat="dd/mm/yy"
                                            ></CalendarDefault>
                                        <small id="fechaFijado" className="p-error block">
                                            {formik.errors.fechaFijado && formik.touched.fechaFijado && formik.errors.fechaFijado}
                                        </small>
                                    </div>
                                </>
                            }
                            {(!IdGrupo && isAdmin )?
                                <div style={{ width: '100%' }}>
                                    <Select
                                        className="p-eva-searchCard"
                                        id="segmento"
                                        name="segmento"
                                        placeholder={'Seleccione el grupo'}
                                        options={opciones}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                                : <div style={{ width: '100%' }}>
                                        <Select
                                            className="p-eva-searchCard"
                                            id="segmento"
                                            name="segmento"
                                            placeholder={'Seleccione el grupo'}
                                            options={opciones2}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                           }
                            <div className="p-eva-crearPublicaion-Publicar">
                                <Boton nombre={"Descartar publicación"} color="secondary" widths="20%" 
                                     metodoClick={()=>setMostrarModalDesacartar(true)}
                                  ///  metodoClick={()=>formik.values.contenido.length>0 ? setMostrarModalDesacartar(true):handleDescartar(formik)}
                                ></Boton>


                               <Boton nombre={"Publicar"} color="primary" widths="20%" type="submit"
                                    disabled={!(formik.isValid && formik.dirty)  || IdSegmento === null}
                                    loading={formik.isSubmitting}
                                ></Boton>
                               
                            </div>
                        </div>
                    </div>
                </form>
                
            )}
        </Card>
        {mostrarModalDesacartar && <ModalEva mostrar={mostrarModalDesacartar} 
            titulo={"¿Quieres descartar la publicación?"} footer={footerModalDescartar}
            widths="29vw" posicionTitulo="center" onHide={()=>setMostrarModalDesacartar(false)}
            ></ModalEva>}
        </>
        
    );
};

export default CrearPublicacion;
