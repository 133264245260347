import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import CardEntry from "../../components/CardEntryEva/CardEntry";
import SearchCard from "../../components/Directorio/SearchCard";
import TextSearchCard from "../../components/TextSearchCardEva/TextSearchCard";

import * as Iconsax from "iconsax-react";
import useUsuario from "../../hooks/useUsuario";
import { ListarTemasSeccion} from "../../service/LiderInService";
import Sk_LiderIn from "../../components/Skeleton/Sk_LiderIn";
import "../../constants/constantes";
import "./LiderIn.scss"
import Boton from "../../components/Boton/Boton";

const LiderIn = () => {
    const navigate = useNavigate();
    const {permisos,isLogged,perfil}= useUsuario();
    const [listaTemas, setListaTemas] = useState(null);
    const [listaTemasTemp, setListaTemasTemp] = useState([]);
    const [permisoEditar, setPermisoEditar] = useState(false);


    let { idSeccion } = useParams();

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const obtenerListaTemas = async ()=>
        {
            let id= idSeccion;
            let jwt = window.localStorage.getItem("jwt");
            await ListarTemasSeccion({id,jwt}).then(data => 
                {
                    setListaTemas(data);
                    setListaTemasTemp(data);
                })
        }
        if(idSeccion){
            obtenerListaTemas();
        } 
    },[idSeccion])
    // useEffect(()=>{
    //     const AccionPermisos = async () => {
    //         if (permisos.indexOf("editarLiderIn") > -1) {
    //             setPermisoEditar(true);
    //         }
    //     };
    //     if(permisos.length >0)
    //     {
    //         permisos.indexOf("verLiderIn") > -1 ? AccionPermisos() : navigate("/")
    //     }

    // },[permisos])

    useEffect(()=>{
        if(perfil)
        {
            if(perfil.esJefe)
            {
                setPermisoEditar(true);
            }
        }
    },[perfil])

    const hadleButtonCard =(idTema)=>{
        navigate("Detalle/"+idTema);
    }

    const BuscarTemas =(e)=>{
       //let listaGruposTotal = [...listaTemas];
        let listaFiltrada =[];
        listaFiltrada = listaTemasTemp.filter(x=>x.nombreTema.toLowerCase().includes(e.target.value.toLowerCase()));
        if(listaFiltrada.length == 0)
        {
            setListaTemas([])
        }
        else{
            setListaTemas(listaFiltrada)
        }
        if(e.target.value.length == 0) setListaTemas(listaTemasTemp)

        // listaTemasTemp.map((item,i)=>{
        //      let temp = item.listaTemas.filter(x=>x.nombreTema.toLowerCase().includes(e.target.value.toLowerCase()));
           
        //     if(temp.length >0) 
        //     {
        //         listaFiltrada.push(item);
        //         listaFiltrada.listaTemas=temp;
        //         setListaTemas(listaFiltrada);
        //     }
        // })
        // if(listaFiltrada.length == 0)
        // {
        //     setListaTemas([])
        // }
        // if(e.target.value.length == 0) setListaTemas(listaTemasTemp)
        
    }
    return ( 
        <div className="eva-dashboard-LiderIn" >
            <div className="eva-dashboard-header header-agrupado">
                <div className="header">
                    <label htmlFor="header">Lider IN</label>
                </div>
                {
                    permisoEditar && 
                    <div className="header-right">
                        <Link to={"Crear"}>Añadir nuevo tema</Link>
                    </div>
                }
                
            </div>
            <div className="eva-dashboard-liderIn-body" style={{marginTop:32}}>
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-9">
                        <TextSearchCard placeholder = "Buscar" icon={<Iconsax.SearchNormal1 color='#F47647'/>}
                                       className ="type-search"onChange={BuscarTemas} ></TextSearchCard>
                    </div>
                </div>
                <div className="p-fluid formgrid grid">
                    {   
                        listaTemas ?
                            (listaTemas.length > 0 ?
                                listaTemas.map((item,i)=>{
                                    return (
                                        
                                            <div className="col-12 md:col-3" key={i}>
                                                <CardEntry tituloCard={item.nombreTema} 
                                                    nombreBoton="Ver más" 
                                                    imagenUrl={"../../../images/inicio/ondas.png"} 
                                                    opacity={1} onClicButton={() => hadleButtonCard(item.id)} />
                                            </div>
                                       
                                    );
                                    
                                }):"No existe data")
                        : 
                        <Sk_LiderIn/>
                    }
                </div>
            </div>
            
        </div>
        );
}
 
export default LiderIn;