import React, { useState, useEffect,forwardRef} from "react";
import { Toast } from "primereact/toast";
import "./ToastEva.scss"
const ToastEva = forwardRef((props,ref) =>{
    return ( 
    
            <Toast ref={ref}{...props} className="p-eva-toastEva"></Toast>
        
     );
})
 
export default ToastEva;