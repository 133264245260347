import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Reglamento.scss";
import { ObtenerGerenciasReglamento, ListarReglamentos } from "../../../service/SomosinService";
import * as Iconsax from "iconsax-react";
import CardEntry from "../../../components/CardEntryEva/CardEntry";
import CardGerencia from "../../../components/Gerencia/CardGerencia";
import CardReglamento from "../../../components/Reglamento/CardReglamento";
import TextSearchCard from "../../../components/TextSearchCardEva/TextSearchCard";
const ReglamentoGerencia = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Políticas y reglamentos");
    const [gerencias, setGerencias] = useState(null);
    const [gerenciasTemp, setGerenciasTemp] = useState(null);

    const [listaReglamentos, setListaReglamentos] = useState(null);
    const [listaReglamentosTemp, setListaReglamentosTemp] = useState(null);
    useEffect(() => {
        const getGerencias = async () => {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerGerenciasReglamento({ jwt }).then((data) => {
                setGerencias(data);
                setGerenciasTemp(data);
            });
        };
        if (!gerencias) getGerencias();
    }, []);

    useEffect(() => {
        const getListaReg = async () => {
            let jwt = window.localStorage.getItem("jwt");
            await ListarReglamentos({ jwt }).then((data) => {
                //setListaReglamentos(data)
                setListaReglamentosTemp(data);
            });
        };
        getListaReg();
    }, []);

    const buscarReglamentos =(e)=>{
        console.log("entra",e.target.value);
        let listaFiltrada =[];
        listaFiltrada = listaReglamentosTemp.filter(x=>x.nombre.toLowerCase().includes(e.target.value.toLowerCase()));
        if(listaFiltrada.length == 0)
        {
            console.log("no encontro en la lista")
            setListaReglamentos(null)
            setGerencias(gerenciasTemp)
        }
        else{
            console.log("encontró",listaFiltrada)
            setListaReglamentos(listaFiltrada)
            setGerencias(null)
        }
        if(e.target.value.length == 0) 
        {
            console.log("Busqueda vacía")
            setListaReglamentos(null)
            setGerencias(gerenciasTemp)
        }

    }
    return (
        <div className="eva-dashboard-reglamentoGerencia" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <div className="eva-dashboard-header">
                <div>
                    <label>{tituloPagina}</label> 
                </div>
            </div>
            <div className="eva-dashboard-reglamentoGerencia-body">
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12">
                        <TextSearchCard placeholder = "Buscar" icon={<Iconsax.SearchNormal1 color='#F47647'/>}
                                       className ="type-search"onChange={buscarReglamentos} ></TextSearchCard>
                    </div>
                    {gerencias &&
                        gerencias.map((item, i) => {
                            return (
                                <div className="field col-12 md:col-3 sm:col-12" key={i}>
                                    <CardGerencia
                                        NombreGerencia={item.nombreGerencia}
                                        colaboradores={item.cantidadReglamentos + " reglamento" + (item.cantidadReglamentos == 0 || item.cantidadReglamentos > 1 ? "s" : "")}
                                        onClicButton={() => navigate("/Dashboard/SomosIn/Reglamento/" + item.idGerencia)}
                                    ></CardGerencia>
                                </div>
                            );
                        })}
                    {listaReglamentos &&
                        listaReglamentos.map((item, i) => {
                            return (
                                <div className="field col-12 md:col-6 sm:col-12" key={i}>
                                    <CardReglamento
                                        titulo={item.nombre}
                                        contenido={item.descripcion}
                                        idReglamento={item.id}
                                        accept={item.aceptaTermino ? true : false}
                                        // onChangeCheck={e => setChecked(e.checked)}
                                        // checkedAcepta={checked}
                                        urlPdf={item.nombreAdjunto}
                                        displayEdit={"none"}
                                        
                                    ></CardReglamento>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default ReglamentoGerencia;
