
import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../components/TextArea/TextArea";
import Boton from "../../components/Boton/Boton";
import ToastEva from "../../components/Toast/ToastEva";
import InputDefault from "../../components/InputDefault/InputDefault";
import classNames from "classnames";
import { ObtenerUsuarioPorId ,UpdateUser} from "../../service/UsuariosService";
import { ListarGerencias } from "../../service/GerenciaService";
import DropdownDefault from "../../components/DropdownDefault/Dropdown";
import { ReestablecerContrasena } from "../../service/PasswordService";

const EditarUsuario = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();
    const {permisos,isLogged} = useUsuario()
    const [tituloPagina, setTituloPagina] = useState("");
    const [usuario, setUsuario] = useState();    
    const toast = useRef(null);
    let { idUsuario } = useParams();
    const [listGerencias, setListGerencias] = useState(null);
    const [isLoadingPass, setIsLoadingPass] = useState(false);

    useEffect(()=>{
        !isLogged && navigate("/");
    },[])

    useEffect(()=>{
        const getUsuario = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idUsuario
            await ObtenerUsuarioPorId({jwt,Id}).then(data => 
                {
                    setTituloPagina(data.nombre)
                    setUsuario(data)
                })
        }

        if(idUsuario) getUsuario()
    },[idUsuario])

    useEffect(()=>{
        const obtenerGerencias=async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarGerencias(jwt).then(data => setListGerencias(data))
        }

        if(!listGerencias)
        {
            obtenerGerencias()
        }
     },[]);
    const dropdownItemsMarca = [
        { value: 1, code: "Marca 1" },
        { value: 2, code: "Marca 2" },
    ];
    const schema = Yup.object().shape({
        correo: Yup.string().required("Correo es un campo obligatorio").email("Formato de correo incorrecto"),
        nombres: Yup.string().required("Nombres y apellidos es un campo obligatorio"),
        dateBirth: Yup.string().required("Fecha de nacimiento es un campo obligatorio"),
        marca: Yup.string().required("Marca es un campo obligatorio"),
        gerencia: Yup.string().required("Gerencia es un campo obligatorio"),
        puesto: Yup.string().required("Puesto es un campo obligatorio"),
        codigoCol: Yup.string().required("Codigo del colaborador es un campo obligatorio"),
    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            id: usuario&&usuario.id,
            puesto: usuario&&usuario.puesto, 
            nombres: usuario&&usuario.nombre, 
            dateBirth: usuario&&usuario.fechaNacimiento.substring(10,0), 
            marca: usuario&&usuario.idMarca, 
            correo: usuario&&usuario.correo, 
            gerencia: usuario&&usuario.idGerencia, 
            codigoCol: usuario&&usuario.codigoColaborador, 
            
            
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id;
        let nombre = values.nombres;
        let codigoColaborador = values.codigoCol;
        let puesto = values.puesto;
        let fechaNacimiento = values.dateBirth;
        let idLogin = 0;
        let idRol = 0;
        let correo = values.correo;
        let idGerencia = values.gerencia;
        let idMarca = values.marca;
        let foto = "";
        let celular = values.celular;
        let jsonUsuario = JSON.stringify({ id, nombre, codigoColaborador, puesto, fechaNacimiento, idLogin, idRol, correo, idGerencia, foto, celular, idMarca },null,2);
        
        // //  console.log(jsonCapacitacion)
        //  formik.setSubmitting(false)
        //  alert(jsonClima);
         Actualizar({jsonUsuario})
        
        
      },
    });

    const Actualizar =({jsonUsuario})=>{
        let jwt = window.localStorage.getItem("jwt");
        UpdateUser({jsonUsuario,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    const Reestablecer =(idUsuario)=>{
        setIsLoadingPass(true)
        let jwt = window.localStorage.getItem("jwt");
        let id = idUsuario
        ReestablecerContrasena({jwt,id}).then(data=>{
            setIsLoadingPass(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Se reestableció contraseña.", life: 7000})
            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            setIsLoadingPass(false)
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
        })
    }


    return (
        <div className="eva-dashboard-editarUsuario" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4">
                            <label htmlFor="nombre">Nombres</label>
                            <InputDefault
                                id="nombres"
                                name="nombres"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.nombres != undefined && (formik.errors.nombres ? "error" : "success")}
                                value={formik.values.nombres}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled
                            />
                            <small className="p-error">{formik.touched.nombres && formik.errors.nombres}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="dateBirth">Fecha de nacimiento</label>
                            <InputDefault
                                id="dateBirth"
                                name="dateBirth"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.nombres != undefined && (formik.errors.dateBirth ? "error" : "success")}
                                value={formik.values.dateBirth}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled
                            />
                            <small className="p-error">{formik.touched.dateBirth && formik.errors.dateBirth}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor={`marca`}>Marca</label>
                            <DropdownDefault
                                id={`marca`}
                                name={`marca`}
                                options={dropdownItemsMarca}
                                optionLabel="code"
                                optionValue="value"
                                errorClass={formik.touched.marca != undefined && (formik.errors.marca ? "error" : "success")}
                                placeholder="Seleccione"
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <small className="p-error">{formik.touched.marca && formik.errors.marca}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="correo">Correo electrónico</label>
                            <InputDefault
                                id="correo"
                                name="correo"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.correo != undefined && (formik.errors.correo ? "error" : "success")}
                                value={formik.values.correo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                            />
                            <small className="p-error">{formik.touched.correo && formik.errors.correo}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor={`gerencia`}>Gerencia/Area</label>
                            <DropdownDefault
                                id={`gerencia`}
                                name={`gerencia`}
                                options={listGerencias}
                                optionLabel="code"
                                errorClass={formik.touched.gerencia != undefined && (formik.errors.gerencia ? "error" : "success")}
                                placeholder="Seleccione"
                                value={formik.values.gerencia}
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <small className="p-error">{formik.touched.gerencia && formik.errors.gerencia}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="puesto">Puesto</label>
                            <InputDefault
                                id="puesto"
                                name="puesto"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.puesto != undefined && (formik.errors.puesto ? "error" : "success")}
                                value={formik.values.puesto}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                            />
                            <small className="p-error">{formik.touched.puesto && formik.errors.puesto}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="codigoCol">Código del colaborador</label>
                            <InputDefault
                                id="codigoCol"
                                name="codigoCol"
                                className="white"
                                placeholder="Escribe aquí"
                                errorClass={formik.touched.codigoCol != undefined && (formik.errors.codigoCol ? "error" : "success")}
                                value={formik.values.codigoCol}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                            />
                            <small className="p-error">{formik.touched.codigoCol && formik.errors.codigoCol}</small>
                        </div>
                    </div>
                    <div className="p-eva-footer-form">
                            <Boton nombre={"Reestablecer"} color="secondary" type={"button"} widths="184px" metodoClick={()=>Reestablecer(formik.values.id)} loading={isLoadingPass}></Boton>
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting} disabled={!formik.isValid}></Boton>
                        </div>
                </form>
            </div>
        </div>
    );
}
 
export default EditarUsuario;