import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import "./PreviewMedia.scss"
const PreviewMedia = ({urlFile, visibleModal=false,onHide,maxWidth,height=90}) => {
    const [visible, setVisible] = useState(false);
    useEffect(()=>{
        setVisible(visibleModal)
    },[visibleModal])
    return ( 
        <Dialog visible={visible}  onHide ={onHide} className="p-eva-PreviewMedia" 
            style={{height:height+"vh",maxWidth:maxWidth}} >

            <div className="p-eva-PreviewMedia-contenido" style={{height:(height-3)+"vh"}}>
                    <embed style={{borderRadius:16}}
                        width="100%" 
                        height="100%" 
                        src={urlFile}
                        allowfullscreen></embed>
            </div>
            

        </Dialog>
    );
}
 
export default PreviewMedia;