import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import "./CardCumpleanios.scss"
import * as constantes from "../../../constants/constantes.js";
import { FelicitarUsuario,ObtenerUsuariosCumpleanios } from '../../../service/UsuariosService';
import autosize from 'autosize';
import axios from "axios";
import { ObtenerNotificacionPorId } from '../../../service/PublicacionService';
import { Dialog } from "primereact/dialog";
import TextareaDefault from '../../TextArea/TextArea';
import * as Yup from "yup";
import { useFormik} from "formik";
import * as Iconsax from "iconsax-react";
import Boton from '../../Boton/Boton.js';
import { useNavigate } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";


const CardCumpleanios = () => {
    const navigate = useNavigate();
    const [mostrarCardCumpleanios, setMostrarCardCumpleanios] = useState(false);
    const [listaUsuarios, setListaUsuarios] = useState(null);
    const [mostrarDescripcion, setMostrarDescripcion] = useState(false);
    const [nombreUsuario, setNombreUsuario] = useState("");
    const [idUsuario, setIdUsuario] = useState(0);
    const [mostrarEmojis, setMostrarEmojis] = useState(false);

    

    const ObtenerCumpleanios= async()=>
    {
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerUsuariosCumpleanios({jwt}).then((data) => {
            if(data.length > 0)
            {
                setListaUsuarios(data);
                setMostrarCardCumpleanios(true)
            }
            
        });
    }
    useEffect(()=>{
        ObtenerCumpleanios();
    },[])

    const sendMessage = async (idDestinoUsuario,idNoti,mensaje="") => {
        console.log("idDestinoUsuario",idDestinoUsuario)
        return axios.post(constantes.URL_NOTIFICACION+"/messageUser",{
            //GroupName: grupo,
             GroupName: "Administrador General - Plataforma",
            //GroupName: "InLearning",
            ConnectionId:idDestinoUsuario,
            Sender:"WEB222",
            Text: "mensaje enviado por el sistema web",
            Link: constantes.URLSITE_HOME,
            IdNotificacion:idNoti,
            Mensaje:mensaje
        }).then(resp=>resp.data)
    }
    const header = 
    <div className='p-eva-header-cumpleanios' style={{position:"relative",width:"100%"}}>
        <div className='header-cumpleanios-img' style={{position:"absolute",width:"100%"}}>
            <img src='../../../images/inicio/TARJETAS OFICIALES_Header_Cumpleaños.png' width={"100%"} style={{borderTopLeftRadius:16,borderTopRightRadius:16}}></img>
        </div>
        <div  className="header-cumpleanios-titulo" style={{textAlign:"center"}}><label>Hoy te deseamos, ¡Feliz cumpleaños!</label></div>
    </div>
        
        ;

    // const footer = <img src='../../../images/inicio/regalos_x1.png' width={"100%"} style={{borderRadius:16}}></img> 
    // const footer = <img src='../../../images/inicio/eVA_footer cumpleaños.jpg' width={"100%"} style={{borderBottomRightRadius:16,borderBottomLeftRadius:16}}></img> 
    
    const ObtenerDetalleNotificacion =async(idDestino,idNoti)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje = data.nombreUsuarioOrigen+" te felicitó por tu cumpleaños."
        
            sendMessage(idDestino,idNoti,mensaje)
        })  
    } 
    const handleEmoji =e=>{
        console.log(e)
        formik.setFieldValue("Mensaje",[formik.values.Mensaje.slice(0, formik.values.Mensaje.length), e.emoji, formik.values.Mensaje.slice(formik.values.Mensaje.length)].join(''));
        
    }
    const footer =<></>
    const handleFelicitar=(id)=>{
        let jwt = window.localStorage.getItem("jwt");
        FelicitarUsuario({id,jwt}).then((data) => {
            ObtenerCumpleanios();
            sendMessage(id,data.data.id)
            ObtenerDetalleNotificacion(id,data.data.id)
            
        });
    }
    const schema = Yup.object().shape({
        Mensaje: Yup.string().required("Campo mensaje requerido."),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Mensaje: "",
            IdUsuario:0
        },
        validationSchema: schema,
        onSubmit: (values) => {
            let IdUsuario = idUsuario;
            let Mensaje = values.Mensaje;         
            let jsonMensaje = JSON.stringify({IdUsuario,Mensaje}, null, 2);
            console.log(jsonMensaje);
            Felicitar({ jsonMensaje });
        },
    });
    const Felicitar =({jsonMensaje})=>{
        let temp = JSON.parse( jsonMensaje );
        let jwt = window.localStorage.getItem("jwt");

        FelicitarUsuario({jsonMensaje,jwt}).then((data) => {
            ObtenerCumpleanios();
            formik.setSubmitting(false);
            setMostrarDescripcion(false)
            formik.setFieldValue("Mensaje", "");
            // sendMessage(jsonMensaje.IdUsuario,data.data.id)
           // ObtenerDetalleNotificacion(jsonMensaje.IdUsuario,data.data.id)
            
        })
        
        // .catch(errors => {
        //     handleError(errors.message);handleDescartar(formik);formik.setSubmitting(false);
        // })
       
    }
    return ( 
        <>
             {
            mostrarCardCumpleanios &&
            <Card header={header} className="p-eva-cardCumpleanios" footer={footer} style={{overflowY:autosize,marginBottom:20}}>
            {
                listaUsuarios && 
                listaUsuarios.length >0 &&
                listaUsuarios.map((item,i)=>{
                    return(
                        <div className='p-eva-cardCumpleanios-item' style={{marginBottom:8, width:"100%"}} key={i}>
                            <Avatar image = {item.foto ? constantes.URLBLOB_ADJ_AVATAR+"/"+item.id+"/"+item.foto : constantes.URLAVATARDEFAULT}
                                size={"xlarge"} shape="circle" style={{marginRight:8}}/>
                            <div className='item-nombre'style={{width:"59%",display:"flex",justifyContent:"left"}}><span className='text-sm font-normal' ><b>{item.nombre} </b><br></br>{item.nombreGerencia}</span></div>
                            <div className='item-link' style={{width:"22%"}}>
                                <span  > 
                                    {
                                        item.felicitado ?
                                        <a  style={{justifyContent:"end",color:"#B5B5B5"}}
                                        className="p-eva-labelForm-orange"
                                        >Felicitar</a>
                                        :
                                        <a  style={{justifyContent:"end",cursor:"pointer"}}
                                        className="p-eva-labelForm-orange"
                                        onClick={()=>{
                                            //  handleFelicitar(item.id)
                                             setMostrarDescripcion(true)
                                             setNombreUsuario(item.nombre)
                                             setIdUsuario(item.id)


                                        }}
                                        // onClick={()=>handleFelicitar(item.id)}
                                        >Felicitar</a>
                                    }
                                    
                                </span></div>
                        </div>
                    )
                })
            }

            {/* <div className='p-eva-cardCumpleanios-item' style={{marginBottom:8, width:"100%"}}>
                <Avatar image = {"https://grinlearningqa9414.blob.core.windows.net/adjuntos/Avatar/1/foto_kevyn.png"}
                    size={"xlarge"}                  />
                <div className='item-nombre'style={{width:"59%"}}><span className='p-eva-text' style={{paddingLeft:8}}>Juan Perez</span></div>
                <div className='item-link' style={{width:"22%"}}><span  > <a  style={{justifyContent:"end"}}className="p-eva-labelForm-orange" target="_blank" href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies">Felicitar</a></span></div>
            </div>
            <div className='p-eva-cardCumpleanios-item' style={{marginBottom:8, width:"100%"}}>
                <Avatar image = {"https://grinlearningqa9414.blob.core.windows.net/adjuntos/Avatar/1/foto_kevyn.png"}
                    size={"xlarge"}                  />
                <div className='item-nombre'style={{width:"59%"}}><span className='p-eva-text' style={{paddingLeft:8}}>Juan Perez</span></div>
                <div className='item-link' style={{width:"22%"}}><span  > <a  style={{justifyContent:"end"}}className="p-eva-labelForm-orange" target="_blank" href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies">Felicitar</a></span></div>
            </div>
            <div className='p-eva-cardCumpleanios-item' style={{marginBottom:8, width:"100%"}}>
                <Avatar image = {"https://grinlearningqa9414.blob.core.windows.net/adjuntos/Avatar/1/foto_kevyn.png"}
                    size={"xlarge"}                  />
                <div className='item-nombre'style={{width:"59%"}}><span className='p-eva-text' style={{paddingLeft:8}}>Juan Perez</span></div>
                <div className='item-link' style={{width:"22%"}}><span  > <a  style={{justifyContent:"end"}}className="p-eva-labelForm-orange" target="_blank" href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies">Felicitar</a></span></div>
            </div>
            <div className='p-eva-cardCumpleanios-item' style={{marginBottom:8, width:"100%"}}>
                <Avatar image = {"https://grinlearningqa9414.blob.core.windows.net/adjuntos/Avatar/1/foto_kevyn.png"}
                    size={"xlarge"}                  />
                <div className='item-nombre'style={{width:"59%"}}><span className='p-eva-text' style={{paddingLeft:8}}>Juan Perez</span></div>
                <div className='item-link' style={{width:"22%"}}><span  > <a  style={{justifyContent:"end"}}className="p-eva-labelForm-orange" target="_blank" href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies">Felicitar</a></span></div>
            </div>  
             */}
           </Card>
        }

        <Dialog visible={mostrarDescripcion} onHide={() => setMostrarDescripcion(false) } 
            className="p-eva-dialog-message" footer={<></>} >
                <form id="form-crearPublicacion" onSubmit={formik.handleSubmit}>
                    <div>
                        <label htmlFor="Mensaje" className="p-eva-labelForm">
                            Deja tu saludo de cumpleaños para <span style={{ color: '#F47647' }}><strong>{nombreUsuario}</strong></span>
                        </label>
                        <div style={{display:"flex",alignItems:"center",position:"relative"}}>
                            <div style={{ width: "98%",marginRight:"10px" }}>
                                <TextareaDefault
                                    id="Mensaje"
                                    minHeight={110}
                                    placeholder="Escribe aquí"
                                    className="grey" 
                                    value={formik.values.Mensaje}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="Mensaje"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <p className="error">{formik.errors.Mensaje && formik.touched.Mensaje && formik.errors.Mensaje}</p>
                            </div>
                            <Iconsax.EmojiHappy className="eva-button-emojis" onClick={()=>{mostrarEmojis ? setMostrarEmojis(false) :setMostrarEmojis(true)}}></Iconsax.EmojiHappy>
                        </div>
                        {mostrarEmojis&& <div className="eva-EmojisIcons" onMouseLeave={()=>setMostrarEmojis(false)} onFocus={()=>setMostrarEmojis(true)}><EmojiPicker emojiStyle="google" onEmojiClick={handleEmoji}></EmojiPicker></div>} 

                        <div className="p-eva-footer-form d-flex justify-content-center">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => {setMostrarDescripcion(false);formik.setFieldValue("Mensaje", "")}}></Boton>
                            <Boton nombre={"Enviar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </div>
                </form>
        </Dialog>
        
        </>
       
     );
}
 
export default CardCumpleanios;