import { registry } from 'chart.js';
import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import {Registrar,Actualizar,ActualizarAdjuntos,RegistrarComentario,EliminarPublicacion}from "../service/PublicacionService";
export default function usePublicacion(){
    const {perfil,jwt,setPerfil,setJwt} = useContext(Context)
    const [stateCreate,setStateCreate] = useState({loading:false,error:false,success:false});
    const [stateUpdate, setStateUpdate] = useState({loading:false,error:false,success:false});
    const [stateDelete, setStateDelete] = useState({loading:false,error:false,success:false});
    const [errorMensaje, setErrorMensaje] = useState(null);
    const [listaComentarios, setListaComentarios] = useState([]);
    const [publicacion, setPublicacion] = useState(null);
    const RegistrarComentarios = useCallback(({jsonComentario})=>{
        RegistrarComentario({jsonComentario,jwt})
        .then(data => {
            setListaComentarios(data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
        })
    },[setStateCreate])

    const RegistrarPublicacion = useCallback(({jsonPublicacion})=>{
        setStateCreate({loading:false,error:false,success:false})
        Registrar({jsonPublicacion,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            //console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
            setErrorMensaje(err)
           
        })
    },[setStateCreate,setErrorMensaje])

    const ActualizarPublicacion = useCallback(({jsonPublicacion})=>{
        console.log("Api1")
        setStateUpdate({loading:false,error:true,success:false})
        Actualizar({jsonPublicacion,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateUpdate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateUpdate({loading:false,error:true,success:false})
        })
    },[setStateUpdate])

    const ActualizarPublicacionAdjuntos = useCallback(({jsonPublicacion})=>{
        console.log("Api2")
        setStateUpdate({loading:false,error:true,success:false})
        ActualizarAdjuntos({jsonPublicacion,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateUpdate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateUpdate({loading:false,error:true,success:false})
        })
        console.log("Api4")

    },[setStateUpdate])
    

    const Eliminar = useCallback(({IdPublicacion})=>{
        EliminarPublicacion({jwt,IdPublicacion})
        .then(data => {
            console.log("success: "+data);
            setStateDelete({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateDelete({loading:false,error:true,success:false})
        })
    },[setStateDelete])
    return{
        RegistrarPublicacion,
        hasCreateError : stateCreate.error,
        isCreateLoading: stateCreate.loading,
        isCreated : stateCreate.success,
        errorMessage :  errorMensaje,
        ActualizarPublicacion,
        ActualizarPublicacionAdjuntos,
        hasUpdateError : stateUpdate.error,
        isUpdateLoading: stateUpdate.loading,
        isUpdate : stateUpdate.success,
        RegistrarComentarios,
        hasCreateErrorComentario : stateCreate.error,
        isCreateLoadingComentario: stateCreate.loading,
        isCreatedComentario : stateCreate.success,
        listaComentarios,
        Eliminar,
        isDelete: stateDelete.success
    }
}
