import * as constantes from "../constants/constantes.js";
//const ENDPOINT = "https://inleraningapi.azurewebsites.net";
 const ENDPOINT = constantes.URLAPI_MAESTROS;
const ENDPOINTEST = constantes.URL_INTERACCIONTEST;


export const Registrar= ({jsonInteraccion,jwt}) =>{
    //return fetch(`${ENDPOINT}/Registrar`,{
    return fetch(`${ENDPOINT}/Interaccion/Registrar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonInteraccion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const Actualizar= ({jsonInteraccion,jwt}) =>{
    //return fetch(`${ENDPOINT}/Actualizar`,{
     return fetch(`${ENDPOINT}/Interaccion/Actualizar`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonInteraccion
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const ListarInteraccion =async ({jwt}) =>{
    //return await fetch(`${ENDPOINT}/ListarTipoInteraccion`,{
    return fetch(`${ENDPOINT}/Interaccion/ListarTipoInteraccion`,{
        method: "GET",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

export const RegistrarAcceso= async({jsonAcceso,jwt}) =>{
    //return await fetch(`${ENDPOINTEST}/RegistrarAcceso`,{
    return fetch(`${ENDPOINT}/Interaccion/RegistrarAcceso`,{
        method: "POST",
        //mode: "no-cors",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type':'application/json'
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        //headers: new Headers({ 'Content-Type': 'application/json' }),
        
        body: jsonAcceso
    }).then(res=>{
         //if(!res.ok) throw new Error("Response is Not Ok")
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}

// export const ObtenerMetricas =async ({jsonfiltro,jwt}) =>{
//     //return await fetch(`${ENDPOINTEST}/ObtenerMetricas/${filtro}`,{
//     return fetch(`${ENDPOINT}/Interaccion/ObtenerMetricas2`,{
//         method: "POST",
//         //mode: "no-cors",
//         headers:{
//             "Authorization":"Bearer "+jwt,
//             //'Content-Type': 'application/json'
//             "accept": "text/plain"
//         },
//         body: jsonfiltro
        
//     }).then(res=>{
//          //if(!res.ok) throw new Error("Response is Not Ok")
//          if(!res.ok) 
//          {
//              if(res.status == 401)
//              {
//                  window.localStorage.removeItem('jwt')
//                  window.location.reload();
//              }
//              else
//              {
//                  throw new Error("No se recibió respuesta del servidor")
//              }
//          }
//          return res.json()
//     }).then(res=>{
//         const {data} = res
//         return data
//     })
// }

export const ObtenerMetricas= ({jsonfiltro,jwt}) =>{
    return fetch(`${ENDPOINT}/Interaccion/ObtenerMetricas2`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonfiltro
    }).then(res=>{
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}
export const ObtenerReporte= ({jsonfiltro,jwt}) =>{
    return fetch(`${ENDPOINT}/Interaccion/ObtenerReporte`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonfiltro
    }).then(res=>{
         if(!res.ok) 
         {
             if(res.status == 401)
             {
                 window.localStorage.removeItem('jwt')
                 window.location.reload();
             }
             else
             {
                 throw new Error("No se recibió respuesta del servidor")
             }
         }
         return res.json()
    }).then(res=>{
        const {data} = res
        return data
    })
}