import React,{useEffect,useState,useRef} from "react";
import ModalEva from "../../Modal/ModalEva";
import { ListBox } from 'primereact/listbox';
import * as constantes from "../../../constants/constantes.js";
import Boton from "../../Boton/Boton";
import "./Compartir.scss"
import TextSearchCard from "../../../components/TextSearchCardEva/TextSearchCard";
import * as Iconsax from "iconsax-react";
const Compartir = ({objPublicacion,mostrar,onHide,grupos,clickCompartir}) => {
    const [listaGrupos, setListaGrupos] = useState([]);
    const [listaGruposTotal, setListaGruposTotal] = useState([]);
    const GrupoSeleccionado =(e)=>{
        //console.log(e)
        clickCompartir(e.value.idGrupo,e.value.nombreGrupo)
    }

    useEffect(()=>{
        let lista=[]
        grupos && 
            grupos.map((item,i)=>{
                item.grupos.map((itemgr,j)=>{
                    lista.push(itemgr)
                })
            })
            setListaGrupos(lista);
            setListaGruposTotal(lista)
        },[])
    const BuscarGrupos = (e)=>{
        let listaFiltrada = listaGruposTotal.filter(x=>x.nombreGrupo.toLowerCase().includes(e.target.value.toLowerCase()));

        listaFiltrada.length >0 ? setListaGrupos(listaFiltrada) : setListaGrupos(listaGruposTotal)
    }
    const gruposTemplate = (option)=>
        <div className="grupos-list-item" style={{display:"flex", alignItems:"center"}}>
            <div className="list-item-img">
                <img src={option.nombreImagen === null || option.nombreImagen.length == 0 ? constantes.URL_IMAGE_GROUP_DEFAULT : constantes.URLBLOB_ADJ_GROUP + "/" + option.nombreImagen}
                    width={74} style={{maxHeight:74,borderRadius:8}} 
                ></img>
            </div>
            <div className="list-item-descripcion" style={{width:"70%",paddingLeft:10}}>
                    <div className="titulo">{option.nombreGrupo}</div>
                    <div className="p-eva-labelForm-grey">{option.cantidadUsuarios + " colaboradores"}</div>
                </div>
            {/* <div className="list-item-btn">
                <Boton nombre={"Compartir"} widths={"180px"} metodoClick={clickCompartir}></Boton>
            </div> */}
        </div>
    ;    
    const contenido = 
                <>
                <div className="grupos-contenido-buscar" style={{display:"flex",marginBottom:10}}>
                    <TextSearchCard placeholder = "Buscar grupos" icon={<Iconsax.SearchNormal1 color='#F47647'/>}
                                       className ="type-search" width="100%" onChange={BuscarGrupos}></TextSearchCard>
                </div>
                
                 <ListBox className="p-compartir-listBox" options={listaGrupos} optionLabel="nombreGrupo"
                    itemTemplate={gruposTemplate} 
                    style={{ width: "100%" }} listStyle={{ maxHeight: '250px'}}  onChange={GrupoSeleccionado}/>
                </>;
    
    const footerModal = <></>
    return (
        <>
            <ModalEva 
                mostrar={mostrar} 
                onHide={onHide}
                titulo={"Compartir en un grupo"}
                contenido = {contenido}
                footer={footerModal}
                widths={680}
                height={612}
            ></ModalEva>
        </>
        
     );
}
 
export default Compartir;