import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { Skeleton } from "primereact/skeleton";
import "./Actividades.scss"
import Boton from "../../../components/Boton/Boton";

import { ObtenerFechaFormatoCorto } from "../../../helpers/helpers";
import * as constantes from "../../../constants/constantes";
import { ObtenerListaSeccionClima,ObtenerActividades ,EliminarCelebracionPorId,EliminarCalendarioPorId} from "../../../service/ClimaService";
import classNames from "classnames";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from "primereact/dialog";
const Actividades = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario()
    const [tituloPagina, setTituloPagina] = useState(null);
    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [actividades, setActividades] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    const op = useRef([]);
    const fechActual = new Date();
    const [isLoading, setIsLoading] = useState(false);
    const mesActual = fechActual.getMonth() +1
    const [idSeleccionado, setIdSeleccionado] = useState(null);
    const [idActividadSeleccionado, setIdActividadSeleccionado] = useState(null);

    const [showDialog, setShowDialog] = useState(false);
    const [showDialogActividad, setShowDialogActividad] = useState(false);
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarClima") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verClima") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])
    const mesAnio =[{numeroMes:"01",nombreMes :"Enero"},
    {numeroMes:"02",nombreMes :"Febrero"},
    {numeroMes:"03",nombreMes :"Marzo"},
    {numeroMes:"04",nombreMes :"Abril"},
    {numeroMes:"05",nombreMes :"Mayo"},
    {numeroMes:"06",nombreMes :"Junio"},
    {numeroMes:"07",nombreMes :"Julio"},
    {numeroMes:"08",nombreMes :"Agosto"},
    {numeroMes:"09",nombreMes :"Setiembre"},
    {numeroMes:"10",nombreMes :"Octubre"},
    {numeroMes:"11",nombreMes :"Noviembre"},
    {numeroMes:"12",nombreMes :"Diciembre"}]

    useEffect(()=>{
        const obtenerListaSeccionClima = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({jwt}).then(data => 
                {
                    let temp = data.filter(x=>x.id == idSeccionClima);

                    setTituloPagina(temp[0].nombre);
                })
        }
        if(idSeccionClima){
            obtenerListaSeccionClima();
        } 
    },[idSeccionClima])

    const getActividades = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerActividades({jwt}).then(data => 
                {
                    setActividades(data)

                })
        }
    useEffect(()=>{
        
        if(!actividades)getActividades()
    },[])

    const Prueba=()=>{
    console.log("PRUEBA")
    }
const EliminarCelebracion=()=>{
    setIsLoading(true)
    let jwt = window.localStorage.getItem("jwt");
    let Id = idSeleccionado
    EliminarCelebracionPorId({Id,jwt}).then(data => 
    {               
        toast.current.show({severity:'success', summary: 'Success', detail:"Registro eliminado exitosamente.", life: 7000})

        setShowDialog(false)
        getActividades()
        setIsLoading(false)

    }).catch(errors => {
        
        toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
        setIsLoading(false)

    })

}

const EliminarActividad=()=>{
    setIsLoading(true)
    let jwt = window.localStorage.getItem("jwt");
    let Id = idActividadSeleccionado
    EliminarCalendarioPorId({Id,jwt}).then(data => 
    {               
        toast.current.show({severity:'success', summary: 'Success', detail:"Registro eliminado exitosamente.", life: 7000})

        setShowDialogActividad(false)
        getActividades()
        setIsLoading(false)

    }).catch(errors => {
        
        toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
        setIsLoading(false)

    })

}
const footerDelete = 
                <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialog(false)}></Boton>
                    <Boton nombre={"Eliminar evento"} widths="50%" color="primary" metodoClick={EliminarCelebracion} 
                    loading = {isLoading}></Boton>
                </div>;
const footerDeleteActividad = 
<div style={{display:"flex",justifyContent:"center",width:"100%"}}>
    <Boton nombre={"Cancelar"} widths="50%" color="secondary" metodoClick={()=>setShowDialogActividad(false)}></Boton>
    <Boton nombre={"Eliminar actividad"} widths="50%" color="primary" metodoClick={EliminarActividad} 
    loading = {isLoading}></Boton>
</div>;
    return ( 
        <div className="eva-actividades" style={{ marginLeft: 40 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div className="header">{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
                    {/* <div className="header">{actividades && <label htmlFor="header">{actividades.titulo}</label>}</div> */}
                    <div className="header-right">{actividades&&permisoEditar && tituloPagina && <Link to={"../Clima/Seccion/" + idSeccionClima + "/CrearActividades"}>{"Crear "+tituloPagina}</Link>}</div>
                </div>
            </div>
            <div className="p-eva-dashboard-actividades" style={{ marginTop: 16 }}>
                <div className="p-eva-labelForm">
                {/* <span>¡Conoce y revive todos los mejores momentos que tenemos dentro de nuestra cultura in y súmate a participar en cada uno de ellos!</span> */}

                    <span>{actividades? actividades.descripcion1:""}</span>
                </div>
                <div className="actividades-calendario-header header-subtitle" style={{marginTop:24}}>{actividades? actividades.subTitulo1:""}</div>
                {/* <div className="actividades-calendario-header header-subtitle" style={{marginTop:24}}>Calendario anual de actividades</div> */}
                <div className="actividades-calendario-body" style={{marginTop:16}}>
                    {/* <div className="calendario-body-descripcion">
                        Te mostramos nuestras principales actividades a lo largo del año para que no te pierdas ninguna.
                    </div> */}
                      <div className="calendario-body-descripcion">
                      {actividades? actividades.descripcion2:""}
                    </div>
                    <div className="calendario-body-meses" >
                    {
                        mesAnio.map((mes,i)=>{
                            return(
                                <div className={classNames("mes-item",{ "actual": parseInt(mesActual) == parseInt(mes.numeroMes),"pasado": parseInt(mesActual) > parseInt(mes.numeroMes)})}>
                                    <div className="mes-item-nombre">
                                        {mes.nombreMes}
                                    </div>
                                    {
                                        actividades && 
                                        actividades.clima_actividades_calendario.length >0 ?
                                        <>
                                            {
                                                actividades.clima_actividades_calendario.filter(x=>x.mes ==mes.numeroMes).length > 0 ?
                                                <div className="mes-item-actividades">
                                                    {
                                                        actividades.clima_actividades_calendario.filter(x=>x.mes ==mes.numeroMes)
                                                        .sort((a, b) => new Date(a.fechaActividad) - new Date(b.fechaActividad)) 
                                                        .map((actividad,j)=>{
                                                            return(
                                                                <>
                                                                <div className="actividades-item-container">
                                                                    <div className="actividades-item-left" style={{background:actividad.color}}></div>
                                                                    <div key={j} className="actividades-item" onClick={(e) => op.current[actividad.nombreActividad].toggle(e)} >
                                                                        {actividad.nombreActividad}
                                                                    </div>
                                                                </div>
                                                                
                                                                <OverlayPanel ref={e => (op.current[actividad.nombreActividad] = e)} 
                                                                    className="actividad-panel" >
                                                                    <div className="actividad-panel-detalle formgrid grid" >
                                                                        {
                                                                            permisoEditar  ? 
                                                                            <>
                                                                                <div className="panel-detalle-header field-detalle col-8 md:col-8 ">{actividad.nombreActividad}</div>
                                                                                <div className="panel-detalle-header field-detalle col-2 md:col-2 "><span><Iconsax.Trash size={24} color="#F47647" cursor={"pointer"} onClick={()=>{
                                                                                                                                                                                                                                setIdActividadSeleccionado(actividad.id)
                                                                                                                                                                                                                                setShowDialogActividad(true)
                                                                                                                                                                                                                        }}/></span></div>
                                                                                <div className="panel-detalle-header field-detalle col-2 md:col-2"><span><Iconsax.Edit2 size={24} color="#F47647" cursor={"pointer"}  onClick={()=>navigate("../Clima/Seccion/"+idSeccionClima+"/EditarCalendario/"+actividad.id)}/></span></div>
                                                                            </>
                                                                            
                                                                            :
                                                                                <div className="panel-detalle-header field-detalle col-12 md:col-12">{actividad.nombreActividad}</div>
                                                                        }
                                                                        
                                                                        <div className="panel-detalle-info field-detalle col-3 md:col-3">Fecha:</div>
                                                                        <div className="panel-detalle-info field-detalle  col-9 md:col-9">{ObtenerFechaFormatoCorto(actividad.fechaActividad)}</div>
                                                                        <div className="panel-detalle-info field-detalle  col-3 md:col-3">Hora:</div>
                                                                        <div className="panel-detalle-info field-detalle  col-9 md:col-9">{actividad.hora.length > 3  ?actividad.hora : ""}</div>
                                                                        <div className="panel-detalle-info field-detalle  col-3 md:col-3">Link:</div>
                                                                        <div className="panel-detalle-info field-detalle  col-9 md:col-9" style={{wordWrap:"break-word"}}><a className="link-actividad" href={actividad.linkActividad.indexOf("http") > -1 ? actividad.linkActividad : "http://"+actividad.linkActividad} target="_blank">{actividad.linkActividad}</a></div>
                                                                        <div className="panel-detalle-info field-detalle  col-3 md:col-3">Lugar:</div>
                                                                        <div className="panel-detalle-info field-detalle  col-9 md:col-9">{actividad.lugar}</div>
                                                                        <div className="panel-detalle-info field-detalle  col-3 md:col-3">Área:</div>
                                                                        <div className="panel-detalle-info field-detalle  col-9 md:col-9">{actividad.nombre}</div>
                                                                    </div>
                                                                </OverlayPanel>
                                                                </>
                                                                
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div className="mes-item-body-vacio">
                                                    No hay actividades y celebraciones para este mes.
                                                </div>
                                            }
                                        </>
                                        
                                       
                                        :
                                        <div className="mes-item-body-vacio">
                                            No hay actividades y celebraciones para este mes.
                                        </div>
                                    }
                                    
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
                {/* <div className="actividades-comunicacion-header header-subtitle" style={{marginTop:24}}>Espacios de Comunicación Interna</div> */}
                <div className="actividades-comunicacion-body" style={{marginTop:16}}>
                    {/* <div className="comunicacion-body-descripcion">
                        Vivimos una cultura horizontal y transparente, sé parte de nuestros espacios de comunicación.
                    </div>
                    <div className="body-repositorio">
                        {
                            actividades && 
                            actividades.clima_actividades_celebracion.filter(x=>x.seccion=="COMUNICACION").map((item,i)=>{
                                return(
                                    <div className="repositorio-item" key={i}>
                                        {
                                            permisoEditar && 
                                                <div className="repositorio-item-delete" onClick={()=>
                                                {
                                                    setIdSeleccionado(item.id)
                                                    setShowDialog(true)
                                                }}>
                                                    <Iconsax.Trash color="#FFFFFF"/>
                                                </div>
                                        }
                                        
                                        <div className="repositorio-item-header">
                                           
                                             <div style={{height:136}}>
                                                <img src={constantes.URLBLOB_CLIMA_ACTIVIDADES_COMUNICACION+"/"+item.nombreFoto}
                                                    style={{maxWidth:312,maxHeight:136}}></img>
                                             </div>
                                            
                                            <div>{item.nombre}</div> 
                                        </div>
                                        <div className="repositorio-item-footer">
                                            <Boton nombre={"Ver más"} widths="312px" color="secondary" margin={0} metodoClick={()=>navigate("../Clima/Seccion/"+idSeccionClima+"/DetalleEvento/"+item.id)}></Boton>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div> */}
                    
                    <div className="actividades-celebracion-header header-subtitle" style={{marginTop:24}}>{actividades? actividades.subTitulo2:""}</div>
                    {/* <div className="actividades-celebracion-header header-subtitle" style={{marginTop:24}}>Celebraciones importantes</div> */}
                    <div className="celebracion-body-descripcion">
                    No te pierdas ninguna de nuestras fechas y actividades realizadas ¡revive aquí los mejores momentos!
                    </div>
                    <div className="body-repositorio">
                        {
                            actividades && 
                            actividades.clima_actividades_celebracion.filter(x=>x.seccion=="CELEBRACION").map((item,i)=>{
                                return(
                                    <div className="repositorio-item" key={i}>
                                        {
                                            permisoEditar && 
                                                <div className="repositorio-item-delete" onClick={()=>
                                                {
                                                    setIdSeleccionado(item.id)
                                                    setShowDialog(true)
                                                }}>
                                                    <Iconsax.Trash color="#FFFFFF"/>
                                                </div>
                                        }
                                        <div className="repositorio-item-header" > 
                                        {/* <div className="repositorio-item-header" style={{backgroundColor:"red"}}>  */}

                                            <div style={{height:136}}>
                                            {/* <div style={{height:136,backgroundColor:"blue"}}> */}

                                                <img src={constantes.URLBLOB_CLIMA_ACTIVIDADES_CELEBRACION+"/"+item.nombreFoto}
                                                    style={{maxWidth:312,maxHeight:136}}></img>
                                            </div>
                                             
                                            <div>{item.nombre}</div> 
                                        </div>
                                        <div className="repositorio-item-footer">
                                            <Boton nombre={"Ver más"} widths="312px" color="secondary" margin={0} metodoClick={()=>navigate("../Clima/Seccion/"+idSeccionClima+"/DetalleEvento/"+item.id)}></Boton>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </div>
            <Dialog visible={showDialog} modal  onHide={()=>setShowDialog(false)} 
                footer={footerDelete}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar este evento?</label>
                
            </Dialog>

            <Dialog visible={showDialogActividad} modal  onHide={()=>setShowDialogActividad(false)} 
                footer={footerDeleteActividad}  className ="p-eva-dialogDelete"
                >
                
                <label htmlFor="confirmDelete">¿Quieres eliminar esta actividad?</label>
            </Dialog>
        </div>
     );
}
 
export default Actividades;