import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import useGrupos from "../../../hooks/useGrupos";
import * as Iconsax from "iconsax-react";
import CardPublicacion from "../../../components/Inicio/Publicaciones/CardPublicacion";
import CrearPublicacion from "../../../components/Inicio/Publicaciones/CrearPublicacion";
import Colaboradores from "../../../components/Inicio/Publicaciones/Colaboradores";
import ModalEva from "../../../components/Modal/ModalEva";
import Boton from "../../../components/Boton/Boton";
import MultiselectEva from "../../../components/Multiselect/MultiSelectEva";
import { ListUsersTag } from "../../../service/UsuariosService";
import EditarPublicacion from "../../../components/Inicio/Publicaciones/EditarPublicacion";
import { ListarPublicacion, ListarPublicacionPorGrupo ,ListarPendientesAprobacion,ActualizarEstadoPublicacion, ObtenerNotificacionPorId} from "../../../service/PublicacionService";
import { ListarInteraccion } from "../../../service/InteraccionService";
import "./PublicacionGrupo.scss";
import axios from "axios";
import { ListGroupsType } from "../../../service/GruposService";

import * as constantes from "../../../constants/constantes.js";

import TabViewEva from "../../../components/TabviewEva/TabviewEva";
import { TabPanel } from "primereact/tabview";
import { TabView} from 'primereact/tabview';
import { Avatar } from "primereact/avatar";
import { Skeleton } from "primereact/skeleton";
import CardPublicacionCompartida from "../../../components/Inicio/Publicaciones/CardPublicacionCompartida";
import  {ObtenerTiempoTranscurrido} from "../../../helpers/helpers.js"
import PreviewMedia from "../../../components/PreviewMedia/PreviewMedia";
import ToastEva from "../../../components/Toast/ToastEva";
import { Tag } from "primereact/tag";
import ReactPlayer from "react-player";
import { AgregarUsuarioGrupos } from "../../../service/GruposService";
const PublicacionGrupo = () => {
    const navigate = useNavigate();
    const { permisos, isLogged, perfil } = useUsuario();
    const { GetGrupo, grupo } = useGrupos();
    const [tituloPagina, setTituloPagina] = useState(null);
    const [colaboradoresGrupo, setColaboradoresGrupo] = useState([]);
    const [mostrarModalColaborador, setMostrarModalColaborador] = useState(false);
    const [selected, setSelected] = useState([]);
    const [listUserTotal, setListUserTotal] = useState([]);
    const [mostrarEditar, setMostrarEditar] = useState(false);
    const [listaPublicacion, setListaPublicacion] = useState(null);
    const [listaInteraccion, setlistaInteraccion] = useState([]);
    const [srcImagenPerfil, setsrcImagenPerfil] = useState();

    const [gruposUsuario, setGruposUsuario] = useState([]);
    const [tipoPermisoGrupo, setTipoPermisoGrupo] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [mostrarAprobacion, setMostrarAprobacion] = useState(false);

    const [listaPendienteAprobacion, setListaPendienteAprobacion] = useState(null);
    const [publicacionEdicion, setPublicacionEdicion] = useState(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [idPublicacionSeleccionada, setIdPublicacionSeleccionada] = useState(null);
    const [imagenPublicacion, setImagenPublicacion] = useState(null);
    const [mostrarTagProgramada, setMostrarTagProgramada] = useState(false);
    const [pestañaActiva, setPestañaActiva] = useState(null);
    const toast = useRef(null);
    let { idGrupo } = useParams();
    let { idPublicacion } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        !isLogged && navigate("/");
    }, []);

    useEffect(() => {
        const AccionPermisos = async () => {
            if (permisos.indexOf("verGruposTodos") > -1) {
                setTipoPermisoGrupo("total");
            } else if (permisos.indexOf("creareditargrupos") > -1) {
                setTipoPermisoGrupo("edicion");
            } else if (permisos.indexOf("verGerenciaArea") > -1) {
                setTipoPermisoGrupo("basico");
            }
        };
        if (permisos.length > 0) {
            permisos.indexOf("verGrupos") > -1 ? AccionPermisos() : navigate("/");
            if(permisos.indexOf("aprobarPublicaciones") > -1) { 
                    //setPestañaActiva(1)
                    setIsAdmin(true);
                }
        }
    }, [permisos]);

    const sendMessage = async (idDestinoUsuario,idNoti,detalle="",mensaje="") => {
        return axios.post(constantes.URL_NOTIFICACION+"/messageUser",{
            //GroupName: grupo,
             GroupName: "Administrador General - Plataforma",
            //GroupName: "InLearning",
            ConnectionId:idDestinoUsuario,
            Sender:"WEB",
            Text: "mensaje enviado por el sistema web",
            IdNotificacion:idNoti,
            Detalle:detalle,
            Mensaje:mensaje
        }).then(resp=>resp.data)
    }

    const sendMessageGroup = async (grupo,idNoti,idGrupo,idPublicacion,ruta,detalle="",mensaje="") => {
        return await axios.post(constantes.URL_NOTIFICACION+"/messageGroup",{
            GroupName: grupo,
            // GroupName: "Administrador General - Plataforma",
            //GroupName: "InLearning",
            ConnectionId:"1",
            Sender:"WEB",
            Text: "mensaje enviado por el sistema web",
            IdNotificacion:idNoti,
            Link:constantes.URLSITE_PUBLICACION+idGrupo+"/Publicaciones/"+idPublicacion,
            RutaImagen:constantes.URLBLOB_PUBLICACIONES+"/"+idPublicacion+"/"+ruta,
            Detalle:detalle,
            Mensaje:mensaje
        }).then(resp=>resp.data)
    }
    
    useEffect(() => {
        const ObtenerGroupType = async () => {
            let jwt = window.localStorage.getItem("jwt");
            await ListGroupsType(jwt, tipoPermisoGrupo).then((data) => setGruposUsuario(data));
        };
        // if (listGruposType.length === 0) {
        if (tipoPermisoGrupo) {
            ObtenerGroupType();
        }
    }, [tipoPermisoGrupo]);

    useEffect(() => {

        if (perfil) perfil.foto === "" || perfil.foto === null ? setsrcImagenPerfil(constantes.URLAVATARDEFAULT) : setsrcImagenPerfil(constantes.URLBLOB_ADJ_AVATAR + "/" + perfil.idUsuario + "/" + perfil.foto);
    }, [perfil]);

    const ObtenerPublicaciones = async () => {
        let jwt = window.localStorage.getItem("jwt");
        let IdGrupo = idGrupo;
        await ListarPublicacionPorGrupo({ jwt, IdGrupo }).then((data) => 
        {
            if(idPublicacion)
            {
                let nuevaData = data.filter((x) => x.id == idPublicacion);
                if(nuevaData.length > 0)
                {
                    if(nuevaData[0].estado == "Aprobado")
                    {
                        setPestañaActiva(0)
                    }
                }
                
                
                setListaPublicacion(nuevaData)
            }else{
                let pubNoHabilitada = data.filter(x=>x.habilitado == false)
                if(pubNoHabilitada[0])
                {
                    setMostrarTagProgramada(true)
                }
                setListaPublicacion(data)
            }
            
        });
    };

    useEffect(() => {
        // const ObtenerPublicaciones = async () => {
        //     let jwt = window.localStorage.getItem("jwt");
        //     let IdGrupo = idGrupo;
        //     await ListarPublicacionPorGrupo({ jwt, IdGrupo }).then((data) => setListaPublicacion(data));
        // };
        if (idGrupo && !idPublicacion) ObtenerPublicaciones();
    }, [idGrupo]);

    const RefrescarListado = (mensaje)=>{
        setListaPublicacion(null)
        toast.current.show({severity:'success', summary: 'Success', detail:mensaje, life: 7000})
        ObtenerPublicaciones();
    }
    const mostrarError = (mensaje)=>{
        toast.current.show({severity:'error', summary: 'Error', detail:mensaje, life: 7000})

    }

    useEffect(() => {
        if (idPublicacion) {
            ObtenerPublicaciones(); 
            //setPestañaActiva(1)
            // if (listaPublicacion) {
            //     let nuevaLista = listaPublicacion.filter((x) => x.id == idPublicacion);
            //     setListaPublicacion(nuevaLista);
            // }
        }
    }, [idPublicacion]);

    useEffect(() => {
        const ObtenerInteraccionesBase = async () => {
            let jwt = window.localStorage.getItem("jwt");
            await ListarInteraccion({ jwt }).then((data) => setlistaInteraccion(data));
        };
        // if (listGruposType.length === 0) {
        ObtenerInteraccionesBase();
    }, []);

    useEffect(() => {
        if (listUserTotal.length === 0) {
            let jwt = window.localStorage.getItem("jwt");
            ListUsersTag(jwt).then((data) => setListUserTotal(data));
        }
    }, []);
    const ObtenerPendientePublicacion = async () => {
        let jwt = window.localStorage.getItem("jwt");
        await ListarPendientesAprobacion({ jwt }).then((data) => 
        {
            if(idPublicacion)
            {
                //setPestañaActiva(1)
                let pendientes = data.filter((x)=>x.id == idPublicacion)
                if(pendientes.length > 0)
                {
                    setPestañaActiva(1)
                }
            }
           
            setListaPendienteAprobacion(data)

        });
    };
    useEffect(() => {
        const CargarUsuariosGrupos = async (grupoSelected) => {
            let usuariosGrupo = [];

            await grupoSelected.usuarios.map((item, i) => {
                usuariosGrupo.push({ name: item.nombre, code: String(item.id), id: item.id, foto: item.foto });
            });
            setColaboradoresGrupo(usuariosGrupo);
        };
        

        if (grupo) {
            CargarUsuariosGrupos(grupo);
            setTituloPagina(grupo.nombre);

            if (isAdmin && grupo.nombre == constantes.GRUPO_GENERAL) {
                setMostrarAprobacion(true);
                ObtenerPendientePublicacion();
            }
            // setModoEdicion(true)
        }
    }, [grupo]);
    const ObtenerGrupo = async () => {
        await GetGrupo({ idGrupo });
    };
    useEffect(() => {
        
        if (idGrupo) {
            ObtenerGrupo();
        }
    }, [idGrupo]);

    const handleSelectChange = (values) => {
        setSelected(values);
    };

    const footerModal = (
        <div className="p-eva-colaboradores-footer">
            <Boton nombre={"Añadir"} color="primary" widths="35%" metodoClick={()=>ActualizarGrupo()} loading={isLoading} disabled={!selected.length >0}></Boton>
        </div>
    );

    const contenidoModal = (
        <div className="p-eva-contenidoModal">
            <label htmlFor="buscarColaborador">Puedes añandir a los colaboradores escribiendo su nombre o código institucional directamente.</label>
            <MultiselectEva
                onChange={handleSelectChange}
                isMulti
                //options={selectButtonValues1}
                options={listUserTotal}
                value={selected}
            />
        </div>
    );
    const ObtenerDetalleNotificacionAprobado =async(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje=data.nombreUsuarioOrigen+ " Subió una publicación en el grupo "+data.nombreGrupo+ "."
        
            sendMessageGroup(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle,mensaje)
        })  
    } 

    const ObtenerDetalleNotificacionRechazado =async(idDestino,idNoti,detalle)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje ="Rechazaron tu publicación en el grupo "+data.nombreGrupo+ ". Para más información comunicarte con el área respectiva."
        
            // sendMessage(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle,mensaje)
            sendMessage(idDestino,idNoti,detalle,mensaje)
        })  
    } 
    const handleAprobar =(idPub,contenido)=>{

        let jwt = window.localStorage.getItem('jwt')
        let estado = "Aprobado";
        let id = idPub; 
        ActualizarEstadoPublicacion({jwt,id,estado})
        .then(data=>{
            toast.current.show({severity:'success', summary: 'Success', detail:"Publicación aprobada con éxito.", life: 3000})
            ObtenerPendientePublicacion();
            ObtenerDetalleNotificacionAprobado("Administrador General - Plataforma"
                                                ,data.data.id
                                                ,data.data.idGrupo
                                                ,data.data.idTipoTransaccion
                                                ,data.data.imagen
                                                ,contenido)
            ObtenerDetalleNotificacionAprobado("Inlearning Institutos"
                                                ,data.data.id
                                                ,data.data.idGrupo
                                                ,data.data.idTipoTransaccion
                                                ,data.data.imagen
                                                ,contenido)
            // sendMessageGroup("Administrador General - Plataforma",data.data.id,data.data.idGrupo,data.data.idTipoTransaccion,data.data.imagen);
            // sendMessageGroup("Inlearning Institutos",data.data.id,data.data.idGrupo,data.data.idTipoTransaccion,data.data.imagen)
        })
    }
    const handleRechazar =(idPub,idUsuarioDestino)=>{
        let jwt = window.localStorage.getItem('jwt')
        let estado = "Rechazado";
        let id = idPub; 
        ActualizarEstadoPublicacion({jwt,id,estado})
        .then(data=>{
            ObtenerPendientePublicacion();
            ObtenerDetalleNotificacionRechazado(idUsuarioDestino,data.data.id,"")
            // sendMessage(idUsuarioDestino,data.data.id)
        })
    }
    const MostrarEdicion =(item)=>{
        setMostrarEditar(true)
        setPublicacionEdicion(item)
    }

    const tabHeaderTemplatePub = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" style={{ cursor: 'pointer',height:"100%"}} onClick={options.onClick}>
                <span className="p-tabview-title">
                    <div  className="flex align-items-center px-3" >
                    Publicaciones
                </div>
            </span>
            </a>
            
        )
    };

    const tabHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <span className="p-tabview-title">
                    <div  className="flex align-items-center px-3">
                    Aprobar Publicaciones
                    {/* <Avatar image="images/avatar/amyelsner.png" onImageError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} shape="circle" className="mx-2" /> */}
                    <div style={{height:20,width:20}} className="p-tabvieweva-num"><span>{listaPendienteAprobacion.length}</span></div>
                </div>
            </span>
            </a>
            
        )
    };

    const ActualizarGrupo = () => {
        setIsLoading(true)
        let id = grupo.id;
        let temp = []
        selected.map((item,i)=>{
            temp.push(item.value)
        })
        let idUsers = temp;
        
        let jsonGrupo = JSON.stringify({ id,idUsers },null,2);
        // alert(jsonGrupo);
        // setIsLoading(false)
        let jwt = window.localStorage.getItem("jwt");
        AgregarUsuarioGrupos({jsonGrupo,jwt}).then(data=>{
            setSelected([])
            toast.current.show({severity:'success', summary: 'Success', detail:"Se agregaron los usuarios exitosamente.", life: 7000})
            setIsLoading(false)

            setTimeout(() => {
                // navigate(-1);
                ObtenerGrupo();
                setMostrarModalColaborador(false)
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            setIsLoading(false)
        })
    };
    useEffect(() => {
        console.log(pestañaActiva)
    }, [pestañaActiva]);

    return (
        <div className="eva-dashboard-publicacionGrupos" style={{ width: "100%" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>

            <div className="eva-dashboard-header" style={{ display: "inline-flex" }}>
                <div>
                    <i onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label style={{ marginLeft: 16 }}>{tituloPagina}</label>
                </div>
            </div>

            <div className="p-eva-dashboard-body" style={{ display: !mostrarAprobacion ? "inline-flex" : "none", width: "100%", marginTop: 32 }}>
                <div className="p-eva-publicacionGrupo" style={{ minWidth:670,width: 670 ,marginLeft:"auto",marginRight:"auto"}}>
                    {!idPublicacion && <CrearPublicacion IdGrupo={idGrupo} perfil={perfil} nombreGrupo={tituloPagina} handleRegister={RefrescarListado} handleError={mostrarError} isAdmin={isAdmin}></CrearPublicacion>}
                    
                    {
                       mostrarTagProgramada&& <Tag severity='warning' value="Existen pubicaciones programadas que se mostrarán según fecha configurada"style={{width:"100%",marginBottom:16}}></Tag>
                    }
                    {listaPublicacion ?
                        listaPublicacion.length > 0 ? (
                            listaPublicacion.map((item, i) => {
                                return (
                                    // <CardPublicacion
                                    //     nombreCorto={item.nombreUsuario}
                                    //     contenido={item.contenidoPublicacion}
                                    //     link={item.link}
                                    //     nombreLink={item.nombreLink}
                                    //     fijada={item.fijada}
                                    //     foto={item.nombreFoto}
                                    //     idUsuario={item.idUsuarioRegistro}
                                    //     imagen={item.rutaImagenVideo}
                                    //     imagenes ={item.publicacionCompletoAdjuntos}
                                    //     IdPublicacion={item.id}
                                    //     listaInteraccion={listaInteraccion}
                                    //     interacionLogin={item.idTipoInteraccionLogin}
                                    //     interacionUsuarios={item.interacionesPorUsuario}
                                    //     totalInteracciones={item.totalInteracciones}
                                    //     listaComentariosPub={item.listaComentarios}
                                    //     perfil={perfil}
                                    //     fechaRegistro={item.fechaRegistro}
                                    //     onClickEdit={() => MostrarEdicion(item)}
                                    //     key={i}
                                    //     keys={i}
                                    //     gruposUsuario={gruposUsuario}
                                    //     objPublicacion = {item}
                                    //     isAdmin={isAdmin}
                                    //     // idLogin = {perfil.idUsuario}
                                    // ></CardPublicacion>
                                    <CardPublicacion
                                    nombreCorto={item.nombreUsuario}
                                    contenido={item.contenidoPublicacion}
                                    link={item.link}
                                    nombreLink={item.nombreLink}
                                    fijada={item.fijada}
                                    foto={item.nombreFoto}
                                    idUsuario={item.idUsuarioRegistro}
                                    imagen={item.rutaImagenVideo}
                                    imagenes ={item.publicacionCompletoAdjuntos}
                                    IdPublicacion={item.id}
                                    listaInteraccion={listaInteraccion}
                                    interacionLogin={item.idTipoInteraccionLogin}
                                    interacionUsuarios={item.interacionesPorUsuario}
                                    totalInteracciones={item.totalInteracciones}
                                    listaComentariosPub={item.listaComentarios}
                                    nombreGrupo={item.nombreGrupo}
                                    perfil={perfil}
                                    fechaRegistro={item.fechaRegistro}
                                    onClickEdit={()=>MostrarEdicion(item)}
                                    key={i}
                                    keys={i}
                                    gruposUsuario={gruposUsuario}
                                    objPublicacion={item}
                                    isAdmin={isAdmin}
                                    //idLogin = {perfil.idUsuario}
                                    // menuRef={menuCompartir}
                                   
                                ></CardPublicacion>
                                );
                            })
                            )  :<>No existe Data</>
                    :
                    <div style={{display:"block"}}>
                            <Skeleton className="mb-4"height='150px' width='100%' style={{borderRadius:16}}></Skeleton>
                            <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>
                            <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>

                        </div>
                    }
                </div>
                <div className="p-eva-body-right" style={{width: 450, marginLeft: 16 }}>
                    <div className="p-eva_imagen_grupo" style={{marginBottom:16}}>
                        {grupo && 
                            <img width={450} style={{borderRadius:16}}
                            src={grupo.imagen ?constantes.URLBLOB_ADJ_GROUP+"/"+grupo.imagen : constantes.URL_IMAGE_GROUP_DEFAULT}></img>
                        }
                        
                    </div>
                    {grupo && <Colaboradores EsAdmin={isAdmin} tipoGrupo={grupo.tipoGrupo} usuarios={colaboradoresGrupo} onClick={() => setMostrarModalColaborador(true)} />}
                </div>
            </div>
            <div className="p-eva-dashboard-body-tab" style={{ display: mostrarAprobacion ? "block" : "none" }}>
                {/* {
                    listaPublicacion && listaPublicacion.length > 0  && */}
                    <TabViewEva cambiarAccion={()=>false} indexDefault={pestañaActiva}>
                    {/* <TabPanel header="Publicaciones"> */}
                    <TabPanel headerTemplate={tabHeaderTemplatePub}>
                        <div className="p-eva-dashboard-body" style={{ display: "inline-flex", width: "100%", marginTop: 32 }}>
                            <div className="p-eva-publicacionGrupo" style={{ minWidth:670,width: 670  ,marginLeft:"auto",marginRight:"auto" }}>
                                {!idPublicacion && <CrearPublicacion IdGrupo={idGrupo} perfil={perfil} nombreGrupo={tituloPagina} handleRegister={RefrescarListado} handleError={mostrarError} isAdmin={isAdmin}></CrearPublicacion>}
                                {listaPublicacion ? 
                                    listaPublicacion.length > 0 ? (
                                        listaPublicacion.map((item, i) => {
                                            return (
                                                // <CardPublicacion
                                                //     nombreCorto={item.nombreUsuario}
                                                //     contenido={item.contenidoPublicacion}
                                                //     link={item.link}
                                                //     nombreLink={item.nombreLink}
                                                //     fijada={item.fijada}
                                                //     foto={item.nombreFoto}
                                                //     idUsuario={item.idUsuarioRegistro}
                                                //     imagen={item.rutaImagenVideo}
                                                //     imagenes ={item.publicacionCompletoAdjuntos}
                                                //     IdPublicacion={item.id}
                                                //     listaInteraccion={listaInteraccion}
                                                //     interacionLogin={item.idTipoInteraccionLogin}
                                                //     interacionUsuarios={item.interacionesPorUsuario}
                                                //     totalInteracciones={item.totalInteracciones}
                                                //     listaComentariosPub={item.listaComentarios}
                                                //     perfil={perfil}
                                                //     fechaRegistro={item.fechaRegistro}
                                                //     onClickEdit={() => MostrarEdicion(item)}
                                                //     key={i}
                                                //     keys={i}
                                                //     gruposUsuario={gruposUsuario}
                                                //     objPublicacion = {item}
                                                //     isAdmin={isAdmin}
                                                //     // idLogin = {perfil.idUsuario}
                                                // ></CardPublicacion>
                                                <CardPublicacion
                                                nombreCorto={item.nombreUsuario}
                                                contenido={item.contenidoPublicacion}
                                                link={item.link}
                                                nombreLink={item.nombreLink}
                                                fijada={item.fijada}
                                                foto={item.nombreFoto}
                                                idUsuario={item.idUsuarioRegistro}
                                                imagen={item.rutaImagenVideo}
                                                imagenes ={item.publicacionCompletoAdjuntos}
                                                IdPublicacion={item.id}
                                                listaInteraccion={listaInteraccion}
                                                interacionLogin={item.idTipoInteraccionLogin}
                                                interacionUsuarios={item.interacionesPorUsuario}
                                                totalInteracciones={item.totalInteracciones}
                                                listaComentariosPub={item.listaComentarios}
                                                nombreGrupo={item.nombreGrupo}
                                                perfil={perfil}
                                                fechaRegistro={item.fechaRegistro}
                                                onClickEdit={()=>MostrarEdicion(item)}
                                                key={i}
                                                keys={i}
                                                gruposUsuario={gruposUsuario}
                                                objPublicacion={item}
                                                isAdmin={isAdmin}
                                                //idLogin = {perfil.idUsuario}
                                                // menuRef={menuCompartir}
                                               
                                            ></CardPublicacion>
                                            );
                                        })
                                    ) : <>No existe data</>
                                :
                                <div style={{display:"block"}}>
                                    <Skeleton className="mb-4"height='150px' width='100%' style={{borderRadius:16}}></Skeleton>
                                    <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>
                                    <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>

                                </div>
                                }
                            </div>
                            <div className="p-eva-body-right" style={{ width: 450, marginLeft: 16 }}>
                                <Colaboradores usuarios={colaboradoresGrupo} onClick={() => setMostrarModalColaborador(true)} />
                            </div>
                        </div>
                    </TabPanel>
                    {/* <TabPanel header={listaPendienteAprobacion&& "Aprobar publicaciones (" +listaPendienteAprobacion.length+")" }  style={{ display: mostrarAprobacion ? "block" : "none" }}> */}
                    <TabPanel headerTemplate={listaPendienteAprobacion&& tabHeaderTemplate}  style={{ display: mostrarAprobacion ? "block" : "none" }}>
                        {
                            listaPendienteAprobacion ? 
                                listaPendienteAprobacion.length > 0 ?
                                    listaPendienteAprobacion.map((item,i)=>{
                                        return(
                                            <div className="p-eva-publicacion-aprobacion" style={{width:"100%",marginBottom:8}} key={i}>
                                                <div className="publicacion-aprobacion-info" >
                                                    <div className="publicacion-aprobacion-info-header">
                                                        {/* <Avatar image={constantes.URLAVATARDEFAULT} size={"xlarge"} shape="circle" style={{ minWidth: 56 }} /> */}
                                                        <Avatar image={item.nombreFoto ? constantes.URLBLOB_ADJ_AVATAR + "/" + item.idUsuarioRegistro + "/" + item.nombreFoto : constantes.URLAVATARDEFAULT} size={"xlarge"} shape="circle" style={{ minWidth: 56 }} />
                                                        <div className="aprobacion-info-header-avatar">
                                                            <span className="titulo">
                                                                {/* {nombreCorto}
                                                                {nombreGrupo && "> " + nombreGrupo} */}
                                                                {item.nombreUsuario}
                                                            </span>
                                                            <span className="p-eva-labelForm-grey">{ObtenerTiempoTranscurrido(item.fechaActual,item.fechaRegistro)}</span>
                                                            {/* <span className="p-eva-labelForm-grey">Hace 20 minutos</span> */}
                                                        </div>
                                                    </div>
                                                    <div className="publicacion-aprobacion-info-body">
                                                        <div className="info-body-contenido">
                                                            <div style={{whiteSpace:"pre-line",color:"#000000"}}
                                                        dangerouslySetInnerHTML={{ __html: item.contenidoPublicacion}}></div>
                                                            {/* <span>{item.contenidoPublicacion}</span> */}
                                                            <div className="p-eva-cardPublicacion-link">
                                                                <a  className="p-eva-labelForm-orange" target="_blank" href={item.link}>{item.nombreLink}</a>
                                                            </div>
                                                            {
                                                                item.rutaImagenVideo && 
                                                                    <div className="p-eva-aprobacion-img">
                                                                        <img src={constantes.URLBLOB_PUBLICACIONES+"/"+item.id+"/"+item.rutaImagenVideo}
                                                                         onClick={()=>(setIdPublicacionSeleccionada(item.id),setImagenPublicacion(item.rutaImagenVideo),setVisibleModal(true))} style={{cursor:"pointer"}}></img>
                                                                    </div>
                                                            }
                                                            {
                                                                item.rutaVideo && 
                                                                <ReactPlayer url={item.rutaVideo}  width="100%"/>
                                                            }
                                                            {
                                                                item.idPublicacionCompartida && <CardPublicacionCompartida publicacion={item.publicacionCompartida}></CardPublicacionCompartida>
                                                            }
                                                            
                                                        </div>
                                                        <div className="info-body-imagen"></div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="publicacion-aprobacion-buttons">
                                                    <Boton nombre="Rechazar" color="secondary" widths="40%" metodoClick={()=>handleRechazar(item.id,item.idUsuarioRegistro)}></Boton>
                                                    <Boton nombre="Aprobar" color="primary" widths="40%"metodoClick={()=>handleAprobar(item.id,item.contenidoPublicacion)}></Boton>
                                                </div>
                                            </div>
                                        )
                                        
                                    })
                                :
                                <>No existe data</>
                            :
                            <div style={{display:"block"}}>
                                <Skeleton className="mb-4"height='150px' width='100%' style={{borderRadius:16}}></Skeleton>
                                <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>
                                <Skeleton className="mb-4"height='150px' width='100%' style={{marginBottom:16,borderRadius:16}}></Skeleton>

                            </div>
                        }
                        
                    </TabPanel>
                    </TabViewEva>
                {/* } */}
                
            </div>
            <div className="p-eva-dashboard-body-tabMobile">
                <TabView>
                    <TabPanel header="Publicaciones">
                        <div className="p-eva-publicacionGrupo" style={{ width: "100%", marginRight: 16 }}>
                            {!idPublicacion && <CrearPublicacion IdGrupo={idGrupo} perfil={perfil} nombreGrupo={tituloPagina} handleRegister={RefrescarListado} handleError={mostrarError} isAdmin={isAdmin}></CrearPublicacion>}
                            {listaPublicacion && listaPublicacion.length > 0 ? (
                                listaPublicacion.map((item, i) => {
                                    return (
                                        // <CardPublicacion
                                        //     nombreCorto={item.nombreUsuario}
                                        //     contenido={item.contenidoPublicacion}
                                        //     link={item.link}
                                        //     nombreLink={item.nombreLink}
                                        //     fijada={item.fijada}
                                        //     foto={item.nombreFoto}
                                        //     idUsuario={item.idUsuarioRegistro}
                                        //     imagen={item.rutaImagenVideo}
                                        //     imagenes ={item.publicacionCompletoAdjuntos}
                                        //     IdPublicacion={item.id}
                                        //     listaInteraccion={listaInteraccion}
                                        //     interacionLogin={item.idTipoInteraccionLogin}
                                        //     interacionUsuarios={item.interacionesPorUsuario}
                                        //     totalInteracciones={item.totalInteracciones}
                                        //     listaComentariosPub={item.listaComentarios}
                                        //     perfil={perfil}
                                        //     fechaRegistro={item.fechaRegistro}
                                        //     onClickEdit={() => MostrarEdicion(item)}
                                        //     key={i}
                                        //     keys={i}
                                        //     gruposUsuario={gruposUsuario}
                                        //     objPublicacion = {item}
                                        //     isAdmin={isAdmin}
                                        //     // idLogin = {perfil.idUsuario}
                                        // ></CardPublicacion>
                                        <CardPublicacion
                                        nombreCorto={item.nombreUsuario}
                                        contenido={item.contenidoPublicacion}
                                        link={item.link}
                                        nombreLink={item.nombreLink}
                                        fijada={item.fijada}
                                        foto={item.nombreFoto}
                                        idUsuario={item.idUsuarioRegistro}
                                        imagen={item.rutaImagenVideo}
                                        imagenes ={item.publicacionCompletoAdjuntos}
                                        IdPublicacion={item.id}
                                        listaInteraccion={listaInteraccion}
                                        interacionLogin={item.idTipoInteraccionLogin}
                                        interacionUsuarios={item.interacionesPorUsuario}
                                        totalInteracciones={item.totalInteracciones}
                                        listaComentariosPub={item.listaComentarios}
                                        nombreGrupo={item.nombreGrupo}
                                        perfil={perfil}
                                        fechaRegistro={item.fechaRegistro}
                                        onClickEdit={()=>MostrarEdicion(item)}
                                        key={i}
                                        keys={i}
                                        gruposUsuario={gruposUsuario}
                                        objPublicacion={item}
                                        isAdmin={isAdmin}
                                        //idLogin = {perfil.idUsuario}
                                        // menuRef={menuCompartir}
                                       
                                    ></CardPublicacion>
                                    );
                                })
                            ) : (
                                <>No existe data</>
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel header="Colaboradores">
                        <div className="p-eva-body-right" style={{ width: "100%" }}>
                            <Colaboradores usuarios={colaboradoresGrupo} onClick={() => setMostrarModalColaborador(true)} />
                        </div>
                    </TabPanel>
                    <TabPanel  header={listaPendienteAprobacion&&"Aprobar publicaciones (" +listaPendienteAprobacion.length+")" } style={{ display: mostrarAprobacion ? "block" : "none" }}>
                        {
                            listaPendienteAprobacion ? 
                                listaPendienteAprobacion.length > 0 ?
                                    listaPendienteAprobacion.map((item,i)=>{
                                        return(
                                            <div className="p-eva-publicacion-aprobacion" style={{width:"100%",marginBottom:8}} key={i}>
                                                <div className="publicacion-aprobacion-info" >
                                                    <div className="publicacion-aprobacion-info-header">
                                                        {/* <Avatar image={constantes.URLAVATARDEFAULT} size={"xlarge"} shape="circle" style={{ minWidth: 56 }} /> */}
                                                        <Avatar image={item.nombreFoto ? constantes.URLBLOB_ADJ_AVATAR + "/" + item.idUsuarioRegistro + "/" + item.nombreFoto : constantes.URLAVATARDEFAULT} size={"xlarge"} shape="circle" style={{ minWidth: 56 }} />
                                                        <div className="aprobacion-info-header-avatar">
                                                            <span className="titulo">
                                                                {/* {nombreCorto}
                                                                {nombreGrupo && "> " + nombreGrupo} */}
                                                                {item.nombreUsuario}
                                                            </span>
                                                            <span className="p-eva-labelForm-grey">{ObtenerTiempoTranscurrido(item.fechaRegistro)}</span>
                                                            {/* <span className="p-eva-labelForm-grey">Hace 20 minutos</span> */}
                                                        </div>
                                                    </div>
                                                    <div className="publicacion-aprobacion-info-body">
                                                        <div className="info-body-contenido">
                                                            <span>{item.contenidoPublicacion}</span>
                                                            <div className="p-eva-cardPublicacion-link">
                                                                <a  className="p-eva-labelForm-orange" target="_blank" href={item.link}>{item.nombreLink}</a>
                                                            </div>
                                                            {
                                                                item.rutaImagenVideo && 
                                                                    <div className="p-eva-aprobacion-img">
                                                                        <img src={constantes.URLBLOB_PUBLICACIONES+"/"+item.id+"/"+item.rutaImagenVideo}
                                                                            onClick={()=>(setIdPublicacionSeleccionada(item.id),setImagenPublicacion(item.rutaImagenVideo),setVisibleModal(true))} style={{cursor:"pointer"}}></img>
                                                                    </div>
                                                            }

                                                            {
                                                                item.IdPublicacionCompartida && <CardPublicacionCompartida publicacion={item.publicacionCompartida}></CardPublicacionCompartida>
                                                            }
                                                        </div>
                                                        <div className="info-body-imagen"></div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="publicacion-aprobacion-buttons">
                                                    <Boton nombre="Rechazar" color="secondary" widths="40%" metodoClick={()=>handleRechazar(item.id)}></Boton>
                                                    <Boton nombre="Aprobar" color="primary" widths="40%" metodoClick={()=>handleAprobar(item.id)}></Boton>
                                                </div>
                                            </div>
                                        )
                                        
                                    })
                                :
                                <>No data found</>
                            :
                            <Skeleton height="300px" width="100%"></Skeleton>
                        }
                        
                    </TabPanel>
                </TabView>
            </div>
            {mostrarModalColaborador && <ModalEva mostrar={mostrarModalColaborador} onHide={() => {setMostrarModalColaborador(false);setSelected([])}} footer={footerModal} contenido={contenidoModal} widths="30vw" height={"350px"} titulo={"Añadir colaborador"} />}
            {mostrarEditar && <EditarPublicacion mostrar={mostrarEditar} perfil={perfil} publicacionEdit={publicacionEdicion} onHide={() => setMostrarEditar(false)}></EditarPublicacion>}
            {visibleModal&&
                <PreviewMedia visibleModal={visibleModal} urlFile={constantes.URLBLOB_PUBLICACIONES+"/"+idPublicacionSeleccionada+"/"+imagenPublicacion} 
                onHide={()=>setVisibleModal(false)}></PreviewMedia>
            }
        </div>
    );
};

export default PublicacionGrupo;
