import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import  Carousel  from "./../Carousel/Carousel";
import "./PreviewMedia.scss"
import { FaDownload } from "react-icons/fa"; // Importa el icono de descarga

const PreviewMediaImage = ({ urlFile,imagenes, visibleModal = false, onHide, maxWidth , height = 85 }) => {
  
    const images = imagenes.map(item => urlFile + item);
    console.log(images);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setVisible(visibleModal);
        console.log("PreviewMediaImage")
    }, [visibleModal]);
    

    const [heightAdjuster, setHeightAdjuster] = useState(null);

  useEffect(() => {
    
    setHeightAdjuster(images.length * 10); 
  }, []);
    return (
        <Dialog visible={visible} onHide={onHide} className="p-eva-PreviewMedia"
        style={{ height: (parseInt(height) + 10).toString() + "vh", width:"80%", display: "flex", justifyContent: "center",overflow:"none",textAlign:"-webkit-center"}}>
         <div className="p-eva-PreviewMedia-contenido" style={{width:"97%"}}>
            <Carousel images={images}  />
         </div>          
         {/* <div className="p-eva-PreviewMedia-contenido" style={{ height: height - 3 + "vh" ,width:"1090px",backgroundColor:"blue"}}>
          <Carousel images={images} style={{ marginLeft: "-20px" }} />
         </div> */}
        </Dialog>  
    );
};
 
export default PreviewMediaImage;