import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate, json } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import "./Comites.scss"
import ToastEva from "../../../../components/Toast/ToastEva";
import { ObtenerClimaComite } from "../../../../service/ClimaService";
import { Panel } from 'primereact/panel';
import * as Iconsax from "iconsax-react";
import ContactCard from "../../../../components/ContactCard/ContactCard";
import ContactCardV2 from "../../../../components/ContactCard/ContactCardV2"
import { Card } from "primereact/card";
import * as constantes from "../../../../constants/constantes";

const Comites = () => {
    const navigate = useNavigate();
    const [comite, setComite] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    let { idEncuesta } = useParams();

    useEffect(()=>{
        const getComite = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerClimaComite({jwt}).then(data => 
                {
                    console.log("setComite",data)
                    setComite(data)
            
                })
        }
        if(!comite)getComite()
    },[])

    const headerMaki = <div className="comite-descripcion" >
     <div className="comite-descripcion-titulo header-subtitle">{comite&&comite.subtitulo2}</div>
        <div className="comite-descripcion-detalle">{comite&&comite.descripcionMaki}</div>
        <div style={{marginTop:12}}>{comite && comite.subtituloMaki}</div>
    </div>
    const headerHostigamiento = <div className="comite-descripcion" >
        <div className="comite-descripcion-titulo header-subtitle">{comite&&comite.subtitulo3}</div>
       <div className="comite-descripcion-detalle">{comite&&comite.descripcionHostigamiento}</div>
       <div style={{marginTop:12}}>¿Quieres conocerlos? Despliega la tarjeta y conoce a cada uno de ellos</div>

   </div>

    const headerHostigamientoMarca=(marca,subtitulo)=>{
        return(
            <>
                <div className="comite-descripcion-titulo header-subtitle">{marca}</div>
                
                <div style={{marginTop:12}}>{subtitulo}</div>
            </>
        )
    }

    const headerContacto =
    <div className="p-eva-DetalleTema-cardContactIcon" >
        <i className="p-eva-DetalleTema-cardContact-icon">
            {/* <Iconsax.Personalcard color="#F47647" /> */}
            <img src="../../../../../images/liderIn/contact-img.png"></img>
        </i>
        <span><label className="header-subtitle">¿Necesita ayuda? Comunícate con</label></span>
    </div>


    ;
    return ( 
        <div className="p-eva-comites" >
            <div className="header-subtitle" >
                {comite&&comite.subtitulo1}
            </div>
            <div>{comite&&comite.descripcionIntervencion}</div>
            
            <Panel 
                className="p-eva-panel-acordeon"
                header={headerMaki} toggleable 
                expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                collapsed
                >
                <div className="comite-usuario" >
                    {/* <div className="p-fluid formgrid grid" >
                        {
                            comite &&
                            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).length > 0 &&
                            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).map((item,i)=>{
                                return(
                                    <div className="field col-12 md:col-4" key={i} style={{marginBottom:60}}>
                                        <ContactCard
                                            idUser={item.usuario.idUsuario}
                                            area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                            gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                            foto ={item.usuario.nombreFoto}
                                            puesto={item.usuario.puesto}
                                            nombreUser = {item.usuario.nombre}
                                            colorFondo="#F5F5F5"
                                            width={328}
                                        ></ContactCard>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                    <div className="usuarios-lista">
                        <div className="usuarios-item-header">
                            <div></div>
                            <div className="titulo" style={{marginLeft:80,width:260}}>Nombre</div>
                            <div className="titulo puesto" style={{width:178}}>Puesto</div>
                            <div className="titulo area" style={{width:258}}>Area</div>
                            <div className="titulo gerencia">Gerencia</div>
                        </div>
                        {
                            comite &&
                            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).length > 0 &&
                           
                            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-COLABORADOR" && x.estado == true).map((item,i)=>{
                                return(
                                    <div className="usuarios-item" key={i}>
                                        <div className="usuarios-item-avatar" style={{marginLeft:-24}}>
                                            <img src={!item.usuario.foto ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.usuario.id+"/"+item.usuario.foto }
                                                width={80} style={{borderRadius:100,marginRight:24}}></img>
                                        </div>
                                        <div className="usuarios-item-nombre p-eva-labelForm">{item.usuario.nombre}</div>
                                        <div className="usuarios-item-marcar p-eva-labelForm">{item.usuario.puesto}</div>
                                        <div className="usuarios-item-gerencia p-eva-labelForm">{item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}</div>
                                        <div className="usuarios-item-puesto p-eva-labelForm">{item.usuario.gerencia ? item.usuario.gerencia.nombre :""}</div>
                                    </div>
                                )
                                
                            })
                        }
                        
                    </div>
                </div>
                {/* <div className="p-eva-detalleTema-contactCard" >
                    <Card   header={headerContacto}>
                        <div className="p-fluid formgrid grid"> */}
                            {
                                comite &&
                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-CONTACTO" && x.estado == true).length > 0 &&
                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "MAKI-CONTACTO" && x.estado == true).map((item,i)=>{
                                            return(
                                                <div className="p-eva-detalleTema-contactCard" >
                                                <Card   header={headerContacto}>
                                                    <div className="p-fluid formgrid grid">
                                                    <div className="temadetalle-item-contactCard" key={i}>
                                                        <ContactCardV2
                                                            idUser={item.usuario.id}
                                                            area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                                            gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                                            foto ={item.usuario.foto}
                                                            puesto={item.usuario.puesto}
                                                            nombreUser = {item.usuario.nombre +"cccc"}
                                                            colorFondo="#F5F5F5"
                                                            width={328}
                                                            email={item?.usuario?.correo}
                                                            fechaNacimiento={item?.usuario?.fechaNacimiento}
                                                            celular={item?.usuario?.celular}
                                                            ></ContactCardV2>
                                                    </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            )
                                        })
                                
                            }
                        {/* </div>
                    </Card>
                </div> */}
            </Panel>
            <div className="header-subtitle" style={{marginTop:24}}>
                {comite&&comite.subtitulo3}
            </div>
            <div>{comite&&comite.descripcionHostigamiento}</div>
            {
                comite && 
                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ZEGEL" && x.estado == true).length > 0 &&
                    <Panel 
                        className="p-eva-panel-acordeon"
                        header={()=>headerHostigamientoMarca("Zegel",comite.subtituloHostigamientoZegel)} toggleable 
                        expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                        collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                        collapsed
                        >
                            <div className="comite-usuario" >
                                <div className="usuarios-lista">
                                    <div className="usuarios-item-header">
                                        <div className="titulo" style={{marginLeft:80,width:260}}>Nombre</div>
                                        <div className="titulo" style={{width:178}}>Puesto</div>
                                        <div className="titulo" style={{width:258}}>Area</div>
                                        <div className="titulo">Gerencia</div>
                                    </div>

                                    {
                                        comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ZEGEL" && x.estado == true).map((item,i)=>{
                                            return(
                                                <div className="usuarios-item" key={i}>
                                                    <div className="usuarios-item-avatar" style={{marginLeft:-24}}>
                                                        <img src={!item.usuario.foto ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.usuario.id+"/"+item.usuario.foto }
                                                            width={80} style={{borderRadius:100,marginRight:24}}></img>
                                                    </div>
                                                    <div className="usuarios-item-nombre p-eva-labelForm">{item.usuario.nombre}</div>
                                                    <div className="usuarios-item-marcar p-eva-labelForm">{item.usuario.puesto}</div>
                                                    <div className="usuarios-item-gerencia p-eva-labelForm">{item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}</div>
                                                    <div className="usuarios-item-puesto p-eva-labelForm">{item.usuario.gerencia ? item.usuario.gerencia.nombre :""}</div>
                                                </div>
                                            )
                                            
                                        })
                                    }
                                </div>
                            </div>
                            {
                                 comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ZEGEL" && x.estado == true).length > 0 &&
                                 <div className="p-eva-detalleTema-contactCard" >
                                    <Card   header={headerContacto}>
                                        <div className="p-fluid formgrid grid">
                                            {
                                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ZEGEL" && x.estado == true).map((item,i)=>{
                                                            return(
                                                                <div className="temadetalle-item-contactCard" key={i}>
                                                                    <ContactCardV2 
                                                                        idUser={item.usuario.id }
                                                                        area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                                                        gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                                                        foto ={item.usuario.foto}
                                                                        puesto={item.usuario.puesto}
                                                                        nombreUser = {item.usuario.nombre }
                                                                        colorFondo="#F5F5F5"
                                                                        width={328}
                                                                        email={item?.usuario?.correo}
                                                                        fechaNacimiento={item?.usuario?.fechaNacimiento}
                                                                        celular={item?.usuario?.celular}
                                                                        ></ContactCardV2>
                                                                </div>
                                                            )
                                                        })
                                                
                                            }
                                        </div>
                                    </Card>
                                </div>
                            }
                           
                    </Panel>   
            }
            {
                comite && 
                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-IDAT" && x.estado == true).length > 0 &&
                    <Panel 
                        className="p-eva-panel-acordeon"
                        header={()=>headerHostigamientoMarca("Idat",comite.subtituloHostigamientoIdat)} toggleable 
                        expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                        collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                        collapsed
                        >
                            <div className="comite-usuario" >
                                <div className="usuarios-lista">
                                    <div className="usuarios-item-header">
                                        <div className="titulo" style={{marginLeft:80,width:260}}>Nombre</div>
                                        <div className="titulo" style={{width:178}}>Puesto</div>
                                        <div className="titulo" style={{width:258}}>Area</div>
                                        <div className="titulo">Gerencia</div>
                                    </div>

                                    {
                                        comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-IDAT" && x.estado == true).map((item,i)=>{
                                            return(
                                                <div className="usuarios-item" key={i}>
                                                    <div className="usuarios-item-avatar" style={{marginLeft:-24}}>
                                                        <img src={!item.usuario.foto ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.usuario.id+"/"+item.usuario.foto }
                                                            width={80} style={{borderRadius:100,marginRight:24}}></img>
                                                    </div>
                                                    <div className="usuarios-item-nombre p-eva-labelForm">{item.usuario.nombre}</div>
                                                    <div className="usuarios-item-marcar p-eva-labelForm">{item.usuario.puesto}</div>
                                                    <div className="usuarios-item-gerencia p-eva-labelForm">{item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}</div>
                                                    <div className="usuarios-item-puesto p-eva-labelForm">{item.usuario.gerencia ? item.usuario.gerencia.nombre :""}</div>
                                                </div>
                                            )
                                            
                                        })
                                    }
                                </div>
                            </div>
                            {
                                 comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-IDAT" && x.estado == true).length > 0 &&
                                 <div className="p-eva-detalleTema-contactCard" >
                                    <Card   header={headerContacto}>
                                        <div className="p-fluid formgrid grid">
                                            {
                                                
                                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-IDAT" && x.estado == true).map((item,i)=>{
                                                            return(
                                                                <div className="temadetalle-item-contactCard" key={i}>
                                                                    <ContactCardV2 
                                                                        idUser={item.usuario.id}
                                                                        area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                                                        gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                                                        foto ={item.usuario.foto}
                                                                        puesto={item.usuario.puesto}
                                                                        nombreUser = {item.usuario.nombre}
                                                                        colorFondo="#F5F5F5"
                                                                        width={328}
                                                                        email={item?.usuario?.correo}
                                                                        fechaNacimiento={item?.usuario?.fechaNacimiento}
                                                                        celular={item?.usuario?.celular}
                                                                        ></ContactCardV2>
                                                                </div>
                                                            )
                                                        })
                                                
                                            }
                                        </div>
                                    </Card>
                                </div>
                            }
                           
                    </Panel>   
            }       

            {
                comite && 
                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CORRIENTE" && x.estado == true).length > 0 &&
                    <Panel 
                        className="p-eva-panel-acordeon"
                        header={()=>headerHostigamientoMarca("Corriente Alterna",comite.subtituloHostigamientoCorriente)} toggleable 
                        expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                        collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                        collapsed
                        >
                            <div className="comite-usuario" >
                                <div className="usuarios-lista">
                                    <div className="usuarios-item-header">
                                        <div className="titulo" style={{marginLeft:80,width:260}}>Nombre</div>
                                        <div className="titulo" style={{width:178}}>Puesto</div>
                                        <div className="titulo" style={{width:258}}>Area</div>
                                        <div className="titulo">Gerencia</div>
                                    </div>

                                    {
                                        comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CORRIENTE" && x.estado == true).map((item,i)=>{
                                            return(
                                                <div className="usuarios-item" key={i}>
                                                    <div className="usuarios-item-avatar" style={{marginLeft:-24}}>
                                                        <img src={!item.usuario.foto ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.usuario.id+"/"+item.usuario.foto }
                                                            width={80} style={{borderRadius:100,marginRight:24}}></img>
                                                    </div>
                                                    <div className="usuarios-item-nombre p-eva-labelForm">{item.usuario.nombre}</div>
                                                    <div className="usuarios-item-marcar p-eva-labelForm">{item.usuario.puesto}</div>
                                                    <div className="usuarios-item-gerencia p-eva-labelForm">{item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}</div>
                                                    <div className="usuarios-item-puesto p-eva-labelForm">{item.usuario.gerencia ? item.usuario.gerencia.nombre :""}</div>
                                                </div>
                                            )
                                            
                                        })
                                    }
                                </div>
                            </div>
                            {
                                 comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CORRIENTE" && x.estado == true).length > 0 &&
                                 <div className="p-eva-detalleTema-contactCard" >
                                    <Card   header={headerContacto}>
                                        <div className="p-fluid formgrid grid">
                                            {
                                                
                                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CORRIENTE" && x.estado == true).map((item,i)=>{
                                                            return(
                                                                <div className="temadetalle-item-contactCard" key={i}>
                                                                    <ContactCardV2 
                                                                        idUser={item.usuario.id}
                                                                        area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                                                        gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                                                        foto ={item.usuario.foto}
                                                                        puesto={item.usuario.puesto}
                                                                        nombreUser = {item.usuario.nombre}
                                                                        colorFondo="#F5F5F5"
                                                                        width={328}
                                                                        email={item?.usuario?.correo}
                                                                        fechaNacimiento={item?.usuario?.fechaNacimiento}
                                                                        celular={item?.usuario?.celular}
                                                                        ></ContactCardV2>
                                                                </div>
                                                            )
                                                        })
                                                
                                            }
                                        </div>
                                    </Card>
                                </div>
                            }
                           
                    </Panel>   
            }  

            {
                comite && 
                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CENTRO" && x.estado == true).length > 0 &&
                    <Panel 
                        className="p-eva-panel-acordeon"
                        header={()=>headerHostigamientoMarca("Centro de la imagen",comite.subtituloHostigamientoCentro)} toggleable 
                        expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                        collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                        collapsed
                        >
                            <div className="comite-usuario" >
                                <div className="usuarios-lista">
                                    <div className="usuarios-item-header">
                                        <div className="titulo" style={{marginLeft:80,width:260}}>Nombre</div>
                                        <div className="titulo" style={{width:178}}>Puesto</div>
                                        <div className="titulo" style={{width:258}}>Area</div>
                                        <div className="titulo">Gerencia</div>
                                    </div>

                                    {
                                        comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-CENTRO" && x.estado == true).map((item,i)=>{
                                            return(
                                                <div className="usuarios-item" key={i}>
                                                    <div className="usuarios-item-avatar" style={{marginLeft:-24}}>
                                                        <img src={!item.usuario.foto ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.usuario.id+"/"+item.usuario.foto }
                                                            width={80} style={{borderRadius:100,marginRight:24}}></img>
                                                    </div>
                                                    <div className="usuarios-item-nombre p-eva-labelForm">{item.usuario.nombre}</div>
                                                    <div className="usuarios-item-marcar p-eva-labelForm">{item.usuario.puesto}</div>
                                                    <div className="usuarios-item-gerencia p-eva-labelForm">{item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}</div>
                                                    <div className="usuarios-item-puesto p-eva-labelForm">{item.usuario.gerencia ? item.usuario.gerencia.nombre :""}</div>
                                                </div>
                                            )
                                            
                                        })
                                    }
                                </div>
                            </div>
                            {
                                 comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CENTRO" && x.estado == true).length > 0 &&
                                 <div className="p-eva-detalleTema-contactCard" >
                                    <Card   header={headerContacto}>
                                        <div className="p-fluid formgrid grid">
                                            {
                                                
                                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-CENTRO" && x.estado == true).map((item,i)=>{
                                                            return(
                                                                <div className="temadetalle-item-contactCard" key={i}>
                                                                    <ContactCardV2 
                                                                        idUser={item.usuario.id}
                                                                        area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                                                        gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                                                        foto ={item.usuario.foto}
                                                                        puesto={item.usuario.puesto}
                                                                        nombreUser = {item.usuario.nombre}
                                                                        colorFondo="#F5F5F5"
                                                                        width={328}
                                                                        email={item?.usuario?.correo}
                                                                        fechaNacimiento={item?.usuario?.fechaNacimiento}
                                                                        celular={item?.usuario?.celular}
                                                                        ></ContactCardV2>
                                                                </div>
                                                            )
                                                        })
                                                
                                            }
                                        </div>
                                    </Card>
                                </div>
                            }
                           
                    </Panel>   
            }  
            {
                comite && 
                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ITS" && x.estado == true).length > 0 &&
                    <Panel 
                        className="p-eva-panel-acordeon"
                        header={()=>headerHostigamientoMarca("Innova Teaching School.",comite.subtituloHostigamientoIts)} toggleable 
                        expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                        collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                        collapsed
                        >
                            <div className="comite-usuario" >
                                <div className="usuarios-lista">
                                    <div className="usuarios-item-header">
                                        <div className="titulo" style={{marginLeft:80,width:260}}>Nombre</div>
                                        <div className="titulo" style={{width:178}}>Puesto</div>
                                        <div className="titulo" style={{width:258}}>Area</div>
                                        <div className="titulo">Gerencia</div>
                                    </div>

                                    {
                                        comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR-ITS" && x.estado == true).map((item,i)=>{
                                            return(
                                                <div className="usuarios-item" key={i}>
                                                    <div className="usuarios-item-avatar" style={{marginLeft:-24}}>
                                                        <img src={!item.usuario.foto ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+"/"+item.usuario.id+"/"+item.usuario.foto }
                                                            width={80} style={{borderRadius:100,marginRight:24}}></img>
                                                    </div>
                                                    <div className="usuarios-item-nombre p-eva-labelForm">{item.usuario.nombre}</div>
                                                    <div className="usuarios-item-marcar p-eva-labelForm">{item.usuario.puesto}</div>
                                                    <div className="usuarios-item-gerencia p-eva-labelForm">{item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}</div>
                                                    <div className="usuarios-item-puesto p-eva-labelForm">{item.usuario.gerencia ? item.usuario.gerencia.nombre :""}</div>
                                                </div>
                                            )
                                            
                                        })
                                    }
                                </div>
                            </div>
                            {
                                 comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ITS" && x.estado == true).length > 0 &&
                                 <div className="p-eva-detalleTema-contactCard" >
                                    <Card   header={headerContacto}>
                                        <div className="p-fluid formgrid grid">
                                            {
                                                
                                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO-ITS" && x.estado == true).map((item,i)=>{
                                                            return(
                                                                <div className="temadetalle-item-contactCard" key={i}>
                                                                    <ContactCardV2 
                                                                        idUser={item.usuario.id}
                                                                        area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                                                        gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                                                        foto ={item.usuario.foto}
                                                                        puesto={item.usuario.puesto}
                                                                        nombreUser = {item.usuario.nombre}
                                                                        colorFondo="#F5F5F5"
                                                                        width={328}
                                                                        email={item?.usuario?.correo}
                                                                        fechaNacimiento={item?.usuario?.fechaNacimiento}
                                                                        celular={item?.usuario?.celular}
                                                                        ></ContactCardV2>
                                                                </div>
                                                            )
                                                        })
                                                
                                            }
                                        </div>
                                    </Card>
                                </div>
                            }
                           
                    </Panel>   
            }  
            {/* <Panel 
                className="p-eva-panel-acordeon"
                header={headerHostigamiento} toggleable 
                expandIcon={<Iconsax.ArrowDown2 color="#F47647"></Iconsax.ArrowDown2>} 
                collapseIcon={<Iconsax.ArrowUp2 color="#F47647"></Iconsax.ArrowUp2>}
                collapsed
                >
                <div className="comite-usuario" style={{marginTop:24}}>
                    <div className="p-fluid formgrid grid" >
                        {
                            comite &&
                            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR" && x.estado == true).length > 0 &&
                            comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-COLABORADOR" && x.estado == true).map((item,i)=>{
                                return(
                                    <div className="field col-12 md:col-4" key={i} style={{marginBottom:60}}>
                                        <ContactCard
                                            idUser={item.usuario.idUsuario}
                                            area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                            gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                            foto ={item.usuario.nombreFoto}
                                            puesto={item.usuario.puesto}
                                            nombreUser = {item.usuario.nombre}
                                            colorFondo="#F5F5F5"
                                            width={328}
                                        ></ContactCard>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="p-eva-detalleTema-contactCard" >
                    <Card   header={headerContacto}>
                        <div className="p-fluid formgrid grid">
                            {
                                comite &&
                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO" && x.estado == true).length > 0 &&
                                comite.clima_diversidad_comite_usuario.filter(x=>x.seccion == "HOSTIGAMIENTO-CONTACTO" && x.estado == true).map((item,i)=>{
                                            return(
                                                <div className="temadetalle-item-contactCard" key={i}>
                                                    <ContactCard 
                                                        idUser={item.usuario.idUsuario}
                                                        area={item.usuario.gerencia_area? item.usuario.gerencia_area.nombre:""}
                                                        gerencia={item.usuario.gerencia ? item.usuario.gerencia.nombre :""}
                                                        foto ={item.usuario.nombreFoto}
                                                        puesto={item.usuario.puesto}
                                                        nombreUser = {item.usuario.nombre}
                                                        colorFondo="#F5F5F5"
                                                        width={328}
                                                        ></ContactCard>
                                                </div>
                                            )
                                        })
                                
                            }
                        </div>
                    </Card>
                </div>

                
            </Panel> */}
            
        </div>
     );
}
 
export default Comites;