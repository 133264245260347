import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Iconsax from "iconsax-react";
import ToastEva from "../../../components/Toast/ToastEva";
import { ObtenerCapacitacionLiderazgoPorId } from "../../../service/CapacitacionService";
import { Skeleton } from "primereact/skeleton";
import * as constantes from "../../../constants/constantes";
import parse from 'html-react-parser'
import CardFile from "../../../components/CardFileEva/CardFile";
import "./CapaLiderazgo.scss"
import { Gallery } from "iconsax-react";
const DetalleCapaLiderazgo = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState(null);

    const {permisos,isLogged} = useUsuario()
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [capacitacionLiderazgo, setCapacitacionLiderazgo] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { idCapacitacion} = useParams()
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])
    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("editarCapaLiderazgo") > -1) {
                setPermisoEditar(true);
            }
        };
        if(permisos.length >0)
        {
            permisos.indexOf("verCapaIntroduccion") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos])
    useEffect(()=>{
        const ObtenerPorId = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCapacitacion;
            await ObtenerCapacitacionLiderazgoPorId({jwt,Id}).then(data => 
                {
                    setCapacitacionLiderazgo(data)
                })
        }
        if(!capacitacionLiderazgo){
            ObtenerPorId();
        } 
    },[idCapacitacion])

    const ObtenerUrlTexto =(text)=>{
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        var textoNuevo= text.replace(urlRegex, function (url) {
          var hyperlink = url;
        //   if (!hyperlink.match('^https?:\/\/')) {
        //     hyperlink = 'http://' + hyperlink;
        //   }
        return '<a  className="p-eva-labelForm-orange" target="_blank" href="http://'+hyperlink+'">'+hyperlink+'</a>'
        //   return '<i onClick={()=>redirectLink("'+hyperlink+'")>'+hyperlink+'</i>'
          
        });
        return  <div>{parse(textoNuevo)}</div>
    }
    return ( 
        <div className="eva-detalleCapaLiderazgo" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div className="header-agrupado">
                    <div>
                        <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                            <Iconsax.ArrowLeft color="#000000" />
                        </i>
                    </div>
                    {
                        permisoEditar &&
                        <div className="header-right">
                            <Link to={"../Capacitacion/Seccion/"+idSeccion+"/EditarLiderazgo/"+idCapacitacion}>Editar porgrama de liderazgo</Link>
                        </div>
                    }
                </div>
                
                <div>{capacitacionLiderazgo && <label htmlFor="header">{capacitacionLiderazgo.nombre}</label>}</div>
            </div>
            <div className="p-eva-dashboard-detalleCapaLiderazgo" style={{ marginTop: 32 }}>
                <div className="p-eva-dashboard-videos p-fluid formgrid grid" >
                {
                    capacitacionLiderazgo && 
                    capacitacionLiderazgo.capacitacion_liderazgo_documentos
                    .filter(x=>x.tipo =="UrlVideo"&& x.estado == true).length >0 &&
                        <div className="header-subtitle field col-12 md:col-12"><span>Programa de liderazgo</span></div>
                }
                
                {
                    capacitacionLiderazgo && 
                    capacitacionLiderazgo.capacitacion_liderazgo_documentos
                        .filter(x=>x.tipo =="UrlVideo"&& x.estado == true)
                        .map((item,i)=>{
                            var re = /(?:\.([^.]+))?$/;
                            return (
                                <div className="field col-12 md:col-3" key={i}>
                                    <CardFile key={i} extensionArchivo={"video"} nombreArchivo={item.nombreDocumento} keys={i} tipo="Urls" />
                                </div>
                            );
                        })
                            
                }
                </div>
                <div className="p-eva-dashboard-pdf p-fluid formgrid grid" >
                {
                    capacitacionLiderazgo && 
                    capacitacionLiderazgo.capacitacion_liderazgo_documentos
                    .filter(x=>x.tipo =="Pdf"&& x.estado == true).length >0 &&
                        <div className="header-subtitle field col-12 md:col-12"><span>Descargables</span></div>
                }
                {
                    capacitacionLiderazgo && 
                    capacitacionLiderazgo.capacitacion_liderazgo_documentos
                    .filter(x=>x.tipo =="Pdf"&& x.estado == true)
                    .map((item,i)=>{
                        var re = /(?:\.([^.]+))?$/;
                        return (
                            <div className="field col-12 md:col-3" key={i}>
                                <CardFile urlFile={constantes.URLBLOB_CAPALIDERAZGO + "/" + item.nombreDocumento} 
                                key={i} extensionArchivo={re.exec(item.nombreDocumento)[1]} 
                                nombreArchivo={item.nombreDocumento} 
                                keys={i} tipo="Adjuntos" descargable={true} />
                            </div>
                        );
                    })
                }
                </div>
                <div className="p-eva-dashboard-material p-fluid formgrid grid" >
                {
                    capacitacionLiderazgo && 
                    capacitacionLiderazgo.capacitacion_liderazgo_documentos
                    .filter(x=>x.tipo =="UrlMaterial"&& x.estado == true).length >0 &&
                        <div className="header-subtitle field col-12 md:col-12"><span>Material para repasar</span></div>
                }
                {
                    capacitacionLiderazgo && 
                    capacitacionLiderazgo.capacitacion_liderazgo_documentos
                    .filter(x=>x.tipo =="UrlMaterial"&& x.estado == true)
                    .map((item,i)=>{
                        var re = /(?:\.([^.]+))?$/;
                        return (
                            <div className="field col-12 md:col-6" key={i}>
                                <div className="material-item"><span >{ObtenerUrlTexto(item.nombreDocumento)}</span></div>
                            </div>
                        );
                    })
                }
                </div>
                <div className="p-eva-dashboard-Fotos p-fluid formgrid grid" >
                {
                    capacitacionLiderazgo && 
                    capacitacionLiderazgo.capacitacion_liderazgo_documentos
                    .filter(x=>x.tipo =="Fotos"&& x.estado == true).length >0 &&
                        <div className="header-subtitle field col-12 md:col-12"><span>Fotos del programa</span></div>
                }
                </div>
                <div className="detalleCapaOpcional-adicional">
                    
                        {capacitacionLiderazgo && 
                        capacitacionLiderazgo.test && 
                        capacitacionLiderazgo.test.length > 0 &&
                            <div className="adicional-item">
                               <div style={{marginBottom:16}}><span className="header-subtitle" >Test</span></div>
                               <div><span>{ObtenerUrlTexto(capacitacionLiderazgo.test)}</span></div>
                            </div>
                            
                        }
                    
                    
                        {capacitacionLiderazgo && 
                        capacitacionLiderazgo.encuestaSatisfaccion && 
                        capacitacionLiderazgo.encuestaSatisfaccion.length > 0 &&
                            <div className="adicional-item">
                                <div style={{marginBottom:16}}><span className="header-subtitle" style={{marginBottom:16}}>Encuesta de satisfacción</span></div>
                                <span>{ObtenerUrlTexto(capacitacionLiderazgo.encuestaSatisfaccion)}</span>
                            </div>
                        }
                    
                </div>
            </div>
        </div>
     );
}
 
export default DetalleCapaLiderazgo;