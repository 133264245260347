import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { TabPanel } from "primereact/tabview";
import TabViewEva from "../../../components/TabviewEva/TabviewEva";
import "./Diversidad.scss";
import useUsuario from "../../../hooks/useUsuario";
import { ObtenerListaSeccionClima } from "../../../service/ClimaService";
import Comites from "./Comites/Comites";
import Actividad from "./Actividades/Actividad";
import Politica from "./Politicas/Politica";
const Diversidad = () => {
    const navigate = useNavigate();

    const [tabSelected, setTabSelected] = useState({ nombre: "", navegacion: "" });
    const { permisos, isLogged } = useUsuario();
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [indexTab, setIndexTab] = useState(null);
    const [tituloPagina, setTituloPagina] = useState("");
    let { indexDefault } = useParams();
    let { idSeccionClima } = useParams();

    const AccionPermisos = async (permiso) => {
        if (permisos.indexOf(permiso) > -1) {
            setPermisoEditar(true);
        } else {
            setPermisoEditar(false);
        }
    };
    useEffect(() => {
        if (permisos.length > 0) {
            // permisos.indexOf("verEvaluaciones") > -1 ? AccionPermisos() : setVerEvaluaciones(false)
            permisos.indexOf("verClima") > -1 ?? navigate("/");
        }
    }, [permisos]);

    useEffect(() => {
        if (indexDefault) {
            setIndexTab(indexDefault);
            cambiarAccion(parseInt(indexDefault), "BACK");
        }
    }, [indexDefault]);

    useEffect(() => {
        const obtenerListaSeccionClima = async () => {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({ jwt }).then((data) => {
                let temp = data.filter((x) => x.id == idSeccionClima);

                setTituloPagina(temp[0].nombre);
            });
        };
        if (idSeccionClima) {
            obtenerListaSeccionClima();
        }
    }, [idSeccionClima]);
    const cambiarAccion = (index, origen = "TAB") => {
        switch (index) {
            case 0:
                AccionPermisos("editarClima");
                setTabSelected({ nombre: "Crear nueva actividad", navegacion: "CrearActividad" });
                //if(origen==="TAB")navigate("../Clima/Seccion/"+idSeccion+"/climaLaboral/"+index)
                break;
            case 1:
                AccionPermisos("editarClima");
                setTabSelected({ nombre: "Editar Comités", navegacion: "EditarComites" });
                //if(origen==="TAB")navigate("../Clima/Seccion/"+idSeccion+"/climaLaboral/"+index)

                break;
            case 2:
                AccionPermisos("editarClima");
                setTabSelected({ nombre: "Editar políticas y documentos", navegacion: "EditarPoliticas" });
                //if(origen==="TAB")navigate("../Clima/Seccion/"+idSeccion+"/climaLaboral/"+index)
                break;
            default:
                break;
        }

        if (origen === "TAB") navigate("../Clima/Seccion/" + idSeccionClima + "/DetalleClimaDiversidad/" + index);
    };
    return (
        <div className="eva-dashboard-diversidad" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <div className="eva-dashboard-header header-agrupado">
                <div className="header">
                    <label htmlFor="header">{tituloPagina}</label>
                </div>
                {permisoEditar && (
                    <div className="header-right">
                        <Link to={tabSelected.navegacion}>{tabSelected.nombre}</Link>
                    </div>
                )}
            </div>
            <div className="eva-dashboard-desarrollo-body">
                {indexTab && (
                    <TabViewEva cambiarAccion={cambiarAccion} indexDefault={parseInt(indexTab)}>
                        {
                            //permisos.length >0 && permisos.indexOf("verConvocatorias") > -1 &&
                            <TabPanel header="Programas y actividades">
                                <Actividad></Actividad>
                            </TabPanel>
                        }
                        {
                            //permisos.length >0 && permisos.indexOf("verEvaluaciones") > -1 &&
                            <TabPanel header="Comités">
                                <Comites></Comites>
                            </TabPanel>
                        }

                        {
                            //permisos.length >0 && permisos.indexOf("verHistorias") > -1 &&
                            <TabPanel header="Políticas y documentos">
                                <Politica></Politica>
                            </TabPanel>
                        }
                    </TabViewEva>
                )}
            </div>
        </div>
    );
};

export default Diversidad;
