import { Skeleton } from "primereact/skeleton";
const Sk_LiderIn = () => {
    return (
        <>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
            <div className="field col-12 md:col-3">
                <Skeleton borderRadius="16px" width="100%" height="250px"></Skeleton>
            </div>
        </>
    );
}
 
export default Sk_LiderIn;