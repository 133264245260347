import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import "./CardAniversario.scss"
import * as constantes from "../../../constants/constantes.js";
import { FelicitarUsuarioAniversario,ObtenerUsuariosAniversario } from '../../../service/UsuariosService';
import autosize from 'autosize';
import axios from "axios";
import { ObtenerNotificacionPorId } from '../../../service/PublicacionService';
const CardAniversario = () => {
    const [mostrarCardAniversario, setMostrarCardAniversario] = useState(false);
    const [listaUsuarios, setListaUsuarios] = useState(null);

    const ObtenerAniversario = async () => {
        let jwt = window.localStorage.getItem("jwt");
        await ObtenerUsuariosAniversario({ jwt }).then((data) => {
            if (data.length > 0) {
                setListaUsuarios(data);
                setMostrarCardAniversario(true);
            }
        });
    };
    const sendMessage = async (idDestinoUsuario,idNoti,mensaje="") => {
        return axios.post(constantes.URL_NOTIFICACION+"/messageUser",{
            //GroupName: grupo,
             GroupName: "Administrador General - Plataforma",
            //GroupName: "InLearning",
            ConnectionId:idDestinoUsuario,
            Sender:"WEB222",
            Text: "mensaje enviado por el sistema web",
            Link: constantes.URLSITE_HOME,
            IdNotificacion:idNoti,
            Mensaje:mensaje
        }).then(resp=>resp.data)
    }
    useEffect(() => {
        ObtenerAniversario();
    }, []);
    const header = 
    <div className='p-eva-header-cumpleanios' style={{position:"relative",width:"100%"}}>
        <div className='header-cumpleanios-img' style={{position:"absolute",width:"100%"}}>
            <img src='../../../images/inicio/ANIVERSARIO_EVA.png' width={"100%"}style={{borderTopLeftRadius:16,borderTopRightRadius:16}}></img>
        </div>
        <div  className="header-cardAniversario-titulo" style={{textAlign:"center"}}><label>¡Feliz aniversario!</label></div>
        {/* <div  className="label-form" style={{textAlign:"center"}}><label>Estamos muy felices de compartir un año más juntos</label></div> */}
    </div>
        
        ;
    // const footer = <img src='../../../images/inicio/regalos_x1.png' width={"100%"} style={{borderRadius:16}}></img> 
    const footer =<><div  className="label-form" style={{textAlign:"center" ,marginBottom:16}}><label>Estamos muy felices de compartir un año más juntos</label></div></>


    const ObtenerDetalleNotificacion =async(idDestino,idNoti)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje = data.nombreUsuarioOrigen+" te felicitó por tu aniversario."
        
            sendMessage(idDestino,idNoti,mensaje)
        })  
    } 
    const handleFelicitar=(id)=>{
        let jwt = window.localStorage.getItem("jwt");
        FelicitarUsuarioAniversario({id,jwt}).then((data) => {
            ObtenerAniversario();
            sendMessage(id,data.data.id)
            ObtenerDetalleNotificacion(id,data.data.id)
        });
    }
    return ( 
        <>
        {
       mostrarCardAniversario &&
       <Card header={header} className="p-eva-cardAniversario" footer={footer} style={{overflowY:autosize}}>
       {
           listaUsuarios && 
           listaUsuarios.length >0 &&
           listaUsuarios.map((item,i)=>{
               return(
                   <div className='p-eva-cardAniversario-item' style={{marginBottom:8, width:"100%"}} key={i}>
                       <Avatar image = {item.foto ? constantes.URLBLOB_ADJ_AVATAR+"/"+item.id+"/"+item.foto : constantes.URLAVATARDEFAULT}
                           size={"xlarge"} shape="circle" style={{marginRight:8}}/>
                       <div className='item-nombre'style={{width:"59%",display:"flex",justifyContent:"left"}}><span className='text-sm font-normal' ><b>{item.nombre} </b><br></br>{item.nombreGerencia}<br></br>{item.tiempo +(item.tiempo == 1? " año":" años") }</span></div>
                       {/* <div className='item-link' style={{width:"22%"}}>
                           <span  > 
                               {
                                   item.felicitado ?
                                   <a  style={{justifyContent:"end",color:"#B5B5B5"}}
                                   className="p-eva-labelForm-orange"
                                   >Felicitar</a>
                                   :
                                   <a  style={{justifyContent:"end",cursor:"pointer"}}
                                   className="p-eva-labelForm-orange" onClick={()=>handleFelicitar(item.id)}
                                   >Felicitar</a>
                               }
                               
                           </span></div> */}
                   </div>
               )
           })
       }
   </Card>
   }
   
   </>
  
     );
}
 
export default CardAniversario;