import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../../components/TextArea/TextArea";
import Boton from "../../../../components/Boton/Boton";
import ToastEva from "../../../../components/Toast/ToastEva";
import InputIcon from "../../../../components/InputIcon/InputIcon";
import {ObtenerDiversidadPolitica,ActualizarComitePolitica} from "../../../../service/ClimaService";
import { getBase64 } from "../../../../helpers/helpers";
import InputDefault from "../../../../components/InputDefault/InputDefault";
const EditarPolitica = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState("");
    const [politica, setPolitica] = useState(null);
    const toast = useRef(null);
    let { idPlan } = useParams();
    let { idSeccionClima } = useParams();

    useEffect(()=>{
        const getPolitica = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerDiversidadPolitica({jwt}).then(data => 
                {
                    let temp = []
                    data.listaSeccion.map((item,i)=>{

                        item.clima_diversidad_politica_documento = item.clima_diversidad_politica_documento.filter(x=> x.tipo == "Adjunto" && x.estado == true)
                       
                    })
                    setPolitica(data)

                })
        }
        if(!politica)getPolitica()
    },[])

    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const Actualizar =({jsonPolitica})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarComitePolitica({jsonPolitica,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    const schema = Yup.object().shape({
       
        listaSeccion: Yup.array()
        .of(
            Yup.object().shape({
                nombreSeccion: Yup.string().nullable().required("Campo nombre de sección requerido."),
               
            })
        )
    });
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema:schema,
        initialValues: {
            id:politica&&politica.id,
            descripcion :politica&&politica.descripcion, 
            listaSeccion : politica?politica.listaSeccion : [{nombreSeccion:"",clima_diversidad_politica_documento:[
                {imagenBase64: null, tipoDocumento: null, id: 0, IdClimaDiversidadPolitica: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null }
            ]}], 
            // documentos:politica && politica.clima_diversidad_politica_documento.filter(x=> x.tipo == "Adjunto" && x.estado == true).length >0 ?
            // politica.clima_diversidad_politica_documento.filter(x=> x.tipo == "Adjunto" && x.estado == true):
            // [{ id: 0, IdClimaDiversidadPolitica: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null }],
           
        },
      //validationSchema: schema,
      onSubmit: values => {
    
         let id = values.id
         let descripcion = values.descripcion
         //let listaDocCompleto = values.documentos
         let listaDocCompleto = [];
         values.listaSeccion.map((item,i)=>{
            item.clima_diversidad_politica_documento.map((doc,j)=>{
                listaDocCompleto.push({seccion:item.nombreSeccion,imagenBase64: doc.imagenBase64, tipoDocumento: doc.tipoDocumento,id: doc.id, idClimaDiversidadPolitica: doc.idClimaDiversidadPolitica, nombreDocumento: doc.nombreDocumento, tipo: "Adjunto", estado: true, fechaEliminacion: null, usuarioEliminacion: null})
            })
         })
        
         let jsonPolitica = JSON.stringify({id,descripcion,listaDocCompleto},null,2)
          console.log(jsonPolitica)
        //  formik.setSubmitting(false)
        //  alert(jsonClima);
         Actualizar({jsonPolitica})
        
        
      },
    });

    return (
        <div className="eva-dashboard-editarPolitica" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label htmlFor="header">Editar políticas y documentos</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.errors.descripcion && formik.touched.descripcion ? "error" : "success"}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.errors.descripcion && formik.touched.descripcion}</small>
                            </div>
                            <FieldArray
                                name="listaSeccion"
                                render={(arraySeccion) => (
                                    <>
                                        <div className="field col-12 md:col-6">
                                            <label className="p-eva-labelForm">Agrega documentos</label>
                                        </div>
                                        <div className="field col-12 md:col-6">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => arraySeccion.push({ nombreSeccion: "", clima_diversidad_politica_documento: [{ id: 0, idClimaDiversidadPolitica: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null }] })}
                                                >
                                                    Añadir sección
                                                </i>
                                            </div>
                                        </div>
                                        {formik.values.listaSeccion &&
                                            formik.values.listaSeccion.map((seccion, index) => (
                                                <>
                                                <div className="field col-12 md:col-12" key={index}>
                                                    <label htmlFor={`listaSeccion[${index}].nombreSeccion`}>Nombre sección</label>

                                                    <InputDefault
                                                        id={`listaSeccion[${index}].nombreSeccion`}
                                                        name={`listaSeccion[${index}].nombreSeccion`}
                                                        className="white"
                                                        errorClass={formik.errors.listaSeccion && formik.touched.listaSeccion && formik.errors.listaSeccion[index] && formik.touched.listaSeccion[index] && (formik.errors.listaSeccion[index].nombreSeccion ? "error" : "success")}
                                                        placeholder="Escribe aquí"
                                                        value={formik.values.listaSeccion[index].nombreSeccion}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />

                                                    <small className="p-error">{formik.errors.listaSeccion && formik.touched.listaSeccion && formik.errors.listaSeccion[index] && formik.touched.listaSeccion[index] && formik.errors.listaSeccion[index].nombreSeccion}</small>
                                                </div>
                                                <FieldArray
                                                    name={`listaSeccion[${index}].clima_diversidad_politica_documento`}
                                                    render={(arrayHelpers) => (
                                                        <>
                                                            <div className="field col-12 md:col-8">
                                                                <div className="p-fluid formgrid grid">
                                                                    {formik.values.listaSeccion[index].clima_diversidad_politica_documento &&
                                                                        formik.values.listaSeccion[index].clima_diversidad_politica_documento.map((documento, jindex) => (
                                                                            <div className="field col-12 md:col-6" key={jindex}>
                                                                                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                                    {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                                        <span>{formik.values.documentos[index].nombreDocumento.length > 0 ? formik.values.documentos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                                                    </label> */}
                                                                                    <InputDefault
                                                                                        id={`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].nombreDocumento`}
                                                                                        name={`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].nombreDocumento`}
                                                                                        placeholder="Adjunta aquí"
                                                                                        className="White"
                                                                                        value={formik.values.listaSeccion[index].clima_diversidad_politica_documento[jindex].nombreDocumento}
                                                                                        onChange={(e) => {
                                                                                            formik.values.listaSeccion[index].clima_diversidad_politica_documento[jindex].imagenBase64 && formik.handleChange(e);
                                                                                        }}
                                                                                        onBlur={formik.handleBlur}
                                                                                    ></InputDefault>
                                                                                    {formik.values.listaSeccion[index].clima_diversidad_politica_documento[jindex].nombreDocumento != "" ? (
                                                                                        <label className="eva-labelFileLider-icon">
                                                                                            <i onClick={() => onDeleteFile(jindex, arrayHelpers)} style={{ cursor: "pointer" }}>
                                                                                                <Iconsax.Trash />
                                                                                            </i>
                                                                                        </label>
                                                                                    ) : (
                                                                                        <label htmlFor={`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                                            <i>
                                                                                                <Iconsax.DocumentText1 color="#F47647" />
                                                                                            </i>
                                                                                        </label>
                                                                                    )}

                                                                                    {/* <input id={`documentos[${index}].imagenBase64`}
                                                                                                name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                                    <input
                                                                                        type="file"
                                                                                        id={`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].nombreDocumentoIcon`}
                                                                                        name={`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].nombreDocumentoIcon`}
                                                                                        onChange={(e) => {
                                                                                            formik.setFieldValue(`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                                            formik.setFieldValue(`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                                            getBase64(e.currentTarget.files[0]).then((result) => {
                                                                                                formik.setFieldValue(`listaSeccion[${index}].clima_diversidad_politica_documento[${jindex}].imagenBase64`, result);
                                                                                            });

                                                                                            //onFileChange(e, "document")
                                                                                        }}
                                                                                        accept="application/pdf,audio/*,image/*"
                                                                                        hidden
                                                                                    ></input>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                            <div className="field col-12 md:col-4">
                                                                <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, IdClimaPlan: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                                        Añadir más
                                                                    </i>
                                                                    <label htmlFor={`file-multiple-${index}`} style={{ cursor: "pointer" }}>
                                                                        <i className="p-eva-link-multiple">Añadir múltiple</i>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        id={`file-multiple-${index}`}
                                                                        name={`file-multiple-${index}`}
                                                                        onChange={(e) => {
                                                                            console.log(e.currentTarget.files);
                                                                            let tempFiles = e.currentTarget.files;
                                                                            console.log(tempFiles);
                                                                            for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                                                let nombre = e.currentTarget.files[index].name;
                                                                                let tipo = e.currentTarget.files[index].type;
                                                                                let base64 = "";
                                                                                getBase64(e.currentTarget.files[index]).then((result) => {
                                                                                    base64 = result;
                                                                                    arrayHelpers.push({ imagenBase64: base64, tipoDocumento: tipo, id: 0, IdClimaPlan: 0, nombreDocumento: nombre, tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null });
                                                                                });
                                                                            }
                                                                        }}
                                                                        accept="application/pdf,audio/*,image/*"
                                                                        hidden
                                                                        multiple
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                ></FieldArray>

                                                </>
                                                
                                            ))}
                                    </>
                                )}
                            ></FieldArray>

                            
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting} disabled={!formik.isValid}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default EditarPolitica;