import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import { ObtenerCapacitacionLiderazgoPorId,ActualizarCapacitacionLiderazgo,RegistrarCapacitacionLiderazgo} from "../../../service/CapacitacionService";
import ToastEva from "../../../components/Toast/ToastEva";
import TextareaDefault from "../../../components/TextArea/TextArea";
import InputDefault from "../../../components/InputDefault/InputDefault";
import InputIcon from "../../../components/InputIcon/InputIcon";
import Boton from "../../../components/Boton/Boton";
const EditarCapaLiderazgo = () => {
    const navigate = useNavigate();
    const [tituloPagina, setTituloPagina] = useState("Crear nuevo programa de liderazgo");
    const [modoEdicion, setModoEdicion] = useState(false);
    const [capacitacionLiderazgo, setCapacitacionLiderazgo] = useState(null);
    const toast = useRef(null);
    let { idSeccion } = useParams();
    let { idCapacitacion } = useParams();
    useEffect(()=>{
        const ObtenerPorId = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idCapacitacion;
            await ObtenerCapacitacionLiderazgoPorId({jwt,Id}).then(data => 
                {
                    setCapacitacionLiderazgo(data)
                })
        }
        if(idCapacitacion){
            ObtenerPorId();
        } 
    },[])

    useEffect(()=>{
        if(capacitacionLiderazgo  && !modoEdicion) {
            
            setTituloPagina("Editar programa de liderazgo")
            setModoEdicion(true)
        }
    },[capacitacionLiderazgo])

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";

          let reader = new FileReader();
    
          reader.readAsDataURL(file);
          const regex = /data:.*base64,/
          reader.onload = () => {
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL.replace(regex,""));
          };
          console.log(fileInfo);
        });
      };

    
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }

    const Actualizar =({jsonCapacitacion})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarCapacitacionLiderazgo({jsonCapacitacion,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }


    const Registrar =({jsonCapacitacion})=>{
        let jwt = window.localStorage.getItem("jwt");
        RegistrarCapacitacionLiderazgo({jsonCapacitacion,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro exitoso.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})

        })
    }
    const schema = Yup.object().shape({
        nombre: Yup.string().required("Campo nombre de capacitación requerido."),

    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:modoEdicion && capacitacionLiderazgo?capacitacionLiderazgo.id:0,
            nombre: modoEdicion  && capacitacionLiderazgo?capacitacionLiderazgo.nombre:"",
            test : modoEdicion && capacitacionLiderazgo?capacitacionLiderazgo.test:"",
            encuestaSatisfaccion: modoEdicion && capacitacionLiderazgo?capacitacionLiderazgo.encuestaSatisfaccion:"",
            documentos: modoEdicion  && capacitacionLiderazgo && capacitacionLiderazgo.capacitacion_liderazgo_documentos.length >0 && capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=>x.tipo=="Pdf"&& x.estado == true).length > 0 ? capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=>x.tipo=="Pdf"&& x.estado == true): [{imagenBase64:null,tipoDocumento:null,id:0,
                idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'Pdf',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            documentosFotos: modoEdicion  && capacitacionLiderazgo && capacitacionLiderazgo.capacitacion_liderazgo_documentos.length >0&& capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=>x.tipo=="Fotos"&& x.estado == true).length > 0 ? capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=>x.tipo=="Fotos"&& x.estado == true) : [{imagenBase64:null,tipoDocumento:null,id:0,
                idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'Fotos',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            documentosVideos: modoEdicion  && capacitacionLiderazgo && capacitacionLiderazgo.capacitacion_liderazgo_documentos.length >0 && capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=>x.tipo=="Videos"&& x.estado == true).length > 0?capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=>x.tipo=="Videos"&& x.estado == true) : [{imagenBase64:null,tipoDocumento:null,id:0,
                idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'Videos',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            listaUrlsVideos : modoEdicion  && capacitacionLiderazgo && capacitacionLiderazgo.capacitacion_liderazgo_documentos.length >0 && capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=> x.tipo=="UrlVideo"&& x.estado == true).length >0 ?capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=> x.tipo=="UrlVideo"&& x.estado == true) : [{id:0,
                idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'UrlVideo',estado:false,fechaEliminacion:null,usuarioEliminacion:null}],
            listaUrlsMaterial : modoEdicion  && capacitacionLiderazgo && capacitacionLiderazgo.capacitacion_liderazgo_documentos.length >0 && capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=> x.tipo=="UrlMaterial"&& x.estado == true).length > 0 ?capacitacionLiderazgo.capacitacion_liderazgo_documentos.filter(x=> x.tipo=="UrlMaterial"&& x.estado == true)  
                : [{id:0,idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'UrlMaterial',estado:false,fechaEliminacion:null,usuarioEliminacion:null},
                {id:0,idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'UrlMaterial',estado:false,fechaEliminacion:null,usuarioEliminacion:null},
                {id:0,idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'UrlMaterial',estado:false,fechaEliminacion:null,usuarioEliminacion:null}]
        },
      validationSchema: schema,
      onSubmit: values => {
        let id = values.id
        let nombre = values.nombre
        let test = values.test
        let encuestaSatisfaccion = values.encuestaSatisfaccion
        let listaDocCompleto = values.documentos[0].nombreDocumento.length >0 ? values.documentos :[];
        let listaVideosCompleto = values.documentosVideos[0].nombreDocumento.length >0 ? values.documentosVideos :[];
        let listaFotosCompleto = values.documentosFotos[0].nombreDocumento.length >0 ? values.documentosFotos :[];
        let listaUrlsVideos = values.listaUrlsVideos[0].nombreDocumento.length >0 ? values.listaUrlsVideos :[];
        let listaUrlsMaterial = values.listaUrlsMaterial[0].nombreDocumento.length >0 ? values.listaUrlsMaterial.filter(x=>x.nombreDocumento.length > 0) :[];
        
        
        let jsonCapacitacion = JSON.stringify({id,nombre,test,encuestaSatisfaccion,listaDocCompleto,listaVideosCompleto,listaFotosCompleto,listaUrlsVideos,listaUrlsMaterial},null,2)
         console.log(jsonCapacitacion)
         //formik.setSubmitting(false);
        if(modoEdicion)Actualizar({jsonCapacitacion})
        else{Registrar({jsonCapacitacion})}

        
      },
    });

    return ( 
        <div className="eva-dashboard-editarCapaLiderazgo" style={{ marginLeft: 10 ,width:1082,margin:"Auto"}}>
        <ToastEva ref={toast} position="top-center"></ToastEva>
        <div className="eva-dashboard-header">
            <div>
                <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                    <Iconsax.ArrowLeft color="#000000" />
                </i>
            </div>
            <div>{tituloPagina && <label htmlFor="header">{tituloPagina}</label>}</div>
        </div>
        <div className="eva-dashboard-body">
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-8">
                            <label htmlFor="nombre">Nombre de la capacitación</label>
                            <InputDefault 
                                id="nombre" 
                                name="nombre" 
                                className="white" 
                                placeholder="Escribe aquí" 
                                errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")} 
                                value={formik.values.nombre} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} />
                            <p className="error">{formik.errors.nombre && formik.touched.nombre && formik.errors.nombre}</p>
                        </div>
                        <div className="field col-12 md:col-4" />
                        <div className="field col-12 md:col-12">
                            <label className="p-eva-labelForm">Agrega los videos de las capacitaciones</label>
                        </div>
                        <FieldArray
                            name="documentosVideos"
                            render={(arrayHelpersVideos) => (
                                <>
                                    <div className="field col-12 md:col-8">
                                        <div className="p-fluid formgrid grid">
                                            {formik.values.documentosVideos &&
                                                formik.values.documentosVideos.map((documento, index) => (
                                                    <div className="field col-12 md:col-6" key={index}>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                <span>{formik.values.documentosVideos[index].nombreDocumento.length > 0 ? formik.values.documentosVideos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                            </label>
                                                            {formik.values.documentosVideos[index].nombreDocumento != "" ? (
                                                                <label className="eva-labelFileLider-icon">
                                                                    <i onClick={() => onDeleteFile(index, arrayHelpersVideos)} style={{ cursor: "pointer" }}>
                                                                        <Iconsax.Trash />
                                                                    </i>
                                                                </label>
                                                            ) : (
                                                                <label htmlFor={`documentosVideos[${index}].nombreDocumento`} className="eva-labelFileLider-icon">
                                                                    <i>
                                                                        <Iconsax.VideoPlay color="#F47647" />
                                                                    </i>
                                                                </label>
                                                            )}

                                                            {/* <input id={`documentos[${index}].imagenBase64`}
                                                                        name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                            <input
                                                                type="file"
                                                                id={`documentosVideos[${index}].nombreDocumento`}
                                                                name={`documentosVideos[${index}].nombreDocumento`}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`documentosVideos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                    formik.setFieldValue(`documentosVideos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                    getBase64(e.currentTarget.files[0]).then((result) => {
                                                                        formik.setFieldValue(`documentosVideos[${index}].imagenBase64`, result);
                                                                    });

                                                                    //onFileChange(e, "document")
                                                                }}
                                                                accept="video/*"
                                                                hidden
                                                            ></input>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                            <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersVideos.push({ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionLiderazgo: 0, nombreDocumento: "", tipo: "Videos", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                Añadir más
                                            </i>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></FieldArray>

                        <div className="field col-12 md:col-12">
                            <label className="p-eva-labelForm">Agrega links de videos</label>
                        </div>
                        <FieldArray
                            name="listaUrlsVideos"
                            render={(arrayHelpersLinkVideos) => (
                                <>
                                    <div className="field col-12 md:col-8">
                                        <div className="p-fluid formgrid grid">
                                            {formik.values.listaUrlsVideos &&
                                                formik.values.listaUrlsVideos.map((documento, index) => (
                                                    <div className="field col-12 md:col-6" key={index}>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <InputIcon
                                                                id={`listaUrlsVideos[${index}].nombreDocumento`}
                                                                name={`listaUrlsVideos[${index}].nombreDocumento`}
                                                                className="white"
                                                                placeholder="Ingresa el link aquí"
                                                                errorClass={formik.touched.nombreDocumento != undefined && (formik.errors.nombreDocumento ? "error" : "success")}
                                                                value={formik.values.listaUrlsVideos[index].nombreDocumento}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                icon={formik.values.listaUrlsVideos[index].nombreDocumento != "" && <Iconsax.Trash />}
                                                                onClickIcon={() => onDeleteLink(index, arrayHelpersLinkVideos)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                            <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLinkVideos.push({id:0,
                                            idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'UrlVideo',estado:false,fechaEliminacion:null,usuarioEliminacion:null})}>
                                                Añadir más
                                            </i>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></FieldArray>

                        <div className="field col-12 md:col-12">
                            <label className="p-eva-labelForm">Agrega documentos descargables</label>
                        </div>
                        <FieldArray
                            name="documentos"
                            render={(arrayHelpersdocumentos) => (
                                <>
                                    <div className="field col-12 md:col-8">
                                        <div className="p-fluid formgrid grid">
                                            {formik.values.documentos &&
                                                formik.values.documentos.map((documento, index) => (
                                                    <div className="field col-12 md:col-6" key={index}>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                <span>{formik.values.documentos[index].nombreDocumento.length > 0 ? formik.values.documentos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                            </label>
                                                            {formik.values.documentos[index].nombreDocumento != "" ? (
                                                                <label className="eva-labelFileLider-icon">
                                                                    <i onClick={() => onDeleteFile(index, arrayHelpersdocumentos)} style={{ cursor: "pointer" }}>
                                                                        <Iconsax.Trash />
                                                                    </i>
                                                                </label>
                                                            ) : (
                                                                <label htmlFor={`documentos[${index}].nombreDocumento`} className="eva-labelFileLider-icon">
                                                                    <i>
                                                                        <Iconsax.DocumentText1 color="#F47647" />
                                                                    </i>
                                                                </label>
                                                            )}

                                                            {/* <input id={`documentos[${index}].imagenBase64`}
                                                                        name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                            <input
                                                                type="file"
                                                                id={`documentos[${index}].nombreDocumento`}
                                                                name={`documentos[${index}].nombreDocumento`}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`documentos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                    formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                    getBase64(e.currentTarget.files[0]).then((result) => {
                                                                        formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                    });

                                                                    //onFileChange(e, "document")
                                                                }}
                                                                accept="application/pdf"
                                                                hidden
                                                            ></input>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                            <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersdocumentos.push({ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionLiderazgo: 0, nombreDocumento: "", tipo: "Pdf", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                Añadir más
                                            </i>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></FieldArray>

                        <div className="field col-12 md:col-12">
                            <label className="p-eva-labelForm">Material para repasar</label>
                        </div>
                        <FieldArray
                            name="listaUrlsMaterial"
                            render={(arrayHelpersLinkMaterial) => (
                                <>
                                    <div className="field col-12 md:col-12">
                                        <div className="p-fluid formgrid grid">
                                            {formik.values.listaUrlsMaterial &&
                                                formik.values.listaUrlsMaterial.map((documento, index) => (
                                                    <div className="field col-12 md:col-4" key={index}>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <InputIcon
                                                                id={`listaUrlsMaterial[${index}].nombreDocumento`}
                                                                name={`listaUrlsMaterial[${index}].nombreDocumento`}
                                                                className="white"
                                                                placeholder="Ingresa el link aquí"
                                                                errorClass={formik.touched.nombreDocumento != undefined && (formik.errors.nombreDocumento ? "error" : "success")}
                                                                value={formik.values.listaUrlsMaterial[index].nombreDocumento}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                // icon={formik.values.listaUrlsMaterial[index].nombreDocumento != "" && <Iconsax.Trash />}
                                                                // onClickIcon={() => onDeleteLink(index, arrayHelpersLinkMaterial)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    {/* <div className="field col-12 md:col-4">
                                        <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                            <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLinkMaterial.push({id:0,
                                            idCapacitacionLiderazgo:0,nombreDocumento:'',tipo:'UrlMaterial',estado:false,fechaEliminacion:null,usuarioEliminacion:null})}>
                                                Añadir más
                                            </i>
                                        </div>
                                    </div> */}
                                </>
                            )}
                        ></FieldArray>

                        <div className="field col-12 md:col-12">
                            <label className="p-eva-labelForm">Galeria de fotos</label>
                        </div>
                        <FieldArray
                            name="documentosFotos"
                            render={(arrayHelpersFotos) => (
                                <>
                                    <div className="field col-12 md:col-8">
                                        <div className="p-fluid formgrid grid">
                                            {formik.values.documentosFotos &&
                                                formik.values.documentosFotos.map((documento, index) => (
                                                    <div className="field col-12 md:col-6" key={index}>
                                                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                            <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                <span>{formik.values.documentosFotos[index].nombreDocumento.length > 0 ? formik.values.documentosFotos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                            </label>
                                                            {formik.values.documentosFotos[index].nombreDocumento != "" ? (
                                                                <label className="eva-labelFileLider-icon">
                                                                    <i onClick={() => onDeleteFile(index, arrayHelpersFotos)} style={{ cursor: "pointer" }}>
                                                                        <Iconsax.Trash />
                                                                    </i>
                                                                </label>
                                                            ) : (
                                                                <label htmlFor={`documentosFotos[${index}].nombreDocumento`} className="eva-labelFileLider-icon">
                                                                    <i>
                                                                        <Iconsax.GalleryAdd color="#F47647" />
                                                                    </i>
                                                                </label>
                                                            )}

                                                            {/* <input id={`documentos[${index}].imagenBase64`}
                                                                        name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                            <input
                                                                type="file"
                                                                id={`documentosFotos[${index}].nombreDocumento`}
                                                                name={`documentosFotos[${index}].nombreDocumento`}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`documentosFotos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                    formik.setFieldValue(`documentosFotos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                    getBase64(e.currentTarget.files[0]).then((result) => {
                                                                        formik.setFieldValue(`documentosFotos[${index}].imagenBase64`, result);
                                                                    });

                                                                    //onFileChange(e, "document")
                                                                }}
                                                                accept="image/*"
                                                                hidden
                                                            ></input>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                            <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersFotos.push({ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionLiderazgo: 0, nombreDocumento: "", tipo: "Fotos", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                Añadir más
                                            </i>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></FieldArray>

                        <div className="field col-12 md:col-12">
                            <label htmlFor="nombre">Test</label>
                            <InputDefault 
                                id="test" 
                                name="test" 
                                className="white" 
                                placeholder="Escribe aquí" 
                                errorClass={formik.touched.test != undefined && (formik.errors.test ? "error" : "success")} 
                                value={formik.values.test} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} />
                            <p className="error">{formik.errors.test && formik.touched.test && formik.errors.test}</p>
                        </div>
                        <div className="field col-12 md:col-12">
                            <label htmlFor="encuestaSatisfaccion" className="p-eva-labelForm">
                                Encuesta de satisfacción
                            </label>
                            <TextareaDefault
                                id="encuestaSatisfaccion"
                                minHeight={96}
                                placeholder="Escribe aquí"
                                className="White"
                                errorClass={formik.touched.encuestaSatisfaccion != undefined && (formik.errors.encuestaSatisfaccion ? "error" : "success")}
                                value={formik.values.encuestaSatisfaccion}
                                icon={<Iconsax.DocumentText1 />}
                                name="encuestaSatisfaccion"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                            />
                            <p className="error">{formik.errors.encuestaSatisfaccion && formik.touched.encuestaSatisfaccion && formik.errors.encuestaSatisfaccion}</p>
                        </div>
                    </div>
                    <div className="p-eva-footer-form">
                        <Boton
                            nombre={"Cancelar"}
                            color="secondary"
                            widths="184px"
                            metodoClick={()=>navigate(-1)}
                        ></Boton>
                        <Boton nombre={modoEdicion?"Actualizar":"Registrar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                    </div>  
                </form>
            </FormikProvider>
        </div>
    </div>
     );
}
 
export default EditarCapaLiderazgo;