import React, { useState, useEffect, useRef ,Component} from 'react';
import "./InputPublicacion.scss"
import { InputTextarea } from 'primereact/inputtextarea';
import { useScrapper } from 'react-tiny-link'
import PreviewMedia from './PreviewMedia';
import { Editor } from 'primereact/editor';
import * as Iconsax from "iconsax-react";
import classNames from 'classnames';
// import autosize from 'autosize';
const InputPublicacion = ({placeholder,onblur,value,onChange,srcMedia=null,srcMediaArreglo=[],id,name,iconRight,onclickIcon,iconSend,iconEmoji,onClicEmoji
    ,onClickSend,isValid,isDirty,keys,widthRight=0,clicDeleteImg}) => {
    const sendClassName = classNames({
        // "cursor-pointer": isValid && isDirty,
        // "cursor-not-alowed": !(isValid && isDirty),
        "cursor-pointer": isValid ,
        "cursor-not-alowed": !(isValid),
    });

    const fref = useRef()
    const handleClick =(e)=>{

       //let reaccionSeleccionada ="me gusta"
       onclickIcon(e)
   }
   const handleEditorChange = (e) => {
        const inputValue = e.htmlValue;
        if (inputValue && inputValue.includes('<img')) {
            const textoSinImg = inputValue.replace(/<img[^>]*>/g, '');
            onChange({...e, htmlValue: textoSinImg});
            return;
        }
        onChange(e);
    };
    const editorRef = useRef(null);
    const handlePaste = (event) => {
        // Acceder a los elementos pegados
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;
        
        for (let i = 0; i < items.length; i++) {
            // Si se detecta una imagen, prevenir la acción de pegar
            if (items[i].kind === 'file' && items[i].type.indexOf('image') !== -1) {
                event.preventDefault();
                return;
            }
        }
    };
   //style={{maxWidth:"calc(100% - "+widthRight+"px)"}}
    return ( 
        <div className='p-eva-inputPublicacion-container' style={{minwidth:"100%",maxWidth:"calc(100% - "+widthRight+"px)"}} >
            <div className='display-flex flex-wrap' >
                <div className='comments-comment-box-comment__text-editor' >
                    <div>
                        <div className='editor-container'>
                            <div>
                                <Editor className='conteiner-editor-item' 
                                    id={id} name={name} placeholder={placeholder} 
                                    onBlur={onblur} onTextChange={handleEditorChange}  
                                    onChange={handleEditorChange} 
                                    value={value}
                                    showHeader={false}
                                    ref={editorRef}
                                    onPaste={handlePaste}
                                ></Editor>
                                 
                            </div>
                        </div>
                    </div>
                    
                        
                    {/* <InputTextarea id={id} name={name} placeholder={placeholder}    
                        onBlur={onblur} onChange={onChange}value={value} rows={row} autoResize 
                        style={{width:"100%"}}
                    ></InputTextarea> */}
                    
                </div>
                <div className="display-flex mlA" style={{padding:8}}>
                    <span style={{color:"black",paddingRight:8}} onClick={onClicEmoji}>{iconEmoji}</span>
                    <span style={{color:"black",paddingRight:8}} onClick={onClickSend} className={sendClassName}>{iconSend}</span>
                    <label htmlFor={'file-input'+keys}><span  style={{cursor:"pointer"}}>{iconRight}</span></label>
                    
                    <input id={'file-input'+keys} type="file"   onChange={handleClick} hidden ></input>
                    
                </div>
                
            </div>
            
            {/* {
                srcMedia && <PreviewMedia src={srcMedia} key={keys} deleteImg={clicDeleteImg}></PreviewMedia>
            } */}
            {
                srcMediaArreglo && <PreviewMedia src={srcMediaArreglo} key={keys} deleteImg={clicDeleteImg}></PreviewMedia>
            }
            
           {/* {
            srcMediaArreglo && srcMediaArreglo.map((url, index) => (
    
            <PreviewMedia src={url} key={keys} deleteImg={clicDeleteImg}></PreviewMedia>
             ))
            } */}
        </div>
        
     );
}
 
export default InputPublicacion;