import { useCallback, useContext, useState } from 'react';
import Context from "../context/usuarioContext"
import loginService from "../service/LoginService";
import {CreateUser, UpdateUser,DeleteUser,UsersGerencia,ListUsers}from "../service/UsuariosService";
export default function useUsuario(){
    const {perfil,jwt,setPerfil,setJwt,permisos,configuraciones} = useContext(Context)
    const [state,setState] = useState({loading:false,error:false})
    const [stateCreate,setStateCreate] = useState({loading:false,error:false,success:false})
    const [stateUpdate,setStateUpdate] = useState({loading:false,error:false,success:false})
    const [stateDelete,setStateDelete] = useState({loading:false,error:false,success:false})
    const [usuariosTotal, setUsuariosTotal] = useState([]);
    const [usersGerencias,setUsersGerencias] = useState([]);
    const login = useCallback(async({userName,password},onSuccess) => {
        //setJwt("test")
        await loginService({userName,password})
        .then(res=> {
            const {token,change} = res
            console.log("success: "+token);
            window.localStorage.setItem('jwt',token)
            window.localStorage.setItem('reset',change)
            //window.localStorage.setItem('passTemp',password)//solo para pruebas
            setState({loading:false,error:false})
            setJwt(token)
        })
        .catch(err=>{
            window.localStorage.removeItem('jwt')
            window.localStorage.removeItem('reset')
            setState({loading:false,error:true})
            logout();
            console.error("error: "+err);
            onSuccess()
        })
    },[setJwt])
    const logout = useCallback(()=>{
        window.localStorage.removeItem('jwt')
        window.localStorage.removeItem('reset')
        setJwt(null)
    },[setJwt])

    const CrearUsuario = useCallback(({jsonUsuario})=>{
        CreateUser({jsonUsuario,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateCreate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateCreate({loading:false,error:true,success:false})
        })
    },[setStateCreate])
    const ActualizarUsuario = useCallback(({jsonUsuario})=>{
        UpdateUser({jsonUsuario,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateUpdate({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateUpdate({loading:false,error:true,success:false})
        })
    },[setStateUpdate])
    const EliminarUsuario = useCallback(({id})=>{
        DeleteUser({id,jwt})
        .then(data => {
            console.log("success: "+data);
            setStateDelete({loading:false,error:false,success:true})
        })
        .catch(err =>{
            console.error("error: "+err);
            setStateDelete({loading:false,error:true,success:false})
        })
    },[setStateDelete])

    const UsersUsuarioGerencia =  useCallback(  async({id})=>{
          await UsersGerencia({id,jwt})
        .then(data => {
            setUsersGerencias(data);
        })
        .catch(err =>{
            setUsersGerencias(null)
        })
    },[setStateDelete])

    const ListarUsuarios = useCallback(async ()=>{
        await ListUsers({jwt})
        .then(data=>{
            setUsuariosTotal(data)
        })
        .catch(err=>{
            setUsuariosTotal([])
        })

    },[setUsuariosTotal])

    const refreshPerfil = useCallback(({data})=>{
        setPerfil(data);
    },[setPerfil]);
    return{
        isLogged: Boolean(jwt),
        isloginLoading : state.loading,
        hasLoginError : state.error,
        perfil,
        permisos,
        configuraciones,
        refreshPerfil,
        login,
        logout,
        CrearUsuario,
        ActualizarUsuario,
        EliminarUsuario,
        UsersUsuarioGerencia,
        ListarUsuarios,
        usuariosTotal,
        //ListarUsuariosGerencia,
        hasCreateError : stateCreate.error,
        isCreateLoading: stateCreate.loading,
        isCreated : stateCreate.success,
        hasUpdateError : stateUpdate.error,
        isUpdateLoading: stateUpdate.loading,
        isUpdate : stateUpdate.success,
        hasDeleteError : stateDelete.error,
        isDeleteLoading: stateDelete.loading,
        isDelete : stateDelete.success,
        listUsersGerencia :usersGerencias,
        
    }
}