import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate, json } from "react-router-dom";
import useUsuario from "../../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import "./Encuesta.scss"
import ToastEva from "../../../../components/Toast/ToastEva";
import { ListarEncuestas,ObtenerEncuesta,ActualizarEncuesta } from "../../../../service/ClimaService";
import TextareaDefault from "../../../../components/TextArea/TextArea";
import DropdownDefault from "../../../../components/DropdownDefault/Dropdown";
import CalendarDefault from "../../../../components/CalendarDefault/CalendarDefault";
import InputDefault from "../../../../components/InputDefault/InputDefault";
import Boton from "../../../../components/Boton/Boton";
const EditarEncuesta = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState("Crear actividades y celebraciones");
    const [modoEdicion, setModoEdicion] = useState(true);
    const [encuesta, setEncuesta] = useState(null);
    const [comboEncuesta, setComboEncuesta] = useState(null);
    const toast = useRef(null);
    let { idSeccionClima } = useParams();
    let { idEncuesta } = useParams();

    const mesAnio =[{numeroMes:"01",nombreMes :"Enero"},
    {numeroMes:"02",nombreMes :"Febrero"},
    {numeroMes:"03",nombreMes :"Marzo"},
    {numeroMes:"04",nombreMes :"Abril"},
    {numeroMes:"05",nombreMes :"Mayo"},
    {numeroMes:"06",nombreMes :"Junio"},
    {numeroMes:"07",nombreMes :"Julio"},
    {numeroMes:"08",nombreMes :"Agosto"},
    {numeroMes:"09",nombreMes :"Setiembre"},
    {numeroMes:"10",nombreMes :"Octubre"},
    {numeroMes:"11",nombreMes :"Noviembre"},
    {numeroMes:"12",nombreMes :"Diciembre"}]
    
    useEffect(()=>{
        const getComboEncuesta = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListarEncuestas({jwt}).then(data => 
                {
                    setComboEncuesta(data)

                })
        }
        if(!comboEncuesta)getComboEncuesta()
    },[])

    useEffect(()=>{
        const getEncuesta = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerEncuesta({jwt}).then(data => 
                {
                    setEncuesta(data)

                })
        }
        if(!encuesta)getEncuesta()
    },[])
    const schema = Yup.object().shape({
        descripcion: Yup.string().required("Campor descripción requerido."),
        listaMeses: Yup.array()
        .of(
            Yup.object().shape({
                mes:Yup.string().nullable().required("Campo mes requerido."),
                // idEncuesta: Yup.number().nullable().required("Campo encuesta requerido."),
                // nombreEncuesta:Yup.string().nullable().required("Campo Nombre de Encuesta requerido"),
                fechaFinalizacion: Yup.string().nullable().required("Campo Fecha finalización requerido")
            })
        )
        
    });

    const Actualizar =({jsonEncuesta})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarEncuesta({jsonEncuesta,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: { 
            id:encuesta && encuesta.id,
            descripcion : encuesta && encuesta.descripcion,
            descripcionTimeline : encuesta && encuesta.descripcionTimeline,
            listaMeses : encuesta && encuesta.clima_laboral_encuesta_mes,
            SubTitulo1 : encuesta && encuesta.subTitulo1
        },
      validationSchema: schema,
      onSubmit: values => {
       

        let id = values.id
        let descripcion = values.descripcion
        let descripcionTimeline = values.descripcionTimeline
        let clima_laboral_encuesta_mes = values.listaMeses
        let SubTitulo1 =values.SubTitulo1;
        let jsonEncuesta = JSON.stringify({id,descripcion,descripcionTimeline,clima_laboral_encuesta_mes,SubTitulo1},null,2)
    //    alert(jsonEncuesta)
    //    formik.setSubmitting(false)

        Actualizar({jsonEncuesta})     
      },
    });

    return (
        <div className="eva-dashboard-editarEncuesta" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>
                    <label htmlFor="header">Editar encuestas</label>
                </div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.errors.descripcion && formik.touched.descripcion? "error" : "success"}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.errors.descripcion && formik.touched.descripcion}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="nombre">Agrega el Sutítulo</label>
                                <InputDefault
                                    id="SubTitulo1"
                                    name="SubTitulo1"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.SubTitulo1!= undefined && (formik.errors.SubTitulo1 ? "error" : "success")}
                                    value={formik.values.SubTitulo1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.SubTitulo1 && formik.errors.SubTitulo1}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcionTimeline" className="p-eva-labelForm">
                                    Añade una descripción de linea de tiempo
                                </label>
                                <TextareaDefault
                                    id="descripcionTimeline"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.errors.descripcionTimeline && formik.touched.descripcionTimeline? "error" : "success"}
                                    value={formik.values.descripcionTimeline}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcionTimeline"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.errors.descripcionTimeline && formik.touched.descripcionTimeline}</small>
                            </div>

                            <div className="field col-12 md:col-12 header-subtitle">Encuesta de clima laboral</div>
                            <FieldArray
                                name="listaMeses"
                                render={(arrayMeses) => (
                                    <>
                                        {formik.values.listaMeses &&
                                            formik.values.listaMeses.map((meses, index) => (
                                                <>
                                                    {index != 0 && <div className="field col-12 md:col-12" style={{ height: 2, background: "#000000", opacity: 0.1, marginTop: 10 }}></div>}

                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`listaMeses[${index}].mes`}>Seleccione el mes</label>
                                                        <DropdownDefault
                                                            id={`listaMeses[${index}].mes`}
                                                            name={`listaMeses[${index}].mes`}
                                                            options={mesAnio}
                                                            optionLabel="nombreMes"
                                                            optionValue="numeroMes"
                                                            errorClass={formik.errors.listaMeses && formik.touched.listaMeses &&
                                                                    formik.errors.listaMeses[index] && formik.touched.listaMeses[index]
                                                                    &&(formik.errors.listaMeses[index].mes ? "error" : "success")}
                                                            placeholder="Seleccione"
                                                            value={formik.values.listaMeses[index].mes}
                                                            onChange={formik.handleChange}
                                                            onblur={formik.handleBlur}
                                                        />
                                                        <small className="p-error">{formik.errors.listaMeses && formik.touched.listaMeses 
                                                                                    && formik.errors.listaMeses[index] && formik.touched.listaMeses[index]
                                                                                    && formik.errors.listaMeses[index].mes}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-8">
                                                        <label htmlFor={`listaMeses[${index}].nombreEncuesta`}>Nombre de  encuesta</label>
                                                        {/* <DropdownDefault
                                                            id={`listaMeses[${index}].idEncuesta`}
                                                            name={`listaMeses[${index}].idEncuesta`}
                                                            options={comboEncuesta}
                                                            optionLabel="nombre"
                                                            optionValue="id"
                                                            errorClass={formik.errors.listaMeses && formik.touched.listaMeses &&
                                                                formik.errors.listaMeses[index] && formik.touched.listaMeses[index]
                                                                &&(formik.errors.listaMeses[index].idEncuesta ? "error" : "success")}
                                                            placeholder="Seleccione"
                                                            value={formik.values.listaMeses[index].idEncuesta}
                                                            onChange={formik.handleChange}
                                                            onblur={formik.handleBlur}
                                                        /> */}
                                                        <InputDefault
                                                            id={`listaMeses[${index}].nombreEncuesta`}
                                                            name={`listaMeses[${index}].nombreEncuesta`}
                                                            className="white"
                                                            errorClass={formik.errors.listaMeses && formik.errors.listaMeses[index] && formik.errors.listaMeses[index].nombreEncuesta != undefined && (formik.errors.listaMeses[index].nombreEncuesta ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.listaMeses[index].nombreEncuesta}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        <small className="p-error">{formik.errors.listaMeses && formik.touched.listaMeses 
                                                                                    && formik.errors.listaMeses[index] && formik.touched.listaMeses[index]
                                                                                    && formik.errors.listaMeses[index].nombreEncuesta}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`listaMeses[${index}].fechaFinalizacion`}>Fecha de finalización</label>
                                                        <CalendarDefault
                                                            id={`listaMeses[${index}].fechaFinalizacion`}
                                                            name={`listaMeses[${index}].fechaFinalizacion`}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.listaMeses[index].fechaFinalizacion && new Date(formik.values.listaMeses[index].fechaFinalizacion)}
                                                            errorClass={formik.errors.listaMeses && formik.touched.listaMeses &&
                                                                formik.errors.listaMeses[index] && formik.touched.listaMeses[index]
                                                                &&(formik.errors.listaMeses[index].fechaFinalizacion ? "error" : "success")}
                                                            placeholder="DD /MM/ AAAA"
                                                            //disabled={!enableFProgramar}
                                                            classesname={"white"}
                                                            minDate={new Date()}
                                                            dateFormat="dd/mm/yy"
                                                        ></CalendarDefault>
                                                        <small className="p-error">{formik.errors.listaMeses && formik.touched.listaMeses 
                                                                                    && formik.errors.listaMeses[index] && formik.touched.listaMeses[index]
                                                                                    && formik.errors.listaMeses[index].fechaFinalizacion}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendalistaMesesrio[${index}].link`}>Agrega link de la encuesta</label>

                                                        <InputDefault
                                                            id={`listaMeses[${index}].link`}
                                                            name={`listaMeses[${index}].link`}
                                                            className="white"
                                                            errorClass={formik.errors.listaMeses && formik.errors.listaMeses[index] && formik.errors.listaMeses[index].link != undefined && (formik.errors.listaMeses[index].link ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.listaMeses[index].link}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.listaMeses && formik.errors.listaMeses[index] && formik.errors.listaMeses[index].link}</small>
                                                    </div>
                                                    <div className="field col-12 md:col-4">
                                                        <label htmlFor={`calendalistaMesesrio[${index}].nombreLink`}>Agrega nombre de link de la encuesta</label>

                                                        <InputDefault
                                                            id={`listaMeses[${index}].nombreLink`}
                                                            name={`listaMeses[${index}].nombreLink`}
                                                            className="white"
                                                            errorClass={formik.errors.listaMeses && formik.errors.listaMeses[index] && formik.errors.listaMeses[index].nombreLink != undefined && (formik.errors.listaMeses[index].nombreLink ? "error" : "success")}
                                                            placeholder="Escribe aquí"
                                                            value={formik.values.listaMeses[index].nombreLink}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />

                                                        <small className="p-error">{formik.errors.listaMeses && formik.errors.listaMeses[index] && formik.errors.listaMeses[index].nombreLink}</small>
                                                    </div>
                                                </>
                                                
                                                
                                            ))
                                            
                                        }

                                        <div className="field col-12 md:col-12">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "center" }}>
                                                <i
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        arrayMeses.push({ id: 0, idClimaEncuesta: 0, mes: "", idEncuesta:1, fechaFinalizacion: null, link: "" ,nombreLink:""});
                                                        // setNumeroPregunta(numeroPregunta+1)
                                                    }}
                                                >
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                                >

                                </FieldArray>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    );
}
 
export default EditarEncuesta;