import React, { useState, useEffect, useRef, useCallback } from "react";
import ModalEva from "../../Modal/ModalEva";
import InputPublicacion from "./InputPublicacion";
import { replace, useFormik } from 'formik';
import * as Yup from "yup";
import "./CrearCompartir.scss"
import * as constantes from "../../../constants/constantes.js";

import Boton from "../../Boton/Boton";
import * as Iconsax from "iconsax-react";
import usePublicacion from "../../../hooks/usePublicacion";
import axios from "axios";
import CardPublicacionCompartida from "./CardPublicacionCompartida";
import { ObtenerNotificacionPorId, Registrar } from "../../../service/PublicacionService";
const CrearCompartir = ({publicacion, mostrar, onHide, IdGrupo, nombrGrupo}) => {

    // const {RegistrarPublicacion,isCreated,isUpdate} = usePublicacion();
    const {isCreated,isUpdate} = usePublicacion();

    // const sendMessage = async (grupo) => {
    //     return await axios.post(constantes.URL_NOTIFICACION+"/messageGroup",{
    //         GroupName: grupo,
    //         // GroupName: "Administrador General - Plataforma",
    //         //GroupName: "InLearning",
    //         ConnectionId:"1",
    //         Sender:"WEB",
    //         Text: "mensaje enviado por el sistema web"
    //     }).then(resp=>resp.data)
    // }

    const sendMessageGroup = async (grupo,idNoti,idGrupo,idPublicacion,ruta,detalle="",mensaje="") => {
        return await axios.post(constantes.URL_NOTIFICACION+"/messageGroup",{
            GroupName: grupo,
            ConnectionId:"1",
            Sender:"WEB",
            Text: "mensaje enviado por el sistema web",
            IdNotificacion:idNoti,
            Link:constantes.URLSITE_PUBLICACION+idGrupo+"/Publicaciones/"+idPublicacion,
            RutaImagen:constantes.URLBLOB_PUBLICACIONES+"/"+idPublicacion+"/"+ruta,
            Detalle:detalle,
            Mensaje:mensaje
        }).then(resp=>resp.data)
    }
    const sendMessage = async (idDestinoUsuario,idNoti,idGrupo,idPublicacion,ruta,detalle  ="",mensaje="") => {
        return axios.post(constantes.URL_NOTIFICACION+"/messageUser",{
            //GroupName: grupo,
             GroupName: "Administrador General - Plataforma",
            //GroupName: "InLearning",
            ConnectionId:idDestinoUsuario,
            Sender:"WEB222",
            Text: "mensaje enviado por el sistema web",
            Link:  constantes.URLSITE_PUBLICACION+idGrupo+"/Publicaciones/"+idPublicacion,
            IdNotificacion:idNoti,
            RutaImagen: constantes.URLBLOB_PUBLICACIONES+"/"+idPublicacion+"/"+ruta,
            Detalle :detalle,
            Mensaje: mensaje
        }).then(resp=>resp.data)
    }
    
    const ObtenerDetalleNotificacion =async(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje="";
            if(data.tipoNotificacion == "PUBLICACION")
            {
                mensaje =data.nombreUsuarioOrigen+ " Subió una publicación en el grupo "+data.nombreGrupo+ "."
            }
            else if(data.tipoNotificacion == "APROBACION")
            {
                mensaje ="Tiene publicaciones pendiente de aprobación en el grupo "+data.nombreGrupo+ "."
            }
           
            sendMessageGroup(grupo,idNoti,idGrupo,idPublicacion,ruta,detalle,mensaje)
        })  
    } 
    const ObtenerDetalleNotificacionUsuario =async(idDestino,idNoti,idGrupo,idPublicacion,ruta,detalle)=>{
        let jwt = window.localStorage.getItem("jwt");
        let id = idNoti;
        await ObtenerNotificacionPorId({jwt,id}).then(data=>{
            let mensaje=data.nombreUsuarioOrigen+ " Compartió tu publicación.";
            
            sendMessage(idDestino,idNoti,idGrupo,idPublicacion,ruta,detalle,mensaje)
        })  
    } 

    const RegistrarPub =({jsonPublicacion})=>{
        let temp = JSON.parse( jsonPublicacion );
        let jwt = window.localStorage.getItem("jwt");
        Registrar({jsonPublicacion,jwt}).then(data=>{
            Promise.all([
                ObtenerDetalleNotificacion("Administrador General - Plataforma",
                                            data.data.id,
                                            data.data.idGrupo,
                                            data.data.idTipoTransaccion,
                                            data.data.imagen,
                                            temp.contenidoPublicacion),
                ObtenerDetalleNotificacion(nombrGrupo,
                                            data.data.id,
                                            data.data.idGrupo,
                                            data.data.idTipoTransaccion,
                                            data.data.imagen,
                                            temp.contenidoPublicacion),
                ObtenerDetalleNotificacionUsuario(publicacion.idUsuarioRegistro,
                                                    data.data.id,
                                                    data.data.idGrupo,
                                                    data.data.idTipoTransaccion,
                                                    data.data.imagen,
                                                    temp.contenidoPublicacion)
                // sendMessageGroup("Administrador General - Plataforma",data.data.id,data.data.idGrupo,data.data.idTipoTransaccion,data.data.imagen),
                //sendMessageGroup(nombrGrupo,data.data.id,data.data.idGrupo,data.data.idTipoTransaccion,data.data.imagen)
            ]).then(setTimeout(() => {
                onHide();
            }, 3000) )
            
            
        })
        // .catch(errors => {
        //     // toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
        //     // formik.setSubmitting(false)
        //     handleError(errors.message);handleDescartar(formik);formik.setSubmitting(false);
        // })
    }

    const formik = useFormik({
        initialValues: {
        contenido:'',
        
      },
    //   validationSchema: Yup.object({
    //       contenido: Yup.string().required("Mensaje de publicación requerido.")
          
    //     }),
      onSubmit: values => {
        let id = 0;
        let idGrupo = IdGrupo;
        let idPublicacionCompartida = publicacion.id;
        let contenidoPublicacion = values.contenido;
        
    
        let jsonPublicacion = JSON.stringify({ id,idGrupo, idPublicacionCompartida, contenidoPublicacion});
        //alert(jsonPublicacion)
        RegistrarPub({jsonPublicacion})
      },
    });

    const footerModal = 
        <form onSubmit={formik.handleSubmit}>
             <div className="p-modal-footer" style={{width:"100%"}}>
                <Boton  nombre={"Publicar"} color="primary" widths="30%" 
                    // disabled={!(formik.isValid && formik.dirty)} 
                    type="submit"></Boton>
            </div>;
        </form>
       
    const contenidoModal = (
        <form>
            <InputPublicacion
                placeholder={"¿Qué quieres compartir, "+publicacion.nombreUsuario.split(" ")[0]}
                //onChange={(e) => handleChange(e.htmlValue)}
                //value={mensajePublicacion}
                id="contenido"
                name="contenido"
                // onChange={formik.handleChange}
                onChange={(e) => formik.setFieldValue("contenido", e.textValue.replace(/\n/g, ""))}
                onblur={formik.handleBlur}
                value={formik.values.contenido}
                widthRight={0}
            ></InputPublicacion>
            <CardPublicacionCompartida publicacion={publicacion}></CardPublicacionCompartida>
            
        </form>
    );
            
    ;

    return ( 
        <ModalEva mostrar={mostrar} onHide={onHide}  footer={footerModal} titulo="Compartir publicación" tamañoTitulo={"text-3xl"}
        contenido={contenidoModal} widths={600} iconoBack={<Iconsax.ArrowLeft></Iconsax.ArrowLeft>} nombreEtiqueta={nombrGrupo} ></ModalEva>
     );
}
 
export default CrearCompartir;