import React,{useEffect,useState} from "react";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import "./Comentarios.scss"
import * as constantes from "../../../constants/constantes.js";
import { ObtenerTiempoTranscurrido } from "../../../helpers/helpers";
import PreviewMedia from "../../PreviewMedia/PreviewMedia";
import * as Iconsax from "iconsax-react";
const Comentarios = ({id,nombreUsuario,contenido,idusuario,fotoUsuario,fechaComentario,nombreImagen,fechaActual,clicEliminar,esAdmin}) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const header =
        <div className="cardComentario p-eva-tituloCard" style={{marginBottom:8}} >
            {esAdmin && <span className="cardComentario-trash" onClick={clicEliminar}><Iconsax.Trash size={24} color="#F47647"/></span>}
            <span className="titulo" style={{marginRight:8}}>{nombreUsuario}</span>
            <span className="p-eva-labelForm-grey">{ObtenerTiempoTranscurrido(fechaActual,fechaComentario)}</span>
            
        </div> 
    return ( 
        <>
             <div className="cardPublicacion-comentarios-item" style={{marginTop:8,minWidth:"100%"}}>
             <Avatar image = {fotoUsuario?constantes.URLBLOB_ADJ_AVATAR+"/"+idusuario+"/"+fotoUsuario: constantes.URLAVATARDEFAULT}
                        size={"large"} shape="circle" style={{marginRight:8,marginTop:12}}
                    />
            <div className="comentario-detalle">
                <Card header={header} className="grey" >
                    <div className="cardComentarioDetalle">
                    <div className="p-eva-cardPublicacion-contenido p-eva-text" style={{whiteSpace:"pre-line",color:"#000000"}}
                    dangerouslySetInnerHTML={{ __html: contenido }}
                    ></div>
                        {/* <span className="p-eva-text" >{contenido}</span> */}
                        {
                            nombreImagen &&
                                <div className="p-eva-comentario-img">
                                    <img src={constantes.URLBLOB_COMENTARIOS+"/"+id+"/"+nombreImagen} onClick={()=>setVisibleModal(true)} style={{cursor:"pointer"}}></img>
                                </div>
                        }
                    </div>
                </Card>
            </div>
            </div>
            {
                visibleModal&&
                <PreviewMedia visibleModal={visibleModal} urlFile={constantes.URLBLOB_COMENTARIOS+"/"+id+"/"+nombreImagen} 
                onHide={()=>setVisibleModal(false)}></PreviewMedia>
            }
        </>
       
     );
}
 
export default Comentarios;