import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUsuario from "../../../hooks/useUsuario";
import * as Yup from "yup";
import * as Iconsax from "iconsax-react";
import { Field,FieldArray, Formik ,useFormik,FormikProvider} from "formik";
import TextareaDefault from "../../../components/TextArea/TextArea";
import Boton from "../../../components/Boton/Boton";
import ToastEva from "../../../components/Toast/ToastEva";
import InputIcon from "../../../components/InputIcon/InputIcon";
import {ObtenerActividadesCelebracionPorId,ActualizarClimaCelebracion} from "../../../service/ClimaService";
import { getBase64 } from "../../../helpers/helpers";
import InputDefault from "../../../components/InputDefault/InputDefault";
import classNames from "classnames";
const EditarEvento = () => {
    const navigate = useNavigate();
    const {configuraciones} = useUsuario();

    const [tituloPagina, setTituloPagina] = useState("");
    const [evento, setEvento] = useState(null);
    const toast = useRef(null);
    let { idEvento } = useParams();
    let { idSeccionClima } = useParams();

    useEffect(()=>{
        const getEvento = async ()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            let Id = idEvento
            await ObtenerActividadesCelebracionPorId({jwt,Id}).then(data => 
                {
                    setTituloPagina(data.nombre)
                    setEvento(data)
                })
        }

        if(idEvento) getEvento()
    },[idEvento])
    const onDeleteFile =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }
    const onDeleteLink =(i,arrayHelpers)=>{
        arrayHelpers.remove(i);
    }

    const Actualizar =({jsonClima})=>{
        let jwt = window.localStorage.getItem("jwt");
        ActualizarClimaCelebracion({jsonClima,jwt}).then(data=>{
            formik.setSubmitting(false)
            toast.current.show({severity:'success', summary: 'Success', detail:"Registro actualizado exitosamente.", life: 7000})


            setTimeout(() => {
                navigate(-1);
            }, 3000)
        })
        .catch(errors => {
            toast.current.show({severity:'error', summary: 'Error', detail:errors.message, life: 7000})
            formik.setSubmitting(false)
        })
    }
    const schema = Yup.object().shape({
        nombre: Yup.string().required("Campo nombre de la actividad requerido."),
        descripcion: Yup.string().required("Campo descripción requerido."),

    });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            imagenBase64:null,tipoDocumento:null,
            id:evento&&evento.id,
            idClimaActividad:evento&&evento.idClimaActividad,
            nombre:evento&&evento.nombre,
            nombreFoto : evento&&(evento.nombreFoto ? evento.nombreFoto: ""),
            descripcion :evento&&evento.descripcion, 
            seccion :evento&&evento.seccion,
            documentos:evento && evento.clima_actividades_celebracion_documento.filter(x=> x.tipo == "Adjunto" && x.estado == true),
            listaUrls:evento && evento.clima_actividades_celebracion_documento.filter(x=> x.tipo == "Url" && x.estado == true),
        },
      //validationSchema: schema,
      onSubmit: values => {
         let imagenBase64 = values.imagenBase64
         let tipoDocumento = values.tipoDocumento
         let id = values.id
         let idClimaActividad = values.idClimaActividad
         let nombre = values.nombre
         let nombreFoto = values.nombreFoto
         let descripcion = values.descripcion
         let seccion = values.seccion
         let listaDocCompleto = values.documentos
         let listaUrls = values.listaUrls
        
         let jsonClima = JSON.stringify({imagenBase64,tipoDocumento,id,idClimaActividad,nombre,nombreFoto,descripcion,seccion,listaDocCompleto,listaUrls},null,2)
        // //  console.log(jsonCapacitacion)
        //  formik.setSubmitting(false)
        //  alert(jsonClima);
         Actualizar({jsonClima})
        
        
      },
    });

    return (
        <div className="eva-dashboard-editarEvento" style={{ marginLeft: 40, width: 1082, margin: "Auto" }}>
            <ToastEva ref={toast} position="top-center"></ToastEva>
            <div className="eva-dashboard-header">
                <div>
                    <i href="#" onClick={() => navigate(-1)} className="p-eva-icon-atras">
                        <Iconsax.ArrowLeft color="#000000" />
                    </i>
                </div>
                <div>{tituloPagina && <label htmlFor="header">{"Editar " + tituloPagina}</label>}</div>
            </div>
            <div className="eva-dashboard-body">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="nombre">Nombre</label>
                                <InputDefault
                                    id="nombre"
                                    name="nombre"
                                    className="white"
                                    placeholder="Escribe aquí"
                                    errorClass={formik.touched.nombre != undefined && (formik.errors.nombre ? "error" : "success")}
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.touched.nombre && formik.errors.nombre}</small>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="nombreFoto">Adjunta una foto</label>
                                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                    <label
                                        className={classNames("eva-labelFileLider", {
                                            "p-eva-error": formik.errors.nombreFoto,
                                        })}
                                        style={{ minHeight: 16, height: "fit-content" }}
                                    >
                                        <span>{formik.values.nombreFoto && formik.values.nombreFoto.length > 0 ? formik.values.nombreFoto : "Adjunta aquí"}</span>
                                    </label>
                                    {formik.values.nombreFoto != "" ? (
                                        <label className="eva-labelFileLider-icon">
                                            <i onClick={() => {
                                                    formik.setFieldValue(`nombreFoto`, "");
                                                    formik.setFieldValue(`imagenBase64`, null);
                                            }}>
                                                <Iconsax.Trash />
                                            </i>
                                        </label>
                                    ) : (
                                        <label htmlFor={`nombreFoto`} className="eva-labelFileLider-icon">
                                            <i>
                                                <Iconsax.GalleryAdd color="#F47647" />
                                            </i>
                                        </label>
                                    )}

                                    <input
                                        type="file"
                                        id={`nombreFoto`}
                                        name={`nombreFoto`}
                                        onChange={(e) => {
                                            formik.setFieldValue(`nombreFoto`, e.currentTarget.files[0].name);
                                            formik.setFieldValue(`tipoDocumento`, e.currentTarget.files[0].type);
                                            getBase64(e.currentTarget.files[0]).then((result) => {
                                                formik.setFieldValue(`imagenBase64`, result);
                                            });

                                            //onFileChange(e, "document")
                                        }}
                                        accept="application/pdf,audio/*,image/*"
                                        hidden
                                    ></input>
                                </div>
                                <small className="p-error">{formik.errors.imagenBase64 && formik.touched.imagenBase64}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion" className="p-eva-labelForm">
                                    Añade una descripción
                                </label>
                                <TextareaDefault
                                    id="descripcion"
                                    minHeight={180}
                                    placeholder="Escribe aquí"
                                    className="White"
                                    errorClass={formik.errors.descripcion && formik.touched.descripcion ? "error" : "success"}
                                    value={formik.values.descripcion}
                                    icon={<Iconsax.DocumentText1 />}
                                    name="descripcion"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                />
                                <small className="p-error">{formik.errors.descripcion && formik.touched.descripcion}</small>
                            </div>
                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega documentos</label>
                            </div>
                            <FieldArray
                                name="documentos"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.documentos &&
                                                    formik.values.documentos.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                {/* <label className={"eva-labelFileLider"} style={{ minHeight: 16, height: "fit-content" }}>
                                                                    <span>{formik.values.documentos[index].nombreDocumento.length > 0 ? formik.values.documentos[index].nombreDocumento : "Adjunta aquí"}</span>
                                                                </label> */}
                                                                <InputDefault
                                                                    id={`documentos[${index}].nombreDocumento`}
                                                                    name={`documentos[${index}].nombreDocumento`}
                                                                    placeholder="Adjunta aquí"
                                                                    className="White"
                                                                    value={formik.values.documentos[index].nombreDocumento}
                                                                    onChange={(e)=>{formik.values.documentos[index].imagenBase64 && formik.handleChange(e)}}
                                                                    onBlur={formik.handleBlur}
                                                                ></InputDefault>
                                                                {formik.values.documentos[index].nombreDocumento != "" ? (
                                                                    <label className="eva-labelFileLider-icon">
                                                                        <i onClick={() => onDeleteFile(index, arrayHelpers)} style={{ cursor: "pointer" }}>
                                                                            <Iconsax.Trash />
                                                                        </i>
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={`documentos[${index}].nombreDocumentoIcon`} className="eva-labelFileLider-icon">
                                                                        <i>
                                                                            <Iconsax.DocumentText1 color="#F47647" />
                                                                        </i>
                                                                    </label>
                                                                )}

                                                                {/* <input id={`documentos[${index}].imagenBase64`}
                                                                            name={`documentos[${index}].imagenBase64`} hidden></input> */}
                                                                <input
                                                                    type="file"
                                                                    id={`documentos[${index}].nombreDocumentoIcon`}
                                                                    name={`documentos[${index}].nombreDocumentoIcon`}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`documentos[${index}].nombreDocumento`, e.currentTarget.files[0].name);
                                                                        formik.setFieldValue(`documentos[${index}].tipoDocumento`, e.currentTarget.files[0].type);
                                                                        getBase64(e.currentTarget.files[0]).then((result) => {
                                                                            formik.setFieldValue(`documentos[${index}].imagenBase64`, result);
                                                                        });

                                                                        //onFileChange(e, "document")
                                                                    }}
                                                                    accept="application/pdf,audio/*,image/*"
                                                                    hidden
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpers.push({ imagenBase64: null, tipoDocumento: null, id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                                <label htmlFor="file-multiple" style={{ cursor: "pointer" }}><i className="p-eva-link-multiple">Añadir múltiple</i></label>
                                                <input
                                                    type="file"
                                                    id={`file-multiple`}
                                                    name={`file-multiple`}
                                                    onChange={(e) => {
                                                        console.log(e.currentTarget.files);
                                                        let tempFiles= e.currentTarget.files
                                                        console.log(tempFiles)
                                                        for (let index = 0; index < e.currentTarget.files.length; index++) {
                                                            let nombre = e.currentTarget.files[index].name;
                                                            let tipo = e.currentTarget.files[index].type;
                                                            let base64  =""
                                                            getBase64(e.currentTarget.files[index]).then((result) => {
                                                                base64 = result;
                                                                arrayHelpers.push({ imagenBase64: base64, tipoDocumento: tipo, id: 0, idCapacitacionMia: 0, nombreDocumento:nombre, tipo: "Adjunto", estado: false, fechaEliminacion: null, usuarioEliminacion: null })
                                                            });
                                                        }
                                                      
                                                    }}
                                                    accept="application/pdf,audio/*,image/*"
                                                    hidden
                                                    multiple
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>

                            <div className="field col-12 md:col-12">
                                <label className="p-eva-labelForm">Agrega links de videos</label>
                            </div>
                            <FieldArray
                                name="listaUrls"
                                render={(arrayHelpersLink) => (
                                    <>
                                        <div className="field col-12 md:col-8">
                                            <div className="p-fluid formgrid grid">
                                                {formik.values.listaUrls &&
                                                    formik.values.listaUrls.map((documento, index) => (
                                                        <div className="field col-12 md:col-6" key={index}>
                                                            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                                <InputIcon
                                                                    id={`listaUrls[${index}].nombreDocumento`}
                                                                    name={`listaUrls[${index}].nombreDocumento`}
                                                                    className="white"
                                                                    placeholder="Ingresa el link aquí"
                                                                    errorClass={formik.touched.nombreDocumento != undefined && (formik.errors.nombreDocumento ? "error" : "success")}
                                                                    value={formik.values.listaUrls[index].nombreDocumento}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    icon={formik.values.listaUrls[index].nombreDocumento != "" && <Iconsax.Trash />}
                                                                    onClickIcon={() => onDeleteLink(index, arrayHelpersLink)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <div className="p-eva-linkText" style={{ display: "flex", justifyContent: "start" }}>
                                                <i style={{ cursor: "pointer" }} onClick={() => arrayHelpersLink.push({ id: 0, idCapacitacionMia: 0, nombreDocumento: "", tipo: "Url", estado: false, fechaEliminacion: null, usuarioEliminacion: null })}>
                                                    Añadir más
                                                </i>
                                            </div>
                                        </div>
                                    </>
                                )}
                            ></FieldArray>
                        </div>
                        <div className="p-eva-footer-form">
                            <Boton nombre={"Cancelar"} color="secondary" widths="184px" metodoClick={() => navigate(-1)}></Boton>
                            <Boton nombre={"Actualizar"} color="primary" widths="184px" type="submit" loading={formik.isSubmitting}></Boton>
                        </div>
                    </form>
                </FormikProvider>
            </div>
           
        </div>
    );
}
 
export default EditarEvento;