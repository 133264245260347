import React, { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import "./TextArea.scss"
import classNames from "classnames";

const TextareaDefault = ({id,value,onChange,onblur,options,optionLabel,placeholder,className,name,errorClass,height,disabled,minHeight})=>
{
    const dropdownClassName = classNames("p-eva-textarea", {
        "white":className === "white" ? true:false,
        "grey" : className === "grey" ? true:false,
        "p-eva-error" : errorClass === "error" ? true:false,
        "p-eva-success" : errorClass === "success" ? true:false,
    });
    return (
        <InputTextarea
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onblur}
            placeholder={placeholder}
            className={dropdownClassName}
            name={name}
            style={{height:height,width:"100%",minHeight:minHeight}}
            autoResize
            disabled={disabled}
        />
    );
}

export default TextareaDefault;