import React, { useState } from "react";
import "./ContactCard.scss";
import { Card } from 'primereact/card';
import Boton from "../Boton/Boton";
import * as Iconsax from "iconsax-react";
import * as constantes from "../../constants/constantes.js";
import PerfilUsuario from "../PerfilUsuario/PerfilUsuario";

const ContactCardV2 =({foto="", idUser="", nombreUser="",gerencia="",area ="",puesto ="",email="",colorFondo="#FFFFFF",width=344,metodoClick,fechaNacimiento,celular,sombreado})=>{
    const [mostrarSlideUsuario, setMostrarSlideUsuario] = useState(false);
   console.log("fechaNacimiento",fechaNacimiento)
    var fechaFormateada = ""
    if (fechaNacimiento != null && fechaNacimiento != "") {
        var fecha = new Date(fechaNacimiento);
        var meses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        var dia = fecha.getDate().toString().padStart(2, '0');
        var mes = meses[fecha.getMonth()];
        fechaFormateada = `${dia} de ${mes}`;
    }

    return (
        <>
            <Card className={`eva-card-contact ${sombreado==true?'sombreado':''}`} style={{backgroundColor:colorFondo,width:width,cursor:"pointer"}} >
                <div className="eva-card-contact-profile">
                    {/* <img className="eva-card-contact-image" src={foto === null || foto === "" ? constantes.URLAVATARDEFAULT : constantes.URLAPI + "/Image/perfil/" + foto + "/" + idUser}></img> */}
                    <img className="eva-card-contact-image" src={foto === null || foto === "" ? constantes.URLAVATARDEFAULT : constantes.URLBLOB_ADJ_AVATAR+ "/" + idUser + "/" + foto}></img>
                </div>
                <div className="eva-card-contact-profile-name"/* onClick={()=>setMostrarSlideUsuario(true)} */>
                    <span style={{textAlign:"left " ,textAlign:"left"}}>{nombreUser}</span>
                </div>
                <div className="contenido-div-center">
                    <div className="eva-card-contact-profile-puesto">
                        <div><img src="../../../../images/directorio/puesto.svg" width={20}height={20} alt="" /></div>
                        <div><span style={{float:"left" ,textAlign:"left"}} >{puesto}</span></div>
                    </div>
                    <div className="eva-card-contact-profile-area">
                        <div><img src="../../../../images/directorio/area2.svg" width={20} height={20}alt="" /></div>
                        <div><span style={{float:"left",textAlign:"left"}} >{area}</span></div>
                    </div>
                    <div className="eva-card-contact-profile-gerencia">
                        <div><img src="../../../../images/directorio/gerencia2.svg" width={20}height={20} alt="" /></div>
                        <div><span style={{float:"left",textAlign:"left"}} >{gerencia}</span></div>
                    </div>
                    {email && email.trim() !== "" && (
                    <div className="eva-card-contact-profile-gerencia">
                        <div><img src="../../../../images/directorio/Correo.svg" width={20}height={20} alt="" /></div>
                        <div><span style={{float:"left",textAlign:"left"}} >{email}</span></div>
                    </div>
                    )}
                    {/* {fechaNacimiento && fechaNacimiento.trim() !== "" && (
                    <div className="eva-card-contact-profile-gerencia">
                        <img src="../../../../images/directorio/Cumpleanios.svg" width={22} alt="" />
                        <span>{fechaNacimiento}</span>
                    </div>
                    )} */}
                    {fechaFormateada && fechaFormateada.trim() !== "" && (
                    <div className="eva-card-contact-profile-gerencia">
                        <div><img src="../../../../images/directorio/Cumpleanios.svg" width={20} height={20} alt="" /></div>
                        <div><span style={{float:"left", marginTop: "6px", paddingBottom :"0px !important" ,textAlign:"left"}} >{fechaFormateada}</span></div>
                    </div>
                    )}
                    {celular && celular.trim() !== "" && (
                        <div className="eva-card-contact-profile-gerencia">
                            <div>
                                {/* <i className="eva-card-contact-profile-icon">
                                    <Iconsax.Mobile color="#F47647" />
                                </i> */}
                                <div><img src="../../../../images/directorio/Telefono.svg" width={20} height={20} alt="" /></div>
                                </div>
                            <div><span style={{float:"left", marginTop:"5px" ,textAlign:"left"}} >{celular}</span></div>
                        </div>
                    )}
                </div>
            </Card>
           {/*  {mostrarSlideUsuario && <PerfilUsuario visible={mostrarSlideUsuario} onHide={() => setMostrarSlideUsuario(false)} id={idUser}></PerfilUsuario>} */}
        </>
        
    );
}

export default ContactCardV2;