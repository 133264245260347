import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import useUsuario from "../../hooks/useUsuario";
import { Button } from "primereact/button";
import { useLocation,useNavigate } from "react-router-dom";
import Slider from "../../components/slider/Slider";
import "../../components/Inicio/FormsEva.scss"
import "./Login.scss";
import Boton from "../../components/Boton/Boton";
import * as Iconsax from "iconsax-react";
import InputDefault from "../../components/InputDefault/InputDefault"
import InputIcon from "../../components/InputIcon/InputIcon"
//const Login = () => {
//const Login = () =>{
export default function Login() {
    const [checked, setChecked] = useState(false);
    // const [username, setUserName] = useState("");
    // const [password, setPassword] = useState("");
    const setPassword = useState("");
    const setUserName = useState("");
    const { isloginLoading,hasLoginError,login, isLogged } = useUsuario();
    const navigate = useNavigate();
    //function navigateTo (){navigate.push('/Inicio');}//eg.history.push('/login');
    useEffect(() => {
        //if (isLogged) window.location = "#/Home";
        // if (isLogged) navigate("/Home")
        if (isLogged)
        {

            //navigate("/Home")
            navigate("/Dashboard/Home")
            
            // window.location.reload();
        }

    }, [isLogged]);

    const handleSubmit = (e) =>{
        e.preventDefault()
        login()
    };
    const schema = Yup.object().shape({
        username: Yup.string().required("El código de colaborador es un campo obligatorio"),
        //.email("Invalid email format"),
        password: Yup.string().required("Contraseña es un campo obligatorio").min(5, "La contraseña debe tener al menos 5 caracteres"),
    });
    const [passwordType, setpasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setpasswordType("text");
            return;
        }
        setpasswordType("password");
    };

    const Logarse = async(userName,password,setSubmitting)=>{
       
        await login({ userName, password},()=>setSubmitting(false));
        // setSubmitting(false)
        // await Promise.all([login({ userName, password })]).catch(
        //     () => setSubmitting(false)
        //     )

    }

    return (
        <>
        
            <Formik
                enableReinitialize
                validationSchema={schema}
                initialValues={{ username: "", password: "" }}
                onSubmit={(values,{ setSubmitting }) => {

                    // Alert the input values of the form that we filled
                    let userName = values.username;
                    let password = values.password;

                    Logarse(userName,password,setSubmitting)
                    // window.localStorage.setItem('jwt','test')
                    // window.location = "#/Home";

                    //   alert(JSON.stringify(values));

                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,setSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <Slider/>
                                <div className="card contentLogin">
                                {/* <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6"> */}
                                    <div className="text-center form-img">
                                        <img src="images/login/Logo.png" alt="hyper" width={365} />
                                    </div>


                                    <div className="form-login">
                                    {
                                        hasLoginError && <p className="error">Credenciales no validas</p>

                                    }
                                        <label htmlFor="CodigoColaborador" className="peva-label">
                                            Código Colaborador
                                        </label>
                                        <InputDefault
                                            id={"CodigoColaborador"}
                                            type="text"
                                            placeholder="Escribe aquí"
                                            name="username"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="grey"
                                            height={48}
                                            />
                                        {/* <input
                                                id="CodigoColaborador"
                                                type="text"
                                                className="peva-input input-login"
                                                placeholder="Escribe aquí"
                                                //onChange={(e) => setUserName(e.target.value)} value={values.username}
                                                name="username"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                width={"100%"}
                                            /> */}

                                            <p className="error">{errors.username && touched.username && errors.username}</p>

                                        <label htmlFor="Password" className="peva-label">
                                            Contraseña
                                        </label>
                                        <div className="peva-input-icon">
                                            <InputIcon
                                                id="Password"
                                                type={passwordType}
                                                className = "grey"
                                                placeholder="Escribe aquí"
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                icon={passwordType === "password"?<Iconsax.EyeSlash /> : <Iconsax.Eye />}
                                                onClickIcon={togglePassword}
                                                width="100%"
                                                height={48}
                                            />
                                            {/* <input
                                                id="Password"
                                                type={passwordType}
                                                className="peva-input input-login"
                                                placeholder="Escribe aquí"
                                                //onChange={(e) => setPassword(e.target.value)} value={values.username}
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            /> */}
                                            {/* <div className="peva-icon-rigth">
                                                {passwordType === "password" ? (
                                                    <i onClick={togglePassword}>
                                                        <Iconsax.EyeSlash />
                                                    </i>
                                                ) : (
                                                    <i onClick={togglePassword}>
                                                        <Iconsax.Eye />
                                                    </i>
                                                )}
                                            </div> */}
                                            <p className="error">{errors.password && touched.password && errors.password}</p>
                                        </div>

                                        {/* <Button label="Ingresar"  className="peva-button-login" to="./Inicio" disabled={isloginLoading}/> */}
                                        <div className="form-footer">
                                            <Boton nombre={"Ingresar"} color="secondary" widths="100%" type="submit" loading={isSubmitting}></Boton>
                                        </div>
                                        
                                        {/* <div className="flex">
                                            <a className="peva-request-pass">Olvidaste tu contraseña?</a>
                                        </div> */}
                                    </div>
                                </div>

                        </div>

                        {/* </div> */}
                    </form>
                )}
            </Formik>
        </>
    );
}

//export default Login;
