import React, { useState, useEffect } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import CardGrupo from "../../components/Grupos/CardGrupo";
import Boton from "../../components/Boton/Boton";
import { Dialog } from "primereact/dialog";
import "./Grupos.scss";
import { ListGroupsType } from "../../service/GruposService";

import * as constantes from "../../constants/constantes.js";
import usePermisos from "../../hooks/usePermisos";

import useGrupos from "../../hooks/useGrupos";

import classNames from "classnames";
import Sk_Grupos from "../../components/Skeleton/Sk_Grupos";

import useUsuario from "../../hooks/useUsuario";

const Grupos = () => {
    const navigate = useNavigate();
    const [listGruposType, setListGruposType] = useState([]);
    const { obtenerPermisosVista, permisosVista } = usePermisos();
    const [tipoVisualizacion, setTipoVisualizacion] = useState("");
    const [permisoCrear, setPermisoCrear] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [idDelete, setIdDelete] = useState(0);
    const {isDelete,EliminarGrupo} = useGrupos();
    const [grupoSeleccionado, setgrupoSeleccionado] = useState("");
    const {permisos,isLogged,perfil} = useUsuario();
    const clasesnameDialog = classNames("peva-dialog",{
        "peva-dialog-delete": showDialog,
    });
    useEffect(()=>{
        !isLogged && navigate("/");
    },[])


    useEffect(()=>{
        const AccionPermisos = async () => {
            if (permisos.indexOf("verGruposTodos") > -1 || perfil.esJefe) {
                setTipoVisualizacion("total");
                setPermisoCrear(true);
            } else if (permisos.indexOf("creareditargrupos") > -1) {
                setTipoVisualizacion("edicion");
                setPermisoCrear(true);
            } else if (permisos.indexOf("verGerenciaArea") > -1) {
                setTipoVisualizacion("basico");
                setPermisoCrear(false);
            }
        };
        if(permisos.length >0 &&  perfil)
        {
            permisos.indexOf("verGrupos") > -1 ? AccionPermisos() : navigate("/")
        }

    },[permisos,perfil])


    useEffect(()=>{

        const ObtenerGroupType= async()=>
        {
            let jwt = window.localStorage.getItem("jwt");
            await ListGroupsType(jwt,tipoVisualizacion).then((data) => setListGruposType(data));
        }
        // if (listGruposType.length === 0) {
        if (tipoVisualizacion) {
            ObtenerGroupType();
        }
    },[tipoVisualizacion])

    useEffect(()=>{
        if(isDelete) window.location.reload();
    },[isDelete])

    const handleShowDialogDelete=(id,nombre)=>
    {
        setgrupoSeleccionado(nombre);
        setIdDelete(id);
        setShowDialog(true);
    }

    const handleDelete = ()=>{
        let id = idDelete;
        EliminarGrupo({id});
    }
    const hideDialog = () => {
        setShowDialog(false);
    };

    const footerDialog=()=>{
        return(
            <>
                <Boton nombre="Cancelar" color="secondary"metodoClick={hideDialog} widths="100%"/>
               <Boton nombre="Eliminar"color="primary" metodoClick={()=>handleDelete()} widths="100%"/>
            </>
            
        )
    }
    return (
        <div className="eva-dashboard-grupos">
            <div className="eva-dashboard-header header-agrupado">
                <div className="header">
                    <label htmlFor="header">Grupos</label>
                </div>
                {
                    permisoCrear&&
                    <div className="header-right">
                        <Link to={"Crear"}>Crear grupo</Link>
                    </div>
                }
                
            </div>

            {
               listGruposType.length >0 ? 
                    listGruposType.map((item, i) => {
                        if (item.grupos.length > 0)
                            return (
                                <div className={"p-eva-seccion-" + i} key={i}>
                                    <div className="eva-dashboard-header">
                                        <div className="header">
                                            <label htmlFor="header">{item.tipo}</label>
                                        </div>
                                    </div>
                                    <div className="p-fluid formgrid grid">
                                        {item.grupos
                                            .sort((a, b) => b.cantidadUsuarios - a.cantidadUsuarios)
                                            .map((group, j) => {
                                                return (
                                                    <div className="field col-12 md:col-4" key={j}>
                                                        <CardGrupo
                                                            name={group.nombreGrupo}
                                                            numbersUser={group.cantidadUsuarios}
                                                            //srcImg = "https://mvpcluster.com/wp-content/uploads/2019/06/AI-Builder-1024x473.jpg"
                                                            // srcImg={group.nombreImagen === null || group.nombreImagen.length == 0 ? constantes.URL_IMAGE_GROUP_DEFAULT : constantes.URLAPI_MAESTROS + "/Image/grupos/" + group.nombreImagen}
                                                            srcImg={group.nombreImagen === null || group.nombreImagen.length == 0 ? constantes.URL_IMAGE_GROUP_DEFAULT : constantes.URLBLOB_ADJ_GROUP + "/" + group.nombreImagen}
                                                            idGrupo={group.idGrupo}
                                                            displayEdit={group.edicion ? "block" : "none"}
                                                            handleDeletegrupo = {()=>handleShowDialogDelete(group.idGrupo,group.nombreGrupo)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            );
                    })
                : <Sk_Grupos/>
            }
        <Dialog visible={showDialog} modal  onHide={hideDialog} 
         footer={footerDialog}  className ="p-eva-dialogDelete"
        >
           
           <label htmlFor="confirmDelete">Quieres eliminar el grupo {grupoSeleccionado}</label>
           {/* <div className="peva-label">
               
           </div> */}
       </Dialog>
        </div>
        
    );
};

export default Grupos;
